import React from "react";
import styled from "styled-components";
import SignOutText from "./SignOutText";
import InteractiveDropdown from "../elements/InteractiveDropdown";
import LeadEmailManage from "../pages/settings/LeadEmailManage";

//Gobal Styles
import { Button } from "../styled/Buttons";

const NotificationsWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 90px;
`;

const ScrollArea = styled.div`
  width: 90%;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font: normal normal 500 16px Poppins, sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #3a0ca2;
  img {
    width: 65px;
    border-radius: 50%;
    margin-bottom: 10px;
  }
`;

const ProfileSection = styled.div`
  border-top: 1px solid rgba(223, 216, 227, 0.5);
  margin-top: 10px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: 400;
  color: #010814;
`;

const CompanyName = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

const UserName = styled.div`
  color: #7211b6;
  font-weight: 400;
`;

const CreditsWrapper = styled.div`
  display: flex;
  text-align: center;
  color: #3a0ca2;
  font-weight: 500;
  margin-top: 12px;
`;

const CreditAmount = styled.div`
  min-width: 110px;
`;

const VerticalLineSeparator = styled.div`
  background-color: #3a0ca2;
  margin: 5px 15px;
  width: 2px;
  border-radius: 10px;
`;

const ProfileInput = styled.div`
  margin: 16px 0;
  width: 100%;
`;

const ProfileLabel = styled.div`
  color: #7211b6;
  font-weight: 500;
  margin-bottom: ${props => (props.noMargin ? "0" : "1rem")};
`;

const LogoutWrapper = styled.div`
  position: absolute;
  background: #fafbfa;
  bottom: 0px;
  width: 90%;
  text-align: center;
  padding-top: 10px;
  border-top: 1px solid rgba(223, 216, 227, 0.5);
`;

const CompanyProfile = (props) => {
  const {
    smsCredits,
    adCreditsTotal,
    clearDataOnLogout,
    businessPage: { image } = {}
  } = props;
  const { firstName, lastName, email, contactEmail, leadEmails } =
    props.userProfile;

  const { name: companyName } = props.userCompany;

  const handleMediaManager = () => {
    props.openMediaManager();
    props.closeCreditModal();
    props.toggleSlideOutMenu(false);
    props.toggleMenu(false);
  };

  const handleCreditModal = () => {
    props.openCreditModal("increaseCredits", 0, 0);
    props.closeMediaManager();
    props.toggleSlideOutMenu(false);
    props.toggleMenu(false);
  };

  return (
    <NotificationsWrapper>
      <ScrollArea>
        <Header>
          <picture>
            <source srcSet={image} alt="Company Logo" />
            <img
              src="https://res.cloudinary.com/equipter/image/upload/c_scale,h_65/v1640714017/Boostpoint_Images/profile-placeholder.jpg"
              alt="Placeholder Logo"
            />
          </picture>
          <CompanyName>{companyName}</CompanyName>
          <UserName>{`${firstName} ${lastName}`}</UserName>
        </Header>
        <ProfileSection id="credits-section">
          <CreditsWrapper>
            <CreditAmount>
              <div className="credit-number">{adCreditsTotal}</div>
              <div className="subtext">Ad Credits</div>
            </CreditAmount>
            <VerticalLineSeparator></VerticalLineSeparator>
            <CreditAmount>
              <div className="credit-number">{smsCredits}</div>
              <div className="subtext">SMS Credits</div>
            </CreditAmount>
          </CreditsWrapper>
          <Button onClick={handleCreditModal} title="Order More Credits">
            Order More
          </Button>
        </ProfileSection>
        <ProfileSection id="contact-section">
          <ProfileInput>
            <ProfileLabel>Contact Emails</ProfileLabel>
            <InteractiveDropdown
                  dropdownItems={
                    props.userCompany.contactEmails || [props.userProfile.email]
                  }
                  alert={props.alert}
                  readOnly={true}
                  type='Contact Email'
                />
          </ProfileInput>
          <ProfileInput>
            <ProfileLabel>Lead Alert Emails</ProfileLabel>
            <LeadEmailManage
                  companyId={props.userCompany._id}
                  email={props.userProfile.email}
                  alert={props.alert}
                  readOnly={true}
                />
          </ProfileInput>
        </ProfileSection>
        <ProfileSection id="contact-section">
          <ProfileInput>
            <ProfileLabel noMargin={true}>Media Management</ProfileLabel>
            <Button onClick={handleMediaManager} title="Open My Media">
              My Media
            </Button>
          </ProfileInput>
        </ProfileSection>
      </ScrollArea>
      <LogoutWrapper>
        <SignOutText clearDataOnLogout={clearDataOnLogout} {...props} />
      </LogoutWrapper>
    </NotificationsWrapper>
  );
};

export default CompanyProfile;
