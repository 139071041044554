import React from "react";
import styled from "styled-components";
import { getInitials } from "../../../utils/Helpers";

const SearchResults = styled.div`
  width: 100%;
  position: absolute;
  top: 82px;
  left: 0;
  z-index: 30;
  padding: 10px;
  background: white;
  border: 1px solid #e4e4e4;
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
    0 1px 3px 1px rgba(60, 64, 67, 0.16);
  @media only screen and (max-width: 900px) and (min-width: 405px) {
    max-width: 22rem;
    margin: 0 auto;
    left: 50%;
    margin-left: -11rem;
  }
`;

const ContactInitials = styled.div`
  display: flex;
  align-items: top;
  justify-content: center;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin-right: 10px;
    border-radius: 50%;
    background: gray;
    color: white;
    font-size: 1em;
    font-weight: bold;
  }
`;

const ContactName = styled.div`
  font-weight: bold;
`;

const SearchResult = styled.div`
  border-bottom: 1px solid #e4e4e4;
  display: grid;
  grid-template-columns: 40px 1fr;
  /* align-items: center; */
  padding-bottom: 2px;
  padding-bottom: ${props => (props.addPadding ? "14px" : "2px")}
  margin: 18px 10px 0;
  &:last-child {
    border: none;
  }
`;

const NumHeader = styled.p`
  margin: 0;
  font-size: 0.8em;
`;

const MsgNum = styled.div`
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  font-size: 19px;
  margin: 18px 0 1px 0;
  padding: 0 12px 18px;
  cursor: pointer;
  span {
    padding-right: 8px;
    font-size: 24px;
  }
  &:last-child {
    border: none;
  }
`;

const MessageSearchResults = props => {
  const { searchResults } = props;
  return searchResults.length > 0 ? (
      <SearchResults>
        {searchResults.map((result, key) => (
          <SearchResult
            key={result._id}
            addPadding={!result.phone && !result.mobilePhone}
          >
            <ContactInitials>
              <span>{getInitials(result)}</span>
            </ContactInitials>
            <div>
              <ContactName>
                {result.firstName} {result.lastName}
              </ContactName>
              {!result.mobilePhone && !result.phone ? (
                <div>No phone number saved</div>
              ) : (
                <div>
                  {result.mobilePhone && result.phone && (
                    <NumHeader>Pick a Number:</NumHeader>
                  )}
                  {!!result.mobilePhone && (
                    <MsgNum
                      onClick={() =>
                        props.loadNewContact(result._id, result.mobilePhone)
                      }
                    >
                      <span className='material-icons'>smartphone</span>
                      {result.mobilePhone}
                    </MsgNum>
                  )}
                  {!!result.phone && (
                    <MsgNum
                      onClick={() =>
                        props.loadNewContact(result._id, result.phone)
                      }
                    >
                      <span className='material-icons'>call</span>
                      {result.phone}
                    </MsgNum>
                  )}
                </div>
              )}
            </div>
          </SearchResult>
        ))}
      </SearchResults>
  ) : null;
};

export default MessageSearchResults;
