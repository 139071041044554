import axios from "axios";

export const getStockVideos = async industry => {
  try {
    let returnedVideos = [];
    const stockReturn = await axios
      .get(`${process.env.REACT_APP_CLOUDINARY_VIDEO_BASEURL}/list/Stock.json`)
      .catch(() => {
        console.log("No Stock Videos Exist");
      });
    const { data: { resources: stockVideos = [] } = {} } = stockReturn || {};
    if (stockVideos) {
      returnedVideos = [...returnedVideos, ...stockVideos];
    }
    if (industry) {
      const industryReturn = await axios
        .get(
          `${process.env.REACT_APP_CLOUDINARY_VIDEO_BASEURL}/list/${industry}.json`
        )
        .catch(() => {
          console.log("No Industry Specific Videos Exist");
        });
      const { data: { resources: industryVideos = [] } = {} } =
        industryReturn || {};
      if (industryVideos) {
        returnedVideos = [...returnedVideos, ...industryVideos];
      }
    }
    return returnedVideos;
  } catch (err) {
    console.log("error getting stock videos", err.message);
    return [];
  }
};

export const getStockImages = async industry => {
  try {
    let returnImages = [];
    const stockReturn = await axios
      .get(`${process.env.REACT_APP_CLOUDINARY_IMAGE_BASEURL}/list/Stock.json`)
      .catch(() => {
        console.log("No Stock Images Exist");
      });
    const { data: { resources: stockImages = [] } = {} } = stockReturn || {};
    console.log("stockImages", stockImages);
    returnImages = [...returnImages, ...stockImages];
    if (industry) {
      const { data: { resources: industryImages = [] } = {} } = await axios
        .get(
          `${process.env.REACT_APP_CLOUDINARY_IMAGE_BASEURL}/list/${industry}.json`
        )
        .catch(() => {
          console.log("No Industry Specific Images Exist");
        });
      returnImages = [...returnImages, ...industryImages];
    }
    return returnImages;
  } catch (err) {
    console.log("error getting stock images", err.message);
    return [];
  }
};
