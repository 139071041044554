import React from "react";
import styled from "styled-components";

const MessagesBtn = styled.div`
  display: flex;
  position: relative;
  margin: 0 8px;
  border-radius: 50%;
  color: ${props => (!props.disabled ? props.theme.HC2 : props.theme.TextC)};
  width: 56px;
  height: 56px;
  justify-content: center;
  align-items: center;
  font-size: 34px;
  span {
    cursor: pointer;
    font-size: inherit;
  }
`;

const UnreadBubble = styled.span`
  background-color: ${props => props.theme.HCErr2};
  width: 18px;
  height: 18px;
  position: absolute;
  display: block;
  border-radius: 50%;
  right: 6px;
  top: 5px;
`;

const NumChooser = styled.div`
  // layout
  position: absolute;
  left: -7px;
  top: 69px;
  width: 15em;
  z-index: 1006;

  // looks
  text-align: left;
  background-color: ${props => props.theme.BGC};
  padding: 1.125em 1.5em 0.5em;
  font-size: 1rem;
  border-radius: 1rem;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.3),
    0 0.0625rem 0.125rem rgba(0, 0, 0, 0.2);
  &::before {
    // layout
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    bottom: 100%;
    left: 1.3em; // offset should move with padding of parent
    border: 0.75rem solid transparent;
    border-top: none;

    // looks
    border-bottom-color: #fff;
    filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, 0.1));
  }
  span {
    display: block;
  }
  p {
    margin: 0;
    color: black;
    font-weight: bold;
  }
`;

const MsgNum = styled.div`
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  font-size: 19px;
  margin: 18px 0 1px 0;
  padding: 0 12px 18px;
  cursor: pointer;
  span {
    padding-right: 8px;
    font-size: 24px;
  }
  &:last-child {
    border: none;
  }
`;

const Overlay = styled.div`
  /* background-color: rgba(0, 0, 0, 0.03); */
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1005;
`;

const ContactMsgBtn = props => {
  const {
    btnDisabled = true,
    mobilePhone,
    phone,
    unreadMessages = 0,
    twilioPhone
  } = props;

  const [showNumDropdown, setShowNumDropdown] = React.useState(false);

  return (
    <MessagesBtn
      disabled={btnDisabled}
      onClick={() =>
        setShowNumDropdown(
          !twilioPhone ? "sms" : !mobilePhone && !phone ? "phone" : ""
        )
      }
    >
      <span className='material-icons'>chat</span>
      {unreadMessages > 0 && <UnreadBubble />}
      {!!showNumDropdown && (
        <div>
          <Overlay onClick={this.toggleNumDropdown} />
          <NumChooser>
            <p>Pick a Number:</p>
            {!!mobilePhone && (
              <MsgNum onClick={() => this.goToMessages(mobilePhone)}>
                <span
                  className='material-icons'
                  title='Message Contact'
                >
                  smartphone
                </span>
                {mobilePhone}
              </MsgNum>
            )}
            {!!phone && (
              <MsgNum onClick={() => this.goToMessages(phone)}>
                <span
                  className='material-icons'
                  title='Message Contact'
                >
                  call
                </span>
                {phone}
              </MsgNum>
            )}
          </NumChooser>
        </div>
      )}
    </MessagesBtn>
  );
};

export default ContactMsgBtn;
