import React from "react";
import { Elements, StripeProvider } from "react-stripe-elements";
import OrderCredits from "./OrderCredits";

class OrderCreditsStripeWrapper extends React.Component {
  render() {
    return (
      <div>
        {this.props.creditsModalOpen && (
          <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
            <Elements>
              <OrderCredits {...this.props} />
            </Elements>
          </StripeProvider>
        )}
      </div>
    );
  }
}

export default OrderCreditsStripeWrapper;
