import React from "react";
import styled from "styled-components";

const I = styled.i`
  font-size: ${props => props.fontSize};
  position: ${props => (props.position ? "relative" : "initial")};
  top: 2px;
  padding: 2px 4px;
  color: ${props => props.theme.IconColor};
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.HCLit};
  }
`;

const Icon = props => {
  const {
    onClick,
    icon,
    name,
    classes = [],
    fontSize = "1em",
    position = false
  } = props;

  return (
    <I
      className={`material-icons ${classes.join(" ")}`}
      name={name}
      onClick={onClick}
      fontSize={fontSize}
      position={position}
    >
      {icon}
    </I>
  );
};

export default Icon;
