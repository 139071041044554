/* global cloudinary */

import React from "react";
import styled from "styled-components";
import ImageMenuWrapper from "../styled/ImageMenuWrapper";

const UploadBtn = styled.div`
  @media screen and (max-width: 580px) {
    display: none;
  }
`;

const VideoModalHeader = props => {
  const { menuSelection } = props;

  return (
    <ImageMenuWrapper>
      <UploadBtn
        id='uploadVideos'
        className={
          menuSelection === "uploadVideo" ? "selected button" : "button"
        }
      >
        <button onClick={() => props.setMenu("uploadVideo")}>
          <h2>Upload</h2>
          <small>Add videos to your gallery</small>
        </button>
      </UploadBtn>
      <div
        id='myVideos'
        className={menuSelection === "myVideos" ? "selected button" : "button"}
      >
        <button onClick={() => props.setMenu("myVideos")}>
          <h2>Gallery</h2>
          <small>Browse your videos</small>
        </button>
      </div>
      <div
        id='freeVideos'
        className={
          menuSelection === "freeImages" ? "selected button" : "button"
        }
      >
        <button onClick={() => props.setMenu("freeImages")}>
          <h2>Stock</h2>
          <small>Royalty free videos</small>
        </button>
      </div>
    </ImageMenuWrapper>
  );
};

export default VideoModalHeader;
