import React from "react";
import styled from "styled-components";

import { LoaderDotsSmall } from "../../elements/Loaders";

// Import Styled Components
import { Button } from "../../styled/Buttons";
import H3 from "../../styled/H3";
import {
  InputBox,
  InputBoxWrapper,
  InputContainer,
  InputLabel
} from "../../styled/Input";
import SectionGroup from "../../styled/SectionGroup";
import SectionTitle from "../../styled/SectionTitle";
import SigninForm from "../../styled/SigninForm";

const ConnectAccountFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  min-height: 31rem;
  max-width: 70rem;
  margin: auto;
`;

const SuccessFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 500px;
  max-width: 30rem;
  margin: auto;

  i {
    color: rgba(124, 196, 122, 1);
    font-size: 3rem;
  }
`;

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value
});

class EZTexting extends React.Component {
  state = {
    username: "",
    password: "",
    connectStarted: false,
    connectSuccess: false,
    error: null
  };

  componentDidMount = () => {};

  render() {
    const { username, password, connectStarted, connectSuccess } = this.state;
    const isInvalid = password === "" || username === "";
    return (
      <SectionGroup>
        {connectSuccess ? (
          <SuccessFlex>
            <i className='material-icons'>check_circle_outline</i>
            <H3>Connection Successful</H3>
            <span>
              You have successfully connected your SMS account and have unlocked
              the power of texting automation. Please let your Boostpoint rep
              know that this is now done.
            </span>
          </SuccessFlex>
        ) : (
          <ConnectAccountFlex>
            <div>
              <img
                // src="https://res.cloudinary.com/equipter/image/upload/c_scale,q_100/v1522850703/Letter_Logo.png"
                src='https://res.cloudinary.com/equipter/image/upload/c_scale,q_100/v1604942300/Boostpoint_Images/boostpoint_Logo_sms.png'
                alt='Boostpoint Logo'
                style={{ height: "40px" }}
              ></img>
              <SectionTitle>
                <h4>Please enter your credentials to link your SMS account</h4>
              </SectionTitle>
            </div>
            <div>
              <SigninForm noMargin>
                <InputContainer alignment={"center"} customWidth={"25rem"}>
                  <InputLabel>Username</InputLabel>
                  <InputBoxWrapper>
                    <InputBox
                      value={username}
                      onChange={event =>
                        this.setState(byPropKey("username", event.target.value))
                      }
                      type='text'
                      placeholder='Username'
                    />
                  </InputBoxWrapper>
                </InputContainer>
                <InputContainer alignment={"center"} customWidth={"25rem"}>
                  <InputLabel>Password</InputLabel>
                  <InputBoxWrapper>
                    <InputBox
                      value={password}
                      onChange={event =>
                        this.setState(byPropKey("password", event.target.value))
                      }
                      type='password'
                      placeholder='Password'
                      id='passwd'
                    />
                  </InputBoxWrapper>
                </InputContainer>
                <Button
                  solid
                  disabled={isInvalid || connectStarted}
                  type='submit'
                >
                  Submit
                  {connectStarted && <LoaderDotsSmall />}
                </Button>
              </SigninForm>
            </div>
          </ConnectAccountFlex>
        )}
      </SectionGroup>
    );
  }
}

export default EZTexting;
