import GoogleMapReact from "google-map-react";
import React from "react";
import * as _ from "underscore";
import MapMarker from "./GoogleMapMarker";

function createMapOptions(maps) {
  // next props are exposed at maps
  // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
  // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
  // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
  // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
  // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
  return {
    Animation: maps.Animation.DROP,
    mapTypeControlOptions: {
      position: maps.ControlPosition.TOP_LEFT
    },
    mapTypeControl: true,
    gestureHandling: "cooperative"
  };
}

class GoogleRadiusMap extends React.Component {
  state = {
    zoom: this.props.options.zoom,
    center: this.props.options.center,
    draggable: this.props.mapDraggable
  };

  componentDidUpdate = prevProps => {
    const { addresses, mapDraggable } = this.props;
    const prevAddresses = prevProps.addresses;
    if (addresses.length !== prevAddresses.length) {
      const { map_, maps_ } = this._mapObject;
      this._apiIsLoaded(map_, maps_, addresses);
    } else {
      addresses.forEach((address, index) => {
        if (address !== prevAddresses[index]) {
        }
      });
    }
    if (
      mapDraggable !== prevProps.mapDraggable &&
      mapDraggable !== this.state.draggable
    ) {
      this.setState({ draggable: mapDraggable });
    }
  };

  // currently resizes zoom reported to markers for the size of the radius circles.

  _onBoundsChange = ({ center, zoom, bounds, marginBounds, size }) => {
    this.setState({
      zoom,
      center
    });
  };

  _getMapBounds = (map, maps, locations) => {
    const bounds = new maps.LatLngBounds();
    locations.forEach(location => {
      const { lat, lng } = location;
      bounds.extend(new maps.LatLng(lat, lng));
    });
    return bounds;
  };

  _bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, "idle", () => {
      maps.event.addDomListener(window, "resize", () => {
        map.fitBounds(bounds);
        const zoom = map.getZoom();
        map.setZoom(zoom > 10 ? 10 : zoom);
      });
    });
  };

  _apiIsLoaded = (map, maps, locations) => {
    if (map) {
      const bounds = this._getMapBounds(map, maps, locations);
      map.fitBounds(bounds);
      const zoom = map.getZoom();
      map.setZoom(zoom > 10 ? 10 : zoom);
      this._bindResizeListener(map, maps, bounds);
    }
  };

  onMapClick = ({ x, y, lat, lng, event }) => {
    this.props.addAddress({ lat, lng });
  };

  onCircleInteractionDown(childKey, childProps, mouse, props) {
    const { lat, lng } = mouse;
    const moveThrottle = _.throttle(
      () => props.updateAddress({ lat, lng }, childKey),
      100
    );
    moveThrottle();
    props.setMapDraggable(false);
  }

  onCircleInteractionDrag(childKey, childProps, mouse, props) {
    const { lat, lng } = mouse;
    const moveThrottle = _.throttle(
      () => props.updateAddress({ lat, lng }, childKey),
      100
    );
    moveThrottle();
    props.setMapDraggable(false);
  }

  onCircleInteractionUp(childKey, childProps, mouse, props) {
    const { lat, lng } = mouse;
    const { addresses } = props;
    props.updateAddress({ lat, lng }, childKey);
    props.setMapDraggable(true);
    const { map_, maps_ } = this._mapObject;
    this._apiIsLoaded(map_, maps_, addresses);
  }

  render() {
    const { center, zoom, draggable = true } = this.state;
    const { addresses, radius } = this.props;
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: "400px", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
          // ref={(mapObject) => console.log("GoogleMap: ", mapObject ? mapObject : "no map object")}
          ref={mapObject => (this._mapObject = mapObject)}
          center={center}
          zoom={zoom}
          options={createMapOptions}
          onChange={this._onBoundsChange}
          yesIWantToUseGoogleMapApiInternals
          onClick={this.onMapClick}
          onGoogleApiLoaded={({ map, maps }) => {
            if (addresses.length > 0) {
              this._apiIsLoaded(map, maps, addresses);
            }
          }}
          draggable={draggable}
          // onChange={this.onChange}
          onChildMouseDown={(childKey, childProps, mouse) =>
            this.onCircleInteractionDown(
              childKey,
              childProps,
              mouse,
              this.props
            )
          }
          onChildMouseUp={(childKey, childProps, mouse) =>
            this.onCircleInteractionUp(childKey, childProps, mouse, this.props)
          }
          onChildMouseMove={(childKey, childProps, mouse) =>
            this.onCircleInteractionDrag(
              childKey,
              childProps,
              mouse,
              this.props
            )
          }
        >
          {addresses.map((address, index) => {
            const { lat, lng } = address;
            return (
              <MapMarker
                key={index}
                lat={lat}
                lng={lng}
                text={index + 1}
                radius={address.radius ? address.radius : radius}
                {...this.state}
              />
            );
          })}
        </GoogleMapReact>
      </div>
    );
  }
}

export default GoogleRadiusMap;
