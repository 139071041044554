import React, {useState, useEffect} from "react";
import moment from "moment";
import styled from "styled-components";
import Floater from "react-floater";
import LoadingBar from "../../elements/LoadingBar";
import {
  ChartTitle,
  ChartTitleChange,
  DashboardModuleTitle,
  DataDirection,
  floaterStyles,
  Icon,
  Overlay,
  Section
} from "./DashboardStyles";
import { contactsGet } from "../../../utils/ContactAPI";
import { formatMoney } from "../../../utils/Helpers";

const LeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  align-content: space-around;
  height: 100%;
  padding-bottom: 47px;
`;

const LeadCost = styled.div`
  font-size: 4em;
  font-weight: bold;
  color: gray;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 804px) {
    min-height: 3em;
  }
`;

const CostPerLead = props => {
  const {
    floaters,
    chartsLoading,
    startDate,
    endDate,
    currentSpend,
    prevSpend
  } = props;
  const [leadCounts, setLeadCounts] = useState({});
  const [currentCostPerLead, setCurrentCostPerLead] = useState(0);
  const [change, setChange] = useState(0);

  useEffect(() => {
    const i = async () => {
      try {
        const start = moment(startDate);
        start.startOf("day").tz("Etc/UTC");
        const end = moment(endDate);
        end.endOf("day").tz("Etc/UTC");
        const daysDiff = end.diff(start, "days");
        const prevEnd = moment(start).subtract(1, "days");
        const prevStart = moment(prevEnd).subtract(daysDiff, "days");
        console.log(
          start.format(),
          end.format(),
          prevStart.format(),
          prevEnd.format()
        );
        const currentLeads = await contactsGet({
          startDate: start.toISOString(),
          endDate: end.toISOString()
        });
        const currentCount = currentLeads.filter(
          c => c.source === "SOCIAL_MEDIA"
        ).length;
        const prevLeads = await contactsGet({
          startDate: prevStart.toISOString(),
          endDate: prevEnd.toISOString()
        });
        const prevCount = prevLeads.filter(
          c => c.source === "SOCIAL_MEDIA"
        ).length;
        setLeadCounts({ current: currentCount, previous: prevCount });
      } catch (err) {
        console.log(err.message);
      }
    };
    i();
  }, [endDate, startDate]);

  useEffect(() => {
    if (currentSpend !== "NaN"){
      if( prevSpend !== "NaN") {
      const currentAve = currentSpend / leadCounts.current;
      const prevAve = prevSpend / leadCounts.previous;
      setCurrentCostPerLead(currentAve);
      const diff = currentAve - prevAve;
      const newChange = Math.round((diff / prevAve) * 100 * 100) / 100;
      setChange(newChange);
    }
    const currentAve = currentSpend / leadCounts.current;
    setCurrentCostPerLead(currentAve);
  }
  }, [leadCounts, currentSpend, prevSpend]);

  return (
    <Section>
      <DashboardModuleTitle>
        <ChartTitle>
          <h6>Cost per Lead (Lead Ads Only)</h6>
          <Floater
            open={floaters.costPerLead}
            content='Average cost per lead over the selected date range. This only takes into account lead generating campaigns.'
            styles={floaterStyles}
            event='hover'
            placement='right'
          >
            <Icon
              className='material-icons'
              onClick={e => props.handleFloaterClick(e, "costPerLead")}
              name='floater-toggle'
            >
              info
            </Icon>
            {floaters.costPerLead && (
              <Overlay
                onClick={e => props.handleFloaterClick(e, "costPerLead")}
                className='overlay'
              />
            )}
          </Floater>
        </ChartTitle>
      </DashboardModuleTitle>
      {chartsLoading ? (
        <LoadingBar />
      ) : (
        <LeadWrapper>
          <LeadCost>
            <span>
              {currentCostPerLead
                ? formatMoney(currentCostPerLead, true, false)
                : "$0"}
            </span>
            {!!change && (
              <ChartTitleChange negative={change < 0}>{`${
                change >= 0 ? "+" : ""
              }${change}%`}</ChartTitleChange>
            )}
          </LeadCost>
          {!!change && (
            <DataDirection negative={change < 0} neutral={change === 0}>
              <Icon className='material-icons'>
                {change < 0
                  ? "arrow_downward"
                  : change === 0
                  ? "horizontal_rule"
                  : "arrow_upward"}
              </Icon>
            </DataDirection>
          )}
        </LeadWrapper>
      )}
    </Section>
  );
};

export default CostPerLead;
