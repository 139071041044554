import React from "react";
import BpModal from "../../elements/BpModal";

// Styled Components
import { TemplateButton } from "../../styled/Buttons";
import CheckboxGroupInput from "../../styled/CheckboxGroupInput";
import CheckboxGroupLabel from "../../styled/CheckboxGroupLabel";
import InputError from "../../styled/InputErrorForm";
import TemplateNameInput from "../../styled/TemplateName";

import {
  InputBox,
  InputBoxWrapper,
  InputContainer,
  InputLabel
} from "../../styled/Input";

const MyTemplates = props => {
  const {
    templateId,
    handleTemplateCallback,
    myTemplates = [],
    stockTemplates = []
  } = props;
  const [newTemplateName, setNewTemplateName] = React.useState("");
  const [updateTemplateName, setUpdateTemplateName] = React.useState("");
  const [saveModalIsOpen, setSaveModalIsOpen] = React.useState(false);
  const [updateModalIsOpen, setUpdateModalIsOpen] = React.useState(false);
  const [removeModalIsOpen, setRemoveModalIsOpen] = React.useState(false);
  const [savePhotos, setSavePhotos] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [errMessages, setErrMessages] = React.useState({});
  const [oldTemplateName, setOldTemplateName] = React.useState("");

  React.useEffect(() => {
    const matchingTemplate = myTemplates.find(
      template => template._id === templateId
    );
    if (matchingTemplate) {
      setOldTemplateName(matchingTemplate.templateName);
    }
  }, [myTemplates, templateId]);

  // handles saving new Photo template
  const handleSave = async () => {
    console.log("saving");
    setLoading(true);
    if (!newTemplateName) {
      setErrMessages({
        noNewTemplateName: "Please provide a name for your template"
      });
    } else {
      // handles saving of template
      // newTemplateName = newTemplateName.trim();
      await handleTemplateCallback({
        action: "save",
        name: newTemplateName,
        saveMedia: savePhotos
      });
    }
    resetSaveModal();
  };

  // handles updating Photo template
  const handleUpdate = async () => {
    // checks to see if template has been named
    // if (updateTemplateName === null || updateTemplateName === undefined) {
    //   updateTemplateName = this.props.pendingCampaign.photoTemplateUsed;
    // }
    if (updateTemplateName === "") {
      setErrMessages({
        blankUpdateTemplateName: "Please provide a name for your template"
      });
    } else {
      setLoading(true);
      await handleTemplateCallback({
        action: "update",
        name: newTemplateName,
        saveMedia: savePhotos
      });
      resetSaveModal();
    }
  };

  const handleRemove = async () => {
    setLoading(true);
    await handleTemplateCallback({ action: "remove" });
    resetSaveModal();
  };

  const handlePhotoTemplateSave = () => {
    setSavePhotos(!savePhotos);
  };

  const resetSaveModal = () => {
    setNewTemplateName("");
    setSavePhotos(false);
    setLoading(false);
    setSaveModalIsOpen(false);
    setUpdateModalIsOpen(false);
    setRemoveModalIsOpen(false);
    setErrMessages({});
  };

  const updateNewTemplateName = value => {
    setNewTemplateName(value.trim());
  };

  const updateUpdateTemplateName = value => {
    setUpdateTemplateName(value.trim());
  };

  const openUpdateModal = () => {
    setUpdateModalIsOpen(true);
    const matchingTemplate = myTemplates.find(t => t._id === templateId);
    if (matchingTemplate.media.length > 0) {
      setSavePhotos(true);
    }
    setUpdateTemplateName(oldTemplateName);
  };

  return (
    <div className='save-template'>
      {!templateId ? (
        <div>
          <h5>Save this as Template?</h5>
          <TemplateButton href='#' onClick={() => setSaveModalIsOpen(true)}>
            Save
          </TemplateButton>
        </div>
      ) : null}

      {stockTemplates.some(template => template._id === templateId) && (
        <div className='save-template'>
          <h5>Save this as my Template?</h5>
          <TemplateButton href='#' onClick={() => setSaveModalIsOpen(true)}>
            Save
          </TemplateButton>
        </div>
      )}

      {myTemplates.some(template => template._id === templateId) && (
        <div className='save-template'>
          <h5>Manage Template:</h5>
          <TemplateButton href='#' onClick={openUpdateModal}>
            Update
          </TemplateButton>
          <TemplateButton href='#' onClick={() => setSaveModalIsOpen(true)}>
            Save New
          </TemplateButton>
          <TemplateButton href='#' onClick={() => setRemoveModalIsOpen(true)}>
            Delete
          </TemplateButton>
        </div>
      )}

      <BpModal
        open={saveModalIsOpen}
        title={`Save Template`}
        primaryAction={{
          btnLabel: "Save",
          action: handleSave,
          loading: loading
        }}
        secondaryAction={{
          btnLabel: "Cancel",
          action: resetSaveModal
        }}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        contentLabel='Save Template'
        body={
          <div>
            <InputContainer customWidth={"20rem"}>
              <InputLabel>Template Name</InputLabel>
              <InputBoxWrapper>
                <InputBox
                  type='text'
                  name='headline'
                  onChange={input => updateNewTemplateName(input.target.value)}
                />
              </InputBoxWrapper>
            </InputContainer>
            <CheckboxGroupInput
              type='checkbox'
              value='photoType'
              name='adType'
              onChange={handlePhotoTemplateSave}
              checked={savePhotos}
            />
            <CheckboxGroupLabel>
              Save selected images/video with this template
            </CheckboxGroupLabel>
            {/* Error message for this input */}
            <div>
              <InputError>{errMessages.noNewTemplateName}</InputError>
            </div>
          </div>
        }
        width='auto'
      />

      <BpModal
        open={updateModalIsOpen}
        title={`Update Template`}
        primaryAction={{
          btnLabel: "Save",
          action: handleUpdate,
          loading: loading
        }}
        secondaryAction={{
          btnLabel: "Cancel",
          action: resetSaveModal
        }}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        contentLabel='Update Template'
        body={
          <div>
            <p>Template name:</p>
            <TemplateNameInput
              type='text'
              name='headline'
              defaultValue={oldTemplateName}
              onChange={input => updateUpdateTemplateName(input.target.value)}
            />
            <CheckboxGroupInput
              type='checkbox'
              value='photoType'
              name='adType'
              onChange={handlePhotoTemplateSave}
              checked={savePhotos}
            />
            <CheckboxGroupLabel>
              Save selected images with this template
            </CheckboxGroupLabel>
            {/* Error message for this input */}
            <div>
              <InputError>{errMessages.blankUpdateTemplateName}</InputError>
            </div>
          </div>
        }
        width='auto'
      />

      <BpModal
        open={removeModalIsOpen}
        title={`Remove Template`}
        primaryAction={{
          btnLabel: "Remove",
          action: handleRemove,
          loading: loading
        }}
        secondaryAction={{
          btnLabel: "Cancel",
          action: resetSaveModal
        }}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        contentLabel='Remove Template'
        body={
          <div>
            <p>Are you sure you want to remove this template?</p>
            <h4>"{oldTemplateName}"</h4>
          </div>
        }
        width='auto'
      />
    </div>
  );
};

export default MyTemplates;
