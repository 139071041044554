import React from "react";
import axios from "axios";
import queryString from "query-string";
import styled from "styled-components";

// Import Styled Components
import { Button } from "../../styled/Buttons";
import SectionGroup from "../../styled/SectionGroup";
import SectionTitle from "../../styled/SectionTitle";
import H3 from "../../styled/H3";
import LoadingBar from "../../elements/LoadingBar";

const ConnectAccountFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  min-height: 31rem;
  max-width: 70rem;
  margin: auto;
`;

const SuccessFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 500px;
  max-width: 30rem;
  margin: auto;

  i {
    color: ${props => props.theme.HCSuc};
    font-size: 3rem;
  }
`;

class ConstantContact extends React.Component {
  state = {
    loading: true,
    connectStarted: false,
    connectSuccess: false,
    error: null
  };

  componentDidMount = async () => {
    const query = queryString.parse(this.props.location.search);
    if (query && query.code) {
      const { code } = query;
      console.log("params: ", query);
      const { userProfile } = this.props;
      console.log("userProfile: ", userProfile);
      try {
        const auth = await axios.post(`${BP_NODE_SERVER}/constantContactAuth`, {
          code,
          userProfile
        });
        this.setState({ loading: false, connectSuccess: true });
        console.log("constant contact auth: ", auth);
      } catch (err) {
        console.error(err.response.data);
        this.setState({ loading: false });
        this.props.alert({
          type: "error",
          msg: err.response.data.error_description
        });
      }
    } else {
      this.setState({ loading: false });
    }
  };

  onConnect = async () => {
    window.open(
      "https://api.cc.email/v3/idfed?client_id=9894ba28-01e9-435a-8a4f-9562512faa2a&redirect_uri=https://app.boostpoint.com/constant-contact&response_type=code&scope=contact_data+campaign_data",
      "_blank"
    );
    this.setState({ connectStarted: true });
  };

  render() {
    const { loading, connectStarted, connectSuccess } = this.state;
    return (
      <SectionGroup>
        {connectSuccess ? (
          <SuccessFlex>
            <i className='material-icons'>check_circle_outline</i>
            <H3>Connection Successful</H3>
            <span>
              You have successfully connected your Constant Contact account.
              Please let your Boostpoint rep know that this is now done.
            </span>
          </SuccessFlex>
        ) : (
          <ConnectAccountFlex>
            <div>
              <img
                src='https://res.cloudinary.com/equipter/image/upload/v1616090520/Boostpoint_Images/Constant_Contact.png'
                alt='Boostpoint Logo'
                style={{ height: "40px" }}
              ></img>
              <SectionTitle>
                <h4>Smart, powerful email. No restrictions.</h4>
              </SectionTitle>
            </div>
            <div>
              {loading ? (
                <div>
                  <LoadingBar noMargin />
                  <p>Checking Constant Contact status.</p>
                </div>
              ) : connectStarted ? (
                <SuccessFlex>
                  <LoadingBar noMargin />
                  <H3>Connection Started</H3>
                  <span>
                    The Constant Contact connection process has started in
                    another window. Please follow the prompts there to complete
                    the connection.
                  </span>
                </SuccessFlex>
              ) : (
                <div>
                  <SectionTitle>
                    <H3>Ready to get started with Constant Contact?</H3>
                  </SectionTitle>
                  <div>
                    <Button solid onClick={() => this.onConnect()}>
                      Connect Constant Contact
                    </Button>
                  </div>
                  <div>
                    <Button
                      outline
                      onClick={() =>
                        window.open(
                          "https://www.constantcontact.com/trial-home",
                          "_blank"
                        )
                      }
                    >
                      Learn More
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </ConnectAccountFlex>
        )}
      </SectionGroup>
    );
  }
}

export default ConstantContact;
