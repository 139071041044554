/* global cloudinary */

import React from "react";
import { Button } from "../styled/Buttons";
import ImageWrapper from "../styled/ImageWrapper";
import { assetAdd } from "../../utils/MediaAPI";

const UploadVideoPane = props => {
  const { show, email, companyId } = props;

  const uploadCloudinaryWidget = () => {
    cloudinary.openUploadWidget(
      {
        cloud_name: "equipter",
        /* cspell: disable-next-line */
        upload_preset: "x8udno21",
        sources: ["local", "camera"],
        resource_type: "video",
        multiple: false,
        show_powered_by: false,
        theme: "minimal",
        tags: [email]
      },
      (error, result) => {
        if (result.event === "success") {
          uploadSuccess(result.info);
        }
        if (result.event === "close") {
          props.setMenu("myVideos");
        }
        if (error) {
          console.error(error);
        }
      }
    );
  };

  const uploadSuccess = async video => {
    const newVideo = {
      companyId,
      name: video.public_id,
      format: video.format,
      type: "VIDEO",
      status: "ACTIVE",
      height: video.height,
      width: video.width,
      createdDate: video.created_at,
      userPending: true,
      usedCampaignIds: []
    };
    await assetAdd(newVideo);
    props.loadUserVideos();
    props.handleMyVideoSelected(newVideo);
  };

  return (
    show && (
      <ImageWrapper drag>
        <div
          style={{
            position: "relative",
            width: "100%",
            flexBasis: "100%"
          }}
        >
          <Button
            type='button'
            onClick={() => {
              uploadCloudinaryWidget();
            }}
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-58%, -50%)"
            }}
          >
            upload new video
          </Button>
        </div>
      </ImageWrapper>
    )
  );
};

export default UploadVideoPane;
