import React from "react";
import styled from "styled-components";
import {
  InputBox,
  InputBoxWrapper,
  InputContainer
} from "../../../styled/Input";

const DefaultIcon = styled.span`
  position: absolute;
  top: 7px;
  right: -30px;
`;

const Type = styled.p`
  text-align: center;
  font-weight: bold;
  &.status-type {
    margin: 0 auto 30px;
  }
`;

const StatusList = props => {
  const { items } = props;

  const [statuses, setStatuses] = React.useState({});

  React.useEffect(() => {
    if (items && items.length > 0) {
      const newStatus = {};
      items.forEach(c => {
        if (!newStatus[c.type]) {
          newStatus[c.type] = [c];
        } else {
          newStatus[c.type].push(c);
        }
      });
      setStatuses(newStatus);
    }
  }, [items]);

  return Object.keys(statuses).map(type => (
    <div key={type}>
      <Type className='status-type'>{type}</Type>
      {statuses[type].map(status => (
        <InputContainer key={status._id}>
          <InputBoxWrapper>
            <InputBox
              type='text'
              name={status.status}
              required
              readOnly={true}
              value={status.status}
            />
            {!!status.isDefault && (
              <DefaultIcon className='material-icons' title='Default status'>
                star
              </DefaultIcon>
            )}
          </InputBoxWrapper>
        </InputContainer>
      ))}
    </div>
  ));
};

export default StatusList;
