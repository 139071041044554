import React from "react";
import styled from "styled-components";
import { LoaderDotsSmall } from "../../elements/Loaders";
import { errorHandler, getInitials, monthsAbr } from "../../../utils/Helpers";
import { messageContactUnread } from "../../../utils/MessagesAPI";

// Styled Components
import { ContactLoading } from "../../styled/Contacts";
import { Button } from "../../styled/Buttons";

const ThreadItem = styled.div`
  padding: 19px 10px 5px 10px;
  border-top: 1px solid ${props => props.theme.Border};
  cursor: ${props => (props.active ? "default" : "pointer")};
  &:last-child {
    border-bottom: 1px solid ${props => props.theme.Border};
  }
  background-color: ${props =>
    props.active ? props.theme.BGCAlt2 : "transparent"};
  color: ${props => props.theme.TextC};
`;

const ThreadHeader = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr 100px;
`;

const InitialWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContactInitials = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  font-size: 1em;
  border-radius: 50%;
  color: ${props => props.theme.BGC};
  background-color: ${props => props.theme.TextC};
  font-weight: bold;
`;

const InitialPlaceholder = styled.span`
  font-size: 37px;
  color: ${props => (props.active ? props.theme.BGC : props.theme.TextC)};
`;

const ThreadName = styled.div`
  display: flex;
  align-items: center;
`;

const NameBox = styled.div`
  display: flex;
  flex-direction: column;
  span {
    display: block;
  }
`;
const NumDescription = styled.span`
  font-size: 0.8em;
  text-align: left;
`;

const ThreadDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ThreadBody = styled.div`
  text-align: left;
  padding: 10px 10px;
  display: grid;
  grid-template-columns: ${props => (props.threadUnread ? "1fr 25px" : "1fr")};
`;

const ThreadText = styled.p`
  font-size: 0.9em;
  margin: 0;
  display: flex;
  align-items: center;
`;

const ThreadUnread = styled.div`
  background-color: ${props => props.theme.HCErr};
  color: white;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MessagingConversationList = props => {
  const {
    conversationList = [],
    contactId,
    conversationLoadNext,
    conversationMore,
    contactPhone
  } = props;

  const [conversationListLoading, setConversationListLoading] =
    React.useState(true);
  const [conversations, setConversations] = React.useState([]);

  React.useEffect(() => {
    if (conversationList.length > 0) {
      const conversationsUnread = conversationList.map(convo => {
        const {
          message = {},
          contact: { firstName = "" },
          unread
        } = convo;
        const lastInteraction = formatDate(convo.message.dateCreated);
        const { media = {} } = message;
        const contentTypes = media && media.mineType ? [media.mimeType] : [];
        const recentMsgDisplay = formatBody(
          message.body,
          message.direction,
          firstName,
          contentTypes,
          unread
        );
        return {
          ...convo,
          contactPhone:
            message.direction === "outbound-api" ? message.to : message.from,
          lastInteractionFormatted: lastInteraction,
          recentMsgDisplay,
          unreadTotal: unread
        };
      });
      setConversations(conversationsUnread);
      setConversationListLoading(false);
    } else {
      setConversationListLoading(false);
    }
  }, [conversationList]);

  const formatDate = date => {
    const now = new Date();
    const today = now.getDate();
    const nowMonth = now.getMonth();
    const lastInteractionDate = new Date(date);
    const interactionDate = lastInteractionDate.getDate();
    const interactionMon = lastInteractionDate.getMonth();
    let dateFormatted = "";
    if (interactionDate !== today || interactionMon !== nowMonth) {
      dateFormatted = `${monthsAbr[interactionMon]} ${interactionDate}`;
    } else {
      let hours = lastInteractionDate.getHours();
      let dayPart = "AM";
      if (hours > 12) {
        hours = hours - 12;
        dayPart = "PM";
      }
      // add 0 if the minute is a single digit.
      const minutes = `${lastInteractionDate.getMinutes()}`.padStart(2, "0");
      dateFormatted = `${hours}:${minutes} ${dayPart}`;
    }
    return dateFormatted;
  };

  const formatBody = (
    body,
    direction,
    firstName,
    contentTypes,
    unreadTotal = 0
  ) => {
    let displayText = "";
    const maxTextCharLength = 37;
    if (body) {
      const trimmedText = body.trim();
      let maxLength = maxTextCharLength;
      maxLength = direction !== "inbound" ? maxLength - 6 : maxLength;
      maxLength = unreadTotal > 0 ? maxLength - 5 : maxLength;
      displayText = trimmedText.substring(0, maxLength);
      // If the difference is too small, just show the whole string
      const textLengthDiff = trimmedText.length - displayText.length;
      if (textLengthDiff <= 5) {
        displayText = trimmedText;
      } else {
        // Sometimes the last character is a comma or period. If that is the case, don't show it.
        displayText = `${displayText}...`;
      }
    } else if (contentTypes) {
      const type = contentTypes.includes("image")
        ? "an image"
        : contentTypes.includes("video")
        ? "a video"
        : "media";
      if (direction === "inbound") {
        if (firstName) {
          displayText = `${firstName} sent ${type}`;
        }
      } else {
        displayText = `sent ${type}`;
      }
    }
    return displayText;
  };

  return props.conversationListLoading || conversationListLoading ? (
    <ContactLoading>
      <LoaderDotsSmall message={false} />
    </ContactLoading>
  ) : conversations.length > 0 ? (
    <div>
      {conversations.map((thread, key) => (
        <ThreadItem
          key={thread.contact._id ? thread.contact._id : thread.message._id}
          onClick={() =>
            props.loadNewContact(
              thread.contact._id ? thread.contact._id : "",
              thread.contactPhone
            )
          }
          className={
            (!contactId ||
              (!thread.contact._id && thread.contact._id === contactId)) &&
            contactPhone === thread.contactPhone
              ? "active"
              : "inactive"
          }
          active={
            (!contactId ||
              (thread.contact._id && thread.contact._id === contactId)) &&
            contactPhone === thread.contactPhone
          }
        >
          <ThreadHeader>
            <InitialWrapper>
              {thread.contact ? (
                <ContactInitials active={thread.contact._id === contactId}>
                  {getInitials(thread.contact)}
                </ContactInitials>
              ) : (
                <InitialPlaceholder
                  active={thread.contact._id === contactId}
                  className='material-icons'
                >
                  account_circle
                </InitialPlaceholder>
              )}
            </InitialWrapper>
            <ThreadName>
              {thread.contact ? (
                <NameBox>
                  <span>{`${thread.contact.firstName} ${thread.contact.lastName}`}</span>
                  <NumDescription>
                    {thread.contactPhone === thread.contact.mobilePhone
                      ? "mobile"
                      : thread.contactPhone === thread.contact.phone
                      ? "phone"
                      : thread.contactPhone}
                  </NumDescription>
                </NameBox>
              ) : (
                <span>{thread.contactPhone}</span>
              )}
            </ThreadName>
            <ThreadDate>{thread.lastInteractionFormatted}</ThreadDate>
          </ThreadHeader>
          <ThreadBody threadUnread={thread.unreadTotal > 0 ? true : false}>
            <ThreadText>
              {thread.message.direction !== "inbound" && "You: "}
              {thread.recentMsgDisplay}
            </ThreadText>
            {thread.unreadTotal > 0 && (
              <div>
                <ThreadUnread>{thread.unreadTotal}</ThreadUnread>
              </div>
            )}
          </ThreadBody>
        </ThreadItem>
      ))}
      {!!conversationMore && (
        <Button onClick={conversationLoadNext}>Load More</Button>
      )}
    </div>
  ) : (
    <p>No conversations</p>
  );
};

export default MessagingConversationList;
