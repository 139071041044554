/* global cloudinary */

import React from "react";
import styled from "styled-components";
import LoadingBar from "../elements/LoadingBar";
import EditMobileMenu from "../styled/EditMobileMenu";
import ImageWrapper from "../styled/ImageWrapper";
import { assetAdd, cloudinaryFormat } from "../../utils/MediaAPI";
import { Button } from "../styled/Buttons";

const ModalFooterWrapper = styled.div`
  border-top: 1px solid lightgrey;
  margin: 0 1.5em;
  @media screen and (max-height: 580px) {
    display: flex;
    justify-content: space-around;
  }
`;

const SelectedImagesContainer = styled.div`
  height: 7em;
`;

const SelectedImagesWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6em;
  margin: 0px 1.5em;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  right: 50%;
  margin-right: -2.5em;
  bottom: 0;
  height: 4em;
  width: 5em;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-height: 580px) {
    position: static;
    margin: 20px 0;
    width: auto;
  }
`;

const SelectedImage = styled.img`
  height: 3em;
  margin: 0.3em;
  cursor: pointer;
`;

const ClearButton = styled.button`
  color: red;
`;

const ImageModalFooter = props => {
  const { selectedImages } = props;

  return (
    <ModalFooterWrapper>
      <SelectedImagesContainer>
        <small>
          {selectedImages.length > 0
            ? selectedImages.length + " Selected Images"
            : "No Images Selected. Click an image to select it."}
          {selectedImages.length > 0 && (
            <ClearButton onClick={props.handleClearSelected}>clear</ClearButton>
          )}
        </small>
        <SelectedImagesWrapper>
          {selectedImages.length > 0 &&
            selectedImages.map(image => (
              <SelectedImage
                key={image.name ? image.name : image.public_id}
                id={image.name}
                src={cloudinaryFormat({
                  filename: image.name ? image.name : image.public_id,
                  format: image.format,
                  height: 48,
                  width: 48,
                  mode: "fill"
                })}
                alt={image.name}
              />
            ))}
        </SelectedImagesWrapper>
      </SelectedImagesContainer>
      <ButtonWrapper>
        <Button id='cancelBtn' type='button' onClick={props.closeImagesModal}>
          cancel
        </Button>
        <Button
          solid
          type='button'
          onClick={props.selectedSubmit}
          id='image-submit'
        >
          submit
        </Button>
      </ButtonWrapper>
    </ModalFooterWrapper>
  );
};

export default ImageModalFooter;
