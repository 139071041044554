import React from "react";
import styled, { keyframes } from "styled-components";

const Keyframe = keyframes`
  0%, 20%, 80%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
}
`;
const FixedDiv = styled.div`
  top: 0;
  z-index: 10000;
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-color: rgba(250, 250, 250, 0.75);
`;
const ParentDiv = styled.div`
  position: absolute;
  right: 50vw;
  top: 40vh;
  transform: translate(50%, 0);
  width: 64px;
  height: 64px;
`;
const ChildDiv = styled.div`
  position: absolute;
  width: 5px;
  height: 5px;
  background: #383838;
  border-radius: 50%;
  animation: ${Keyframe} 1.2s linear infinite;

  &:nth-child(1) {
    animation-delay: 0s;
    top: 29px;
    left: 53px;
  }
  &:nth-child(2) {
    animation-delay: -0.1s;
    top: 18px;
    left: 50px;
  }
  &:nth-child(3) {
    animation-delay: -0.2s;
    top: 9px;
    left: 41px;
  }
  &:nth-child(4) {
    animation-delay: -0.3s;
    top: 6px;
    left: 29px;
  }
  &:nth-child(5) {
    animation-delay: -0.4s;
    top: 9px;
    left: 18px;
  }
  &:nth-child(6) {
    animation-delay: -0.5s;
    top: 18px;
    left: 9px;
  }
  &:nth-child(7) {
    animation-delay: -0.6s;
    top: 29px;
    left: 6px;
  }
  &:nth-child(8) {
    animation-delay: -0.7s;
    top: 41px;
    left: 9px;
  }
  &:nth-child(9) {
    animation-delay: -0.8s;
    top: 50px;
    left: 18px;
  }
  &:nth-child(10) {
    animation-delay: -0.9s;
    top: 53px;
    left: 29px;
  }
  &:nth-child(11) {
    animation-delay: -1s;
    top: 50px;
    left: 41px;
  }
  &:nth-child(12) {
    animation-delay: -1.1s;
    top: 41px;
    left: 50px;
  }
`;
const Message = styled.span`
  position: absolute;
  background-color: rgba(250, 250, 250);
  padding: 0.5em;
  font-weight: 600;
  font-style: italic;
  width: 30em;
  max-width: 90%;
  right: 50vw;
  top: 50vh;
  transform: translate(50%, 0);
`;

class FullScreenWaiting extends React.Component {
  render() {
    const { message } = this.props;
    return (
      <FixedDiv>
        <ParentDiv>
          <ChildDiv />
          <ChildDiv />
          <ChildDiv />
          <ChildDiv />
          <ChildDiv />
          <ChildDiv />
          <ChildDiv />
          <ChildDiv />
          <ChildDiv />
          <ChildDiv />
          <ChildDiv />
          <ChildDiv />
        </ParentDiv>
        <Message>{message}</Message>
      </FixedDiv>
    );
  }
}

export default FullScreenWaiting;
