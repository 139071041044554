import styled from "styled-components";

export const DashboardSectionWrapper = styled.div`
  margin: 2em 3em;
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2em;

  @media screen and (max-width: 804px) {
    margin: 1em;
    grid-template-columns: 100%;
  }
`;

export const Section = styled.div`
  border-radius: 8px;
  width: 100%;
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
    0 1px 3px 1px rgba(60, 64, 67, 0.16);
  position: relative;
  @media screen and (min-width: 805px) {
    height: 27vh;
  }
  @media screen and (max-height: 950px) {
    height: auto;
    min-height: 27vw;
  }
  @media screen and (min-width: 1300px) {
    height: 33vh;
  }
  .line-chart {
    height: 70%;
    @media screen and (min-width: 805px) {
      position: absolute;
      bottom: 0;
      height: 80%;
    }
    @media screen and (max-height: 550px) {
      height: 70%;
    }
  }
  .not-enough-data {
    max-width: 95%;
  }
`;

export const DashboardModuleTitle = styled.div`
  text-align: left;
  margin: 16px 20px;

  h6 {
    margin: 0;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.15em;
  }
`;

export const Icon = styled.i`
  position: relative;
  top: 2px;
  font-size: 1em;
  color: lightgray;
  &:hover {
    color: darkgray;
  }
`;

export const Overlay = styled.div`
  /* background-color: rgba(0, 0, 0, 0.03); */
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1005;
`;

export const ChartTitleWrapper = styled.div`
  display: flex;
  flex-direction: ${props => (props.mobileStacking ? "column" : "row")};
  align-items: baseline;
  margin: 0.3em 0;
  @media only screen and (min-width: 901px) {
    flex-direction: row;
  }
`;

export const ChartTitle = styled.h2`
  display: flex;
  align-items: baseline;
  margin: 0.3em 0;
`;

export const ChartTitleNumber = styled.div`
  margin-right: 1em;
  font-weight: 600;
`;

export const ChartTitleChange = styled.div`
  font-size: 13px;
`;

export const ChartDataWrapper = styled.div`
  display: grid;
  grid-template-columns: ${props =>
    props.mobileStacking ? "1fr" : "1fr 53px"};
  @media only screen and (min-width: 901px) {
    grid-template-columns: 1fr 53px;
  }
`;

export const ChartSummary = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  margin-bottom: 10px;
`;

export const DataDirection = styled.div`
  background: ${props =>
    props.negative
      ? props.theme.HCErr2Transparent
      : props.neutral
      ? "rgba(101,101,101,.1)"
      : props.theme.HCSucTransparent};
  border-radius: 10px;
  width: 53px;
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  padding-bottom: 4px;
  margin: 0 auto;
  i {
    color: ${props =>
      props.negative
        ? props.theme.HCErr2
        : props.neutral
        ? props.theme.HCLit
        : props.theme.HCSuc};
  }
`;

export const floaterStyles = {
  wrapper: {
    cursor: "pointer"
  },
  container: {
    borderRadius: 5,
    padding: 15,
    textAlign: "center"
  }
};
