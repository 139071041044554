import styled from "styled-components";

export const Card = styled.div`
  background-color: ${props => props.theme.BGC};
  margin: 2.5em;
  padding: 0 0 45px;
  box-shadow: 0px 0px 16px #00000015;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1060px) {
    display: flex;
    margin: 2.5em 0.5em;
  }
  p {
    max-width: 768px;
    margin: 0 auto 20px;
    text-align: left;
  }
  ul {
    list-style: disc;
    list-style-position: inside;
    margin-top: 20px;
  }
`;

export const TitleWrapper = styled.div`
  margin: 0;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media only screen and (min-width: 901px) {
    padding: 2.5em;
  }
`;

export const TitleLeft = styled.div`
  display: flex;
  flex-direction: ${props => (props.mobileStacking ? "column" : "row")};
  justify-content: flex-start;
  align-items: center;
  flex-grow: 2;
  width: 100%;
  @media only screen and (min-width: 901px) {
    flex-direction: row;
    width: auto;
  }
`;

export const TitleRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
`;

export const CardTitle = styled.div`
  text-align: center;
  margin: 0 auto;
  padding-top: 0;
  max-width: 700px;
`;

export const HeaderImg = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  /* margin-top: 2em; */
  img {
    border-radius: 10px;
    max-width: 80px;
    max-height: 80px;
  }
  .material-icons {
    font-size: 5em;
  }
`;
