import React from "react";
import styled from "styled-components";

const FbPage = styled.div`
  padding: 1px;
`;

const FbSelect = styled.button`
  background: ${props => (props.selected ? "#e0e0e0" : "white")};
  opacity: ${props => (!props.manage ? ".5" : "1")};
  cursor: ${props => (props.manage ? "pointer" : "auto")};
  display: flex;
  /* flex-flow: column; */
  justify-content: left;
  align-items: center;
  width: 100%;
  margin: 7px auto;
  padding: 10px 0;
  min-height: 68px;
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
    0 1px 3px 1px rgba(60, 64, 67, 0.16);
  transition: all 0.1s ease-in-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
  transform: translateZ(0);
  color: rgb(56, 56, 56);
  font-weight: 100;
  letter-spacing: 1px;
  font-size: 16px;

  :hover {
    transform: ${props => (props.manage ? "scale(1.02)" : "none")};
    transition: all 0.1s ease-in-out;
  }
  img {
    margin: 0 1em;
  }
  p {
    text-align: left;
  }
`;

const PageName = styled.p`
  display: grid;
`;

const PermissionMissing = styled.span`
  color: red;
  font-size: 0.8em;
`;

const PagesList = props => {
  const {
    pages,
    selectedFBPage
  } = props;

  const pageSelect = page => {
    const {tasks = []} = page;
    if (!tasks || !tasks.includes('MANAGE')) {
      props.alert({
        type: 'error',
        msg: 'You do not have the correct permissions to select this page.'
      });
      return;
    }
    if (!page.is_published) {
      props.alert({
        type: 'error',
        msg: 'This page is not published. Please publish the page and then try to connect again.'
      });
      return;
    }
    props.handleFBPageHighlight(page);
  }

  return pages.map(page => {
    const { url } = page.picture.data;
    const { tasks = [], is_published = false } = page;
    return (
      <FbPage key={page.id}>
        <FbSelect
          selected={selectedFBPage === page.id}
          manage={tasks.includes("MANAGE") && is_published}
          onClick={() => {
            pageSelect(page);
          }}
        >
          {/* <FbSelectOverlay /> */}
          <img src={url} alt='Facebook Page' />
          <PageName>
            <span>
              {page.name}, {page.category}
            </span>
            {!tasks.includes("MANAGE") && (
              <PermissionMissing>
                Admin Permissions Required
              </PermissionMissing>
            )}
            {!page.is_published && (
              <PermissionMissing>
                Page is Unpublished
              </PermissionMissing>
            )}
          </PageName>
        </FbSelect>
      </FbPage>
    );
  })
};

export default PagesList;
