import React from "react";
import styled, { css } from "styled-components";

const MenuItemWrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  border-radius: 10px;
  padding: 7px;
  margin-left: -7px;
  border-radius: 10px;
  position: relative;
  :hover {
    background-color: #2c097b;
  }
  ${({ isMobile }) =>
    !isMobile &&
    css`
      width: ${props => (props.menuOpen ? "100%" : "45px")};
    `}
`;

const MenuText = styled.div`
  color: #ffffff;
  text-align: left;
  margin-left: 20px;
  font: normal normal 500 16px Poppins, sans-serif;
  width: ${props => (props.menuOpen ? "100%" : "0px")};
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  opacity: ${props => (props.menuOpen ? "1" : "0")};
  white-space: nowrap;
`;

const MenuIcon = styled.span`
  color: #64edc2;
  font-size: 30px;
  margin: 10px 0;
`;

const UnreadBubble = styled.span`
  background-color: ${props => props.theme.HCErr2};
  width: 18px;
  height: 18px;
  position: absolute;
  display: block;
  border-radius: 50%;
  left: 28px;
  top: 2px;
`;

const MainMenuItems = props => {
  const { menuOpen, handleMenuLink, isMobile, unreadMessages } = props;

  return (
    <div>
      <MenuItemWrapper
        onClick={() => handleMenuLink("/")}
        menuOpen={menuOpen && "menuOpen"}
        isMobile={isMobile}
      >
        <MenuIcon
          className='material-icons'
          title='Dashboard'
        >
          assessment
        </MenuIcon>
        <MenuText menuOpen={menuOpen && "menuOpen"}>Dashboard</MenuText>
      </MenuItemWrapper>
      <MenuItemWrapper
        onClick={() => handleMenuLink("/campaign-select")}
        menuOpen={menuOpen && "menuOpen"}
        isMobile={isMobile}
      >
        <MenuIcon
          className='material-icons'
          title='New Campaign'
        >
          add_circle_outline
        </MenuIcon>
        <MenuText menuOpen={menuOpen && "menuOpen"}>New Campaign</MenuText>
      </MenuItemWrapper>
      <MenuItemWrapper
        onClick={() => handleMenuLink("/campaign-history")}
        menuOpen={menuOpen && "menuOpen"}
        isMobile={isMobile}
      >
        <MenuIcon
          className='material-icons'
          title='Campaign History'
        >
          trending_up
        </MenuIcon>
        <MenuText menuOpen={menuOpen && "menuOpen"}>Campaign History</MenuText>
      </MenuItemWrapper>
      <MenuItemWrapper
        onClick={() => handleMenuLink("/contacts")}
        menuOpen={menuOpen && "menuOpen"}
        isMobile={isMobile}
      >
        <MenuIcon
          className='material-icons'
          title='Contacts'
        >
          contacts
        </MenuIcon>
        <MenuText menuOpen={menuOpen && "menuOpen"}>Contacts</MenuText>
      </MenuItemWrapper>
      <MenuItemWrapper
        onClick={() => handleMenuLink("/messages")}
        menuOpen={menuOpen && "menuOpen"}
        isMobile={isMobile}
      >
        <MenuIcon
          className='material-icons'
          title='Messaging'
        >
          chat
        </MenuIcon>
        {unreadMessages > 0 && (
          <UnreadBubble title='You have unread messages' />
        )}
        <MenuText menuOpen={menuOpen && "menuOpen"}>Messaging</MenuText>
      </MenuItemWrapper>
      <MenuItemWrapper
        onClick={() => handleMenuLink("/workflows")}
        menuOpen={menuOpen && "menuOpen"}
        isMobile={isMobile}
        id='tour-workflowMenu'
      >
        <MenuIcon
          className='material-icons'
          title='Workflows'
        >
          account_tree
        </MenuIcon>
        <MenuText menuOpen={menuOpen && "menuOpen"}>Workflows</MenuText>
      </MenuItemWrapper>
      <MenuItemWrapper
        onClick={() => handleMenuLink("/integrations")}
        menuOpen={menuOpen && "menuOpen"}
        isMobile={isMobile}
      >
        <MenuIcon
          className='material-icons'
          title='Integrations'
        >
          compare_arrows
        </MenuIcon>
        <MenuText menuOpen={menuOpen && "menuOpen"}>Integrations</MenuText>
      </MenuItemWrapper>
    </div>
  );
};

export default MainMenuItems;
