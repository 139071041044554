import React from "react";
import * as _ from "underscore";
import {
  contactsGet,
  contactUpdate,
  statusDelete
} from "../../../../utils/ContactAPI";
import BpModal from "../../../elements/BpModal";
import { Button } from "../../../styled/Buttons";
import InputError from "../../../styled/InputErrorForm";
import StatusReassign from "./statusReassign";

const StatusRevert = props => {
  const { contactStatuses, defaultStatuses } = props;

  const [statusMigrate, setStatusMigrate] = React.useState({});
  const [modalStatusReassign, setModalStatusReassign] = React.useState(false);
  const [modalConfirm, setModalConfirm] = React.useState(false);
  const [confirmed, setConfirmed] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const handleRevertStatuses = async () => {
    let newStatusMigrate = { ...statusMigrate };
    if (_.isEmpty(statusMigrate) && !modalStatusReassign) {
      contactStatuses.forEach(status => {
        const defaultStatus = defaultStatuses.find(
          dStatus =>
            dStatus.status.toLowerCase() === status.status.toLowerCase()
        );
        newStatusMigrate = {
          ...statusMigrate,
          [status._id]: defaultStatus ? defaultStatus : {}
        };
      });
      setStatusMigrate(newStatusMigrate);
      setModalStatusReassign(true);
    } else {
      let statusEmpty = false;
      _.mapObject(statusMigrate, async status => {
        if (_.isEmpty(status)) {
          statusEmpty = true;
          setError("Please assign a new status to each old status");
          return;
        }
      });
      if (!statusEmpty) {
        if (!confirmed) {
          setModalConfirm(true);
          setModalStatusReassign(false);
          return;
        }
        try {
          setLoading(true);
          const wait = [];
          _.mapObject(statusMigrate, async (status, oldStatus) => {
            const contactsMap = await contactsGet({ statusId: oldStatus });
            if (contactsMap.length > 0) {
              const statusMismatch = contactsMap.some(
                c => c.statusId !== oldStatus
              );
              if (statusMismatch) {
                console.log(
                  "a status was returned that doesnt match the status we are deleting"
                );
                setLoading(false);
                return;
              }
              contactsMap.forEach(contact => {
                if (contact.statusId === oldStatus) {
                  wait.push(
                    contactUpdate({ ...contact, statusId: status._id })
                  );
                }
              });
            }
          });
          contactStatuses.forEach(status => {
            wait.push(statusDelete(status._id));
          });
          await Promise.all(wait);
          setModalStatusReassign(false);
          setLoading(false);
          setStatusMigrate({});
        } catch (err) {
          console.log("error deleting custom statuses", err);
        }
      }
    }
  };

  return (
    <div>
      <Button onClick={handleRevertStatuses}>Revert to Default Statuses</Button>
      <BpModal
        open={modalStatusReassign}
        title={`Reassign your custom statuses to default statuses`}
        primaryAction={{
          btnLabel: "Save",
          action: handleRevertStatuses,
          loading: loading
        }}
        secondaryAction={{
          btnLabel: "Cancel",
          action: () => {
            setModalConfirm(false);
            setModalStatusReassign(false);
          },
          loading: loading
        }}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        contentLabel='Reassign your custom statuses to default statuses'
        body={
          <div>
            <p>
              You are about to revert back to custom statuses. Please choose the
              default statuses you want to migrate your leads to.
            </p>
            <div>
              <StatusReassign
                defaultStatuses={defaultStatuses}
                contactStatuses={contactStatuses}
                statusMigrate={statusMigrate}
                handleRevertStatusMatch={setStatusMigrate}
              />
              <InputError>{error}</InputError>
            </div>
          </div>
        }
        width='auto'
      />
      <BpModal
        open={modalConfirm}
        title={`Reassign your custom statuses to default statuses`}
        primaryAction={{
          btnLabel: "Continue",
          action: () => {
            setConfirmed(true);
            handleRevertStatuses();
          },
          loading: loading
        }}
        secondaryAction={{
          btnLabel: "Cancel",
          action: () => {
            setModalConfirm(false);
            setModalStatusReassign(false);
            setStatusMigrate({});
          },
          loading: loading
        }}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        contentLabel='Reassign your custom statuses to default statuses'
        body={
          <div>
            <p>Are you sure you want to revert your custom statuses?</p>
            <p>
              Your contacts will be updated to use the default contact statuses.
            </p>
          </div>
        }
        width='auto'
      />
    </div>
  );
};

export default StatusRevert;
