import "react-dates/initialize";
import React from "react";
import moment from "moment-timezone";
import styled from "styled-components";
import {
  getAllCompanyCampaigns,
  adsGetInsights
} from "../../../utils/FacebookAPI";
import { contactsGet } from "../../../utils/ContactAPI";
import DateRangePickerWrapper from "../../elements/PresetDateRangePicker";
import "react-dates/lib/css/_datepicker.css";
import { handleFloaterClick } from "../../../utils/Helpers";
import { withTheme } from "styled-components";
// Styled Components
import { Button } from "../../styled/Buttons";
import { TitleLeft, TitleRight, TitleWrapper } from "../../styled/Layout";
import LeadsGraph from "./LeadsGraph";
import AdSpendGraph from "./AdSpendGraph";
import CostPerLead from "./CostPerLead";
import StatusGraph from "./StatusGraph";
import LinkClicks from "./LinkClicks";
import Impressions from "./Impressions";
import Reach from "./Reach";
import Frequency from "./Frequency";
import { DashboardSectionWrapper } from "./DashboardStyles";
import { H1 } from "../workflows/styles";

const SectionGroupFirst = styled.div`
  margin-top: 0;
  @media screen and (min-width: 1820px) {
    margin-top: 0;
  }
  @media screen and (max-width: 600px) {
    margin-top: 0;
  }
  @media screen and (max-width: 450px) {
    margin-top: 0;
  }
  background-color: ${props => props.theme.BGC};
`;

const DashboardTitle = styled(H1)`
  margin-right: 30px;
  padding: 7px 30px 7px 0;
  @media only screen and (min-width: 901px) {
    border-right: 1px solid ${props => props.theme.HC1};
  }
`;

const DateTitle = styled.h2`
  font-size: 16px;
  margin-right: 10px;
  color: ${props => props.theme.HC1};
`;

const DateSelector = styled.div`
  background-color: ${props => props.theme.BGC};
  display: flex;
  margin: 0.5em 0 12px;
  .DateRangePicker {
    box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
      0 1px 3px 1px rgba(60, 64, 67, 0.16);
  }
  > .dateRangePicker {
    box-shadow: none;
    display: block;
  }
  @media screen and (max-width: 599px) {
    margin-left: 0;
    justify-content: center;
  }
`;

const ViewAllCampaignsWrapper = styled.div`
  position: relative;
  top: -7px;
  @media screen and (max-width: 600px) {
    top: auto;
    button {
      margin: 1.2rem 1rem;
    }
  }
  button {
    display: none;
  }
  @media only screen and (min-width: 901px) {
    button {
      display: block;
    }
  }
`;

const MobileMore = styled.div`
  position: relative;
  @media only screen and (min-width: 901px) {
    display: none;
  }
`;

const MoreDropdown = styled.div`
  position: absolute;
  min-width: 190px;
  right: 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 4px 22px 54px #f0eff4;
  border-radius: 19px;
  border: 1px solid #e0e0e0;
  z-index: 1006;
`;

const MoreItem = styled.p`
  padding: 17px 20px;
  display: block;
  margin: 0;
`;

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.03);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1005;
  cursor: pointer;
`;

const presets = {
  today: {
    text: "Today",
    start: moment().startOf("day"),
    end: moment().startOf("day")
  },
  yesterday: {
    text: "Yesterday",
    start: moment().startOf("day").subtract(1, "days"),
    end: moment().startOf("day").subtract(1, "days")
  },
  last7days: {
    text: "Last 7 Days",
    start: moment().startOf("day").subtract(6, "days"),
    end: moment().startOf("day")
  },
  last30days: {
    text: "Last 30 Days",
    start: moment().startOf("day").subtract(29, "days"),
    end: moment().startOf("day")
  },
  thisMonth: {
    text: "This Month",
    start: moment().startOf("month"),
    end: moment().endOf("month")
  },
  lastMonth: {
    text: "Last Month",
    start: moment().subtract(1, "month").startOf("month"),
    end: moment().subtract(1, "month").endOf("month")
  }
};

class Dashboard extends React.Component {
  state = {
    startDate: null,
    endDate: null,
    preset: null,
    focusedInput: null,
    chartsLoading: false,
    chartsData: {
      currentAdAccount: {},
      prevAdAccount: {},
      currentIncAdAccount: [],
      prevIncAdAccount: [],
      recentCampaigns: []
    },
    leadsChartData: {
      labelValue: [],
      dataValue: [],
      prevLabelValue: [],
      prevDataValue: []
    },
    leadsWaiting: { current: false, prev: false },
    fbCampaignHistory: [],
    campaignSelectedName: null,
    campaignSelectedID: null,
    floaters: {
      impressions: false,
      linkClicks: false,
      reach: false,
      frequency: false,
      leads: false,
      adSpend: false,
      costPerLead: false
    },
    campaignsLoading: false,
    contactStatusData: [],
    contactStatusLabels: [],
    contactStatusDataPrev: [],
    contactStatusCount: 0,
    showMore: false
  };

  componentDidMount = () => {
    const { settings: { dashboardDateRange = "" } = {} } =
      this.props.userProfile;
    this.setState({ campaignsLoading: true }, async () => {
      //TODO: Change this call to get only non-draft campaigns;
      const allCampaigns = await getAllCompanyCampaigns();
      const campaignHistory = allCampaigns.filter(
        campaign => campaign.status !== "DRAFT"
      );
      this.setState({
        fbCampaignHistory: campaignHistory,
        campaignsLoading: false
      });
      this.props.checkPermissions();
    });
    if (dashboardDateRange) {
      const { startDate: start, endDate: end } = dashboardDateRange;
      const startMoment = moment(start).startOf("day");
      const endMoment = moment(end).endOf("day");
      const daysDiff = endMoment.diff(startMoment, "days");
      const endDate = moment(new Date());
      const startDate = endDate.clone().subtract(daysDiff, "days");
      this.setState(
        {
          chartsLoading: true,
          startDate,
          endDate
        },
        () => this.loadData()
      );
    } else {
      this.setState(
        {
          chartsLoading: true,
          startDate: presets.last7days.start,
          endDate: presets.last7days.end
        },
        () => this.loadData()
      );
    }
  };

  async componentDidUpdate(prevProps) {
    const { businessPage = {} } = this.props;
    const { businessPage: prevBusinessPage = {} } = prevProps;
    if (businessPage !== prevBusinessPage) {
      this.loadData();
    }
  }

  onClose = (start, end) => {
    const startUTC = start.tz("Etc/UTC");
    const endUTC = end.tz("Etc/UTC");
    const { token, isValid } = this.props.platformToken;
    const { alert } = this.props;
    if (!token || !isValid) {
      console.error(
        "Please link your facebook account through the My Settings page"
      );
      alert({
        type: "error",
        msg: "Please link your facebook account through the Settings page"
      });
    } else {
      const { preset } = this.state;
      this.setState(
        {
          chartsLoading: true,
          startDate: startUTC,
          endDate: endUTC
        },
        () => this.loadData()
      );
      const { settings = {} } = this.props.userProfile;
      const newSettings = {
        settings: {
          ...settings,
          dashboardDateRange: { startDate: startUTC, endDate: endUTC, preset }
        }
      };
      this.props.updateUserDoc(newSettings);
    }
  };

  loadData = async () => {
    const { adAccountId } = this.props.businessPage;
    const { startDate: start, endDate: end } = this.state;
    const startMoment = moment(start);
    const endMoment = moment(end);
    const daysDiff = endMoment.diff(startMoment, "days");
    const startDate = startMoment.format("YYYY-MM-DD");
    const endDate = endMoment.format("YYYY-MM-DD");
    const prevEndDate = startMoment.subtract(1, "days").format("YYYY-MM-DD");
    const prevStartDate = startMoment
      .subtract(daysDiff, "days")
      .format("YYYY-MM-DD");
    await this.getLeadsData(moment(start), moment(end));
    if (adAccountId) {
      const insightParams = {
        adAccountId,
        startDate,
        endDate,
        prevStartDate,
        prevEndDate,
        limit: (daysDiff + 5).toString()
      };
      try {
        const adsInsights = await adsGetInsights(insightParams);
        this.setState({
          chartsData: adsInsights,
          chartsLoading: false
        });
      } catch (e) {
        console.log("error loading data", e.message);
      }
    }
    // this.loadContactData();
  };

  getLeadsData = async (leadsStartMoment, leadsEndMoment) => {
    this.setState({ leadsWaiting: { current: true, prev: true } });
    const leadsData = {};
    const endDate = leadsStartMoment.clone();
    leadsEndMoment.endOf("day").tz("Etc/UTC");
    const daysDiff = leadsEndMoment.diff(leadsStartMoment, "days");
    endDate.add(daysDiff, "days").endOf("day").tz("Etc/UTC");
    const startAt = leadsStartMoment.clone().tz("Etc/UTC");
    const endAt = leadsStartMoment.clone().tz("Etc/UTC");
    endAt.endOf("day");
    const prevStartDate = leadsStartMoment
      .subtract(daysDiff + 1, "days")
      .tz("Etc/UTC");
    const prevEndDate = leadsStartMoment.clone().endOf("day").tz("Etc/UTC");
    const leads = await this.getLeads(
      prevStartDate.toISOString(),
      endDate.toISOString()
    );
    for (let day = 0; day < daysDiff + 1; day++) {
      if (day === 0) {
        startAt.startOf("day");
        endAt.endOf("day");
      } else {
        startAt.add(1, "days").startOf("day");
        endAt.add(1, "days").endOf("day");
      }
      const leadsFiltered = leads.filter(created =>
        created.isBetween(startAt, endAt, null, "[]")
      );
      leadsData[startAt.format("YYYY/MM/DD")] = leadsFiltered.length;
    }
    const labelValue = Object.keys(leadsData);
    const dataValue = Object.keys(leadsData).map(lead => leadsData[lead]);
    const leadCount = dataValue.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    this.setState({
      leadsChartData: {
        labelValue,
        dataValue,
        leadCount,
        prevLabelValue: [],
        prevDataValue: []
      },
      leadsWaiting: { current: false, prev: true }
    });
    const leadsDataPrev = [];
    for (let day = 0; day < daysDiff + 1; day++) {
      if (day === 0) {
        prevStartDate.startOf("day");
        prevEndDate.endOf("day");
      } else {
        prevStartDate.add(1, "days").startOf("day");
        prevEndDate.add(1, "days").endOf("day");
      }
      const leadsFiltered = leads.filter(created =>
        created.isBetween(prevStartDate, prevEndDate, null, "[]")
      );
      leadsDataPrev[prevStartDate.format("YYYY/MM/DD")] = leadsFiltered.length;
    }
    const prevLabelValue = Object.keys(leadsDataPrev);
    const prevDataValue = Object.keys(leadsDataPrev).map(
      lead => leadsDataPrev[lead]
    );
    const prevLeadCount = prevDataValue.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    const leadDiff = leadCount - prevLeadCount;
    let leadChange = 0;
    if (prevLeadCount === 0) {
      leadChange = Math.round(leadDiff * 100 * 100) / 100;
    } else {
      leadChange = Math.round((leadDiff / prevLeadCount) * 100 * 100) / 100;
    }
    this.setState({
      leadsChartData: {
        labelValue,
        dataValue,
        leadCount,
        prevLabelValue,
        prevDataValue,
        prevLeadCount,
        leadChange
      },
      leadsWaiting: { current: false, prev: false }
    });
  };

  getLeads = async (startAt, endAt) => {
    try {
      if (startAt && endAt) {
        const contacts = await contactsGet();
        const leads = [];
        if (contacts) {
          contacts.forEach(contact => {
            if (contact.source === "SOCIAL_MEDIA") {
              const created = moment(contact.created).tz("Etc/UTC");
              leads.push(created);
            }
          });
        }
        return leads;
      }
    } catch (err) {
      console.error(err.message);
      return [];
    }
  };

  handleFloaterClick = (e, clickTarget) => {
    e.preventDefault();
    const { floaters } = this.state;
    const newFloaters = handleFloaterClick(clickTarget, floaters);
    this.setState({ floaters: newFloaters });
  };

  handlePreset = range => {
    let preset = "";
    if (range) {
      switch (range.text) {
        case "Today":
          preset = "today";
          break;
        case "Yesterday":
          preset = "yesterday";
          break;
        case "Last 7 Days":
          preset = "last7days";
          break;
        case "Last 30 Days":
          preset = "last30days";
          break;
        case "This Month":
          preset = "thisMonth";
          break;
        case "Last Month":
          preset = "lastMonth";
          break;
        default:
          break;
      }
    }
    if (preset) {
      this.setState({ preset });
    }
  };

  toggleMore = () => {
    const { showMore } = this.state;
    this.setState({ showMore: !showMore });
  };

  render() {
    const {
      currentAdAccount,
      currentIncAdAccount,
      prevIncAdAccount,
      prevAdAccount
    } = this.state.chartsData;

    const { fbCampaignHistory, floaters } = this.state;

    // line chart incremental data formatting
    const currentIncDate = currentIncAdAccount.map(res => res.date_start);
    const prevIncDate = prevIncAdAccount.map(res => res.date_start);

    return (
      <SectionGroupFirst>
        <TitleWrapper>
          <TitleLeft mobileStacking={true}>
            <DashboardTitle>Marketing Campaigns</DashboardTitle>
            <DateTitle>Date Range</DateTitle>
            <DateSelector>
              <DateRangePickerWrapper
                onClose={(startDate, endDate) =>
                  this.onClose(startDate, endDate)
                }
                initStartDate={this.state.startDate}
                initEndDate={this.state.endDate}
                presetCallback={this.handlePreset}
              />
            </DateSelector>
          </TitleLeft>
          <TitleRight>
            {fbCampaignHistory && fbCampaignHistory.length > 0 && (
              <ViewAllCampaignsWrapper>
                <MobileMore>
                  <span className='material-icons' onClick={this.toggleMore}>
                    more_horiz
                  </span>
                  {!!this.state.showMore && (
                    <MoreDropdown>
                      <MoreItem>
                        <a href='/campaign-history'>Campaign History</a>
                      </MoreItem>
                    </MoreDropdown>
                  )}
                  {!!this.state.showMore && (
                    <Overlay onClick={this.toggleMore} />
                  )}
                </MobileMore>
                <Button
                  onClick={() => this.props.history.push("/campaign-history")}
                  disabled={this.state.campaignsLoading}
                >
                  Campaign History
                </Button>
              </ViewAllCampaignsWrapper>
            )}
          </TitleRight>
        </TitleWrapper>
        {this.state.startDate !== null && this.state.endDate !== null && (
          <DashboardSectionWrapper id='movable'>
            <LeadsGraph
              floaters={floaters}
              chartsLoading={this.state.chartsLoading}
              chartData={this.state.leadsChartData}
              leadsWaiting={this.state.leadsWaiting}
              handleFloaterClick={this.handleFloaterClick}
            />
            <AdSpendGraph
              floaters={floaters}
              chartsLoading={this.state.chartsLoading}
              currentAdAccount={currentAdAccount}
              currentIncDate={currentIncDate}
              adSpendData={currentIncAdAccount.map(
                res => parseFloat(res.spend) || 0
              )}
              prevIncDate={prevIncDate}
              prevAdSpendData={prevIncAdAccount.map(
                res => parseFloat(res.spend) || 0
              )}
              handleFloaterClick={this.handleFloaterClick}
            />
            <CostPerLead
              floaters={floaters}
              chartsLoading={this.state.chartsLoading}
              handleFloaterClick={this.handleFloaterClick}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              currentSpend={currentAdAccount.spend}
              prevSpend={prevAdAccount.spend}
            />
            <StatusGraph
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              industry={this.props.userCompany.industry}
              floaters={floaters}
              handleFloaterClick={this.handleFloaterClick}
            />
            <Impressions
              chartsLoading={this.state.chartsLoading}
              floaters={floaters}
              currentIncDate={currentIncDate}
              currentIncImpressions={currentIncAdAccount.map(
                res => res.impressions
              )}
              prevIncDate={prevIncDate}
              prevIncImpressions={prevIncAdAccount.map(res => res.impressions)}
              impressions={{
                current: currentAdAccount.impressions,
                prev: prevAdAccount.impressions
              }}
              theme={this.props.theme}
              handleFloaterClick={this.handleFloaterClick}
            />
            <LinkClicks
              chartsLoading={this.state.chartsLoading}
              floaters={floaters}
              handleFloaterClick={this.handleFloaterClick}
              linkClicks={{
                current: currentAdAccount.inline_link_clicks,
                prev: prevAdAccount.inline_link_clicks
              }}
              currentIncDate={currentIncDate}
              currentIncClicks={currentIncAdAccount.map(
                res => res.inline_link_clicks
              )}
              prevIncDate={prevIncDate}
              prevIncClicks={prevIncAdAccount.map(
                res => res.inline_link_clicks
              )}
              theme={this.props.theme}
            />
            <Reach
              chartsLoading={this.state.chartsLoading}
              floaters={floaters}
              handleFloaterClick={this.handleFloaterClick}
              currentIncDate={currentIncDate}
              currentIncReach={currentIncAdAccount.map(res => res.reach)}
              prevIncDate={prevIncDate}
              prevIncReach={prevIncAdAccount.map(res => res.reach)}
              reach={{
                current: currentAdAccount.reach,
                prev: prevAdAccount.reach
              }}
              theme={this.props.theme}
            />
            <Frequency
              chartsLoading={this.state.chartsLoading}
              floaters={floaters}
              handleFloaterClick={this.handleFloaterClick}
              currentAdAccount={currentAdAccount}
              currentIncDate={currentIncDate}
              currentIncFrequency={currentIncAdAccount.map(res =>
                parseFloat(res.frequency).toFixed(2)
              )}
              prevIncDate={prevIncDate}
              prevIncFrequency={prevIncAdAccount.map(res =>
                parseFloat(res.frequency).toFixed(2)
              )}
              frequency={{
                current: currentAdAccount.frequency,
                prev: prevAdAccount.frequency
              }}
              theme={this.props.theme}
            />
          </DashboardSectionWrapper>
        )}
      </SectionGroupFirst>
    );
  }
}

export default withTheme(Dashboard);
