import React from "react";
// import { useRouteMatch } from 'react-router-dom';
import * as _ from "underscore";
import styled from "styled-components";

import InputTag from "./InputTag";

import { InputLabel, Dropdown } from "../../styled/Input";
import { tagUpdate } from "../../../utils/ContactAPI";

const TagsWrapper = styled.div`
  display: flex;
  flex-direction: ${props => (props.bulkEdit ? "column-reverse" : "column")};
`;

const ContactTags = props => {
  const {
    contactTags = [],
    contact = {},
    companyTags = [],
    handleTagsChange,
    userProfile,
    companyId,
    contactId,
    showInModal,
    showInfo,
    bulkEdit
  } = props;

  const [tagSelected, setTagSelected] = React.useState("");
  const [tagsFiltered, setTagsFiltered] = React.useState([]);
  const [existingValueSelected, setExistingValueSelected] = React.useState("");

  React.useEffect(() => {
    if (contactTags.length < 1) {
      setTagsFiltered(companyTags);
    } else {
      const tagIds = contactTags.map(c => c._id);
      const newTagsFiltered = companyTags.filter(
        tag => !tagIds.includes(tag._id)
      );
      setTagsFiltered(newTagsFiltered);
    }
  }, [contactTags, companyTags]);

  const updateTags = async (action, tag) => {
    const { tagIds = [] } = contact;
    if (tag && contactId) {
      if (action === "add") {
        try {
          await handleTagsChange([...tagIds, tag._id]);
          const alertMsg = "Contact's tags was successfully updated";
          props.alert({ type: "success", msg: alertMsg });
          return true;
        } catch (err) {
          console.log("error saving field", err);
          props.alert({
            type: "error",
            msg: "Something went wrong saving the field. Please try again. If you continue to get this message, contact Boostpoint at help@boostpoint.com"
          });
          return false;
        }
      } else if (action === "remove") {
        const newTags = tagIds.filter(item => item !== tag._id);
        await handleTagsChange(newTags);
        const alertMsg = "Contact's tags was successfully updated";
        props.alert({ type: "success", msg: alertMsg });
        return true;
      }
    } else {
      handleTagsChange(tag, action);
    }
  };

  const handleColorChange = (color, tag) => {
    if (tag._id) {
      const throttleColor = _.debounce(async () => {
        await tagUpdate({ ...tag, color });
        props.getTags();
      }, 100);
      throttleColor();
    } else {
      console.log("tag id doesnt exist");
    }
  };

  const addExisting = async input => {
    const value = input.target.value;
    const { tagIds = [] } = contact;
    if (contactId) {
      handleTagsChange([...tagIds, value]);
    } else {
      const thisTag = companyTags.find(c => c._id === value);
      handleTagsChange(thisTag, "add");
    }
    setExistingValueSelected("");
  };

  return (
    <TagsWrapper className='tags-element' bulkEdit={bulkEdit}>
      {!!props.showLabel && <InputLabel>Contact Tags</InputLabel>}
      <Dropdown
        type='text'
        name='tag-dropdown'
        value={existingValueSelected}
        onChange={addExisting}
      >
        <option>Select Existing Tag</option>
        {tagsFiltered.length > 0 &&
          tagsFiltered.map((tag, index) => (
            <option key={index} value={tag._id}>
              {tag.name}
            </option>
          ))}
      </Dropdown>
      <InputTag
        alert={props.alert}
        tags={contactTags || []}
        updateTags={updateTags}
        handleColorChange={handleColorChange}
        userProfile={userProfile}
        showInfo={showInfo}
        companyId={companyId}
        showInModal={showInModal}
      />
    </TagsWrapper>
  );
};

export default ContactTags;
