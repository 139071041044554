import axios from "axios";
import { axiosResponse, errorHandler } from "./Helpers";

const BP_API = process.env.REACT_APP_BP_API;

export const creditsGetCompany = async () => {
  try {
    const credits = axiosResponse(
      await axios.get(`${BP_API}/v1/credit/company`)
    );
    return credits;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(`${status}: ${message}`);
  }
};

export const creditsCreate = async (companyId, config) => {
  const doc = {
    companyId,
    adBalance: 0,
    messageCredits: 0,
    messageFixedCredits: 0
  };
  try {
    if (config) {
      const credits = axiosResponse(
        await axios.post(`${BP_API}/v1/credit/create`, doc, config)
      );
      return credits;
    } else {
      const credits = axiosResponse(
        await axios.post(`${BP_API}/v1/credit/create`, doc)
      );
      return credits;
    }
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(`${status}: ${message}`);
  }
};

export const creditsUpdate = async creditDoc => {
  try {
    const credits = axiosResponse(
      await axios.put(`${BP_API}/v1/credit/update`, creditDoc)
    );
    return credits;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(`${status}: ${message}`);
  }
};

export const adCreditsCheck = async ({ adCreditsAmount }) => {
  try {
    if (!adCreditsAmount) {
      return {
        status: "amountMissing",
        success: false
      };
    }
    const adCredits = await creditsGetCompany();
    if (adCredits.adBalance >= adCreditsAmount) {
      return { status: "success", success: true };
    } else {
      return {
        status: "notEnoughCredits",
        creditsRequired: adCreditsAmount - adCredits.adBalance,
        campaignBudget: adCreditsAmount,
        success: false
      };
    }
  } catch (err) {
    console.log(err.message);
    throw new Error(err.message);
  }
};
