
import React from "react";
// import { useRouteMatch } from 'react-router-dom';
import "react-phone-number-input/style.css";
import styled from "styled-components";
// import PhoneInput from 'react-phone-number-input';
import Cleave from "cleave.js/react";
import 'cleave.js/dist/addons/cleave-phone.us'
import "react-phone-input-2/lib/style.css";
import ContactTags from "./ContactTags";
// eslint-disable-next-line no-unused-vars

// Styled Components
import { contactSource, emailValidator } from "../../../utils/Helpers";
import StatusDropdown from "./contactStatus/StatusDropdown";
import { Button, TxtButton } from "../../styled/Buttons";
import { ContactFieldset } from "../../styled/Contacts";
import {
  InputBox,
  InputBoxWrapper,
  InputContainer,
  InputLabel
} from "../../styled/Input";
import InputError from "../../styled/InputErrorForm";
import CardTitle from "../../styled/TitleCard";
import OtherInfo from "./view/OtherInfo";
import ButtonActions from "./view/ButtonActions";
import { set } from "react-ga";

const Title = styled.h2`
  margin: 25px auto;
  color: ${props => props.theme.HC1};
  font-weight: 600;
  font-size: 34px;
  @media only screen and (min-width: 901px) {
    margin: 90px auto 25px;
  }
`;

const ContactCard = styled.div`
  margin: 0;
  padding: 1em;
  @media only screen and (min-width: 901px) {
    background-color: ${props => props.theme.BGC};
  }
`;

const PhoneWrapper = styled.div`
  display: flex;
  select {
    display: inline-block;
    width: 80px;
    height: 32px;
  }
  input {
    display: inline-block;
    width: 100%;
    text-align: left;
  }
`;

const ButtonWrapper = styled.div`
  min-height: 68px;
  @media only screen and (min-width: 901px) {
    grid-column: 1/3;
  }
`;

const phoneCodes = {
  US: { name: "US", code: 1, length: 1 },
  CA: { name: "CA", code: 1, length: 1 },
  MX: { name: "MX", code: 52, length: 2 }
};

const ContactInfo = props => {
  const {
    contact = {},
    contactTags,
    contactStatuses = [],
    contactStatus,
    companyTags = [],
    userProfile,
    userCompany,
    alert
  } = props;
  const [errMessages, setErrMessages] = React.useState({});
  const [errFields, setErrFields] = React.useState([]);
  const [phoneRegion, setPhoneRegion] = React.useState("US");
  const [mobilePhoneRegion, setMobilePhoneRegion] = React.useState("US");
  const [infoEdit, setInfoEdit] = React.useState(false);
  const [addressEdit, setAddressEdit] = React.useState(false);
  const [loadingInfo, setLoadingInfo] = React.useState(false);
  const [infoSuccess, setInfoSuccess] = React.useState(false);
  const [loadingAddress, setLoadingAddress] = React.useState(false);
  const [addressSuccess, setAddressSuccess] = React.useState(false);
  const [contactInfo, setContactInfo] = React.useState({
    firstName: contact.firstName,
    lastName: contact.lastName,
    phone: contact.phone,
    email: contact.email,
    mobilePhone: contact.mobilePhone
  });
  const [addressInfo, setAddressInfo] = React.useState({
    address: contact.address,
    address2: contact.address2,
    city: contact.city,
    state: contact.state,
    zipCode: contact.zipCode,
    website: contact.website ? contact.website : ""
  });
  React.useEffect(() => {
    setContactInfo({
      firstName: contact.firstName,
      lastName: contact.lastName,
      phone: contact.phone,
      email: contact.email,
      mobilePhone: contact.mobilePhone
    });
    setAddressInfo({
      address: contact.address,
      address2: contact.address2,
      city: contact.city,
      state: contact.state,
      zipCode: contact.zipCode,
      website: contact.website ? contact.website : ""
    });
  }, [contact]);

  React.useEffect(() => {
    setTimeout(() => {
      setInfoSuccess(false);
    }, 5000 )
  }, [infoSuccess])

  React.useEffect(() => {
    setTimeout(() => {
      setAddressSuccess(false);
    }, 5000 )
  }, [addressSuccess])

  const handlePhoneChange = async input => {
    const { rawValue } = input.target;
    try {
      setInfoEdit(true);
      if (!rawValue) {
        setContactInfo({ ...contactInfo, phone: "" });
      } else if (
        phoneRegion &&
        phoneCodes[phoneRegion] &&
        !rawValue.startsWith(`+${phoneCodes[phoneRegion].code}`)
      ) {
        const newValue = `+${phoneCodes[phoneRegion].code}${rawValue}`;
        setContactInfo({ ...contactInfo, phone: newValue });
      } else {
        setContactInfo({ ...contactInfo, phone: rawValue });
      }
    } catch (err) {
      console.log("error saving field", err);
      alert({
        type: "error",
        msg: "Something went wrong saving the field. Please try again. If you continue to get this message, contact Boostpoint at help@boostpoint.com"
      });
      return false;
    }
  };

  const handleMobileChange = async input => {
    const { rawValue } = input.target;
    try {
      setInfoEdit(true);
      if (
        phoneRegion &&
        phoneCodes[phoneRegion] &&
        !rawValue.startsWith(`+${phoneCodes[phoneRegion].code}`)
      ) {
        const newValue = `+${phoneCodes[phoneRegion].code}${rawValue}`;
        setContactInfo({ ...contactInfo, mobilePhone: newValue });
      } else {
        setContactInfo({ ...contactInfo, mobilePhone: rawValue });
      }
    } catch (err) {
      console.log("error saving field", err);
      alert({
        type: "error",
        msg: "Something went wrong saving the field. Please try again. If you continue to get this message, contact Boostpoint at help@boostpoint.com"
      });
      return false;
    }
  };

  const handleInfoChange = async input => {
    const { value: newValue, name: targetName } = input.target;
    const value = newValue;
    try {
      setInfoEdit(true);
      setContactInfo({ ...contactInfo, [targetName]: value });
      props.contactChange(true);
    } catch (err) {
      console.log("error saving field", err);
      alert({
        type: "error",
        msg: "Something went wrong saving the field. Please try again. If you continue to get this message, contact Boostpoint at help@boostpoint.com"
      });
      return false;
    }
  };

  const handleInfoSave = async () => {
    try {
      setLoadingInfo(true);
      const newInfo = {};
      Object.keys(contactInfo).forEach(a => {
        newInfo[a] = contactInfo[a] ? contactInfo[a].trim() : contactInfo[a];
      });
      const newContact = { ...contact, ...newInfo };
      const valid = await validateFields(newContact);
      if (!valid) {
        return false;
      }
      await props.updateContact(newContact);
      setInfoEdit(false);
      setLoadingInfo(false);
      setInfoSuccess(true);
      setErrMessages({});
      setErrFields([]);
      props.contactChange(false);
    } catch (err) {
      console.log(err.message);
      alert({
        type: "error",
        msg: "Something went wrong saving the contact. Please try again. If you continue to get this message, contact Boostpoint at help@boostpoint.com"
      });
      return false;
    }
  };

  const handleInfoCancel = () => {
    setContactInfo({
      firstName: contact.firstName,
      lastName: contact.lastName,
      phone: contact.phone,
      email: contact.email,
      mobilePhone: contact.mobilePhone
    });
    setErrMessages({});
    setErrFields([]);
    setInfoEdit(false);
    props.contactChange(false);
  };

  const handleAddressChange = async input => {
    const { value: newValue, name: targetName } = input.target;
    const value = newValue;
    try {
      setAddressEdit(true);
      setAddressInfo({ ...addressInfo, [targetName]: value });
      props.contactChange(true);
    } catch (err) {
      console.log("error saving field", err);
      alert({
        type: "error",
        msg: "Something went wrong saving the field. Please try again. If you continue to get this message, contact Boostpoint at help@boostpoint.com"
      });
      return false;
    }
  };

  const handleAddressSave = async () => {
    try {
      setLoadingAddress(true);
      const newAddressInfo = {};
      Object.keys(addressInfo).forEach(a => {
        if (addressInfo[a]) {
          newAddressInfo[a] = addressInfo[a].trim();
        } else {
          newAddressInfo[a] = "";
        }
      });
      const newContact = { ...contact, ...newAddressInfo };
      const valid = await validateFields(newContact);
      if (!valid) {
        return false;
      }
      await props.updateContact(newContact);
      props.contactChange(false);
      setAddressEdit(false);
      setLoadingAddress(false);
      setAddressSuccess(true);
      setErrMessages({});
      setErrFields([]);
    } catch (err) {
      console.log(err.message);
      alert({
        type: "error",
        msg: "Something went wrong saving the contact. Please try again. If you continue to get this message, contact Boostpoint at help@boostpoint.com"
      });
      return false;
    }
  };

  const handleAddressCancel = () => {
    setAddressInfo({
      address: contact.address,
      address2: contact.address2,
      city: contact.city,
      state: contact.state,
      zipCode: contact.zipCode,
      website: contact.website ? contact.website : ""
    });
    setErrMessages({});
    setErrFields([]);
    setAddressEdit(false);
    props.contactChange(false);
  };

  const validateFields = newContact =>
    new Promise((resolve, reject) => {
      let fieldsValid = true;
      setErrMessages({});
      setErrFields([]);
      let errMessagesNew = {};
      let errFieldsNew = [];
      const requiredFields = {
        firstName: "a first name",
        lastName: "a last name",
        email: "an email"
      };
      Object.entries(newContact).forEach(([key, field]) => {
        if (requiredFields[key] && !field) {
          errMessagesNew = {
            ...errMessagesNew,
            [key]: `${requiredFields[key]} is required`
          };
          errFieldsNew = [...errFieldsNew, requiredFields[key]];
          fieldsValid = false;
        }
        if (key === "email") {
          const emailValid = emailValidator(field);
          if (!emailValid) {
            console.log("email is not valid");
            errMessagesNew = {
              ...errMessagesNew,
              [key]: `a valid email is required`
            };
            fieldsValid = false;
          }
        }
      });
      setErrMessages(errMessagesNew);
      setErrFields(errFields);
      if (errFieldsNew.length > 0) {
        let errFieldsString = "";
        if (errFieldsNew.length === 1) {
          errFieldsString = errFieldsNew[0];
        } else if (errFieldsNew.length === 2) {
          errFieldsString = errFields.join(" and ");
        } else {
          errFieldsNew[errFields.length - 1] =
            "and " + errFields[errFieldsNew.length - 1];
          errFieldsString = errFieldsNew.join(", ");
        }
        alert({
          type: "error",
          msg: `You are missing ${errFieldsString}`
        });
      }
      if (fieldsValid) {
        resolve(true);
      } else {
        resolve(false);
      }
    });

  return (
    <div className='cardSection__campaign'>
      <ContactCard>
        <CardTitle>
          <Title uppercase>Contact Info</Title>
        </CardTitle>
        <ContactFieldset>
          <InputContainer>
            <InputLabel>First Name</InputLabel>
            <InputBoxWrapper>
              <InputBox
                type='text'
                name='firstName'
                required
                data-namereadable='first name'
                value={contactInfo.firstName || ""}
                onChange={input => handleInfoChange(input)}
              />
            </InputBoxWrapper>
            <InputError>{errMessages.firstName}</InputError>
          </InputContainer>
          <InputContainer>
            <InputLabel>Last Name</InputLabel>
            <InputBoxWrapper>
              <InputBox
                type='text'
                name='lastName'
                required
                data-namereadable='last name'
                value={contactInfo.lastName || ""}
                onChange={input => handleInfoChange(input)}
              />
            </InputBoxWrapper>
            <InputError>{errMessages.lastName}</InputError>
          </InputContainer>
          <InputContainer>
            <InputLabel>Email</InputLabel>
            <InputBoxWrapper>
              <InputBox
                type='text'
                name='email'
                required
                data-namereadable='email'
                value={contactInfo.email || ""}
                onChange={input => handleInfoChange(input)}
              />
            </InputBoxWrapper>
            <InputError>{errMessages.email}</InputError>
          </InputContainer>
          <InputContainer>
            <InputLabel>Phone</InputLabel>
            <PhoneWrapper>
              <select
                name='phoneRegion'
                onChange={input => setPhoneRegion(input.target.value)}
                value={phoneRegion}
              >
                <option disabled value=''>
                  Select your Phone Region
                </option>
                <option value='US'>US +1</option>
                <option value='CA'>CA +1</option>
                <option value='MX'>MX +52</option>
              </select>
              <Cleave
                name='phone'
                options={{
                  phone: true,
                  phoneRegionCode: phoneRegion
                }}
                onChange={input => handlePhoneChange(input)}
                value={contactInfo.phone || ""}
              />
            </PhoneWrapper>
            <InputError>{errMessages.phone}</InputError>
          </InputContainer>
          <InputContainer>
            <InputLabel>Mobile Phone</InputLabel>
            <PhoneWrapper>
              <select
                name='mobilePhoneRegion'
                onChange={input => setMobilePhoneRegion(input.target.value)}
                value={mobilePhoneRegion}
              >
                <option disabled value=''>
                  Select your Phone Region
                </option>
                <option value='US'>US +1</option>
                <option value='CA'>CA +1</option>
                <option value='MX'>MX +52</option>
              </select>
              <Cleave
                name='mobilePhone'
                options={{
                  phone: true,
                  phoneRegionCode: phoneRegion
                }}
                onChange={input => handleMobileChange(input)}
                value={contactInfo.mobilePhone || ""}
              />
            </PhoneWrapper>
            <InputError>{errMessages.mobilePhone}</InputError>
          </InputContainer>
          <ButtonActions 
            changed={infoEdit}
            loading={loadingInfo}
            success={infoSuccess}
            handleReset={handleInfoCancel}
            handleSave={handleInfoSave}
          />
        </ContactFieldset>
      </ContactCard>
      <ContactCard>
        <CardTitle>
          <Title uppercase>Address</Title>
        </CardTitle>
        <ContactFieldset>
          <InputContainer>
            <InputLabel>Street Address</InputLabel>
            <InputBoxWrapper>
              <InputBox
                type='text'
                name='address'
                data-namereadable='street address'
                value={addressInfo.address || ""}
                onChange={input => handleAddressChange(input)}
              />
            </InputBoxWrapper>
          </InputContainer>
          <InputContainer>
            <InputLabel>Street Address 2</InputLabel>
            <InputBoxWrapper>
              <InputBox
                type='text'
                name='address2'
                data-namereadable='street address 2'
                value={addressInfo.address2 || ""}
                onChange={input => handleAddressChange(input)}
              />
            </InputBoxWrapper>
          </InputContainer>
          <InputContainer>
            <InputLabel>City</InputLabel>
            <InputBoxWrapper>
              <InputBox
                type='text'
                name='city'
                data-namereadable='city'
                value={addressInfo.city || ""}
                onChange={input => handleAddressChange(input)}
              />
            </InputBoxWrapper>
          </InputContainer>
          <InputContainer>
            <InputLabel>State</InputLabel>
            <InputBoxWrapper>
              <InputBox
                type='text'
                name='state'
                data-namereadable='state'
                value={addressInfo.state || ""}
                onChange={input => handleAddressChange(input)}
              />
            </InputBoxWrapper>
          </InputContainer>
          <InputContainer>
            <InputLabel>Zip</InputLabel>
            <InputBoxWrapper>
              <InputBox
                type='text'
                name='zipCode'
                data-namereadable='zip code'
                value={addressInfo.zipCode || ""}
                onChange={input => handleAddressChange(input)}
              />
            </InputBoxWrapper>
          </InputContainer>
          <InputContainer>
            <InputLabel>Website</InputLabel>
            <InputBoxWrapper>
              <InputBox
                type='text'
                name='website'
                data-namereadable='street website'
                value={addressInfo.website}
                onChange={input => handleAddressChange(input)}
              />
            </InputBoxWrapper>
            <InputError>{errMessages.website}</InputError>
          </InputContainer>
          <ButtonActions 
            changed={addressEdit}
            loading={loadingAddress}
            success={addressSuccess}
            handleReset={handleAddressCancel}
            handleSave={handleAddressSave}
          />
        </ContactFieldset>
      </ContactCard>
      <OtherInfo 
        contact={contact}
        contactStatuses={contactStatuses}
        errMessages={errMessages}
        updateContact={props.updateContact}
        getTags={props.getTags}
        contactTags={contactTags}
        userProfile={userProfile}
        companyTags={companyTags}
        companyId={userCompany._id}
        alert={props.alert}
      />
    </div>
  );
};

export default ContactInfo;
