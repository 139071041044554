import React from "react";
import styled from "styled-components";

const CheckboxControl = styled.label`
  display: block;
  position: relative;
  padding-left: 30px;
  margin: 10px 0;
  padding-top: 3px;
  cursor: pointer;
  font-size: 15px;
  font-weight: normal;
  text-transform: none;
  &:hover input ~ .control_indicator {
    background: #cccccc;
  }
  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    pointer-events: ${props => (props.changeEnabled ? "auto" : "none")};
    &:focus ~ .control_indicator {
      background: #cccccc;
    }
    &:checked ~ .control_indicator {
      background: #e6e6e6;
      color: black;
    }
    &:checked:focus ~ .control_indicator {
      background: #ebebeb;
    }
    &:checked ~ .control_indicator:after {
      display: block;
    }
  }
`;

const CheckboxIndicator = styled.div`
  position: absolute;
  top: ${props => (props.topPosition ? props.topPosition : "2px")};
  left: 0;
  height: 20px;
  width: 20px;
  background: #fcfcfc;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  &:after {
    box-sizing: unset;
    content: "";
    position: absolute;
    display: none;
    left: 8px;
    top: 4px;
    width: 3px;
    height: 8px;
    border: solid ${props => props.theme.HC1};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 4.5rem;
    height: 4.5rem;
    margin-left: -1.3rem;
    margin-top: -1.3rem;
    background: #e6e6e6;
    border-radius: 3rem;
    opacity: 0.6;
    z-index: 99999;
    transform: scale(0);
  }
`;

const PartialSelectIndicator = styled(CheckboxIndicator)`
  &::after {
    box-sizing: unset;
    content: "";
    position: absolute;
    display: none;
    left: 5px;
    top: 0px;
    width: 8px;
    height: 8px;
    border: solid ${props => props.theme.HC1};
    border-width: 0 0px 2px 0;
    transform: none;
  }
`;

const Checkbox = props => {
  const { checked, onChange, partialSelectOn = false, children = "" } = props;
  return (
    <CheckboxControl
      changeEnabled={typeof onChange === "function"}
      className='checkbox-wrapper'
    >
      {children}
      <input
        type='checkbox'
        checked={checked}
        onChange={onChange}
      />
      {!partialSelectOn ? (
        <CheckboxIndicator
          className='control_indicator'
          topPosition='-8px'
        ></CheckboxIndicator>
      ) : (
        <PartialSelectIndicator
          className='control_indicator'
          topPosition='-8px'
        ></PartialSelectIndicator>
      )}
    </CheckboxControl>
  );
};

export default Checkbox;
