import styled from "styled-components";

const FbPreviewCompanyName = styled.h4`
  background-color: ${props => (props.PH ? "lightgrey" : "")};
  grid-column: ${props => (props.PH ? "3 / 7" : "3 / 13")};
  grid-row: span 2;
  text-align: left;
  margin: 10px 0 0 0;
  font-weight: bold;
  font-size: 0.9em;
`;

export default FbPreviewCompanyName;
