import styled from "styled-components";

const AvailableFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 2em;
  position: relative;
  font: normal normal 600 15px/22px "Poppins", sans-serif;
  color: ${props => props.theme.CTextC}
  cursor: move;
  border: 1px solid ${props => props.theme.HC2};
  border-radius: 3px;
  margin: 10px 0;
  padding: 7px 0;
  i {
    color: ${props => props.theme.TextC};
  }
  @media (any-hover: none) {
    border-top: 1px solid #f4f4f4;
    border-bottom: ${props => (props.last ? "1px solid #f4f4f4" : "none")};
  }
  &:hover {
    i {
      color: ${props => props.theme.HC2};
    }
  }
`;

const IconWrapper = styled.div`
  cursor: pointer;
  text-align: right;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Icon = styled.i`
  color: transparent;
  @media (any-hover: none) {
    color: #e5e5e5;
  }
`;

const AvailableField = props => {
  const { field, custom } = props;

  return (
    <AvailableFieldWrapper key={field.key} data-id={field.key}>
      <IconWrapper className='icon-wrapper' position='left'>
        <Icon className='material-icons'>drag_indicator</Icon>
      </IconWrapper>
      {field.name}{" "}
      <IconWrapper className='icon-wrapper' position='right'>
        {!!custom && (
          <Icon
            className='material-icons'
            onClick={() => props.deleteCustomField(field)}
          >
            delete
          </Icon>
        )}
        <Icon
          className='material-icons'
          onClick={() => props.moveField(field.key, "add")}
        >
          add
        </Icon>
      </IconWrapper>
    </AvailableFieldWrapper>
  );
};

export default AvailableField;
