import axios from "axios";
import { axiosResponse, errorHandler } from "./Helpers";

const BP_API = process.env.REACT_APP_BP_API;

const workflowObject = {
  actions: [],
  name: "Untitled Workflow",
  description: "",
  filters: [
    {
      campaignIds: [],
      tagIds: [],
      statusIds: [],
      type: "ALL_CONTACTS"
    }
  ],
  triggerType: "",
  status: "DRAFT",
  endActions: [
    {
      type: "CONTACT_RESPONDS",
      actions: ["REMOVE_FROM_WORKFLOW"],
      statusId: "",
      tagIds: []
    }
  ]
};

export const createNewWorkflow = async newWorkflow => {
  try {
    const workflow = axiosResponse(
      await axios.post(`${BP_API}/v1/workflow/create`, {
        ...workflowObject,
        ...newWorkflow
      })
    );
    return workflow;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const getAllCompanyWorkflows = async () => {
  try {
    const workflow = axiosResponse(
      await axios.get(`${BP_API}/v1/workflow/company`)
    );
    return workflow;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const getWorkflowById = async workflowId => {
  try {
    const workflow = axiosResponse(
      await axios.get(`${BP_API}/v1/workflow/id/${workflowId}`)
    );
    return workflow;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const updateWorkflow = async (workflowId, workflowObject) => {
  try {
    const workflow = axiosResponse(
      await axios.put(`${BP_API}/v1/workflow/update`, {
        _id: workflowId,
        ...workflowObject
      })
    );
    return workflow;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const deleteWorkflowById = async workflowId => {
  try {
    const workflow = axiosResponse(
      await axios.delete(`${BP_API}/v1/workflow/id/${workflowId}`)
    );
    return workflow;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const workflowMatching = ({ triggerType, filters, workflows }) => {
  try {
    const matches = workflows.filter(flow => {
      if (flow.triggerType !== triggerType) {
        return false;
      }
      const matchingFilters = flow.filters.filter(f => {
        if (f.type === "ALL_CONTACTS" && triggerType === "NEW_CONTACT") {
          return true;
        }
        const filterToMatch = filters.find(ftm => ftm.type === f.type);
        let matching = false;
        if (filterToMatch) {
          switch (filterToMatch.type) {
            case "CAMPAIGN":
              f.campaignIds.forEach(cId => {
                if (filterToMatch.campaignIds.includes(cId)) {
                  matching = true;
                }
              });
              break;
            case "TAGS":
              f.tagIds.forEach(t => {
                if (filterToMatch.tagIds.includes(t)) {
                  matching = true;
                }
              });
              break;
            case "STATUS":
              f.statusIds.forEach(s => {
                if (filterToMatch.statusIds.includes(s)) {
                  matching = true;
                }
              });
              break;
            default:
              break;
          }
        }
        return matching;
      });
      if (matchingFilters.length > 0) {
        return true;
      }
      return false;
    });
    return matches;
  } catch (err) {
    console.log("error getting matching workflows", err.message);
    throw new Error(err.message);
  }
};
