import React from "react";
import styled from "styled-components";
import Floater from "react-floater";
import { monthsAbr } from "../../../utils/Helpers";
import { Button } from "../../styled/Buttons";
import { LoaderDotsSmall } from "../../elements/Loaders";

const MsgDateContents = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: inherit;
  flex-direction: column;
  & > div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .material-icons {
    font-size: 19px;
  }
`;

const Status = styled.div`
  color: ${props =>
    props.status === "failed" || props.status === "undelivered"
      ? props.theme.HCErr
      : props.status === "delivered"
      ? props.theme.HCSuc
      : props.theme.TextC};
  margin-left: 5px;
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: ${props => (props.inbound ? "flex-start" : "flex-end")};
  margin: 0 3px;
`;

const MsgPending = styled.div`
  position: relative;
  top: 15px;
  width: 35px;
`;

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.03);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1001;
  cursor: pointer;
`;

const Icon = styled.i`
  position: relative;
  top: 2px;
  font-size: 1em;
  color: ${props => props.theme.HCErr};
  &:hover {
    color: ${props => props.theme.BTextC};
  }
`;

const floaterStyles = {
  wrapper: {
    cursor: "pointer",
    zIndex: 1010
  },
  container: {
    borderRadius: 5,
    color: "rgb(51, 51, 51)",
    padding: 15,
    textAlign: "center",
    zIndex: 1010
  },
  floater: {
    zIndex: 1010
  }
};

const MessageInfo = props => {
  const { message, num: key, floaters, errorCodes } = props;

  const getFormattedDate = initDate => {
    const date = new Date(initDate);
    const dateMonthNum = date.getMonth();
    const dateMonthText = monthsAbr[dateMonthNum];
    const dateFormatted = `${dateMonthText} ${date.getDate()}, ${date.getFullYear()}`;
    let dateHours = date.getHours();
    const timePeriod = dateHours > 12 ? "PM" : "AM";
    if (dateHours > 12) {
      dateHours = dateHours - 12;
    }
    const dateMinutes = `${date.getMinutes()}`.padStart(2, "0");
    const dateString = date.toString();
    const dateParts = dateString.split(/[/(/)]/);
    const timeZoneStrings = dateParts[1].split(" ");
    let timeZone = "";
    timeZoneStrings.forEach(
      zoneString => (timeZone = `${timeZone}${zoneString.charAt(0)}`)
    );
    return `${dateFormatted} ${dateHours}:${dateMinutes} ${timePeriod}`;
  };

  return message.dateCreated ? (
    <MsgDateContents>
      {getFormattedDate(message.dateCreated)}
      {message.smsStatus && message.direction !== "incoming" && (
        <Status status={message.smsStatus}>
          {(message.smsStatus === "failed" ||
            message.smsStatus === "undelivered") && (
            <Floater
              open={floaters[`failed${key}`]}
              content={
                <div>
                  <p>Your message didn't send.</p>
                  <p>{errorCodes[message.errorCode]}</p>
                  <Button onClick={() => props.tryAgain(message)}>
                    Try again
                  </Button>
                </div>
              }
              styles={floaterStyles}
              // event="hover"
              placement='left'
            >
              <Icon
                className='material-icons'
                onClick={e => props.handleFloaterClick(e, `failed${key}`)}
                name='floater-toggle'
              >
                error_outline
              </Icon>
              {floaters[`failed${key}`] && (
                <Overlay
                  onClick={e => props.handleFloaterClick(e, `failed${key}`)}
                  className='overlay'
                />
              )}
            </Floater>
          )}
          <span
            className='material-icons'
            title={message.smsStatus}
            status={message.smsStatus}
          >
            {message.smsStatus === "delivered"
              ? "check_circle"
              : message.smsStatus === "queued" || message.smsStatus === "sent"
              ? "check_circle_outline"
              : "error_outline"}
          </span>
        </Status>
      )}
    </MsgDateContents>
  ) : (
    <LoaderWrapper inbound={message.direction === "incoming"}>
      <span>sending</span>
      <MsgPending>
        <LoaderDotsSmall />
      </MsgPending>
    </LoaderWrapper>
  );
};

export default MessageInfo;
