import React from "react";
import styled from "styled-components";
import WorkflowModal from "./WorkflowModal";

import {
  statusesGetByCompany,
  statusesGetByIndustry
} from "../../../utils/ContactAPI";

import {
  Icon,
  H3,
  FilterHeader,
  FilterHeaderLeft,
  FlowHeaderDivider,
  InputWrapper
} from "./styles";
import { Button } from "../../styled/Buttons";
import StatusSelectMultiple from "../contacts/contactStatus/StatusSelectMultiple";

const StatusBody = styled.div``;

const OptionList = styled.div`
  padding: 35px;
  div {
    margin: auto;
  }
`;

const FilterStatus = props => {
  const { industry, setOpenEditStatus, filters, addFilter } = props;

  const [statusList, setStatusList] = React.useState([]);
  const [selectedStatus, setSelectedStatus] = React.useState([]);

  React.useEffect(() => {
    const getStatuses = async () => {
      let statuses = [];
      const companyStatuses = await statusesGetByCompany();
      statuses = [...companyStatuses];
      if (statuses.length === 0) {
        if (industry) {
          const industryStatuses = await statusesGetByIndustry(industry);
          statuses = [...industryStatuses];
        }
      }
      setStatusList(statuses);
    };
    getStatuses();
  }, [industry]);

  React.useEffect(() => {
    filters.forEach(item => {
      if (item.type === "STATUS") {
        const { statusIds } = item;
        setSelectedStatus(statusIds);
      }
    });
  }, [filters]);

  const updateStatuses = value => {
    if (selectedStatus.includes(value)) {
      console.log("already included", value);
      setSelectedStatus(selectedStatus.filter(s => s !== value));
    } else {
      setSelectedStatus([...selectedStatus, value]);
    }
  };

  const applyFilter = () => {
    const filter = {
      campaignIds: [],
      tagIds: [],
      statusIds: selectedStatus,
      type: "STATUS"
    };
    addFilter(filter);
  };

  return (
    <WorkflowModal
      open={true}
      onCloseMethod={() => setOpenEditStatus()}
      body={
        <div>
          <FilterHeader>
            <FilterHeaderLeft>
              <Icon small className='material-icons-outlined'>
                filter_alt
              </Icon>
              <H3>Filters</H3>
              <FlowHeaderDivider></FlowHeaderDivider>
              <H3>Select a Status</H3>
            </FilterHeaderLeft>
          </FilterHeader>
          <StatusBody>
            <OptionList>
              <InputWrapper>
                <StatusSelectMultiple
                  selected={selectedStatus}
                  contactStatuses={statusList}
                  onChange={updateStatuses}
                />
              </InputWrapper>
            </OptionList>
          </StatusBody>
          <Button
            onClick={() => applyFilter()}
            small
            disabled={selectedStatus.length === 0}
          >
            APPLY
          </Button>
        </div>
      }
    />
  );
};
export default FilterStatus;
