import styled from "styled-components";
import { provinceList, statesList } from "../../../../utils/Helpers";

import { Button } from "../../../styled/Buttons";
import { Dropdown, InputBox } from "../../../styled/Input";
import { firstBreakpoint } from "../../../styled/PhoneNumberProvision";

const ContentWrapper = styled.div`
  margin: 20px;
  @media screen and (min-width: ${firstBreakpoint}px) {
    margin: 0;
  }
`;

const SelectOption = styled.div`
  display: block;
  grid-template-columns: 175px 1fr;
  grid-gap: 1em;
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: ${props => (props.compact ? "block" : "grid")};
    margin: ${props => (props.compact ? "0 30px 30px" : "0 60px 60px")};
  }
`;

const SearchContainer = styled.div`
  margin-top: 20px;
  @media screen and (min-width: ${firstBreakpoint}px) {
    margin-top: ${props => (props.compact ? "20px" : "0")};
  }
`;

const MethodWrapper = styled.div`
  /* overflow: hidden; */
  max-height: ${props => (props.hidden ? 0 : "500px")};
  transition: all 0.3s ease-in-out;
`;

const LocalityWrapper = styled.div`
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: ${props => (props.compact ? "block" : "grid")};
    grid-template-columns: 1fr 150px;
    grid-gap: 1em;
  }
`;

const ContinueButton = styled(Button)`
  display: none;
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: inline-block;
  }
`;

const NumberSearch = props => {
  const {
    searchMethod,
    selectSearchType,
    areaCode,
    inLocality,
    inRegion,
    inPostalCode,
    handleFieldChange,
    onKeyDown,
    compact,
    phoneSearch,
    country
  } = props;
  return (
    <ContentWrapper>
      <h2>Pick Your Number</h2>
      <p>This is the number your customers will see.</p>
      <SelectOption compact={compact}>
        <Dropdown
          type='text'
          name='searchBy'
          value={searchMethod}
          onChange={input => selectSearchType(input)}
        >
          <option value='areaCode'>Area Code</option>
          <option value='inLocality'>City</option>
          <option value='inPostalCode'>Zip Code</option>
        </Dropdown>
        <SearchContainer compact={compact}>
          <MethodWrapper hidden={searchMethod !== "areaCode"}>
            <InputBox
              type='text'
              name='areaCode'
              required
              value={areaCode}
              onChange={input => handleFieldChange(input)}
              onKeyDown={onKeyDown}
              placeholder='Search by area code'
            />
          </MethodWrapper>
          <MethodWrapper hidden={searchMethod !== "inLocality"}>
            <LocalityWrapper compact={compact}>
              <InputBox
                type='text'
                name='inLocality'
                required
                value={inLocality}
                placeholder='Search by City'
                onChange={input => handleFieldChange(input)}
                onKeyDown={onKeyDown}
              />
              {country === "us" ? (
                <Dropdown
                  type='text'
                  name='inRegion'
                  value={inRegion ? inRegion : "Select One"}
                  onChange={input => handleFieldChange(input)}
                  onKeyDown={onKeyDown}
                >
                  <option value='Select State'>State</option>
                  {statesList.map(item => (
                    <option key={item.abr}>{item.abr}</option>
                  ))}
                </Dropdown>
              ) : (
                <Dropdown
                  type='text'
                  name='inRegion'
                  value={inRegion ? inRegion : "Select One"}
                  onChange={input => handleFieldChange(input)}
                  onKeyDown={onKeyDown}
                >
                  <option value='Select Province'>Province</option>
                  {provinceList.map(item => (
                    <option key={item.abr}>{item.abr}</option>
                  ))}
                </Dropdown>
              )}
            </LocalityWrapper>
          </MethodWrapper>
          <MethodWrapper hidden={searchMethod !== "inPostalCode"}>
            <InputBox
              type='text'
              name='inPostalCode'
              required
              value={inPostalCode}
              onChange={input => handleFieldChange(input)}
              onKeyDown={onKeyDown}
              placeholder='Search by zip code'
            />
          </MethodWrapper>
        </SearchContainer>
      </SelectOption>
      <ContinueButton onClick={phoneSearch} solid>
        Search
      </ContinueButton>
    </ContentWrapper>
  );
};

export default NumberSearch;
