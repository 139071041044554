import React from "react";
import {
  InputContainer,
  InputBoxWrapper,
  InputBox,
  InputLabel,
  FormWrapper,
  FormDescription
} from "../../../styled/Input";

export const HealthcareSourceCoreSettings = props => {
  const { integration } = props;

  return (
    <FormWrapper single>
      <InputContainer slimHeight customWidth='100%'>
        <InputLabel>Customer ID</InputLabel>
        <InputBoxWrapper>
          <InputBox type='text' name='cId' readOnly value={integration.cId} />
        </InputBoxWrapper>
      </InputContainer>
      <InputContainer slimHeight customWidth='100%'>
        <InputLabel>SMS Consent</InputLabel>
        <InputBoxWrapper>
          <InputBox
            type='text'
            name='smsConsent'
            readOnly
            value={integration.smsConsent}
          />
        </InputBoxWrapper>
      </InputContainer>
      <InputContainer slimHeight customWidth='100%'>
        <InputLabel>Send Invite</InputLabel>
        <InputBoxWrapper>
          <InputBox
            type='text'
            name='sendInvite'
            readOnly
            value={integration.sendInvite}
          />
        </InputBoxWrapper>
      </InputContainer>
      <InputContainer slimHeight customWidth='100%'>
        <InputLabel>Referrer Name</InputLabel>
        <InputBoxWrapper>
          <InputBox
            type='text'
            name='referrerName'
            readOnly
            value={integration.referrerName}
          />
        </InputBoxWrapper>
      </InputContainer>
      <FormDescription evenContainer>
        Please contact your Support Representative to update these values.
      </FormDescription>
    </FormWrapper>
  );
};
