import React from "react";
import styled from "styled-components";
import Floater from "react-floater";
import { monthsAbr } from "../../../utils/Helpers";
import { Button } from "../../styled/Buttons";
import { LoaderDotsSmall } from "../../elements/Loaders";

const SendMedia = styled.div`
  display: flex;
  width: 100%;
  padding: 0 15px;
`;

const MediaWrapper = styled.div`
  position: relative;
  margin: 5px;
  .media-delete {
    opacity: 0.5;
  }
  &:hover .media-delete {
    opacity: 1;
  }
`;

const MediaDelete = styled.span`
  position: absolute;
  top: 0;
  display: block;
  left: 4px;
  top: 4px;
  padding: 3px;
  border-radius: 50%;
  color: ${props => props.theme.HC1};
  background-color: rgba(225, 225, 225, 0.8);
  cursor: pointer;
`;

const MediaAdd = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  span {
    font-size: 34px;
  }
`;

const ImageButton = styled.span`
  color: ${props => props.theme.HC1};
  cursor: pointer;
  border-radius: 5px;
  padding: 4px;
  font-size: 36px;
`;

const MessageMedia = props => {
  const { mediaUrl = [] } = props;

  return (
    <SendMedia>
      {mediaUrl.map((media, key) =>
        media.mimetype.includes("image") ? (
          <MediaWrapper key={key}>
            <MediaDelete
              className='material-icons media-delete'
              onClick={() => props.removeMedia(key)}
            >
              delete
            </MediaDelete>
            <img
              src={`https://cdn.filestackcontent.com/resize=height:80,width:80,fit:crop/${media.handle}`}
              alt='thumbnail'
              data-pin-nopin='true'
            />
          </MediaWrapper>
        ) : media.mimetype.includes("video") ? (
          <MediaWrapper key={key}>
            <MediaDelete
              className='material-icons media-delete'
              onClick={() => props.removeMedia(key)}
            >
              delete
            </MediaDelete>
            <video
              style={{
                maxWidth: "100%",
                maxHeight: "100px"
              }}
            >
              <source src={media.url} type={media.mimetype} />
              Your browser does not support this video type
            </video>
          </MediaWrapper>
        ) : null
      )}
      <MediaAdd>
        <ImageButton
          className='material-icons'
          onClick={() => props.toggleShowUploader()}
        >
          add_to_photos
        </ImageButton>
      </MediaAdd>
    </SendMedia>
  );
};

export default MessageMedia;
