import React from "react";
import styled from "styled-components";
import AnimateHeight from "react-animate-height";
import WorkflowModal from "./WorkflowModal";
import WorkflowCompleteTags from "./WorkflowCompleteTags";
import WorkflowCompleteStatus from "./WorkflowCompleteStatus";
import {
  statusesGetByCompany,
  statusesGetByIndustry,
  tagsGetByCompany
} from "../../../utils/ContactAPI";
import { LoaderDotsSmall } from "../../elements/Loaders";
import { Button } from "../../styled/Buttons";
import { Purple } from "../../styled/Colors";
import {
  AddAction,
  ActionEditButton,
  ActionEditDelete,
  ActionBodyDivider,
  FlowCard,
  FlowHeader,
  FlowHeaderToggle,
  FlowHeaderTitle,
  FlowHeaderRight,
  FlowHeaderLeft,
  FlowHeaderDivider,
  FlowInputLabel,
  Icon,
  H2,
  H3,
  WorkflowError
} from "./styles";
import { Dropdown } from "../../styled/Input";

const ActionBody = styled.div`
  text-align: left;
`;

const ActionItemWrapper = styled.div`
  padding: 20px 50px 40px 40px;
  border-bottom: 1px solid #d9d9da;
`;

const AddActionWrapper = styled.div`
  padding: 20px 40px;
  text-align: center;
`;

export const AddWorkflowCondition = styled.div`
  color: ${Purple};
  margin: 13px auto auto 10px;
  border-radius: 50%;
  .material-icons-outlined {
    cursor: pointer;
    font-size: 20px;
    &:hover {
      transform: scale(1.1);
    }
  }
`;

const UnenrollmentWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 30px;
  h3 {
    margin-right: 20px;
  }
`;

const UnenrollmentOptions = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-right: 10px;
  letter-spacing: 0px;
  .material-icons {
    font-size: 15px;
    margin-right: 5px;
  }
`;

const CompletionRow = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 15px 0;
`;

const CompletionItem = styled.div`
  display: flex;
  align-items: baseline;
  width: 100%;
  margin-right: 10px;
`;

const CompletionDropdown = styled.div`
  display: flex;
  width: 100%;
  margin-left: 10px;
`;

const CompletionSelectedTitle = styled.div`
  text-align: center;
  font-size: smaller;
  font-weight: bold;
  margin-bottom: 10px;
`;

const CompletionSelectedWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const CompletionSelectedItem = styled.div`
  margin: 0 10px;
  font-size: smaller;
  display: flex;
  align-items: center;
`;

const TagColor = styled.div`
  border-radius: 50%;
  height: 10px;
  width: 10px;
  margin-right: 4px;
  background-color: ${props => props.color && props.color};
`;

const AndOrDivider = styled.div`
  font-size: 13px;
  width: 55px;
  margin: 40px auto -48px auto;
  text-align: center;
  background: white;
`;

const UrgentText = styled.p`
  font-weight: 600;
  font-style: italic;
`;

const workflowDropdownList = {
  conditions: [
    {
      type: "CONTACT_RESPONDS,",
      name: "Contact Responds",
      shortName: "Contact Responds"
    },
    {
      type: "CONTACT_STAGE_CHANGE",
      name: "Contact Status Changes",
      shortName: "Status Change"
    },
    {
      type: "NO_RESPONSE",
      name: "No Response",
      shortName: "No Response"
    }
  ],
  actions: [
    {
      type: "ADD_TAGS",
      name: "Add/Update Contact Tag(s)",
      shortName: "Add Tag(s)"
    },
    {
      type: "MOVE_TO_STAGE",
      name: "Move to a New Status",
      shortName: "Status Change"
    },
    {
      type: "REMOVE_FROM_WORKFLOW",
      name: "Remove from Workflow",
      shortName: "Remove from Workflow"
    }
  ]
};

const WorkflowComplete = props => {
  const [triggerOpen, setTriggerOpen] = React.useState(true);
  const [modalDeleteConfirm, setModalDeleteConfirm] = React.useState(false);
  const [currentDeleteIndex, setCurrentDeleteIndex] = React.useState("");
  const [deleting, setDeleteLoading] = React.useState(false);
  const [height, setHeight] = React.useState("auto");
  const [workflowCompleteLength, setWorkflowCompleteLength] = React.useState(0);
  const [currentUpdateIndex, setCurrentUpdateIndex] = React.useState("");
  const [currentUpdateAction, setCurrentUpdateAction] = React.useState("");
  const [currentEndAction, setCurrentEndAction] = React.useState({});
  const [openEditTags, setOpenEditTags] = React.useState(false);
  const [tagsList, setTagsList] = React.useState([]);
  const [openEditStatus, setOpenEditStatus] = React.useState(false);
  const [statusList, setStatusList] = React.useState([]);

  const {
    userCompany,
    endActions = [],
    updateEndActions,
    errors,
    resetValidate
  } = props;

  React.useEffect(() => {
    const length = endActions.length;
    setWorkflowCompleteLength(length);
  }, [endActions]);

  React.useEffect(() => {
    const getStatuses = async () => {
      let statuses = [];
      const companyStatuses = await statusesGetByCompany();
      statuses = [...companyStatuses];
      if (statuses.length === 0) {
        const { industry } = userCompany;
        if (industry) {
          const industryStatuses = await statusesGetByIndustry(industry);
          statuses = [...industryStatuses];
        }
      }
      setStatusList(statuses);
    };
    getStatuses();
  }, [userCompany]);

  React.useEffect(() => {
    const getTags = async () => {
      const tags = await tagsGetByCompany();
      setTagsList(tags);
    };
    getTags();
  }, []);

  const toggleHeight = () => {
    const newHeight = height === 0 ? "auto" : 0;
    setHeight(newHeight);
    setTriggerOpen(!triggerOpen);
  };

  const addNewCompletion = () => {
    resetValidate("endActions");
    const newCompletion = [
      ...endActions,
      {
        type: "default",
        actions: ["default"],
        tagIds: [],
        statusId: ""
      }
    ];
    updateEndActions(newCompletion);
  };

  const updateConditionList = (value, workflowIndex) => {
    resetValidate("endActions");
    const updatedList = [...endActions];
    updatedList[workflowIndex].type = value;
    updateEndActions(updatedList);
  };

  const updateOutcomeList = (value, updateIndex) => {
    resetValidate("endActions");
    const oldEndAction = endActions[updateIndex];
    const updatedList = endActions;
    const newItem = {
      ...oldEndAction,
      actions: [value]
    };
    if (value === "ADD_TAGS") {
      newItem.tagIds = [];
    } else if (value === "MOVE_TO_STAGE") {
      newItem.statusId = "";
    }
    updatedList[updateIndex] = newItem;
    updateEndActions(updatedList);
  };

  const openOutcomeFilter = (outcome, index) => {
    console.log("outcome: ", outcome, index);
    const newEndAction = endActions[index];
    newEndAction.tagIds = [];
    newEndAction.statusId = "";
    setCurrentEndAction(newEndAction);
    setCurrentUpdateIndex(index);
    setCurrentUpdateAction(outcome);
    if (outcome === "MOVE_TO_STAGE") {
      setOpenEditStatus(true);
    }
    if (outcome === "ADD_TAGS") {
      setOpenEditTags(true);
    }
    if (outcome === "REMOVE_FROM_WORKFLOW") {
      updateOutcomeList(outcome, index);
    }
  };

  const addStatus = status => {
    resetValidate("endActions");
    const oldEndAction = currentEndAction;
    const updatedList = endActions;
    const newItem = {
      ...oldEndAction,
      actions: ["MOVE_TO_STAGE"],
      statusId: status
    };
    updatedList[currentUpdateIndex] = newItem;
    updateEndActions(updatedList);
    setOpenEditStatus(false);
    setCurrentUpdateIndex("");
    setCurrentUpdateAction("");
    setCurrentEndAction({});
  };

  const addTags = tagIds => {
    resetValidate("endActions");
    const oldEndAction = currentEndAction;
    const updatedList = endActions;
    const newItem = {
      ...oldEndAction,
      actions: ["ADD_TAGS"],
      tagIds
    };
    updatedList[currentUpdateIndex] = newItem;
    updateEndActions(updatedList);
    setOpenEditTags(false);
    setCurrentUpdateIndex("");
    setCurrentUpdateAction("");
    setCurrentEndAction({});
  };

  const removeEndAction = index => {
    if (!modalDeleteConfirm) {
      setCurrentDeleteIndex(index);
      setModalDeleteConfirm(true);
    } else {
      resetValidate("endActions");
      setDeleteLoading(true);
      const removeEndActions = [...endActions];
      removeEndActions.splice(currentDeleteIndex, 1);
      // const updatedEndActions = { endActions: removeEndActions };
      console.log(removeEndActions);
      updateEndActions(removeEndActions);
      setDeleteLoading(false);
      setModalDeleteConfirm(false);
    }
  };

  return (
    <FlowCard hasError={errors.endActions}>
      <FlowHeader triggerOpen={triggerOpen}>
        <FlowHeaderLeft>
          <Icon className='material-icons'>fact_check</Icon>
          <H2>Workflow Completion</H2>
        </FlowHeaderLeft>
        <FlowHeaderRight>
          <FlowHeaderToggle
            triggerOpen={triggerOpen}
            onClick={() => toggleHeight()}
          >
            <span className='material-icons-outlined'>keyboard_arrow_up</span>
          </FlowHeaderToggle>
        </FlowHeaderRight>
      </FlowHeader>
      <AnimateHeight
        duration={200}
        height={height} // see props documentation below
      >
        <ActionBody>
          {errors.endActions &&
            errors.endActions.some(error => error.noCard === true) && (
              <WorkflowError>{errors.endActions[0].message}</WorkflowError>
            )}
          {endActions &&
            endActions.length > 0 &&
            endActions.map((workflowItem, workflowIndex) => {
              const { actions = [], statusId, tagIds, type } = workflowItem;
              return (
                <ActionItemWrapper key={workflowIndex}>
                  {errors.endActions &&
                    errors.endActions.map(error => {
                      if (error.index === workflowIndex) {
                        return <WorkflowError>{error.message}</WorkflowError>;
                      }
                    })}
                  <CompletionRow>
                    <CompletionItem>
                      <FlowInputLabel>If</FlowInputLabel>
                      <CompletionDropdown>
                        <Dropdown
                          type='text'
                          name='conditionOne'
                          value={type}
                          onChange={input =>
                            updateConditionList(
                              input.target.value,
                              workflowIndex
                            )
                          }
                        >
                          <option value='default' disabled hidden>
                            Select a Condition
                          </option>
                          {workflowDropdownList.conditions.map(condition => (
                            <option value={condition.type} key={condition.type}>
                              {condition.name}
                            </option>
                          ))}
                        </Dropdown>
                      </CompletionDropdown>
                    </CompletionItem>
                  </CompletionRow>
                  <CompletionRow>
                    <CompletionItem>
                      <FlowInputLabel>Then</FlowInputLabel>
                      <CompletionDropdown>
                        {actions.map((action, index) => (
                          <Dropdown
                            key={`${action}${index}`}
                            type='text'
                            name='delayValue'
                            value={action}
                            onChange={input =>
                              openOutcomeFilter(
                                input.target.value,
                                workflowIndex,
                                index
                              )
                            }
                          >
                            <option value='default' disabled hidden>
                              Select an Outcome
                            </option>
                            {workflowDropdownList.actions.map(outcome => (
                              <option value={outcome.type} key={outcome.type}>
                                {outcome.name}
                              </option>
                            ))}
                          </Dropdown>
                        ))}
                      </CompletionDropdown>
                    </CompletionItem>
                  </CompletionRow>
                  {actions.includes("ADD_TAGS") && tagIds.length > 0 && (
                    <div>
                      <CompletionSelectedTitle>
                        Selected Tags
                      </CompletionSelectedTitle>
                      <CompletionSelectedWrapper>
                        {tagsList.map(tag => {
                          if (tagIds.some(value => value === tag._id)) {
                            return (
                              <CompletionSelectedItem key={tag._id}>
                                [<TagColor color={tag.color} />
                                <div>{tag.name}</div>]
                              </CompletionSelectedItem>
                            );
                          }
                        })}
                      </CompletionSelectedWrapper>
                    </div>
                  )}
                  {actions.includes("MOVE_TO_STAGE") && statusId && (
                    <div>
                      <CompletionSelectedTitle>
                        Selected Status
                      </CompletionSelectedTitle>
                      <CompletionSelectedWrapper>
                        {statusList.map(status => {
                          if (status._id === statusId) {
                            return (
                              <CompletionSelectedItem key={status._id}>
                                [{status.status}]
                              </CompletionSelectedItem>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </CompletionSelectedWrapper>
                    </div>
                  )}
                  <ActionEditDelete>
                    <span
                      className='material-icons'
                      onClick={() => removeEndAction(workflowIndex)}
                    >
                      delete
                    </span>
                  </ActionEditDelete>
                  {workflowCompleteLength - 1 > workflowIndex && (
                    <AndOrDivider>Or</AndOrDivider>
                  )}
                </ActionItemWrapper>
              );
            })}
          <AddActionWrapper>
            <AddAction>
              <span
                onClick={() => addNewCompletion()}
                className='material-icons-outlined'
              >
                add_circle_outline
              </span>
            </AddAction>
          </AddActionWrapper>
          <ActionBodyDivider />
          {/* cspell:disable */}
          <UnenrollmentWrapper>
            <H3>Auto Unenrollment</H3>
            <UnenrollmentOptions>
              {/* cspell:enable */}
              <span className='material-icons'>radio_button_checked</span>
              <span>Message Error</span>
              {/* cspell:disable */}
            </UnenrollmentOptions>
            <UnenrollmentOptions>
              {/* cspell:enable */}
              <span className='material-icons'>radio_button_checked</span>
              <span>Opt out</span>
              {/* cspell:disable */}
            </UnenrollmentOptions>
          </UnenrollmentWrapper>
          {/* cspell:enable */}
        </ActionBody>
        {openEditStatus && (
          <WorkflowCompleteStatus
            statusList={statusList}
            setOpenEditStatus={setOpenEditStatus}
            currentEndAction={currentEndAction}
            addStatus={addStatus}
          />
        )}
        {openEditTags && (
          <WorkflowCompleteTags
            tagsList={tagsList}
            setOpenEditTags={setOpenEditTags}
            currentEndAction={currentEndAction}
            addTags={addTags}
          />
        )}
      </AnimateHeight>
      <WorkflowModal
        open={modalDeleteConfirm}
        title='Delete Completion Condition'
        onCloseMethod={() => {
          setCurrentDeleteIndex("");
          setModalDeleteConfirm(false);
        }}
        body={
          <div>
            <p>
              Are you sure you want to delete this Workflow Completion
              Condition?
            </p>
            <UrgentText>This action can't be undone!</UrgentText>
            <Button
              onClick={() => {
                setCurrentDeleteIndex("");
                setModalDeleteConfirm(false);
              }}
              disabled={deleting}
            >
              Cancel
            </Button>
            <Button solid onClick={() => removeEndAction()} disabled={deleting}>
              Delete
              {deleting && <LoaderDotsSmall />}
            </Button>
          </div>
        }
      />
    </FlowCard>
  );
};

export default WorkflowComplete;
