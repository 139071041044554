import styled from "styled-components";

const SectionGroup = styled.div`
  background-color: ${props => props.theme.BGC};
  min-height: ${props => (props.heightOff ? "none" : "500px")};
  margin: 2.5em;
  padding: ${props => (props.padding ? "40px" : "0")};
  /* padding: 1em 0 3em; */
  /* overflow: auto; //removed for ad schedule drop down date range picker*/
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
    0 1px 3px 1px rgba(60, 64, 67, 0.16);
  border-radius: 8px;
  @media screen and (min-width: 1820px) {
    max-width: 1740px;
    margin: 2.5em auto;
  }

  @media screen and (max-width: 600px) {
    margin: 2.5em 1.5em;
    /* width: 86%; */
  }

  @media screen and (max-width: 450px) {
    margin: 2.5em 0.5em;
  }
`;

export default SectionGroup;
