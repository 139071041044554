/* global FB */
import React from "react";
import { withRouter, Route, Switch } from "react-router-dom";
import ScrollToTop from "./elements/ScrollToTop";
import * as Sentry from "@sentry/browser";
import LogRocket from "logrocket";
import ReactGA from "react-ga";
import { withAlert } from "react-alert";
import styled from "styled-components";
import axios from "axios";
import jwt from "jwt-decode";
import { auth, firestore } from "../firebase/firebase";
import PrivateRoute from "./PrivateRoute";
import PublicRouteHidden from "./PublicRouteHidden";
import ErrorBoundary from "./ErrorBoundary";
import BpModal from "./elements/BpModal";
import OrderCreditsStripeWrapper from "./OrderCreditsStripeWrapper";
import MediaManager from "./media/MediaManager";

import { getPagePicture, camelCaseConvert } from "../utils/Helpers";
import packageJson from "../../package.json";
import socketIOClient from "socket.io-client";
import IntercomLauncher from "./styled/IntercomLauncher";

// Import components
import Dashboard from "./pages/dashboard/Dashboard";
import CampaignHistory from "./pages/campaignHistory/CampaignHistory";
import CampaignHistorySingle from "./pages/campaignHistory/CampaignHistorySingle";
import MemberSettings from "./pages/settings/MemberSettings";
import Login from "./pages/login/Login";
import CreateAccountWrapper from "./pages/login/createAccount/CreateAccountWrapper";
import Contacts from "./pages/contacts/Contacts";
import ContactsView from "./pages/contacts/ContactsView";
import CampaignSelectType from "./pages/campaignCreation/CampaignSelectType";
import AreaCampaign from "./pages/campaignCreation/AreaCampaign";
import NotFound from "./NotFound";
import LeadAdCreate from "./pages/campaignCreation/LeadAdCreate";
import PasswordReset from "./pages/login/PasswordReset";
import Thankyou from "./pages/campaignCreation/Thankyou";
import ContactStatuses from "./pages/contacts/ContactStatuses";
import EZTexting from "./pages/integrations/EZTexting";
import FieldMapping from "./pages/integrations/FieldMapping";
import IntegrationSettings from "./pages/integrations/IntegrationSettings";
import ConstantContact from "./pages/integrations/ConstantContact";
import Messaging from "./pages/messaging/Messaging";
import IntegrationList from "./pages/integrations/IntegrationList";
import IntegrationMessaging from "./pages/integrations/Messaging";
import Workflows from "./pages/workflows/Workflows";
import EditWorkflow from "./pages/workflows/EditWorkflow";
import CreateAd from "./pages/campaignCreation/CreateAd";
import { ThemeProvider } from "styled-components";
import { lightTheme } from "./Themes";
import GlobalStyle from "./GlobalStyle";

// Styled Components
import LoadingCircle from "./styled/LoadingCircle";
import Menu from "./nav/Menu";
import MobileMenu from "./nav/MobileMenu";
import {
  facebookGetInfo,
  permissionGet,
  permissionsCreate,
  permissionsUpdate,
  requiredPermissions
} from "../utils/FacebookAPI";
import { creditsCreate, creditsGetCompany } from "../utils/CreditsAPI";
import { messageCompanyUnread, socketInit } from "../utils/MessagesAPI";
import {
  userCreate,
  userAuth,
  userUpdate,
  getUserById,
  userLogout
} from "../utils/UserAPI";
import {
  companyCreate,
  companyGetById,
  companyUpdate
} from "../utils/CompanyAPI";
import {
  getStripeInfo,
  stripeCreate,
  stripeGetSubscriptions
} from "../utils/StripeAPI";
import PaymentFixStripeWrapper from "./pages/settings/PaymentFixStripeWrapper";
import ImitatingUserNotice from "./ImitatingUserNotice";

const allowedOrigins = [process.env.REACT_APP_BP_API];

const ModalTxtWrapper = styled.p`
  margin: 20px;
`;

const AppContainer = styled.div`
  padding-top: ${props => props.alert || "0"};
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: row;
`;

const ContentContainer = styled.div`
  width: 100%;
  overflow: auto;
  @media (max-width: 900px) {
    margin-bottom: 132px;
  }
  margin-top: ${props => (props.imitatingUser ? "50px" : "0")};
`;

const appVersion = packageJson.version;

// const socket = socketIOClient(process.env.REACT_APP_BP_API);

class App extends React.Component {
  state = {
    authUser: false,
    campaignHistory: [],
    globalNotification: [],
    notificationsLoaded: false,
    notificationsMargin: 0,
    loading: true,
    userProfile: {},
    userCompany: {},
    menuOpen: false,
    version: "",
    modalPermissionMissing: false,
    permissionsModalIgnored: false,
    memberFieldsEdit: false,
    modalDisconnected: false,
    adCredits: {},
    smsCredits: 0,
    creditsModalOpen: false,
    creditsPurpose: "",
    creditsRequired: 0,
    userLoading: true,
    companyLoading: true,
    modalMediaManager: false,
    unreadMessages: 0,
    theme: lightTheme,
    mobileView: window.matchMedia("(max-width: 978px)").matches,
    platformToken: {},
    businessPage: {},
    platformPermissions: [],
    registering: false,
    paymentSource: null,
    newUser: null,
    headerAuth: null,
    unreadUpdated: new Date(),
    paymentFailedMessage: "",
    modalCorrectPaymentMethod: false,
    paymentFailed: false,
    imitatingUser: false
  };

  constructor(props) {
    super(props);
    window.Intercom("boot", {
      app_id: "g0e1eyg3",
      custom_launcher_selector: "#custom_intercom"
    });
  }

  async componentDidMount() {
    const configRef = firestore.doc("config/master");
    try {
      const versionSnapshot = configRef.onSnapshot(
        doc => {
          if (doc.exists) {
            const docData = doc.data();
            const version = docData.version;
            this.setState({
              version
            });
            console.log("App version ", appVersion);
            if (version > appVersion) {
              this.alert({
                type: "info",
                msg: "New app version available. Please refresh your browser.",
                timeout: 0
              });
              console.log("App version is behind. A new version is available.");
            }
          }
        },
        err => {
          console.log("Getting version error", err);
        }
      );
      this.setState({ versionSnapshot });
    } catch (err) {
      console.log("Getting version error", err);
    }
    auth.onAuthStateChanged(async fireUser => {
      try {
        if (fireUser) {
          console.log("logging in");
          if (this.state.headerAuth !== null) {
            axios.interceptors.request.eject(this.state.headerAuth);
          }
          const fireUserUID = fireUser.uid;
          const fireToken = await auth.currentUser.getIdToken(
            /* forceRefresh */ true
          );

          if (this.state.registering) {
            try {
              console.log("registering");
              const tempUser = await this.createUser(fireToken, fireUserUID);
              console.log("tempUser", tempUser);
              const { token: tempToken } = await userAuth(fireToken);
              console.log("token", tempToken);
              let config = {
                headers: { Authorization: `Bearer ${tempToken}` }
              };
              const company = await this.createCompany(
                tempUser.email,
                tempUser._id,
                config
              );
              console.log("new company", company);
              const { _id: companyIdNew } = company;
              const updateResponse = await userUpdate(
                { ...tempUser, companyId: companyIdNew },
                config
              );
              const { token: newToken } = await userAuth(fireToken);
              console.log("new token", newToken);
              config = {
                headers: { Authorization: `Bearer ${newToken}` }
              };
              try {
                const stripeCustomer = await this.createStripeCustomer(
                  updateResponse,
                  companyIdNew,
                  config
                );
                console.log(
                  "update user",
                  updateResponse,
                  company,
                  stripeCustomer
                );
                this.toggleRegistering();
              } catch (err) {
                this.setState({
                  paymentFailedMessage: err.message,
                  modalCorrectPaymentMethod: true,
                  paymentFailed: true
                });
                console.log(err.message);
              }
            } catch (err) {
              console.log(err.message);
            }
          }
          const { token } = await userAuth(fireToken);
          console.log("fireToken", token);
          const headerAuth = axios.interceptors.request.use(
            config => {
              const { origin } = new URL(config.url);
              if (allowedOrigins.some(or => or.includes(origin))) {
                config.headers.authorization = `Bearer ${token}`;
              }
              return config;
            },
            error => Promise.reject(error)
          );
          this.setState({ headerAuth });
          axios.interceptors.response.use(
            response => response,
            error => {
              // Any status codes that falls outside the range of 2xx cause this function to trigger
              // Do something with response error
              const { response: { status, config: { url = "" } = {} } = {} } =
                error;
              if (
                allowedOrigins.some(or => url.includes(or)) &&
                status === 403
              ) {
                console.log("error", status, url);
                // clear server-side, user session-specific data before user logout
                userLogout().then(() => {
                  auth.signOut().then(() => {
                    this.clearDataOnLogout();
                    this.props.history.push(`/login`);
                  });
                });
              }
              return Promise.reject(error);
            }
          );
          const { user: tokenUser } = jwt(token);
          const { _id: uid, companyId, imitation } = tokenUser;
          const newUser = await getUserById(uid);
          this.setState({
            fId: fireUserUID,
            userProfile: newUser,
            userLoading: false,
            authUser: true,
            imitatingUser: imitation
          });
          if (companyId) {
            const newCompany = await companyGetById(companyId);
            this.setState({
              userCompany: newCompany,
              companyLoading: false,
              loading: false
            });
          }
          try {
            const stripeInfo = await getStripeInfo();
            if (!stripeInfo._id) {
              this.setState({ modalCorrectPaymentMethod: true });
            } else {
              stripeGetSubscriptions(stripeInfo.stripeId);
            }
          } catch (err) {
            this.setState({ modalCorrectPaymentMethod: true });
          }
          this.getFacebookInfo();
          this.getCredits();
          this.setState({ unreadUpdated: new Date() });
          this.getMessageUnread();
          // console.log("connecting socket");
          // socket.on("connect", async () => {
          //   console.log("socket connected");
          //   try {
          //     console.log("connecting");
          //     await socketInit();
          //     socket.on(`t-${companyId}`, data => {
          //       // something changed - refresh data
          //       console.log("message update");
          //       this.setState({ unreadUpdated: new Date() });
          //       this.getCredits();
          //       this.getMessageUnread();
          //     });
          //   } catch (err) {
          //     console.log(err.message);
          //   }
          // });
          // socket.on("disconnect", () => console.log("socket disconnected"));
          // socket.on("connect_error", err => {
          //   console.log("connect error", err);
          // });
          // socket.on("connect_failed", err => console.log(err));

          const { email } = newUser;
          // setup logrocket
          LogRocket.identify(uid, {
            email: email
          });

          //Add LogRocket session URL to possible Sentry Report
          LogRocket.getSessionURL(sessionURL => {
            Sentry.configureScope(scope => {
              scope.setExtra("LogRocket Session", sessionURL);
            });
          });
          if (!email.includes("boostpoint")) {
            ReactGA.set({ userId: uid });
          }

          // check facebook for updated facebook image

          // Sending ProfitWell script startup information
          const dataLayer = window.dataLayer;
          dataLayer.push({
            event: "start_pw",
            pw_user_email: fireUser.email
          });
        } else {
          this.setState({
            loading: false
          });
        }
      } catch (err) {
        console.log(err.message);
        if (err.message.includes("User not found by FID")) {
          console.log("force log out");
          await userLogout();
          auth.signOut().then(() => {
            this.clearDataOnLogout();
            this.props.history.push(`/login`);
            //add alert here
          });
        }
        this.setState({
          // authUser: false,
          loading: false
        });
      }
    });
    ReactGA.pageview(window.location.pathname);
    this.props.history.listen(location => {
      if (!location.pathname) {
        console.log("location.pathname is missing");
      }
      if (
        this.state.userProfile._id &&
        !this.state.userProfile.email.includes("boostpoint")
      ) {
        ReactGA.set({ userId: this.state.userProfile._id });
      }
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    });
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
    try {
      window.fbAsyncInit = async () => {
        FB.init({
          appId: "511351665866139",
          cookie: true,
          xfbml: true,
          version: "v8.0"
        });
        this.checkPermissions();
      };
    } catch (err) {
      console.log(err);
    }
    if (window.FB) {
      try {
        this.checkPermissions();
      } catch (err) {
        console.log(err);
      }
    }
    const handler = e => this.setState({ mobileView: e.matches });
    window.matchMedia("(max-width: 978px)").addEventListener("change", handler);
  }

  componentWillUnmount = () => {
    if (typeof this.state.versionSnapshot === "function") {
      this.state.versionSnapshot();
    }
    this.setState({ versionSnapshot: "" });
  };

  clearDataOnLogout = () => {
    if (this.state.headerAuth !== null) {
      axios.interceptors.request.eject(this.state.headerAuth);
    }
    this.setState({
      adCredits: {},
      businessPage: {},
      userProfile: {},
      userCompany: {},
      campaignHistory: [],
      platformToken: {},
      platformPermissions: [],
      globalNotification: [],
      notificationsLoaded: false,
      smsCredits: 0,
      stripeInfo: {},
      unreadMessages: 0,
      headerAuth: null,
      creditsPurpose: "",
      creditsRequired: 0,
      authUser: false,
      fId: "",
      modalCorrectPaymentMethod: false,
      paymentFailed: false,
      paymentFailedMessage: ""
    });
    window.Intercom("shutdown");
    window.Intercom("boot", {
      app_id: "g0e1eyg3",
      custom_launcher_selector: "#custom_intercom"
    });
    socket.close();
  };

  getCredits = async () => {
    try {
      const adCredits = await creditsGetCompany();
      if (adCredits.length) {
        const { messageCredits = 0, messageFixedCredits = 0 } = adCredits[0];
        const smsCredits = messageCredits + messageFixedCredits;
        this.setState({ adCredits: adCredits[0], smsCredits });
      } else {
        const { messageCredits = 0, messageFixedCredits = 0 } = adCredits;
        const smsCredits = messageCredits + messageFixedCredits;
        this.setState({ adCredits, smsCredits });
      }
    } catch (err) {
      console.log("err", err.message);
      if (err.message.includes(404)) {
        const {
          userCompany: { _id: companyId }
        } = this.state;
        const adCredits = await creditsCreate(companyId);
        const { messageCredits = 0, messageFixedCredits = 0 } = adCredits;
        const smsCredits = messageCredits + messageFixedCredits;
        this.setState({ adCredits, smsCredits });
      }
    }
  };

  getMessageUnread = async () => {
    try {
      const unreadMessages = await messageCompanyUnread();
      this.setState({ unreadMessages });
    } catch (err) {
      console.log("error getting unread messages", err.message);
    }
  };

  updateUserDoc = async newData => {
    try {
      const newUser = { ...this.state.userProfile, ...newData };
      this.setState({
        userProfile: newUser
      });
      const response = await userUpdate(newUser);
      console.log("user update response", response);
    } catch (err) {
      console.log("error updating user doc", err.message);
    }
  };

  updateCompanyDoc = async newData => {
    try {
      const { _id: companyId } = this.state.userCompany;
      this.setState({
        userCompany: {
          ...this.state.userCompany,
          ...newData
        }
      });
      const response = await companyUpdate({ ...newData, _id: companyId });
      console.log("company update response", response);
    } catch (err) {
      console.log("error updating company doc", err.message);
    }
  };

  companyGet = async () => {
    const { userProfile: { companyId } = {} } = this.state;
    const userCompany = await companyGetById(companyId);
    this.setState({ userCompany });
    return true;
  };

  async componentDidUpdate(prevProps, prevState) {
    // Typical usage (don't forget to compare props):
    // console.log('user update', this.state.userProfile);

    const {
      authUser,
      notificationsLoaded,
      userProfile,
      globalNotification,
      platformToken = {},
      businessPage = {}
    } = this.state;
    const {
      platformToken: prevPlatformToken = {},
      businessPage: prevBusinessPage = {}
    } = prevState;

    if (userProfile !== prevState.userProfile) {
      window.Intercom("shutdown");
      window.Intercom("boot", {
        app_id: "g0e1eyg3",
        email: userProfile.email,
        user_id: userProfile._id,
        name: userProfile.firstName + " " + userProfile.lastName,
        phone: userProfile.phoneNumber,
        custom_launcher_selector: "#custom_intercom"
      });
    }

    if (
      platformToken.token !== prevPlatformToken.token ||
      businessPage.adAccountId !== prevBusinessPage.adAccountId
    ) {
      try {
        window.fbAsyncInit = async () => {
          FB.init({
            appId: "511351665866139",
            cookie: true,
            xfbml: true,
            version: "v8.0"
          });
          this.checkPermissions();
        };
        if (window.FB) {
          this.checkPermissions();
        }
      } catch (err) {
        console.log(err);
      }
    }

    if (
      (platformToken.token !== prevPlatformToken.token ||
        businessPage.pageId !== prevBusinessPage.pageId) &&
      platformToken.isValid &&
      businessPage.pageId &&
      businessPage.pageToken &&
      platformToken.token
    ) {
      try {
        await getPagePicture(platformToken, businessPage);
        this.getFacebookInfo();
      } catch (err) {
        console.log("error getting image", err);
      }
    }
  }

  updatePaymentSource = newSource =>
    new Promise((resolve, reject) => {
      this.setState({ paymentSource: newSource }, () => {
        resolve(true);
      });
    });

  updateNewUser = user =>
    new Promise((resolve, reject) => {
      this.setState({ newUser: user, registering: true }, () => {
        resolve(true);
      });
    });

  createUser = async (fireToken, fId) => {
    const { newUser } = this.state;
    const user = await userCreate({ fId, newUser, fireToken });
    return user;
  };

  createCompany = async (email, ownerId, config) => {
    const company = await companyCreate(
      {
        name: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        phone: "",
        website: "",
        industry: "",
        twilioPhone: "",
        leadEmails: [email],
        contactEmails: [email],
        ownerId
      },
      config
    );
    return company;
  };

  createStripeCustomer = async (user, companyId, config) => {
    const { paymentSource } = this.state;
    try {
      const response = await stripeCreate(
        {
          companyId,
          ...(paymentSource && { newSource: paymentSource }),
          metadata: { first_name: user.firstName, last_name: user.lastName },
          email: user.email,
          subscriptions: [],
          invoices: []
        },
        config
      );
      console.log("customer response", response);
      await creditsCreate(companyId, config);
      return response;
    } catch (err) {
      console.log("error creating stripe customer", err.message);
    }
  };

  refreshStripe = () => {
    this.setState({
      modalCorrectPaymentMethod: false,
      paymentFailed: false,
      paymentFailedMessage: ""
    });
  };

  getFacebookInfo = async () => {
    try {
      const { businessPage = [], platformToken } = await facebookGetInfo();
      const platformPermissions = await permissionGet();
      let fbObject = { platformToken, platformPermissions };
      if (businessPage.length > 0) {
        await fetch(businessPage[0].image)
          .then(res => {
            fbObject = {
              ...fbObject,
              ...(businessPage[0] && {
                businessPage: {
                  ...businessPage[0],
                  image: res.ok ? res.url : ""
                }
              })
            };
          })
          .catch(console.log("Cannot retrieve a FB Business Page Image"));
      }
      this.setState(fbObject);
    } catch (err) {
      console.log(err.message);
    }
  };

  checkPermissions = () =>
    new Promise((resolve, reject) => {
      const {
        permissionsModalIgnored,
        platformToken: { platformId, token },
        platformPermissions = [],
        userProfile: { _id: userId }
      } = this.state;
      if (!window.FB) {
        console.log("Facebook is not loaded. Reloading Facebook");
        (function (d, s, id) {
          var js,
            fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) {
            return;
          }
          js = d.createElement(s);
          js.id = id;
          js.src = "//connect.facebook.net/en_US/sdk.js";
          fjs.parentNode.insertBefore(js, fjs);
        })(document, "script", "facebook-jssdk");
        window.fbAsyncInit = async () => {
          FB.init({
            appId: "511351665866139",
            cookie: true,
            xfbml: true,
            version: "v8.0"
          });
          this.checkPermissions();
        };
      } else {
        if (platformId && token) {
          FB.api(
            `/${platformId}/permissions`,
            {
              access_token: token
            },
            async response => {
              if (response && !response.error) {
                //stuff here
                const facebookPermissions = platformPermissions.find(
                  p => p.platform === "Facebook"
                );
                const permissionArray = response.data;
                const newPermissions = {};
                let fbAllPermissionsGranted = true;
                permissionArray.forEach(p => {
                  const permissionName = camelCaseConvert(p.permission);
                  const granted = p.status === "granted";
                  newPermissions[permissionName] = granted;
                  if (!granted && requiredPermissions.includes[p.permission]) {
                    fbAllPermissionsGranted = false;
                  }
                });
                if (!facebookPermissions) {
                  const permResponse = await permissionsCreate({
                    userId,
                    platform: "Facebook",
                    ...newPermissions
                  });
                  console.log("perResponse", permResponse);
                  this.setState({
                    platformPermissions: [...platformPermissions, permResponse]
                  });
                } else {
                  const permResponse = await permissionsUpdate({
                    ...facebookPermissions,
                    ...newPermissions
                  });
                  this.setState({
                    platformPermissions: [
                      ...platformPermissions.filter(
                        p => p._id !== permResponse._id
                      ),
                      permResponse
                    ]
                  });
                }
                if (!fbAllPermissionsGranted) {
                  if (!permissionsModalIgnored) {
                    this.setState({ modalPermissionMissing: true });
                  }
                  resolve(false);
                } else {
                  this.setState({
                    modalPermissionMissing: false,
                    modalDisconnected: false
                  });
                  resolve(true);
                }
              } else {
                console.log("error checking permission", response.error);
                console.error("permission error", response.error.message);
                if (!response.error.message.includes("empty response")) {
                  this.setState({ modalPermissionMissing: true });
                  resolve(false);
                }
              }
            }
          );
        }
      }
    });

  handleMenuOpenClick = open => {
    // Used to advance the tour when the menu is clicked
    const menuOpen = open || false;
    this.setState({ menuOpen });
  };

  handleMemberFields = value => {
    this.setState({ memberFieldsEdit: value });
  };

  openCreditModal = (
    creditsPurpose,
    creditsRequired,
    creditsCampaignBudget
  ) => {
    const newState = {
      creditsModalOpen: true
    };
    if (creditsPurpose !== undefined) {
      newState.creditsPurpose = creditsPurpose;
    }
    if (creditsRequired !== undefined) {
      newState.creditsRequired = creditsRequired;
    }
    if (creditsCampaignBudget !== undefined) {
      newState.creditsCampaignBudget = creditsCampaignBudget;
    }
    this.setState(newState);
  };

  closeCreditModal = () => {
    this.setState({ creditsModalOpen: false });
    this.getCredits();
  };

  adminControlLogout = async () => {
    try {
      const adminControlColl = firestore
        .collection("remoteControl")
        .where("program", "==", this.state.userProfile.uid);
      await adminControlColl.get().then(querySnapshot => {
        querySnapshot.forEach(
          doc => {
            doc.ref.delete();
          },
          err => {
            console.log("error removing the admin control doc", err);
          }
        );
      });
      window.location.reload(false);
    } catch (err) {
      console.log("error logging out admin control", err);
    }
  };

  openMediaManager = () => {
    this.setState({ modalMediaManager: true });
  };

  closeMediaManager = () => {
    this.setState({ modalMediaManager: false });
  };

  themeHandler = () => {
    if (this.state.theme === lightTheme) {
      // this.setState({ theme: darkTheme });
    } else {
      this.setState({ theme: lightTheme });
    }
  };

  toggleMenu = open => {
    const { menuOpen } = this.state;
    if (!open) {
      this.setState({ menuOpen: false });
    } else {
      this.setState({ menuOpen: !menuOpen });
    }
  };

  toggleRegistering = () => {
    const { registering } = this.state;
    this.setState({ registering: !registering });
  };

  alert = ({ msg, type, timeout = 3000 }) => {
    const { theme } = this.state;
    switch (type) {
      case "info":
        this.props.alert.info(msg, { timeout, theme });
        break;
      case "success":
        this.props.alert.success(msg, { timeout, theme });
        break;
      case "error":
        this.props.alert.error(msg, { timeout, theme });
        break;
      default:
        this.props.alert.show(msg, { timeout, theme });
    }
  };

  render() {
    const {
      authUser,
      campaignHistory,
      globalNotification,
      loading,
      userProfile,
      userCompany,
      creditsModalOpen,
      notificationsMargin,
      modalMediaManager,
      theme,
      mobileView,
      platformToken,
      businessPage,
      adCredits = {},
      smsCredits = 0,
      unreadUpdated
    } = this.state;
    const { adminControl } = userProfile;
    let { adBalance: adCreditsTotal = 0 } = adCredits;
    adCreditsTotal = Math.floor(adCreditsTotal);
    return (
      <ErrorBoundary>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <AppContainer
            className='appContainer'
            adminControl={adminControl}
            menuOpen={this.state.menuOpen}
          >
            {mobileView ? (
              <MobileMenu
                menuOpen={this.state.menuOpen}
                toggleMenu={this.toggleMenu}
                authUser={this.state.authUser}
                userCompany={this.state.userCompany}
                userProfile={this.state.userProfile}
                adCreditsTotal={adCreditsTotal}
                smsCredits={smsCredits}
                clearDataOnLogout={this.clearDataOnLogout}
                openMediaManager={this.openMediaManager}
                closeMediaManager={this.closeMediaManager}
                openCreditModal={this.openCreditModal}
                closeCreditModal={this.closeCreditModal}
                businessPage={businessPage}
                unreadMessages={this.state.unreadMessages}
                {...this.props}
              />
            ) : (
              <Menu
                menuOpen={this.state.menuOpen}
                toggleMenu={this.toggleMenu}
                authUser={this.state.authUser}
                userCompany={this.state.userCompany}
                userProfile={this.state.userProfile}
                adCreditsTotal={adCreditsTotal}
                smsCredits={smsCredits}
                clearDataOnLogout={this.clearDataOnLogout}
                openMediaManager={this.openMediaManager}
                closeMediaManager={this.closeMediaManager}
                openCreditModal={this.openCreditModal}
                closeCreditModal={this.closeCreditModal}
                businessPage={businessPage}
                unreadMessages={this.state.unreadMessages}
                {...this.props}
              />
            )}
            {loading ? (
              <LoadingCircle />
            ) : (
              <ContentContainer
                id='scrollTop'
                mobileView={mobileView}
                imitatingUser={this.state.imitatingUser}
              >
                <ScrollToTop />
                {/* <Breadcrumb path={this.props.location.pathname} /> */}
                {adminControl && (
                  <div
                    style={{
                      position: "fixed",
                      top: "0",
                      background: theme.HC1,
                      color: theme.ATextC,
                      zIndex: "10000",
                      width: "100%",
                      height: "2rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer"
                    }}
                    onClick={this.adminControlLogout}
                  >
                    <p>
                      This is an assumed view of this customer. Click here to
                      log out.
                    </p>
                  </div>
                )}
                <Switch>
                  <PrivateRoute
                    exact
                    path='/'
                    component={Dashboard}
                    userProfile={userProfile}
                    updateUserDoc={this.updateUserDoc}
                    userCompany={userCompany}
                    businessPage={businessPage}
                    platformToken={platformToken}
                    authUser={this.state.authUser}
                    clearDataOnLogout={this.clearDataOnLogout}
                    notifications={globalNotification}
                    notificationsMargin={notificationsMargin}
                    handleMenuOpenClick={this.handleMenuOpenClick}
                    memberFieldsEditing={this.state.memberFieldsEdit}
                    adCreditsAvailable={adCreditsTotal}
                    checkPermissions={this.checkPermissions}
                    openCreditModal={this.openCreditModal}
                    notificationsExist={
                      globalNotification && globalNotification.length > 0
                    }
                    openMediaManager={this.openMediaManager}
                    modalMediaManager={modalMediaManager}
                    unreadMessages={this.state.unreadMessages}
                    themeHandler={this.themeHandler}
                    theme={theme}
                    alert={this.alert}
                    // {...this.props}
                  />
                  <PrivateRoute
                    exact
                    path='/campaign-history'
                    userProfile={userProfile}
                    userCompany={userCompany}
                    authUser={authUser}
                    loading={loading}
                    component={CampaignHistory}
                    alert={this.alert}
                    {...this.props}
                  />
                  <PrivateRoute
                    exact
                    path='/campaign-history/:campaignId'
                    userProfile={userProfile}
                    userCompany={userCompany}
                    businessPage={businessPage}
                    authUser={authUser}
                    loading={loading}
                    component={CampaignHistorySingle}
                    alert={this.alert}
                  />
                  <Route
                    exact
                    path='/password-reset'
                    loading={loading}
                    component={PasswordReset}
                  />
                  <PrivateRoute
                    exact
                    path='/member-settings'
                    userProfile={userProfile}
                    userCompany={userCompany}
                    authUser={authUser}
                    loading={loading}
                    component={MemberSettings}
                    menuOpen={this.state.menuOpen}
                    handleMenuOpenClick={this.handleMenuOpenClick}
                    checkPermissions={this.checkPermissions}
                    handleMemberFields={this.handleMemberFields}
                    adCredits={adCredits}
                    updateUserDoc={this.updateUserDoc}
                    updateCompanyDoc={this.updateCompanyDoc}
                    businessPage={businessPage}
                    platformToken={platformToken}
                    getFacebookInfo={this.getFacebookInfo}
                    alert={this.alert}
                    registrationPaymentFailed={this.state.paymentFailed}
                    platformPermissions={this.state.platformPermissions}
                  />
                  <PrivateRoute
                    exact
                    path='/contacts/:contactId'
                    userProfile={userProfile}
                    userCompany={userCompany}
                    authUser={authUser}
                    loading={loading}
                    component={ContactsView}
                    checkPermissions={this.checkPermissions}
                    alert={this.alert}
                    updateUnreadMsgCount={this.getMessageUnread}
                    unreadUpdated={unreadUpdated}
                    smsCredits={smsCredits}
                  />
                  <PrivateRoute
                    exact
                    path='/contacts'
                    userProfile={userProfile}
                    userCompany={userCompany}
                    authUser={authUser}
                    loading={loading}
                    component={Contacts}
                    checkPermissions={this.checkPermissions}
                    campaignHistory={campaignHistory}
                    alert={this.alert}
                  />
                  <PrivateRoute
                    exact
                    path='/field-mapping'
                    userProfile={userProfile}
                    authUser={authUser}
                    loading={loading}
                    component={FieldMapping}
                    alert={this.alert}
                  />
                  <PrivateRoute
                    exact
                    path='/campaign-select'
                    userProfile={userProfile}
                    userCompany={userCompany}
                    userLoading={this.state.userLoading}
                    companyLoading={this.state.companyLoading}
                    authUser={authUser}
                    loading={loading}
                    component={CampaignSelectType}
                    checkPermissions={this.checkPermissions}
                    updateCompanyDoc={this.updateCompanyDoc}
                    alert={this.alert}
                  />
                  <PrivateRoute
                    exact
                    path='/campaign-select-type'
                    userProfile={userProfile}
                    userCompany={userCompany}
                    authUser={authUser}
                    loading={loading}
                    component={CampaignSelectType}
                    checkPermissions={this.checkPermissions}
                    alert={this.alert}
                  />
                  <PrivateRoute
                    exact
                    path='/area-campaign'
                    userProfile={userProfile}
                    userCompany={userCompany}
                    authUser={authUser}
                    loading={loading}
                    component={AreaCampaign}
                    checkPermissions={this.checkPermissions}
                    platformToken={platformToken}
                    businessPage={businessPage}
                    alert={this.alert}
                  />
                  <PrivateRoute
                    exact
                    path='/create-lead-ad'
                    userProfile={userProfile}
                    userCompany={userCompany}
                    authUser={authUser}
                    loading={loading}
                    component={LeadAdCreate}
                    checkPermissions={this.checkPermissions}
                    adCreditsAvailable={adCreditsTotal}
                    openCreditModal={this.openCreditModal}
                    platformToken={platformToken}
                    businessPage={businessPage}
                    platformPermissions={this.state.platformPermissions}
                    updateUserDoc={this.updateUserDoc}
                    alert={this.alert}
                  />
                  <PrivateRoute
                    exact
                    path='/create-ad'
                    userProfile={userProfile}
                    userCompany={userCompany}
                    authUser={authUser}
                    loading={loading}
                    component={CreateAd}
                    checkPermissions={this.checkPermissions}
                    adCreditsAvailable={adCreditsTotal}
                    openCreditModal={this.openCreditModal}
                    platformToken={platformToken}
                    businessPage={businessPage}
                    platformPermissions={this.state.platformPermissions}
                    alert={this.alert}
                  />
                  <PrivateRoute
                    exact
                    path='/thank-you'
                    userProfile={userProfile}
                    authUser={authUser}
                    loading={loading}
                    component={Thankyou}
                  />
                  <PrivateRoute
                    exact
                    path='/contact-statuses'
                    userProfile={userProfile}
                    userCompany={userCompany}
                    authUser={authUser}
                    loading={loading}
                    alert={this.alert}
                    component={ContactStatuses}
                  />
                  <PrivateRoute
                    exact
                    path='/messages'
                    userProfile={userProfile}
                    userCompany={userCompany}
                    authUser={authUser}
                    loading={loading}
                    unreadMessages={this.state.unreadMessages}
                    updateUnreadMsgCount={this.getMessageUnread}
                    alert={this.alert}
                    component={Messaging}
                    smsCredits={smsCredits}
                    unreadUpdated={unreadUpdated}
                    openCreditModal={this.openCreditModal}
                    getCredits={this.getCredits}
                  />
                  <PrivateRoute
                    exact
                    path='/integrations'
                    userProfile={userProfile}
                    userCompany={userCompany}
                    authUser={authUser}
                    loading={loading}
                    alert={this.alert}
                    component={IntegrationList}
                  />
                  <PrivateRoute
                    exact
                    path='/sms-connect'
                    userProfile={userProfile}
                    authUser={authUser}
                    loading={loading}
                    component={EZTexting}
                    alert={this.alert}
                  />
                  <PrivateRoute
                    exact
                    path='/constant-contact'
                    userProfile={userProfile}
                    authUser={authUser}
                    loading={loading}
                    component={ConstantContact}
                    alert={this.alert}
                  />
                  <PrivateRoute
                    exact
                    path='/integrations/messaging'
                    userProfile={userProfile}
                    authUser={authUser}
                    loading={loading}
                    component={IntegrationMessaging}
                    alert={this.alert}
                  />
                  <PrivateRoute
                    exact
                    path='/integrations/:integrationType'
                    userProfile={userProfile}
                    userCompany={userCompany}
                    authUser={authUser}
                    loading={loading}
                    component={IntegrationSettings}
                    alert={this.alert}
                    smsCredits={smsCredits}
                    companyGet={this.companyGet}
                  />
                  <PrivateRoute
                    exact
                    path='/workflows'
                    userProfile={userProfile}
                    userCompany={userCompany}
                    authUser={authUser}
                    loading={loading}
                    component={Workflows}
                    alert={this.alert}
                  />
                  <PrivateRoute
                    exact
                    path='/workflows/:workflowID'
                    userProfile={userProfile}
                    userCompany={userCompany}
                    authUser={authUser}
                    loading={loading}
                    component={EditWorkflow}
                    alert={this.alert}
                    notifications={globalNotification.length > 0 ? true : false}
                    updateCompanyDoc={this.updateCompanyDoc}
                  />
                  <PublicRouteHidden
                    exact
                    path='/login'
                    authUser={authUser}
                    userProfile={userProfile}
                    loading={loading}
                    component={Login}
                    alert={this.alert}
                  />
                  <PublicRouteHidden
                    exact
                    path='/create-account'
                    authUser={authUser}
                    userProfile={userProfile}
                    loading={loading}
                    component={CreateAccountWrapper}
                    alert={this.alert}
                    toggleRegistering={this.toggleRegistering}
                    registering={this.state.registering}
                    updatePaymentSource={this.updatePaymentSource}
                    updateNewUser={this.updateNewUser}
                  />
                  <Route component={NotFound} />
                </Switch>
              </ContentContainer>
            )}
            {authUser && (
              <OrderCreditsStripeWrapper
                userProfile={userProfile}
                creditsModalOpen={creditsModalOpen}
                creditsPurpose={this.state.creditsPurpose}
                adCredits={this.state.adCredits}
                closeCreditModal={this.closeCreditModal}
                creditsRequired={this.state.creditsRequired}
                creditsCampaignBudget={this.state.creditsCampaignBudget}
                alert={this.alert}
                authUser={this.state.authUser}
                getCredits={this.getCredits}
              />
            )}
            {!!modalMediaManager && (
              <MediaManager
                closeMediaManager={this.closeMediaManager}
                userProfile={userProfile}
                alert={this.alert}
              />
            )}
            <BpModal
              open={
                this.state.modalPermissionMissing &&
                platformToken.token &&
                this.props.location.pathname !== "/member-settings"
              }
              title={"Oops! We’re missing some permissions"}
              primaryAction={{
                btnLabel: "Go to Settings",
                action: () => this.props.history.push("/member-settings")
              }}
              secondaryAction={{
                btnLabel: "Ignore",
                action: () =>
                  this.setState({
                    modalPermissionMissing: false,
                    permissionsModalIgnored: true
                  })
              }}
              shouldCloseOnOverlayClick={false}
              shouldCloseOnEsc={false}
              contentLabel='Permissions Missing'
              body={
                <div>
                  <ModalTxtWrapper>
                    Boostpoint does not have all the permissions we need to your
                    Facebook account. Please disconnect and reconnect your
                    account to fix this issue. When connecting Facebook please
                    make sure to leave all the permission options enabled, as
                    Boostpoint only requests the permissions we absolutely need
                    to function on your behalf.
                  </ModalTxtWrapper>
                  <ModalTxtWrapper>
                    Please go to Settings and reconnect your account.
                  </ModalTxtWrapper>
                </div>
              }
              width='auto'
            />
            <BpModal
              open={this.state.modalDisconnected}
              title={"Oops! We lost connection to Facebook."}
              primaryAction={{
                btnLabel: "Go to Settings",
                action: () => {
                  this.setState({ modalDisconnected: false });
                  this.props.history.push("/member-settings");
                }
              }}
              secondaryAction={{
                btnLabel: "Ignore",
                action: () =>
                  this.setState({
                    modalDisconnected: false,
                    permissionsModalIgnored: true
                  })
              }}
              shouldCloseOnOverlayClick={false}
              shouldCloseOnEsc={false}
              contentLabel='Facebook Disconnected'
              body={
                <div>
                  <ModalTxtWrapper>
                    Something changed with our connection to Facebook. This can
                    happen if you changed your password or if Facebook changed
                    the session for security reasons.
                  </ModalTxtWrapper>
                  <ModalTxtWrapper>
                    Please go to Settings and reconnect your account.
                  </ModalTxtWrapper>
                </div>
              }
              width='auto'
            />
            <IntercomLauncher path={this.props.location.pathname} />
            <PaymentFixStripeWrapper
              alert={this.alert}
              userCompany={userCompany}
              userProfile={userProfile}
              modalCorrectPaymentMethod={this.state.modalCorrectPaymentMethod}
              paymentFailed={this.state.paymentFailed}
              refreshStripe={this.refreshStripe}
              paymentFailedMessage={this.state.paymentFailedMessage}
            />
            {!!this.state.imitatingUser && (
              <ImitatingUserNotice
                name={`${userProfile.firstName} ${userProfile.lastName}`}
                company={userCompany.name}
              />
            )}
          </AppContainer>
        </ThemeProvider>
      </ErrorBoundary>
    );
  }
}

export default withRouter(withAlert(App));
