import React from "react";
import * as _ from "underscore";
import { contactsGet, statusDelete } from "../../../../utils/ContactAPI";
import BpModal from "../../../elements/BpModal";
import { LoaderDotsSmall } from "../../../elements/Loaders";

const StatusDelete = props => {
  const { statusToDelete, loadingLeads } = props;

  const [contactCount, setContactCount] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [modalDeleteConfirm, setModalDeleteConfirm] = React.useState(false);
  const [modalContactsExist, setModalContactsExist] = React.useState(false);
  const [modalDefault, setModalDefault] = React.useState(false);

  React.useEffect(() => {
    const i = async () => {
      if (statusToDelete.isDefault) {
        setModalDefault(true);
        return;
      } else if (statusToDelete._id) {
        console.log("deleting");
        const contactsToUpdate = await contactsGet({
          statusId: statusToDelete._id
        });
        console.log("contactsToUpdate", contactsToUpdate);
        if (contactsToUpdate.length > 0) {
          setContactCount(contactsToUpdate.length);
          setModalContactsExist(true);
        } else {
          setContactCount(0);
          setModalDeleteConfirm(true);
        }
      }
      setLoading(false);
    };
    i();
  }, [statusToDelete]);

  const handleDelete = async () => {
    setLoading(true);
    await statusDelete(statusToDelete._id);
    setLoading(false);
    setModalDeleteConfirm(false);
    props.handleStatusRemoved();
  };

  const goToContacts = () => {
    props.history.push(`/contacts?statusId=${statusToDelete._id}`);
  };

  return (
    <div>
      <BpModal
        open={modalDeleteConfirm}
        title={`Confirm Status Delete`}
        primaryAction={{
          btnLabel: "Delete",
          action: handleDelete,
          loading: loadingLeads || loading
        }}
        secondaryAction={{
          btnLabel: "Cancel",
          action: () => {
            props.handleDeleteCancel();
            setModalDeleteConfirm(false);
          },
          loading: loadingLeads || loading
        }}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        contentLabel='Confirm Status Delete'
        body={
          loading || loadingLeads ? (
            <div>
              <LoaderDotsSmall />
            </div>
          ) : (
            <div>
              <p>
                Are you sure you want to delete{" "}
                <strong>
                  {statusToDelete.status
                    ? statusToDelete.status
                    : "this status"}
                </strong>
                ?
              </p>
            </div>
          )
        }
        width='auto'
      />
      <BpModal
        open={modalContactsExist}
        title={"This status still has contacts assigned"}
        primaryAction={{
          btnLabel: "Ok",
          action: () => {
            setModalContactsExist(false);
            props.handleDeleteCancel();
          },
          loading: loadingLeads
        }}
        secondaryAction={{
          btnLabel: "Go to Contacts",
          action: goToContacts
        }}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        contentLabel='This status still has contacts assigned'
        body={
          <div>
            <p>
              {contactCount} contacts have this status assigned. Statuses that
              have contacts assigned cannot be deleted. Please move all contacts
              to a different status before deleting.
            </p>
            <p>
              <a href={`/contacts?statusId=${statusToDelete._id}`}>
                Go to the Contacts page and choose a new status
              </a>{" "}
              for the assigned contacts. When this status no longer has
              contacts, come back here and delete it.
            </p>
          </div>
        }
        width='auto'
      />
      <BpModal
        open={modalDefault}
        title={`You cannot delete a default status`}
        primaryAction={{
          btnLabel: "Ok",
          action: () => setModalDefault(false),
          loading: loadingLeads
        }}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        contentLabel='You cannot delete a default status'
        body={
          <div>
            <p>
              The status you are attempting to delete is set as the default
              status for all new contacts. You must choose a new default status
              before you can delete this one.
            </p>
          </div>
        }
        width='auto'
      />
    </div>
  );
};

export default StatusDelete;
