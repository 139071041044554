export const InputBg = "#f7f7f7";
export const Border = "#f0f0f0";

const DeepPurple = "#3A0CA2";
const DeepPurpleTransparent = "rgba(58,12,162, .5)";
const LightPurple = "#7211B6";
const LightPurpleTransparent = "rgba(114,17,182,.5)";
const Magenta = "#b4269d";
const Red = "#ea6262";
const RedTransparent = "rgba(234,98,98,.1)";
const Blue = "#4361ed";
const Teal = "#57c8ef";
const Aqua = "#64edc2";
const AquaTransparent = "rgba(100,237,194,.1)";
const Yellow = "#ffb603";
const Gray = "#656565";
const DarkGray = "#383838";
const Black = "#262626";

export const lightTheme = {
  BGC: "#FFF",
  BGC2: "#FAFAFA",
  BGCAlt: "#f9fafa",
  BGCAlt2: "#eeeeee",
  ATextC: "#FAFAFA",
  TextC: Gray,
  BTextC: DarkGray,
  CTextC: Black,
  HC1: DeepPurple,
  HC2: LightPurple,
  HC3: Blue,
  HC4: Yellow,
  HC1Transparent: DeepPurpleTransparent,
  HB2Transparent: LightPurpleTransparent,
  HCErr: Magenta,
  HCErr2: Red,
  HCErr2Transparent: RedTransparent,
  HCSuc: Aqua,
  HCSucTransparent: AquaTransparent,
  HCLit: Gray,
  Border: "#DFD8E3",
  IconColor: "#D8D8D8"
};
