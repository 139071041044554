import React from "react";
import styled from "styled-components";

import {
  PreviewContainer,
  FormCard,
  HeadlineImage,
  SubmitButton
} from "../../styled/LeadAdPreview";

const TacTitle = styled.p`
  font-weight: bold;
  text-align: left;
  font-size: 18px;
`;

const TacCompany = styled.p`
  text-align: left;
  font-size: 14px;
  border-bottom: 1px solid #939393;
  padding-bottom: 16px;
`;

const TacFacebook = styled.p`
  font-size: 13px;
  color: #939393;
`;

const LeadAdPrivacy = props => {
  const { headline, coverImage, privacyPolicyUrl } = props;
  const [pageName, setPageName] = React.useState(props.pageName);

  React.useEffect(() => {
    if (!props.pageName) {
      setPageName("Preview Only. Please relink your Business Page");
    }
  }, [pageName, props.pageName]);

  return (
    <div>
      <PreviewContainer>
        {coverImage && <HeadlineImage src={coverImage} alt={headline} />}
        <FormCard contextTitle>
          <TacTitle>Terms and Conditions</TacTitle>
          <TacCompany>
            By clicking Submit, you agree to send your info to {pageName} and
            opt into text and email communication from {pageName}. At anytime
            reply STOP to unsubscribe.
          </TacCompany>
          <TacFacebook>
            By clicking Submit, you agree to send your info to {pageName} who
            agrees to use it according to their privacy policy. Facebook will
            also use it subject to our Data Policy, including to auto-fill forms
            for ads.{" "}
            <a href='https://www.facebook.com/policy.php'>
              View Facebook Data Policy
            </a>
            . <a href={privacyPolicyUrl}>Privacy Policy</a>.
          </TacFacebook>
        </FormCard>
        <SubmitButton>Submit</SubmitButton>
      </PreviewContainer>
    </div>
  );
};

export default LeadAdPrivacy;
