import axios from "axios";
import { axiosResponse, errorHandler } from "./Helpers";
import { getStripeInfo, isAttractActive } from "./StripeAPI";

const BP_API = process.env.REACT_APP_BP_API;

const newCampaign = {
  userId: "",
  name: "",
  fbCampaignId: "",
  credits: {},
  isActive: false,
  isDraft: true,
  companyId: "",
  addresses: [],
  radiusLinked: true,
  startDate: "",
  endDate: "",
  status: "DRAFT",
  media: [],
  radius: 10,
  type: "",
  targetingId: "",
  content: {},
  templateId: "",
  formTemplateId: "",
  form: {},
  inactiveReason: "",
  effectiveStatus: "",
  adReviewFeedback: ""
};

export const requiredPermissions = [
  "ads_management",
  "adsManagement",
  "business_management",
  "businessManagement",
  "email",
  "leads_retrieval",
  "leadsRetrieval",
  "manage_pages",
  "managePages",
  "public_profile",
  "publicProfile",
  "pages_manage_ads",
  "pagesManageAds",
  "pages_manage_metadata",
  "pagesManageMetadata",
  "pages_show_list",
  "pagesShowList",
  "pages_read_engagement",
  "pagesReadEngagement",
  "pages_read_user_content",
  "pagesReadUserContent"
];

export const getAllCompanyCampaigns = async () => {
  try {
    const campaigns = axiosResponse(
      await axios.get(`${BP_API}/v1/facebook/campaigns`)
    );
    return campaigns;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, "Error getting campaigns", message);
    throw new Error(message);
  }
};

export const facebookGetInfo = async () => {
  try {
    const facebookInfo = axiosResponse(
      await axios.get(`${BP_API}/v1/facebook/my/info`)
    );
    return facebookInfo;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, "Error getting facebook info", message);
    throw new Error(message);
  }
};

export const platformTokenCreate = async tokenObject => {
  try {
    const facebookInfo = axiosResponse(
      await axios.post(
        `${BP_API}/v1/facebook/create/platform-token`,
        tokenObject
      )
    );
    return facebookInfo;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, "Error creating platform token", message);
    throw new Error(message);
  }
};

export const getLongLivedToken = async shortToken => {
  try {
    const platformToken = axiosResponse(
      await axios.get(`${BP_API}/v1/facebook/token/long/${shortToken}`)
    );
    return platformToken;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const platformTokenUpdate = async tokenObject => {
  try {
    const platformToken = axiosResponse(
      await axios.put(`${BP_API}/v1/facebook/update/token`, tokenObject)
    );
    return platformToken;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const permissionsDelete = async fbUserId => {
  try {
    const response = axiosResponse(
      await axios.delete(`${BP_API}/v1/facebook/permissions/${fbUserId}`)
    );
    return response;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const businessPageCreate = async businessObject => {
  try {
    const businessPage = axiosResponse(
      await axios.post(
        `${BP_API}/v1/facebook/create/business-page`,
        businessObject
      )
    );
    return businessPage;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const adAccountCreate = async businessObject => {
  try {
    const businessPage = axiosResponse(
      await axios.post(
        `${BP_API}/v1/facebook/create/ad-account`,
        businessObject
      )
    );
    return businessPage;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const pagePermissionsRequest = async (businessObject, pageId) => {
  try {
    const businessPage = axiosResponse(
      await axios.post(
        `${BP_API}/v1/facebook/page/${pageId}/set-permissions`,
        businessObject
      )
    );
    return businessPage;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const businessPageUpdate = async businessObject => {
  try {
    const businessPage = axiosResponse(
      await axios.put(`${BP_API}/v1/facebook/update/page`, businessObject)
    );
    return businessPage;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const pagesGetByCompany = async companyId => {
  try {
    const businessPage = axiosResponse(
      await axios.get(`${BP_API}/v1/facebook/pageId/${companyId}`)
    );
    return businessPage;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const leadGenStatusCheck = async pageId => {
  try {
    if (!pageId) {
      throw new Error("pageId is required");
    }
    const statusResponse = axiosResponse(
      await axios.get(`${BP_API}/v1/facebook/gen-status/${pageId}`)
    );
    console.log("statusResponse", statusResponse);
    const { accepted = false } = statusResponse;
    return accepted;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const campaignGetById = async campaignId => {
  try {
    const templates = axiosResponse(
      await axios.get(`${BP_API}/v1/facebook/campaign/${campaignId}`)
    );
    return templates;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const campaignGetDrafts = async () => {
  try {
    const campaigns = axiosResponse(
      await axios.get(`${BP_API}/v1/facebook/my/campaign/drafts`)
    );
    return campaigns;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const campaignCreate = async campaign => {
  try {
    const campaignResponse = axiosResponse(
      await axios.post(`${BP_API}/v1/facebook/create/campaign`, {
        ...newCampaign,
        ...campaign
      })
    );
    return campaignResponse;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const campaignDelete = async campaignId => {
  try {
    const campaigns = axiosResponse(
      await axios.delete(`${BP_API}/v1/facebook/campaign/${campaignId}/draft`)
    );
    return campaigns;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const campaignUpdate = async campaignObject => {
  try {
    const campaign = axiosResponse(
      await axios.put(`${BP_API}/v1/facebook/update/campaign`, campaignObject)
    );
    return campaign;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const mediaTemplateCreate = async templateObject => {
  try {
    const template = axiosResponse(
      await axios.post(
        `${BP_API}/v1/facebook/media-template/create`,
        templateObject
      )
    );
    return template;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const mediaTemplateUpdate = async templateObject => {
  console.log("started mediaTemplateUpdate: ", templateObject);
  try {
    const template = axiosResponse(
      await axios.put(
        `${BP_API}/v1/facebook/media-template/update`,
        templateObject
      )
    );
    console.log("media templateUpdate: ", template);
    return template;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const mediaTemplateDelete = async templateId => {
  try {
    console.log("deleting", templateId);
    const template = axiosResponse(
      await axios.delete(
        `${BP_API}/v1/facebook/media-template/id/${templateId}`
      )
    );
    return template;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const mediaTemplateGetByCompany = async () => {
  try {
    const templates = axiosResponse(
      await axios.get(`${BP_API}/v1/facebook/media-template/company`)
    );
    return templates;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const mediaTemplateByIndustry = async industry => {
  try {
    const templates = axiosResponse(
      await axios.get(`${BP_API}/v1/facebook/media-template/stock/${industry}`)
    );
    return templates;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const mediaTemplateGetById = async templateId => {
  try {
    const template = axiosResponse(
      await axios.get(`${BP_API}/v1/facebook/media-template/id/${templateId}`)
    );
    return template;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const targetingGetById = async targetingId => {
  try {
    const targeting = axiosResponse(
      await axios.get(`${BP_API}/v1/facebook/targeting/id/${targetingId}`)
    );
    return targeting;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const targetingGetByIndustry = async industry => {
  try {
    const targeting = axiosResponse(
      await axios.get(`${BP_API}/v1/facebook/targeting/industry/${industry}`)
    );
    return targeting;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const targetingGetAll = async () => {
  try {
    const targeting = axiosResponse(
      await axios.get(`${BP_API}/v1/facebook/targeting/all`)
    );
    return targeting;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const targetingCreate = async targetingObject => {
  try {
    const targeting = axiosResponse(
      await axios.post(
        `${BP_API}/v1/facebook/targeting/create`,
        targetingObject
      )
    );
    return targeting;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const estimatedReachGet = async reachObject => {
  try {
    const estimatedReach = axiosResponse(
      await axios.post(`${BP_API}/v1/facebook/estimated-reach`, reachObject)
    );
    return estimatedReach;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const campaignSubmitInit = async campaignSubmit => {
  try {
    const campaignResponse = axiosResponse(
      await axios.post(`${BP_API}/v1/facebook/campaign/init`, campaignSubmit)
    );
    return campaignResponse;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const leadFormSubmit = async formData => {
  try {
    const formResponse = axiosResponse(
      await axios.post(`${BP_API}/v1/facebook/create/lead-form`, formData)
    );
    return formResponse;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    if (status > 400 && status < 500 && message.message) {
      throw new Error(message.message);
    }
    throw new Error(message);
  }
};

export const adSetCreate = async adSetData => {
  try {
    const response = axiosResponse(
      await axios.post(`${BP_API}/v1/facebook/create/ad-set`, adSetData)
    );
    return response;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const adCreativeCreate = async adCreativeData => {
  try {
    const response = axiosResponse(
      await axios.post(`${BP_API}/v1/facebook/create/ad-set`, adCreativeData)
    );
    return response;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const myPagesGet = async () => {
  try {
    const response = axiosResponse(
      await axios.get(`${BP_API}/v1/facebook/my/pages`)
    );
    return response;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const leadCostGet = async params => {
  try {
    const response = axiosResponse(
      await axios.get(`${BP_API}/v1/facebook/lead-cost`, { params })
    );
    return response;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const adsGetInsights = async params => {
  try {
    const response = axiosResponse(
      await axios.get(`${BP_API}/v1/facebook/accounts/ads`, { params })
    );
    return response;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const campaignsGet = async () => {
  try {
    const response = axiosResponse(
      await axios.get(`${BP_API}/v1/facebook/campaigns`)
    );
    return response;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const campaignInsightsGetById = async campaignId => {
  try {
    const response = axiosResponse(
      await axios.get(`${BP_API}/v1/facebook/campaign/insight/${campaignId}`)
    );
    return response;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const leadFormArchive = async leadFormId => {
  try {
    const response = axiosResponse(
      await axios.get(`${BP_API}/v1/facebook/lead-form/archive/${leadFormId}`)
    );
    return response;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const leadFormDelete = async formData => {
  try {
    const response = axiosResponse(
      await axios.post(`${BP_API}/v1/facebook/delete/lead-form`, formData)
    );
    return response;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const campaignPause = async fbCampaignId => {
  try {
    const response = axiosResponse(
      await axios.post(`${BP_API}/v1/facebook/campaign/pause`, { fbCampaignId })
    );
    return response;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const formTemplateGetById = async formId => {
  try {
    const response = axiosResponse(
      await axios.get(`${BP_API}/v1/facebook/form-template/${formId}`)
    );
    return response;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const formTemplatesGetByCompany = async () => {
  try {
    const response = axiosResponse(
      await axios.get(`${BP_API}/v1/facebook/form-template/company`)
    );
    return response;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const formTemplatesGetByIndustry = async industry => {
  try {
    const response = axiosResponse(
      await axios.get(`${BP_API}/v1/facebook/form-template/stock/${industry}`)
    );
    return response;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const formTemplateCreate = async templateObject => {
  try {
    const templateDoc = {
      description: "",
      headline: "",
      fields: [],
      privacyPolicyUrl: "",
      privacyPolicyValid: false,
      questionTitle: "",
      templateName: "",
      templateNameInternal: "",
      thankyouBody: "",
      thankyouButtonText: "",
      thankyouPhone: "",
      thankyouPhoneRegion: "",
      thankyouTitle: "",
      thankyouURL: "",
      companyId: "",
      isStock: false,
      industry: ""
    };
    const response = axiosResponse(
      await axios.post(`${BP_API}/v1/facebook/form-template/create`, {
        ...templateDoc,
        ...templateObject
      })
    );
    return response;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const formTemplateUpdate = async templateObject => {
  try {
    const response = axiosResponse(
      await axios.put(
        `${BP_API}/v1/facebook/form-template/update`,
        templateObject
      )
    );
    return response;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const formTemplateDelete = async templateId => {
  try {
    const response = axiosResponse(
      await axios.delete(
        `${BP_API}/v1/facebook/form-template/id/${templateId}`,
        templateId
      )
    );
    return response;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const customFieldsGetByCompany = async () => {
  try {
    const response = axiosResponse(await axios.get(`${BP_API}/v1/custom-form`));
    if (response.length > 0) {
      return response[0];
    }
    return response;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const customFieldsCreate = async customFields => {
  try {
    const response = axiosResponse(
      await axios.post(`${BP_API}/v1/custom-form`, customFields)
    );
    return response;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const customFieldsUpdate = async customFields => {
  try {
    const response = axiosResponse(
      await axios.put(
        `${BP_API}/v1/custom-form/${customFields._id}`,
        customFields
      )
    );
    return response;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};


export const permissionsCreate = async permissions => {
  const permissionData = {
    userId: "",
    platform: "",
    adsManagement: false,
    businessManagement: false,
    email: false,
    leadsRetrieval: false,
    managePages: false,
    pagesShowList: false,
    publicProfile: false,
    pagesManageAds: false,
    pagesManageMetadata: false,
    pagesReadEngagement: false,
    pagesReadUserContent: false
  };
  try {
    const response = axiosResponse(
      await axios.post(`${BP_API}/v1/platform-permissions`, {
        ...permissionData,
        ...permissions
      })
    );
    return response;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const permissionGet = async () => {
  try {
    const response = axiosResponse(
      await axios.get(`${BP_API}/v1/platform-permissions`)
    );
    return response;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const permissionsGetByUserId = async userId => {
  try {
    const response = axiosResponse(
      await axios.get(`${BP_API}/v1/platform-permissions/user/${userId}`)
    );
    return response;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const permissionsGetById = async id => {
  try {
    const response = axiosResponse(
      await axios.get(`${BP_API}/v1/platform-permissions/id/${id}`)
    );
    return response;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const permissionsUpdate = async permissionsDoc => {
  try {
    const response = axiosResponse(
      await axios.put(
        `${BP_API}/v1/platform-permissions/${permissionsDoc._id}`,
        permissionsDoc
      )
    );
    return response;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const pageDelete = async pageId => {
  try {
    const response = axiosResponse(
      await axios.delete(`${BP_API}/v1/facebook/page/${pageId}`)
    );
    return response;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const platformTokenDelete = async tokenId => {
  try {
    const response = axiosResponse(
      await axios.delete(
        `${BP_API}/v1/facebook/delete/platform-token/${tokenId}`
      )
    );
    return response;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const permissionCheck = async (permissions, permissionsToCheck) => {
  // permissions = platformPermissions document
  // permissionsToCheck = either 'all' or an array of permissions
  let hasPermission = true;
  Object.keys(permissions).forEach(p => {
    if (
      (permissionsToCheck === "all" || permissionsToCheck.includes(p)) &&
      !permissions[p]
    ) {
      hasPermission = false;
    }
  });
  return hasPermission;
};

export const campaignValidation = async ({
  businessPage,
  platformPermissions
}) => {
  const { adAccountId } = businessPage;
  const stripeInfo = await getStripeInfo();
  console.log("stripe active", stripeInfo);
  const { stripeId, subscriptions } = stripeInfo;
  if (!stripeId) {
    return {
      type: "error",
      msg: "Credit card information is missing or invalid. Please enter a valid credit card from your My Settings page.",
      timeout: 0
    };
  }
  if (!isAttractActive(subscriptions)) {
    return {
      type: "error",
      msg: "You do not have an active subscription. Please activate your subscription from your My Settings page.",
      timeout: 0
    };
  }
  if (!adAccountId) {
    return {
      type: "error",
      msg: "Your Facebook account is not connected or is incomplete. Please connect your Facebook page from your My Settings page.",
      timeout: 0
    };
  }
  if (!permissionCheck(platformPermissions, "all")) {
    return {
      type: "error",
      msg: "Your Facebook account is missing permissions. Please reconnect your Facebook page from your My Settings page.",
      timeout: 0
    };
  }
  return { type: "success" };
};
