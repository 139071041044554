import React from "react";
import { Line } from "react-chartjs-2";
import styled from "styled-components";

// Styled Components
import Module from "../../styled/Module";

const ChartNoData = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  width: 30em;
  max-width: 100%;
  height: 7em;
  margin: 4em auto;
  text-align: center;
  font-size: 14px;
  background: url("../images/sample_chart_green_half.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.75);
  background-blend-mode: lighten;
`;
const ChartTitle = styled.span`
  font-weight: 600;
  width: 100%;
  text-transform: uppercase;
`;

class LineChart extends React.Component {
  state = {
    startDate: null,
    endDate: null,
    focusedInput: null,
    chartsLoading: false
  };

  render() {
    const {
      labelValue = [],
      dataValue = [],
      backgroundColor,
      borderColor,
      prevLabelValue,
      prevBackgroundColor,
      prevBorderColor,
      prevDataValue
    } = this.props;

    const data = {
      labels: labelValue,
      datasets: [
        {
          label: labelValue,
          fill: true,
          backgroundColor: backgroundColor,
          borderColor: borderColor,
          borderWidth: 2,
          data: dataValue
        },
        {
          label: prevLabelValue,
          fill: true,
          backgroundColor: prevBackgroundColor,
          borderColor: prevBorderColor,
          borderWidth: 2,
          data: prevDataValue
        }
      ]
    };
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        callbacks: {
          title: function () {},
          label: function (tooltipItem, data) {
            const dataset = data.datasets[tooltipItem.datasetIndex];
            const index = tooltipItem.index;
            return dataset.label[index] + ": " + dataset.data[index];
          }
        }
      },
      elements: {
        line: {
          tension: 0.1 // disables bezier curves
        },
        point: {
          radius: 0,
          hitRadius: 10
        }
      },
      legend: {
        display: false
      },
      scales: {
        yAxes: [
          {
            display: false,
            ticks: {
              beginAtZero: true
            }
          }
        ],
        xAxes: [
          {
            display: false,
            ticks: {
              beginAtZero: true
            }
          }
        ]
      }
    };
    if (this.props.aspectRatio) {
      options.maintainAspectRatio = true;
      options.aspectRatio = this.props.aspectRatio;
    }

    return (
      <Module className='line-chart'>
        {dataValue.length > 0 ? (
          <Line data={data} options={options} />
        ) : (
          <ChartNoData className='not-enough-data'>
            <ChartTitle>Not Enough Data</ChartTitle>
            <span>
              Please select another date range or start by running a new
              campaign.
            </span>
          </ChartNoData>
        )}
      </Module>
    );
  }
}

export default LineChart;
