import React, { useContext } from "react";
import { useTable } from "react-table";
import moment from "moment-timezone";
import styled from "styled-components";
import { firstBreakpoint } from "../../styled/PhoneNumberProvision";
import { ThemeContext } from "styled-components";
import WorkflowModal from "./WorkflowModal";

import { getAllCompanyCampaigns } from "../../../utils/FacebookAPI";

import {
  Icon,
  H3,
  FilterHeader,
  FilterHeaderLeft,
  FlowHeaderDivider
} from "./styles";
import { Button } from "../../styled/Buttons";

const TableStyle = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const Head = styled.thead`
  background-color: #ebe8ed;
  display: none;
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: table-header-group;
  }
`;

const TH = styled.th`
  color: #3a0ca2;
  font-weight: 500;
  padding: 20px 15px;
  display: none;
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: table-cell;
  }
  &.actions {
    text-align: right;
    padding-right: 25px;
  }
  &.active {
    text-align: center;
  }
`;

const THCheck = styled.th`
  padding: 0 13px;
  width: 48px;
  display: none;
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: table-cell;
  }
`;

const TDCheck = styled.td`
  padding: 0 13px;
  margin-top: 6px;
  width: 48px;
  display: none;
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: block;
  }
`;

const CheckboxWrapper = styled.div`
  input {
    cursor: pointer;
    opacity: 0;
  }
  label {
    cursor: pointer;
    position: relative;
    font-style: italic;
    &::before {
      content: "";
      display: inline-block;
      height: 20px;
      width: 20px;
      border-radius: 3px;
      border: 1px solid ${props => props.theme.TextC};
      position: absolute;
      top: -3px;
      left: -20px;
      background: ${props => props.theme.BGC};
    }
    &::after {
      content: "";
      display: inline-block;
      height: 7px;
      width: 12px;
      border-left: 2px solid;
      border-bottom: 2px solid;
      transform: rotate(-45deg);
      position: absolute;
      left: -16px;
      top: 2px;
      color: ${props => props.theme.HC1};
    }
  }
  input + label::after {
    content: none;
  }
  input:checked + label::before {
    background: white;
    border-color: ${props => props.theme.TextC};
  }
  input:checked + label::after {
    content: "";
  }
`;

const TD = styled.td`
  text-align: left;
  padding: 0 15px;
  display: ${props => (props.mobileHide ? "none" : "block")};
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: table-cell;
  }
  &.name {
    font-weight: 510;
  }
  &.name,
  &.created-by,
  &.date-updated {
    text-align: left;
  }
`;

const BodyRow = styled.tr`
  color: ${props => props.color};
  border-bottom: 1px solid #d9d9da;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: table-row;
  }
  &:last-of-type {
    border-bottom: none;
  }
  td {
    padding-top: 16px;
    padding-bottom: 16px;
  }
`;

const mobileHiddenColumns = ["created-by", "date-updated", "active"];

const EditCampaignFilter = props => {
  const {
    columns,
    data,
    setOpenEditCampaign,
    selectedCampaigns = [],
    updateSelectedCampaigns,
    applyFilter
  } = props;

  const themeContext = useContext(ThemeContext);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <WorkflowModal
      open={true}
      onCloseMethod={() => setOpenEditCampaign()}
      body={
        <div>
          <FilterHeader>
            <FilterHeaderLeft>
              <Icon small className='material-icons-outlined'>
                filter_alt
              </Icon>
              <H3>Filters</H3>
              <FlowHeaderDivider></FlowHeaderDivider>
              <H3>Select Campaigns</H3>
            </FilterHeaderLeft>
          </FilterHeader>
          <TableStyle {...getTableProps()}>
            <Head>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  <THCheck></THCheck>
                  {headerGroup.headers.map(column => (
                    <TH
                      {...column.getHeaderProps()}
                      className={column.id ? column.id : ""}
                    >
                      {column.render("Header")}
                    </TH>
                  ))}
                </tr>
              ))}
            </Head>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row);
                return (
                  <BodyRow
                    {...row.getRowProps()}
                    color={
                      row.values.active === "draft"
                        ? themeContext.TextC
                        : themeContext.HC1
                    }
                  >
                    <TDCheck>
                      <CheckboxWrapper
                        onClick={() => updateSelectedCampaigns(row.original.id)}
                      >
                        <input
                          type='checkbox'
                          checked={selectedCampaigns.includes(row.original.id)}
                          onChange={() =>
                            updateSelectedCampaigns(row.original.id)
                          }
                        />
                        <label />
                      </CheckboxWrapper>
                    </TDCheck>
                    {row.cells.map(cell => (
                      <TD
                        {...cell.getCellProps()}
                        className={cell.column.id ? cell.column.id : ""}
                        mobileHide={
                          mobileHiddenColumns.includes(cell.column.id)
                            ? true
                            : false
                        }
                      >
                        {/* {console.log(cell)} */}
                        {cell.render("Cell")}
                      </TD>
                    ))}
                  </BodyRow>
                );
              })}
            </tbody>
          </TableStyle>
          <Button
            onClick={() => applyFilter()}
            small
            disabled={selectedCampaigns.length === 0}
          >
            APPLY
          </Button>
        </div>
      }
    />
  );
};

const CampaignList = props => {
  const { setOpenEditCampaign, filters, addFilter, timezone } = props;

  const [campaignList, setCampaignList] = React.useState([]);
  const [selectedCampaigns, setSelectedCampaigns] = React.useState([]);

  React.useEffect(() => {
    const getCampaigns = async () => {
      const campaigns = await getAllCompanyCampaigns();
      console.log("campaigns: ", campaigns);
      setCampaignList(campaigns);
    };
    getCampaigns();
  }, []);

  React.useEffect(() => {
    filters.forEach(item => {
      if (item.type === "CAMPAIGN") {
        const { campaignIds } = item;
        setSelectedCampaigns(campaignIds);
      }
    });
  }, [filters]);

  const updateSelectedCampaigns = campaignId => {
    console.log("campaignId: ", campaignId);
    let newSelectedCampaigns = [...selectedCampaigns];
    const exists = newSelectedCampaigns.includes(campaignId);
    console.log("exists: ", exists);
    if (!exists) {
      newSelectedCampaigns = [...selectedCampaigns, campaignId];
      console.log("newSelectedCampaigns: ", newSelectedCampaigns);
    } else {
      const index = newSelectedCampaigns.findIndex(id => id === campaignId);
      console.log("index: ", index);
      newSelectedCampaigns.splice(index, 1);
      console.log("newSelectedCampaigns: ", newSelectedCampaigns);
    }
    setSelectedCampaigns(newSelectedCampaigns);
  };

  const applyFilter = () => {
    const filter = {
      campaignIds: [...selectedCampaigns],
      tagsIds: [],
      statusIds: [],
      type: "CAMPAIGN"
    };
    addFilter(filter);
  };

  const headers = [
    {
      Header: "Name",
      accessor: "name",
      id: "name"
    },
    { Header: "Start Date", accessor: "startDate", id: "start-date" },
    { Header: "End Date", accessor: "endDate", id: "end-date" }
  ];
  const tableData = campaignList.map(campaign => {
    console.log("campaign: ", campaign);
    console.log("timezone: ", timezone);
    let startFormatted = "";
    let endFormatted = "";
    if (campaign.startDate) {
      const startMoment = moment(campaign.startDate).tz(timezone);
      startFormatted = startMoment.format("MMM DD, YYYY");
    }
    if (campaign.endDate) {
      const endMoment = moment(campaign.endDate).tz(timezone);
      endFormatted = endMoment.format("MMM DD, YYYY");
    }
    return {
      id: campaign._id,
      name: campaign.name,
      startDate: startFormatted,
      endDate: endFormatted
    };
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = React.useMemo(() => tableData, [campaignList]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = React.useMemo(() => headers, [campaignList]);

  return (
    <EditCampaignFilter
      columns={columns}
      data={data}
      setOpenEditCampaign={setOpenEditCampaign}
      updateSelectedCampaigns={updateSelectedCampaigns}
      selectedCampaigns={selectedCampaigns}
      applyFilter={applyFilter}
      history={props.history}
    />
  );
};
export default CampaignList;
