import React from "react";
import * as Sentry from "@sentry/browser";
import { PickerOverlay } from "filestack-react";

const MessageFileSelector = props => {

  const mediaError = err => {
    console.log("image error", err);
    Sentry.captureException(err);
  };


  return (
    <PickerOverlay
      apikey='AF3TGK2TiG0ENSpQlYA1nz'
      onSuccess={props.chooseMedia}
      onError={mediaError}
      pickerOptions={{
        onClose: () => props.toggleShowUploader(true),
        transformations: {
          circle: false,
          crop: true,
          rotate: true
        },
        accept: ["image/*", "video/*"],
        acceptFn: (file, options) =>
          options
            .mimeFromMagicBytes(file.originalFile)
            .then(res => {
              if (res === "application/pdf") {
                return Promise.resolve();
              }
              const mimeArray = res.split("/");
              if (mimeArray[0] !== "image") {
                return Promise.reject(
                  "Only images and PDF files are allowed in messages."
                );
              } else if (
                !["jpeg", "jpg", "png", "gif"].includes(
                  mimeArray[1]
                )
              ) {
                return Promise.reject(
                  "That image type is not allowed. Only jpeg, png, and gif images are allowed."
                );
              }
              return Promise.resolve();
            }),
        maxSize: 5 * 1024 * 1024 // 5MB file size limit
      }}
    />
  )};

export default MessageFileSelector;
