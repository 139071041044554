import React from "react";
import styled from "styled-components";
import { Button } from "../../../styled/Buttons";
import { LoaderDotsSmall } from "../../../elements/Loaders";
import { firstBreakpoint } from "../../../styled/PhoneNumberProvision";

const Wrapper = styled.div`
  padding: 50px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const H1 = styled.h1`
  font-size: 1em;
  margin-bottom: 1.5em;
  @media screen and (min-width: ${firstBreakpoint}px) {
    font-size: 1.2em;
    margin-bottom: 1em;
  }
`;

const Number = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.BGC};
`;

const NumberIcon = styled.span`
  font-size: 40px;
  margin-right: 18px;
  color: ${props => props.theme.TextC};
`;

const SelectedNumber = styled.span`
  font-weight: bold;
  font-size: 1.3em;
  letter-spacing: 0.1em;
  color: ${props => props.theme.HC1};
  @media screen and (min-width: ${firstBreakpoint}px) {
    font-size: 1.6em;
    letter-spacing: 0.2em;
  }
`;

const Question = styled.p`
  font-size: 0.8em;
  @media screen and (min-width: ${firstBreakpoint}px) {
    font-size: 1em;
  }
`;

const Disclaimer = styled.p`
  font-size: 0.8em;
  max-width: 440px;
  margin: 1em auto;
  @media screen and (min-width: ${firstBreakpoint}px) {
    font-size: 0.9em;
  }
`;

const NumberSelect = props => {
  const { selectedNumber, next, back, bpConnectActive, loading } = props;
  return (
    <Wrapper>
      <div>
        <H1>The number you selected is:</H1>
        <Number>
          <NumberIcon className='material-icons'>smartphone</NumberIcon>
          <SelectedNumber>{selectedNumber.friendlyName}</SelectedNumber>
        </Number>
        <Question>
          <strong>Is this the number you want to use?</strong>
        </Question>
        {bpConnectActive && (
          <Disclaimer>
            By clicking 'Confirm' you agree to Boostpoint Connect Terms of Use
            and Privacy Policy. Once you confirm a number, you will not be able
            to change it.
          </Disclaimer>
        )}
        <Button onClick={back}>Select a Different Number</Button>
        <Button onClick={next} solid disabled={loading}>
          {bpConnectActive ? "Confirm" : "Yes, Continue"}
          {loading && <LoaderDotsSmall />}
        </Button>
      </div>
    </Wrapper>
  );
};

export default NumberSelect;
