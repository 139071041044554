import React from "react";
import styled from "styled-components";
import { contactSource } from "../../../../utils/Helpers";
import { LoaderDotsSmall } from "../../../elements/Loaders";
import { Button, TxtButton } from "../../../styled/Buttons";
import { ContactFieldset } from "../../../styled/Contacts";
import {
  InputBox,
  InputBoxWrapper,
  InputContainer,
  InputLabel
} from "../../../styled/Input";
import CardTitle from "../../../styled/TitleCard";
import { InputError } from "../../workflows/styles";
import StatusDropdown from "../contactStatus/StatusDropdown";
import ContactTags from "../ContactTags";
import ButtonActions from "./ButtonActions";

const ContactCard = styled.div`
  margin: 0;
  padding: 1em;
  @media only screen and (min-width: 901px) {
    background-color: ${props => props.theme.BGC};
  }
`;

const Title = styled.h2`
  margin: 25px auto;
  color: ${props => props.theme.HC1};
  font-weight: 600;
  font-size: 34px;
  @media only screen and (min-width: 901px) {
    margin: 90px auto 25px;
  }
`;

const Link = styled.a`
  color: ${props => props.theme.HC1};
  font-style: italic;
  font-size: 0.8em;
  margin: 6px 0 0;
`;

const ButtonWrapper = styled.div`
  min-height: 68px;
  @media only screen and (min-width: 901px) {
    grid-column: 1/3;
  }
`;

const OtherInfo = props => {
  const { 
    contact, 
    contactStatuses, 
    errMessages, 
    contactTags = [], 
    companyTags 
  } = props;
  const { created, campaignName = "" } = contact;
  
  const [createdFormatted, setCreatedFormatted] = React.useState("");
  const [statusId, setStatusId] = React.useState("");
  const [source, setSource] = React.useState("");
  const [activeTags, setActiveTags] = React.useState(contactTags);
  const [changed, setChanged] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  
  React.useEffect(() => {
    if (contact.statusId) {
      setStatusId(contact.statusId);
    }
    if (contact.source) {
      setSource(contact.source);
    }
  }, [contact]);

  React.useEffect(() => {
    if (created) {
      const createdDate = new Date(created);
      const createdFormattedNew =
        createdDate.getMonth() +
        1 +
        "-" +
        createdDate.getDate() +
        "-" +
        createdDate.getFullYear();
      setCreatedFormatted(createdFormattedNew);
    }
  }, [created]);

  React.useEffect(() => {
    setActiveTags(contactTags);
  }, [contactTags])

  React.useEffect(() => {
    setTimeout(() => {
      setSuccess(false);
    }, 5000 )
  }, [success])

  const handleStatusChange = async value => {
    setStatusId(value)
    setChanged(true);
  };

  const handleTagsChange = async tagIds => {
    const newTags = companyTags.filter(t => tagIds.includes(t._id));
    setActiveTags(newTags);
    setChanged(true);
  };

  const handleReset = () => {
    setChanged(false);
    setStatusId(contact.statusId);
    setSource(contact.source);
    setActiveTags(contactTags);
  }

  const handleSave = async () => {
    setLoading(true);
    try {
      await props.updateContact({
        ...contact,
        statusId,
        tagIds: activeTags.map(t => t._id)
      });
      setLoading(false);
      setChanged(false);
      setSuccess(true);
    } catch (err) {
      setLoading(false);
      console.log('err saving contact', err.message);
      props.alert({
        type: 'error',
        mas: 'Something went wrong saving the field. Please try again. If you continue to get this message, contact Boostpoint at help@boostpoint.com'
      })
    }
  }

  return (
    <ContactCard>
        <CardTitle>
          <Title uppercase>Other Info</Title>
        </CardTitle>
        <ContactFieldset>
          <InputContainer>
            <InputLabel className='contact-status'>Contact Status</InputLabel>
            <InputBoxWrapper>
              <StatusDropdown
                statusValue={statusId}
                contactStatuses={contactStatuses}
                onChange={handleStatusChange}
              />
            </InputBoxWrapper>
            <InputError>{errMessages.leadStatus}</InputError>
            <Link href='/contact-statuses'>Customize status list</Link>
          </InputContainer>
          <InputContainer>
            <InputLabel>Source</InputLabel>
            <InputBoxWrapper>
              {source && (
                <InputBox
                  type='text'
                  name='source'
                  value={contactSource[source]}
                  readOnly
                />
              )}
            </InputBoxWrapper>
          </InputContainer>
          <InputContainer>
            <InputLabel>Campaign</InputLabel>
            <InputBoxWrapper>
              <InputBox
                type='text'
                name='campaignName'
                value={campaignName}
                readOnly
              />
            </InputBoxWrapper>
          </InputContainer>
          <InputContainer>
            <InputLabel>Created Date</InputLabel>
            <InputBoxWrapper>
              <InputBox
                type='text'
                name='created'
                value={createdFormatted}
                readOnly
              />
            </InputBoxWrapper>
          </InputContainer>
          <InputContainer>
            <ContactTags
              alert={props.alert}
              contactTags={activeTags}
              userProfile={props.userProfile}
              companyId={props.companyId}
              contact={contact}
              contactId={contact._id}
              companyTags={props.companyTags}
              showLabel={true}
              handleTagsChange={handleTagsChange}
              getTags={props.getTags}
            />
          </InputContainer>
          <ButtonActions 
            changed={changed}
            loading={loading}
            success={success}
            handleReset={handleReset}
            handleSave={handleSave}
          />
        </ContactFieldset>
      </ContactCard>
  );
};

export default OtherInfo;
