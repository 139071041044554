import styled from "styled-components";

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 1em auto;
  height: 17em;
  max-height: 48vh;
  max-width: 40em;
  overflow-y: scroll;
  flex-flow: ${props => (props.drag ? "column" : "")};
  flex-basis: ${props => (props.drag ? "100%" : "")};

  @media screen and (max-width: 580px) {
    justify-content: center;
    flex-direction: column;
    width: 80vw;
    height: 15em;
    margin: 0 auto 1em;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .desktop {
    @media screen and (max-width: 580px) {
      display: none;
    }
  }
  .mobile {
    @media screen and (min-width: 581px) {
      display: none;
    }
  }
`;

export default ImageWrapper;
