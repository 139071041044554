import React from "react";
import styled from "styled-components";
import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useColumnOrder
} from "react-table";
import moment from "moment-timezone";
import { useExportData } from "react-table-plugins";
import MultiSelect from "react-multi-select-component";
import XLSX from "xlsx";
/* cspell: disable-next-line */
import Papa from "papaparse";
import JsPDF from "jspdf";
import * as _ from "underscore";
/* cspell: disable-next-line */
import "jspdf-autotable";
import { parseFullName } from "parse-full-name";
import matchSorter from "match-sorter";
import DateRangePickerWrapper from "../../elements/PresetDateRangePicker";
// Styled Components
import SectionGroup from "../../styled/SectionGroup";
import { Button } from "../../styled/Buttons";
import {
  InputContainer,
  InputBoxWrapper,
  InputBox,
  Dropdown
} from "../../styled/Input";
import { TitleWrapper, TitleLeft, TitleRight } from "../../styled/Layout";
import { H1 } from "../../styled/Fonts";
import Search from "../../elements/Search";
import Checkbox from "../../elements/Checkbox";
import { LoaderDotsSmall } from "../../elements/Loaders";

const TableWrapper = styled.div`
  width: 100%;
  table {
    border-collapse: collapse;
    margin: 0 auto 30px;
    @media only screen and (max-width: 1220px),
      (min-device-width: 768px) and (max-device-width: 1024px) {
      margin: 0;
    }
  }
`;

const TitleWrapperContact = styled(TitleWrapper)`
  @media only screen and (max-width: 1220px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    flex-direction: column;
  }
`;

const ContactHeaderWrapper = styled.div`
  @media only screen and (max-width: 1220px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: 1px solid ${props => props.theme.BGC};
  }
`;

const ContactTitleWrapper = styled.div`
  text-transform: uppercase;
  margin: 0 20px 0 0;
  text-align: left;
`;

const ContactCount = styled.p`
  font-style: italic;
  text-align: left;
  margin-top: 0;
  text-transform: none;
`;

const ContactTableActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* @media only screen and (max-width: 1220px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    display: none;
  } */
`;

const BulkActionButton = styled(Button)`
  margin: 0.6rem 1rem;
  position: relative;
  @media only screen and (max-width: 1220px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    display: none;
  }
`;

const ClearFilters = styled.p`
  /* height: 2.3rem; */
  padding: 0 1rem 0 1.5rem;
  width: 100%;
  margin: 1rem 0 1rem;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  font-weight: normal;
  font-size: 15px;
  font-style: italic;
  letter-spacing: 1px;
  color: ${props => props.theme.HC1};
  text-align: center;
  cursor: pointer;
`;

const TableActions = styled.div`
  padding: 10px 0;
  @media only screen and (max-width: 1220px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    grid-template-columns: 1fr;
    border: none;
  }
`;

const BulkActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  @media only screen and (max-width: 1220px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    padding-right: 1em;
  }
`;

const FilterToggle = styled.div`
  position: relative;
  cursor: pointer;
  width: 28px;
  height: 28px;
`;

const FilterColumnIcon = styled.span`
  color: ${props => (props.filtered ? props.theme.HC1 : "#ccc")};
  /* position: absolute; */
  /* left: -30px; */
  /* top: -7px; */
  margin-left: 3px;
  border-radius: 50%;
  padding: 3px 2px 1px 2px;
  border: ${props =>
    props.filtered ? `1px solid ${props => props.theme.HC1}` : "none"};
  @media only screen and (max-width: 1220px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    top: -14px;
  }
`;

const MobileSearch = styled.div`
  display: none;
  max-height: ${props => (props.showSearch ? "100px" : 0)};
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  grid-column: 1 / 3;
  margin-bottom: 20px;
  @media only screen and (max-width: 1220px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    display: block;
  }
`;

const ToggleCell = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
`;

const ColumnToggle = styled.span`
  color: ${props => props.theme.TextC};
  font-size: 31px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  transform: ${props => (props.columnsOpen ? "rotate(45deg)" : "")};
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
`;

const ColumnDropdown = styled.div`
  display: ${props => (props.open ? "block" : "none")};
  position: absolute;
  background: white;
  color: black;
  border: 1px solid ${props => props.theme.BGC};
  padding: 0 10px;
  top: 31px;
  right: 0;
  min-width: 170px;
  z-index: 10;
`;

const CheckboxControl = styled.label`
  display: block;
  position: relative;
  padding-left: 30px;
  margin: 10px 0;
  padding-top: 3px;
  cursor: pointer;
  font-size: 15px;
  font-weight: normal;
  text-transform: none;
  &:hover input ~ .control_indicator {
    background: ${props => props.theme.BGC};
  }
  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    &:focus ~ .control_indicator {
      background: ${props => props.theme.BGC};
    }
    &:checked ~ .control_indicator {
      background: ${props => props.theme.BGC};
      color: black;
    }
    &:checked:focus ~ .control_indicator {
      background: ${props => props.theme.BGC};
    }
    &:checked ~ .control_indicator:after {
      display: block;
    }
  }
`;

const CheckboxIndicator = styled.div`
  position: absolute;
  top: ${props => (props.topPosition ? props.topPosition : "2px")};
  left: 0;
  height: 20px;
  width: 20px;
  background: ${props => props.theme.BGC};
  border: 1px solid ${props => props.theme.HCLit};
  border-radius: 3px;
  &:after {
    box-sizing: unset;
    content: "";
    position: absolute;
    display: none;
    left: 8px;
    top: 4px;
    width: 3px;
    height: 8px;
    border: solid ${props => props.theme.HC1};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 4.5rem;
    height: 4.5rem;
    margin-left: -1.3rem;
    margin-top: -1.3rem;
    background: ${props => props.theme.BGC};
    border-radius: 3rem;
    opacity: 0.6;
    z-index: 99999;
    transform: scale(0);
  }
`;

const PartialSelectIndicator = styled(CheckboxIndicator)`
  &::after {
    box-sizing: unset;
    content: "";
    position: absolute;
    display: none;
    left: 5px;
    top: 0px;
    width: 8px;
    height: 8px;
    border: solid ${props => props.theme.HC1};
    border-width: 0 0px 2px 0;
    transform: none;
  }
`;

const TableHead = styled.thead`
  @media only screen and (max-width: 1220px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    display: none;
    tr {
      display: block;
    }
  }
  select {
    max-width: 500px;
  }
`;

const HeaderCell = styled.td`
  margin: 10px 0;
  background-color: ${props => props.theme.ATextC};
  color: ${props => props.theme.TextC};
  text-align: left;
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: bold;
  padding: 20px 13px;
  position: relative;
  @media only screen and (max-width: 1220px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    display: ${props => (props.showFilters ? "block" : "none")};
    & > div {
      display: ${props => (props.showFilters ? "grid" : "none")};
      grid-template-columns: 1fr 1fr;
    }
  }
`;

const HeaderCellInner = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
`;

const HeaderText = styled.span`
  position: relative;

  @media only screen and (max-width: 1220px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    /* position: absolute;
    top: -9999px;
    left: -9999px; */
  }
`;

const SortIcon = styled.span`
  position: relative;
  top: 7px;
  line-height: 0;
`;

const FilterDropdown = styled(Dropdown)`
  /* max-width: 200px; */
`;

const FilterBox = styled(InputBox)`
  /* max-width: 200px; */
`;

const Table = styled.table`
  width: 100%;
`;

const TableBody = styled.tbody`
  @media only screen and (max-width: 1220px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    display: block;
  }
`;

const TableRow = styled.tr`
  padding: 10px 0 19px;
  min-height: 55px;
  background-color: ${props =>
    props.zebrastripe !== "odd" ? props.theme.ATextC : "transparent"};
  color: ${props => props.theme.TextC};
  @media only screen and (max-width: 1220px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    display: grid;
    grid-template-columns: 71px 2fr 1fr;
  }
  a {
    color: ${props => props.theme.TextC};
  }
`;

const BodyCell = styled.td`
  margin: 10px 0;
  text-align: left;
  padding: 10px;
  @media only screen and (max-width: 1220px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    /* display: ${props => (props.additionalInfo ? "table-cell" : "grid")};
    grid-template-columns: 2fr 3fr;
    grid-gap: 10px; */
    padding: 0;
    display: ${props => (props.showOnMobile ? "block" : "none")};
    &.nameIcon {
      grid-row-start: 1;
      grid-row-end: 4;
      div {
        height: 100%;
        width: 100%;
        justify-content: center;
      }
    }
    &.fullName {
      margin-bottom: 0;
    }
    &.fullName a {
      font-weight: bold;
    }
    &.phoneNumber {
      grid-row-start: 2;
      margin: 0;
      font-size: 0.9em;
    }
    &.email {
      grid-row-start: 3;
      margin: 0;
      font-size: 0.9em;
    }
    &.tags {
      grid-row-start: 1;
      grid-row-end: ${props => (props.gridRowEnd ? props.gridRowEnd : 5)};
      padding-right: 20px;
    }
  }
`;

const BodyCellInner = styled.div`
  min-height: 55px;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 1220px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    min-height: auto;
  }
`;

const CellValue = styled.span`
  text-align: left;
  /* @media only screen and (max-width: 1220px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    text-align: right;
  } */
`;

const AdvancedFilter = styled.div`
  display: ${props => (props.showFilters ? "block" : "none")};
  position: absolute;
  right: ${props => (props.filterOffScreen ? "0" : "auto")};
  background: white;
  border: 1px solid ${props => props.theme.BGC};
  padding: 20px;
  min-width: 170px;
  z-index: 10;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  display: ${props => (props.showFilters ? "block" : "none")};
  width: 100vw;
  height: 100vh;
`;

const Tag = styled.span`
  position: relative;
  border-radius: 2px;
  color: ${props => props.theme.CTextC};
  display: flex;
  font-size: 16px;
  font-weight: 300;
  list-style: none;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 0px 10px;
  border-radius: 1.2rem;
  white-space: nowrap;
  /* overflow: hidden; */
  line-height: 1.8em;
  max-height: 1.8em;
  text-overflow: ellipsis;
  max-width: 13em;
  align-items: center;
`;

const TagColor = styled.span`
  width: 15px;
  height: 15px;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 50%;
  margin-right: 7px;
  background: ${props => (props.color ? props.color : props.theme.HC1)};
`;

const HeaderInputContainer = styled(InputContainer)`
  max-width: 18rem;
  min-width: 8rem;
  margin: 0;
  @media only screen and (max-width: 1220px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    margin: 0 auto;
  }
`;

const Initials = styled.a`
  display: inline-block;
  padding: 0.5em;
  width: 2.5em;
  height: 2.5em;
  color: white;
`;

const PaginationRow = styled.tr`
  @media only screen and (max-width: 1220px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    display: block;
  }
`;

const PaginationCell = styled.td`
  @media only screen and (max-width: 1220px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    display: block;
  }
`;

const Pagination = styled.div`
  display: grid;
  grid-template-columns: 1fr 200px;
  margin: 20px 0 20px 200px;
  @media only screen and (max-width: 1220px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    grid-template-columns: 1fr;
    margin: 20px auto;
  }
`;

const Pager = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 1220px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    margin-bottom: 20px;
  }
`;

const PagerItem = styled.li`
  display: inline-block;
  padding: 0 4px;
  cursor: pointer;
  border-radius: 3px;
  pointer-events: ${props => (props.disabled ? "none" : "auto")};
  &:hover,
  &.selectedPage {
    background-color: ${props => props.theme.HC2};
    color: ${props => props.theme.ATextC};
  }
`;

const PageSelectorWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PageInputContainer = styled(InputContainer)`
  margin: 0 auto;
`;

const NoContactsMsg = styled.div`
  padding: 22px 22px 0;
  font-weight: ${props => (props.bold ? "bold" : "normal")};
  a {
    color: ${props => props.theme.HC1};
    font-style: italic;
  }
`;

const BulkActionDropdown = styled.div`
  display: ${props => (props.open ? "block" : "none")};
  position: absolute;
  background: white;
  color: black;
  border: 1px solid ${props => props.theme.BGC};
  padding: 1px 20px 7px;
  text-align: left;
  top: 35px;
  left: 0;
  min-width: 170px;
  z-index: 10;
`;

const BulkDropdownIcon = styled.span`
  position: absolute;
  top: 4px;
`;

const BulkActionWrapper = styled.span`
  display: inline-block;
  padding-right: 14px;
`;

const BulkActionItem = styled.div`
  margin: 0.5em 0;
  .material-icons {
    position: relative;
    top: 6px;
  }
`;

const CellShowOnMobile = [
  "fullName",
  "nameIcon",
  "email",
  "phoneNumber",
  "tags"
];

const SelectedContactsAll = styled.p`
  text-align: center;
`;

const BulkSelectContactsLink = styled.span`
  color: ${props => props.theme.HC1};
  font-weight: bold;
`;

function getExportFileBlob({ columns, data, fileType, fileName }) {
  if (fileType === "csv") {
    // CSV example
    const headerNames = columns.map(col => col.exportValue);
    /* cspell: disable-next-line */
    const csvString = Papa.unparse({ fields: headerNames, data });
    return new Blob([csvString], { type: "text/csv" });
  } else if (fileType === "xlsx") {
    // XLSX example

    const header = columns.map(c => c.exportValue);
    const compatibleData = data.map(row => {
      const obj = {};
      header.forEach((col, index) => {
        if (_.isArray(row[index])) {
          obj[col] = row[index].join(", ");
        } else {
          obj[col] = row[index];
        }
      });
      return obj;
    });

    const wb = XLSX.utils.book_new();
    const ws1 = XLSX.utils.json_to_sheet(compatibleData, {
      header
    });
    XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
    XLSX.writeFile(wb, `${fileName}.xlsx`);

    // Returning false as downloading of file is already taken care of
    return false;
  }
  //PDF example
  if (fileType === "pdf") {
    const headerNames = columns.map(column => column.exportValue);
    const pdfData = data.map(row => {
      row.forEach((column, index) => {
        if (_.isArray(column)) {
          row[index] = column.join(", ");
        }
      });
      return row;
    });
    const doc = new JsPDF();
    doc.autoTable({
      head: [headerNames],
      body: pdfData,
      tableWidth: "wrap",
      bodyStyles: { valign: "middle" },
      cellWidth: "wrap",
      styles: {
        halign: "left",
        fontSize: 8,
        cellPadding: 3,
        overflow: "linebreak"
      },
      columnStyles: {
        [headerNames.indexOf("Campaign")]: {
          cellWidth: 30
        },
        [headerNames.indexOf("Tags")]: {
          cellWidth: 30
        }
      }
    });
    doc.save(`${fileName}.pdf`);

    return false;
  }

  // Other formats goes here
  return false;
}

// Define a default UI for filtering
function GlobalFilter({ globalFilter, setGlobalFilter }) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce(newValue => {
    setGlobalFilter(newValue || undefined);
  }, 200);

  return (
    <Search
      searchValue={value}
      onSearchChange={e => {
        setValue(e);
        onChange(e);
      }}
      clearSearch={() => {
        setValue("");
        onChange("");
      }}
      placeholder='Search by Name, Address, Contact Info..'
      showClear={!!value && value.length > 0}
      compact
    />
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id }
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const optionSet = new Set();
    preFilteredRows.forEach(row => {
      if (row.values[id]) {
        optionSet.add(row.values[id]);
      }
    });
    const optionValues = [...optionSet.values()];
    optionValues.sort(function (a, b) {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });
    return [...optionValues, "Empty"];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <HeaderInputContainer>
      <InputBoxWrapper>
        <span>Select Filter Terms</span>
        <FilterDropdown
          value={filterValue || "All"}
          onChange={e => {
            setFilter(e.target.value || undefined);
          }}
          onClick={e => e.stopPropagation()}
        >
          <option value=''>All</option>
          {options.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </FilterDropdown>
      </InputBoxWrapper>
    </HeaderInputContainer>
  );
}

function MultiSelectFilterFn({
  column: { filterValue, setFilter, preFilteredRows, id }
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const justValues = [];
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach(row => {
      if (row.values[id] && row.values[id].status) {
        const label = row.values[id].status;
        if (label && justValues.indexOf(label) < 0) {
          options.add({
            label: label,
            value: label
          });
          justValues.push(label);
        }
      }
      if (
        row.values[id] &&
        Array.isArray(row.values[id]) &&
        row.values[id].length > 0
      ) {
        row.values[id].forEach(tag => {
          if (justValues.indexOf(tag.name) < 0) {
            options.add({ label: tag.name, value: tag.name });
            justValues.push(tag.name);
          }
        });
      }
    });
    return [...options.values()];
  }, [id, justValues, preFilteredRows]);
  let selectValues = [];
  if (filterValue) {
    selectValues = filterValue.map(filter => ({
      label: filter,
      value: filter
    }));
  }

  const customValueRenderer = selected => (
    <ul>
      {selected.length ? (
        selected.map((item, i) => <li key={i}>{item.label}</li>)
      ) : (
        <li>No tags selected</li>
      )}
    </ul>
  );

  // Render a multi-select box
  return (
    <div>
      <span>Select Filter Terms</span>
      <MultiSelect
        options={options}
        value={selectValues}
        valueRenderer={customValueRenderer}
        onChange={e => {
          const allValues = Object.keys(e).map(i => e[i].value);
          setFilter(allValues && allValues.length ? allValues : undefined);
        }}
        labeledBy={"Select"}
      />
    </div>
  );
}

function DateFilter({
  column: { filterValue, setFilter, preFilteredRows, id }
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach(row => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <HeaderInputContainer>
      <span>Select Date to Filters</span>
      <DateRangePickerWrapper
        onClose={(startDateClose, endDateClose) => {
          const startDate = startDateClose || undefined;
          const endDate = endDateClose || undefined;
          setFilter({ startDate, endDate });
        }}
        initStartDate={
          filterValue && filterValue.startDate
            ? filterValue.startDate
            : undefined
        }
        initEndDate={
          filterValue && filterValue.endDate ? filterValue.endDate : undefined
        }
      />
    </HeaderInputContainer>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter }
}) {
  const count = preFilteredRows.length;

  return (
    <HeaderInputContainer>
      <span>Enter Filter Terms</span>
      <InputBoxWrapper>
        <FilterBox
          type='text'
          value={filterValue || ""}
          onChange={e => {
            setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
          }}
          onClick={e => e.stopPropagation()}
          placeholder={`Search ${count} records...`}
        />
      </InputBoxWrapper>
    </HeaderInputContainer>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, {
    keys: [
      row => (row.values[id].value ? row.values[id].value : row.values[id])
    ]
  });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val;

function PageCountLoop(pageCount, pageIndex, gotoPage) {
  const pageItems = [];
  const windowWidth = window.innerWidth;
  const pageItemMax = windowWidth < 600 ? 5 : 11;
  let startPage = 0;
  let endPage = pageCount - 1;
  if (pageCount <= pageItemMax) {
    startPage = 0;
    endPage = pageCount;
  } else {
    const maxPagesBeforeCurrentPage = Math.floor(pageItemMax / 2);
    const maxPagesAfterCurrentPage = Math.ceil(pageItemMax / 2);
    if (pageIndex < maxPagesBeforeCurrentPage) {
      // current page near the start
      startPage = 0;
      endPage = pageItemMax;
      if (pageCount <= pageItemMax) {
        endPage = pageCount;
      }
    } else if (pageIndex + maxPagesAfterCurrentPage > pageCount) {
      // current page near the end
      startPage = pageCount - pageItemMax + 1;
      endPage = pageCount;
    } else {
      // current page somewhere in the middle
      startPage = pageIndex - maxPagesBeforeCurrentPage;
      endPage = pageIndex + maxPagesAfterCurrentPage;
    }
  }
  for (let i = startPage; i < endPage; i++) {
    pageItems.push(
      <PagerItem
        key={i}
        onClick={() => gotoPage(i)}
        disabled={i === pageIndex}
        className={i === pageIndex ? "selectedPage" : "pageNotSelected"}
      >
        {i + 1}
      </PagerItem>
    );
  }
  return pageItems;
}

function TableData(props) {
  const {
    columns,
    data,
    contactCount,
    showFilters,
    createContact,
    tagFilterOperator = "or",
    history,
    deleteContact,
    defaultFiltered = [],
    filterEmptyMessage = "",
    selectedContacts,
    bulkEditOpen,
    selectAllContacts,
    selectAllPages
  } = props;
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      multiple: (rows, id, filterValue) =>
        rows.filter(row => {
          let values = [];
          if (row.values && row.values[id] && row.values[id].status) {
            values = [row.values[id].status];
          } else if (row.values && row.values[id]) {
            values = row.values[id].map(tag => tag.name);
          } else {
            return false;
          }
          if (tagFilterOperator === "or") {
            let includesFilter = false;
            filterValue.forEach(filter => {
              if (values.includes(filter)) {
                includesFilter = true;
              }
            });
            return includesFilter;
          } else if (tagFilterOperator === "and") {
            let includesFilter = [];
            filterValue.forEach(filter => {
              if (values.includes(filter)) {
                includesFilter = [...includesFilter, true];
              }
            });
            if (filterValue.length === includesFilter.length) {
              return true;
            }
          } else {
            return false;
          }
        }),
      text: (rows, id, filterValue) =>
        rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .includes(String(filterValue).toLowerCase())
            : false;
        }),
      textMatch: (rows, id, filterValue) =>
        rows.filter(row => {
          const rowValue = row.values[id];
          if (
            String(rowValue).toLowerCase() === String(filterValue).toLowerCase()
          ) {
            return true;
          }
          if (filterValue === "Empty" && !rowValue) {
            return true;
          }
          return false;
        }),
      date: (rows, id, filterValue) =>
        rows.filter(row => {
          if (row.values[id]) {
            if (filterValue.endDate) {
              if (
                moment(row.values[id]).isBetween(
                  filterValue.startDate,
                  filterValue.endDate,
                  "day",
                  "[]"
                )
              ) {
                return true;
              } else {
                return false;
              }
            } else if (filterValue.startDate) {
              if (moment(row.values[id]).isSame(filterValue.startDate, "day")) {
                return true;
              } else {
                return false;
              }
            } else {
              return true;
            }
          } else {
            return true;
          }
        })
    }),
    [tagFilterOperator]
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter
    }),
    []
  );

  const initialState = {
    pageIndex: 0,
    hiddenColumns: [...props.hiddenColumns]
  };
  if (defaultFiltered && defaultFiltered.length > 0) {
    initialState.filters = defaultFiltered;
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state,
    visibleColumns,
    setPageSize,
    setGlobalFilter,
    preGlobalFilteredRows,
    setAllFilters,
    exportData,
    allColumns,
    rows,
    state: { pageIndex, pageSize, filters }
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState,
      getExportFileBlob,
      sortTypes: {
        alphanumeric: (row1, row2, columnName) => {
          const rowOneColumn =
            row1.values[columnName] && row1.values[columnName].value
              ? row1.values[columnName].value
              : row1.values[columnName];
          const rowTwoColumn =
            row2.values[columnName] && row2.values[columnName].value
              ? row2.values[columnName].value
              : row2.values[columnName];
          if (!rowOneColumn && !rowTwoColumn) return 0;
          if (
            !rowOneColumn ||
            _.isEmpty(rowOneColumn) ||
            rowOneColumn.length === 0
          )
            return -1;
          if (
            !rowTwoColumn ||
            _.isEmpty(rowTwoColumn) ||
            rowTwoColumn.length === 0
          )
            return 1;
          if (
            typeof rowOneColumn === "string" &&
            typeof rowTwoColumn === "string"
          ) {
            return rowOneColumn.toUpperCase() > rowTwoColumn.toUpperCase()
              ? 1
              : -1;
          }
          return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1;
        }
      }
    },
    useColumnOrder,
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useExportData
  );
  const filteredColumns = filters.map(filter => filter.id);
  props.clearSelectedContacts(filters, state.globalFilter);
  const windowWidth = window.innerWidth;

  const [columnsOpen, setColumnsOpen] = React.useState(false);
  const [showSearch, setShowSearch] = React.useState(false);
  const [showColumnFilter, setShowColumnFilter] = React.useState(null);
  const [filterOffScreen, setFilterOffScreen] = React.useState(false);
  const [exportDropdownOpen, setExportDropdownOpen] = React.useState(false);
  const contactsOnPage = page.map(item => item.original.contactID);

  return (
    <div>
      <TitleWrapperContact>
        <TitleLeft mobileStacking>
          <ContactTitleWrapper>
            <H1>Contacts</H1>
            {rows.length !== contactCount ? (
              <ContactCount>
                Showing {rows.length} of {contactCount} Contact
                {contactCount > 1 ? "s" : ""}
              </ContactCount>
            ) : (
              <ContactCount>
                {contactCount} Contact{contactCount > 1 ? "s" : ""}
              </ContactCount>
            )}
          </ContactTitleWrapper>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            showOnDesktop={true}
            showOnMobile={false}
          />
        </TitleLeft>
        <TitleRight>
          <ContactTableActions>
            <BulkActionButton
              className='downloadContacts'
              onClick={() => setExportDropdownOpen(true)}
            >
              <BulkActionWrapper>
                Export View{" "}
                <BulkDropdownIcon className='material-icons'>
                  arrow_drop_down
                </BulkDropdownIcon>
              </BulkActionWrapper>
              <BulkActionDropdown open={exportDropdownOpen}>
                <BulkActionItem
                  onClick={() => {
                    exportData("csv", false);
                    props.alert({
                      type: "success",
                      msg: "Downloading export. Check your computer downloads folder."
                    });
                  }}
                >
                  <span className='material-icons'>text_snippet</span> Download
                  CSV
                </BulkActionItem>
                <BulkActionItem
                  onClick={() => {
                    exportData("xlsx", false);
                    props.alert({
                      type: "success",
                      msg: "Downloading export. Check your computer downloads folder."
                    });
                  }}
                >
                  <span className='material-icons'>table_chart</span> Download
                  Excel
                </BulkActionItem>
                <BulkActionItem
                  onClick={() => {
                    exportData("pdf", false);
                    props.alert({
                      type: "success",
                      msg: "Downloading export. Check your computer downloads folder."
                    });
                  }}
                >
                  <span className='material-icons'>picture_as_pdf</span>{" "}
                  Download PDF
                </BulkActionItem>
              </BulkActionDropdown>
            </BulkActionButton>
            <Button
              solid
              onClick={createContact}
              className='newContact'
              title='Add Contact'
              disabled={props.loadingContact}
            >
              New Contact
              {!!props.loadingContact && <LoaderDotsSmall />}
            </Button>
          </ContactTableActions>
        </TitleRight>
      </TitleWrapperContact>
      <SectionGroup>
        <ContactHeaderWrapper>
          <TableActions>
            <BulkActionsWrapper>
              <BulkActionButton
                className='editContact'
                disabled={selectedContacts.length > 0 ? false : true}
                outlined={bulkEditOpen}
                onClick={() =>
                  selectedContacts.length === 1
                    ? history.push(`contacts/${selectedContacts[0]}`)
                    : props.toggleBulkEditMenu()
                }
              >
                {selectedContacts.length <= 1 ? (
                  "Edit Contact"
                ) : (
                  <BulkActionWrapper>
                    Edit Contacts{" "}
                    <BulkDropdownIcon className='material-icons'>
                      arrow_drop_down
                    </BulkDropdownIcon>
                  </BulkActionWrapper>
                )}
                <BulkActionDropdown open={bulkEditOpen}>
                  <BulkActionItem onClick={props.editContactStatuses}>
                    <span className='material-icons'>how_to_reg</span> Edit
                    Status
                  </BulkActionItem>
                  <BulkActionItem onClick={props.editContactTags}>
                    <span className='material-icons'>local_offer</span> Edit
                    Tags
                  </BulkActionItem>
                </BulkActionDropdown>
              </BulkActionButton>
              <BulkActionButton
                className='deleteContact'
                disabled={selectedContacts.length === 1 ? false : true}
                onClick={e => deleteContact(e, selectedContacts[0])}
              >
                Delete Contact
              </BulkActionButton>
            </BulkActionsWrapper>
          </TableActions>
          <MobileSearch showSearch={showSearch}>
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
              showOnDesktop={false}
              showOnMobile={true}
            />
          </MobileSearch>
        </ContactHeaderWrapper>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup, key) => (
              <TableRow
                {...headerGroup.getHeaderGroupProps()}
                className='row header-group'
                key={key}
              >
                <HeaderCell>
                  <Checkbox
                    checked={selectAllContacts || selectedContacts.length > 0}
                    onChange={() => props.handleSelectAll(contactsOnPage)}
                    partialSelectOn={!selectAllContacts}
                  />
                </HeaderCell>
                {headerGroup.headers.map(
                  (column, index) =>
                    column.id !== "nameIcon" && (
                      <HeaderCell
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        className={`cell header ${column.id}`}
                        showFilters={showFilters}
                        colSpan={column.id === "fullName" ? 2 : 1}
                        showOnMobile={CellShowOnMobile.includes(column.id)}
                      >
                        <HeaderCellInner>
                          <HeaderText>
                            {column.render("Header")}
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <SortIcon className='material-icons'>
                                  arrow_drop_down
                                </SortIcon>
                              ) : (
                                <SortIcon className='material-icons'>
                                  arrow_drop_up
                                </SortIcon>
                              )
                            ) : (
                              ""
                            )}
                          </HeaderText>
                          {!!column.canFilter && (
                            <FilterToggle
                              title={`${
                                showFilters ? "Hide" : "Show"
                              } Advanced Filter`}
                              className={`${column.id}-filter-toggle`}
                            >
                              <FilterColumnIcon
                                className={"material-icons"}
                                onClick={e => {
                                  e.stopPropagation();
                                  if (showColumnFilter === column.id) {
                                    setShowColumnFilter(null);
                                    setFilterOffScreen(false);
                                  } else {
                                    setShowColumnFilter(column.id);
                                  }
                                  if (showFilters) {
                                    setAllFilters([]);
                                  }
                                }}
                                filtered={filteredColumns.includes(column.id)}
                              >
                                filter_list
                              </FilterColumnIcon>
                              <AdvancedFilter
                                ref={el => {
                                  if (!el) return;
                                  const elementPosition =
                                    el.getBoundingClientRect();
                                  if (elementPosition.right > windowWidth) {
                                    setFilterOffScreen(true);
                                  }
                                }}
                                filterOffScreen={filterOffScreen}
                                showFilters={showColumnFilter === column.id}
                              >
                                {column.render("Filter")}
                                {column.filter === "multiple" && (
                                  <FilterDropdown
                                    value={tagFilterOperator}
                                    onChange={props.toggleFilterOperator}
                                    onClick={e => e.stopPropagation()}
                                  >
                                    <option value='or'>Any</option>
                                    <option value='and'>All</option>
                                  </FilterDropdown>
                                )}
                              </AdvancedFilter>
                            </FilterToggle>
                          )}
                        </HeaderCellInner>
                      </HeaderCell>
                    )
                )}
                <HeaderCell>
                  <ToggleCell>
                    <ColumnToggle
                      className='material-icons column-toggle'
                      columnsOpen={columnsOpen}
                      onClick={() => setColumnsOpen(true)}
                    >
                      add_circle
                    </ColumnToggle>
                    <ColumnDropdown open={columnsOpen}>
                      {allColumns.map(column => (
                        <CheckboxControl key={column.id}>
                          {column.Header}
                          <input
                            type='checkbox'
                            {...column.getToggleHiddenProps()}
                          />
                          <CheckboxIndicator className='control_indicator'></CheckboxIndicator>
                        </CheckboxControl>
                      ))}
                    </ColumnDropdown>
                  </ToggleCell>
                </HeaderCell>
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {filteredColumns && filteredColumns.length > 0 && (
              <TableRow>
                <BodyCell colSpan={visibleColumns.length + 2}>
                  <ClearFilters
                    onClick={() => setAllFilters([])}
                    title='Clear All Filters'
                  >
                    Clear All Filters
                  </ClearFilters>
                </BodyCell>
              </TableRow>
            )}
            {!!selectAllContacts && (
              <TableRow>
                <BodyCell colSpan={visibleColumns.length + 2}>
                  {pageCount > 1 && !selectAllPages ? (
                    <SelectedContactsAll>
                      All {page.length} contacts on this page are selected.{" "}
                      <BulkSelectContactsLink
                        onClick={props.handleSelectAllPages}
                      >
                        Select all {contactCount} contacts
                      </BulkSelectContactsLink>
                    </SelectedContactsAll>
                  ) : selectAllPages ? (
                    <SelectedContactsAll>
                      All {contactCount} selected.{" "}
                      <BulkSelectContactsLink onClick={props.handleSelectAll}>
                        Clear Selection
                      </BulkSelectContactsLink>
                    </SelectedContactsAll>
                  ) : (
                    <SelectedContactsAll>
                      {page.length} contacts are selected.
                    </SelectedContactsAll>
                  )}
                </BodyCell>
              </TableRow>
            )}
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow
                  {...row.getRowProps()}
                  zebrastripe={i % 2 ? "even" : "odd"}
                >
                  <BodyCell showOnMobile={false}>
                    <Checkbox
                      key={i}
                      checked={selectedContacts.includes(
                        row.original.contactID
                      )}
                      onChange={() => {
                        props.handleSelectedContacts(row.original.contactID);
                      }}
                    />
                  </BodyCell>
                  {row.cells.map(cell => (
                    <BodyCell
                      {...cell.getCellProps()}
                      colSpan={cell.column.id === "tags" ? 2 : 1}
                      showOnMobile={CellShowOnMobile.includes(cell.column.id)}
                      className={cell.column.id}
                      gridRowEnd={
                        cell.column.id === "tags" && cell.value.length > 2
                          ? cell.value.length + 2
                          : 5
                      }
                    >
                      <BodyCellInner>
                        <CellValue>{cell.render("Cell")}</CellValue>
                      </BodyCellInner>
                    </BodyCell>
                  ))}
                </TableRow>
              );
            })}
            <tr>
              <td colSpan={visibleColumns.length + 2}>
                {filterEmptyMessage ? (
                  <NoContactsMsg bold>{filterEmptyMessage}</NoContactsMsg>
                ) : page.length === 0 && filters.length > 0 ? (
                  <NoContactsMsg bold>
                    No contacts match these filters.
                  </NoContactsMsg>
                ) : page.length === 0 ? (
                  <NoContactsMsg>
                    No contacts to view. You can add contacts manually by
                    clicking the New Contact button or{" "}
                    <a href='/campaign-select'>
                      start a new Lead-Generating Campaign
                    </a>
                    .
                  </NoContactsMsg>
                ) : null}
                {}
              </td>
            </tr>
            <PaginationRow>
              <PaginationCell colSpan={visibleColumns.length + 2}>
                <Pagination>
                  {pageCount && pageCount > 1 ? (
                    <Pager>
                      <PagerItem
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                      >
                        {"< Prev"}
                      </PagerItem>
                      {PageCountLoop(pageCount, pageIndex, gotoPage)}
                      <PagerItem
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                      >
                        {"Next >"}
                      </PagerItem>
                    </Pager>
                  ) : null}
                  <PageSelectorWrapper>
                    <PageInputContainer>
                      <InputBoxWrapper>
                        <FilterDropdown
                          value={pageSize}
                          onChange={e => {
                            setPageSize(Number(e.target.value));
                          }}
                          onClick={e => e.stopPropagation()}
                        >
                          {[10, 20, 30, 40, 50].map(ps => (
                            <option key={ps} value={ps}>
                              {ps} per page
                            </option>
                          ))}
                        </FilterDropdown>
                      </InputBoxWrapper>
                    </PageInputContainer>
                  </PageSelectorWrapper>
                </Pagination>
              </PaginationCell>
            </PaginationRow>
          </TableBody>
        </Table>
      </SectionGroup>
      <Overlay
        showFilters={showColumnFilter || exportDropdownOpen || columnsOpen}
        onClick={() => {
          setShowColumnFilter(null);
          setFilterOffScreen(false);
          setExportDropdownOpen(false);
          setColumnsOpen(false);
        }}
      ></Overlay>
    </div>
  );
}

function ContactsTable(props) {
  const {
    toggleFilters,
    contacts,
    contactCount,
    showFilters,
    createContact,
    history,
    userProfile
  } = props;
  const windowWidth = window.innerWidth;
  const csvHeaders = [{ label: "Date Created", key: "dateCreated" }];
  const headers = [
    { Header: "Picture", accessor: "nameIcon", disableExport: true },
    {
      Header: "Name",
      accessor: "fullName",
      filter: "fuzzyText",
      // eslint-disable-next-line react/display-name
      Cell: cellInfo => (
        <a href={`/contacts/${cellInfo.row.original.contactID}`}>
          {cellInfo.value ? cellInfo.value : "No Name"}
        </a>
      )
    },
    {
      Header: "Campaign",
      accessor: "campaignName",
      Filter: SelectColumnFilter,
      filter: "textMatch"
    },
    {
      Header: "Email",
      accessor: "email",
      filter: "fuzzyText",
      // eslint-disable-next-line react/display-name
      Cell: cellInfo => (
        <span>
          {windowWidth < 500 &&
          cellInfo.row.values.tags &&
          cellInfo.row.values.tags.length > 0 &&
          cellInfo.value.shorter
            ? cellInfo.value.shorter
            : cellInfo.value.value}
        </span>
      ),
      getCellExportValue: (row, column) =>
        row.values.email && row.values.email.value
          ? row.values.email.value
          : undefined
    },
    { Header: "Phone", accessor: "phoneNumber" },
    {
      Header: "Date Created",
      accessor: "dateCreated",
      Filter: DateFilter,
      filter: "date"
    },
    {
      Header: "Status",
      accessor: "status",
      Filter: MultiSelectFilterFn,
      filter: "multiple",
      Cell: cellInfo =>
        cellInfo.value && cellInfo.value.status ? cellInfo.value.status : null,
      getCellExportValue: (row, column) =>
        row.values.status && row.values.status && row.values.status.status
          ? row.values.status.status
          : undefined
    },
    {
      Header: "Source",
      accessor: "source",
      Filter: MultiSelectFilterFn,
      filter: "multiple",
      Cell: cellInfo => {
        console.log("cellInfo", cellInfo);
        return cellInfo.value && cellInfo.value.source
          ? cellInfo.value.source
          : null;
      },
      getCellExportValue: (row, column) =>
        row.values.source && row.values.source && row.values.source
          ? row.values.source
          : undefined
    },
    {
      Header: "Tags",
      accessor: "tags",
      disableSortBy: true,
      Filter: MultiSelectFilterFn,
      filter: "multiple",
      Cell: cellInfo =>
        cellInfo.value
          ? cellInfo.value.map((value, key) => (
              <Tag key={value._id}>
                <TagColor
                  color={value.color ? value.color : "#675da8"}
                ></TagColor>
                <span className='color-btn'>{value.name}</span>
              </Tag>
            ))
          : null,
      getCellExportValue: (row, column) => {
        if (row.values.tags && row.values.tags.length > 0) {
          const tags = row.values.tags.map(tag => tag.name);
          return tags;
        } else {
          return "";
        }
      }
    }
  ];
  contacts.sort((a, b) => {
    const aCreated = new Date(a.created);
    const aTimestamp = aCreated.getTime();
    const bCreated = new Date(b.created);
    const bTimestamp = bCreated.getTime();
    return bTimestamp - aTimestamp;
  });
  const stringLengthMax = Math.floor(windowWidth / 20);
  let csvData = [];
  const tableData = contacts.map((lead, index) => {
    const number = index + 1;
    let returnData = {
      number,
      campaignName: lead.campaignName,
      contactID: lead._id,
      status: lead.contactStatus,
      source: lead.source
    };
    if (lead.tags && lead.tags.length > 0) {
      returnData.tags = lead.tags;
      returnData.tags.sort(function (a, b) {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name, "en", { sensitivity: "base" });
        } else {
          return 0;
        }
      });
    } else {
      returnData.tags = "";
    }
    const createdMoment = moment(lead.created).tz("Etc/UTC");
    const createdFormatted = createdMoment.format("MM-DD-YYYY");
    returnData.dateCreated = createdFormatted;
    let fullName = "";
    let initials = "";
    if (lead.full_name) {
      if (Array.isArray(lead.full_name) && lead.full_name.length > 0) {
        const name = parseFullName(lead.full_name[0]);
        if (name.first) {
          initials = name.first.charAt(0).toUpperCase();
        }
        if (name.last) {
          initials = initials + name.last.charAt(0).toUpperCase();
        }
        fullName = lead.full_name[0];
      } else {
        const name = parseFullName(lead.full_name);
        if (name.first) {
          initials = name.first.charAt(0).toUpperCase();
        }
        if (name.last) {
          initials = initials + name.last.charAt(0).toUpperCase();
        }
        fullName = lead.full_name;
      }
    }
    if (!lead.full_name && lead.first_name) {
      if (Array.isArray(lead.first_name) && lead.first_name.length > 0) {
        fullName = lead.first_name[0];
        initials = lead.first_name[0].first.charAt(0).toUpperCase();
      } else {
        fullName = lead.first_name;
        initials = lead.first_name.charAt(0).toUpperCase();
      }
    } else if (!lead.full_name && lead.firstName) {
      if (Array.isArray(lead.firstName) && lead.firstName.length > 0) {
        fullName = lead.firstName[0];
        initials = lead.firstName[0].first.charAt(0).toUpperCase();
      } else {
        fullName = lead.firstName;
        initials = lead.firstName.charAt(0).toUpperCase();
      }
    }
    if (!lead.full_name && lead.last_name) {
      if (Array.isArray(lead.last_name) && lead.last_name.length > 0) {
        fullName = fullName + " " + lead.last_name[0];
        initials = initials + lead.last_name[0].charAt(0).toUpperCase();
      } else {
        fullName = fullName + " " + lead.last_name;
        initials = initials + lead.last_name.charAt(0).toUpperCase();
      }
    } else if (!lead.full_name && lead.lastName) {
      if (Array.isArray(lead.lastName) && lead.lastName.length > 0) {
        fullName = fullName + " " + lead.lastName[0];
        initials = initials + lead.lastName[0].charAt(0).toUpperCase();
      } else {
        fullName = fullName + " " + lead.lastName;
        initials = initials + lead.lastName.charAt(0).toUpperCase();
      }
    }
    const email = {};

    if (lead.email) {
      if (Array.isArray(lead.email) && lead.email.length > 0) {
        email.value = lead.email[0];
      } else {
        email.value = lead.email;
      }
    }
    if (email.value && email.value.length > stringLengthMax) {
      email.shorter = email.value.substr(0, stringLengthMax) + "...";
    }
    let phoneNumber = "";
    if (lead.phone) {
      if (Array.isArray(lead.phone) && lead.phone.length > 0) {
        phoneNumber = lead.phone[0];
      } else {
        phoneNumber = lead.phone;
      }
    }
    if (lead.phone_number) {
      if (Array.isArray(lead.phone_number) && lead.phone_number.length > 0) {
        phoneNumber = lead.phone_number[0];
      } else {
        phoneNumber = lead.phone_number;
      }
    }
    returnData = {
      ...returnData,
      fullName,
      phoneNumber,
      email,
      nameIcon: <Initials href={`/contacts/${lead._id}`}>{initials}</Initials>
    };
    let csvDataRow = { dateCreated: createdFormatted };
    const additionalInfo = [];
    const additionalHeaders = [];
    if (lead.field_data) {
      lead.field_data.forEach(field => {
        const headerAccessor = field.name;
        const headerName = field.name
          .split("_")
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");

        const defaultHeaders = ["dateCreated", "number"];
        const matchingHeaders = headers.filter(
          header => header.accessor === headerAccessor
        );
        if (
          matchingHeaders.length === 0 &&
          defaultHeaders.includes(headerAccessor)
        ) {
          headers.push({ Header: headerName, accessor: headerAccessor });
        }
        const matchAdditionalHeaders = additionalHeaders.filter(
          header => header.accessor === headerAccessor
        );
        if (
          matchAdditionalHeaders.length === 0 &&
          !defaultHeaders.includes(headerAccessor)
        ) {
          additionalHeaders.push({
            Header: headerName,
            accessor: headerAccessor
          });
        }
        if (
          !csvHeaders.find(csv => csv.label === headerName) &&
          field.name !== "number" &&
          field.name !== "Date Created"
        ) {
          csvHeaders.push({ label: headerName, key: field.name });
        }
        if (field.values.length === 1) {
          if (defaultHeaders.includes(headerAccessor)) {
            returnData = {
              ...returnData,
              [headerAccessor]: field.values[0]
            };
          }
          csvDataRow = {
            ...csvDataRow,
            [headerAccessor]: field.values[0]
          };
        } else {
          console.log("values are longer", field.values);
        }
      });
      additionalInfo.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (b.label > a.label) {
          return 1;
        }
        return 0;
      });
      returnData.additionalInfo = additionalInfo;
      csvData = [...csvData, { ...csvDataRow }];
    }
    return returnData;
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = React.useMemo(() => tableData, [contacts]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = React.useMemo(() => headers, [contacts]);
  return (
    <TableWrapper showFilters={showFilters}>
      {/* {TableData({ columns, data, toggleFilters, clearFilters, showFilters })} */}
      <TableData
        // ref={el => (this.tableInstance = el)}
        columns={columns}
        data={data}
        contactCount={contactCount}
        toggleFilters={toggleFilters}
        showFilters={showFilters}
        createContact={createContact}
        history={history}
        tagFilterOperator={props.tagFilterOperator}
        toggleFilterOperator={props.toggleFilterOperator}
        deleteContact={props.deleteContact}
        defaultFiltered={props.defaultFiltered}
        filterEmptyMessage={props.filterEmptyMessage}
        userProfile={userProfile}
        editContactStatuses={props.editContactStatuses}
        handleSelectedContacts={props.handleSelectedContacts}
        selectedContacts={props.selectedContacts}
        bulkEditOpen={props.bulkEditOpen}
        toggleBulkEditMenu={props.toggleBulkEditMenu}
        editContactTags={props.editContactTags}
        clearSelectedContacts={props.clearSelectedContacts}
        selectAllContacts={props.selectAllContacts}
        selectAllPages={props.selectAllPages}
        handleSelectAll={props.handleSelectAll}
        handleSelectAllPages={props.handleSelectAllPages}
        hiddenColumns={props.hiddenColumns}
        alert={props.alert}
        loadingContact={props.loadingContact}
      />
    </TableWrapper>
  );
}

export default ContactsTable;
