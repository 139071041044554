import { createGlobalStyle, withTheme } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    color: ${({ theme }) => theme.TextC};
		background-color: ${({ theme }) => theme.BGC2};
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    letter-spacing: 1px;
    
  }

	canvas {
		width: 100% !important;
		max-width: 800px;
		height: auto !important;
	}
  input {
    font-family: "Poppins", sans-serif;
  }
  button {
    border: none;
    background: none;
    cursor: pointer;
    font-family: "Poppins", sans-serif;

    :focus {
      outline: none;
    }
  }

  // Font Specific Tags

  h2 {
    font-weight: 400;
  }
  h3 {
    letter-spacing: 2.5px;
    font-weight: 600;
  }
  h4{
    font-weight: 400;
  }
  h5 {
    font-weight: 400;
  }
  h6 {
    font-weight: 400;
  }

  // Input Styles

  input[type="text"],
  input[type="password"],
  input[type="tel"],
  input[type="email"] {
    /* background-color: ${({ theme }) => theme.BGC};
    border: 2px solid ${({ theme }) => theme.BGC};
    height: 2.5em;
    border-radius: 3px;
    font-size: 16px; */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
  }

  // select {
  //   border: 0;
  //   outline: 0.5px solid lightgrey;
  //   outline-offset: -1px;
  //   max-width: 95%;
  // }

  textarea {
    background-color: ${({ theme }) => theme.BGC};
    border: 1px solid ${({ theme }) => theme.BGC};
    border-radius: 3px;
    font-size: 16px;
    height: 6rem;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    &:focus{
      outline: ${({ theme }) => theme.HC2}; auto 3px;
    }
  }

  // List Styles

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  // Link Styles

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.TextC};
    font-weight: 400;
  }

  img {
    border: 0;
    vertical-align: middle;
  }
`;
export default withTheme(GlobalStyle);
