import React from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import styled from "styled-components";
import {
  stripeCreateCard,
  stripeGetPaymentMethods
} from "../../../../utils/StripeAPI";
import { LoaderDotsSmall } from "../../../elements/Loaders";
import { Button } from "../../../styled/Buttons";
import {
  firstBreakpoint,
  secondBreakpoint
} from "../../../styled/PhoneNumberProvision";
import { Error } from "../../../Errors";
import MobileButtons from "./MobileButtons";
import ModalHeader from "./ModalHeader";
const env = process.env.REACT_APP_STRIPE_ENV
  ? process.env.REACT_APP_STRIPE_ENV
  : process.env.NODE_ENV;

const PricingWrapper = styled.div`
  margin: 0;
  position: relative;
  /* padding-bottom: 20px; */
  padding: 0;
  height: 100%;
  display: grid;
  grid-template-rows: 130px 1fr 100px;
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: block;
  }
`;

const PlanTitle = styled.h1`
  font-size: 0.8em;
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: none;
  }
`;

const PricingContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  @media screen and (min-width: ${firstBreakpoint}px) {
    width: 66vw;
    margin: 0 20px;
    padding: 30px 15px 15px;
    display: grid;
    grid-gap: 2em;
    grid-template-columns: 2fr 3fr;
  }
  @media screen and (min-width: ${secondBreakpoint}px) {
    width: 800px;
    grid-template-columns: 300px 1fr;
    grid-gap: 5em;
    padding-bottom: 60px;
    margin: 0 60px;
  }
`;

const PlanWrapper = styled.div`
  flex-direction: column;
  display: ${props => (props.showing ? "flex" : "none")};
  overflow: scroll;
  padding: 0 10px;
  /* max-height: 47vh; */
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: flex;
    padding: 5px;
    /* max-height: none; */
  }
`;

const PlanItem = styled.div`
  display: grid;
  /* flex-direction: column; */
  grid-template-columns: 2fr 3fr;
  box-shadow: 0px 0px 9px -4px rgba(0, 0, 0, 0.75);
  border: ${props =>
    props.selected
      ? `7px solid${props.theme.HC2}`
      : `1px solid ${props.theme.ATextC}`};
  border-radius: 5px;
  margin: 12px 0;
  padding: ${props => (props.selected ? "14px 7px" : "22px 10px")};
  cursor: pointer;
  @media screen and (min-width: ${firstBreakpoint}px) {
    padding: ${props => (props.selected ? "20px 7px" : "28px 10px")};
  }
`;

const PlanName = styled.p`
  font-weight: bold;
  margin: 0;
`;

const PlanPrice = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 1.7em;
  font-weight: bold;
  margin: 0;
  grid-area: 1/1/3/1;
  span {
    font-size: 0.5em;
  }
  @media screen and (min-width: ${firstBreakpoint}px) {
    font-size: 2.2em;
  }
`;

const PlanCredits = styled.p`
  /* font-size: 0.5em; */
  margin: 0 auto;
  max-width: 150px;
`;

const SelectPaymentWrapper = styled.div`
  display: ${props => (props.showing ? "block" : "none")};
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: block;
  }
`;

const PaymentMethods = styled.div`
  margin: 20px 0;
`;

const PaymentDivider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  margin: 30px 0 0;
  span {
    display: inline-block;
    background: white;
    padding: 4px 30px;
    z-index: 10;
  }
  &::after {
    content: "";
    border: 1px solid ${props => props.theme.ATextC};
    z-index: 1;
    position: absolute;
    left: 0;
    width: 100%;
  }
`;

const PaymentMethod = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  .payment-details {
  }
`;

const PaymentTitle = styled.h3`
  color: black;
  text-align: center;
  font-weight: normal;
  font-size: 1em;
  padding: 18px 20px;
  margin: 1rem;
  span {
    display: block;
    position: relative;
  }
`;

const CardWrapper = styled.div`
  margin: 0 20px;
`;

const ContinueButton = styled(Button)`
  display: none;
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: inline-block;
  }
`;

class Pricing extends React.Component {
  state = {
    paymentMethod: "default",
    ccValid: {},
    ccNumber: "",
    updateLoading: false,
    plans: [
      {
        planID: env !== "production" ? "price_1J7mCzJ9EVKHmQsl066rYgYx" : "",
        name: "Connect",
        amount: 49,
        interval: "month",
        credits: 800
      },
      {
        planID: env !== "production" ? "price_1J7mDoJ9EVKHmQslJ7O6op31" : "",
        name: "Messaging Plus",
        amount: 69,
        interval: "month",
        credits: 1200
      },
      {
        planID: env !== "production" ? "price_1J7mEOJ9EVKHmQslCMXOhlAN" : "",
        name: "Messaging Premium",
        amount: 99,
        interval: "month",
        credits: 2000
      }
    ],
    showingPane: "plans"
  };

  componentDidMount = () => {
    this.getCard();
  };

  getCard = async () => {
    const { stripeId } = this.props;
    try {
      const { success, ccExists, details } = await stripeGetPaymentMethods(
        stripeId
      );
      if (ccExists) {
        console.log("details", details.last4);
        this.setState({ ccNumber: details.last4 });
      }
    } catch (err) {
      console.log("err", err.message);
    }
  };

  setPaymentMethod = paymentMethod => {
    this.setState({ paymentMethod });
  };

  stripeElementChange = cc => {
    console.log("element change is ", cc);
    const { paymentMethod } = this.state;
    if (paymentMethod === "default" && !cc.empty) {
      this.togglePayment();
    }
    this.setState({ ccValid: cc });
  };

  validateCC = () => {
    const { ccValid } = this.state;
    if (!ccValid) {
      throw Error("Please enter a credit card to purchase credits");
    } else {
      if (!ccValid.complete) {
        if (ccValid.empty && !ccValid.error) {
          console.error(ccValid);
          throw Error(
            "You need to enter a credit card to activate your subscription"
          );
        }
        if (!ccValid.error) {
          console.error(ccValid);
          throw Error(
            "There is an issue with your credit card. Please try to re-enter it."
          );
        }
        console.log(ccValid);
        if (ccValid.error) {
          console.error(ccValid);
          throw Error(ccValid.error.message);
        }
      } else {
        return ccValid;
      }
    }
  };

  createPaymentMethod = async () => {
    const { stripeId, alert } = this.props;
    this.setState({ updateLoading: true });
    try {
      const { token } = await this.props.stripe.createToken({});
      const { cardId } = await stripeCreateCard({
        stripeId,
        source: token.id
      });
      console.log("response", cardId);
      this.setState({ updateLoading: false });
      alert({
        type: "success",
        msg: "Credit Card Added Successfully"
      });
      return cardId;
    } catch (err) {
      // new Error(err.message);
      console.error(`updateCustomer err: `, err.response);
      this.setState({ updateLoading: false });
      alert({
        type: "error",
        msg: err.response.data
      });
      throw Error(Error.general);
    }
  };

  saveCard = async () => {
    const { paymentMethod, ccNumber } = this.state;
    const { selectedPlan, alert, handlePricing } = this.props;
    if (!selectedPlan.planID) {
      alert({
        type: "error",
        msg: "Please select a plan before continuing"
      });
      return;
    }
    if (paymentMethod === "default") {
      handlePricing({
        paymentMethod: { paymentMethod: "default", last4: ccNumber }
      });
      return;
    }
    try {
      this.setState({ loading: true });
      const valid = this.validateCC();
      if (valid) {
        const stripePaymentMethod = await this.createPaymentMethod();
        handlePricing({ paymentMethod: stripePaymentMethod });
        this.setState({ loading: false });
      }
    } catch (err) {
      console.log("error saving card", err.message);
      alert({
        type: "error",
        msg: err.message
      });
    }
  };

  togglePayment = () => {
    const { paymentMethod } = this.state;
    if (paymentMethod === "default") {
      this.setState({ paymentMethod: "new" });
    } else {
      this._element.clear();
      this.setState({ paymentMethod: "default", ccValid: {} });
    }
  };

  paymentMethodNotice = e => {
    const { paymentMethod } = this.state;
    if (paymentMethod === "default") {
      this.togglePayment();
      e.preventDefault();
    }
  };

  togglePanes = () => {
    const { showingPane } = this.state;
    if (showingPane === "plans") {
      this.setState({ showingPane: "payment" });
    } else {
      this.saveCard();
    }
  };

  back = () => {
    const { showingPane } = this.state;
    if (showingPane === "payment") {
      this.setState({ showingPane: "plans" });
    } else {
      this.props.back();
    }
  };

  render() {
    const { plans, paymentMethod, ccNumber, loading, showingPane } = this.state;
    const { selectedPlan, progress } = this.props;
    return (
      <PricingWrapper>
        <ModalHeader
          back={this.props.back}
          title='Set up Messaging'
          progress={progress}
          showProgress={true}
        />
        <PricingContent>
          <PlanWrapper showing={showingPane === "plans"}>
            <PlanTitle>Select a Package</PlanTitle>
            {/* package list */}
            {plans.map((plan, i) => (
              <PlanItem
                key={i}
                onClick={() => this.props.selectPlan(plan)}
                selected={plan.planID === selectedPlan.planID}
              >
                <PlanPrice>
                  ${plan.amount} <span>/{plan.interval}</span>
                </PlanPrice>
                <PlanName>{plan.name}</PlanName>
                <PlanCredits>{plan.credits} Message Credits</PlanCredits>
              </PlanItem>
            ))}
          </PlanWrapper>
          <SelectPaymentWrapper showing={showingPane === "payment"}>
            {/* <h2>Select a plan</h2> */}
            <PaymentMethods>
              <input
                type='checkbox'
                value='paymentMethod'
                name='paymentMethod'
                onChange={this.togglePayment}
                checked={paymentMethod === "default"}
              />
              <label htmlFor='paymentMethod'>
                Use my card on file: {ccNumber}
              </label>
              <PaymentDivider>
                <span>or</span>
              </PaymentDivider>

              <PaymentMethod onClick={this.paymentMethodNotice}>
                <PaymentTitle className='title'>
                  <span>Enter a New Card</span>
                </PaymentTitle>
                <div className='payment-details'>
                  <CardWrapper>
                    <CardElement
                      onChange={this.stripeElementChange}
                      onReady={c => (this._element = c)}
                    />
                  </CardWrapper>
                </div>
              </PaymentMethod>
              <ContinueButton solid onClick={this.saveCard} disabled={loading}>
                Continue
                {loading && <LoaderDotsSmall />}
              </ContinueButton>
            </PaymentMethods>
          </SelectPaymentWrapper>
        </PricingContent>
        <MobileButtons next={this.togglePanes} back={this.back} />
      </PricingWrapper>
    );
  }
}

export default injectStripe(Pricing);
