import React from "react";
import WorkflowModal from "./WorkflowModal";
import {
  Icon,
  H2,
  ActionOptions,
  ActionGrid,
  ActionType,
  ActionIcon,
  ActionText
} from "./styles";

const SelectAction = props => {
  const {
    selectNewAction,
    toggleActionModal,
    addNewAction,
    withinActionGroup,
    activeStepNo
  } = props;
  return (
    <WorkflowModal
      open={selectNewAction}
      title={
        <div>
          <Icon className='material-icons-outlined'>add</Icon>{" "}
          <H2>Add an Action</H2>
        </div>
      }
      onCloseMethod={() => toggleActionModal(false, false)}
      body={
        <div>
          <ActionOptions>
            <ActionGrid short>
              <ActionType
                right
                onClick={() => addNewAction("SMS", activeStepNo)}
              >
                <ActionIcon>
                  <span className='material-icons-outlined'>add_circle</span>
                </ActionIcon>
                <ActionText>Text Message</ActionText>
              </ActionType>
              {/* TODO: Add this back in if we decide to allow multiple conditions */}
              {/* <ActionType
                top={!withinActionGroup}
                onClick={() => addNewAction('Email')}
              >
                <ActionIcon>
                  <span className="material-icons-outlined">add_circle</span>
                </ActionIcon>
                <ActionText>Email</ActionText>
              </ActionType> */}
              {!withinActionGroup && (
                <ActionType onClick={() => addNewAction("DELAY", activeStepNo)}>
                  <ActionIcon>
                    <span className='material-icons-outlined'>add_circle</span>
                  </ActionIcon>
                  <ActionText>Delay</ActionText>
                </ActionType>
              )}
            </ActionGrid>
          </ActionOptions>
        </div>
      }
    />
  );
};

export default SelectAction;
