import React from "react";
import styled from "styled-components";
import {
  getWorkflowById,
  getAllCompanyWorkflows,
  updateWorkflow,
  deleteWorkflowById
} from "../../../utils/WorkflowAPI";
import { validateWorkflow, validateName } from "./Validation";
import { LoaderDotsSmall } from "../../elements/Loaders";
import { Button } from "../../styled/Buttons";
import { firstBreakpoint } from "../../styled/PhoneNumberProvision";
import {
  ContactViewWrapper,
  ErrorWrapper,
  ErrorInner,
  ErrorToggle
} from "../../styled/Integrations";
import { TitleWrapper, TitleLeft, TitleRight } from "../../styled/Layout";
import WorkflowModal from "./WorkflowModal";
import ToggleBtn from "../../elements/ToggleBtn";
import WorkflowOptions from "./WorkflowOptions";
import { H1, H2, InputError } from "./styles";
import { InputBox } from "../../styled/Input";
import IndustrySelectModal from "../campaignCreation/IndustrySelectModal";

const WorkflowContainer = styled.div``;

const HeaderRight = styled.div`
  display: flex;
  justify-content: space-between;
  width: 200px;
  @media only screen and (max-width: 901px) {
    min-width: 100px;
    width: auto;
    h2 {
      display: none;
    }
  }
`;

const Back = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .material-icons {
    font-size: 44px;
  }
`;

const Delete = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${props => props.theme.TextC};
  &:hover {
    color: ${props => props.theme.HC1};
  }
`;

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 1/4;
  @media screen and (min-width: ${firstBreakpoint}px) {
    grid-column: auto;
  }
`;

const WorkflowTitle = styled.div`
  display: flex;
  align-items: center;
  margin: 20px;
  h2 {
    margin: 0;
  }
`;

const NameWrapper = styled.div`
  position: relative;
  min-height: 32px;
  display: flex;
  align-items: center;
`;

const NameInputWrapper = styled.div`
  position: relative;
  min-height: 32px;
  width: 300px;
  max-width: 90%;
`;

const EditWorkflowIcon = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #7211b6;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  margin-left: 10px;
  span {
    color: white;
    font-size: 16px;
  }
`;

const TitleInputError = styled(InputError)`
  top: 33px;
`;
class EditWorkflows extends React.Component {
  state = {
    workflowID: "",
    workflow: {
      name: ""
    },
    pendingWorkflowName: "",
    editWorkflowNameActive: false,
    errors: {},
    modalActivate: false,
    activate: false,
    activeReady: false,
    workflowValid: false,
    showErrors: false,
    expandErrors: false,
    deleting: false,
    modalDraft: false,
    workflows: [],
    modalOverlap: false,
    deleteActionKey: null,
    modalDeleteAction: false,
    modalIndustrySelect: false,
    savingIndustry: false
  };

  componentDidMount = async () => {
    const {
      computedMatch: {
        params: { workflowID }
      },
      userCompany
    } = this.props;
    this.setState({ workflowID });
    const workflow = await this.getWorkflow(workflowID);
    const activate = workflow && workflow.status === "ACTIVE" ? true : false;
    this.setState({ workflow, activate });
    const workflows = await this.getWorkflows();
    this.setState({ workflows });
    if (userCompany._id && !userCompany.industry) {
      this.setState({ modalIndustrySelect: true });
    }
  };

  componentDidUpdate = prevProps => {
    const { userCompany } = this.props;
    const { userCompany: oldCompany } = prevProps;
    if (userCompany.industry !== oldCompany.industry && !userCompany.industry) {
      this.setState({ modalIndustrySelect: true });
    }
  };

  getWorkflow = async workflowID => {
    const workflow = await getWorkflowById(workflowID);
    return workflow;
  };

  getWorkflows = async () => {
    const allWorkflows = getAllCompanyWorkflows();
    return allWorkflows;
  };

  updateWorkflowState = update => {
    const { workflow } = this.state;
    this.setState({ workflow: { ...workflow, ...update } });
  };

  updateWorkflowName = async () => {
    const { workflow, workflows, pendingWorkflowName } = this.state;
    const { _id } = workflow;
    const notValid = validateName(pendingWorkflowName, workflows, _id);
    console.log("validated: ", notValid);
    if (notValid) {
      this.setState({ errors: { ...this.state.errors, ...notValid } });
    }
    if (!notValid) {
      updateWorkflow(_id, { name: pendingWorkflowName });
      this.setState({
        workflow: { ...workflow, name: pendingWorkflowName },
        editWorkflowNameActive: false,
        pendingWorkflowName: "",
        errors: {}
      });
    }
  };

  editWorkFlowName = () => {
    const { workflow } = this.state;
    const { name } = workflow;
    this.setState({ editWorkflowNameActive: true, pendingWorkflowName: name });
  };

  changePendingName = input => {
    this.setState({ pendingWorkflowName: input.target.value });
  };

  onSave = async () => {
    const { modalActivate, activate, workflow } = this.state;
    const valid = await this.validate();
    if (!valid) {
      return;
    }
    const overlap = this.checkOverlap();
    console.log("overlap", overlap);
    if (overlap && !workflow.overlapIgnore) {
      this.setState({ modalOverlap: true });
      return;
    }
    if (!modalActivate) {
      this.setState({ modalActivate: true, showErrors: false });
      return;
    } else {
      if (activate) {
        this.setState(
          {
            workflow: {
              ...workflow,
              status: "active"
            }
          },
          async () => {
            await this.saveWorkflow();
            this.props.history.push("/workflows");
          }
        );
      } else {
        this.setState(
          {
            workflow: {
              ...workflow,
              status: "inactive"
            }
          },
          async () => {
            await this.saveWorkflow();
            this.props.history.push("/workflows");
          }
        );
      }
    }
  };

  onBack = async () => {
    const { editWorkflowNameActive } = this.state;
    if (editWorkflowNameActive) {
      const { alert } = this.props;
      alert({
        type: "error",
        msg: "Please finish saving your workflow name"
      });
    } else {
      this.props.history.push("/workflows");
    }
  };

  toggleActive = async () => {
    const { workflow, editWorkflowNameActive } = this.state;
    const { _id } = workflow;
    const { alert } = this.props;
    if (editWorkflowNameActive) {
      alert({
        type: "error",
        msg: "Please finish saving your workflow name"
      });
    } else if (workflow.status === "ACTIVE") {
      this.setState(
        {
          workflow: {
            ...workflow,
            status: "INACTIVE",
            overlapIgnore: false
          }
        },
        async () => {
          await updateWorkflow(_id, { status: "INACTIVE" });
          alert({
            type: "info",
            msg: "Workflow Inactive"
          });
        }
      );
    } else if (workflow.status === "INACTIVE" || workflow.status === "DRAFT") {
      const notValid = validateWorkflow(workflow);
      console.log("notValid: ", notValid);
      if (notValid) {
        this.setState({ errors: { ...this.state.errors, ...notValid } });
        alert({
          type: "error",
          msg: "There are errors with your workflow"
        });
      } else {
        console.log("setting to active");
        this.setState(
          {
            workflow: {
              ...workflow,
              status: "ACTIVE"
            }
          },
          async () => {
            await updateWorkflow(_id, { status: "ACTIVE" });
            alert({
              type: "success",
              msg: "Workflow activated"
            });
          }
        );
      }
    }
  };

  onFilterClose = () => {
    const { workflow } = this.state;
    if (!workflow.overlapIgnore && workflow.status === "active") {
      const overlap = this.checkOverlap();
      if (overlap) {
        return true;
      }
    }
    return false;
  };

  setOverlapIgnore = x => {
    const { workflow } = this.state;
    this.setState(
      {
        workflow: {
          ...workflow,
          overlapIgnore: true
        },
        modalOverlap: false
      },

      () => {
        if (x !== "stopToggle") {
          this.toggleActive();
        }
      }
    );
  };

  checkOverlap = () => {
    const { workflow } = this.state;
    const workflows = this.state.workflows.filter(
      flow =>
        flow.actionID === workflow.actionID &&
        flow.triggerID === workflow.triggerID &&
        flow.status === "active" &&
        flow.workflowID !== workflow.workflowID
    );
    let overlap = false;
    if (workflows.length === 0) {
      return false;
    }
    workflow.filters.forEach(filter => {
      console.log(filter);
      if (filter.value === "all" && workflows.length > 0) {
        overlap = true;
        return overlap;
      }
      const valueAll = workflows.some(flow => {
        const flowOverlap = flow.filters.some(
          flowFilter =>
            flowFilter.value === "all" && flowFilter.field === filter.field
        );
        console.log("overlap all", flowOverlap);
        return flowOverlap;
      });
      if (valueAll) {
        overlap = true;
        return overlap;
      }
      const fieldMatch = workflows.some(flow => {
        const flowOverlap = flow.filters.some(flowFilter => {
          if (flowFilter.field === filter.field && flowFilter.value === "all") {
            overlap = true;
            return true;
          } else if (flowFilter.field === filter.field) {
            const valueOverlap = flowFilter.value.some(value =>
              filter.value.includes(value)
            );
            if (valueOverlap) {
              overlap = true;
            }
            return valueOverlap;
          }
          return false;
        });
        console.log("overlap", flowOverlap);
        return flowOverlap;
      });
      if (fieldMatch) {
        overlap = true;
        return true;
      }
    });
    return overlap;
  };

  resetValidate = type => {
    let { errors: newErrors } = this.state;
    newErrors = delete newErrors[type];
    console.log("newErrors: ", newErrors);
    // this.setState({ errors: { ...this.state.errors, ...notValid } });
  };

  changeTrigger = triggerID => {
    const { workflow } = this.state;
    this.setState({ workflow: { ...workflow, triggerID } }, () => {
      this.saveWorkflow();
    });
  };

  changeFilter = filterID => {
    const { workflow } = this.state;
    this.setState({ workflow: { ...workflow, filterID } }, () => {
      this.saveWorkflow();
    });
  };

  updateFilters = filters => {
    const { workflow } = this.state;
    this.setState({ workflow: { ...workflow, filters } }, () => {
      this.saveWorkflow();
    });
  };

  toggleErrors = () => {
    const { showErrors } = this.state;
    this.setState({ showErrors: !showErrors }, () => {
      this.validate();
    });
  };

  deleteWorkflow = async () => {
    const { workflowID, workflow } = this.state;
    const { name } = workflow;
    this.setState({ deleting: true });
    await deleteWorkflowById(workflowID);
    this.props.alert({ type: "info", msg: `Deleted Workflow '${name}'` });
    this.props.history.push("/workflows");
  };

  expandErrors = () => {
    const { expandErrors } = this.state;
    this.setState({ expandErrors: !expandErrors });
  };

  cancelDeleteAction = () => {
    this.setState({ modalDeleteAction: false, deleteActionKey: null });
  };

  setIntegrationName = (integrationName, key) => {
    const { workflow } = this.state;
    const { actions } = workflow;
    const integrations = {
      ConstantContact: {
        list_membership: ""
      },
      AppStack: {
        jobID: "",
        stage: ""
      },
      JobNimbus: {
        record_type_name: "",
        status_name: "Active"
      },
      Hatch: {
        deptID: "",
        status: "Active"
      },
      EZTexting: {
        groupID: {
          id: 0,
          name: ""
        }
      },
      JobProgress: {
        referred_by_type: "",
        referred_by_note: ""
      },
      HealthcareSource: {
        candidateID: ""
      },
      PreIntent: {
        webhookURL: ""
      }
    };
    actions[key] = {
      ...actions[key],
      integrationName,
      options: integrations[integrationName]
        ? integrations[integrationName]
        : null
    };
    this.setState(
      {
        workflow: {
          ...workflow,
          actions
        }
      },
      () => {
        this.saveWorkflow();
      }
    );
  };

  setIntegrationOptions = (options, key) => {
    const { workflow } = this.state;
    const { actions } = workflow;
    actions[key].options = options;
    this.setState(
      {
        workflow: {
          ...workflow,
          actions
        }
      },
      () => {
        this.saveWorkflow();
      }
    );
  };

  toggleSaveIndustry = () => {
    const { savingIndustry } = this.state;
    this.setState({ savingIndustry: !savingIndustry });
  };

  handleSaveIndustry = async userCompany => {
    const { alert } = this.props;
    try {
      this.props.updateCompanyDoc(userCompany);
      this.setState({ modalIndustrySelect: false });
      alert({
        type: "success",
        msg: "Industry successfully saved."
      });
    } catch (err) {
      console.log("error setting industry", err.message);
    }
    this.toggleSaveIndustry();
  };

  render() {
    const {
      errors,
      workflow = {},
      modalActivate,
      editWorkflowNameActive,
      pendingWorkflowName,
      workflowValid,
      showErrors,
      activeReady,
      expandErrors,
      modalDeleteConfirm,
      modalDraft,
      deleting,
      modalOverlap,
      modalDeleteAction
    } = this.state;
    const { userProfile, userCompany, notifications } = this.props;

    return (
      <div>
        <TitleWrapper>
          <TitleLeft>
            <Back>
              <a
                href='/workflows'
                onClick={e => {
                  e.preventDefault();
                  this.onBack();
                }}
              >
                <span className='material-icons'>navigate_before</span>
              </a>
            </Back>
            {editWorkflowNameActive ? (
              <WorkflowTitle>
                <NameInputWrapper>
                  <InputBox
                    type='text'
                    name='name'
                    data-namereadable='workflow title'
                    placeholder='Untitled Workflow'
                    value={pendingWorkflowName}
                    onChange={input => this.changePendingName(input)}
                  />
                  {errors.name && (
                    <InputError>{errors.name.message}</InputError>
                  )}
                </NameInputWrapper>
                <EditWorkflowIcon onClick={() => this.updateWorkflowName()}>
                  <span className='material-icons'>done</span>
                </EditWorkflowIcon>
              </WorkflowTitle>
            ) : (
              <WorkflowTitle>
                <NameWrapper>
                  <H1>
                    {workflow.name === "Untitled Workflow"
                      ? "Untitled Workflow"
                      : workflow.name}
                  </H1>
                  {errors.name && (
                    <TitleInputError>{errors.name.message}</TitleInputError>
                  )}
                </NameWrapper>
                <EditWorkflowIcon onClick={() => this.editWorkFlowName()}>
                  <span className='material-icons'>edit</span>
                </EditWorkflowIcon>
              </WorkflowTitle>
            )}
          </TitleLeft>
          <TitleRight>
            <HeaderRight>
              {/* <Save>
            <Button solid onClick={this.onSave}>
              Save
            </Button>
          </Save> */}
              <ToggleWrapper>
                <ToggleBtn
                  on={workflow.status === "ACTIVE"}
                  // disabled={!activeReady}
                  onChange={this.toggleActive}
                />
              </ToggleWrapper>
              <H2>Activate</H2>
              <Delete
                onClick={() => {
                  this.setState({
                    modalDeleteConfirm: true
                  });
                }}
              >
                <span className='material-icons'>delete</span>
              </Delete>
            </HeaderRight>
          </TitleRight>
        </TitleWrapper>
        <WorkflowContainer>
          <ErrorWrapper
            notificationOffset={notifications}
            className={showErrors && !workflowValid ? "show" : "hidden"}
            multipleErrors={Object.keys(errors).length > 1}
          >
            {showErrors && !workflowValid && (
              <div>
                {Object.keys(errors).length > 1 && (
                  <ErrorToggle onClick={this.expandErrors}>
                    <span className='material-icons'>error_outline</span>There
                    are some errors with your flow
                    <span
                      className={`material-icons arrow-down ${
                        expandErrors ? "show" : "hide"
                      }`}
                    >
                      arrow_drop_down
                    </span>
                  </ErrorToggle>
                )}
                <ErrorInner
                  show={expandErrors}
                  className={`${expandErrors ? "show" : "hide"} ${
                    Object.keys(errors).length === 1
                      ? "hideToggle"
                      : "showToggle"
                  }`}
                  onClick={this.toggleErrors}
                >
                  {Object.keys(errors).map(key => (
                    <div key={key}>
                      {Object.keys(errors).length === 1 && (
                        <span className='material-icons'>error_outline</span>
                      )}
                      {errors[key]}
                    </div>
                  ))}
                </ErrorInner>
              </div>
            )}
          </ErrorWrapper>
          <ContactViewWrapper>
            {/* <IntegrationCard layoutPosition="left">Actions</IntegrationCard> */}
            <WorkflowOptions
              workflow={workflow}
              showErrors={showErrors}
              errors={errors}
              resetValidate={this.resetValidate}
              userProfile={userProfile}
              userCompany={userCompany}
              updateWorkflowState={this.updateWorkflowState}
              changeTrigger={this.changeTrigger}
              changeFilter={this.changeFilter}
              updateFilters={this.updateFilters}
              onFilterClose={this.filterClose}
              setOverlapIgnore={this.setOverlapIgnore}
              alert={this.props.alert}
              saveWorkflow={this.saveWorkflow}
              setIntegrationName={this.setIntegrationName}
              setIntegrationOptions={this.setIntegrationOptions}
              setAction={this.setAction}
              newAction={this.newAction}
              toggleActive={this.toggleActive}
              removeAction={this.removeAction}
              setActionOrder={this.setActionOrder}
            />
            <WorkflowModal
              open={modalActivate}
              title='Activate your workflow'
              onCloseMethod={() => this.setState({ modalActivate: false })}
              body={
                <div>
                  <p>
                    Your workflow looks complete. Do you want to activate your
                    workflow?
                  </p>
                  <Button
                    onClick={() => {
                      this.setState({ activate: false }, () => {
                        this.saveWorkflow();
                        this.props.history.push("/workflows");
                      });
                    }}
                  >
                    Keep inactive
                  </Button>
                  <Button
                    solid
                    onClick={() => {
                      this.setState({ activate: true }, () => {
                        this.onSave();
                      });
                    }}
                  >
                    Activate workflow
                  </Button>
                </div>
              }
            />
            <WorkflowModal
              open={modalDeleteConfirm}
              title='Delete your workflow'
              onCloseMethod={() => this.setState({ modalDeleteConfirm: false })}
              body={
                <div>
                  <p>Are you sure you want to delete this workflow?</p>
                  <Button
                    onClick={() => this.setState({ modalDeleteConfirm: false })}
                    disabled={deleting}
                  >
                    Cancel
                  </Button>
                  <Button
                    solid
                    onClick={() => this.deleteWorkflow()}
                    disabled={deleting}
                  >
                    Delete
                    {deleting && <LoaderDotsSmall />}
                  </Button>
                </div>
              }
            />
            <WorkflowModal
              open={modalDraft}
              title='Save as Draft'
              onCloseMethod={() => {
                this.setState({ modalDraft: false });
              }}
              body={
                <div>
                  <p>
                    There are errors with this workflow, so it will be saved as
                    a draft. Would you like to continue editing?
                  </p>
                  <Button
                    onClick={() => {
                      this.setState({ modalDraft: false });
                    }}
                  >
                    Edit Workflow
                  </Button>
                  <Button
                    solid
                    onClick={() => {
                      this.props.history.push("/workflows");
                    }}
                  >
                    Save as Draft
                  </Button>
                </div>
              }
            />
            <WorkflowModal
              open={modalOverlap}
              title='Workflow Overlap'
              shouldCloseOnOverlayClick={false}
              shouldCloseOnEsc={false}
              body={
                <div>
                  <p>
                    This workflow overlaps with another workflow. New leads will
                    get multiple messages. Are you sure you want to activate
                    this workflow?
                  </p>
                  <Button
                    onClick={() => {
                      this.setState({ modalOverlap: false });
                    }}
                  >
                    Cancel
                  </Button>
                  <Button solid onClick={this.setOverlapIgnore}>
                    Ignore and Activate
                  </Button>
                </div>
              }
            />
            <WorkflowModal
              open={modalDeleteAction}
              title='Delete Action'
              shouldCloseOnOverlayClick={false}
              shouldCloseOnEsc={false}
              body={
                <div>
                  <p>Are you sure you want to delete this action?</p>
                  <Button onClick={this.cancelDeleteAction}>Cancel</Button>
                  <Button solid onClick={() => this.removeAction()}>
                    Delete
                  </Button>
                </div>
              }
            />
          </ContactViewWrapper>
        </WorkflowContainer>
        <IndustrySelectModal
          modalIndustrySelect={this.state.modalIndustrySelect}
          loading={this.state.savingIndustry}
          saveAction={this.handleSaveIndustry}
          saving={this.toggleSaveIndustry}
          onClose={() => this.setState({ modalIndustrySelect: false })}
          userCompany={userCompany}
          msg='Before editing this workflow, please select your industry. You can
            update this at any time from the settings page.'
        />
      </div>
    );
  }
}

export default EditWorkflows;
