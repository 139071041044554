import React from "react";
import BaseIcon from "./BaseIcon";

const InfoIcon = props => (
  <BaseIcon
    color={props.theme && props.theme.HC3 ? props.theme.HC3 : "#4664e3"}
  >
    <circle
      cx='12'
      cy='12'
      r='10'
    />
    <line
      x1='12'
      y1='16'
      x2='12'
      y2='12'
    />
    <line
      x1='12'
      y1='8'
      x2='12'
      y2='8'
    />
  </BaseIcon>
);

export default InfoIcon;
