import axios from "axios";
import { axiosResponse, errorHandler } from "./Helpers";

const BP_API = process.env.REACT_APP_BP_API;

export const companyCreate = async (companyDoc, config) => {
  try {
    if (config) {
      console.log("config", config);
      const user = axiosResponse(
        await axios.post(`${BP_API}/v1/company`, companyDoc, config)
      );
      return user;
    } else {
      console.log("no config");
      const user = axiosResponse(
        await axios.post(`${BP_API}/v1/company`, companyDoc)
      );
      return user;
    }
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const companyGetById = async companyId => {
  try {
    const user = axiosResponse(
      await axios.get(`${BP_API}/v1/company/${companyId}`)
    );
    return user;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const companyUpdate = async companyDoc => {
  try {
    const user = axiosResponse(
      await axios.put(`${BP_API}/v1/company/${companyDoc._id}`, companyDoc)
    );
    return user;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};
