import styled from "styled-components";

const FbPreviewHeadline = styled.p`
  background-color: ${props => (props.PH ? "lightgrey" : "")};
  /* grid-column: ${props => (props.PH ? "1 / 8" : "1 / -1")};
  grid-row: 9; */
  margin: 6px 0;
  font-weight: bold;
  font-size: 16px;
`;

export default FbPreviewHeadline;
