import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const config = {
  apiKey: "AIzaSyA7uUJ3q2du0YcLSJEQuWHozUxmbLCePl8",
  authDomain: "campaign-191617.firebaseapp.com",
  databaseURL: "https://campaign-191617.firebaseio.com",
  projectId: "campaign-191617",
  storageBucket: "campaign-191617.appspot.com"
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const auth = firebase.auth();
const firestore = firebase.firestore();
const storage = firebase.storage();

export { firebase, auth, firestore, storage };
