import React from "react";
import {
  InputContainer,
  InputBoxWrapper,
  InputBox,
  InputLabel,
  FormWrapper,
  FormDescription
} from "../../../styled/Input";

export const JobProgressCoreSettings = props => {
  const { integration } = props;

  return (
    <FormWrapper single>
      <InputContainer slimHeight customWidth='100%'>
        <InputLabel>API Token</InputLabel>
        <InputBoxWrapper>
          <InputBox
            type='text'
            name='API_Token'
            readOnly
            value={integration.API_Token}
          />
        </InputBoxWrapper>
      </InputContainer>
      <InputContainer slimHeight customWidth='100%'>
        <InputLabel>Referred By Type</InputLabel>
        <InputBoxWrapper>
          <InputBox
            type='text'
            name='referred_by_type'
            readOnly
            value={integration.referred_by_type}
          />
        </InputBoxWrapper>
      </InputContainer>
      <InputContainer slimHeight customWidth='100%'>
        <InputLabel>Referred By Note</InputLabel>
        <InputBoxWrapper>
          <InputBox
            type='text'
            name='referred_by_note'
            readOnly
            value={integration.referred_by_note}
          />
        </InputBoxWrapper>
      </InputContainer>
      <FormDescription>
        Please contact your Support Representative to update these values.
      </FormDescription>
    </FormWrapper>
  );
};
