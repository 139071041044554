import React from "react";
import styled from "styled-components";

const LoadingBarWrapper = styled.div`
  margin: ${props => (props.noMargin ? "0" : "3em")};
  min-height: ${props => (props.noMargin ? "0" : "5em")};
`;
const Bar = styled.div`
  display: inline-block;
  width: 4px;
  height: 1em;
  border-radius: 4px;
  animation: loading 1s ease-in-out infinite;
  background-color: lightgrey;
  margin: 1px;

  :nth-child(1) {
    animation-delay: 0;
  }

  :nth-child(2) {
    animation-delay: 0.09s;
  }

  :nth-child(3) {
    animation-delay: 0.18s;
  }

  :nth-child(4) {
    animation-delay: 0.27s;
  }

  //TODO: Figure out animation
  @keyframes loading {
    0% {
      transform: scale(1);
    }
    20% {
      transform: scale(1, 1.5);
    }
    40% {
      transform: scale(1);
    }
  }
`;

class LoadingBar extends React.Component {
  render() {
    const { noMargin } = this.props;
    return (
      <LoadingBarWrapper noMargin={noMargin}>
        <Bar />
        <Bar />
        <Bar />
        <Bar />
      </LoadingBarWrapper>
    );
  }
}

export default LoadingBar;
