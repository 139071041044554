import styled from "styled-components";

const FbPreviewWebsite = styled.h6`
  background-color: ${props => (props.PH ? "lightgrey" : "")};
  color: #606770;
  /* grid-column: 1 / 4;
  grid-row: 14; */
  margin: 0;
  text-transform: uppercase;
  font-size: 0.7em;
  max-width: 174px;
`;

export default FbPreviewWebsite;
