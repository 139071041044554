import React from "react";
import styled, { css } from "styled-components";
import { InputBoxWrapper, Dropdown } from "../../styled/Input";
import { Purple, LightGray, Gray, Red } from "../../styled/Colors";

export const FlowCard = styled.div`
  position: relative;
  max-width: 600px;
  //TODO: turn on to test minimizing cards again.
  /* max-height: ${props => (props.triggerOpen ? "1024px" : "57px")}; */
  /* overflow: hidden; */
  background: ${props => props.theme.BGC};
  border: ${props =>
    props.hasError ? "3px solid #EA6262" : "3px solid #f0f0f0"};
  box-shadow: 0px 0px 16px #00000015;
  margin: 36px auto;
  outline: ${props =>
    props.error ? `3px solid ${props.theme.HCErr}` : "none"};
  transition: max-height 0.2s ease-in-out;
  ${({ isTrigger }) =>
    !isTrigger &&
    css`
      &:before {
        content: "";
        height: 20px;
        border-left: 1px solid ${props => props.theme.HC1};
        position: absolute;
        top: -32px;
        left: 50%;
      }
    `}
  &:after {
    content: "";
    height: 20px;
    border-left: 1px solid #3a0ca2;
    position: absolute;
    bottom: -30px;
  }
  .device,
  .device * {
    &,
    &::before,
    &::after {
      box-sizing: border-box;
      display: block;
    }
  }
`;

export const FlowHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border-bottom: 1px solid ${LightGray};
  background: ${props => (props.triggerOpen ? "#f8f8f9" : "#ffffff")};
  position: relative;
  &:hover {
    .delete {
      opacity: 1;
    }
  }
`;

export const FlowHeaderToggle = styled.div`
  color: #3a0ca2;
  cursor: pointer;
  transform: ${props => (props.triggerOpen ? "none" : "rotate(-180deg)")};
  transition: transform 150ms ease-in-out;
  span {
    font-size: 30px;
  }
`;

export const FlowHeaderDivider = styled.div`
  height: 30px;
  width: 1px;
  background-color: #3a0ca2;
  margin: 10px;
`;

export const FlowHeaderTitle = styled.div`
  color: #3a0ca2;
  font-size: 15px;
  font-weight: 500;
  font-style: italic;
`;

export const FlowHeaderRight = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-right: 40px;
  }
`;

export const FlowHeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

export const FlowBody = styled.div`
  /* margin: 45px; */
  text-align: left;
`;

export const FlowInputLabel = styled.p`
  margin-bottom: 3px;
  color: ${props => props.theme.HC1};
  font-size: 15px;
  font-weight: ${props => (props.bold ? "500" : "300")};
`;

export const Icon = styled.span`
  font-size: ${props => (props.small ? "22px" : "30px")};
  margin-right: 8px;
  display: flex;
  align-items: center;
  color: ${props => props.theme.HC1};
`;

export const H1 = styled.h2`
  font-weight: bold;
  font-size: 1.3em;
  color: ${props => props.theme.HC1};
`;

export const H2 = styled.h2`
  font-weight: bold;
  font-size: 1.1em;
  color: ${props => props.theme.HC1};
`;

export const H3 = styled.h3`
  font-weight: bold;
  font-size: 16px;
  letter-spacing: inherit;
  color: ${props => props.theme.HC1};
`;

export const InputWrapper = styled(InputBoxWrapper)`
  max-width: 400px;
  overflow: hidden;
  max-height: ${props => (props.accordion ? "0" : "none")};
  min-height: ${props => (props.accordion ? "0" : "32px")};
  transition: all 0.3s ease-in-out;
  &.open {
    max-height: 100px;
  }
`;

export const AddAction = styled.div`
  color: ${Purple};
  margin: auto;
  border-radius: 50%;
  .material-icons-outlined {
    cursor: pointer;
    font-size: 36px;
    &:hover {
      transform: scale(1.1);
    }
  }
`;

export const ActionEditButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

export const ActionEditDelete = styled.div`
  cursor: pointer;
  color: #f5b508;
  position: absolute;
  right: 20px;
`;

export const ActionOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
`;

export const ActionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  height: ${props => (props.short ? "60px" : "140px")};
`;

export const ActionType = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${props => props.disabled && "#656565"};
  cursor: ${props => props.disabled && "not-allowed"};
  border-bottom: ${props => props.top && "1px solid #00000015"};
  border-right: ${props => props.right && "1px solid #00000015"};
`;

export const ActionIcon = styled.div`
  color: ${props => (props.disabled ? "#656565" : "#3a0ca2")};
  margin-right: 10px;
`;

export const ActionText = styled.div`
  color: ${props => (props.disabled ? "#656565" : "#7211b6")};
  font-weight: 500;
`;

export const ActionDelete = styled.span`
  display: block;
  position: absolute;
  right: 15px;
  cursor: pointer;
  opacity: 0.2;
  &:hover {
    opacity: 1;
  }
`;

export const ActionBodyDivider = styled.div`
  width: 90%;
  height: 1px;
  background-color: #3a0ca2;
  margin: auto;
`;

export const AndOrDivider = styled.div`
  font-size: 13px;
  width: 55px;
  margin: 10px auto -28px auto;
  text-align: center;
  background: white;
`;

export const OrderIcons = styled.div`
  position: absolute;
  left: 5px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  span {
    opacity: 0.2;
  }
  &:hover span {
    opacity: 1;
    &.first {
      opacity: ${props => (props.first ? "0" : "1")};
    }
    &.last {
      opacity: ${props => (props.last ? "0" : "1")};
    }
  }
  .first {
    pointer-events: ${props => (props.first ? "none" : "auto")};
    opacity: ${props => (props.first ? "0" : ".2")};
  }
  .last {
    pointer-events: ${props => (props.last ? "none" : "auto")};
    opacity: ${props => (props.last ? "0" : "0.2")};
  }
`;

export const FilterHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${props => (props.triggerOpen ? "#f8f8f9" : "#ffffff")};
  position: relative;
  margin-bottom: 20px;
`;

export const FilterHeaderLeft = styled.div`
  display: flex;
  align-items: center;
  h3 {
    text-transform: none;
  }
`;

export const FilterHeaderRight = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-right: 40px;
  }
`;

export const InputError = styled.div`
  color: #ea6262;
  background-color: rgba(237, 41, 57, 0.1);
  position: absolute;
  width: 100%;
  padding: 0.5em 1em;
  font-size: 14px;
  font-weight: 400;

  :empty {
    display: none;
  }
`;

export const WorkflowError = styled.div`
  color: #ea6262;
  background-color: rgba(237, 41, 57, 0.1);
  width: 100%;
  padding: 0.5em 1em;
  font-size: 14px;
  font-weight: 400;
  text-align: center;

  :empty {
    display: none;
  }
`;

export const WorkflowErrorNoCard = styled.div`
  color: #ea6262;
  background-color: rgba(237, 41, 57, 0.1);
  width: 100%;
  max-width: 600px;
  margin: auto;
  padding: 0.5em 1em;
  font-size: 14px;
  font-weight: 400;
  text-align: center;

  :empty {
    display: none;
  }
`;
