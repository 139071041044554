import styled from "styled-components";

const SigninForm = styled.form`
  text-align: center;
  margin-bottom: ${props => (props.noMargin ? "auto" : "2.5em")};

  input {
    max-width: 25em !important;
  }

  select {
    width: 40%;
    max-width: 25em;
    height: 2em;
    border-radius: 0;
    font-size: 16px;
  }

  @media screen and (max-width: 600px) {
    input {
      width: 80%;
    }

    select {
      width: 80%;
    }
  }
`;

export default SigninForm;
