import React from "react";
import AnimateHeight from "react-animate-height";
import styled from "styled-components";
import {
  FlowCard,
  FlowHeader,
  FlowHeaderToggle,
  FlowHeaderRight,
  FlowHeaderDivider,
  FlowHeaderTitle,
  FlowHeaderLeft,
  FlowBody,
  FlowInputLabel,
  Icon,
  H2,
  InputWrapper,
  WorkflowError
} from "./styles";
import { Dropdown } from "../../styled/Input";

const triggers = [
  {
    triggerType: "NEW_CONTACT",
    name: "New Applicant Created",
    shortName: "New Applicant"
  },
  {
    triggerType: "STAGE_CHANGE",
    name: "Contact Status Changes",
    shortName: "Status Change"
  }
  // removing manual enrollment trigger for now
  // {
  //   triggerType: 'MANUAL_ENROLLMENT',
  //   name: 'Manual Contact Enrollment',
  //   shortName: 'Manual Enrollment'
  // }
];

const TriggerBodyWrapper = styled.div`
  margin: 45px;
`;

const SelectTrigger = props => {
  const [triggerOpen, setTriggerOpen] = React.useState(true);
  const [height, setHeight] = React.useState("auto");
  const [currentTrigger, setCurrentTrigger] = React.useState({});

  const { triggerType, updateTrigger, errors, resetValidate } = props;

  React.useEffect(() => {
    if (triggerType) {
      const newTrigger = triggers.find(
        trigger => trigger.triggerType === triggerType
      );
      setCurrentTrigger(newTrigger);
    }
  }, [triggerType]);

  const setNewTrigger = value => {
    console.log("trigger value: ", value);
    resetValidate("triggerType");
    setCurrentTrigger(value);
    const triggerUpdate = { triggerType: value };
    updateTrigger(triggerUpdate);
  };

  const toggleHeight = () => {
    const newHeight = height === 0 ? "auto" : 0;
    setHeight(newHeight);
    setTriggerOpen(!triggerOpen);
  };

  return (
    <FlowCard isTrigger hasError={errors.triggerType}>
      <FlowHeader triggerOpen={triggerOpen}>
        <FlowHeaderLeft>
          <Icon className='material-icons-outlined'>start</Icon>
          <H2>Trigger</H2>
          {currentTrigger && currentTrigger.shortName && (
            <FlowHeaderDivider></FlowHeaderDivider>
          )}
          <FlowHeaderTitle>
            {currentTrigger &&
              currentTrigger.shortName &&
              currentTrigger.shortName}
          </FlowHeaderTitle>
        </FlowHeaderLeft>
        <FlowHeaderRight>
          <FlowHeaderToggle
            triggerOpen={triggerOpen}
            onClick={() => toggleHeight()}
          >
            <span className='material-icons-outlined'>keyboard_arrow_up</span>
          </FlowHeaderToggle>
        </FlowHeaderRight>
      </FlowHeader>
      <AnimateHeight
        duration={200}
        height={height} // see props documentation below
      >
        <FlowBody triggerOpen={triggerOpen}>
          {errors.triggerType && (
            <WorkflowError>{errors.triggerType.message}</WorkflowError>
          )}
          <TriggerBodyWrapper>
            <FlowInputLabel bold>When this workflow runs.</FlowInputLabel>
            <InputWrapper>
              <Dropdown
                type='text'
                name='trigger'
                value={triggerType}
                onChange={input => setNewTrigger(input.target.value)}
              >
                <option value='default'>
                  Select a trigger to start the workflow...
                </option>
                {triggers.map(trigger => (
                  <option value={trigger.triggerType} key={trigger.triggerType}>
                    {trigger.name}
                  </option>
                ))}
              </Dropdown>
            </InputWrapper>
          </TriggerBodyWrapper>
        </FlowBody>
      </AnimateHeight>
    </FlowCard>
  );
};

export default SelectTrigger;
