import styled from "styled-components";

const LargeFiftyTwoWrapper = styled.div`
  display: flex;
  max-width: 1024px;
  margin: 0 auto;

  @media screen and (max-width: 836px) {
    flex-flow: column;
  }
`;

export default LargeFiftyTwoWrapper;
