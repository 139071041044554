import React from "react";
import styled from "styled-components";
import BpModal from "../elements/BpModal";
// Images
import DropdownArrow from "../../images/DropdownArrow.png";
// Styles
import { InputBox, InputContainer, InputBoxWrapper } from "../styled/Input";
import { AreaBackground } from "../styled/Backgrounds";
import InputError from "../styled/InputErrorForm";

const Dropdown = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
`;

const DropdownItem = styled.div`
  width: 100%;
  text-align: left;
  color: ${({ disabled, topItem }) => {
    switch (true) {
      case disabled:
        return "#828282";
      case !topItem:
        return "white";
      default:
        return "inherit";
    }
  }};
  margin: 0 auto;
  display: flex;
  align-items: center;
  letter-spacing: 0
  cursor: ${props => (props.clickableItem ? "pointer" : "auto")};
  justify-content: ${props => (props.centered ? "center" : "space-between")};
  padding-left: 15px;
  background: ${props =>
    props.customBackground ? props.customBackground : "none"};
  background-color: ${props => (props.topItem ? props.theme.BGC : "#393939")};
  border: ${props => (props.topItem ? "1px solid " + props.theme.HC2 : "none")};
  height: 2.5em;
  border-radius: ${props => (props.topItem ? "5px" : "none")};
  font-size: 16px;
  &:focus {
    outline: ${props => (props.disabled ? "none" : "#FCB74B auto 3px")};
  }
  &:hover {
    background-color: ${props => !props.topItem && "#252525"};
  }
`;

const DropdownText = styled.div`
  max-width: 80%;
  overflow: hidden;
  cursor: ${props =>
    props.disabled || !props.topItem ? "default" : "pointer"};
  font-weight: ${props => (props.topItem ? "400" : "300")};
`;
const DropdownIcons = styled.div`
  display: flex;
  justify-content: space-around;
  opacity: ${props => (props.disabled ? ".5" : "1")};

  span {
    font-size: 18px;
    margin: 0 6px;
    cursor: ${props => (props.disabled ? "auto" : "pointer")};
  }

  img {
    margin: 0 6px;
    cursor: ${props => (props.disabled ? "auto" : "pointer")};
  }
`;
const HideShowItems = styled.div`
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  max-height: ${props => (props.dropdownOpen ? "180px" : "0")};
  border-radius: 3px;
  position: absolute;
  width: 18rem;
  top: 2.5rem;
  z-index: 11;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.46, 0.46, 0.46, 0.46);
`;
const ToggleIcon = styled.img`
  margin: 0 6px;
  transform: ${props => (props.dropdownOpen ? "rotate(180deg)" : "none")};
  transition: 0.3s all;
`;

const Overlay = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
`;

const InteractiveDropdown = props => {
  const {
    dropdownItems = [],
    alert,
    disabled,
    readOnly,
    type = "Lead Email",
    validateItem
  } = props;

  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [editItem, setEditItem] = React.useState({
    open: false,
    value: "",
    index: ""
  });
  const [addItem, setAddItem] = React.useState({
    open: false,
    value: "",
    index: ""
  });
  const [removeItem, setRemoveItem] = React.useState({
    open: false,
    value: "",
    index: ""
  });
  const [errMessage, setErrMessage] = React.useState("");

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return function cleanup() {
      console.log("removing event listener");
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const openRemoveModal = (value, index) => {
    setRemoveItem({
      open: true,
      value,
      index
    });
  };

  const handleRemoveItem = async () => {
    const newArray = [...dropdownItems];
    newArray.splice(removeItem.index, 1);
    try {
      props.updateValues(newArray);
      setRemoveItem({
        open: false,
        value: "",
        index: ""
      });
      alert({
        type: "success",
        msg: "Email successfully removed"
      });
    } catch (err) {
      console.error(err.message);
    }
  };

  const openEditModal = (value, index) => {
    setEditItem({
      open: true,
      value,
      index
    });
  };

  const handleEditItem = async () => {
    const valid = validate(editItem.value);
    if (!valid) {
      return false;
    }
    const newArray = dropdownItems.map((item, index) => {
      if (index === editItem.index) {
        return editItem.value;
      } else {
        return item;
      }
    });
    try {
      props.updateValues(newArray);
      setEditItem({
        open: false,
        value: "",
        index: ""
      });
      alert({
        type: "success",
        msg: "Email successfully Updated"
      });
    } catch (err) {
      console.error(err.message);
    }
  };

  const openAddModal = () => {
    setAddItem({
      open: true,
      value: "",
      index: ""
    });
  };

  const handleAddItem = async () => {
    const valid = validate(addItem.value);
    if (!valid) {
      return false;
    }
    const newArray = [...dropdownItems, addItem.value];
    try {
      props.updateValues(newArray);
      setAddItem({
        open: false,
        value: "",
        index: ""
      });
      alert({
        type: "success",
        msg: "Email successfully Added"
      });
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleCancel = () => {
    setEditItem({
      open: false,
      value: "",
      index: ""
    });
    setAddItem({
      open: false,
      value: "",
      index: ""
    });
    setRemoveItem({ open: false, value: "", index: "" });
  };

  const handleOpenCloseDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleClickOutside = event => {
    const targetClasses = event.target.classList;
    if (targetClasses.contains("overlay")) {
      setDropdownOpen(false);
    }
  };

  const validate = value => {
    const validResponse = validateItem(value);
    if (validResponse.valid) {
      return true;
    } else {
      setErrMessage(validResponse.msg);
      return false;
    }
  };

  const onEnterEdit = event => {
    if (event.key === "Enter") {
      handleEditItem();
    }
  };

  const onEnterAdd = event => {
    if (event.key === "Enter") {
      handleAddItem();
    }
  };

  return (
    <div>
      {!!dropdownOpen && <Overlay className='overlay' />}
      <Dropdown className='interactive-dropdown'>
        <DropdownItem
          topItem={true}
          clickableItem={!disabled}
          disabled={disabled}
          onClick={() => !disabled && handleOpenCloseDropdown()}
        >
          <DropdownText
            topItem={true}
            disabled={disabled}
          >
            <span>{!!dropdownItems && dropdownItems[0]}</span>
          </DropdownText>
          <DropdownIcons disabled={disabled}>
            <ToggleIcon
              dropdownOpen={dropdownOpen}
              src={DropdownArrow}
            />
          </DropdownIcons>
        </DropdownItem>
        <HideShowItems dropdownOpen={dropdownOpen}>
          {dropdownItems.map((value, index) => (
            <DropdownItem key={index}>
              <DropdownText>
                <span>{value}</span>
              </DropdownText>
              {!readOnly && 
              <DropdownIcons>
                <span
                  color={"grey"}
                  className='material-icons'
                  title='Edit Item'
                  onClick={() => openEditModal(value, index)}
                >
                  edit
                </span>
                <span
                  color={"red"}
                  className='material-icons'
                  title='Remove Item'
                  onClick={() => openRemoveModal(value, index)}
                >
                  remove_circle_outline
                </span>
              </DropdownIcons>
              }
            </DropdownItem>
          ))}
          {!readOnly && 
          <DropdownItem
            centered={true}
            clickableItem
            onClick={openAddModal}
          >
            <DropdownIcons>
              Add New
              <span
                color={"red"}
                className='material-icons'
                title='Add Item'
              >
                add_circle_outline
              </span>
            </DropdownIcons>
          </DropdownItem>          
          }
        </HideShowItems>
      </Dropdown>
      <BpModal
        open={editItem.open}
        title={`Edit ${type}`}
        primaryAction={{
          btnLabel: "Continue",
          action: handleEditItem
        }}
        secondaryAction={{
          btnLabel: "Cancel",
          action: handleCancel
        }}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        contentLabel={`Edit ${type}`}
        body={
          <div>
            <p>Edit the lead email below then click continue</p>
            <InputContainer>
              <InputBoxWrapper>
                <InputBox
                  bgColor={"white"}
                  name={"editEmailValue"}
                  value={editItem.value}
                  onChange={input =>
                    setEditItem({ ...editItem, value: input.target.value })
                  }
                  onKeyDown={onEnterEdit}
                />
              </InputBoxWrapper>
              {!!errMessage && <InputError>{errMessage}</InputError>}
            </InputContainer>
          </div>
        }
        width='auto'
      />
      <BpModal
        open={addItem.open}
        title={`Add ${type}`}
        primaryAction={{
          btnLabel: "Continue",
          action: handleAddItem
        }}
        secondaryAction={{
          btnLabel: "Cancel",
          action: handleCancel
        }}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        contentLabel={`Add ${type}`}
        body={
          <div>
            <p>Add a new lead email address below then click continue</p>
            <InputContainer>
              <InputBoxWrapper>
                <InputBox
                  bgColor={"white"}
                  name={"addEmailValue"}
                  value={addItem.value}
                  onChange={input =>
                    setAddItem({ ...addItem, value: input.target.value })
                  }
                  onKeyDown={onEnterAdd}
                />
              </InputBoxWrapper>
              {!!errMessage && <InputError>{errMessage}</InputError>}
            </InputContainer>
          </div>
        }
        width='auto'
      />
      <BpModal
        open={removeItem.open}
        title={`Remove ${type}`}
        primaryAction={{
          btnLabel: "Remove",
          action: handleRemoveItem
        }}
        secondaryAction={{
          btnLabel: "Cancel",
          action: handleCancel
        }}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        contentLabel={`Remove ${type}`}
        body={
          <div>
            <p>
              If you continue, this address will no longer receive Facebook
              generated leads.
            </p>
            <p>Are you sure you want to remove this lead email address?</p>
            <AreaBackground>
              <p>
                <strong>{removeItem.value}</strong>
              </p>
            </AreaBackground>
          </div>
        }
        width='auto'
      />
    </div>
  );
};

export default InteractiveDropdown;
