import React from "react";
import styled from "styled-components";
import {
  contactsGet,
  statusesGetByCompany,
  statusesGetByIndustry
} from "../../../utils/ContactAPI";
import BarChart from "../../elements/charts/BarChart";
import LoadingBar from "../../elements/LoadingBar";
import { Dropdown } from "../../styled/Input";
import {
  ChartTitle,
  ChartTitleNumber,
  DashboardModuleTitle,
  Section
} from "./DashboardStyles";

const ChartDropdownWrapper = styled.div`
  max-width: 200px;
`;

const StatusSection = styled(Section)`
  display: grid;
  grid-template-rows: 139px 1fr;
`;

const StatusGraph = props => {
  const { startDate, endDate, industry } = props;
  const [contactCount, setContactCount] = React.useState(0);
  const [recruitingChartData, setRecruitingChartData] = React.useState({});
  const [customerChartData, setCustomerChartData] = React.useState({});
  const [chartsLoading, setChartsLoading] = React.useState(true);
  const [statusList, setStatusList] = React.useState({});
  const [contacts, setContacts] = React.useState([]);
  const [contactType, setContactType] = React.useState("RECRUITING");

  React.useEffect(() => {
    const i = async () => {
      const getContacts = await contactsGet({
        startDate: startDate.toDate(),
        endDate: endDate.toDate()
      });
      const filteredContacts = getContacts.filter(
        contact => !contact.source || contact.source.toUpperCase() !== "TWILIO"
      );
      setContactCount(filteredContacts.length);
      setContacts(filteredContacts);
    };
    i();
  }, [startDate, endDate]);

  React.useEffect(() => {
    console.log("running chartData update");
    if (statusList.RECRUITING) {
      const newDataValues = statusList.RECRUITING.map(status => {
        const count = contacts.reduce(
          (accumulator, currentValue) =>
            currentValue.statusId === status._id
              ? accumulator + 1
              : accumulator,
          0
        );
        return {
          ...status,
          count
        };
      });
      setRecruitingChartData({
        labels: newDataValues.map(s => s.status),
        values: newDataValues.map(s => s.count)
      });
    }
    if (statusList.CUSTOMER) {
      const newDataValues = statusList.CUSTOMER.map(status => {
        const count = contacts.reduce(
          (accumulator, currentValue) =>
            currentValue.statusId === status._id
              ? accumulator + 1
              : accumulator,
          0
        );
        return {
          ...status,
          count
        };
      });
      setCustomerChartData({
        labels: newDataValues.map(s => s.status),
        values: newDataValues.map(s => s.count)
      });
    }
    setChartsLoading(false);
    // contactType is in the dependency array because without it, the data was getting reassigned randomly. My theory was a ghost was updating the values to make me go crazy - Lonita
  }, [statusList, contacts, contactType]);

  const getStatuses = React.useCallback(async () => {
    const companyStatuses = await statusesGetByCompany();
    if (companyStatuses.length > 0) {
      return companyStatuses;
    } else if (industry) {
      const industryStatuses = await statusesGetByIndustry(industry);
      if (industryStatuses.length > 0) {
        return industryStatuses;
      }
    }
  }, [industry]);

  React.useEffect(() => {
    const i = async () => {
      console.log("getting statuses");
      const statuses = await getStatuses();
      const tempStatuses = {};
      statuses.forEach(status => {
        if (!tempStatuses[status.type]) {
          tempStatuses[status.type] = [];
        }
        tempStatuses[status.type].push(status);
      });
      Object.keys(tempStatuses).map(type => {
        tempStatuses[type].sort((a, b) => a.orderNumber - b.orderNumber);
      });
      setStatusList(tempStatuses);
    };
    i();
  }, [getStatuses]);

  return (
    <StatusSection columns="2/5">
      <DashboardModuleTitle style={{ margin: '16px 30px 16px 20px' }}>
        <ChartTitle>
          <h6>Contact Status</h6>
        </ChartTitle>
        {!chartsLoading && (
          <div>
            <ChartTitle>
              <ChartTitleNumber className="color-yellow">
                {contactCount} Contacts
              </ChartTitleNumber>
            </ChartTitle>
            <ChartDropdownWrapper>
              <Dropdown
                selected={contactType}
                onChange={input => setContactType(input.target.value)}
              >
                {Object.keys(statusList).map(type => (
                  <option key={type} value={type}>{`${type.replace(
                    /([^\W_]+[^\s-]*) */g,
                    txt =>
                      txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
                  )} Contacts`}</option>
                ))}
              </Dropdown>
            </ChartDropdownWrapper>
          </div>
        )}
      </DashboardModuleTitle>
      {chartsLoading ? (
        <LoadingBar />
      ) : contactType === "RECRUITING" ? (
        <BarChart
          backgroundColor={"#64edc2"}
          borderColor={"#64edc2"}
          labelValue={recruitingChartData.labels}
          dataValue={recruitingChartData.values}
        />
      ) : (
        <BarChart
          backgroundColor={"#64edc2"}
          borderColor={"#64edc2"}
          labelValue={customerChartData.labels}
          dataValue={customerChartData.values}
        />
      )}
    </StatusSection>
  );
};

export default StatusGraph;
