import React from "react";
import Floater from "react-floater";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import styled from "styled-components";
import { getAllCompanyCampaigns } from "../../../utils/FacebookAPI";
import { handleFloaterClick, monthsAbr } from "../../../utils/Helpers";
import Icon from "../../elements/Icon";

const SummaryWrapper = styled.div`
  p {
    text-align: center;
  }
`;

const SummaryContainer = styled.div`
  background: #f7f7f7;
  padding: 1rem;
  border-radius: 4px;
  max-width: 250px;
  margin: 0.75rem auto;
  p {
    text-align: center;
  }
`;

const SummaryLine = styled.div`
  display: grid;
  grid-template-columns: 1fr 85px;
  div {
    margin: 4px;
    display: flex;
    align-items: center;
  }
  .creditsDescription {
    text-align: left;
  }
  .creditsAmount {
    background: white;
    border-radius: 8px;
    padding: 5px;
    justify-content: center;
  }
`;

const TableWrapper = styled.div`
  max-width: 950px;
  margin: 2em auto 0;
  @media screen and (max-width: 40em) {
    width: 100%;
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }
`;

const TableRow = styled(Tr)`
  padding: 10px 0;
  border-top: ${props =>
    props.zebrastripe === "odd" ? "1px solid #e4e4e4" : "none"};
  border-bottom: ${props =>
    props.zebrastripe === "odd" ? "1px solid #e4e4e4" : "none"};
  background-color: ${props =>
    props.zebrastripe === "odd" ? "#f8f8f8" : "transparent"};
`;

const HeaderCell = styled(Th)`
  margin: 10px 0;
  color: ${props => props.theme.HC3};
  text-align: center;
`;

const BodyCell = styled(Td)`
  margin: 10px 0;
  padding: 0.75rem 7px;
  text-align: center;
  /* min-height: 1.7em; */
  @media screen and (max-width: 40em) {
    &.last {
      padding-bottom: 1em;
    }
  }
`;

const floaterStyles = {
  wrapper: {
    cursor: "pointer"
  },
  container: {
    borderRadius: 5,
    color: "rgb(51, 51, 51)",
    padding: 15,
    textAlign: "center"
  }
};

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.03);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1005;
  cursor: pointer;
`;

const CreditsDate = styled.p`
  font-size: 0.7em;
`;

const CreditDisplay = props => {
  const { adCredits: { adBalance = 0, adSpent = 0, adReserved = 0 } = {} } =
    props;
  const [campaignsActive, setCampaignsActive] = React.useState([]);
  const [campaignBudgetTotal, setCampaignBudgetTotal] = React.useState(0);
  const [campaignSpentTotal, setCampaignSpentTotal] = React.useState(0);
  const [campaignRemainingTotal, setCampaignRemainingTotal] = React.useState(0);
  const [creditLastRun, setCreditLastRun] = React.useState("");
  const [creditsHold, setCreditsHold] = React.useState(0);
  // Resolved TBR-133 - These field names are not references to the database fields
  const [floaters, setFloaters] = React.useState({
    available: false,
    reserved: false,
    total: false,
    hold: false
  });

  React.useEffect(() => {
    const run = async () => {
      try {
        const campaigns = await getAllCompanyCampaigns();
        const campaignsUpdate = campaigns
          .filter(c => c.status === "ACTIVE")
          .map(c => {
            if (c.startDate) {
              const startDate = new Date(c.startDate);
              c.startFormatted = `${
                startDate.getMonth() + 1
              }-${startDate.getDate()}-${startDate.getFullYear()}`;
            }
            if (c.endDate) {
              const endDate = new Date(c.endDate);
              c.endFormatted = `${
                endDate.getMonth() + 1
              }-${endDate.getDate()}-${endDate.getFullYear()}`;
            }
            return c;
          });
        const creditsUpdate = campaignsUpdate.reduce(
          (prev, current) =>
            prev + current.credits.holdExpiration !== null
              ? current.credits.available
              : 0,
          0
        );
        const creditsTotal = campaignsUpdate.reduce(
          (prev, current) => prev + current.credits.original,
          0
        );
        const creditsSpent = campaignsUpdate.reduce(
          (prev, current) => prev + current.credits.spent,
          0
        );
        const creditsAvailable = campaignsUpdate.reduce(
          (prev, current) =>
            prev + current.credits.original - current.credits.spent,
          0
        );
        setCampaignsActive(campaignsUpdate);
        setCreditsHold(creditsUpdate);
        setCampaignBudgetTotal(creditsTotal);
        setCampaignSpentTotal(creditsSpent);
        setCampaignRemainingTotal(creditsAvailable);

        const lastRun = new Date();
        const lastRunMonth = monthsAbr[lastRun.getMonth()];
        const lastRunDay = lastRun.getDate();
        let lastRunHours = lastRun.getHours();
        const timePeriod = lastRunHours > 12 ? "pm" : "am";
        if (lastRunHours > 12) {
          lastRunHours = lastRunHours - 12;
        }
        const lastRunMinutes = lastRun.getMinutes();
        setCreditLastRun(
          `${lastRunMonth} ${lastRunDay}, ${lastRunHours}:${lastRunMinutes}${timePeriod}`
        );
      } catch (err) {
        console.log(err.message);
      }
    };
    run();
  }, []);

  const floaterClick = (e, clickTarget) => {
    e.preventDefault();
    const newFloaters = handleFloaterClick(clickTarget, floaters);
    setFloaters({ ...newFloaters });
  };

  React.useEffect(() => {
    console.log("floaters changed", floaters);
  }, [floaters]);

  return (
    <SummaryWrapper className='creditsSummary'>
      {!!creditLastRun && (
        <CreditsDate>Last Updated: {creditLastRun}</CreditsDate>
      )}
      <SummaryContainer>
        <SummaryLine>
          <div className='creditsDescription'>
            <strong>Available</strong>
            <Floater
              open={floaters.available}
              content='The available credits you have to use on new ads.'
              styles={floaterStyles}
              placement='right'
            >
              <Icon
                onClick={e => floaterClick(e, "available")}
                name='floater-toggle'
                icon='info'
              />
              {floaters.available && (
                <Overlay
                  onClick={e => floaterClick(e, "available")}
                  className='overlay'
                />
              )}
            </Floater>
          </div>
          <div className='creditsAmount'>
            <span>{Math.floor(adBalance)}</span>
          </div>
        </SummaryLine>
        <SummaryLine>
          <div className='creditsDescription'>
            <strong>Reserved</strong>
            <Floater
              open={floaters.reserved}
              content='The reserved credits for your currently running ads.'
              styles={floaterStyles}
              placement='right'
            >
              <Icon
                onClick={e => floaterClick(e, "reserved")}
                name='floater-toggle'
                icon='info'
              />
              {floaters.reserved && (
                <Overlay
                  onClick={e => floaterClick(e, "reserved")}
                  className='overlay'
                />
              )}
            </Floater>
          </div>
          <div className='creditsAmount'>{Math.ceil(adReserved)}</div>
        </SummaryLine>
        {!!creditsHold && (
          <SummaryLine>
            <div className='creditsDescription'>
              <strong>On Hold</strong>
              <Floater
                open={floaters.hold}
                content='After a campaign is canceled we put 5 credits on hold until the final ad spend is received from Facebook.'
                styles={floaterStyles}
                placement='right'
              >
                <Icon
                  onClick={e => floaterClick(e, "hold")}
                  name='floater-toggle'
                  icon='info'
                />
                {floaters.hold && (
                  <Overlay
                    onClick={e => floaterClick(e, "hold")}
                    className='overlay'
                  />
                )}
              </Floater>
            </div>
            <div className='creditsAmount'>{creditsHold}</div>
          </SummaryLine>
        )}
        <SummaryLine>
          <div className='creditsDescription'>
            <strong>Total</strong>
            <Floater
              open={floaters.total}
              content='Your total credits. This includes the credits that are reserved for currently running ads and any on hold credits for recently canceled ads.'
              styles={floaterStyles}
              placement='right'
            >
              <Icon
                onClick={e => floaterClick(e, "total")}
                name='floater-toggle'
                icon='info'
              />
              {floaters.total && (
                <Overlay
                  onClick={e => floaterClick(e, "total")}
                  className='overlay'
                />
              )}
            </Floater>
          </div>
          <div className='creditsAmount'>
            {Math.floor(adBalance + adReserved)}
          </div>
        </SummaryLine>
      </SummaryContainer>
      {campaignsActive.length > 0 && (
        <TableWrapper>
          <h4>Current Campaigns</h4>
          <Table>
            <Thead>
              <TableRow>
                <HeaderCell>Campaign Name</HeaderCell>
                <HeaderCell>Budget</HeaderCell>
                <HeaderCell>Used</HeaderCell>
                <HeaderCell>Unused</HeaderCell>
                <HeaderCell>Campaign Start</HeaderCell>
                <HeaderCell>Campaign End</HeaderCell>
              </TableRow>
            </Thead>
            <Tbody>
              {campaignsActive.map((campaign, key) => (
                <TableRow
                  key={campaign._id}
                  zebrastripe={key % 2 ? "even" : "odd"}
                >
                  <BodyCell>{campaign.name}</BodyCell>
                  <BodyCell>{campaign.credits.original}</BodyCell>
                  <BodyCell>{Math.ceil(campaign.credits.spent) || ""}</BodyCell>
                  <BodyCell>
                    {Math.floor(
                      campaign.credits.original - campaign.credits.spent
                    ) || ""}
                  </BodyCell>
                  <BodyCell>
                    {campaign.startFormatted
                      ? campaign.startFormatted
                      : campaign.startDate}
                  </BodyCell>
                  <BodyCell className='last'>
                    {campaign.endFormatted
                      ? campaign.endFormatted
                      : campaign.endDate}
                  </BodyCell>
                </TableRow>
              ))}
              <TableRow className='totals'>
                <BodyCell>
                  <strong>Totals</strong>
                </BodyCell>
                <BodyCell>
                  <strong>{campaignBudgetTotal}</strong>
                </BodyCell>
                <BodyCell>
                  <strong>{Math.ceil(campaignSpentTotal)}</strong>
                </BodyCell>
                <BodyCell>
                  <strong>{Math.floor(campaignRemainingTotal)}</strong>
                </BodyCell>
              </TableRow>
            </Tbody>
          </Table>
        </TableWrapper>
      )}
    </SummaryWrapper>
  );
};

export default CreditDisplay;
