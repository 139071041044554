import axios from "axios";
import { axiosResponse, errorHandler } from "./Helpers";

const BP_API = process.env.REACT_APP_BP_API;

export const messagesGetThread = async ({
  companyId = "",
  contactId = "",
  contactPhone = ""
}) => {
  try {
    const params = [];
    if (companyId) {
      params.push(`companyId=${companyId}`);
    }
    if (contactId) {
      params.push(`contactId=${contactId}`);
    }
    if (contactPhone) {
      params.push(`contactPhone=${contactPhone}`);
    }
    const messageThread = axiosResponse(
      await axios.get(`${BP_API}/v1/twilio/query?${params.join("&")}`)
    );
    return messageThread;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(`${status}: ${message}`);
  }
};

export const messagesSend = async request => {
  try {
    const messageResponse = axiosResponse(
      await axios.post(`${BP_API}/v1/twilio/sms`, request)
    );
    return messageResponse;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(`${status}: ${message}`);
  }
};

export const messageDelete = async messageId => {
  try {
    const messageResponse = axiosResponse(
      await axios.delete(`${BP_API}/v1/twilio/sms/${messageId}`)
    );
    return messageResponse;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(`${status}: ${message}`);
  }
};

export const messageCompanyUnread = async () => {
  try {
    const messageResponse = axiosResponse(
      await axios.get(`${BP_API}/v1/twilio/unread/company`)
    );
    return Number.isInteger(messageResponse.data)
      ? messageResponse.data
      : messageResponse;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(`${status}: ${message}`);
  }
};

export const messageContactUnread = async contactId => {
  try {
    const messageResponse = axiosResponse(
      await axios.get(`${BP_API}/v1/twilio/unread/contact/${contactId}`)
    );
    return Number.isInteger(messageResponse.data)
      ? messageResponse.data
      : messageResponse;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(`${status}: ${message}`);
  }
};

export const messagesGetConversations = async params => {
  try {
    const messageResponse = axiosResponse(
      await axios.get(`${BP_API}/v1/twilio/contact-list/most-recent`, {
        params
      })
    );
    return messageResponse;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(`${status}: ${message}`);
  }
};

export const messagesMarkRead = async ({ contactId, companyId }) => {
  try {
    const messageResponse = axiosResponse(
      await axios.post(`${BP_API}/v1/twilio/mark/read`, {
        contactId,
        companyId
      })
    );
    return messageResponse;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(`${status}: ${message}`);
  }
};

export const twilioGetNumbers = async params => {
  try {
    const messageResponse = axiosResponse(
      await axios.get(`${BP_API}/v1/twilio/find/numbers`, { params })
    );
    return messageResponse;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(`${status}: ${message}`);
  }
};

export const twilioGetMedia = async mediaUrl => {
  try {
    const mediaResponse = axiosResponse(await axios.get(mediaUrl));
    console.log("mediaResponse", mediaResponse);
    return mediaResponse;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(`${status}: ${message}`);
  }
};

export const socketInit = async () => {
  try {
    const response = axiosResponse(await axios.get(`${BP_API}/v1/twilio/ws`));
    return response;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(`${status}: ${message}`);
  }
};

export const twilioNumberProvision = async provisionData => {
  try {
    const response = axiosResponse(
      await axios.post(`${BP_API}/v1/twilio/create/provisioned`, {
        smsUrl: `https://api.boostpooint.com/v1/twilio/incomingsms`,
        ...provisionData
      })
    );
    return response;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(`${status}: ${message}`);
  }
};
