import React from "react";
import styled from "styled-components";

const NotificationsWrapper = styled.div`
  width: 100%;
`;

const NotificationHeader = styled.div`
  display: flex;
  align-items: center;
  font: normal normal 500 24px Poppins, sans-serif;
  color: #3a0ca2;
  padding-bottom: 6px;
  border-bottom: 1px solid rgba(223, 216, 227, 0.5);
  .material-icons {
    color: #3a0ca2;
  }
`;

const NotificationIcon = styled.div`
  margin: 0 10px;
`;

const NotificationsContent = styled.div``;

const NotificationItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  padding-bottom: 6px;
  border-bottom: 1px solid rgba(223, 216, 227, 0.5);
`;

const Icon = styled.span`
  color: #8c8c8c;
  margin: 0 10px;
`;

const NotificationBody = styled.div`
  /* margin-left: 15px; */
`;

const NotificationTitle = styled.p`
  font-weight: bold;
  margin-top: 0;
`;

const NotificationLinks = styled.p`
  display: flex;
  justify-content: center;
`;

const MainLink = styled.a`
  color: ${props => props.theme.HC1};
  text-decoration: underline;
`;

const Notifications = props => {
  const { unreadMessages } = props;

  return (
    <NotificationsWrapper>
      <NotificationHeader>
        <NotificationIcon>
          <span className='material-icons'>notifications</span>
        </NotificationIcon>
        <div>Notifications</div>
      </NotificationHeader>
      <NotificationsContent>
        {unreadMessages > 0 ? (
          <NotificationItem>
            <Icon className='material-icons'>chat</Icon>
            <NotificationBody>
              <NotificationTitle>
                You have {unreadMessages} unread message
                {unreadMessages > 1 ? "s" : ""}
              </NotificationTitle>
              <NotificationLinks>
                <MainLink href='/messages'>Go To Messages</MainLink>
              </NotificationLinks>
            </NotificationBody>
          </NotificationItem>
        ) : (
          <NotificationItem>
            <Icon className='material-icons'></Icon>
            <NotificationBody>
              <NotificationTitle>You're all caught up!</NotificationTitle>
            </NotificationBody>
          </NotificationItem>
        )}
      </NotificationsContent>
    </NotificationsWrapper>
  );
};

export default Notifications;
