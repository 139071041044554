import styled from "styled-components";
import Card from "./Card";
import { InputContainer } from "./Input";

export const ContactViewWrapper = styled.div`
  display: grid;
  row-gap: 1em;
  column-gap: 3em;
  margin: 3px;
  grid-template-columns: 1fr 2fr;
  height: 100%;
  overflow: auto;
  transition: all 0.3s ease-in-out;
  &.valid {
    padding-top: 0;
  }
  &.invalid {
    padding-top: 36px;
  }
  @media (min-width: 991px) and (max-width: 1300px) {
    grid-template-columns: 345px 1fr;
  }
  @media (min-width: 1600px) {
    grid-template-columns: 500px 1fr;
  }
  @media only screen and (max-width: 1060px) {
    display: block;
    position: relative;
    /* max-width: 87vw; */
    margin: 0 auto 1.5em;
  }
`;

export const BackToList = styled.div`
  grid-column: 1/3;
  margin-bottom: 1.5em;
  display: flex;
  @media only screen and (max-width: 1060px) {
    grid-column: auto;
  }
  div {
    background-color: #4a4c4a;
    font-weight: bold;
    color: white;
    padding: 10px 20px 16px;
    display: flex;
    align-content: center;
    position: relative;
  }
  a {
    color: inherit;
  }
`;

export const NavigateBack = styled.span`
  position: relative;
  top: 5px;
`;

export const IntegrationCard = styled(Card)`
  display: ${props =>
    props.mobile && props.layoutPosition !== "left" ? "none" : "block"};
  margin: ${props =>
    props.layoutPosition === "left"
      ? "0 0 3em 2em"
      : props.layoutPosition === "full"
      ? "0 2em 1em 2em"
      : "0 2em 3em 0"};
  grid-column: ${props =>
    props.layoutPosition === "left"
      ? "1/2"
      : props.layoutPosition === "full"
      ? "1/3"
      : "2/3"};
  padding: 1em;
  ${props =>
    props.fixed
      ? `
    position: fixed;
    top: 0;
    z-index: 1001;
    left: 0;
    width: 100%;
    margin: 0;
    background: white;
  `
      : ""}
  @media only screen and (max-width: 1040px) {
    /* margin: 0 1.5em 1.5em 1.5em; */
  }
  @media only screen and (max-width: 1060px) {
    /* max-width: 87vw; */
    margin: 0 1.5em 1.5em;
    display: ${props =>
      !props.mobile && props.layoutPosition !== "left" && !props.displayAll
        ? "none"
        : "block"};
  }
`;

export const IntegrationName = styled.h3`
  font-size: 2em;
`;

export const IntegrationLoading = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.6);
`;

export const TitleIcon = styled.span`
  display: block;
  color: #8c8c8c;
  text-align: center;
  font-size: 4rem;
`;

export const MappingRow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 641px;
  margin: 36px auto 0;
  border-bottom: 1px solid gray;
  padding-bottom: 30px;
  @media only screen and (min-width: 700px) {
    display: grid;
    grid-template-columns: 1fr 25px 1fr;
    margin: 0 auto;
    padding: 0;
    border: none;
  }
`;

export const ArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  @media only screen and (min-width: 700px) {
    margin-top: 8px;
  }
`;

export const ArrowIcon = styled.span`
  transform: rotate(90deg);
  @media only screen and (min-width: 700px) {
    transform: none;
  }
`;

export const InputContainerField = styled(InputContainer)`
  margin-bottom: 5px;
  @media only screen and (max-width: 699px) {
  }
`;

export const DropdownContainer = styled(InputContainer)`
  margin-bottom: 5px;
`;

export const IntegrationIcon = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  img {
    margin-bottom: 10px;
  }
  .material-icons {
    font-size: 64px;
  }
  @media only screen and (min-width: 700px) {
    width: 18rem;
    margin: 20px auto;
  }
`;

export const ErrorWrapper = styled.div`
  position: absolute;
  top: ${props => (props.notificationOffset ? "90px" : "58px")};
  max-height: 0;
  left: 0;
  width: 100%;
  background: ${props => props.theme.HCErr};
  color: white;
  padding: 0;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
  &.show {
    padding: 15px 0;
    max-height: 50px;
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: ${props => (props.multipleErrors ? "column" : "row")};
  }
  .material-icons {
    padding-right: 10px;
  }
`;

export const ErrorInner = styled.div`
  max-height: 0;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  width: 100%;
  background: ${props => props.theme.HCErr};
  z-index: 10;
  &.show {
    padding: 10px 0;
    max-height: 500px;
    overflow: scroll;
  }
  &.hideToggle {
    padding: 0 0 10 0;
    max-height: 500px;
    div {
      padding-top: 0;
    }
  }
  div {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ErrorToggle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .arrow-down {
    transition: all 0.3s linear;
    transform-origin: 11px 12px;
    &.show {
      transform: rotate(180deg);
    }
  }
`;
