import axios from "axios";
import { axiosResponse, errorHandler } from "./Helpers";

const BP_API = process.env.REACT_APP_BP_API;

const newIntegration = {
  type: "",
  authentication: {},
  fieldMappings: {},
  options: {},
  companyId: ""
};

export const integrationCreate = async newData => {
  try {
    if (!newData.type) {
      throw new Error("Integration type is required.");
    }
    if (!newData.companyId) {
      throw new Error("Company Id is required.");
    }
    const response = axiosResponse(
      await axios.post(`${BP_API}/v1/integration/create`, {
        ...newIntegration,
        ...newData
      })
    );
    return response;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(`${status}: ${message}`);
  }
};

export const integrationUpdate = async updatedData => {
  try {
    const response = axiosResponse(
      await axios.put(`${BP_API}/v1/integration/update`, updatedData)
    );
    return response;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(`${status}: ${message}`);
  }
};

export const integrationGetById = async integrationId => {
  try {
    const response = axiosResponse(
      await axios.get(`${BP_API}/v1/integration/id/${integrationId}`)
    );
    return response;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(`${status}: ${message}`);
  }
};

export const integrationDeleteById = async integrationId => {
  try {
    const response = axiosResponse(
      await axios.delete(`${BP_API}/v1/integration/id/${integrationId}`)
    );
    return response;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(`${status}: ${message}`);
  }
};

export const integrationGetByCompany = async () => {
  try {
    const response = axiosResponse(await axios.get(`${BP_API}/v1/integration`));
    return response;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(`${status}: ${message}`);
  }
};

export const integrationFieldsByType = async (types = []) => {
  const integrationFields = [
    {
      type: "APPLICANTSTACK",
      fields: {
        firstName: "First Name",
        lastName: "Last Name",
        company: "Company",
        email: "Email",
        phone: "Phone Number",
        mobilePhone: "Mobile Phone",
        address: "Address",
        address2: "Address 2",
        city: "City",
        contactState: "State",
        zip: "Zip",
        website: "Website"
      },
      icon: ""
    },
    {
      type: "CONSTANT_CONTACT",
      fields: {
        firstName: "First Name",
        lastName: "Last Name",
        company: "Company",
        email: "Email",
        phone: "Phone Number",
        mobilePhone: "Mobile Phone",
        address: "Address",
        address2: "Address 2",
        city: "City",
        contactState: "State",
        zip: "Zip",
        website: "Website"
      },
      icon: ""
    }
  ];
  const matchingIntegrations = integrationFields.filter(f =>
    types.includes(f.type)
  );
  return matchingIntegrations;
};
