import React from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import styled from "styled-components";
import LoadingCircle from "../../../styled/LoadingCircle";

import { LoaderDotsSmall } from "../../../elements/Loaders";

const PaymentFixWrapper = styled.div`
  min-width: 300px;
  max-width: 530px;
  margin: 0 auto;
  p.invoice-description {
    margin-top: 20px;
  }
`;
const CCContainer = styled.div`
  width: 25em;
  margin: 1em auto;
  max-width: 90%;
  border: solid 0.5px rgb(238, 238, 238);
  padding: 0.5em;
`;

class StripeCreateAccount extends React.Component {
  state = {
    setDefaultMethod: true,
    hideCardElement: false
  };

  render() {
    const { invoiceLoading, hideCardElement } = this.state;
    return (
      <PaymentFixWrapper>
        {invoiceLoading ? (
          <LoadingCircle />
        ) : (
          <div>
            {!hideCardElement ? (
              <CCContainer>
                <CardElement onChange={this.props.stripeElementChange} />
              </CCContainer>
            ) : (
              <LoaderDotsSmall />
            )}
          </div>
        )}
      </PaymentFixWrapper>
    );
  }
}

export default injectStripe(StripeCreateAccount, { withRef: true });
