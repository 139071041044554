import React from "react";
// import PropTypes from 'prop-types';
import moment from "moment";
import { DateRangePicker } from "react-dates";
import styled, { withTheme } from "styled-components";
import { Button } from "../styled/Buttons";

// Styled Components
const DatePickerWrapper = styled.div`
  background-color: ${props => props.theme.BGC};
  border-radius: 8px;
  .CalendarDay__selected {
    background-color: ${props => props.theme.HC1};
    border: 1px solid ${props => props.theme.HC1};
    &:hover {
      background-color: ${props => props.theme.HB2Transparent};
      border-color: ${props => props.theme.HC1Transparent};
      color: ${props => props.theme.ATextC};
    }
  }
  .CalendarDay__selected_span {
    background-color: ${props => props.theme.HC1Transparent};
    border: 1px solid ${props => props.theme.HC1Transparent};
    &:hover {
      background-color: ${props => props.theme.HB2Transparent};
      border-color: ${props => props.theme.HC1Transparent};
      color: ${props => props.theme.ATextC};
    }
  }
  .CalendarDay__hovered_span {
    background-color: ${props => props.theme.HB2Transparent};
    border-color: ${props => props.theme.HB2Transparent};
    color: ${props => props.theme.CTextC};
    &:hover {
      background-color: ${props => props.theme.HB2Transparent};
      border-color: ${props => props.theme.HC1Transparent};
      color: ${props => props.theme.ATextC};
    }
  }
  .DateInput_input__focused {
    border-bottom-color: ${props => props.theme.HC1};
  }
`;

const PresetList = styled.div`
  margin: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const SubmitList = styled.div`
  margin: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const PresetButton = styled.button`
  background-color: ${props => props.theme.HC2};
  color: ${props => props.theme.ATextC};
  border-radius: 10px;
  margin: 0.5em;
  height: 2.5em;
  letter-spacing: 1px;
  font-size: 12px;
`;

const today = moment().startOf("day");
// const tomorrow = moment().add(1, 'day');
const presets = [
  {
    text: "Today",
    start: today,
    end: today
  },
  {
    text: "Yesterday",
    start: moment().startOf("day").subtract(1, "days"),
    end: moment().startOf("day").subtract(1, "days")
  },
  {
    text: "Last 7 Days",
    start: moment().startOf("day").subtract(6, "days"),
    end: today
  },
  {
    text: "Last 30 Days",
    start: moment().startOf("day").subtract(29, "days"),
    end: today
  },
  {
    text: "This Month",
    start: moment().startOf("month"),
    end: moment().endOf("month")
  },
  {
    text: "Last Month",
    start: moment().subtract(1, "month").startOf("month"),
    end: moment().subtract(1, "month").endOf("month")
  }
];

class DateRangePickerWrapper extends React.Component {
  state = {
    prevStartDate: this.props.initStartDate
      ? moment(this.props.initStartDate)
      : null,
    prevEndDate: this.props.initEndDate ? moment(this.props.initEndDate) : null,
    startDate: this.props.initStartDate
      ? moment(this.props.initStartDate)
      : moment().startOf("day"),
    endDate: this.props.initEndDate
      ? moment(this.props.initEndDate)
      : moment().add(4, "weeks").endOf("day"),
    focusedInput: null
  };

  componentDidUpdate(prevProps) {
    const { initStartDate, initEndDate } = this.props;
    if (initStartDate !== prevProps.initStartDate) {
      this.setState({
        prevStartDate: this.props.initStartDate
          ? moment(this.props.initStartDate)
          : null,
        startDate: this.props.initStartDate
          ? moment(this.props.initStartDate)
          : null
      });
    }
    if (initEndDate !== prevProps.initEndDate) {
      this.setState({
        prevEndDate: this.props.initEndDate
          ? moment(this.props.initEndDate)
          : null,
        endDate: this.props.initEndDate ? moment(this.props.initEndDate) : null
      });
    }
  }

  onDatesChange = ({ startDate, endDate, preset }) => {
    this.setState({ startDate, endDate });
    if (this.props.presetCallback) {
      this.props.presetCallback(preset || null);
    }
  };

  onFocusChange = focusedInput => {
    this.setState({ focusedInput });
  };

  onSelect = () => {
    const { startDate, endDate } = this.state;
    this.setState({
      prevStartDate: startDate,
      prevEndDate: endDate,
      focusedInput: null
    });
    this.props.onClose(startDate, endDate);
  };

  onCancel = () => {
    this.setState({
      startDate: this.state.prevStartDate,
      endDate: this.state.prevEndDate,
      focusedInput: null
    });
  };

  renderDatePresets = () => {
    const { isAdSchedule } = this.props;

    return (
      <div>
        {!isAdSchedule ? (
          <PresetList>
            {presets.map(preset => (
              <PresetButton
                key={preset.text}
                className='dayPicker__presetBtn'
                type='button'
                onClick={() =>
                  this.onDatesChange({
                    startDate: preset.start,
                    endDate: preset.end,
                    focusedInput: "endDate",
                    preset
                  })
                }
              >
                {preset.text}
              </PresetButton>
            ))}
          </PresetList>
        ) : null}
        <SubmitList className='dayPicker__submitList'>
          <Button onClick={this.onSelect} solid>
            Select
          </Button>
          <Button onClick={this.onCancel}>Cancel</Button>
        </SubmitList>
      </div>
    );
  };

  render() {
    const smallDevice = window.matchMedia("(max-width: 599px)").matches;
    const showWithPortal = !!smallDevice;
    const numberOfMonths = smallDevice ? 1 : 2;

    const { focusedInput, startDate, endDate } = this.state;
    const { isAdSchedule } = this.props;
    return (
      <DatePickerWrapper className='dateRangePicker'>
        {!isAdSchedule ? (
          <DateRangePicker
            renderCalendarInfo={this.renderDatePresets}
            numberOfMonths={numberOfMonths}
            initialVisibleMonth={() =>
              moment().subtract(1, "month").startOf("month")
            }
            onDatesChange={this.onDatesChange}
            onFocusChange={this.onFocusChange}
            startDate={startDate} // momentPropTypes.momentObj or null,
            startDateId='your_unique_start_date_id' // PropTypes.string.isRequired,
            endDate={endDate} // momentPropTypes.momentObj or null,
            endDateId='your_unique_end_date_id' // PropTypes.string.isRequired,
            focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onClose={() => this.onCancel()}
            isOutsideRange={() => false}
            keepOpenOnDateSelect
            hideKeyboardShortcutsPanel
            withFullScreenPortal={showWithPortal}
            noBorder
          />
        ) : (
          <DateRangePicker
            renderCalendarInfo={this.renderDatePresets}
            numberOfMonths={numberOfMonths}
            onDatesChange={this.onDatesChange}
            onFocusChange={this.onFocusChange}
            startDate={startDate} // momentPropTypes.momentObj or null,
            startDateId='your_unique_start_date_id' // PropTypes.string.isRequired,
            endDate={endDate} // momentPropTypes.momentObj or null,
            endDateId='your_unique_end_date_id' // PropTypes.string.isRequired,
            focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onClose={() => this.onCancel()}
            // controls which direction picker opens
            openDirection='up'
            keepOpenOnDateSelect
            hideKeyboardShortcutsPanel
            withFullScreenPortal={showWithPortal}
            noBorder
          />
        )}
      </DatePickerWrapper>
    );
  }
}

export default withTheme(DateRangePickerWrapper);
