import axios from "axios";
import { axiosResponse, errorHandler } from "./Helpers";

const BP_API = process.env.REACT_APP_BP_API;

export const coreSubscriptions = ["price_1HSRgsJ9EVKHmQslmeFRaAKC"];

export const connectSubscriptions = [
  "prod_Llbwqd1huSicV8",
  "prod_JlbSURYfOTvmF0",
  "prod_JlIqGzOVpGvIv4", //test mode
  "prod_JlIpuNonjxLG9o", //test mode
  "prod_JlIofxsg3brpo0", //test mode
  "prod_JTJ9X8OPhkd15k", //test mode
  "prod_JRTM3WrdjPISTW" //test mode
];

export const attractSubscriptions = [
  "prod_LlbwsR3plAbKVJ",
  "prod_I5Tl0UMu5QzTem",
  "prod_HcFiRD6UHQBHWz",
  "prod_EAiENlaovqmXSg",
  "prod_I2Wk8DwMtnN2hC", //test mode
  "prod_I2TiddSXyuuHwa", //test mode
  "prod_G2wCcrGbG7VKKq", //test mode
  "prod_G29JzSyisis7rL", //test mode
  "prod_E6DuKzlWoYu0u6" // test mode
];

export const stripeCreate = async (body, config) => {
  try {
    if (config) {
      const stripeInfo = axiosResponse(
        await axios.post(`${BP_API}/v1/stripe/customer`, body, config)
      );
      return stripeInfo;
    } else {
      const stripeInfo = axiosResponse(
        await axios.post(`${BP_API}/v1/stripe/customer`, body)
      );
      return stripeInfo;
    }
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const getStripeInfo = async () => {
  try {
    const stripeInfo = axiosResponse(
      await axios.get(`${BP_API}/v1/stripe/info`)
    );
    return stripeInfo;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const stripeInfoUpdate = async stripeInfoData => {
  try {
    const stripeInfo = axiosResponse(
      await axios.put(`${BP_API}/v1/stripe/info`, stripeInfoData)
    );
    return stripeInfo;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const isConnectActive = subscriptions => {
  try {
    return subscriptions.some(s => connectSubscriptions.includes(s.product));
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const isAttractActive = subscriptions => {
  try {
    return subscriptions.some(s => attractSubscriptions.includes(s.product));
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const stripeCreateCard = async cardData => {
  try {
    const stripeInfo = axiosResponse(
      await axios.post(`${BP_API}/v1/stripe/card`, cardData)
    );
    return stripeInfo;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const stripeUpdateCustomer = async customerData => {
  try {
    const stripeInfo = axiosResponse(
      await axios.put(`${BP_API}/v1/stripe/customer`, customerData)
    );
    return stripeInfo;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const stripeGetPaymentMethods = async stripeId => {
  try {
    const stripeInfo = axiosResponse(
      await axios.get(`${BP_API}/v1/stripe/cards/${stripeId}`)
    );
    return stripeInfo;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(`${status} : ${message}`);
  }
};

export const stripeCreateSubscriptions = async ({ stripeId, plan }) => {
  try {
    // this requests the subscriptions from Stripe, not our database
    const stripeInfo = axiosResponse(
      await axios.post(`${BP_API}/v1/stripe/subscriptions/`, { stripeId, plan })
    );
    return stripeInfo;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const stripeGetSubscriptions = async stripeId => {
  try {
    // this requests the subscriptions from Stripe, not our database
    const stripeInfo = axiosResponse(
      await axios.get(`${BP_API}/v1/stripe/subscriptions/${stripeId}`)
    );
    return stripeInfo;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const stripeGetInvoices = async params => {
  try {
    // this requests the subscriptions from Stripe, not our database
    const stripeInfo = axiosResponse(
      await axios.get(`${BP_API}/v1/stripe/invoices`, {
        params
      })
    );
    return stripeInfo;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

/**
 * creates a Stripe invoice
 * @param  {object} params
 * @param  {string} params.stripeId Stripe customer id
 * @param  {string} params.currency=usd currency of invoice
 * @param  {number} params.invoiceAmount invoice amount in cents
 * @param  {string} params.description displayed on invoice as line item description
 * @return {object} stripeInfo
 */
export const stripeCreateInvoice = async params => {
  try {
    const { stripeId, currency = "usd", invoiceAmount, description } = params;
    const stripeInfo = axiosResponse(
      await axios.post(`${BP_API}/v1/stripe/invoice`, {
        stripeId,
        currency,
        invoiceAmount,
        description
      })
    );
    return stripeInfo;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

/**
 * Pays stripe invoice
 * @param  {object} params
 * @param  {string} params.invoiceId Stripe id of invoice to be paid
 * @param  {string} params.source source id of Stripe payment method to use for paying the invoice
 */
export const stripePayInvoice = async ({ invoiceId, source }) => {
  try {
    if (!invoiceId) {
      throw new Error("Invoice ID missing");
    }
    if (!source) {
      throw new Error("Payment source missing");
    }
    const stripeInfo = axiosResponse(
      await axios.post(`${BP_API}/v1/stripe/pay/invoice`, {
        invoiceId,
        source
      })
    );
    return stripeInfo;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const stripeGetInvoiceById = async invoiceId => {
  try {
    // this requests the subscriptions from Stripe, not our database
    const stripeInfo = axiosResponse(
      await axios.get(`${BP_API}//v1/stripe/invoice/${invoiceId}`)
    );
    return stripeInfo;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const invoiceDelete = async invoiceId => {
  try {
    const stripeInfo = axiosResponse(
      await axios.delete(`${BP_API}/v1/stripe/invoice/${invoiceId}`)
    );
    return stripeInfo;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const invoiceVoid = async invoiceId => {
  try {
    const stripeInfo = axiosResponse(
      await axios.delete(`${BP_API}/v1/stripe/invoice/${invoiceId}/void`)
    );
    return stripeInfo;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};
