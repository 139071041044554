import React from "react";
import styled from "styled-components";
import AdPreview from "../../AdPreview";
import {
  campaignGetById,
  campaignInsightsGetById,
  campaignPause
} from "../../../utils/FacebookAPI";
import { contactsGet } from "../../../utils/ContactAPI";
import { dateFormat, formatMoney, formatTenth } from "../../../utils/Helpers";
import BpModal from "../../elements/BpModal";

import Floater from "react-floater";
import GoogleRadiusMap from "../campaignCreation/GoogleMap";
import { TitleWrapper, TitleLeft } from "../../styled/Layout";
import { H1 } from "../workflows/styles";
import { Button, CancelButton } from "../../styled/Buttons";
import mediaSize from "../../styled/MediaQueries";
import CampaignDuplicate from "../campaignHistory/CampaignDuplicate";
import _ from "underscore";
import CampaignEdit from "../campaignHistory/CampaignEdit";
import Icon from "../../elements/Icon";

const floaterStyles = {
  wrapper: {
    cursor: "pointer"
  },
  container: {
    borderRadius: 5,
    color: "rgb(51, 51, 51)",
    padding: 15,
    textAlign: "center"
  }
};

const Back = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .material-icons {
    font-size: 44px;
    cursor: pointer;
  }
`;

// const Icon = styled.i`
//   position: relative;
//   top: 2px;
//   font-size: 1em;
//   color: #dfd8e3;
//   &:hover {
//     color: darkgray;
//   }
// `;

const OverviewWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  box-shadow: 0 3px 5px -2px #000000;
  overflow-x: hidden;
  overflow-y: scroll;
  ${mediaSize.phone`
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
  `}
`;

const Overview = styled.div`
  max-width: 1500px;
  flex: 1;
  padding: 0 3em;
  ${mediaSize.phone`
    padding: 0;
    margin-top: 4rem;
    width: 100%;
  `}
`;

const Header = styled.div`
  padding: 2em 0 0.5em 0;
  ${mediaSize.phone`
    padding: 0.5em 1em;
  `}
`;

const HeaderDetails = styled.div`
  padding-left: 3em;
  ${mediaSize.phone`
    padding-left: 0;
  `}
`;

const CampaignType = styled.p`
  text-transform: capitalize;
`;
const MapWrapper = styled.div`
  padding-left: 3em;
  ${mediaSize.phone`
    padding-left: 0;
  `}
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 3rem;
  padding: 3em;
  ${mediaSize.phone`
    margin: 0;
    padding: 1em
  `}
`;

const DetailsRow = styled.div`
  display: flex;
  width: 100%;
  max-width: 3024px;
  flex-wrap: wrap;
  justify-content: ${props => (props.center ? "center" : "space-between")};
  margin: ${props => (props.center ? "1rem 0" : "")};
`;

const DetailsHeader = styled.h3`
  font-weight: 400;
  color: ${props => props.theme.HC2};
  margin-bottom: 0.5em;
  letter-spacing: 1px;
`;

const HeaderTooltipWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  H3 {
    margin-bottom: 0;
  }
`;

const AddressRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  :nth-child(odd) {
    background-color: #fafafa;
  }
`;

const AddressWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50%;
  p {
    margin: 0.5rem;
  }
`;

const AddressMarker = styled.p`
  border: 1px solid #3a0ca2;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  min-width: 20px;
  color: white;
  background-color: #3a0ca2;
  font-size: 14px;
`;

const Details = styled.div`
  width: 50%;
  padding: 1em;
  ${mediaSize.phone`
    width: 100%
    padding: 1em 0;
  `}
`;

const SpendPercent = styled.div`
  height: 1rem;
  width: 17rem;
  max-width: 100%;
  margin: auto;
  background: linear-gradient(
    to right,
    #3a0ca2 ${props => props.percent || "0"}%,
    rgb(228, 228, 228) ${props => props.percent || "0"}%
  );
`;

const SpentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 17rem;
  max-width: 100%;
  margin: 0.5rem auto;
  font-size: 13px;
`;

const ModalLink = styled.a`
  color: #453992;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const AdsLink = styled.a`
  display: block;
  font-size: 0.8em;
  color: #685da9;
  font-style: italic;
  margin: 1em 0;
`;

const FloaterOverlay = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1005;
`;

const ContactLink = styled.a`
  text-align: center;
  display: inline-block;
  margin: auto;
  background: #dfd8e3;
  padding: 10px;
  transition: all 0.3s ease-in-out;
  border-radius: 3px;
  font-style: italic;
  &:hover {
    background: #7211b6;
    color: white;
  }
`;

const UrgentText = styled.p`
  font-weight: 600;
  font-style: italic;
`;

const mapOptions = {
  zoom: 10,
  center: {
    lat: 40.089195,
    lng: -76.1604385
  }
};

const CampaignHistorySingle = props => {
  const { businessPage, alert } = props;
  const [isLoading, setLoading] = React.useState(true);
  const [campaign, setCampaign] = React.useState({});
  const [isActive, setIsActive] = React.useState(false);
  const [campaignInsights, setCampaignInsights] = React.useState({});
  const [dupCampaign, setDupCampaign] = React.useState(false);
  const [spent, setSpent] = React.useState(0);
  const [spentPercent, setSpentPercent] = React.useState(0);
  const [leadCount, setLeadCount] = React.useState(0);
  const [modalEditOpen, setModalEditOpen] = React.useState(false);
  const [modalCancelOpen, setModalCancelOpen] = React.useState(false);
  const [floaters, setFloaters] = React.useState({
    impressions: false,
    reach: false,
    linkClicks: false,
    costPerClick: false,
    cpm: false,
    frequency: false,
    numberOfLeads: false,
    costPerLead: false
  });

  const getCampaign = React.useCallback(
    async campaignId => {
      const { _id: existingId = "" } = campaign;
      const campaignIdToGet = campaignId ? campaignId : existingId;
      if (campaignIdToGet) {
        const campaignResponse = await campaignGetById(campaignIdToGet);
        const { credits: campaignCredits } = campaignResponse;
        const calcSpent = campaignCredits.spent;
        const calcSpentPercent = (calcSpent / campaignCredits.original) * 100;
        if (campaignResponse.fbCampaignId) {
          const getInsights = await campaignInsightsGetById(
            campaignResponse.fbCampaignId
          );
          setCampaignInsights(getInsights);
        }

        setCampaign(campaignResponse);
        setSpent(calcSpent);
        setSpentPercent(calcSpentPercent);
        setLoading(false);
      } else {
        props.history.push(`/campaign-history`);
      }
    },
    [campaign, props.history]
  );

  React.useEffect(() => {
    const {
      computedMatch: {
        params: { campaignId }
      }
    } = props;
    if (campaignId && campaignId !== campaign._id) {
      getCampaign(campaignId);
    }
  }, [campaign._id, getCampaign, props]);

  React.useEffect(() => {
    (async () => {
      if (campaign.fbCampaignId) {
        const getContacts = await contactsGet();
        const contacts = getContacts.filter(
          contact => contact.campaignId === campaign.fbCampaignId
        );
        const contactsCount = contacts.length;
        setLeadCount(contactsCount);
      }
      if (campaign.status === "ACTIVE") {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    })();
  }, [campaign]);

  const handleFloaterClick = floaterTarget => {
    setFloaters({ ...floaters, [floaterTarget]: true });
  };

  const removeFloaterClick = () => {
    setFloaters({
      impressions: false,
      reach: false,
      linkClicks: false,
      costPerClick: false,
      cpm: false,
      frequency: false,
      numberOfLeads: false,
      costPerLead: false
    });
  };

  const toggleDuplicateModal = async () => {
    setDupCampaign(!dupCampaign);
  };

  const goToCampaign = value => {
    if (value) {
      props.history.push("/area-campaign");
    }
  };

  const pauseCampaign = async () => {
    try {
      setLoading(true);
      await campaignPause(campaign.fbCampaignId);
      setModalCancelOpen(false);
      setLoading(false);
    } catch (err) {
      setModalCancelOpen(false);
      setLoading(false);
      alert({
        type: "error",
        msg: (
          <span>
            Something went wrong. Please try again. If you continue to get this
            message, contact Boostpoint at{" "}
            <a href='mailto:help@boostpoint.com'>help@boostpoint.com</a>
          </span>
        )
      });
      console.log("error pausing campaign", err.message);
    }
  };

  const sendMessage = () => {};

  const {
    _id,
    name,
    addresses,
    startDate,
    endDate,
    type,
    credits,
    inactiveReason = ``
  } = campaign;

  const {
    impressions,
    frequency,
    reach,
    cpm,
    spend,
    inline_link_clicks,
    cost_per_inline_link_click
  } = campaignInsights;

  return (
    <div>
      <TitleWrapper>
        <TitleLeft>
          <Back>
            <a
              onClick={() => {
                props.history.push("/campaign-history");
              }}
            >
              <span className='material-icons'>navigate_before</span>
            </a>
          </Back>
          <H1>{name && name}</H1>
        </TitleLeft>
      </TitleWrapper>
      {!isLoading && (
        <OverviewWrapper>
          <Overview>
            <Header>
              <HeaderDetails>
                <CampaignType>
                  {`Status: 
              ${isActive ? `Active` : `Not Active (${inactiveReason})`}`}
                </CampaignType>
                {/* <CampaignNotifications
              notifications={props.notifications}
              campaignID={campaignID}
            /> */}
              </HeaderDetails>
            </Header>
            <MapWrapper>
              {addresses.length > 0 && (
                <GoogleRadiusMap
                  addresses={addresses}
                  radius={addresses[0].radius}
                  options={mapOptions}
                  moveMarker={() => {}}
                  updateAddress={() => {}}
                  mapDraggable={true}
                  setMapDraggable={() => {}}
                />
              )}
            </MapWrapper>
            <DetailsWrapper>
              <DetailsRow center>
                <div>
                  <DetailsHeader>Locations</DetailsHeader>
                </div>
                {addresses.map((address, index) => (
                  <AddressRow key={index}>
                    <AddressWrapper key={`address${index}`}>
                      <AddressMarker>{index + 1}</AddressMarker>
                      <p name='address'>{address.formattedAddress}</p>
                    </AddressWrapper>
                    <AddressWrapper key={`radius${index}`}>
                      <p>{address.radius} Miles</p>
                    </AddressWrapper>
                  </AddressRow>
                ))}
              </DetailsRow>
              <DetailsRow>
                <Details>
                  <DetailsHeader>Start/End Date</DetailsHeader>
                  <p>
                    {dateFormat(startDate)} - {dateFormat(endDate)}
                  </p>
                </Details>
                <Details>
                  <DetailsHeader>Budget</DetailsHeader>
                  <SpendPercent percent={spentPercent} />
                  <SpentWrapper>
                    <span>{formatMoney(Math.ceil(spent))} spent</span>
                    <span>{formatMoney(credits.original)}</span>
                  </SpentWrapper>
                </Details>
              </DetailsRow>
              <DetailsRow>
                <Details>
                  <HeaderTooltipWrapper>
                    <DetailsHeader>Impressions</DetailsHeader>
                    <Floater
                      open={floaters.impressions}
                      content='The number of times your ads were on screen.'
                      styles={floaterStyles}
                      placement='right'
                    >
                      <Icon
                        onClick={() => handleFloaterClick("impressions")}
                        name='floater-toggle'
                        icon='info'
                      />
                      {floaters.impressions && (
                        <FloaterOverlay
                          onClick={() => removeFloaterClick()}
                          className='overlay'
                        />
                      )}
                    </Floater>
                  </HeaderTooltipWrapper>
                  {impressions ? <p>{impressions}</p> : <p>Not enough data</p>}
                </Details>
                <Details>
                  <HeaderTooltipWrapper>
                    <DetailsHeader>Reach</DetailsHeader>
                    <Floater
                      open={floaters.reach}
                      content='The estimated number of people who saw your ads at least once. Reach is different from impressions, which may include multiple views of your ads by the same people.'
                      styles={floaterStyles}
                      placement='right'
                    >
                      <Icon
                        icon='info'
                        onClick={() => handleFloaterClick("reach")}
                        name='floater-toggle'
                      />
                      {floaters.reach && (
                        <FloaterOverlay
                          onClick={() => removeFloaterClick()}
                          className='overlay'
                        />
                      )}
                    </Floater>
                  </HeaderTooltipWrapper>
                  {reach ? <p>{reach}</p> : <p>Not enough data</p>}
                </Details>
              </DetailsRow>
              <DetailsRow>
                <Details>
                  <HeaderTooltipWrapper>
                    <DetailsHeader>Link Clicks</DetailsHeader>
                    <Floater
                      open={floaters.linkClicks}
                      content='The number of clicks on links within the ad.'
                      styles={floaterStyles}
                      placement='right'
                    >
                      <Icon
                        onClick={() => handleFloaterClick("linkClicks")}
                        name='floater-toggle'
                        icon='info'
                      />
                      {floaters.linkClicks && (
                        <FloaterOverlay
                          onClick={() => removeFloaterClick()}
                          className='overlay'
                        />
                      )}
                    </Floater>
                  </HeaderTooltipWrapper>
                  {inline_link_clicks ? (
                    <p>{inline_link_clicks}</p>
                  ) : (
                    <p>Not enough data</p>
                  )}
                </Details>
                <Details>
                  <HeaderTooltipWrapper>
                    <DetailsHeader>Cost per Click</DetailsHeader>
                    <Floater
                      open={floaters.costPerClick}
                      content='The average cost per click from your ads.'
                      styles={floaterStyles}
                      placement='right'
                    >
                      <Icon
                        onClick={() => handleFloaterClick("costPerClick")}
                        name='floater-toggle'
                        icon='info'
                      />
                      {floaters.costPerClick && (
                        <FloaterOverlay
                          onClick={() => removeFloaterClick()}
                          className='overlay'
                        />
                      )}
                    </Floater>
                  </HeaderTooltipWrapper>
                  {cost_per_inline_link_click ? (
                    <p>{formatMoney(cost_per_inline_link_click)}</p>
                  ) : (
                    <p>Not enough data</p>
                  )}
                </Details>
              </DetailsRow>
              <DetailsRow>
                <Details>
                  <HeaderTooltipWrapper>
                    <DetailsHeader>CPM</DetailsHeader>
                    <Floater
                      open={floaters.cpm}
                      content='The average cost for 1,000 impressions.'
                      styles={floaterStyles}
                      placement='right'
                    >
                      <Icon
                        onClick={() => handleFloaterClick("cpm")}
                        name='floater-toggle'
                        icon='info'
                      />
                      {floaters.cpm && (
                        <FloaterOverlay
                          onClick={() => removeFloaterClick()}
                          className='overlay'
                        />
                      )}
                    </Floater>
                  </HeaderTooltipWrapper>
                  <p>{formatMoney(cpm)}</p>
                </Details>
                <Details>
                  <HeaderTooltipWrapper>
                    <DetailsHeader>Frequency</DetailsHeader>
                    <Floater
                      open={floaters.frequency}
                      content='The average number of times each person saw your ad.'
                      styles={floaterStyles}
                      placement='right'
                    >
                      <Icon
                        onClick={() => handleFloaterClick("frequency")}
                        name='floater-toggle'
                        icon='info'
                      />
                      {floaters.frequency && (
                        <FloaterOverlay
                          onClick={() => removeFloaterClick()}
                          className='overlay'
                        />
                      )}
                    </Floater>
                  </HeaderTooltipWrapper>
                  <p>{formatTenth(frequency)}</p>
                </Details>
              </DetailsRow>
              {type === "LeadAd" && (
                <DetailsRow>
                  <Details>
                    <HeaderTooltipWrapper>
                      <DetailsHeader>Number of Leads</DetailsHeader>
                      <Floater
                        open={floaters.numberOfLeads}
                        content='The number of leads from people submitting your form within the ad.'
                        styles={floaterStyles}
                        placement='right'
                      >
                        <Icon
                          onClick={() => handleFloaterClick("numberOfLeads")}
                          name='floater-toggle'
                          icon='info'
                        />
                        {floaters.numberOfLeads && (
                          <FloaterOverlay
                            onClick={() => removeFloaterClick()}
                            className='overlay'
                          />
                        )}
                      </Floater>
                    </HeaderTooltipWrapper>
                    <p>{leadCount}</p>
                  </Details>
                  <Details>
                    <HeaderTooltipWrapper>
                      <DetailsHeader>Cost per Lead</DetailsHeader>
                      <Floater
                        open={floaters.costPerLead}
                        content='The average cost per lead from your ads.'
                        styles={floaterStyles}
                        placement='right'
                      >
                        <Icon
                          onClick={() => handleFloaterClick("costPerLead")}
                          name='floater-toggle'
                          icon='info'
                        />
                        {floaters.costPerLead && (
                          <FloaterOverlay
                            onClick={() => removeFloaterClick()}
                            className='overlay'
                          />
                        )}
                      </Floater>
                    </HeaderTooltipWrapper>
                    <p>{leadCount ? formatMoney(spend / leadCount) : 0}</p>
                  </Details>
                </DetailsRow>
              )}
              {leadCount > 0 && (
                <ContactLink href={`/contacts?campaignId=${_id}`}>
                  View your leads for this campaign here
                </ContactLink>
              )}
              <DetailsRow center>
                <div>
                  <DetailsHeader>Ad Preview</DetailsHeader>
                  <AdPreview
                    pendingCampaign={campaign}
                    userProfile={props.userProfile}
                    businessPage={{
                      name: businessPage.name,
                      image: businessPage.image
                    }}
                    callToAction={campaign.content.callToAction}
                  />
                  <div>
                    <Button small onClick={() => setModalEditOpen(true)}>
                      Edit Campaign
                    </Button>
                  </div>
                  <AdsLink
                    target='_blank'
                    href={`https://www.facebook.com/ads/library/?active_status=all&ad_type=all&country=US&view_all_page_id=${businessPage.pageId}&search_type=page&media_type=all`}
                  >
                    View all running ads within Facebook Ad Library
                  </AdsLink>
                  <Button
                    // disabled={this.state.duplicateProcessing}
                    onClick={toggleDuplicateModal}
                  >
                    Duplicate Campaign
                  </Button>
                  <CancelButton
                    disabled={!isActive}
                    onClick={() => setModalCancelOpen(true)}
                  >
                    Cancel Campaign
                  </CancelButton>
                </div>
              </DetailsRow>
            </DetailsWrapper>
          </Overview>
        </OverviewWrapper>
      )}
      <CampaignDuplicate
        duplicating={dupCampaign}
        campaign={campaign}
        industry={props.userCompany.industry}
        onClose={goToCampaign}
      />
      <BpModal
        open={modalCancelOpen}
        title={"Cancel Campaign"}
        primaryAction={{
          btnLabel: "Continue",
          action: pauseCampaign,
          disabled: isLoading
        }}
        secondaryAction={{
          btnLabel: "Back",
          action: () => setModalCancelOpen(false),
          disabled: isLoading
        }}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        contentLabel='Cancel Campaign'
        body={
          <div>
            <p>
              Do you have a question about this campaign or need to edit it?{" "}
              <ModalLink
                onClick={() => {
                  setModalCancelOpen(false);
                  setModalEditOpen(true);
                }}
              >
                Send us a message
              </ModalLink>
              .
            </p>
            <p>
              Canceling will <strong>end all ads</strong> in this campaign.{" "}
              <strong>Are you sure you want to cancel?</strong>
            </p>
          </div>
        }
        width='auto'
      />
      {/* <BpModal
        // open={this.state.modalMessageSent}
        title={'Edit Campaign'}
        primaryAction={{
          btnLabel: 'Close',
          //   action: () => this.setState({ modalMessageSent: false }),
          disabled: isLoading
        }}`
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        contentLabel='Edit Campaign'
        body={<p>Message sent. We will be in touch about your campaign.</p>}
      /> */}
      <CampaignEdit
        modalEditOpen={modalEditOpen}
        setModalEditOpen={setModalEditOpen}
        userProfile={props.userProfile}
        campaign={campaign}
      />
    </div>
  );
};

export default CampaignHistorySingle;
