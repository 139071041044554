import React from 'react';
import styled from 'styled-components';
import MessageSearchResults from './MessageSearchResults';
import Search from '../../elements/Search';

const MessageWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const SearchValueList = styled.ul`
  display: flex;
  margin: 8px auto;
  max-width: 22rem;
`;

const SearchValueDisplay = styled.li`
  display: flex;
  align-items: center;
  background: rgba(114,17,182,.1);
  color: ${props => props.theme.HC2};
  padding-left: 6px;
`;

const Icon = styled.i`
  cursor: pointer;
  padding: 2px 4px;
  font-size: 18px;
  &:hover {
    color: gray;
  }
`;

const SearchBgCover = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 29;
`;

const MessageSearch = props => {
  const { contacts, alert } = props;

  const [searchInput, setSearchInput] = React.useState('');
  const [searchValue, setSearchValue] = React.useState([]);
  const [searchResults, setSearchResults] = React.useState([]);

  const search = () => {
    const searchRaw = searchInput.trim().toLowerCase();
    setSearchValue([searchInput.trim()]);
    setSearchInput('');
    if (!searchRaw) {
      alert({ type: 'error', msg: 'Please enter a value to search.' });
      return;
    }
    try {
      const firstNames = contacts.filter(c =>
        c.firstName ? c.firstName.toLowerCase().includes(searchRaw) : false
      );
      const lastNames = contacts.filter(c =>
        c.lastName ? c.lastName.toLowerCase().includes(searchRaw) : false
      );
      setSearchResults([...new Set([...firstNames, ...lastNames])]);
    } catch (err) {
      console.log('error getting search results', err);
    }
  };

  const onKeyDown = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      search();
    }
  };

  const clearSearch = () => {
    setSearchResults([]);
    setSearchValue('');
  };

  const loadNewContact = (contactId, contactPhone) => {
    clearSearch();
    props.loadNewContact(contactId, contactPhone)
  }

  return (
    <MessageWrapper>
      <Search
        searchValue={searchInput}
        onEnter={onKeyDown}
        onSearch={search}
        onSearchChange={setSearchInput}
        clearSearch={clearSearch}
        // showSearch={showSearch}
        showMobileView={false}
        placeholder="Search by Name"
        showActive={searchResults.length > 0}
        showClear={searchResults.length > 0}
        compact={true}
      />
      <SearchValueList>
        {searchValue.length > 0 && searchValue.map(value => (
          <SearchValueDisplay key={value}>
            <span>{searchValue}</span>
            <Icon
              className="material-icons arrow-up"
              onClick={clearSearch}
              position="top"
            >
              close
            </Icon>
          </SearchValueDisplay>
        ))}
      </SearchValueList>
      <MessageSearchResults
        searchResults={searchResults}
        loadNewContact={loadNewContact}
        clearSearch={clearSearch}
      />
      {searchResults.length > 0 && (
      <SearchBgCover onClick={clearSearch}></SearchBgCover>
      )}
    </MessageWrapper>
  );
};

export default MessageSearch;
