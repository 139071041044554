import styled from "styled-components";

export const firstBreakpoint = "800";
export const secondBreakpoint = "1040";

export const ContentWrapper = styled.div`
  margin: 0;
  position: relative;
  /* padding-bottom: 20px; */
  padding: 0;
  height: 100%;
  display: grid;
  grid-template-rows: 142px 1fr 100px;
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: block;
  }
`;
