/* global $FPROM */

import React from "react";
import { Elements, StripeProvider } from "react-stripe-elements";
import CreateAccount from "./CreateAccount";

class CreateAccountWrapper extends React.Component {
  render() {
    return (
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
        <Elements>
          <CreateAccount
            {...this.props}
            alert={this.props.alert}
            toggleRegistering={this.props.toggleRegistering}
            updatePaymentSource={this.props.updatePaymentSource}
            updateNewUser={this.props.updateNewUser}
          />
        </Elements>
      </StripeProvider>
    );
  }
}

export default CreateAccountWrapper;
