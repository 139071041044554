import React, { useContext } from "react";
import { useTable, usePagination } from "react-table";
import moment from "moment-timezone";
import styled from "styled-components";
import { firstBreakpoint } from "../../styled/PhoneNumberProvision";
import { ContactViewWrapper, IntegrationCard } from "../../styled/Integrations";
import { ThemeContext } from "styled-components";
import WorkflowsListHeader from "./WorkflowsListHeader";
import { InputBoxWrapper, InputContainer, Dropdown } from "../../styled/Input";
import DotsFlashingLoader from "../../elements/DotsFlashingLoader";

const TableCard = styled(IntegrationCard)`
  padding: 0;
`;

const TableStyle = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const Head = styled.thead`
  border-bottom: 1px solid #d9d9da;
  display: none;
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: table-header-group;
  }
`;

const TH = styled.th`
  padding: 20px 15px;
  text-transform: uppercase;
  display: none;
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: table-cell;
  }
  &.actions {
    text-align: right;
    padding-right: 25px;
  }
  &.active {
    text-align: center;
  }
`;

const THCheck = styled.th`
  padding: 0 13px;
  width: 48px;
  display: none;
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: table-cell;
  }
`;

const TDCheck = styled.td`
  padding: 0 13px;
  margin-top: 6px;
  width: 48px;
  display: none;
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: block;
  }
`;

const CheckboxWrapper = styled.div`
  /* padding: 10px 0 10px 24px; */
  input {
    opacity: 0;
  }
  label {
    position: relative;
    font-style: italic;
    &::before {
      content: "";
      display: inline-block;
      height: 20px;
      width: 20px;
      border-radius: 3px;
      border: 1px solid ${props => props.theme.TextC};
      position: absolute;
      top: -3px;
      left: -20px;
      background: ${props => props.theme.BGC};
    }
    &::after {
      content: "";
      display: inline-block;
      height: 7px;
      width: 12px;
      border-left: 2px solid;
      border-bottom: 2px solid;
      transform: rotate(-45deg);
      position: absolute;
      left: -16px;
      top: 2px;
      color: ${props => props.theme.HC1};
    }
  }
  input + label::after {
    content: none;
  }
  input:checked + label::before {
    background: white;
    border-color: ${props => props.theme.TextC};
  }
  input:checked + label::after {
    content: "";
  }
`;

const TD = styled.td`
  cursor: pointer;
  padding: 0 15px;
  display: ${props => (props.mobileHide ? "none" : "block")};
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: table-cell;
  }
  &.name {
    font-weight: 510;
  }
  &.name,
  &.created-by,
  &.date-updated {
    text-align: left;
  }
`;

const ActionWrapper = styled.div`
  display: flex;
  min-width: 110px;
  justify-content: flex-end;
  @media screen and (min-width: ${firstBreakpoint}px) {
    justify-content: baseline;
  }
  span {
    color: ${props => props.theme.TextC};
    margin: 3px 6px;
  }
`;

const ActionButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  a,
  span {
    color: ${props => props.theme.TextC};
    cursor: pointer;
    &:hover {
      color: ${props => props.theme.HC1};
    }
  }
`;

const BodyRow = styled.tr`
  color: ${props => props.color};
  border-bottom: 1px solid #d9d9da;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: table-row;
  }
  &:last-of-type {
    border-bottom: none;
  }
  td {
    padding-top: 16px;
    padding-bottom: 16px;
  }
`;

const Pager = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 1220px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    margin-bottom: 20px;
  }
`;

const PagerItem = styled.li`
  display: inline-block;
  padding: 0 4px;
  cursor: pointer;
  border-radius: 3px;
  pointer-events: ${props => (props.disabled ? "none" : "auto")};
  &:hover,
  &.selectedPage {
    background-color: ${props => props.theme.HC2};
    color: ${props => props.theme.ATextC};
  }
`;

const PaginationRow = styled.tr`
  @media only screen and (max-width: 1220px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    display: block;
  }
`;

const PaginationCell = styled.td`
  @media only screen and (max-width: 1220px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    display: block;
  }
`;

const Pagination = styled.div`
  display: grid;
  grid-template-columns: 1fr 200px;
  margin: 20px 0 20px 200px;
  @media only screen and (max-width: 1220px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    grid-template-columns: 1fr;
    margin: 20px auto;
  }
`;

const PageSelectorWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PageInputContainer = styled(InputContainer)`
  margin: 0 auto;
`;

const PageCountLoop = (pageCount, pageIndex, gotoPage) => {
  const pageItems = [];
  const windowWidth = window.innerWidth;
  const pageItemMax = windowWidth < 600 ? 5 : 11;
  let startPage = 0;
  let endPage = pageCount - 1;
  if (pageCount <= pageItemMax) {
    startPage = 0;
    endPage = pageCount;
  } else {
    const maxPagesBeforeCurrentPage = Math.floor(pageItemMax / 2);
    const maxPagesAfterCurrentPage = Math.ceil(pageItemMax / 2);
    if (pageIndex < maxPagesBeforeCurrentPage) {
      // current page near the start
      startPage = 0;
      endPage = pageItemMax;
      if (pageCount <= pageItemMax) {
        endPage = pageCount;
      }
    } else if (pageIndex + maxPagesAfterCurrentPage > pageCount) {
      // current page near the end
      startPage = pageCount - pageItemMax + 1;
      endPage = pageCount;
    } else {
      // current page somewhere in the middle
      startPage = pageIndex - maxPagesBeforeCurrentPage;
      endPage = pageIndex + maxPagesAfterCurrentPage;
    }
  }
  for (let i = startPage; i < endPage; i++) {
    pageItems.push(
      <PagerItem
        key={i}
        onClick={() => gotoPage(i)}
        disabled={i === pageIndex}
        className={i === pageIndex ? "selectedPage" : "pageNotSelected"}
      >
        {i + 1}
      </PagerItem>
    );
  }
  return pageItems;
};

const mobileHiddenColumns = ["created-by", "date-updated", "active"];

const WorkflowTable = props => {
  const { columns, data, selectAll, selectedWorkflows = [] } = props;
  const themeContext = useContext(ThemeContext);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    visibleColumns,
    state: { pageIndex, pageSize }
  } = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    usePagination
  );

  const clearSearch = () => {
    props.setFilteredWorkflows("reset");
  };

  return (
    <div>
      <WorkflowsListHeader
        search={props.search}
        clearSearch={clearSearch}
        workflowsCount={props.workflowsCount}
        filteredWorkflowsCount={props.filteredWorkflowsCount}
        selectedWorkflowCount={props.selectedWorkflowCount}
        createWorkflow={props.createWorkflow}
        deleteMultiple={props.deleteMultiple}
      />
      <ContactViewWrapper>
        <TableCard layoutPosition='full' displayAll>
          <TableStyle {...getTableProps()}>
            <Head>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  <THCheck>
                    <CheckboxWrapper
                      header
                      onClick={props.toggleWorkflowSelectAll}
                    >
                      <input
                        type='checkbox'
                        checked={selectAll}
                        onChange={props.toggleWorkflowSelectAll}
                      />
                      <label />
                    </CheckboxWrapper>
                  </THCheck>
                  {headerGroup.headers.map(column => (
                    <TH
                      {...column.getHeaderProps()}
                      className={column.id ? column.id : ""}
                    >
                      {column.render("Header")}
                    </TH>
                  ))}
                </tr>
              ))}
            </Head>
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row);
                return (
                  <BodyRow
                    {...row.getRowProps()}
                    color={
                      row.values.active === "draft"
                        ? themeContext.TextC
                        : themeContext.HC1
                    }
                  >
                    <TDCheck>
                      <CheckboxWrapper
                        onClick={() =>
                          props.toggleWorkflowSelect(row.original.id)
                        }
                      >
                        <input
                          type='checkbox'
                          checked={
                            selectedWorkflows.includes(row.original.id) ||
                            selectAll
                          }
                          onChange={() =>
                            props.toggleWorkflowSelect(row.original.id)
                          }
                        />
                        <label />
                      </CheckboxWrapper>
                    </TDCheck>
                    {row.cells.map(cell => (
                      <TD
                        {...cell.getCellProps()}
                        onClick={() => {
                          if (!(cell.column.id === "actions")) {
                            props.history.push(`/workflows/${row.original.id}`);
                          }
                        }}
                        className={cell.column.id ? cell.column.id : ""}
                        mobileHide={
                          mobileHiddenColumns.includes(cell.column.id)
                            ? true
                            : false
                        }
                      >
                        {/* {console.log(cell)} */}
                        {cell.render("Cell")}
                      </TD>
                    ))}
                  </BodyRow>
                );
              })}
              <PaginationRow>
                <PaginationCell colSpan={visibleColumns.length + 1}>
                  <Pagination>
                    {pageCount && pageCount > 1 ? (
                      <Pager>
                        <PagerItem
                          onClick={() => previousPage()}
                          disabled={!canPreviousPage}
                        >
                          {"< Prev"}
                        </PagerItem>
                        {PageCountLoop(pageCount, pageIndex, gotoPage)}
                        <PagerItem
                          onClick={() => nextPage()}
                          disabled={!canNextPage}
                        >
                          {"Next >"}
                        </PagerItem>
                      </Pager>
                    ) : null}
                    <PageSelectorWrapper>
                      <PageInputContainer>
                        <InputBoxWrapper>
                          <Dropdown
                            value={pageSize}
                            onChange={e => {
                              setPageSize(Number(e.target.value));
                            }}
                            onClick={e => e.stopPropagation()}
                          >
                            {[10, 20, 30, 40, 50].map(ps => (
                              <option key={ps} value={ps}>
                                {ps} per page
                              </option>
                            ))}
                          </Dropdown>
                        </InputBoxWrapper>
                      </PageInputContainer>
                    </PageSelectorWrapper>
                  </Pagination>
                </PaginationCell>
              </PaginationRow>
            </tbody>
          </TableStyle>
        </TableCard>
      </ContactViewWrapper>
    </div>
  );
};

const WorkflowList = props => {
  const {
    workflowList,
    workflowUsers,
    toggleWorkflowSelect,
    selectedWorkflows,
    toggleWorkflowSelectAll,
    selectAll,
    timezone
  } = props;

  const headers = [
    {
      Header: "Name",
      accessor: "name",
      id: "name"
    },
    {
      Header: "Created By",
      accessor: "createdBy",
      id: "created-by"
    },
    { Header: "Date Updated", accessor: "dateUpdated", id: "date-updated" },
    { Header: "Active", accessor: "active", id: "active" },
    {
      Header: "Actions",
      accessor: "actions",
      id: "actions",
      // eslint-disable-next-line react/display-name
      Cell: cellInfo => (
        <ActionWrapper>
          <ActionButton>
            <a
              href={`/workflows/${cellInfo.row.original.id}`}
              className='material-icons'
              title='Edit workflow'
              onClick={e => {
                e.preventDefault();
                props.history.push(`/workflows/${cellInfo.row.original.id}`);
              }}
            >
              edit
            </a>
          </ActionButton>
          <ActionButton>
            <span
              className='material-icons'
              onClick={() => props.duplicateWorkflow(cellInfo.row.original.id)}
              title='Duplicate workflow'
            >
              content_copy
            </span>
          </ActionButton>
          <ActionButton>
            <span
              className='material-icons'
              onClick={() => props.openDeleteConfirm(cellInfo.row.original.id)}
              title='Delete workflow'
            >
              delete
            </span>
          </ActionButton>
        </ActionWrapper>
      )
    }
  ];
  const tableData = workflowList.map(flow => {
    let createdFormatted = "";
    if (flow.modifiedDate) {
      const createdMoment = moment(flow.modifiedDate).tz(timezone);
      createdFormatted = createdMoment.format("MMM DD, YYYY h:mm A");
    }
    const createdBy = flow.user ? (
      `${flow.user.firstName} ${flow.user.lastName}`
    ) : flow.createdBy ? (
      <DotsFlashingLoader />
    ) : (
      ""
    );
    return {
      id: flow._id,
      name: flow.name,
      createdBy,
      dateUpdated: createdFormatted,
      active: flow.status,
      actions: ""
    };
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = React.useMemo(() => tableData, [workflowList]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = React.useMemo(() => headers, [workflowList]);

  return (
    <WorkflowTable
      columns={columns}
      data={data}
      toggleWorkflowSelect={toggleWorkflowSelect}
      toggleWorkflowSelectAll={toggleWorkflowSelectAll}
      selectedWorkflows={selectedWorkflows}
      selectAll={selectAll}
      history={props.history}
      search={props.search}
      workflowsCount={props.workflowsCount}
      filteredWorkflowsCount={props.filteredWorkflowsCount}
      createWorkflow={props.createWorkflow}
      {...props}
    />
  );
};
export default WorkflowList;
