import * as Sentry from "@sentry/browser";
import axios from "axios";
import { axiosResponse, errorHandler } from "./Helpers";

const BP_API = process.env.REACT_APP_BP_API;

export const assetImagesGetByCompany = async companyId => {
  try {
    const assetResponse = axiosResponse(
      await axios.get(`${BP_API}/v1/media-asset/images/${companyId}`)
    );
    return assetResponse;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const assetVideosGetByCompany = async companyId => {
  try {
    const assetResponse = axiosResponse(
      await axios.get(`${BP_API}/v1/media-asset/videos/${companyId}`)
    );
    return assetResponse;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const assetAdd = async asset => {
  try {
    const assetResponse = axiosResponse(
      await axios.post(`${BP_API}/v1/media-asset`, asset)
    );
    return assetResponse;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const assetDelete = async asset => {
  try {
    const assetResponse = axiosResponse(
      await axios.put(`${BP_API}/v1/media-asset`, {
        ...asset,
        status: "DELETED"
      })
    );
    return assetResponse;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const assetUpdate = async asset => {
  try {
    const assetResponse = axiosResponse(
      await axios.put(`${BP_API}/v1/media-asset`, asset)
    );
    return assetResponse;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const cloudinaryFormat = ({
  height = 250,
  width,
  mode = "scale",
  quality = "good",
  type = "image",
  filename,
  format
}) => {
  let name = filename;
  if (mode === "crop" && !height && !width) {
    Sentry.captureMessage(
      "Cloudinary crop " +
        (!height && "height ") +
        (!width && "width ") +
        "is missing @ MediaAPI cloudinaryFormat()"
    );
    throw new Error("crop mode requires both a height and width");
  }
  if (!filename) {
    console.log("filename is required");
    Sentry.captureMessage(
      "Cloudinary filename is missing @ MediaAPI cloudinaryFormat()"
    );
    return "#";
  }
  if (!format) {
    console.log("format is required");
    Sentry.captureMessage(
      "Cloudinary message is missing @ MediaAPI cloudinaryFormat()"
    );
    return "#";
  }
  const dotIndex = filename.indexOf(".");
  if (dotIndex > 0) {
    name = filename.substring(0, dotIndex);
  }
  if (["webm", "mp4", "ogg"].includes(filename.substring(dotIndex + 1))) {
    return `https://res.cloudinary.com/equipter/video/upload/${name}.${format}`;
  }
  const formatArray = [
    "f_auto",
    `h_${height}`,
    `c_${mode}`,
    `q_auto:${quality}`,
    "dpr_auto"
  ];
  if (width > 0) {
    formatArray.push(`w_${width}`);
  }
  if ((mode === "crop" || mode === "fill") && type === "image") {
    formatArray.push(`g_auto:subject:faces`);
  }

  return `https://res.cloudinary.com/equipter/${type}/upload/${formatArray.join(
    ","
  )}/${name}.${format}`;
};

// const cloudinaryUrlVideo =
//   'https://res.cloudinary.com/equipter/video/upload/c_scale,f_auto,h_250';

// const cloudinaryUrlImages =
//   'https://res.cloudinary.com/equipter/image/upload/c_scale,f_auto,h_250';

// const url = `https://res.cloudinary.com/equipter/${media[0].mediaType.toLowerCase()}/upload/c_fill,f_auto,h_40,q_auto:eco,w_40/${
//   media[0].title
// }.png`

// const cloudinaryUrlImage =
//   'https://res.cloudinary.com/equipter/image/upload/h_80,c_scale,dpr_auto';

// const cloudinaryUrlVideo =
//   'https://res.cloudinary.com/equipter/video/upload/h_80,c_scale,dpr_auto';

//   `https://res.cloudinary.com/equipter/image/upload/c_scale,f_auto,h_96,q_auto/c_crop,g_center,h_96,w_144/${data.public_id}.${data.format}`

//   `https://res.cloudinary.com/equipter/image/upload/h_48,w_48,c_fill,g_center,f_auto,q_auto/${image.downloadURL}`

//   const cloudinaryUrl = `${process.env.REACT_APP_CLOUDINARY_IMAGE_BASEURL}/upload/c_scale,f_auto,h_96,q_auto/c_crop,g_center,h_96,w_144`;

// const cloudinaryUrlSelected = `${process.env.REACT_APP_CLOUDINARY_IMAGE_BASEURL}/upload/h_48,w_48,c_fill,g_center,f_auto,q_auto`;

// const cloudinaryUrlImage =
//   'https://res.cloudinary.com/equipter/image/upload/h_300,c_scale,dpr_auto';

// const cloudinaryUrlVideo =
//   'https://res.cloudinary.com/equipter/image/upload/c_fill,g_auto:faces,h_300,w_322/v1570461719';

//   `${process.env.REACT_APP_CLOUDINARY_VIDEO_BASEURL}/upload/c_scale,h_96,q_auto/${data.name}.jpg`
//   `${process.env.REACT_APP_CLOUDINARY_IMAGE_BASEURL}/upload/c_scale,f_auto,h_96,q_auto/c_crop,g_center,h_96,w_144/${deleteAsset.name}.${deleteAsset.format}`

//   const cloudinaryUrl =
//   'https://res.cloudinary.com/equipter/image/upload/c_scale,f_auto,h_96,q_auto/c_crop,g_center,h_96,w_144';

// const cloudinaryUrlSelected =
//   'https://res.cloudinary.com/equipter/image/upload/h_48,w_48,c_fill,g_center,f_auto,q_auto';

//   'https://res.cloudinary.com/equipter/video/upload/c_scale,h_96,q_auto/' +
//                           data.name +
//                           '.jpg'

//                           `${process.env.REACT_APP_CLOUDINARY_VIDEO_BASEURL}/upload/c_scale,h_96,q_auto/${data.name}.jpg`
