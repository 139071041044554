import moment from "moment-timezone";
import React from "react";
import { useTable } from "react-table";
import styled from "styled-components";
import { formatMoney } from "../../../utils/Helpers";
import { isAttractActive, isConnectActive } from "../../../utils/StripeAPI";
import { firstBreakpoint } from "../../styled/PhoneNumberProvision";

const TableStyle = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const Head = styled.thead`
  border-bottom: 1px solid #d9d9da;
  display: none;
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: table-header-group;
  }
`;

const TH = styled.th`
  padding: 20px 15px;
  text-transform: uppercase;
  display: none;
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: table-cell;
  }
  &.actions {
    text-align: right;
    padding-right: 25px;
  }
  &.active {
    text-align: center;
  }
`;

const THCheck = styled.th`
  padding: 0 13px;
  width: 48px;
  display: none;
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: table-cell;
  }
`;

const TDCheck = styled.td`
  padding: 0 13px;
  margin-top: 6px;
  width: 48px;
  display: none;
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: block;
  }
`;

const CheckboxWrapper = styled.div`
  /* padding: 10px 0 10px 24px; */
  input {
    opacity: 0;
  }
  label {
    position: relative;
    font-style: italic;
    &::before {
      content: "";
      display: inline-block;
      height: 20px;
      width: 20px;
      border-radius: 3px;
      border: 1px solid ${props => props.theme.TextC};
      position: absolute;
      top: -3px;
      left: -20px;
      background: ${props => props.theme.BGC};
    }
    &::after {
      content: "";
      display: inline-block;
      height: 7px;
      width: 12px;
      border-left: 2px solid;
      border-bottom: 2px solid;
      transform: rotate(-45deg);
      position: absolute;
      left: -16px;
      top: 2px;
      color: ${props => props.theme.HC1};
    }
  }
  input + label::after {
    content: none;
  }
  input:checked + label::before {
    background: white;
    border-color: ${props => props.theme.TextC};
  }
  input:checked + label::after {
    content: "";
  }
`;

const TD = styled.td`
  cursor: pointer;
  padding: 0 15px;
  display: ${props => (props.mobileHide ? "none" : "block")};
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: table-cell;
  }
  &.name {
    font-weight: 510;
  }
  &.name,
  &.created-by,
  &.date-updated {
    text-align: left;
  }
`;

const ActionWrapper = styled.div`
  display: flex;
  min-width: 110px;
  justify-content: flex-end;
  @media screen and (min-width: ${firstBreakpoint}px) {
    justify-content: baseline;
  }
  span {
    color: ${props => props.theme.TextC};
    margin: 3px 6px;
  }
`;

const ActionButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  a,
  span {
    color: ${props => props.theme.TextC};
    cursor: pointer;
    &:hover {
      color: ${props => props.theme.HC1};
    }
  }
`;

const BodyRow = styled.tr`
  color: ${props => props.color};
  border-bottom: 1px solid #d9d9da;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: table-row;
  }
  &:last-of-type {
    border-bottom: none;
  }
  td {
    padding-top: 16px;
    padding-bottom: 16px;
  }
`;

const SubscriptionTable = props => {
  const { columns, data } = props;
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <TableStyle {...getTableProps()}>
      <Head>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <TH
                {...column.getHeaderProps()}
                className={column.id ? column.id : ""}
              >
                {column.render("Header")}
              </TH>
            ))}
          </tr>
        ))}
      </Head>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <BodyRow {...row.getRowProps()}>
              {row.cells.map(cell => (
                <TD
                  {...cell.getCellProps()}
                  className={cell.column.id ? cell.column.id : ""}
                >
                  {/* {console.log(cell)} */}
                  {cell.render("Cell")}
                </TD>
              ))}
            </BodyRow>
          );
        })}
      </tbody>
    </TableStyle>
  );
};

const SubscriptionList = props => {
  const { subscriptionList, timezone } = props;

  const headers = [
    {
      Header: "Subscription",
      accessor: "subscription",
      id: "subscription"
    },
    {
      Header: "Next Bill Date",
      accessor: "nextBillDate",
      id: "next-bill-date"
    },
    {
      Header: "Next Amount Due",
      accessor: "nextAmountDue",
      id: "next-amount-due"
    },
    {
      Header: "Frequency",
      accessor: "frequency",
      id: "frequency"
    }
  ];
  const tableData = subscriptionList.map(sub => {
    let nextBillDate = "";
    if (sub.currentPeriodEnd) {
      const billMoment = moment(sub.currentPeriodEnd).tz(timezone);
      nextBillDate = billMoment.format("MMM DD, YYYY");
    }
    let subscription = "";
    if (isConnectActive([sub])) {
      subscription = "Boostpoint Connect";
    }
    if (isAttractActive([sub])) {
      subscription = "Boostpoint Attract";
    }
    const nextAmountDue = formatMoney(sub.unitAmount, true, true);
    const frequency = `every ${
      sub.intervalCount > 1 ? sub.intervalCount : ""
    } ${sub.interval}${sub.intervalCount > 1 ? "s" : ""}`;

    return {
      id: sub._id,
      subscription,
      nextBillDate,
      nextAmountDue,
      frequency
    };
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = React.useMemo(() => tableData, [subscriptionList]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = React.useMemo(() => headers, [subscriptionList]);

  return (
    <SubscriptionTable columns={columns} data={data} history={props.history} />
  );
};
export default SubscriptionList;
