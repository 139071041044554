import React from "react";
import styled from "styled-components";
import { Button } from "../../styled/Buttons";

const Wrapper = styled.div`
  margin: 50px 60px 80px;
  a {
    color: ${props => props.theme.HC1};
    text-decoration: underline;
    font-style: italic;
  }
`;

const CelebrateIcon = styled.span`
  color: ${props => props.theme.HC1};
  font-size: 4em;
  display: block;
`;

const Header = styled.h1`
  font-size: 1.2em;
  text-transform: uppercase;
  margin: 1em 1em 2em;
`;

const GeneralSuccess = props => {
  const name = props.name;
  const close = e => {
    e.preventDefault();
    props.close();
  };
  return (
    <Wrapper>
      <CelebrateIcon className='material-icons'>celebration</CelebrateIcon>
      <Header>Congrats! You are good to go.</Header>
      <p>You can now start to use {name} with Boostpoint</p>
      <Button solid onClick={close}>
        Go to {name} connection
      </Button>
    </Wrapper>
  );
};

export default GeneralSuccess;
