/* global cloudinary */

import React from "react";
import Modal from "react-modal";
import styled from "styled-components";
import Floater from "react-floater";
import BpModal from "../elements/BpModal";
import { assetDelete, assetVideosGetByCompany } from "../../utils/MediaAPI";

// Import Styled Components
import { Button } from "../styled/Buttons";
import ModalWrapper from "../styled/ModalWrapper";
import ModalLargeWrapper from "../styled/ModalLargeWrapper";

import UploadVideoPane from "./UploadVideoPane";
import MyVideosPane from "./MyVideosPane";
import StockVideosPane from "./StockVideosPane";
import { getStockVideos } from "../../utils/CloudinaryAPI";
import VideoModalFooter from "./VideoModalFooter";
import VideoModalHeader from "./VideoModalHeader";

const customStyles = {
  overlay: {
    zIndex: "1002",
    backgroundColor: "rgba(250, 250, 250, .75)"
  },
  content: {
    textAlign: "center",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    maxWidth: "90%",
    padding: "1em 2em 2em",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 0px 9px 0px rgba(0, 0, 6, .1)"
  }
};

const PreviewModalWrapper = styled.div`
  margin: 2em auto 0.5em;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const floaterStyles = {
  wrapper: {
    cursor: "pointer"
  },
  floater: {
    zIndex: 1001
  },
  container: {
    borderRadius: 5,
    color: "rgb(51, 51, 51)",
    padding: 15,
    textAlign: "center"
  }
};

class VideoSelector extends React.Component {
  state = {
    myVideos: [],
    freeVideos: [],
    selectedVideo: {},
    menuSelection: "myVideos",
    previewIsOpen: false,
    previewItem: null,
    loading: true,
    selectionChanged: false,
    showChangeWarning: false,
    showSelectedWarning: false,
    deletingVideo: false,
    deleteModal: false,
    deleteAsset: {}
  };

  async componentDidMount() {
    const { userCompany } = this.props;
    const { industry = "" } = userCompany;
    const { media } = this.props.pendingCampaign;
    const mediaVideo = media.find(item => item.mediaType === "VIDEO");
    const { filename = "" } = mediaVideo || "";
    try {
      const userVideos = await this.loadUserVideos();
      const freeVideos = await getStockVideos(industry);
      const selectedVideo = [...userVideos, ...freeVideos].find(v =>
        filename.includes(v.name)
      );
      this.setState({
        selectedVideo,
        freeVideos,
        loading: false
      });
    } catch (err) {
      console.log(err);
    }
  }

  loadUserVideos = async () => {
    const { userCompany } = this.props;
    try {
      this.setState({ loading: true });
      const videoResponse = await assetVideosGetByCompany(userCompany._id);
      const videos = videoResponse.filter(video => video.status === "ACTIVE");
      this.setState({ myVideos: videos, loading: false });
      return videos;
    } catch (err) {
      console.log("err getting user videos", err.message);
      return [];
    }
  };

  setMenu = menuSelection => {
    this.setState({ menuSelection });
  };

  handleVideoSelected = async video => {
    this.setState({
      selectedVideo: video ? video : null,
      selectionChanged: true
    });
  };

  handleClearSelected = () => {
    this.setState({
      selectedVideo: null,
      selectionChanged: true
    });
  };

  handlePreviewOpen = newState => {
    this.setState({
      ...newState
    });
  };

  handlePreviewClose = () => {
    this.setState({
      previewIsOpen: false,
      previewItem: null
    });
  };

  selectedSubmit = () => {
    const { selectedVideo } = this.state;
    const { pendingCampaign: { media = [] } = {} } = this.props;
    const selectedImages = media.filter(item => item.mediaType === "IMAGE");
    const updatedSelection = selectedVideo
      ? [...selectedImages, this.videoObjectFormat(selectedVideo)]
      : [...selectedImages];
    this.props.updateMedia(updatedSelection);
  };

  videoObjectFormat = video => ({
    filename: `${video.name}.${video.format}`,
    mediaType: video.type,
    status: video.status,
    title: "",
    adId: "",
    inactiveReason: "",
    effectiveStatus: "",
    adReviewFeedback: ""
  });

  closeVideoModal = () => {
    const { selectionChanged, showChangeWarning } = this.state;
    if (selectionChanged && showChangeWarning) {
      this.props.updateMedia(false);
    } else if (selectionChanged) {
      this.setState({ showChangeWarning: true });
      console.log("Something changed. Are you sure?");
    } else {
      this.props.updateMedia(false);
    }
  };

  deleteAssetClick = asset => {
    this.setState({
      deleteModal: true,
      deleteAsset: asset
    });
  };

  deleteAsset = async () => {
    const { deleteAsset: asset, selectedVideo = {} } = this.state;
    this.setState({ loading: true });
    if (asset) {
      try {
        await assetDelete(asset);
        if (
          selectedVideo &&
          (selectedVideo.name === asset.public_id ||
            selectedVideo.public_id === asset.public_id)
        ) {
          this.handleClearSelected();
        }
        this.setState(
          {
            loading: false,
            deleteModal: false,
            deleteAsset: {}
          },
          () => {
            this.loadUserVideos();
          }
        );
        this.props.alert({
          type: "success",
          msg: "The video has been deleted."
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  render() {
    const {
      myVideos,
      freeVideos,
      selectedVideo,
      showChangeWarning,
      showSelectedWarning,
      loading,
      menuSelection,
      deleteAsset
    } = this.state;
    const { asset } = this.state.deleteAsset;

    return (
      <ModalWrapper id='modal-wrapper'>
        <ModalLargeWrapper id='video-modal-container'>
          <VideoModalHeader
            menuSelection={menuSelection}
            setMenu={this.setMenu}
          />
          <UploadVideoPane
            show={menuSelection === "uploadVideo"}
            loadUserVideos={this.loadUserVideos}
            handleMyVideoSelected={this.handleVideoSelected}
            setMenu={this.setMenu}
            email={this.props.userProfile.email}
            companyId={this.props.userCompany._id}
          />
          <MyVideosPane
            show={menuSelection === "myVideos"}
            myVideos={myVideos}
            selectedVideo={selectedVideo}
            handleMyVideoSelected={this.handleVideoSelected}
            setMenu={this.setMenu}
            email={this.props.userProfile.email}
            companyId={this.props.userCompany._id}
            loading={loading}
            handlePreviewOpen={this.handlePreviewOpen}
            deleteAssetClick={this.deleteAssetClick}
          />
          <StockVideosPane
            show={menuSelection === "freeImages"}
            freeVideos={freeVideos}
            loading={loading}
            handlePreviewOpen={this.handlePreviewOpen}
            handleStockVideoSelected={this.handleVideoSelected}
            companyId={this.props.userCompany._id}
            selectedVideo={selectedVideo}
          />
          <VideoModalFooter
            handleClearSelected={this.handleClearSelected}
            closeVideoModal={this.closeVideoModal}
            selectedSubmit={this.selectedSubmit}
            selectedVideo={selectedVideo}
          />
        </ModalLargeWrapper>

        <Modal
          isOpen={this.state.previewIsOpen}
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
          style={customStyles}
          contentLabel='Video Preview'
          appElement={document.getElementById("root")}
        >
          <video style={{ maxWidth: "100%", maxHeight: "63vh" }} controls>
            <source
              src={`${process.env.REACT_APP_CLOUDINARY_VIDEO_BASEURL}/upload/${this.state.previewItem}.webm`}
              type='video/webm'
            />
            <source
              src={`${process.env.REACT_APP_CLOUDINARY_VIDEO_BASEURL}/upload/${this.state.previewItem}.mp4`}
              type='video/mp4'
            />
            <source
              src={`${process.env.REACT_APP_CLOUDINARY_VIDEO_BASEURL}/upload/${this.state.previewItem}.ogv`}
              type='video/ogg'
            />
            Your browser does not support this video type
          </video>
          <PreviewModalWrapper>
            <Button type='button' onClick={this.handlePreviewClose}>
              close
            </Button>
          </PreviewModalWrapper>
        </Modal>
        <Floater
          content={
            <span>
              <strong>Warning!</strong> Clicking cancel will not save your
              changes. Click submit to save your selection. Click cancel to
              continue without saving.
            </span>
          }
          open={showChangeWarning}
          target='#cancelBtn'
          styles={floaterStyles}
        />
        <Floater
          content={
            <span>
              You already have a video selected. Please deselect the other video
              and try again.
            </span>
          }
          open={showSelectedWarning}
          target='#video-modal-container'
          styles={floaterStyles}
          hideArrow={true}
        />

        <BpModal
          open={this.state.deleteModal}
          title={"Delete Video"}
          primaryAction={{
            btnLabel: "Delete Video",
            action: this.deleteAsset,
            loading
          }}
          secondaryAction={{
            btnLabel: "Cancel",
            action: () => {
              this.setState({ deleteModal: false, deleteAsset: {} });
            }
          }}
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
          contentLabel='Delete Video'
          body={
            deleteAsset && (
              <div>
                <p>
                  Are you sure you want to delete this video? This cannot be
                  undone.
                </p>
                <p>
                  <img
                    src={`${process.env.REACT_APP_CLOUDINARY_VIDEO_BASEURL}/upload/c_scale,f_auto,h_96,q_auto/${deleteAsset.name}.jpg`}
                    alt='to delete'
                  />
                </p>
              </div>
            )
          }
          width='auto'
          zIndex='1002'
        />
      </ModalWrapper>
    );
  }
}

export default VideoSelector;
