import React from "react";
import PhoneInput from "react-phone-input-2";
import styled from "styled-components";

import {
  notificationCreate,
  notificationsGet,
  notificationUpdate
} from "../../../../utils/NotificationsAPI";
import { Title } from "../../../styled/Headers";
import { InputBox } from "../../../styled/Input";
import { Card, CardTitle } from "../../../styled/Layout";
import ToggleBtn from "../../../elements/ToggleBtn";

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 144px;
  margin: 14px auto;
`;

const ToggleLabel = styled.span`
  margin: 0 20px;
`;

const NotifyWrapper = styled.div`
  width: 400px;
  margin: 20px auto 0;
`;

const Icon = styled.i`
  color: ${props => props.theme.TextC};
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.ATextC};
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .react-tel-input {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
`;

const inputStyle = {
  border: "2px solid ",
  paddingLeft: "14px",
  width: "300px",
  maxWidth: "100%",
  marginLeft: "5px"
};

const buttonStyle = {
  border: "2px solid ",
  position: "static"
};

const NotificationSettings = props => {
  const {
    userProfile: { email, companyId } = {},
    title = "Notification Settings"
  } = props;
  // const {
  //   sendSms = false,
  //   sendEmail = false,
  //   smsNumbers = [],
  //   emails = []
  // } = notificationSettings;

  const [newNum, setNewNum] = React.useState("");
  const [addNum, setAddNum] = React.useState(false);
  const [newEmail, setNewEmail] = React.useState("");
  const [addEmail, setAddEmail] = React.useState(false);
  const [smsLoading, setSmsLoading] = React.useState(false);
  const [emailLoading, setEmailLoading] = React.useState(false);
  const [notificationSettings, setNotificationSettings] = React.useState({
    companyId: "",
    smsActive: false,
    emailActive: false,
    appActive: false,
    notificationType: "MESSAGE",
    smsContacts: [],
    emailContacts: [],
    isActive: false
  });

  React.useEffect(() => {
    const get = async () => {
      try {
        console.log("getting notifications");
        if (!companyId) {
          return;
        }
        const notifications = await notificationsGet();
        console.log("raw notifications", notifications);
        let messageNotification = notifications.find(
          n => n.notificationType === "MESSAGE"
        );
        if (!messageNotification) {
          messageNotification = await notificationCreate({
            companyId,
            notificationType: "MESSAGE",
            ...(email && { emailContacts: [email] }),
            isActive: true
          });
        }
        setNotificationSettings(messageNotification);
      } catch (err) {
        console.log(err.message);
      }
    };
    get();
  }, [companyId, email]);

  const notificationsUpdate = async newNotifications => {
    try {
      const updateResponse = await notificationUpdate(newNotifications);
      setNotificationSettings(updateResponse);
      return true;
    } catch (err) {
      console.log(err.message);
    }
  };

  const toggleSms = async () => {
    try {
      setSmsLoading(true);
      await notificationsUpdate({
        ...notificationSettings,
        smsActive: !notificationSettings.smsActive
      });
      setSmsLoading(false);
    } catch (err) {
      console.log("error getting conversation list", err);
    }
  };

  const toggleEmail = async () => {
    try {
      setEmailLoading(true);
      await notificationsUpdate({
        ...notificationSettings,
        emailActive: !notificationSettings.emailActive
      });
      setEmailLoading(false);
    } catch (err) {
      console.log("error getting conversation list", err);
    }
  };

  const handlePhoneChange = async (value, key) => {
    const num = value.replace(/[^0-9]+/g, "");
    const { smsContacts } = notificationSettings;
    if (key === "new") {
      if (value && value !== "1") {
        smsContacts.push(`+${num}`);
        setNewNum("");
      } else {
        return;
      }
    } else {
      smsContacts[key] = `+${num}`;
    }
    try {
      await notificationsUpdate({
        ...notificationSettings,
        smsContacts
      });
    } catch (err) {
      console.log("error getting conversation list", err);
    }
  };

  const handleEmailChange = async (value, key) => {
    const { emailContacts } = notificationSettings;
    if (key === "new") {
      if (value && value !== "+1") {
        emailContacts.push(value);
        setNewEmail("");
      } else {
        return;
      }
    } else {
      emailContacts[key] = value;
    }
    try {
      await notificationsUpdate({
        ...notificationSettings,
        emailContacts
      });
    } catch (err) {
      console.log("error getting conversation list", err);
    }
  };

  const deleteNum = async num => {
    const { smsContacts } = notificationSettings;
    const newNumbers = smsContacts.filter(s => s !== num);
    try {
      await notificationsUpdate({
        ...notificationSettings,
        smsContacts: newNumbers
      });
    } catch (err) {
      console.log("error getting conversation list", err);
    }
  };

  const deleteEmail = async email => {
    const { emailContacts } = notificationSettings;
    const newEmails = emailContacts.filter(e => e !== email);
    try {
      await notificationsUpdate({
        ...notificationSettings,
        emailContacts: newEmails
      });
    } catch (err) {
      console.log("error getting conversation list", err);
    }
  };

  const onKeyDown = (e, key) => {
    if (e.key === "Enter") {
      console.log("enter");
      if (e.target.name === "phone") {
        handlePhoneChange(e.target.value, key);
      } else if (e.target.name === "email") {
        handleEmailChange(e.target.value, key);
      }
    }
  };

  return (
    <Card>
      <CardTitle>
        <Title>{title}</Title>
      </CardTitle>
      <p>How do you want to receive notifications about message responses?</p>
      <NotifyWrapper>
        <ToggleWrapper>
          <ToggleBtn
            on={notificationSettings.smsActive}
            onChange={toggleSms}
            loading={smsLoading}
          />
          <ToggleLabel>SMS</ToggleLabel>
        </ToggleWrapper>
        {!!notificationSettings.smsActive && (
          <div>
            {notificationSettings.smsContacts.map((num, key) => (
              <InputWrapper key={num}>
                <PhoneInput
                  type='text'
                  name='phone'
                  data-namereadable='phone'
                  value={num}
                  onBlur={input => handlePhoneChange(input.target.value, key)}
                  country='us'
                  regions={["north-america"]}
                  inputStyle={inputStyle}
                  buttonStyle={buttonStyle}
                  prefix='+'
                  onKeyDown={e => onKeyDown(e, key)}
                />
                <Icon className='material-icons' onClick={() => deleteNum(num)}>
                  delete
                </Icon>
                <Icon
                  className='material-icons'
                  onClick={() => setAddNum(true)}
                >
                  add
                </Icon>
              </InputWrapper>
            ))}
            {notificationSettings.smsContacts.length === 0 || addNum ? (
              <InputWrapper>
                <PhoneInput
                  type='text'
                  name='phone'
                  data-namereadable='phone'
                  value={newNum}
                  onChange={value => setNewNum(value)}
                  onBlur={input => handlePhoneChange(input.target.value, "new")}
                  country='us'
                  regions={["north-america"]}
                  inputStyle={inputStyle}
                  buttonStyle={buttonStyle}
                  prefix='+'
                  onKeyDown={e => onKeyDown(e, "new")}
                />
              </InputWrapper>
            ) : null}
          </div>
        )}
      </NotifyWrapper>
      <NotifyWrapper>
        <ToggleWrapper>
          <ToggleBtn
            on={notificationSettings.emailActive}
            onChange={toggleEmail}
            loading={emailLoading}
          />
          <ToggleLabel>Email</ToggleLabel>
        </ToggleWrapper>
        {!!notificationSettings.emailActive && (
          <div>
            {notificationSettings.emailContacts.map((email, key) => (
              <InputWrapper key={email}>
                <InputBox
                  type='text'
                  name='email'
                  data-namereadable='email'
                  defaultValue={email}
                  onBlur={input => handleEmailChange(input.target.value, key)}
                  onKeyDown={e => onKeyDown(e, key)}
                />
                <Icon
                  className='material-icons'
                  onClick={() => deleteEmail(email)}
                >
                  delete
                </Icon>
                <Icon
                  className='material-icons'
                  onClick={() => setAddEmail(true)}
                >
                  add
                </Icon>
              </InputWrapper>
            ))}
            {notificationSettings.emailContacts.length === 0 || addEmail ? (
              <InputWrapper>
                <InputBox
                  type='text'
                  name='email'
                  data-namereadable='email'
                  value={newEmail}
                  onChange={input => setNewEmail(input.target.value)}
                  onBlur={input => handleEmailChange(input.target.value, "new")}
                  onKeyDown={e => onKeyDown(e, "new")}
                />
              </InputWrapper>
            ) : null}
          </div>
        )}
      </NotifyWrapper>
    </Card>
  );
};

export default NotificationSettings;
