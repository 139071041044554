import React from "react";
import {
  InputContainer,
  InputBoxWrapper,
  InputBox,
  InputLabel,
  FormWrapper,
  FormDescription
} from "../../../styled/Input";

export const MarketSharpCoreSettings = (props) => {
  const { integration } = props;

  return (
    <FormWrapper single>
      <InputContainer slimHeight customWidth="100%">
        <InputLabel>COY</InputLabel>
        <InputBoxWrapper>
          <InputBox
            type="text"
            name="MSM_coy"
            readOnly
            value={integration.MSM_coy}
          />
        </InputBoxWrapper>
      </InputContainer>
      <InputContainer slimHeight customWidth="100%">
        <InputLabel>Source Key</InputLabel>
        <InputBoxWrapper>
          <InputBox
            type="text"
            name="MSM_source"
            readOnly
            value={integration.MSM_source}
          />
        </InputBoxWrapper>
      </InputContainer>
      <FormDescription evenContainer>
        Please contact your Support Representative to update these values.
      </FormDescription>
    </FormWrapper>
  );
};
