import styled from "styled-components";
import {
  firstBreakpoint,
  secondBreakpoint
} from "../../../styled/PhoneNumberProvision";

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding: 31px 20px 21px;
  background: white;
  border-bottom: 11px solid ${props => props.theme.ATextC};
  @media screen and (min-width: 500px) {
    display: grid;
    grid-template-columns: 1fr 1fr 10px;
    grid-gap: 1em;
  }
  @media screen and (min-width: ${firstBreakpoint}px) {
    grid-template-columns: 63px 1fr 300px 10px;
    margin: 20px 0;
    padding: 0;
    border: none;
  }
  @media screen and (min-width: ${secondBreakpoint}px) {
    grid-template-columns: 63px 1fr 300px 20px;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    margin: 22px 0 0;
    text-transform: uppercase;
    font-size: 1.4em;
    @media screen and (min-width: 500px) {
      margin: 0;
    }
  }
`;

const BackBtn = styled.div`
  display: none;
  background: ${props => (props.empty ? "transparent" : props.theme.HC1)};
  color: white;
  border-radius: 0 10px 10px 0;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
  padding: 8px 4px 7px 15px;
  cursor: pointer;
  span {
    font-size: 30px;
  }
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: flex;
  }
`;

const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProgressIndicator = styled.div`
  width: 100%;
  background: ${props => props.theme.ATextC};
  position: relative;
  height: 7px;
  border-radius: 9px;
  span {
    background: ${props => props.theme.HC1};
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: ${props => (props.displayWidth ? props.displayWidth : "0px")};
    height: 100%;
    border-radius: 9px 0 0 9px;
  }
  @media screen and (min-width: ${firstBreakpoint}px) {
    height: 13px;
  }
`;

const ProgressTxt = styled.span`
  display: inline-block;
  margin-left: 10px;
`;

const ModalHeader = props => {
  const { back, showProgress, progress } = props;
  return (
    <ModalWrapper className='modal-header'>
      <BackBtn onClick={back}>
        <span className='material-icons'>arrow_back</span>
      </BackBtn>
      <HeaderWrapper>
        <h1>Set up Messaging</h1>
      </HeaderWrapper>
      {!!showProgress && (
        <ProgressWrapper>
          <ProgressIndicator displayWidth={`${progress}%`}>
            <span></span>
          </ProgressIndicator>
          <ProgressTxt>{`${progress}%`}</ProgressTxt>
        </ProgressWrapper>
      )}
    </ModalWrapper>
  );
};

export default ModalHeader;
