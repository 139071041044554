import axios from "axios";
import queryString from "query-string";
import React from "react";
import styled from "styled-components";
import { auth } from "../../../../firebase/firebase";

// eslint-disable-next-line no-unused-vars
import { injectStripe } from "react-stripe-elements";
import { LoaderDotsSmall } from "../../../elements/Loaders";
import Checkbox from "../../../elements/Checkbox";
import StripeCreateAccount from "./StripeCreateAccount";

// ***THIS MUST STAY FOR CLEAVE TO WORK WITH PHONE NUMBER INPUTS

import { errorHandler, handleFloaterClick } from "../../../../utils/Helpers";

// Styled Components
import DotsBrickLoader from "../../../elements/DotsBrickLoader";
import { Button } from "../../../styled/Buttons";
import { Title } from "../../../styled/Headers";
import {
  Input,
  InputBox,
  InputContainer,
  InputLabel
} from "../../../styled/Input";
import InputError from "../../../styled/InputErrorForm";
import { Card } from "../../../styled/Layout";
import RegisterForm from "../../../styled/RegisterForm";

const DocLink = styled.a`
  color: RGB(252, 183, 75);
`;

const SigninWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SigninOption = styled.div`
  margin-left: 10px;
  text-decoration: underline;
  color: #3a0ca2;
  cursor: pointer;
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  input {
    width: 2em !important;
    min-width: 0 !important;
  }
  label {
    font-size: 15px;
  }
`;

const GroupTitle = styled.span`
  display: block;
  font-weight: 600;
  color: #fbb74c;
  letter-spacing: 2.5px;
  font-size: 17px;
  margin: 0.5em;
`;

const SubscrGroup = styled.div`
  background-color: #fafafa;
  border-radius: 4px;
  width: 90%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.14), 0 0px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  max-width: 25em;
  margin: auto;
  padding: 1em;
`;

const TooltipWrapper = styled.div`
  margin: 0.5em 0;
  img {
    height: 12px;
    margin: 0 0.4em;
  }
  span {
    font-size: 13px;
    transition: 5s;
  }
  b {
    font-size: smaller;
  }
  p {
    font-size: 15px;
    margin-top: 2em;
  }
`;

const RegisterLoading = styled.div`
  margin: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingText = styled.div`
  margin: 0 20px 0;
  color: ${props => props.theme.HC1};
  font-weight: bold;
  font-size: 20px;
`;

const BricksContainer = styled.div`
  position: relative;
  width: 30px;
`;

const ErrorWrapper = styled.p`
maxWidth: '35em',
                  margin: '3em auto',
                  fontWeight: '500'
`;

// USE IF REQUIRED ASTERISKS ARE PLACED AFTER REQUIRED FIELDS
// const RequiredRed = styled.div`
//   color: #ed5e69;
// `;

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value
});

class CreateAccount extends React.Component {
  stripeRef = React.createRef();

  state = {
    email: "",
    passwordOne: "",
    passwordTwo: "",
    firstName: "",
    lastName: "",
    companyName: "",
    companyAddress: "",
    companyWebsite: "",
    phoneNumber: "",
    industryType: "",
    stripeID: null,
    openTermsOfUse: false,
    openBetaAgreement: false,
    openCDA: false,
    isSubscriptionChecked: true,
    isTermsChecked: false,
    isBetaChecked: false,
    isCdaChecked: false,
    submissionStarted: false,
    interval: "",
    selectedPlan: {},
    standardPlans: null,
    premiumPlans: null,
    error: null,
    errMessages: {},
    userExists: false,
    userExistsTooltipOpen: false,
    floaters: {
      explainCC: false
    },
    ccValid: {}
  };

  componentDidMount = async () => {
    const query = queryString.parse(this.props.location.search);
    const {
      email = "",
      firstname: firstName = "",
      lastname: lastName = "",
      phone: phoneNumber = "",
      website: companyWebsite = "",
      industry: industryType = ""
    } = query || {};
    // this.setState({ ...query });
    this.setState(
      {
        email,
        firstName,
        lastName,
        companyWebsite,
        phoneNumber,
        industryType
      },
      () => {
        if (email) {
          this.checkUserEmail();
        }
      }
    );
  };

  checkUserEmail = async () => {
    const { email, errMessages } = this.state;
    const { alert } = this.props;
    try {
      const signInMethods = await auth.fetchSignInMethodsForEmail(email);
      if (signInMethods && signInMethods.length > 0) {
        this.setState({
          userExists: true,
          errMessages: {
            ...errMessages,
            userExists:
              "User already exists for this email. Please use another email or go to the login page.",
            email: null
          }
        });
        alert({ type: "error", msg: "User already exists for this email." });
      } else {
        this.setState({
          userExists: false,
          errMessages: {
            ...errMessages,
            userExists: null,
            email: null
          }
        });
      }
      this.getHubspotUser();
    } catch (err) {
      console.log("checking user email error", err);
      if (err.code === "auth/invalid-email") {
        this.setState({
          errMessages: {
            ...this.state.errMessages,
            email: "Please enter a valid email address"
          }
        });
      }
    }
  };

  getHubspotUser = async () => {
    try {
      // todo get hubspot by email
      /* const hsUser = await axios.post(
        `${BP_NODE_SERVER}/hubspotGetContactByEmail`,
        {
          email
        }
      );
      if (hsUser.data && hsUser.data.properties) {
        const { firstname, lastname, company, phone, website, industry } =
          hsUser.data.properties;
        this.setState({
          firstName: firstname ? firstname.value : '',
          lastName: lastname ? lastname.value : '',
          companyName: company ? company.value : '',
          companyWebsite: website ? website.value : '',
          phoneNumber: phone ? phone.value : '',
          industryType: industry ? industry.value : ''
        });
      } */
    } catch (err) {
      if (err.response) {
        console.error(err.response);
      } else {
        console.error(err);
      }
    }
  };

  handleUserAgreementCheckbox = e => {
    const { name } = e.target;
    if (name === "termsOfUse") {
      const { isTermsChecked } = this.state;
      if (!isTermsChecked) {
        this.setState({ isTermsChecked: true });
      } else {
        this.setState({ isTermsChecked: false });
      }
    }
  };

  onPhoneChange = event => {
    this.setState(byPropKey("phoneNumber", event.target.value));
  };

  handleUserAgreementCheckbox = () => {
    const { isTermsChecked } = this.state;
    this.setState({ isTermsChecked: !isTermsChecked });
  };

  handlePasswordCompare = () => {
    const { alert } = this.props;
    if (this.state.passwordOne !== this.state.passwordTwo) {
      alert({ type: "error", msg: "Your passwords do not match." });
      this.setState({
        errMessages: {
          ...this.state.errMessages,
          passwordsDontMatch: "Your passwords do not match"
        }
      });
    } else {
      this.setState({
        errMessages: {
          ...this.state.errMessages,
          passwordsDontMatch: ""
        }
      });
    }
  };

  stripeElementChange = cc => {
    this.setState({ ccValid: cc });
    const { alert } = this.props;
    if (cc.empty) {
      alert({ type: "error", msg: "Please enter your credit card" });
    } else if (cc.error) {
      console.warn(cc.error.message);
      alert({ type: "error", msg: cc.error.message });
    }
  };

  onSubmit = async e => {
    e.preventDefault();
    this.setState({ submissionStarted: true });
    const { ccValid, isTermsChecked } = this.state;
    const { alert } = this.props;
    if (!isTermsChecked) {
      console.log("Need to accept terms");
      return;
    }
    if (this.state.userExists) {
      console.error("User already exists");
      alert({
        type: "error",
        msg: "User already exists for this email. Did you forget your password? Please contact us."
      });
    } else if (ccValid.error) {
      console.warn(ccValid.error.message);
      alert({ type: "error", msg: ccValid.error.message });
    } else if (ccValid.empty) {
      console.warn("credit card empty");
      alert({ type: "error", msg: "Please enter your credit card" });
    } else {
      try {
        this.props.toggleRegistering();
        const stripeToken = await this.props.stripe.createToken({});
        const { token: source } = stripeToken;
        console.log("stripeToken", stripeToken);
        await this.props.updatePaymentSource(source.id);
        const { email, passwordOne, firstName, lastName } = this.state;
        const client = await axios.get("https://ipapi.co/json/");
        const termsAcceptedIP = client.data.ip;
        const termsAcceptedTime = new Date();
        await this.props.updateNewUser({
          email,
          firstName,
          lastName,
          phoneNumber: "",
          timezone: "America/New_York",
          isActive: false,
          companyId: "",
          fId: "",
          settings: { termsAccepted: true, termsAcceptedIP, termsAcceptedTime }
        });
        auth
          .createUserWithEmailAndPassword(email, passwordOne)
          .then(() => console.log("logged in"))
          .catch(err => {
            const { status, message, url } = errorHandler(err);
            console.log("err", `${status}: ${message} ${url}`);
            alert({ type: "error", msg: message });
            this.props.toggleRegistering();
            this.setState({ submissionStarted: false });
          });
      } catch (err) {
        const { status, message } = errorHandler(err);
        console.log(status, message);
      }
    }
  };

  handleFloaterClick = (e, clickTarget) => {
    e.preventDefault();
    const { floaters } = this.state;
    const newFloaters = handleFloaterClick(clickTarget, floaters);
    this.setState({ floaters: newFloaters });
  };

  render() {
    const {
      email,
      passwordOne,
      passwordTwo,
      firstName,
      lastName,
      companyName,
      isTermsChecked,
      submissionStarted,
      userExists,
      error,
      ccValid
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === "" ||
      email === "" ||
      !isTermsChecked;

    return (
      <div id='registerWrapper'>
        <Card>
          <Title>Create Account</Title>
          <SigninWrapper>
            <span>Already have an account?</span>
            <SigninOption
              onClick={() => {
                this.props.history.push(`/login`);
              }}
            >
              <span>Sign In</span>
            </SigninOption>
          </SigninWrapper>
          <RegisterForm>
            <InputContainer customWidth={"25rem"}>
              <InputLabel>Email Address</InputLabel>
              <InputBox
                value={email}
                onChange={event =>
                  this.setState(byPropKey("email", event.target.value))
                }
                onBlur={this.checkUserEmail}
                type='email'
                placeholder='Email Address'
                required
              />
              {/* Error message for this input */}
              <InputError>{this.state.errMessages.userExists}</InputError>
              <InputError>{this.state.errMessages.email}</InputError>
            </InputContainer>
            <InputContainer customWidth={"25rem"}>
              <InputLabel>Password</InputLabel>
              <InputBox
                value={passwordOne}
                disabled={userExists}
                onChange={event =>
                  this.setState(byPropKey("passwordOne", event.target.value))
                }
                type='password'
                placeholder='Password'
                required
              />
              <InputError>
                {this.state.errMessages.passwordsDontMatch}
              </InputError>
            </InputContainer>
            {/* Error message for this input */}
            <InputContainer customWidth={"25rem"}>
              <InputLabel>Confirm Password</InputLabel>
              <InputBox
                value={passwordTwo}
                disabled={userExists}
                onChange={event => {
                  this.setState(byPropKey("passwordTwo", event.target.value));
                }}
                onBlur={this.handlePasswordCompare}
                type='password'
                placeholder='Confirm Password'
                required
              />
              <InputError>
                {this.state.errMessages.passwordsDontMatch}
              </InputError>
            </InputContainer>
            {/* Error message for this input */}
            <SubscrGroup>
              <GroupTitle>Payment Information</GroupTitle>
              <TooltipWrapper>
                <p>Please Enter your Credit Card</p>
                {/* <StripeRegister
                        {...this.props}
                        email={email}
                        ref={this.stripeRef}
                        fromRegister
                      /> */}
                <StripeCreateAccount
                  {...this.props}
                  email={email}
                  firstName={firstName}
                  lastName={lastName}
                  companyName={companyName}
                  ref={this.stripeRef}
                  stripeElementChange={this.stripeElementChange}
                />
                {/* <StripeCreateAccount
                      {...this.props}
                      email={email}
                      firstName={firstName}
                      lastName={lastName}
                      companyName={companyName}
                      ref={this.stripeRef}
                    /> */}
              </TooltipWrapper>
            </SubscrGroup>
            <Input>
              <CheckBoxWrapper>
                <Checkbox
                  type='checkbox'
                  value='termsOfUse'
                  name='termsOfUse'
                  onChange={() => this.handleUserAgreementCheckbox()}
                  checked={this.state.isTermsChecked}
                />
                <label>
                  I agree to the{" "}
                  <DocLink
                    id='termsOfUse'
                    href='https://boostpoint.com/terms/'
                    target='_blank'
                  >
                    {" "}
                    Terms of Use
                  </DocLink>{" "}
                </label>
              </CheckBoxWrapper>
            </Input>

            {!this.props.registering || !submissionStarted ? (
              <Button
                solid
                disabled={
                  isInvalid ||
                  submissionStarted ||
                  userExists ||
                  ccValid.empty ||
                  ccValid.error ||
                  !ccValid.complete
                }
                onClick={e => this.onSubmit(e)}
              >
                Register
                {submissionStarted && <LoaderDotsSmall />}
              </Button>
            ) : (
              <RegisterLoading>
                <LoadingText>Registering</LoadingText>
                <BricksContainer>
                  <DotsBrickLoader darkMode={true} />
                </BricksContainer>
              </RegisterLoading>
            )}

            {error && <ErrorWrapper>{error.message}</ErrorWrapper>}
          </RegisterForm>
        </Card>
      </div>
    );
  }
}

export default injectStripe(CreateAccount);
