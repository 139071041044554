export const validateWorkflow = workflow => {
  const { name } = workflow;
  let status;
  const nameValidate = validateName(name);
  const triggerValidate = validateTrigger(workflow);
  const filterValidate = validateFilters(workflow);
  const actionsValidate = validateActions(workflow);
  const endActionsValidate = validateEndActions(workflow);
  if (
    nameValidate ||
    triggerValidate ||
    filterValidate ||
    actionsValidate ||
    endActionsValidate
  ) {
    status = {
      ...nameValidate,
      ...triggerValidate,
      ...filterValidate,
      ...actionsValidate,
      ...endActionsValidate
    };
  } else {
    status = false;
  }
  return status;
};

export const validateName = (name, workflows, _id) => {
  let duplicate = false;
  if (workflows && _id) {
    duplicate = workflows.some(
      item => item.name.toLowerCase() === name.toLowerCase() && item._id !== _id
    );
  }
  if (name === "Untitled Workflow") {
    return {
      name: {
        message: `Please rename workflow from 'Untitled Workflow'`
      }
    };
  } else if (name === "") {
    return {
      name: {
        message: `Workflow name cannot be empty.`
      }
    };
  } else if (duplicate) {
    return {
      name: { message: `Workflow Name Already Exists. Update Name` }
    };
  } else {
    return false;
  }
};

export const validateTrigger = workflow => {
  const { triggerType } = workflow;
  if (triggerType === "") {
    return {
      triggerType: { message: `Please select a valid trigger` }
    };
  } else {
    return false;
  }
};

export const validateFilters = workflow => {
  const { filters } = workflow;
  let error = false;
  filters.forEach(filter => {
    const { type, tagIds, statusIds, campaignIds } = filter;
    if (
      (type === "CAMPAIGN" && campaignIds.length === 0) ||
      (type === "STATUS" && statusIds.length === 0) ||
      (type === "TAGS" && tagIds.length === 0)
    ) {
      error = {
        filters: {
          message: `Each filter needs to have at least one field selected.`
        }
      };
    }
    if (type === "ALL_CONTACTS" && filter.length > 1) {
      error = {
        filters: {
          message: `Filter and All Contacts can't be selected simultaneously`
        }
      };
    }
  });
  return error;
};

export const validateActions = workflow => {
  const { actions } = workflow;
  let actionErrors = [];

  actionErrors =
    actions.length === 0
      ? [
          ...actionErrors,
          {
            message: `You must select at least one action.`,
            index: 0,
            noCard: true
          }
        ]
      : [];

  if (actionErrors.length === 0) {
    actionErrors =
      actions[0].type === "DELAY"
        ? [
            ...actionErrors,
            {
              message: `First action cannot be a delay.`,
              index: 0
            }
          ]
        : [];
  }

  if (actionErrors.length === 0) {
    const orderMismatch = actions.some(
      (action, index) =>
        (action.type === "DELAY" && index % 2 === 0) ||
        (action.type === "SMS" && index % 2 === 1)
    );
    if (orderMismatch) {
      const newActionErrors = actions.map((action, index) => ({
        message: `There must be a delay between each action`,
        index
      }));
      actionErrors = [...actionErrors, ...newActionErrors];
    }
  }

  if (actionErrors.length === 0) {
    actions.forEach((action, index) => {
      const { type } = action;
      if (type === "SMS" && action.body === "") {
        actionErrors = [
          ...actionErrors,
          {
            message: `A valid message must be entered for every outbound text message.`,
            index
          }
        ];
      }
    });
  }

  let error = false;
  if (actionErrors.length > 0) {
    error = { actions: [...actionErrors] };
  }

  return error;
};

export const validateEndActions = workflow => {
  const { endActions } = workflow;
  let actionErrors = [];

  actionErrors =
    endActions.length === 0
      ? [
          ...actionErrors,
          {
            message: `You must select at least one workflow completion condition.`,
            index: 0,
            noCard: true
          }
        ]
      : [];

  if (actionErrors.length === 0) {
    endActions.forEach((endAction, index) => {
      const { actions, type } = endAction;
      if (type === "default" || type === "") {
        actionErrors = [
          ...actionErrors,
          {
            message: `'If' values must all have a condition selected.`,
            index
          }
        ];
      }
      const defaultSelected = actions.some(a => a === "default" || !a);
      if (defaultSelected) {
        actionErrors = [
          ...actionErrors,
          {
            message: `'Then' values must all have an outcome selected.`,
            index
          }
        ];
      }
    });
  }

  let error = false;
  if (actionErrors.length > 0) {
    error = { endActions: [...actionErrors] };
  }

  return error;
};
