import React from "react";
import styled from "styled-components";
import { ProfileImageProps } from "./ProfileImageProps";

const Profile = styled.div<{ img?: string }>`
  border-radius: 100%;
  overflow: hidden;
  width: 70px;
  height: 70px;
  ${props => (props.img ? `background-image: url(${props.img});` : "")}
  background-repeat: no-repeat;
  background-size: 70px 70px;
  align-content: center;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  font-size: 47px;
  font-family: poppins;
  margin: 0 14px;
`;

const BusinessManagerConnect = (props: ProfileImageProps) => {
  const { img, name } = props;
  return (
    <Profile
      img={
        img
          ? img
          : !name
          ? "https://res.cloudinary.com/equipter/image/upload/c_scale,h_65/v1640714017/Boostpoint_Images/profile-placeholder.jpg"
          : undefined
      }
      alt='Placeholder Logo'
    >
      {!img && name && name.charAt(0).toUpperCase()}
    </Profile>
  );
};

export default BusinessManagerConnect;
