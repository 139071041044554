/* global cloudinary */

import React from "react";
import styled from "styled-components";
import LoadingBar from "../elements/LoadingBar";
import EditMobileMenu from "../styled/EditMobileMenu";
import ImageWrapper from "../styled/ImageWrapper";
import { assetAdd } from "../../utils/MediaAPI";

const SelectableVideoWrapper = styled.div`
  position: relative;
  img {
    height: 6em;
    margin: 1em;
    cursor: pointer;
    box-shadow: ${props =>
      props.selected ? "0px 0px 2px 5px #fbb74c" : "none"};
  }
`;

const SelectableImagePlay = styled.div`
  position: absolute;
  overflow: hidden;
  cursor: pointer;
  background-color: RGBA(191, 191, 191, 0.6);
  padding: 0.1em 0.2em;
  width: 2em;
  height: 1.6em;
  top: 1em;
  left: 1em;
  transition: all ease 0.5s;

  :hover {
    background-color: RGBA(191, 191, 191, 1);
    transition: all ease 0.5s;
  }
`;

const DeleteAssetButton = styled.button`
  display: block;
  position: absolute;
  right: 16px;
  top: 16px;
  background-color: rgba(255, 255, 255, 0.8);
  width: 1.95em;
  height: 2em;
  &:hover {
    background-color: rgba(236, 100, 75, 0.8);
  }
`;

const MyVideosPane = props => {
  const { show, myVideos, selectedVideo, email, companyId, loading } = props;

  const uploadCloudinaryWidget = () => {
    cloudinary.openUploadWidget(
      {
        cloud_name: "equipter",
        /* cspell: disable-next-line */
        upload_preset: "x8udno21",
        sources: ["local", "camera"],
        resource_type: "video",
        multiple: false,
        show_powered_by: false,
        theme: "minimal",
        tags: [email]
      },
      (error, result) => {
        if (result.event === "success") {
          uploadSuccess(result.info);
        }
        if (result.event === "close") {
          props.setMenu("myVideos");
        }
        if (error) {
          console.error(error);
        }
      }
    );
  };

  const uploadSuccess = async video => {
    const newVideo = {
      companyId,
      name: video.public_id,
      format: video.format,
      type: "VIDEO",
      status: "ACTIVE",
      height: video.height,
      width: video.width,
      createdDate: video.created_at,
      userPending: true,
      usedCampaignIds: []
    };
    await assetAdd(newVideo);
    props.loadUserVideos();
    if (selectedVideo && selectedVideo.name === newVideo.name) {
      props.handleMyVideoSelected(null);
    } else {
      props.handleMyVideoSelected(newVideo);
    }
  };

  return (
    show && (
      <div>
        <EditMobileMenu>
          <div id='editMenuLeft' className='editMenuMobile__item'>
            <p onClick={uploadCloudinaryWidget}>+ add</p>
          </div>
          <button id='editMenuRight' className='editMenuMobile__item'>
            <p>edit</p>
          </button>
        </EditMobileMenu>
        <ImageWrapper id='imageWrapper'>
          {/* displaying a thumbnail of all the users videos stored in state.myVideos */}
          {loading ? (
            <LoadingBar />
          ) : myVideos.length === 0 ? (
            <p id='emptyMessage'>
              You have no videos.{" "}
              <span className='desktop'>Use the Upload tab to add videos.</span>
              <span className='mobile'>
                Click the +add button to add videos.
              </span>
            </p>
          ) : (
            myVideos.map((data, index) => (
              <SelectableVideoWrapper
                style={{ position: "relative" }}
                selected={
                  selectedVideo &&
                  selectedVideo.name &&
                  selectedVideo.name.includes(data.name)
                }
                key={index}
              >
                <SelectableImagePlay
                  onClick={() =>
                    props.handlePreviewOpen({
                      previewIsOpen: true,
                      previewItem: data
                    })
                  }
                >
                  <i className='material-icons md-dark md-24'>
                    play_circle_filled_white
                  </i>
                </SelectableImagePlay>
                <img
                  src={`${process.env.REACT_APP_CLOUDINARY_VIDEO_BASEURL}/upload/c_scale,h_96,q_auto/${data.name}.jpg`}
                  key={data.name}
                  id={data.name + "." + data.format}
                  onClick={() => props.handleMyVideoSelected(data)}
                  alt={data.name}
                />
                <DeleteAssetButton
                  onClick={() => props.deleteAssetClick(data, index)}
                >
                  X
                </DeleteAssetButton>
              </SelectableVideoWrapper>
            ))
          )}
        </ImageWrapper>
      </div>
    )
  );
};

export default MyVideosPane;
