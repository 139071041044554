import React from "react";
import styled from "styled-components";
import Notifications from "./Notifications";
import CompanyProfile from "./CompanyProfile";

import MainMenuItems from "./MainMenuItems";

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: rgb(58, 12, 162);
  background: linear-gradient(180deg, #7211b6 0%, #3a0ca2 100%);
  width: ${props => (props.menuOpen ? "433px" : "85px")};
  transition: all 0.2s ease-in-out;
  position: relative;
  justify-content: space-between;
  align-items: flex-start;
  padding: 30px 24px;
  z-index: 1001;
`;

const MenuIcon = styled.span`
  color: #64edc2;
  font-size: 30px;
  margin: 10px 0;
`;

const TopMenu = styled.div`
  width: 100%;
  /* overflow: hidden auto; */
  img {
    height: 30px;
  }
`;

const SeparatorLine = styled.div`
  width: ${props => (props.menuOpen ? "286px" : "50px")};
  height: 0px;
  border: 1px solid #693fc6;
  border-radius: 1px;
  margin-left: -8px;
  margin-bottom: ${props => (props.authUser ? "0" : "25px")};
  transition: all 0.2s ease-in-out;
`;

const BPLogo = styled.div`
  display: flex;
  position: relative;
  height: 30px;
  justify-content: flex-start;
  transition: width 0.2s ease-in-out;
  overflow: hidden;
  text-align: left;
  margin-bottom: 30px;
  img {
    height: 30px;
  }
  #smallLogo {
    padding-bottom: 6px;
    opacity: ${props => (props.menuOpen ? "0" : "1")};
    display: ${props => (props.menuOpen ? "none" : "block")};
    position: absolute;
  }
  #wordLogo {
    padding-bottom: 0;
    opacity: ${props => (props.menuOpen ? "1" : "0")};
    display: ${props => (props.menuOpen ? "block" : "none")};
    position: absolute;
  }
`;

const BottomMenu = styled.div`
  text-align: left;
`;

const ProfileWrapper = styled.div`
  display: flex;
`;

const CompanyLogo = styled.div`
  cursor: pointer;
  margin: ${props =>
    props.menuOpen ? "20px 0 50px -8px" : "30px 0 40px -8px"};
  transition: all 0.1s ease-in-out;
  img {
    width: ${props => (props.menuOpen ? "65px" : "50px")};
    border-radius: 50%;
    transition: all 0.1s ease-in-out;
  }
`;

const ProfileInfo = styled.div`
  margin: 25px 0 0 25px;
  width: ${props => (props.menuOpen ? "100%" : "0px")};
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  opacity: ${props => (props.menuOpen ? "1" : "0")};
  .profile-name {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    justify-content: space-between;
    height: 20px;
    cursor: pointer;
  }
  .profile-name:hover .company-name {
    color: #64edc2;
  }
  .profile-name:hover > * .material-icons {
    color: #64edc2;
  }
  .profile-name:hover .company-name {
    color: #64edc2;
  }
  .company-name {
    color: #ffffff;
    font: normal normal 500 16px Poppins, sans-serif;
    white-space: nowrap;
  }
  .credits-wrapper {
    display: flex;
    color: #ffffff;
    white-space: nowrap;
    margin-bottom: 12px;
  }
  .credit-amounts {
    font: normal normal 300 15px Poppins, sans-serif;
    white-space: nowrap;
  }
  .credit-number {
    color: #f5b508;
    font-weight: 600;
  }
  .subtext {
    font-size: 11px;
  }
  #ad-credits {
    padding-right: 15px;
    border-right: 1px solid #f5b508;
  }
  #sms-credits {
    padding-left: 15px;
  }
`;

const MenuItemWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 40px;
  width: ${props => (props.menuOpen ? "100%" : "45px")};
  border-radius: 10px;
  padding: 7px;
  margin-left: -7px;
  border-radius: 10px;
  justify-content: flex-start;
  position: relative;
  :hover {
    background-color: #2c097b;
  }
`;

const MenuText = styled.div`
  color: #ffffff;
  text-align: left;
  margin-left: 20px;
  font: normal normal 500 16px Poppins, sans-serif;
  width: ${props => (props.menuOpen ? "100%" : "0px")};
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  opacity: ${props => (props.menuOpen ? "1" : "0")};
  white-space: nowrap;
`;

const NotificationWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const ExpandMenuWrapper = styled.div`
  margin: 40px 0 0 0;
`;

const ExpandMenu = styled.div`
  cursor: pointer;
  height: 24px;
  color: #f5b508;
  display: flex;
  align-items: baseline;
  position: relative;
  width: ${props => (props.menuOpen ? "auto" : "0")};
  :hover > *.collapse-color {
    color: #ffffff;
  }
  :hover > .expandIcon .collapse-BGcolor {
    background-color: #ffffff !important;
  }
  :hover > .expandIcon .collapse-border-color {
    border-left: 6px solid #ffffff;
  }
`;

const CollapseMenuText = styled.div`
  color: #57c8ef;
  text-align: left;
  margin-left: 50px;
  width: 90px;
  position: absolute;
  font: normal normal 500 16px Poppins, sans-serif;
  width: ${props => (props.menuOpen ? "100%" : "0px")};
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  opacity: ${props => (props.menuOpen ? "1" : "0")};
  white-space: nowrap;
`;

//custom expand icon
const ExpandMenuIcon = styled.div`
  width: 43px;
  transform: ${props =>
    props.menuOpen ? "matrix(-1, 0, 0, 1, 0, 0)" : "matrix(1, 0, 0, 1, 0, 0)"};
  margin-left: ${props => (props.menuOpen ? "-11px" : "5px")};
  transition: 0.1s;
  transform-style: preserve-3d;
  position: absolute;
  perspective: 1000px;
  .top {
    width: 23px;
    height: 4px;
    background-color: #57c8ef;
    margin: 2px 0;
    transition: background-color 0.2s ease-in-out;
  }
  .middleWrapper {
    display: flex;
    position: relative;
  }
  .middle {
    width: 28px;
    height: 4px;
    background-color: #57c8ef;
    margin: 2px 0;
    transition: background-color 0.2s ease-in-out;
  }
  .middle-triangle {
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid #57c8ef;
    position: absolute;
    right: 10px;
    top: -1.52px;
    transition: border-left 0.2s ease-in-out;
  }
  .bottom {
    width: 23px;
    height: 4px;
    background-color: #57c8ef;
    margin: 2px 0;
    transition: background-color 0.2s ease-in-out;
  }
`;

// SlideOut Content
const SlideOutMenuArrow = styled.div`
  opacity: ${props => (props.menuOpen ? "1" : "0")};
  .material-icons {
    font-size: 30px;
    margin: 10px 0;
    color: #ffffff;
  }
`;

const SlideOutMenu = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1;
  background: #fafbfa;
  top: 0;
  left: 100%;
  width: ${props => (props.slideOutOpen ? "350px" : "0")};
  height: 100%;
  padding: ${props => (props.slideOutOpen ? "15px" : "15px 0")};
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 0px 16px #00000015;
`;

const CloseBtn = styled.div`
  cursor: pointer;
  align-self: flex-end;
  .material-icons {
    color: #3a0ca2;
    margin: 0;
  }
`;

const SlideOutContent = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
`;

const UnreadBubble = styled.span`
  background-color: ${props => props.theme.HCErr2};
  width: 14px;
  height: 14px;
  position: absolute;
  display: block;
  border-radius: 50%;
  left: 23px;
  top: 4px;
`;

const Menu = props => {
  const {
    menuOpen,
    authUser,
    smsCredits,
    adCreditsTotal,
    businessPage: { image } = {},
    unreadMessages
  } = props;
  const { name: companyName } = props.userCompany;
  const [slideOutOpen, setSlideOutOpen] = React.useState(false);
  const [notificationsOpen, setNotificationsOpen] = React.useState(false);
  const [profileOpen, setProfileOpen] = React.useState(false);

  const toggleSlideOutMenu = (open, type) => {
    setSlideOutOpen(false);
    setNotificationsOpen(false);
    setProfileOpen(false);
    if (open & (type === "notification")) {
      setSlideOutOpen(true);
      setNotificationsOpen(true);
    }
    if (open & (type === "profile")) {
      setSlideOutOpen(true);
      setProfileOpen(true);
    }
  };

  const handleMenuLink = pathname => {
    toggleSlideOutMenu(false);
    props.history.push({ pathname });
  };

  return (
    <MenuWrapper
      id='tour-leftMenu'
      menuOpen={menuOpen && "menuOpen"}
    >
      <TopMenu>
        <BPLogo menuOpen={menuOpen && "menuOpen"}>
          <img
            id='smallLogo'
            alt='Boostpoint Small Logo'
            src='https://res.cloudinary.com/equipter/image/upload/v1633965014/Boostpoint_Images/BP-Icon-Rev-RGB-trimmed.png'
          />
          <img
            id='wordLogo'
            alt='Boostpoint Full Logo'
            src='https://res.cloudinary.com/equipter/image/upload/v1633965014/Boostpoint_Images/BP-Logo-OneColor-Rev-Trimmed.png'
          />
        </BPLogo>
        <MainMenuItems
          menuOpen={menuOpen}
          handleMenuLink={handleMenuLink}
          unreadMessages={unreadMessages}
        ></MainMenuItems>
      </TopMenu>
      <BottomMenu>
        <SeparatorLine
          authUser={authUser}
          menuOpen={menuOpen && "menuOpen"}
        ></SeparatorLine>
        {authUser && (
          <div>
            <ProfileWrapper>
              <CompanyLogo
                menuOpen={menuOpen && "menuOpen"}
                onClick={() => toggleSlideOutMenu(!profileOpen, "profile")}
                title='toggle menu'
              >
                <picture>
                  <source
                    srcSet={image}
                    alt='Company Logo'
                  />
                  <img
                    src='https://res.cloudinary.com/equipter/image/upload/c_scale,h_65/v1640714017/Boostpoint_Images/profile-placeholder.jpg'
                    alt='Placeholder Logo'
                  />
                </picture>
              </CompanyLogo>
              <ProfileInfo menuOpen={menuOpen && "menuOpen"}>
                <div
                  onClick={() => toggleSlideOutMenu(!profileOpen, "profile")}
                  className='profile-name'
                >
                  <div className='company-name'>{companyName}</div>
                  <SlideOutMenuArrow
                    menuOpen={menuOpen && "menuOpen"}
                    title='Open Profile'
                  >
                    <span className='material-icons'>keyboard_arrow_right</span>
                  </SlideOutMenuArrow>
                </div>
                <div className='credits-wrapper'>
                  {/* cspell: disable-next-line */}
                  <div
                    id='ad-credits'
                    className='credit-amountsad-credits'
                  >
                    <div className='credit-number'>{adCreditsTotal}</div>
                    <div className='subtext'>Ad Credits</div>
                  </div>
                  {/* cspell: disable-next-line */}
                  <div
                    id='sms-credits'
                    className='credit-amountssms-credits'
                  >
                    <div className='credit-number'>{smsCredits}</div>
                    <div className='subtext'>SMS Credits</div>
                  </div>
                </div>
              </ProfileInfo>
            </ProfileWrapper>
          </div>
        )}
        <MenuItemWrapper
          menuOpen={menuOpen && "menuOpen"}
          onClick={() => toggleSlideOutMenu(!notificationsOpen, "notification")}
          title='Notifications'
        >
          <MenuIcon className='material-icons'>notifications</MenuIcon>
          {unreadMessages > 0 && (
            <UnreadBubble title='You have unread messages' />
          )}
          <NotificationWrapper>
            <MenuText menuOpen={menuOpen && "menuOpen"}>
              <div>Notifications</div>
            </MenuText>
            <SlideOutMenuArrow menuOpen={menuOpen && "menuOpen"}>
              <MenuIcon
                className='material-icons'
                title='Open Menu'
              >
                keyboard_arrow_right
              </MenuIcon>
            </SlideOutMenuArrow>
          </NotificationWrapper>
        </MenuItemWrapper>
        <MenuItemWrapper
          onClick={() => handleMenuLink("/member-settings")}
          menuOpen={menuOpen && "menuOpen"}
        >
          <MenuIcon
            className='material-icons'
            title='Settings'
          >
            settings
          </MenuIcon>
          <MenuText menuOpen={menuOpen && "menuOpen"}>Settings</MenuText>
        </MenuItemWrapper>
        <ExpandMenuWrapper id='tour-expandMenu'>
          <ExpandMenu
            menuOpen={menuOpen && "menuOpen"}
            onClick={props.toggleMenu}
          >
            <ExpandMenuIcon
              className='expandIcon'
              menuOpen={menuOpen && "menuOpen"}
              title='Collapse menu'
            >
              <div className='top collapse-BGcolor'></div>
              <div className='middleWrapper'>
                <div className='middle collapse-BGcolor'></div>
                <div className='middle-triangle collapse-border-color'></div>
              </div>
              <div className='bottom collapse-BGcolor'></div>
            </ExpandMenuIcon>
            <CollapseMenuText
              className='collapse-color'
              menuOpen={menuOpen && "menuOpen"}
            >
              Collapse
            </CollapseMenuText>
          </ExpandMenu>
        </ExpandMenuWrapper>
        <SlideOutMenu slideOutOpen={slideOutOpen && "slideOutOpen"}>
          <CloseBtn onClick={() => toggleSlideOutMenu(false)}>
            <span className='material-icons'>close</span>
          </CloseBtn>
          {notificationsOpen && (
            <SlideOutContent>
              <Notifications unreadMessages={props.unreadMessages} />
            </SlideOutContent>
          )}
          {profileOpen && (
            <SlideOutContent>
              <CompanyProfile
                toggleSlideOutMenu={toggleSlideOutMenu}
                {...props}
              ></CompanyProfile>
            </SlideOutContent>
          )}
        </SlideOutMenu>
      </BottomMenu>
    </MenuWrapper>
  );
};

export default Menu;
