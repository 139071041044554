import styled from "styled-components";

const FbPreviewCTA = styled.button`
  background-color: ${props => (props.PH ? "lightblue" : "#eff1f3")};
  width: ${props => (props.PH ? "65px" : "auto")}
  color: #4b4f56;
  /* grid-column: 13 / -1;
  grid-row: ${props => (props.PH ? "14" : "13 / span 2")}; */
  border: 1px solid #bec3c9;
  border-radius: 2px;
  font-size: 12px;
  font-weight: bold;
  height: auto;
  padding: 0 10px;
  line-height: 26px;
  box-sizing: content-box;
  text-shadow: none;
  vertical-align: middle;
  /* box-shadow: rgba(0, 0, 6, 0.1) 0px 0px 9px 0px; */

  h4 {
    margin: 0;
  }
`;

export default FbPreviewCTA;
