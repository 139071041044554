import React from "react";
import styled from "styled-components";
import { getWorkflowById } from "../../../utils/WorkflowAPI";
import SelectTrigger from "./SelectTrigger";
import SelectAction from "./SelectAction";
import Filter from "./Filter";
import Actions from "./Actions";
import WorkflowComplete from "./WorkflowComplete";
import { updateWorkflow } from "../../../utils/WorkflowAPI";

import { Button } from "../../styled/Buttons";
import {
  FlowCard,
  FlowHeader,
  Icon,
  H2,
  AddAction,
  ActionOptions,
  ActionType,
  ActionDelete,
  WorkflowErrorNoCard
} from "./styles";

import { IntegrationCard } from "../../styled/Integrations";
// import SendMessage from './EditSMS';
import ConnectIntegration from "./ConnectIntegration";

const FlowWrapper = styled(IntegrationCard)`
  padding-top: 0;
  background: transparent;
  box-shadow: none;
`;

const WorkflowOptions = props => {
  const {
    workflow,
    showErrors,
    errors,
    resetValidate,
    userProfile,
    userCompany,
    updateWorkflowState
  } = props;
  const [currentWorkflowId, setCurrentWorkflowId] = React.useState("");
  const [selectNewAction, setNewActionStatus] = React.useState(false);
  const [withinActionGroup, setWithinActionGroup] = React.useState(false);
  const [activeStepNo, setCurrentStepNo] = React.useState(0);
  const [actionGroup, setActionGroup] = React.useState([]);

  React.useEffect(() => {
    const { _id: workflowId } = workflow;
    setCurrentWorkflowId(workflowId);
    if (workflow.actions && Array.isArray(workflow.actions)) {
      organizeActionGroup(workflow);
    }
  }, [workflow]);

  const organizeActionGroup = workflowObject => {
    const newActions = workflowObject.actions;
    newActions.sort((a, b) => a.stepNo - b.stepNo);
    const groupedActions = newActions.reduce((r, a) => {
      r[a.stepNo] = [...(r[a.stepNo] || []), a];
      return r;
    }, []);
    setActionGroup(groupedActions);
  };

  const toggleActionModal = (isActive, isWithinActionGroup, currentStepNo) => {
    setCurrentStepNo(currentStepNo);
    if (isWithinActionGroup || !isActive) {
      setWithinActionGroup(isWithinActionGroup);
    }
    setNewActionStatus(isActive);
  };

  const updateTrigger = async triggerType => {
    const update = await updateWorkflow(currentWorkflowId, triggerType);
    updateWorkflowState(update);
  };

  const updateFilters = async filters => {
    const update = await updateWorkflow(currentWorkflowId, filters);
    updateWorkflowState(update);
  };

  const addNewAction = async (type, stepNo) => {
    resetValidate("actions");
    const flattenActions = actionGroup.flat(1);
    let action = {};
    switch (type) {
      case "DELAY":
        action = {
          type: "DELAY",
          stepNo,
          body: "",
          delayInterval: "DAY",
          delayMinutes: 1440,
          header: "",
          subject: ""
        };
        break;
      case "SMS":
        action = {
          type: "SMS",
          stepNo,
          body: "",
          delayInterval: "",
          delayMinutes: 0,
          header: "",
          subject: "",
          mediaUrl: []
        };
        break;
      default:
        return {};
    }
    let newActions = flattenActions.map(item => {
      if (item.stepNo > action.stepNo) {
        return { ...item, stepNo: item.stepNo + 1 };
      } else {
        return item;
      }
    });
    newActions = {
      actions: [...newActions, { ...action, stepNo: action.stepNo + 1 }]
    };
    const update = await updateWorkflow(currentWorkflowId, newActions);
    updateWorkflowState(update);
    organizeActionGroup(update);
    toggleActionModal(false, false);
  };

  const updateActions = async action => {
    const flattenActions = actionGroup.flat(1);
    const filteredActions = flattenActions.filter(
      item => item.stepNo !== action.stepNo
    );
    const newActions = { actions: [...filteredActions, action] };
    const update = await updateWorkflow(currentWorkflowId, newActions);
    updateWorkflowState(update);
    organizeActionGroup(update);
  };

  const deleteAction = async action => {
    const flattenActions = actionGroup.flat(1);
    const filteredActions = flattenActions.filter(
      item => item.stepNo !== action.stepNo
    );
    let newActions = filteredActions.map(item => {
      if (item.stepNo > action.stepNo) {
        return { ...item, stepNo: item.stepNo - 1 };
      } else {
        return item;
      }
    });
    newActions = { actions: newActions };
    const update = await updateWorkflow(currentWorkflowId, newActions);
    updateWorkflowState(update);
    organizeActionGroup(update);
  };

  const updateEndActions = async endActions => {
    const newEndActions = { endActions: [...endActions] };
    const update = await updateWorkflow(currentWorkflowId, newEndActions);
    updateWorkflowState(update);
  };

  return (
    <FlowWrapper displayAll layoutPosition='full'>
      <SelectTrigger
        triggerType={workflow.triggerType}
        updateTrigger={updateTrigger}
        errors={errors}
        resetValidate={resetValidate}
      />
      <Filter
        filterID={workflow.filterID}
        changeFilter={props.changeFilter}
        filters={workflow.filters}
        updateFilters={updateFilters}
        userProfile={userProfile}
        userCompany={userCompany}
        errMessages={showErrors ? errors : {}}
        onFilterClose={props.onFilterClose}
        setOverlapIgnore={props.setOverlapIgnore}
      />
      <AddAction>
        <span
          onClick={() => toggleActionModal(true, false, 0)}
          className='material-icons-outlined'
        >
          add_circle
        </span>
      </AddAction>
      {errors.actions &&
        errors.actions.some(error => error.noCard === true) && (
          <WorkflowErrorNoCard>{errors.actions[0].message}</WorkflowErrorNoCard>
        )}
      {actionGroup &&
        actionGroup.length > 0 &&
        actionGroup.map((actions, index) => {
          const { stepNo } = actions[0];
          return (
            <div key={index}>
              <Actions
                actions={actions}
                updateActions={updateActions}
                deleteAction={deleteAction}
                errors={errors}
                resetValidate={resetValidate}
                workflowActive={workflow.status === "ACTIVE"}
                alert={props.alert}
              ></Actions>
              <AddAction>
                <span
                  onClick={() => toggleActionModal(true, false, stepNo)}
                  className='material-icons-outlined'
                >
                  add_circle
                </span>
              </AddAction>
            </div>
          );
        })}
      <WorkflowComplete
        userCompany={userCompany}
        endActions={workflow.endActions}
        updateEndActions={updateEndActions}
        errors={errors}
        resetValidate={resetValidate}
      />
      <SelectAction
        selectNewAction={selectNewAction}
        addNewAction={addNewAction}
        toggleActionModal={toggleActionModal}
        withinActionGroup={withinActionGroup}
        activeStepNo={activeStepNo}
        {...props}
      ></SelectAction>

      {workflow.status === "ACTIVE" ? (
        <Button onClick={props.toggleActive} solid>
          Set Inactive
        </Button>
      ) : (
        <Button onClick={props.toggleActive}>Activate</Button>
      )}
    </FlowWrapper>
  );
};

export default WorkflowOptions;
