import { useEffect } from "react";
import styled from "styled-components";
import IL from "../../images/IL.svg";

const But = styled.button`
  border-radius: 100%;
  height: 60pt;
  width: 60pt;
  position: fixed;
  bottom: 76px;
  z-index: 1000;
  right: 10px;
  @media screen and (min-width: 979px) {
    height: 60px;
    width: 60px;
    bottom: 20px;
  }
  &:hover {
    height: 62pt;
    width: 62pt;
    bottom: 75px;
    right: 9px;
    @media screen and (min-width: 979px) {
      height: 62px;
      width: 62px;
      bottom: 19px;
    }
  }
`;

const IntercomLauncher = props => {
  const { path } = props;
  useEffect(() => {
    window.Intercom("update");
  }, [path]);

  return path === "/messages" ? (
    <></>
  ) : (
    <But id='custom_intercom'>
      <img src={IL} alt='Intercom Launcher' />
    </But>
  );
};

export default IntercomLauncher;
