import styled from "styled-components";

const FbPreviewSponsoredAd = styled.h5`
  color: #606770;
  grid-column: 3 / 9;
  grid-row: 3;
  text-align: left;
  margin: -6px 0 0 0;
`;

export default FbPreviewSponsoredAd;
