import React from "react";
import styled from "styled-components";
import DotsBrickLoader from "../../../elements/DotsBrickLoader";
import { Button, TxtButton } from "../../../styled/Buttons";

const ButtonWrapper = styled.div`
  min-height: 68px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  @media only screen and (min-width: 901px) {
    grid-column: 1/3;
  }
`;

const Loading = styled.div`
  margin: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BricksContainer = styled.div`
  position: relative;
  width: 30px;
`;

const Icon = styled.i`
  font-size: 40px;
  color: ${props => props.theme.HC1}
  opacity: 1;
  transition: all 0.3s ease-in-out;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  &.invisible {
    opacity: 0;
  }
`;

const ButtonActions = props => {
  const { 
    changed, loading, success
  } = props;
  
  return (
    <ButtonWrapper>
      {changed && !loading ? (
        <div>
          <TxtButton onClick={props.handleReset}>Cancel</TxtButton>
          <Button onClick={props.handleSave}>Save</Button>
        </div>
      ) : loading ? (
        <Loading>
          <BricksContainer>
            <DotsBrickLoader darkMode={true} />
          </BricksContainer>
        </Loading>
      ) : null }
      <Icon className={`material-icons ${success ? 'visible' : 'invisible'}`}>
        check_circle
      </Icon>
    </ButtonWrapper>
  );
};

export default ButtonActions;
