import React from "react";
import styled from "styled-components";
import LoadingBar from "../elements/LoadingBar";
import ImageWrapper from "../styled/ImageWrapper";

const SelectableVideoWrapper = styled.div`
  position: relative;
  img {
    height: 6em;
    margin: 1em;
    cursor: pointer;
    box-shadow: ${props =>
      props.selected ? "0px 0px 2px 5px #fbb74c" : "none"};
  }
`;

const SelectableImagePlay = styled.div`
  position: absolute;
  overflow: hidden;
  cursor: pointer;
  background-color: RGBA(191, 191, 191, 0.6);
  padding: 0.1em 0.2em;
  width: 2em;
  height: 1.6em;
  top: 1em;
  left: 1em;
  transition: all ease 0.5s;

  :hover {
    background-color: RGBA(191, 191, 191, 1);
    transition: all ease 0.5s;
  }
`;

const StockVideosPane = props => {
  const { show, freeVideos, selectedVideo, companyId, loading } = props;

  const handlePreviewOpen = data => {
    if (data.public_id) {
      props.handlePreviewOpen({
        previewIsOpen: true,
        previewItem: data.public_id
      });
    } else {
      props.handlePreviewOpen({
        previewIsOpen: true,
        previewItem: data.name
      });
    }
  };

  const handleStockVideoSelected = async video => {
    const newVideo = {
      name: video.public_id,
      format: video.format,
      type: "VIDEO",
      status: "ACTIVE",
      height: video.height,
      width: video.width
    };
    if (
      selectedVideo &&
      (selectedVideo.name === newVideo.name ||
        selectedVideo.public_id === video.public_id)
    ) {
      props.handleStockVideoSelected(null);
    } else {
      props.handleStockVideoSelected(newVideo);
    }
  };

  return (
    show && (
      <ImageWrapper>
        {/* displaying a thumbnail of all the stock videos stored in state.freeVideos */}
        {loading ? (
          <LoadingBar />
        ) : (
          freeVideos.map(data => (
            <SelectableVideoWrapper
              selected={selectedVideo && data.public_id === selectedVideo.name}
              key={data.public_id}
            >
              <SelectableImagePlay onClick={() => handlePreviewOpen(data)}>
                <i className='material-icons md-dark md-24'>
                  play_circle_filled_white
                </i>
              </SelectableImagePlay>
              <img
                src={`${process.env.REACT_APP_CLOUDINARY_VIDEO_BASEURL}/upload/c_scale,h_96,q_auto/${data.public_id}.jpg`}
                key={data.public_id}
                id={data.public_id + "." + data.format}
                className={"selectableImage " + (data.public_id + data.format)}
                onClick={() => handleStockVideoSelected(data)}
                alt={data.public_id}
              />
            </SelectableVideoWrapper>
          ))
        )}
      </ImageWrapper>
    )
  );
};

export default StockVideosPane;
