import axios from "axios";
import { axiosResponse, errorHandler } from "./Helpers";

const BP_API = process.env.REACT_APP_BP_API;

export const getUserById = async (uid) => {
  try {
    const user = axiosResponse(await axios.get(`${BP_API}/v1/user/${uid}`));
    return user;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const userCreate = async ({ fId, newUser, fireToken }) => {
  try {
    const user = axiosResponse(
      await axios.post(`${BP_API}/v1/user`, {
        user: { ...newUser, fId },
        idToken: fireToken
      })
    );
    return user;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const userUpdate = async (userDoc, config) => {
  try {
    if (config) {
      const user = axiosResponse(
        await axios.put(`${BP_API}/v1/user/${userDoc._id}`, userDoc, config)
      );
      return user;
    } else {
      const user = axiosResponse(
        await axios.put(`${BP_API}/v1/user/${userDoc._id}`, userDoc)
      );
      return user;
    }
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const userAuth = async (token) => {
  try {
    const auth = axiosResponse(
      await axios.post(`${BP_API}/v1/auth`, { token })
    );
    return auth;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const userLogout = async () => {
  try {
    const auth = axiosResponse(await axios.delete(`${BP_API}/v1/auth`));
    return auth;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};
