import React from "react";
import styled, { keyframes } from "styled-components";

const Keyframe = keyframes`
  0%, 20%, 80%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
}
`;
const FixedDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ParentDiv = styled.div`
  transform: translate(50%, 0);
  margin-top: ${props => (props.toggle ? "-39px" : "-21px")};
`;
const ChildDiv = styled.div`
  position: absolute;
  width: 3px;
  height: 3px;
  background: #383838;
  border-radius: 50%;
  animation: ${Keyframe} 1.2s linear infinite;

  &:nth-child(1) {
    animation-delay: 0s;
    top: 0;
    left: 11px;
  }
  &:nth-child(2) {
    animation-delay: -0.1s;
    top: -5px;
    left: 9px;
  }
  &:nth-child(3) {
    animation-delay: -0.2s;
    top: -9px;
    left: 5px;
  }
  &:nth-child(4) {
    animation-delay: -0.3s;
    top: -11px;
    left: 0;
  }
  &:nth-child(5) {
    animation-delay: -0.4s;
    top: -9px;
    left: -5px;
  }
  &:nth-child(6) {
    animation-delay: -0.5s;
    top: -5px;
    left: -9px;
  }
  &:nth-child(7) {
    animation-delay: -0.6s;
    top: 0;
    left: -11px;
  }
  &:nth-child(8) {
    animation-delay: -0.7s;
    top: 5px;
    left: -9px;
  }
  &:nth-child(9) {
    animation-delay: -0.8s;
    top: 9px;
    left: -5px;
  }
  &:nth-child(10) {
    animation-delay: -0.9s;
    top: 11px;
    left: 0;
  }
  &:nth-child(11) {
    animation-delay: -1s;
    top: 9px;
    left: 5px;
  }
  &:nth-child(12) {
    animation-delay: -1.1s;
    top: 5px;
    left: 9px;
  }
`;
const Message = styled.span`
  position: absolute;
  background-color: rgba(250, 250, 250);
  padding: 0.5em;
  font-weight: 600;
  font-style: italic;
  width: 30em;
  max-width: 90%;
  right: 50vw;
  top: 50vh;
  transform: translate(50%, 0);
`;

export class LoaderDotsSmall extends React.Component {
  render() {
    const { message, toggle } = this.props;
    return (
      <FixedDiv className='loader'>
        <ParentDiv toggle={toggle}>
          <ChildDiv />
          <ChildDiv />
          <ChildDiv />
          <ChildDiv />
          <ChildDiv />
          <ChildDiv />
          <ChildDiv />
          <ChildDiv />
          <ChildDiv />
          <ChildDiv />
          <ChildDiv />
          <ChildDiv />
        </ParentDiv>
        {!!message && <Message>{message}</Message>}
      </FixedDiv>
    );
  }
}
