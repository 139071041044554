import React, { useState, createContext } from "react";
// @ts-ignore: Unreachable code error
//TODO: on migration from react-scripts (CRA), fix import.
import { motion } from "framer-motion/dist/framer-motion";
import styled from "styled-components";

import Bubble from "./Bubble";

const BWrap = styled(motion.div)`
  position: absolute;
  top: ${props =>
    props.dir === "top" ? "-40px" : props.dir === "bottom" ? "30px" : "0"};
  left: ${props =>
    props.dir === "left" ? "-30%" : props.dir === "right" ? "125%" : "50%"};
  transform: translate(-30%, 0);
  pointer-events: none;
`;
const Wrap = styled.div`
  position: relative;
  display: inline-block;
  align-content: center;
  justify-content: center;
`;

const BubbleWrap = (props: BubbleWrapProps) => {
  const { dir, text, children } = props;
  const [opacity, setOpacity] = useState(0);

  const showBubble = async () => {
    try {
      setOpacity(1);
      await new Promise(resolve => setTimeout(resolve, 2000));
      setOpacity(0);
    } catch {
      setOpacity(0);
    }
  };

  return (
    <Wrap>
      <BWrap
        dir={dir}
        animate={{ opacity: opacity }}
        initial={false}
      >
        <Bubble
          text={text}
          dir={dir}
        />
      </BWrap>
      <BubbleContext.Provider value={showBubble}>
        {children}
      </BubbleContext.Provider>
    </Wrap>
  );
};

export type BubbleWrapProps = {
  dir?: "top" | "bottom" | "left" | "right";
  text: string;
  children: React.ReactNode;
};
export const BubbleContext = createContext<undefined | (() => Promise<void>)>(
  undefined
);
export default BubbleWrap;
