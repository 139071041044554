/* global FB */
import React from "react";
import Modal from "react-modal";
import styled from "styled-components";
import * as _ from "underscore";
// import ReactPlayer from 'react-player';
import WistiaPlayer from "react-player/lib/players/Wistia";
import { errorHandler } from "../../../../utils/Helpers";
import BpModal from "../../../elements/BpModal";
import { LoaderDotsSmall } from "../../../elements/Loaders";
import ToggleBtn from "../../../elements/ToggleBtn";
import BusinessManagerConnect from "../BusinessManagerConnect";

// Styled Components
import { ModalButtonWrapper } from "../../../styled/Buttons";
import Button from "../../../elements/Button";
import CheckToggleWrapper from "../../../styled/CheckToggleWrapper";
import SocialSelect from "../../../styled/SocialSelect";
// Modal Styling
import {
  adAccountCreate,
  businessPageCreate,
  businessPageUpdate,
  facebookGetInfo,
  getLongLivedToken,
  leadGenStatusCheck,
  pageDelete,
  pagePermissionsRequest,
  permissionsDelete,
  platformTokenDelete,
  platformTokenUpdate,
  requiredPermissions, myPagesGet
} from "../../../../utils/FacebookAPI";
import { integrationCreate } from "../../../../utils/IntegrationsAPI";
import { fbPagesModal } from "../../../styled/ModalStyles";
import H3 from "../../../styled/H3";
import PagesList from "./PagesList";

const FbPageHeader = styled.div`
  box-shadow: rgba(60, 64, 67, 0.08) 0px 1px 1px 0px,
    rgba(60, 64, 67, 0.16) 0px 1px 3px 1px;
  padding: 0.3rem 2rem;
  position: relative;
`;

const FbPageMain = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 44vh;
  padding: 16px;
  background: #f5f5f5;
  position: relative;
`;

const MoreArrow = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  bottom: ${props => (props.footerHeight ? `${props.footerHeight}px` : "auto")};
  top: ${props => (props.headerHeight ? `${props.headerHeight}px` : "auto")};
  z-index: 1000;
  pointer-events: none;
  i {
    background: #675da9;
    color: white;
    /* border-radius: 7px; */
    border-top-left-radius: ${props => (props.footerHeight ? "7px" : "0")};
    border-top-right-radius: ${props => (props.footerHeight ? "7px" : "0")};
    border-bottom-right-radius: ${props => (props.headerHeight ? "7px" : "0")};
    border-bottom-left-radius: ${props => (props.headerHeight ? "7px" : "0")};
    opacity: 0.3;
    cursor: pointer;
    display: block;
    width: 29px;
    margin: 0 auto;
    transition: all 0.3s;
    pointer-events: all;
    :hover {
      opacity: 1;
    }
  }
`;

const FbPageFooter = styled.div`
  box-shadow: rgba(60, 64, 67, 0.08) 0px 1px 1px 0px,
    rgba(60, 64, 67, 0.16) 0px 1px 3px 1px;
  position: relative;
`;

const Icon = styled.i`
  position: relative;
  top: 2px;
  font-size: 1em;
  color: darkgray;
  &:hover {
    color: black;
  }
`;

const PagingButton = styled.span`
  cursor: pointer;
  padding: 3px 6px;
  display: inline-block;
  color: black;
  &:hover {
    color: #675da8;
  }
`;

const MissingPagesWrapper = styled.div`
  font-size: small;
  margin: auto;
`;

const MissingPagesTitle = styled.div`
  font-size: small;
  max-width: 30rem;
  margin: auto;
  p {
    margin-top: 0;
  }
`;

const MissingPagesBody = styled.div`
  font-size: small;
  max-width: 25rem;
  margin: auto;
  p {
    box-shadow: rgba(0, 0, 6, 0.1) 0px 0px 9px 0px;
    padding: 1rem;
    margin: 1.5rem 0;
  }
`;

const RemoveAllFBPermissions = styled.button`
  color: rgb(104, 93, 169);
  font-weight: 300;
  font-size: 0.8em;
  font-style: italic;
  margin-top: 0;
  outline: ${props =>
    props.outlined ? "1px solid rgb(104, 93, 169)" : "none"};
  padding: ${props => (props.outlined ? "6px 0" : "0")};
`;

const ModalTxtWrapper = styled.p`
  margin: 20px;
`;

const ModalHelpVideoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;

  @media screen and (max-width: 979px) {
    align-items: center;
    flex-flow: column;
  }
`;

const ModalHelpVideoWrapper = styled.div`
  width: 400px;
  margin: 0 2rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media screen and (max-width: 510px) {
    max-width: 95%;
  }
`;

const ModalHelpVideo = styled.div`
  position: relative;
  padding-top: 56.25%;
`;

const ModalImg = styled.img`
  max-width: 100%;
`;

const Ul = styled.ul`
  list-style: disc;
  list-style-position: outside;
  text-align: left;
  max-width: 400px;
  margin: 0 auto;
`;

const Link = styled.a`
  color: ${props => (props.solid ? "rgb(255, 255, 255)" : "#675da8")};
  background-color: ${props =>
    props.solid ? "#675da8" : "rgb(255, 255, 255)"};
  display: inline-block;
  border-radius: 1.2rem;
  height: 2.3rem;
  padding: 0.4rem 1.5rem;
  min-width: 7rem;
  margin: ${props => (props.customMargin ? props.customMargin : "1rem")};
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  border: 2px solid #675da9;
  font-size: 15px;
  letter-spacing: 1px;
  :disabled {
    color: ${props => (props.solid ? "rgb(255, 255, 255)" : "lightgrey")};
    background-color: ${props =>
      props.solid ? "lightgrey" : "rgb(255, 255, 255)"};
    border: 1.5px solid lightgrey;
  }
`;

const FbBusinessPageImg = styled.img`
  border-radius: 50%;
  margin-right: 20px;
`;

const CopyWrapper = styled.p`
  background-color: ${props => (props.bgColor ? props.bgColor : "#f7f7f7")};
  border: ${props => (props.border ? props.border : "2px solid lightgrey")};
  height: 2.5em;
  border-radius: 3px;
  margin: 0 auto;
  font-size: 16px;
  width: 100%;
  max-width: 480px;
  display: grid;
  grid-template-columns: 1fr 110px;
  align-items: center;
  text-align: left;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  color: ${props => (props.readOnly ? "#828282" : "inherit")};
  cursor: auto;
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : "15px")};
  ${props => props.customBackground && `background: ${props.customBackground};`}
`;

const CopyTarget = styled.input`
  border: none;
  background: transparent;
`;

const CopyButton = styled.button`
  background: #675da9;
  color: white;
  padding: 11px;
  border-radius: 0 2px 2px 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
`;

const SelectedPageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 13px;
  img {
    width: 50px;
    margin-right: 5px;
  }
`;
const Warning = styled.span`
display:flex;
justify-content:center;
align-content:center;
font-size:15px;
cursor: pointer;
gap: 5px;
`;

const permissions = {
  adsManagement: {
    name: "Ads Management",
    description: "Needed to create ads and show results"
  },
  businessManagement: {
    name: "Business Management",
    description: "Needed to manage your ad account and create ads"
  },
  email: {
    name: "Email",
    description: "Needed to remember your information"
  },
  leadsRetrieval: {
    name: "Leads Retrieval",
    description: "Needed to send you your leads"
  },
  managePages: {
    name: "Manage Pages",
    description:
      "Needed to get details about your Business Page and send you leads"
  },
  pagesManageAds: {
    name: "Manage Page Ads",
    description: "Needed to be able to publish ads on behalf of your page"
  },
  pagesManageMetadata: {
    name: "Manage Page Metadata",
    description: "Needed to send you your leads"
  },
  pagesShowList: {
    name: "Show Pages List",
    description: "Needed so you can select which page to advertise on behalf of"
  },
  publicProfile: {
    name: "Public Profile",
    description: "Needed to show the ad preview when creating and viewing ads"
  },
  pagesReadEngagement: {
    name: "Read Page Engagement",
    description: "Needed to send you your leads"
  },
  pagesReadUserContent: {
    name: "Read User Content",
    description: "Needed to send you your leads"
  }
};

class FbConnect extends React.Component {
  pagesListRef = React.createRef();
  pagesContainerRef = React.createRef();
  pagesHeaderRef = React.createRef();
  pagesFooterRef = React.createRef();
  state = {
    fbStatus: false,
    pagesList: [],
    openPagesModal: false,
    selectedFBPage: null,
    selectedFBImage: null,
    selectedFBToken: null,
    selectedFBName: null,
    selectedFBBusiness: {},
    loading: false,
    fbStatusLoading: false,
    modalPageUnpublished: false,
    modalPermissionsConfirm: false,
    permissionsCorrect: true,
    modalPermissionMissing: false,
    modalDisconnectConfirm: false,
    businessManagementMissing: false,
    businessManagerConnectOpen:false,
    businessManager:{id:'',name:'',image:''},
    businessPageConnectStatus:'',
    modalFbConnectVideo: false,
    modalPermissionError: false,
    moreBelow: false,
    moreAbove: false,
    headerHeight: 0,
    footerHeight: 0,
    leadgenTosModalOpen: false,
    paging: {
      after: "",
      before: "",
      showAfter: false,
      showBefore: false
    },
    copySuccess: false,
    showDeletePermissions: false
  };

  componentDidMount = async () => {
    // TODO: change this to only check for access_token expiry and warn to extend if expiring soon.
    // Do not check login status on each component mount only if it's true in DB. Leave in initialization
    // of the FB class.
    window.fbAsyncInit = () => {
      FB.init({
        appId: "511351665866139",
        cookie: true,
        xfbml: true,
        version: "v8.0"
      });
      FB.AppEvents.logPageView();
    };
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");

    const pages = await myPagesGet();
    console.log(pages);
    if(pages.length > 0) this.setState({businessManager:pages[0]?.business, businessPageConnectStatus:pages[0].status});
  };

  componentDidUpdate = prevProps => {
    const { businessPage = {} } = this.props;
    const { fbStatus } = this.state;
    const newFbStatus = businessPage.pageId ? true : false;
    if (newFbStatus !== fbStatus) {
      this.setState({ fbStatus: newFbStatus });
    }

    // if (
    //   fbAllPermissionsGranted !== prevProps.userProfile.fbAllPermissionsGranted
    // ) {
    //   if (fbAllPermissionsGranted) {
    //     this.setState({ modalPermissionMissing: false });
    //   } else if (!fbStatusLoading) {
    //     this.setState({ modalPermissionMissing: true });
    //   }
    // }
  };

  handleToggleChange = (e, confirm) => {
    const {
      userCompany = {},
      businessPage = {},
      platformToken = {},
      alert
    } = this.props;
    const { name: companyName = "" } = userCompany;
    try {
      if (platformToken.token && businessPage.pageToken) {
        if (!confirm) {
          this.setState({ modalDisconnectConfirm: true });
          return;
        }
        this.setState({
          fbStatusLoading: true,
          modalDisconnectConfirm: false
        });
        this.fbLogout();
      } else {
        if (!companyName) {
          alert({
            type: "error",
            msg: "Company Name is missing. Please add your company name and try again.",
            timeout: 10000
          });
          return;
        } else {
          this.setState({ fbStatusLoading: true });
          this.fbLogin();
        }
      }
    } catch (err) {
      console.log("error toggling facebook status", err.message);
      if (err.message === "FB is not defined") {
        alert({
          type: "error",
          msg: "Error loading Facebook authentication. This could be caused by a web browser extension. You can try using your browsers private/incognito mode, or try using another web browser for this process.",
          timeout: 0
        });
      }
      // this.setState({ fbStatusLoading: false, fbStatus });
    }
  };

  fbLogin = () => {
    FB.login(
      response => {
        // handle the response
        if (
          response &&
          !response.error &&
          response.authResponse !== null &&
          response.authResponse !== undefined
        ) {
          this.setAccount(response);
        } else {
          const { alert } = this.props;
          alert({
            type: "error",
            msg: "The login process was exited before a Facebook account was linked. Please try again."
          });
          console.warn(
            "The login process was aborted. This could be due to user initiated cancel."
          );
          this.setState({ fbStatusLoading: false, fbStatus: false });
        }
      },
      {
        scope:
          "email,ads_management,pages_manage_ads,pages_manage_metadata,pages_show_list,business_management,leads_retrieval,pages_read_engagement,pages_read_user_content"
      }
    );
  };

  setAccount = async loginInfo => {
    console.log("loginInfo: ", loginInfo);
    if (loginInfo && !loginInfo.error) {
      let platformToken = {};
      try {
        const shortToken = loginInfo.authResponse.accessToken;
        platformToken = await getLongLivedToken(shortToken);
        console.log("long term token", platformToken);
      } catch (err) {
        const { status, message } = errorHandler(err);
        console.log("err", `${status}: ${message}`);
        this.props.alert({
          type: "error",
          msg: "There was an issue with the Facebook login process. Please try again."
        });

        this.setState({
          fbStatusLoading: false,
          fbStatus: false
        });
        return;
      }
      // if (process.env.REACT_APP_BP_ENV !== 'production') {
      //   return;
      // }
      FB.api("/me?fields=id,name,email,picture{url}", async responseData => {
        if (responseData && !responseData.error) {
          const profile = responseData;
          const {
            id: platformId,
            email = "undefined",
            picture: {
              data: { url: image }
            }
          } = profile;
          await platformTokenUpdate({
            _id: platformToken._id,
            platformId,
            image,
            email,
            token: platformToken.token // always include the token or it will be set to null
          });
          await this.props.getFacebookInfo();
          this.listAllPages();
          console.log("We set the account.");
        } else {
          console.log("We didn't set the account.", responseData.error);
          this.props.alert({
            type: "error",
            msg: "There was an issue with the Facebook login process. Please try again."
          });
          this.setState({
            fbStatusLoading: false
          });
        }
      });
    }
  };

  showPrevious = () => {
    let { paging } = this.state;
    paging.showBefore = true;
    paging = {
      ...paging,
      showBefore: true,
      showAfter: false
    };
    this.setState({ paging }, () => this.listAllPages());
  };

  showAfter = () => {
    let { paging } = this.state;
    paging.showAfter = true;
    paging = {
      ...paging,
      showBefore: false,
      showAfter: true
    };
    this.setState({ paging }, () => this.listAllPages());
  };

  listAllPages = () => {
    // const { fbUserID, fbAccessToken } = this.props.userProfile;
    const { token, platformId } = this.props.platformToken;
    let { paging } = this.state;
    console.log("FB User ID: " + platformId);
    if (platformId) {
      let params = {};
      if (paging.showAfter && paging.after) {
        params = {
          ...params,
          after: paging.after
        };
      }
      if (paging.showBefore && paging.before) {
        params = {
          ...params,
          before: paging.before
        };
      }
      FB.api(
        `/${platformId}/accounts`,
        {
          fields: "name_with_location_descriptor,picture,about,category,access_token,tasks,business,is_published",
          access_token: token,
          ...params
        },
        response => {
          if (response && !response.error) {
            /* handle the result */
            if (response.data.length > 0) {
              let pageArray = [];
              console.log("response", response);
              paging = {
                after: "",
                before: "",
                showAfter: false,
                showBefore: false
              };
              if (
                response.paging &&
                response.paging.previous &&
                response.paging.cursors.before
              ) {
                paging = {
                  ...paging,
                  before: response.paging.cursors.before
                };
              }
              if (
                response.paging &&
                response.paging.next &&
                response.paging.cursors.after
              ) {
                paging = {
                  ...paging,
                  after: response.paging.cursors.after
                };
              }
              pageArray = response.data;
              pageArray.forEach(page=>{page.name = page.name_with_location_descriptor});
              console.log("pageArray: ", pageArray);
              pageArray.sort((a, b) => {
                const aName = a.name.toLowerCase();
                const bName = b.name.toLowerCase();
                return aName.localeCompare(bName);
              });
              this.setState({
                paging,
                pagesList: pageArray,
                openPagesModal: true
              });
            } else {
              this.setState({
                openPagesModal: true
              });
            }
          } else {
            console.error(response.error);
            this.props.alert({
              type: "error",
              msg: `There was an error generating a list of Facebook Pages. Please refresh your page and try again.`
            });
          }
        }
      );
    } else {
      console.error(
        "There is no Facebook User ID for this account. How did it get this far?"
      );
    }
  };

  afterOpenModal = () => {
    const pagesHeight = this.pagesListRef.current.clientHeight;
    const headerHeight = this.pagesHeaderRef.current.clientHeight;
    const footerHeight = this.pagesFooterRef.current.clientHeight;
    // pages height is calculated by taking the total window height, multiplying by .44 (because the height of the element is set to 44% of the view height) and then subtracting the padding value.
    // window height * 44% of view height - padding
    const maxPagesHeight = window.innerHeight * 0.44 - 16;
    const newState = {
      headerHeight,
      footerHeight
    };
    if (pagesHeight > maxPagesHeight) {
      newState.moreBelow = true;
    }
    this.setState({ ...newState });
    this.pagesContainerRef.current.addEventListener("scroll", this.pagesScroll);
  };

  pagesScroll = () => {
    const pagesHeight = this.pagesListRef.current.clientHeight;
    const maxPagesHeight = window.innerHeight * 0.44 - 16;
    if (pagesHeight > maxPagesHeight) {
      // page list is taller than the max pages height
      const scrollTop = this.pagesContainerRef.current.scrollTop;
      const newState = {};
      if (scrollTop > 0) {
        newState.moreAbove = true;
      } else {
        newState.moreAbove = false;
      }
      if (pagesHeight - scrollTop < maxPagesHeight) {
        newState.moreBelow = false;
      } else {
        newState.moreBelow = true;
      }
      this.setState({ ...newState });
    }
  };

  handlePagesArrow = direction => {
    const maxPagesHeight = window.innerHeight * 0.44 - 16;
    let scrollTop = this.pagesContainerRef.current.scrollTop;
    if (direction === "up") {
      scrollTop = scrollTop - maxPagesHeight;
    } else if (direction === "down") {
      scrollTop = scrollTop + maxPagesHeight;
    }
    this.pagesContainerRef.current.scrollTop = scrollTop;
  };

  handleFBPageHighlight = data => {
    const { id, access_token, tasks = [], name, business } = data;
    console.log("page name", name);
    const { url } = data.picture.data;
    if (tasks.includes("MANAGE")) {
      this.setState({
        selectedFBPage: id,
        selectedFBToken: access_token,
        selectedFBImage: url,
        selectedFBName: name,
        selectedFBBusiness: business
      });
    }
  };

  requestPagePermissions = async () => {
    this.setState({ modalPageUnpublished: false, loading: true });
    // const { token } = this.state.platformToken;
    const { selectedFBPage, selectedFBToken, selectedFBImage } = this.state;
    const { businessPage: pageArray = [] } = await facebookGetInfo();
    try {
      let permissionReturn = {};
      const businessPage = pageArray[0] ? pageArray[0] : {};
      console.log("businessPage", businessPage);
      if (businessPage.pageId && (businessPage.pageId === selectedFBPage || !selectedFBPage)) {
        permissionReturn = await businessPageUpdate({
          ...businessPage,
          pageToken: selectedFBToken,
          image: selectedFBImage
        });
      } else {
        permissionReturn = await businessPageCreate({
          pageId: selectedFBPage,
          pageToken: selectedFBToken,
          image: selectedFBImage
        });
      }
      this.setState({businessPageConnectStatus:permissionReturn.status})
      if(permissionReturn.status ==="PENDING_BMA"){
        this.setState({
          businessManagerConnectOpen:true,
          businessManager:{
            ...permissionReturn.business
          },
          
          openPagesModal:false,
          fbStatusLoading: false,
            loading: false
        });
        this.props.getFacebookInfo();
      }
      else if (permissionReturn.pageToken) {
        try {
          const { businessPage: newPageArray = [] } = await facebookGetInfo();
          const newBusinessPage = newPageArray[0] ? newPageArray[0] : {};
          if (!newBusinessPage.pageToken) {
            throw new Error("Page token is missing");
          }
          await this.createAdAccount(newBusinessPage);
          this.setState({ fbStatusLoading: false, loading: false, businessPageConnectStatus:permissionReturn.status });
        } catch (err) {
          console.error(err.response);
          console.log("message is", err.response.data.message);
          console.log("user msg is", err.response.data.error_user_msg);
          this.setState({
            fbStatusLoading: false,
            loading: false
          });
          if (err.response.data.error_user_msg) {
            this.props.alert({
              type: "error",
              msg: err.response.data.error_user_msg
            });
          } else {
            this.props.alert({
              type: "error",
              msg: `There was an error connecting your facebook page to Boostpoint. Please refresh your page and try again.`
            });
          }
        }
      }
    } catch (err) {
      console.error("Facebook connect error", err.response);
      const { status, message } = errorHandler(err);
      console.log("err", `${status}:`, message);
      this.setState({
        fbStatusLoading: false,
        loading: false
      });
      if (err.response.data === "Page is not published") {
        this.props.alert({
          type: "error",
          msg: "Your page is not published. Please publish your page and then try to connect again."
        });
        this.setState({ modalPageUnpublished: true });
      } else if (err.response.data.message) {
        const msg = err.response.data.message;
        console.log("msg is", msg);
        if (msg.includes("Requires business_management permission")) {
          this.setState({
            businessManagementMissing: true,
            modalPermissionMissing: true
          });
        } else if (msg === "(#200) Permissions error") {
          this.setState({ modalPermissionError: true });
        } else {
          this.props.alert({ type: "error", msg });
        }
      } else if (err.response.data.error_user_msg) {
        this.props.alert({
          type: "error",
          msg: err.response.data.error_user_msg
        });
      } else {
        this.props.alert({
          type: "error",
          msg: `There was an error connecting your facebook page to Boostpoint. Please refresh your page and try again.`
        });
      }
    }
  };

  checkBMAPermissions = async () => {
    this.setState({fbStatusLoading: true });
    const { selectedFBPage } = this.state;
    const { businessPage: pageArray = [] } = await facebookGetInfo();
    try {
      let permissionReturn = {};
      const businessPage = pageArray[0] ? pageArray[0] : {};
      if (businessPage.pageId && (businessPage.pageId === selectedFBPage || !selectedFBPage)) {
        permissionReturn = await businessPageUpdate({
          ...businessPage,
        });
      }
      if(permissionReturn.status ==="CONNECTED"){
        this.setState({
          businessManager:{
            ...permissionReturn.business
          },
          businessPageConnectStatus:permissionReturn.status

        });
      }
    } catch (err) {
      console.error(err.response);
    }
    this.setState({fbStatusLoading: false });
  }

  createAdAccount = async businessPage => {
    const { userCompany } = this.props;
    const { pageId, pageToken, adAccountId } = businessPage;
    const { _id: companyId, name: companyName = "" } = userCompany;
    if (!adAccountId) {
      if (companyName) {
        try {
          this.setState({ openPagesModal: false, loading: false });
          const adAccountRes = await adAccountCreate({
            pageId,
            pageToken,
            companyName
          });
          await pagePermissionsRequest({
            fbBusinessPageToken: pageToken,
          },pageId);
          this.setState({
            fbStatusLoading: false,
            openPagesModal: false
          });
          integrationCreate({ type: "FACEBOOK", companyId });
          await this.props.getFacebookInfo();
          const leadGenCheck = await this.checkLeadgenTos();
          if (!leadGenCheck) {
            this.toggleLeadgenModal();
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        this.props.alert({
          type: "error",
          msg: "Company Name is missing. Please add your company name and try again. Remember to save your changes.",
          timeout: 10000
        });
        this.setState({
          fbStatusLoading: false
        });
      }
    } else {
      // TODO: possibly reset permissions? Is there a need to reset permissions?
      console.log(
        "An Ad Account already exists for this user. We're using the existing Ad Account on record but updating permissions."
      );
      await this.props.getFacebookInfo();
      const { selectedFBPage, selectedFBToken, selectedFBImage } = this.state;
      // const { businessPage: updatedBusinessPage } = this.props;
      try {
        const permissionReturn = await businessPageUpdate({
          ...businessPage,
          pageId: selectedFBPage,
          pageToken: selectedFBToken,
          image: selectedFBImage
        });
        integrationCreate({ type: "FACEBOOK", companyId });
        this.props.getFacebookInfo();
        const { userProfile } = this.props;
        const { settings } = userProfile;
        delete settings.facebookDisconnected;
        this.props.updateUserDoc({
          ...userProfile,
          settings: settings
        });
      } catch (err) {
        const { status, message } = errorHandler(err);
        console.log("err", `${status}:`, message);
      }

      this.setState({
        fbStatusLoading: false,
        fbStatus: true,
        openPagesModal: false
      });
      const permissionsGranted = await this.props.checkPermissions();
      if (!permissionsGranted) {
        this.setState({ modalPermissionMissing: true, fbStatusLoading: false });
      }
    }
  };

  fbLogout = async reason => {
    const { platformToken, businessPage, alert, userProfile } = this.props;
    const { settings = {} } = userProfile;
    this.setState({ businessManagementMissing: false,businessPageConnectStatus:''  });
    if (platformToken.token && businessPage.pageToken) {
      try {
        const tokenResponse = await platformTokenUpdate({
          ...platformToken,
          token: "",
          isValid: false
        });
        console.log("tokenResponse", tokenResponse);
        const pageResponse = await businessPageUpdate({
          ...businessPage,
          pageToken: "",
          image: ""
        });
        console.log("pageResponse", pageResponse);
        this.setState({
          fbStatus: false,
          fbStatusLoading: false,
          showDeletePermissions: true
        });
        this.deletePermissions();
        alert({
          type: "success",
          msg: "Facebook account successfully disconnected."
        });
        this.props.updateUserDoc({
          ...userProfile,
          settings: { ...settings, facebookDisconnected: true }
        });
        console.log("logged out successfully");
        this.props.getFacebookInfo();
        if (reason) {
          return "true";
        }
      } catch (err) {
        const { status, message } = errorHandler(err);
        console.log("err", `${status}: ${message}`);
        this.setState({
          fbStatusLoading: false
        });
      }
    } else {
      this.setState({
        fbStatusLoading: false
      });
      console.warn(
        "User is already logged out. Cannot initiate logout if already logged out."
      );
      if (reason) {
        Promise.resolve("not active");
      }
    }
  };

  deletePermissions = async () => {
    const { userProfile } = this.props;
    const { settings } = userProfile;
    
      const { userId } = this.props.platformToken;
      this.setState({
        fbStatusLoading: true,
        modalPermissionsConfirm: false,
        modalPermissionMissing: false
      });
      try {
        const deleteReturn = await permissionsDelete(userId);
        if (deleteReturn) {
          const {
            businessPage: { pageId },
            platformToken: { _id: tokenId }
          } = this.props;
          await pageDelete(pageId);
          await platformTokenDelete(tokenId);
          delete settings.facebookDisconnected;
          this.props.updateUserDoc({
            ...userProfile,
            settings: settings
          });
          this.props.alert({
            type: "success",
            msg: "All Boostpoint permissions have been removed."
          });
        } else {
          this.props.alert({
            type: "error",
            msg: "There was an issue removing permissions. Please refresh the page and try again. If you continue to receive this error, please contact us with the help chat or email us."
          });
          return false;
        }
      } catch (err) {
        console.log("attempt to delete permissions", err);
        return false;
      }
      this.setState({ fbStatusLoading: false });
      console.log("done deleting");
      return true;
    
  };

  fixPermissions = async () => {
    this.setState({ fbStatusLoading: true, modalPermissionMissing: false });
    try {
      await this.fbLogout("reconnect");
      this.setState({ modalPermissionsConfirm: true }, async () => {
        await this.deletePermissions();
        this.fbLogin();
      });
    } catch (err) {
      console.log("fix permission error", err);
    }
  };

  checkLeadgenTos = async () => {
    const { businessPage: { pageId, pageToken } = {} } = this.props;
    if (pageId && pageToken) {
      try {
        const checkAccepted = await leadGenStatusCheck(pageId);
        console.log("checkAccepted", checkAccepted);
        return checkAccepted;
      } catch (err) {
        if (err.response && err.response.data) {
          console.log("error checking lead gen tos", err.response.data);
        } else {
          console.log("error checking lead gen tos", err);
        }
        return false;
      }
    } else {
      return false;
    }
  };

  toggleLeadgenModal = () => {
    const { leadgenTosModalOpen } = this.state;
    this.setState({ leadgenTosModalOpen: !leadgenTosModalOpen });
  };

  submitLeadgenModal = async () => {
    this.setState({ leadGenLoading: true });
    const { alert } = this.props;
    try {
      const leadgenCheck = await this.checkLeadgenTos();
      if (leadgenCheck) {
        this.setState({ leadGenLoading: false, leadgenTosModalOpen: false });
        alert({
          type: "success",
          msg: "Facebook Leadgen Terms of Service accepted. You may now create Lead Ads."
        });
      } else {
        console.log("nothing returned");
        this.setState({ leadGenLoading: false });
        alert({
          type: "error",
          msg: (
            <p>
              Facebook Leadgen Terms of Service were not accepted. Please try
              again. If you continue to get this message, please contact
              Boostpoint Support at{" "}
              <a href='mailto:help@boostpoint.com'>help@boostpoint.com</a>
            </p>
          )
        });
      }
    } catch (err) {
      if (err.response && err.response.data) {
        console.log("error checking lead gen tos", err.response.data);
      } else {
        console.log("error checking lead gen tos", err);
      }
    }
  };

  copyToClipboard = (e, text) => {
    navigator.permissions.query({ name: "clipboard-write" }).then(result => {
      if (result.state === "granted" || result.state === "prompt") {
        navigator.clipboard.writeText(text).then(
          () => {
            this.setState({ copySuccess: true }, () => {
              setTimeout(() => {
                this.setState({ copySuccess: false });
              }, 3000);
            });
          },
          () => {
            this.props.alert({
              type: "error",
              msg: "We could not copy the text to your clipboard."
            });
          }
        );
      }
    });
    e.target.focus();
  };

  render() {
    const {
      fbStatusLoading,
      modalPermissionMissing,
      modalPermissionsConfirm,
      modalDisconnectConfirm,
      openPagesModal,
      leadgenTosModalOpen,
      paging,
      selectedFBBusiness = {},
      fbStatus,
      pagesList,
      loading,
      businessPageConnectStatus,
      businessManager
    } = this.state;
    const {
      platformToken = {},
      businessPage = {},
      platformPermissions = {},
      userProfile = {}
    } = this.props;
    const { token, platformId } = platformToken;
    const { image, name: fbBusinessPageName, pageToken } = businessPage;
    const { settings: { facebookDisconnected = false } = {} } = userProfile;
    let fbAllPermissionsGranted = true;
    const missingPermissions = [];
    const permissionReturn = _.mapObject(
      platformPermissions.find(p => p.platform === "Facebook"),
      (val, key) => {
        if (requiredPermissions.includes(key) && !val) {
          fbAllPermissionsGranted = false;
          if (permissions[key]) {
            missingPermissions.push(
              `${permissions[key].name} missing: ${permissions[key].description}`
            );
          }
        }
      }
    );
    const missingPermissionsDisplay = missingPermissions.join(", ");
    return (
      <div>
        <SocialSelect>
          <p style={{ margin: 0 }}>Facebook</p>
          <CheckToggleWrapper>
            <ToggleBtn
              onChange={this.handleToggleChange}
              on={token && pageToken ? true : false}
              disabled={fbStatusLoading}
              loading={fbStatusLoading}
              showLabels={false}
              pending={businessPageConnectStatus==="PENDING_BMA"}
            />
          </CheckToggleWrapper>
          {token && pageToken && businessPageConnectStatus==="PENDING_BMA" ? 
            <Button icon='refresh' onClick={this.checkBMAPermissions}/>
         : <></>}
        </SocialSelect>
        {token && pageToken && businessPageConnectStatus==="PENDING_BMA" ? 
            <Warning onClick={()=>{this.setState({businessManagerConnectOpen:true})}}><Icon className='material-icons'>warning</Icon> pending admin's approval</Warning>
         : <></>}
        {token && pageToken && (
          <div>
            <h4>Connected Page</h4>
            <span>
              {!!image && <FbBusinessPageImg src={image} />}
              {!!fbBusinessPageName && fbBusinessPageName}
            </span>
          </div>
        )}
        {!pageToken && (
          <div
            onClick={() => this.setState({ modalFbConnectVideo: true })}
            style={{
              cursor: "pointer",
              margin: "14px 0 24px",
              fontStyle: "italic",
              color: "#B4269D"
            }}
          >
            How to connect to Facebook
          </div>
        )}
        {/* {!fbStatusLoading && !fbAllPermissionsGranted && pageToken && (
          <InputError
            onClick={() => this.setState({ modalPermissionMissing: true })}
            style={{ cursor: 'pointer' }}
          >
            Boostpoint is missing the correct permissions to your Facebook Page
            to work correctly. View Details Here.
          </InputError>
        )} */}
        {/* Remove BP Permissions Modal */}
        <BpModal
          open={modalPermissionsConfirm}
          title={"Are you sure?"}
          primaryAction={{
            btnLabel: "Confirm",
            action: this.deletePermissions
          }}
          secondaryAction={{
            btnLabel: "Cancel",
            action: () =>
              this.setState({
                modalPermissionsConfirm: false,
                fbStatusLoading: false
              })
          }}
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
          contentLabel='Confirm Permissions Delete'
          body={
            <p>
              Clicking the confirm button will remove Boostpoint's permissions
              from your account. If you later decide to reconnect, you will need
              to log into Facebook again.
            </p>
          }
          width='auto'
        />

        {/* Select Facebook Page Modal */}
        <Modal
          isOpen={openPagesModal}
          onAfterOpen={this.afterOpenModal}
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
          style={fbPagesModal}
          contentLabel='Select a Facebook Page'
          appElement={document.getElementById("root")}
        >
          <FbPageHeader ref={this.pagesHeaderRef}>
            <H3 ref={subtitle => (this.subtitle = subtitle)}>
              Select a Facebook Page
            </H3>
            {this.state.pagesList.length > 0 && (
              <p>
                Please select which Facebook page you would like your ads posted
                from.
              </p>
            )}
          </FbPageHeader>
          <FbPageMain ref={this.pagesContainerRef}>
            {this.state.moreAbove && (
              <MoreArrow position='top' headerHeight={this.state.headerHeight}>
                <i
                  className='material-icons'
                  onClick={() => this.handlePagesArrow("up")}
                >
                  keyboard_arrow_up
                </i>
              </MoreArrow>
            )}
            <div ref={this.pagesListRef}>
              <PagesList 
                pages={pagesList} 
                handleFBPageHighlight={this.handleFBPageHighlight} 
                selectedFBPage={this.state.selectedFBPage} 
                alert={this.props.alert}
              />
            </div>
            {this.state.moreBelow && (
              <MoreArrow
                position='bottom'
                footerHeight={this.state.footerHeight}
                onClick={() => this.handlePagesArrow("down")}
              >
                <i className='material-icons'>keyboard_arrow_down</i>
              </MoreArrow>
            )}
            {pagesList.length === 0 && (
              <MissingPagesWrapper>
                <MissingPagesTitle>
                  <i className='material-icons' style={{ color: "#ffae42" }}>
                    warning
                  </i>
                  <p>
                    Unfortunately, you either don't have a Facebook Business
                    Page or you don't have the proper permissions to a Facebook
                    Business Page. Boostpoint requires a Facebook Business Page
                    to post ads on behalf of. Please try the following steps in
                    order to fix this issue.
                  </p>
                </MissingPagesTitle>
                <MissingPagesBody>
                  <p>
                    1. If you believe you have a Facebook Business Page, please
                    contact your page owner to give you admin permissions.
                  </p>
                  <p>
                    2. If you do not have a Facebook Business Page, you can find
                    more information about creating a page{" "}
                    <a href='https://www.facebook.com/business/pages/set-up'>
                      here
                    </a>
                    . When you are ready, you can get started creating a new
                    page at{" "}
                    <a href='https://www.facebook.com/pages/create'>
                      https://www.facebook.com/pages/create
                    </a>
                    .
                  </p>
                  <p>
                    3. If you believe you are getting this message in error,
                    please contact us at{" "}
                    <a href='mailto:help@boostpoint.com'>help@boostpoint.com</a>
                    .
                  </p>
                </MissingPagesBody>
              </MissingPagesWrapper>
            )}
          </FbPageMain>
          <FbPageFooter ref={this.pagesFooterRef}>
            <div>
              {paging.before && (
                <PagingButton onClick={this.showPrevious}>
                  <Icon className='material-icons'>keyboard_arrow_left</Icon>
                  Previous
                </PagingButton>
              )}
              {paging.after && (
                <PagingButton onClick={this.showAfter}>
                  Next
                  <Icon className='material-icons'>keyboard_arrow_right</Icon>
                </PagingButton>
              )}
            </div>
            <ModalButtonWrapper>
              <Button
                onClick={() => {
                  this.setState({
                    openPagesModal: false,
                    selectedFBPage: null,
                    fbStatusLoading: false
                  });
                }}
              >
                Cancel
              </Button>
              {pagesList.length > 0 && (
                <Button
                  solid
                  onClick={this.requestPagePermissions}
                  disabled={loading}
                >
                  Submit {loading && <LoaderDotsSmall />}
                </Button>
              )}
            </ModalButtonWrapper>
          </FbPageFooter>
        </Modal>

        {/* Page not published */}
        <BpModal
          open={this.state.modalPageUnpublished}
          title={"Page is not published"}
          primaryAction={{
            btnLabel: "Continue",
            action: this.requestPagePermissions
          }}
          secondaryAction={{
            btnLabel: "Cancel",
            action: () => this.setState({ modalPageUnpublished: false })
          }}
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
          contentLabel='Page is not Published'
          body={
            <div>
              <ModalTxtWrapper>
                Your page is not published. Please go to Facebook and publish
                your page and then click Continue. Reference this video below
                for how to do this on Facebook page. You must be a page admin to
                publish your page.
              </ModalTxtWrapper>
              <p>
                <ModalImg
                  alt='video instructions to publish page'
                  src='https://firebasestorage.googleapis.com/v0/b/boostpoint-firestore/o/support_gifs%2Fpublish-page.gif?alt=media&token=91137c99-6776-4a67-b886-c23a4b4cf541'
                />
              </p>
            </div>
          }
          width='auto'
        />

        {/* Missing permissions Modal */}
        <BpModal
          open={
            modalPermissionMissing &&
            (fbStatus || this.state.businessManagementMissing) &&
            !fbStatusLoading
          }
          title={"Oops! We’re missing some permissions"}
          primaryAction={{
            btnLabel: "Fix it",
            action: this.fixPermissions
          }}
          secondaryAction={{
            btnLabel: "Ignore",
            action: () => this.setState({ modalPermissionMissing: false })
          }}
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
          contentLabel='Permissions Missing'
          body={
            <div>
              <ModalTxtWrapper>
                Boostpoint does not have all the permissions we need to your
                Facebook account. Please disconnect and reconnect your account
                to fix this issue.
              </ModalTxtWrapper>
              <ModalTxtWrapper>
                {this.state.businessManagementMissing ? (
                  <strong>Business Management Permission missing</strong>
                ) : (
                  <strong>{missingPermissionsDisplay}</strong>
                )}
              </ModalTxtWrapper>
              <ModalHelpVideoContainer>
                <ModalHelpVideoWrapper>
                  <h4>Reconnecting and Fixing Facebook Account Permissions</h4>
                  <ModalHelpVideo>
                    <WistiaPlayer
                      url='https://boostpoint.wistia.com/medias/ysn80p3jjl'
                      width='100%'
                      height='100%'
                      style={{ position: "absolute", top: "0", left: "0" }}
                    />
                  </ModalHelpVideo>
                </ModalHelpVideoWrapper>
              </ModalHelpVideoContainer>
              <ModalTxtWrapper>
                You can use the video above for guidance on how to properly
                connect your Facebook page. If you need additional help, use the
                chat window in the lower corner, or email us at{" "}
                <a href='mailto:help@boostpoint.com'>help@boostpoint.com</a>.
              </ModalTxtWrapper>
            </div>
          }
          width='auto'
        />

        {/* How to connect Facebook modal */}
        <BpModal
          open={this.state.modalFbConnectVideo}
          title={"How to connect your Facebook page"}
          primaryAction={{
            btnLabel: "Close",
            action: () => this.setState({ modalFbConnectVideo: false })
          }}
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
          contentLabel='How to connect Facebook'
          body={
            <div>
              <ModalHelpVideoContainer>
                <ModalHelpVideoWrapper>
                  <ModalHelpVideo>
                    <WistiaPlayer
                      url='https://boostpoint.wistia.com/medias/782tsg7cln'
                      width='100%'
                      height='100%'
                      style={{ position: "absolute", top: "0", left: "0" }}
                    />
                  </ModalHelpVideo>
                </ModalHelpVideoWrapper>
              </ModalHelpVideoContainer>
              <ModalTxtWrapper>
                You can use the video above for guidance on how to properly
                connect your Facebook page. If you need additional help, use the
                chat window in the lower corner, or email us at{" "}
                <a href='mailto:help@boostpoint.com'>help@boostpoint.com</a>.
              </ModalTxtWrapper>
            </div>
          }
          width='auto'
        />

        {/* Disconnect Facebook confirmation */}
        <BpModal
          open={modalDisconnectConfirm}
          title={"Disconnect your Facebook page"}
          primaryAction={{
            btnLabel: "Disconnect",
            action: e => this.handleToggleChange(e, true)
          }}
          secondaryAction={{
            btnLabel: "Cancel",
            action: () => this.setState({ modalDisconnectConfirm: false })
          }}
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
          contentLabel='How to connect Facebook'
          body={
            <p>
              You are about to disconnect your Facebook account. Are you sure
              you want to disconnect?
            </p>
          }
          width='auto'
        />

        {/* Accept Facebook's Leadgen Terms of Service */}
        <BpModal
          open={leadgenTosModalOpen}
          title={`Accept Facebook's Leadgen Terms of Service`}
          primaryAction={{
            btnLabel: `I'm Done`,
            action: this.submitLeadgenModal,
            loading: this.state.leadGenLoading
          }}
          secondaryAction={{
            btnLabel: "Cancel",
            action: this.toggleLeadgenModal
          }}
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
          style={fbPagesModal}
          contentLabel='Leadgen Terms of Service'
          body={
            <div>
              <p>
                You need to accept Facebook's Leadgen Terms of Service before
                you can submit a Leadgen ad. Click the continue button to be
                taken to the accept page.
              </p>
              <Link
                solid
                href='https://www.facebook.com/ads/leadgen/tos'
                target='_blank'
              >
                Continue
              </Link>
              <Ul>
                <li>
                  Make sure the correct page is showing in the dropdown at the
                  top left of the page
                </li>
                <li>Click Accept</li>
                <li>
                  Come back here and click <i>I'm Done</i>
                </li>
              </Ul>
            </div>
          }
        />

        {/* Permissions Error */}
        <BpModal
          open={
            this.state.modalPermissionError &&
            !selectedFBBusiness &&
            !selectedFBBusiness.name
              ? true
              : false
          }
          primaryAction={{
            btnLabel: `Close`,
            action: () => {
              this.setState({ modalPermissionError: false });
            }
          }}
          // onAfterOpen={this.afterOpenModal}
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
          style={fbPagesModal}
          contentLabel='Facebook Connect Error'
          body={
            <div>
              <p>
                There was a permissions issue connecting your Facebook page.
                Please contact us at 717-455-7125 or{" "}
                <a
                  href='mailto:help@boostpoint.com'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  help@boostpoint.com
                </a>{" "}
                to help connect your page.
              </p>
            </div>
          }
        />

        {/* page owned by business manager */}
        <BusinessManagerConnect open={this.state.businessManagerConnectOpen} close={()=>{this.setState({businessManagerConnectOpen:false})}} businessManager={businessManager}/>
      </div>
    );
  }
}

export default FbConnect;
