import React from "react";
import styled from "styled-components";
import { LoaderDotsSmall } from "./Loaders";

const ToggleWrapper = styled.div`
  position: relative;
  width: 60px;
  height: 28px;
`;

const ToggleInput = styled.input`
  position: absolute;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  position: relative;
  cursor: pointer;
  margin: 0;
  width: 63px;
  height: 28px;
  outline: none;
  background-color: ${(props) =>
    props.uncheckedC ? props.uncheckedC : props.theme.HCErr};
  border-radius: 20px;
  border: 1px solid ${(props) => props.theme.ATextC};
  cursor: pointer;
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  &:checked {
    background: ${(props) =>
      props.checkedC ? props.checkedC : props.pending?props.theme.HC4: props.theme.HCSuc};
    border: none;
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2);
  }
  &::before {
    content: "";
    position: absolute;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    top: -1px;
    left: -1px;
    background: white;
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    cursor: pointer;
  }
  &:checked::before {
    left: 35px;
    top: 0.4px;
  }
  &:disabled {
    opacity: 0.5;
    background-color: ${(props) => props.theme.TextC};
    pointer-events: none;
  }
  &:disabled::before {
    cursor: not-allowed;
  }
`;

const OnLabel = styled.span`
  color: white;
  font-size: 14px;
  pointer-events: none;
`;

const OffLabel = styled.span`
  color: white;
  font-size: 14px;
  pointer-events: none;
  padding-left: 5px;
`;

const LabelWrapper = styled.div`
  pointer-events: none;
  position: absolute;
  ${props => props.on? "padding-left:44px" : "padding-right:44px"};
  top:26px;
  color: white;
  width: 100%;
  height: 100%;
  transform: scale(.8);
`;

const ToggleBtn = (props) => {
  const {
    on,
    disabled = false,
    loading = false,
    onChange,
    onLabel = "On",
    offLabel = "Off",
    showLabels = false,
    checkedC,
    uncheckedC,
    icon = false,
    pending
  } = props;
  return (
    <ToggleWrapper>
      <ToggleInput
        type="checkbox"
        onChange={onChange}
        checked={on}
        disabled={disabled}
        title="hover"
        checkedC={checkedC}
        uncheckedC={uncheckedC}
        pending={pending}
      />
      <LabelWrapper on={on} icon={icon}>
        {!!showLabels && <OnLabel show={on || !disabled}>{onLabel}</OnLabel>}
        {!!showLabels && <OffLabel show={!on || disabled}>{offLabel}</OffLabel>}
        {loading && <LoaderDotsSmall toggle={true} />}
      </LabelWrapper>
    </ToggleWrapper>
  );
};

export default ToggleBtn;
