import {
  FormDescription,
  FormWrapper,
  InputBox,
  InputBoxWrapper,
  InputContainer,
  InputLabel
} from "../../../styled/Input";

export const EZTextingCoreSettings = props => {
  const { integration } = props;

  return (
    <FormWrapper single>
      <InputContainer slimHeight customWidth='100%'>
        <InputLabel>Auth String</InputLabel>
        <InputBoxWrapper>
          <InputBox type='text' name='auth' readOnly value={integration.auth} />
        </InputBoxWrapper>
      </InputContainer>
      <FormDescription>
        Please contact your Support Representative to update these values.
      </FormDescription>
    </FormWrapper>
  );
};

export const EZTextingOptionalSettings = props => {
  const { integration } = props;

  return (
    <FormWrapper>
      <InputContainer customWidth='100%'>
        <InputLabel>Group ID</InputLabel>
        <InputBox
          type='text'
          name='groupIdsId'
          readOnly
          value={
            integration.groupIds && integration.groupIds.id
              ? integration.groupIds.id
              : ""
          }
        />
      </InputContainer>
      <InputContainer customWidth='100%'>
        <InputLabel>Group ID Name</InputLabel>
        <InputBox
          type='text'
          name='groupIdsName'
          readOnly
          value={
            integration.groupIds && integration.groupIds.name
              ? integration.groupIds.name
              : ""
          }
        />
      </InputContainer>
      <FormDescription evenContainer>
        Please contact your Support Representative to update these values.
      </FormDescription>
    </FormWrapper>
  );
};
