import React from "react";
import styled from "styled-components";
import { industries } from "../../../utils/Helpers";
import BpModal from "../../elements/BpModal";
import { Dropdown, InputBoxWrapper, InputContainer } from "../../styled/Input";
import InputError from "../../styled/InputErrorForm";
import { firstBreakpoint } from "../../styled/PhoneNumberProvision";

const ModalWrapper = styled.div`
  padding: 0;
  position: relative;
  display: block;
  grid-gap: 1em;
  margin: 0 60px 30px 60px;
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: ${props => (props.showMenu ? "grid" : "block")};
    grid-template-columns: 3fr 4fr;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${props => props.theme.BGC};
  align-items: center;
  margin-top: 35px;
  h2 {
    font-weight: 600;
    letter-spacing: initial;
    font-size: 20px;
    color: #3a0ca2;
  }
  div {
    display: flex;
    align-items: center;
  }
`;

const ModalMenu = styled.div`
  background-color: ${props => props.theme.BGC};
  border-radius: 5px;
`;

const ModalBody = styled.div`
  background-color: ${props => props.theme.BGC};
  border-radius: 5px;
  height: auto;
  width: auto;
  @media screen and (min-width: ${firstBreakpoint}px) {
    max-height: ${props => (props.maxHeight ? props.maxHeight : "44vh")};
    min-height: ${props => (props.minHeight ? props.minHeight : "initial")};
  }
  padding: 0;
  img {
    width: 100%;
  }
  .infoIcon {
    width: auto;
  }
  p:first-of-type {
    margin-top: 0;
  }
  h4 {
    text-align: left;
    font-size: 1.2em;
    margin: 1.5em 1em;
  }
`;

const Close = styled.div`
  margin: 10px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  span {
    color: #3a0ca2;
    font-size: 30px;
  }
`;

const IndustrySelectModal = props => {
  const { modalIndustrySelect, loading, userCompany, msg } = props;

  const [industry, setIndustry] = React.useState(userCompany.industry);
  const [errorMessage, setErrorMessage] = React.useState("");

  const saveIndustry = async () => {
    props.saving();
    const { industry: oldIndustry } = userCompany;
    if (!industry) {
      setErrorMessage("Please select an industry");
    } else if (industry !== oldIndustry) {
      try {
        setErrorMessage("");
        props.saveAction({
          ...userCompany,
          industry
        });
      } catch (err) {
        console.log("error saving industry", err.message);
      }
    } else {
      props.saveAction();
    }
  };

  return (
    <BpModal
      open={modalIndustrySelect}
      title={"Select an Industry."}
      primaryAction={{
        btnLabel: "Save",
        action: saveIndustry,
        loading: loading
      }}
      secondaryAction={{
        btnLabel: "Cancel",
        action: props.onClose,
        loading: loading
      }}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      contentLabel='Select an Industry'
      width='30rem'
      body={
        <div>
          {msg ? (
            <p>{msg}</p>
          ) : (
            <p>
              Please select your industry to continue. You can update this at
              any time from the settings page
            </p>
          )}
          <InputContainer>
            <InputBoxWrapper>
              <Dropdown
                type='text'
                name='industryType'
                data-namereadable='industry'
                value={industry}
                onChange={input => setIndustry(input.target.value)}
              >
                <option>Select One</option>
                {Object.keys(industries).map(i => (
                  <option value={i} key={i}>
                    {industries[i]}
                  </option>
                ))}
              </Dropdown>
            </InputBoxWrapper>
            <InputError>{errorMessage}</InputError>
          </InputContainer>
        </div>
      }
    />
  );
};

export default IndustrySelectModal;
