import styled from "styled-components";

const SectionTitle = styled.div`
  text-align: center;
  margin: 0 auto;
  padding-top: 90px;
  max-width: 700px;
`;

export default SectionTitle;
