import React from "react";
import styled, { keyframes } from "styled-components";

const DotBricks = props => keyframes`
  0% {
    box-shadow: 9991px -16px 0 0 ${
      props.darkMode ? props.theme.HC1 : props.theme.BGC
    }, 9991px 0 0 0 ${
  props.darkMode ? props.theme.HC1 : props.theme.BGC
}, 10007px 0 0 0 ${props.darkMode ? props.theme.HC1 : props.theme.BGC};
  }
  8.333% {
    box-shadow: 10007px -16px 0 0 ${
      props.darkMode ? props.theme.HC1 : props.theme.BGC
    }, 9991px 0 0 0 ${
  props.darkMode ? props.theme.HC1 : props.theme.BGC
}, 10007px 0 0 0 ${props.darkMode ? props.theme.HC1 : props.theme.BGC};
  }
  16.667% {
    box-shadow: 10007px -16px 0 0 ${
      props.darkMode ? props.theme.HC1 : props.theme.BGC
    }, 9991px -16px 0 0 ${
  props.darkMode ? props.theme.HC1 : props.theme.BGC
}, 10007px 0 0 0 ${props.darkMode ? props.theme.HC1 : props.theme.BGC};
  }
  25% {
    box-shadow: 10007px -16px 0 0 ${
      props.darkMode ? props.theme.HC1 : props.theme.BGC
    }, 9991px -16px 0 0 ${
  props.darkMode ? props.theme.HC1 : props.theme.BGC
}, 9991px 0 0 0 ${props.darkMode ? props.theme.HC1 : props.theme.BGC};
  }
  33.333% {
    box-shadow: 10007px 0 0 0 ${
      props.darkMode ? props.theme.HC1 : props.theme.BGC
    }, 9991px -16px 0 0 ${
  props.darkMode ? props.theme.HC1 : props.theme.BGC
}, 9991px 0 0 0 ${props.darkMode ? props.theme.HC1 : props.theme.BGC};
  }
  41.667% {
    box-shadow: 10007px 0 0 0 ${
      props.darkMode ? props.theme.HC1 : props.theme.BGC
    }, 10007px -16px 0 0 ${
  props.darkMode ? props.theme.HC1 : props.theme.BGC
}, 9991px 0 0 0 ${props.darkMode ? props.theme.HC1 : props.theme.BGC};
  }
  50% {
    box-shadow: 10007px 0 0 0 ${
      props.darkMode ? props.theme.HC1 : props.theme.BGC
    }, 10007px -16px 0 0 ${
  props.darkMode ? props.theme.HC1 : props.theme.BGC
}, 9991px -16px 0 0 ${props.darkMode ? props.theme.HC1 : props.theme.BGC};
  }
  58.333% {
    box-shadow: 9991px 0 0 0 ${
      props.darkMode ? props.theme.HC1 : props.theme.BGC
    }, 10007px -16px 0 0 ${
  props.darkMode ? props.theme.HC1 : props.theme.BGC
}, 9991px -16px 0 0 ${props.darkMode ? props.theme.HC1 : props.theme.BGC};
  }
  66.666% {
    box-shadow: 9991px 0 0 0 ${
      props.darkMode ? props.theme.HC1 : props.theme.BGC
    }, 10007px 0 0 0 ${
  props.darkMode ? props.theme.HC1 : props.theme.BGC
}, 9991px -16px 0 0 ${props.darkMode ? props.theme.HC1 : props.theme.BGC};
  }
  75% {
    box-shadow: 9991px 0 0 0 ${
      props.darkMode ? props.theme.HC1 : props.theme.BGC
    }, 10007px 0 0 0 ${
  props.darkMode ? props.theme.HC1 : props.theme.BGC
}, 10007px -16px 0 0 ${props.darkMode ? props.theme.HC1 : props.theme.BGC};
  }
  83.333% {
    box-shadow: 9991px -16px 0 0 ${
      props.darkMode ? props.theme.HC1 : props.theme.BGC
    }, 10007px 0 0 0 ${
  props.darkMode ? props.theme.HC1 : props.theme.BGC
}, 10007px -16px 0 0 ${props.darkMode ? props.theme.HC1 : props.theme.BGC};
  }
  91.667% {
    box-shadow: 9991px -16px 0 0 ${
      props.darkMode ? props.theme.HC1 : props.theme.BGC
    }, 9991px 0 0 0 ${
  props.darkMode ? props.theme.HC1 : props.theme.BGC
}, 10007px -16px 0 0 ${props.darkMode ? props.theme.HC1 : props.theme.BGC};
  }
  100% {
    box-shadow: 9991px -16px 0 0 ${
      props.darkMode ? props.theme.HC1 : props.theme.BGC
    }, 9991px 0 0 0 ${
  props.darkMode ? props.theme.HC1 : props.theme.BGC
}, 10007px 0 0 0 ${props.darkMode ? props.theme.HC1 : props.theme.BGC};
  }
`;

const Bricks = styled.div`
  position: relative;
  top: 8px;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${props =>
    props.darkMode ? props.theme.HC1 : props.theme.BGC};
  color: ${props => (props.darkMode ? props.theme.HC1 : props.theme.BGC)};
  box-shadow: 9991px -16px 0 0 ${props => (props.darkMode ? props.theme.HC1 : props.theme.BGC)},
    9991px 0 0 0
      ${props => (props.darkMode ? props.theme.HC1 : props.theme.BGC)},
    10007px 0 0 0
      ${props => (props.darkMode ? props.theme.HC1 : props.theme.BGC)};
  animation: ${DotBricks} 2s infinite ease;
`;

// darkMode = true: dots are the Highlight 1 theme color DEFAULT
// darkMode = false: dots are background color

const DotsBrickLoader = props => {
  const { darkMode = true } = props;
  return <Bricks darkMode={darkMode} />;
};

export default DotsBrickLoader;
