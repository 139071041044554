/* global cloudinary */

import React from "react";
import styled from "styled-components";
import LoadingBar from "../elements/LoadingBar";
import EditMobileMenu from "../styled/EditMobileMenu";
import ImageWrapper from "../styled/ImageWrapper";
import { assetAdd } from "../../utils/MediaAPI";
import { Button } from "../styled/Buttons";

const ModalFooterWrapper = styled.div`
  border-top: 1px solid lightgrey;
  margin: 0 1.5em;
  @media screen and (max-height: 580px) {
    display: flex;
    justify-content: space-around;
  }
`;

const SelectedImagesContainer = styled.div`
  height: 7em;
`;

const SelectedImagesWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6em;
  margin: 0px 1.5em;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  right: 50%;
  margin-right: -2.5em;
  bottom: 0;
  height: 4em;
  width: 5em;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-height: 580px) {
    position: static;
    margin: 20px 0;
    width: auto;
  }
`;

const VideoModalFooter = props => {
  const { selectedVideo } = props;

  return (
    <ModalFooterWrapper>
      <SelectedImagesContainer>
        <small>
          {selectedVideo
            ? "Selected Video"
            : "No video selected. Click a video to select it."}
          {selectedVideo ? (
            <button
              onClick={props.handleClearSelected}
              style={{ color: "red" }}
            >
              clear
            </button>
          ) : null}
        </small>
        <SelectedImagesWrapper>
          {selectedVideo && selectedVideo.name ? (
            <img
              id={selectedVideo.name}
              src={`${process.env.REACT_APP_CLOUDINARY_VIDEO_BASEURL}/upload/h_48,c_fill,g_center,f_auto,q_auto/${selectedVideo.name}.jpg`}
              alt={selectedVideo.name}
              style={{ height: "3em", margin: ".3em", cursor: "pointer" }}
            />
          ) : null}
        </SelectedImagesWrapper>
      </SelectedImagesContainer>
      <ButtonWrapper>
        <Button id='cancelBtn' type='button' onClick={props.closeVideoModal}>
          cancel
        </Button>
        <Button
          solid
          type='button'
          onClick={props.selectedSubmit}
          id='video-submit'
        >
          submit
        </Button>
      </ButtonWrapper>
    </ModalFooterWrapper>
  );
};

export default VideoModalFooter;
