import React from "react";
import styled from "styled-components";
import MessageContent from "./MessageContent";
import MessageInfo from "./MessageInfo";

const MsgWrapper = styled.div`
  display: flex;
  flex-direction: ${props => (props.inbound ? "row" : "row-reverse")};
  position: relative;
  margin-left: ${props => (props.showSelectors ? "30px" : "0")};
  transition: margin 0.3s ease-in-out;
  margin-bottom: 12px;
  @media only screen and (min-width: 901px) {
    margin-bottom: 20px;
  }
`;

const Selector = styled.div`
  max-width: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  position: absolute;
  left: -35px;
  display: flex;
  align-items: center;
  height: 55%;
  top: 0;
  &.showSelectors {
    max-width: 50px;
  }
`;

const MsgInner = styled.div`
  max-width: 700px;
  width: 70%;
  @media only screen and (max-width: 900px) {
    max-width: 80%;
    width: 80%;
  }
`;

const MsgDate = styled.div`
  text-align: ${props => (props.inbound ? "left" : "right")};
  font-weight: bold;
  font-size: 0.7em;
  margin-top: 8px;
`;

const ErrorMessage = styled.div`
  color: ${props => props.theme.HCErr};
`;

const MessageDisplay = props => {
  const { contactMessages, showSelectors, selected, floaters, errorCodes } = props;

  return contactMessages.map((message, key) => (
    <MsgWrapper
      key={message._id ? message._id : message.contactId}
      inbound={
        message.direction === "inbound" ||
        message.direction === "incoming"
      }
      statusNew={message.read}
      className={message.read ? "new" : ""}
      selected={selected.includes(message.smsSid)}
      showSelectors={showSelectors}
    >
      <Selector
        show={showSelectors}
        className={showSelectors ? "showSelectors" : null}
      >
        <input
          type='radio'
          value={message._id}
          checked={selected.includes(message._id)}
          onClick={input =>
            props.onChangeSelector(input.target.value)
          }
          readOnly
        />
      </Selector>
      <MsgInner>
        <MessageContent message={message} />
        <MsgDate
          inbound={
            message.direction === "inbound" ||
            message.direction === "incoming"
          }
        >
          {!message.errorMessage ? (
            <MessageInfo
              message={message}
              num={key}
              tryAgain={props.tryAgain}
              floaters={floaters}
              handleFloaterClick={props.handleFloaterClick}
              errorCodes={errorCodes}
            />
          ) : (
            <ErrorMessage>{message.errorMessage}</ErrorMessage>
          )}
        </MsgDate>
      </MsgInner>
    </MsgWrapper>
  ))
};

export default MessageDisplay;
