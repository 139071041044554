import React from "react";
import {
  InputContainer,
  InputBoxWrapper,
  InputBox,
  InputLabel,
  FormWrapper,
  FormDescription
} from "../../../styled/Input";

export const HatchCoreSettings = props => {
  const { integration } = props;

  return (
    <FormWrapper single>
      <InputContainer slimHeight customWidth='100%'>
        <InputLabel>API Key</InputLabel>
        <InputBoxWrapper>
          <InputBox
            type='text'
            name='apiKey'
            readOnly
            value={integration.apiKey}
          />
        </InputBoxWrapper>
      </InputContainer>
      <FormDescription>
        Please contact your Support Representative to update these values.
      </FormDescription>
    </FormWrapper>
  );
};

export const HatchOptionalSettings = props => {
  const { integration } = props;

  return (
    <FormWrapper>
      <InputContainer customWidth='100%'>
        <InputLabel>Department ID</InputLabel>
        <InputBox
          type='text'
          name='deptID'
          readOnly
          value={integration.deptID}
        />
      </InputContainer>
      <InputContainer customWidth='100%'>
        <InputLabel>Status</InputLabel>
        <InputBox
          type='text'
          name='status'
          readOnly
          value={integration.status}
        />
      </InputContainer>
      <InputContainer customWidth='100%'>
        <InputLabel>Source Name</InputLabel>
        <InputBox
          type='text'
          name='source'
          readOnly
          value={integration.source}
        />
      </InputContainer>
      <FormDescription>
        Please contact your Support Representative to update these values.
      </FormDescription>
    </FormWrapper>
  );
};
