export const Purple = "#675da8";
export const Gray = "#8c8c8c";
export const LightGray = "#ededee";
export const DarkGray = "#383838";
export const Red = "#ea6262";
export const Yellow = "#fbb74c";
export const Green = "#4b7c60";
export const LightGreen = "#7cc47a";
export const InputBg = "#f7f7f7";
export const Border = "#f0f0f0";
