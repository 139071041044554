import React from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { firstBreakpoint } from "../../styled/PhoneNumberProvision";
import H3 from "../../styled/H3";
const ModalWrapper = styled.div`
  padding: 0;
  position: relative;
`;

const ModalHeader = styled.div`
  /* border-bottom: 1px solid #cfcfcf; */
  padding: 1rem 2rem 2rem;
  text-align: left;
  h3 {
    margin: 0;
    text-align: center;
  }
`;

const ModalBody = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 90vh;
  width: 90vw;
  display: flex;
  @media screen and (min-width: ${firstBreakpoint}px) {
    height: auto;
    width: auto;
    max-height: ${props => (props.maxHeight ? props.maxHeight : "44vh")};
    min-height: ${props => (props.minHeight ? props.minHeight : "initial")};
  }
  padding: 0;
  /* background-color: #fafafafa; */
  img {
    width: 100%;
  }
  .infoIcon {
    width: auto;
  }
  p:first-of-type {
    margin-top: 0;
  }
`;

const Close = styled.div`
  position: absolute;
  right: 0;
  padding: 10px;
  cursor: pointer;
  z-index: 10;
  span {
    font-size: 30px;
  }
  @media screen and (min-width: ${firstBreakpoint}px) {
    padding: 16px;
  }
`;

class IntegrationModal extends React.Component {
  render() {
    const {
      open,
      title,
      body,
      shouldCloseOnEsc = true,
      shouldCloseOnOverlayClick = true,
      contentLabel = "",
      zIndex = "1000",
      width = "44rem",
      fontSize = "1em",
      padding,
      styleOverride = {}
    } = this.props;
    //I feel like we need a bit more directly on the modal styles for build this part out more.
    const { bodyStyles: { maxHeight = "44vh" } = {} } = styleOverride;
    const overlay = {
      backgroundColor: "rgba(0, 0, 0, .75)",
      zIndex
    };
    const content = {
      textAlign: "center",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      width,
      maxWidth: "90%",
      maxHeight: "90%",
      padding: "0",
      transform: "translate(-50%, -50%)",
      border: "none",
      boxShadow: "0px 0px 9px 0px rgba(0, 0, 6, .1)",
      fontSize
    };
    return (
      <Modal
        isOpen={open}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        shouldCloseOnEsc={shouldCloseOnEsc}
        style={{ overlay, content }}
        contentLabel={contentLabel}
        onAfterOpen={this.props.onAfterOpen ? this.props.onAfterOpen : null}
        onRequestClose={
          this.props.onCloseMethod ? this.props.onCloseMethod : null
        }
        appElement={document.getElementById("root")}
      >
        {!!this.props.onCloseMethod && (
          <Close onClick={this.props.onCloseMethod}>
            <span className='material-icons'>close</span>
          </Close>
        )}
        <ModalWrapper padding={padding} className='modal-content-wrapper'>
          {!!title && (
            <ModalHeader>
              <H3>{title}</H3>
            </ModalHeader>
          )}
          <ModalBody maxHeight={maxHeight}>{body}</ModalBody>
        </ModalWrapper>
      </Modal>
    );
  }
}

export default IntegrationModal;
