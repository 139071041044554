import React from "react";
import Floater from "react-floater";
import LineChart from "../../elements/charts/LineChart";
import LoadingBar from "../../elements/LoadingBar";
import {
  ChartTitle,
  ChartTitleChange,
  ChartTitleNumber,
  DashboardModuleTitle,
  floaterStyles,
  Icon,
  Overlay,
  Section
} from "./DashboardStyles";

const LeadsGraph = props => {
  const { floaters, chartsLoading, chartData = {}, leadsWaiting } = props;

  return (
    <Section columns='1/3'>
      <DashboardModuleTitle>
        <ChartTitle>
          <h6>Leads</h6>
          <Floater
            open={floaters.leads}
            content='Number of leads generated through Lead Campaigns. This does not include leads that might have been obtained from your own website or landing page traffic.'
            styles={floaterStyles}
            event='hover'
            placement='right'
          >
            <Icon
              className='material-icons'
              onClick={e => props.handleFloaterClick(e, "leads")}
              name='floater-toggle'
            >
              info
            </Icon>
            {floaters.leads && (
              <Overlay
                onClick={e => props.handleFloaterClick(e, "leads")}
                className='overlay'
              />
            )}
          </Floater>
        </ChartTitle>
        {chartsLoading ? null : (
          <ChartTitle>
            <ChartTitleNumber className='color-yellow'>
              {chartData.leadCount}
            </ChartTitleNumber>
            {!leadsWaiting.prev && chartData.leadChange >= 0 ? (
              <ChartTitleChange>
                (+
                {chartData.leadChange}
                %)
              </ChartTitleChange>
            ) : (
              <ChartTitleChange style={{ color: "red" }}>
                ({chartData.leadChange}
                %)
              </ChartTitleChange>
            )}
          </ChartTitle>
        )}
      </DashboardModuleTitle>
      {chartsLoading ? (
        <LoadingBar />
      ) : (
        <LineChart
          backgroundColor='rgba(254, 185, 76, 0.2)'
          borderColor='rgba(254,185,76,1)'
          labelValue={chartData.labelValue}
          dataValue={chartData.dataValue}
          prevBackgroundColor='rgba(235, 239, 239, 0.2)'
          prevBorderColor='rgba(211, 224, 224, 1)'
          prevLabelValue={chartData.prevLabelValue}
          prevDataValue={chartData.prevDataValue}
        />
      )}
      {!chartsLoading && leadsWaiting.current ? (
        <span>Loading current leads</span>
      ) : !chartsLoading && leadsWaiting.prev ? (
        <span>Loading previous leads</span>
      ) : null}
    </Section>
  );
};

export default LeadsGraph;
