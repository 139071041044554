import React from "react";
// import { useRouteMatch } from 'react-router-dom';
import styled from "styled-components";
import { LoaderDotsSmall } from "../../elements/Loaders";
import * as AllAboutInfo from "./IntegrationAbout";
import * as AllConnectModals from "./Connect";

import {
  Card,
  CardTitle,
  TitleWrapper,
  TitleLeft,
  TitleRight,
  HeaderImg
} from "../../styled/Layout";
import { Title, PageTitle } from "../../styled/Headers";
import NotificationSettings from "./bpConnect/NotificationSettings";
import BpConnectDetails from "./bpConnect/BpConnectDetails";
import { withTheme } from "styled-components";
import ToggleBtn from "../../elements/ToggleBtn";
import {
  EZTextingCoreSettings,
  EZTextingOptionalSettings
} from "./settings/EZTexting";
import {
  JobNimbusCoreSettings,
  JobNimbusOptionalSettings
} from "./settings/JobNimbus";
import { HatchCoreSettings, HatchOptionalSettings } from "./settings/Hatch";
import { ZohoCoreSettings, ZohoOptionalSettings } from "./settings/Zoho";
import { MarketSharpCoreSettings } from "./settings/MarketSharp";
import { ApplicantStackCoreSettings } from "./settings/ApplicantStack";
import { AccuLynxCoreSettings } from "./settings/AccuLynx";
import { JobProgressCoreSettings } from "./settings/JobProgress";
import { SalesPypeCoreSettings } from "./settings/SalesPype";
import { HealthcareSourceCoreSettings } from "./settings/HealthcareSource";
import BpModal from "../../elements/BpModal";
import { getStripeInfo, isConnectActive } from "../../../utils/StripeAPI";
import FieldMapping from "./FieldMapping";
import { integrationGetByCompany } from "../../../utils/IntegrationsAPI";

const ConnectBtnLabel = styled.span`
  font-size: 24px;
  color: ${props => props.theme.HC1};
  margin-left: 13px;
  font-weight: 600;
`;

const IntegrationLoading = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.6);
`;

const CreditCount = styled.div`
  color: ${props => props.theme.HC1};
  display: flex;
  flex-direction: column;
  min-width: 145px;
  text-align: left;
  line-height: 20px;
  border-left: 1px solid ${props => props.theme.HC1};
  padding-left: 39px;
  margin-left: 30px;
`;

const CreditNum = styled.span`
  font-weight: bold;
  font-size: 1.2em;
`;

let About = AllAboutInfo.NullAbout;
let ConnectModal = AllConnectModals.NullConnect;
const IntegrationSettings = props => {
  const { userProfile, userCompany } = props;
  const [integration, setIntegration] = React.useState({
    type: "",
    data: {},
    loading: true,
    active: false
  });
  const [showAbout, setShowAbout] = React.useState(false);
  const [modalSignUp, setModalSignUp] = React.useState(false);
  const [modalContactSupport, setModalContactSupport] = React.useState(false);

  React.useEffect(() => {
    const signup = false;
    const i = async () => {
      try {
        const stripeInfo = await getStripeInfo();
        const isActive = isConnectActive(stripeInfo.subscriptions);
        const { twilioPhone } = props.userCompany;
        const active = isActive && twilioPhone ? true : false;
        setIntegration({
          data: {
            nameReadable: "Boostpoint Connect",
            name: "BoostpointConnect"
          },
          type: "BoostpointConnect",
          active,
          loading: false
        });
      } catch (err) {
        console.log("error getting integration info", err.message);
      }
    };
    i();
    if (signup) {
      setModalSignUp(true);
    }
  }, [props.userCompany]);

  React.useEffect(() => {
    const getIntegrations = async () => {
      try {
        if (AllAboutInfo[`${integration.type}About`]) {
          About = AllAboutInfo[`${integration.type}About`];
          setShowAbout(true);
        }
        if (AllConnectModals[`${integration.type}Connect`]) {
          ConnectModal = AllConnectModals[`${integration.type}Connect`];
        }
        const type =
          integration.type === "BoostpointConnect"
            ? "FACEBOOK"
            : integration.type.toUpperCase();
        const integrationResponse = await integrationGetByCompany();
        const integrationMatch = integrationResponse.find(i => i.type === type);
        if (integrationMatch) {
          setIntegration(prevInt => ({
            ...prevInt,
            data: {
              ...prevInt.data,
              ...integrationMatch
            }
          }));
        }
      } catch (err) {
        console.log("error getting integrations", err.message);
      }
    };
    if (integration.type) {
      getIntegrations();
    }
  }, [integration.type]);

  const toggleActive = () => {
    if (!integration.active) {
      setModalSignUp(true);
    } else {
      toggleContact();
    }
  };

  const toggleContact = () => {
    setModalContactSupport(!modalContactSupport);
  };

  const integrationImage = () => (
    <img
      src={`https://res.cloudinary.com/equipter/image/upload/v1616090520/Boostpoint_Images/${
        integration.data.icon ? integration.data.icon : integration.data.image
      }`}
      alt={
        integration.data.nameReadable
          ? integration.data.nameReadable
          : integration.data.name
      }
    />
  );

  const handleModalClose = async () => {
    setModalSignUp(false);
    if (integration.type === "BoostpointConnect") {
      setIntegration({ ...integration, loading: true });
      await props.companyGet();
      const stripeInfo = await getStripeInfo();
      const isActive = isConnectActive(stripeInfo.subscriptions);
      const { twilioPhone } = props.userCompany;
      const active = isActive && twilioPhone ? true : false;
      setIntegration({ ...integration, active, loading: false });
    }
  };

  return (
    <div>
      <TitleWrapper>
        <TitleLeft>
          {integration.data.image || integration.data.icon ? (
            <HeaderImg>{integrationImage()}</HeaderImg>
          ) : integration.data.gmIcon ? (
            <HeaderImg>
              <span className='material-icons'>{integration.data.gmIcon}</span>
            </HeaderImg>
          ) : (
            <HeaderImg>
              <span className='material-icons'>compare_arrows</span>
            </HeaderImg>
          )}
          <PageTitle>
            {integration.data.nameReadable === "Boostpoint Connect" ? (
              <span>
                Boostpoint <span>Connect</span>
              </span>
            ) : integration.data.nameReadable ? (
              integration.data.nameReadable
            ) : (
              integration.data.name
            )}
          </PageTitle>
          {integration.active && integration.data.name === "BoostpointConnect" && (
            <CreditCount>
              <CreditNum>{props.smsCredits}</CreditNum>
              <span>SMS Credits</span>
            </CreditCount>
          )}
        </TitleLeft>
        <TitleRight>
          <ToggleBtn
            on={integration.active}
            showLabels={false}
            onChange={toggleActive}
          />
          <ConnectBtnLabel>
            {integration.active ? "Active" : "Activate"}
          </ConnectBtnLabel>
        </TitleRight>
      </TitleWrapper>

      {!!integration.loadingIntegration && (
        <IntegrationLoading>
          <LoaderDotsSmall message={false} />
        </IntegrationLoading>
      )}
      {integration.active && integration.data.name !== "BoostpointConnect" && (
        <div>
          <Card>
            <CardTitle>
              <Title>Core Connection Settings</Title>
            </CardTitle>
            {integration.data.name === "EZTexting" && (
              <EZTextingCoreSettings integration={integration.data} />
            )}
            {integration.data.name === "JobNimbus" && (
              <JobNimbusCoreSettings integration={integration.data} />
            )}
            {integration.data.name === "Hatch" && (
              <HatchCoreSettings integration={integration.data} />
            )}
            {integration.data.name === "Zoho" && (
              <ZohoCoreSettings integration={integration.data} />
            )}
            {integration.data.name === "MarketSharp" && (
              <MarketSharpCoreSettings integration={integration.data} />
            )}
            {integration.data.name === "ApplicantStack" && (
              <ApplicantStackCoreSettings integration={integration.data} />
            )}
            {integration.data.name === "AccuLynx" &&
              {
                /* <AccuLynxCoreSettings integration={integration} />*/
              }}
            {integration.data.name === "JobProgress" && (
              <JobProgressCoreSettings integration={integration.data} />
            )}
            {integration.data.name === "SalesPype" && (
              <SalesPypeCoreSettings integration={integration.data} />
            )}
            {integration.data.name === "HealthcareSource" && (
              <HealthcareSourceCoreSettings integration={integration.data} />
            )}
          </Card>
          {!!["EZTexting", "JobNimbus", "Hatch", "Zoho"].includes(
            integration.name
          ) && (
            <Card>
              <CardTitle>
                <Title>Default Optional Settings</Title>
              </CardTitle>
              {integration.data.name === "EZTexting" && (
                <EZTextingOptionalSettings integration={integration.data} />
              )}
              {integration.data.name === "JobNimbus" && (
                <JobNimbusOptionalSettings integration={integration.data} />
              )}
              {integration.data.name === "Hatch" && (
                <HatchOptionalSettings integration={integration.data} />
              )}
              {integration.data.name === "Zoho" && (
                <ZohoOptionalSettings integration={integration.data} />
              )}
            </Card>
          )}
        </div>
      )}

      {integration.active && integration.data.name === "BoostpointConnect" && (
        <div>
          <NotificationSettings userProfile={userProfile} />
          <Card>
            <BpConnectDetails
              userProfile={userProfile}
              userCompany={userCompany}
              smsCredits={props.smsCredits}
            />
          </Card>
          <FieldMapping integration={integration.data} />
        </div>
      )}
      {(!integration.active || showAbout) && (
        <div>
          <About toggleContact={toggleContact} />
        </div>
      )}
      <ConnectModal
        showModal={modalSignUp}
        userProfile={userProfile}
        userCompany={userCompany}
        alert={props.alert}
        onClose={handleModalClose}
        history={props.history}
        integrationData={integration.data}
        companyGet={props.companyGet}
        active={integration.active}
      />
      <BpModal
        open={modalContactSupport}
        title='Contact Support'
        onCloseMethod={toggleContact}
        primaryAction={{
          btnLabel: "Close",
          action: toggleContact
        }}
        body={
          <p>
            Contact Customer Support at{" "}
            <a href='mailto:help@boostpoint.com'>help@boostpoint.com.</a>
          </p>
        }
      />
    </div>
  );
};

export default withTheme(IntegrationSettings);
