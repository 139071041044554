/* global FB */
import moment from "moment";
import { parseFullName } from "parse-full-name";
import * as _ from "underscore";
import { businessPageUpdate } from "./FacebookAPI";
import { invoiceDelete, invoiceVoid } from "./StripeAPI";

export const formatMoney = (amount, withCommas, formatCents) => {
  let number = Number(amount);
  if (formatCents) {
    number = number / 100;
  }
  let formatAmount = number.toFixed(2);
  if (isNaN(formatAmount)) {
    return "";
  }
  if (withCommas) {
    formatAmount = formatAmount
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return `$${formatAmount}`;
};

export const formatPlanPrice = amount => {
  const number = Number(amount);
  const formatAmount = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `$${formatAmount}`;
};

export const formatTenth = value => {
  const number = Number(value);
  if (isNaN(number)) {
    return "";
  }
  const formatAmount = number.toFixed(2);
  return formatAmount;
};

export const getDaysDiff = days => {
  const { endDate, startDate } = days;
  return moment(endDate, "YYYY-MM-DD").diff(
    moment(startDate, "YYYY-MM-DD"),
    "days"
  );
};

export const dateFormat = (date, timezone, format) => {
  const useFormat = format || "MM-DD-YYYY";
  let dateFormatted;
  if (timezone) {
    const dateMoment = moment(date).tz(timezone);
    dateFormatted = dateMoment.format(useFormat);
  } else {
    const dateMoment = moment(date);
    dateFormatted = dateMoment.format(useFormat);
  }
  return dateFormatted;
};

export const urlValidator = (str, protocol = false, facebook = true) => {
  // protocol is a boolean to check if http(s) is required or not
  // facebook is a boolean to check if facebook is a valid domain
  if (!facebook) {
    const facebookPattern = new RegExp("(facebook.com)", "i");
    if (facebookPattern.test(str)) {
      return "facebook.com is not a valid domain. You must link to an external website.";
    }
  }
  if (protocol) {
    var pattern = new RegExp(
      "^(https?://)(w{3}[.])?([-a-zA-Z0-9]{3,63}){1}([.]{1}[a-zA-Z]+)(/?[-.a-zA-Z0-9#?&//=:%]+)?$",
      "i"
    ); // fragment locator
  } else {
    pattern = new RegExp(
      "^(https?://)?(w{3}[.])?([-a-zA-Z0-9]{3,63}){1}([.]{1}[a-zA-Z]+)(/?[-.a-zA-Z0-9#?&//=:%]+)?$",
      "i"
    ); // fragment locator
    console.log("checking URL validation", pattern.test(str));
  }
  if (!pattern.test(str)) {
    return "The URL you entered is not valid!";
  } else {
    return true;
  }
};

export const emailValidator = str => {
  console.log("email to validate", str);
  var pattern = new RegExp("^[a-zA-Z0-9+.-]+@[a-zA-Z0-9-]+[.][A-Za-z]+$", "i"); // fragment locator
  if (!pattern.test(str)) {
    console.log("email is not valid");
    return false;
  } else {
    console.log("email is valid");
    return true;
  }
};

export const handleFloaterClick = (clickTarget, floaters) => {
  const newFloaters = floaters;
  const thisFloater = newFloaters[clickTarget];
  Object.keys(newFloaters).forEach(key => {
    if (key === clickTarget) {
      newFloaters[clickTarget] = !thisFloater;
    } else if (newFloaters[key]) {
      newFloaters[key] = false;
    }
  });
  return newFloaters;
};

export const handleFloaterClose = (e, floaters) => {
  const targetName = e.target.getAttribute("name");
  if (floaters && (!targetName || targetName !== "floater-toggle")) {
    const newFloaters = floaters;
    Object.keys(newFloaters).forEach(key => {
      if (newFloaters[key]) {
        newFloaters[key] = false;
      }
    });
    return newFloaters;
  }
};

export const getUnique = (value, index, self) => self.indexOf(value) === index;

export const groupByArray = (xs, key) =>
  xs.reduce(function (rv, x) {
    const v = key instanceof Function ? key(x) : x[key];
    const el = rv.find(r => r && r.key === v);
    if (el) {
      el.values.push(x);
    } else {
      rv.push({ key: v, values: [x] });
    }
    return rv;
  }, []);

export const getPagePicture = async (platformToken, businessPage) => {
  try {
    FB.api(
      `/${businessPage.pageId}/picture?access_token=${platformToken.token}`,
      {
        redirect: "0"
      },
      async response => {
        if (response && !response.error) {
          try {
            /* handle the result */
            if (response.data && response.data.url) {
              const newPageImage = response.data.url;
              if (newPageImage && businessPage.image !== newPageImage) {
                console.log("Updating page image");
                await businessPageUpdate({
                  ...businessPage,
                  image: newPageImage
                });
              }
            }
          } catch (err) {
            console.log("error getting image", err);
          }
        }
        return true;
      }
    );
  } catch (err) {
    console.log(err);
  }
};

export const colorDark = x => {
  let color = x;
  // Variables for red, green, blue values
  let r, g, b;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If hex --> Convert it to RGB: http://gist.github.com/983661
    const c = color.substring(1); // strip #
    const rgb = parseInt(c, 16); // convert hex to decimal
    r = (rgb >> 16) & 0xff; // extract red
    g = (rgb >> 8) & 0xff; // extract green
    b = (rgb >> 0) & 0xff; // extract blue

    const l = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
    if (l < 72) {
      return true;
    } else {
      return false;
    }
  }
  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
  // Using the HSP value, determine whether the color is light or dark
  // return true if the color is dark
  if (hsp > 128) {
    return false;
  } else {
    return true;
  }
};

export const deleteCreditInvoice = async (stripeInfo = {}) => {
  console.log("checking pending");
  const { invoices = [] } = stripeInfo;
  try {
    const wait = [];
    invoices.forEach(async i => {
      if (i.status === "invoice finalized") {
        wait.push(invoiceVoid(i.invoiceId));
      } else {
        wait.push(invoiceDelete(i.invoiceId));
      }
    });
    await Promise.all(wait);
    return true;
  } catch (err) {
    console.log("error deleting invoice", err.response);
    throw new Error(err.response);
  }
};

export const getInitials = contact => {
  let initials = "";
  if (contact.full_name) {
    if (Array.isArray(contact.full_name) && contact.full_name.length > 0) {
      const name = parseFullName(contact.full_name[0]);
      if (name.first) {
        initials = name.first.charAt(0).toUpperCase();
      }
      if (name.last) {
        initials = initials + name.last.charAt(0).toUpperCase();
      }
    } else {
      const name = parseFullName(contact.full_name);
      if (name.first) {
        initials = name.first.charAt(0).toUpperCase();
      }
      if (name.last) {
        initials = initials + name.last.charAt(0).toUpperCase();
      }
    }
  }
  if (!contact.full_name && contact.first_name) {
    if (Array.isArray(contact.first_name) && contact.first_name.length > 0) {
      initials = contact.first_name[0].first.charAt(0).toUpperCase();
    } else {
      initials = contact.first_name.charAt(0).toUpperCase();
    }
  } else if (!contact.full_name && contact.firstName) {
    if (Array.isArray(contact.firstName) && contact.firstName.length > 0) {
      initials = contact.firstName[0].first.charAt(0).toUpperCase();
    } else {
      initials = contact.firstName.charAt(0).toUpperCase();
    }
  }
  if (!contact.full_name && contact.last_name) {
    if (Array.isArray(contact.last_name) && contact.last_name.length > 0) {
      initials = initials + contact.last_name[0].charAt(0).toUpperCase();
    } else {
      initials = initials + contact.last_name.charAt(0).toUpperCase();
    }
  } else if (!contact.full_name && contact.lastName) {
    if (Array.isArray(contact.lastName) && contact.lastName.length > 0) {
      initials = initials + contact.lastName[0].charAt(0).toUpperCase();
    } else {
      initials = initials + contact.lastName.charAt(0).toUpperCase();
    }
  }
  return initials;
};

export const monthsAbr = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

export const provinceList = [
  { name: "Alberta", abr: "AB" },
  { name: "British Columbia", abr: "BC" },
  { name: "Manitoba", abr: "MB" },
  { name: "New Brunswick", abr: "NB" },
  { name: "Newfoundland and Labrador", abr: "NL" },
  { name: "Northwest Territories", abr: "NT" },
  { name: "Nova Scotia", abr: "NS" },
  { name: "Nunavut", abr: "NU" },
  { name: "Ontario", abr: "ON" },
  { name: "Prince Edward Island", abr: "PE" },
  { name: "Quebec", abr: "QC" },
  { name: "Saskatchewan", abr: "SK" },
  { name: "Yukon", abr: "YT" }
];

export const statesList = [
  { name: "Alabama", abr: "AL" },
  { name: "Alaska", abr: "AK" },
  { name: "Arizona", abr: "AZ" },
  { name: "Arkansas", abr: "AR" },
  { name: "California", abr: "CA" },
  { name: "Colorado", abr: "CO" },
  { name: "Connecticut", abr: "CT" },
  { name: "Delaware", abr: "DE" },
  { name: "District of Columbia", abr: "DC" },
  { name: "Florida", abr: "FL" },
  { name: "Georgia", abr: "GA" },
  { name: "Hawaii", abr: "HI" },
  { name: "Idaho", abr: "ID" },
  { name: "Illinois", abr: "IL" },
  { name: "Indiana", abr: "IN" },
  { name: "Iowa", abr: "IA" },
  { name: "Kansas", abr: "KS" },
  { name: "Kentucky", abr: "KY" },
  { name: "Louisiana", abr: "LA" },
  { name: "Maine", abr: "ME" },
  { name: "Maryland", abr: "MD" },
  { name: "Massachusetts", abr: "MA" },
  { name: "Michigan", abr: "MI" },
  { name: "Minnesota", abr: "MN" },
  { name: "Mississippi", abr: "MS" },
  { name: "Missouri", abr: "MO" },
  { name: "Montana", abr: "MT" },
  { name: "Nebraska", abr: "NE" },
  { name: "Nevada", abr: "NV" },
  { name: "New Hampshire", abr: "NH" },
  { name: "New Jersey", abr: "NJ" },
  { name: "New Mexico", abr: "NM" },
  { name: "New York", abr: "NY" },
  { name: "North Carolina", abr: "NC" },
  { name: "North Dakota", abr: "ND" },
  { name: "Ohio", abr: "OH" },
  { name: "Oklahoma", abr: "OK" },
  { name: "Oregon", abr: "OR" },
  { name: "Pennsylvania", abr: "PA" },
  { name: "Rhode Island", abr: "RI" },
  { name: "South Carolina", abr: "SC" },
  { name: "South Dakota", abr: "SD" },
  { name: "Tennessee", abr: "TN" },
  { name: "Texas", abr: "TX" },
  { name: "Utah", abr: "UT" },
  { name: "Vermont", abr: "VT" },
  { name: "Virginia", abr: "VA" },
  { name: "Washington", abr: "WA" },
  { name: "West Virginia", abr: "WV" },
  { name: "Wisconsin", abr: "WI" },
  { name: "Wyoming", abr: "WY" }
];

export const timezones = [
  "America/Boise",
  "America/Chicago",
  "America/Dawson_Creek",
  "America/Denver",
  "America/Detroit",
  "America/Halifax",
  "America/Indiana/Indianapolis",
  "America/Indiana/Knox",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Tell_City",
  "America/Indiana/Vevay",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Indianapolis",
  "America/Juneau",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Los_Angeles",
  "America/Montreal",
  "America/New_York",
  "America/Nipigon",
  "America/Nome",
  "America/North_Dakota/Beulah",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/Phoenix",
  "America/Regina",
  "America/Sitka",
  "America/Thunder_Bay",
  "America/Toronto",
  "America/Vancouver",
  "America/Winnipeg"
];

export const axiosResponse = response => {
  if (response.data && response.data.data) {
    return response.data.data;
  } else {
    return response.data;
  }
};

export const errorHandler = err => {
  const { response = {} } = err;
  const { status: responseError, data, config: { url = "" } = {} } = response;
  console.log("responseError", response);
  if (data && data.raw) {
    const { code, message, statusCode: status } = data.raw;
    return { responseError, code, message, status, url };
  } else if (data && data.Message) {
    const { Message: message = "" } = data;
    return { responseError, message, status: responseError, url };
  }
  return { responseError, message: err.message, status: responseError, url };
};

const maxTextWordLength = 34;
const maxHeadlineCharLength = 60;
const maxUrlLength = 22;
const maxLinkDescCharLength = 60;

export const processText = text => {
  const trimmedText = text.trim();
  let display = "";
  if (text) {
    display = trimmedText.split(" ", maxTextWordLength).join(" ");
    // If the difference is too small, just show the whole string
    const textLengthDiff = text.length - display.length;
    if (textLengthDiff <= 15) {
      display = trimmedText;
    } else {
      // Sometimes the last character is a comma or period. If that is the case, don't show it.
      const lastTextChar = display[display.length - 1];
      const isAlphanumeric = /^[a-z0-9]+$/i.test(lastTextChar);
      if (!isAlphanumeric) {
        display = display.substring(0, display.length - 1);
      }
    }
  }
  return {
    trimmedText,
    displayText: display
  };
};

const checkWordSplit = (text, display) => {
  const textSplit = text.split(" ");
  let newDisplay = display;
  const displaySplit = display.split(" ");
  const lastWord = displaySplit.length - 1;
  let newArray = [];
  if (textSplit[lastWord] !== displaySplit[lastWord]) {
    newArray = [
      ...displaySplit.slice(0, lastWord),
      ...textSplit.slice(lastWord, lastWord + 1)
    ];
  }
  if (newArray.length !== 0) {
    let shorterArray = [];
    if (newArray[lastWord].length > 15) {
      shorterArray = [...newArray.slice(0, lastWord)];
    }
    if (shorterArray.length !== 0) {
      newDisplay = shorterArray.join(" ");
    } else {
      newDisplay = newArray.join(" ");
    }
  }

  return newDisplay;
};

const checkLastCharAlphanumeric = text => {
  let newText = text;
  const lastTextChar = text[text.length - 1];
  const isAlphanumeric = /^[a-z0-9]+$/i.test(lastTextChar);
  if (!isAlphanumeric) {
    newText = text.substring(0, text.length - 1);
  }
  return newText;
};

export const processHeadline = headline => {
  let displayHeadline = "";
  const trimmedHeadline = headline.trim();
  if (headline) {
    displayHeadline = trimmedHeadline.split("", maxHeadlineCharLength).join("");
    if (headline.length - displayHeadline.length <= 15) {
      displayHeadline = trimmedHeadline;
    }
    // Make sure we are not splitting the last word
    displayHeadline = checkWordSplit(trimmedHeadline, displayHeadline);
    displayHeadline = checkLastCharAlphanumeric(displayHeadline);
  }
  return { headline: displayHeadline, trimmedHeadline };
};

export const processUrl = url => {
  let displayWebsite = "";
  if (url) {
    displayWebsite = url.split("", maxUrlLength).join("");
    if (displayWebsite !== url) {
      displayWebsite += "...";
    }
  }
  return displayWebsite;
};

export const processLinkDesc = newsFeedLinkDesc => {
  const trimmedLinkDesc = newsFeedLinkDesc.trim();
  let displayNewsFeedLinkDesc = "";
  if (trimmedLinkDesc) {
    displayNewsFeedLinkDesc = trimmedLinkDesc
      .split("", maxLinkDescCharLength)
      .join("");
  }
  const textLinkLengthDiff =
    trimmedLinkDesc.length - displayNewsFeedLinkDesc.length;
  if (textLinkLengthDiff <= 15) {
    displayNewsFeedLinkDesc = trimmedLinkDesc;
  } else {
    // Sometimes the last character is a comma or period. If that is the case, don't show it.
    displayNewsFeedLinkDesc = checkWordSplit(
      trimmedLinkDesc,
      displayNewsFeedLinkDesc
    );
    displayNewsFeedLinkDesc = checkLastCharAlphanumeric(
      displayNewsFeedLinkDesc
    );
    if (trimmedLinkDesc !== displayNewsFeedLinkDesc) {
      displayNewsFeedLinkDesc += "...";
    }
  }
  return displayNewsFeedLinkDesc;
};

export const industries = {
  SENIOR_CARE: "Senior Care",
  MANUFACTURING: "Manufacturing",
  FOOD_SERVICE: "Food Service",
  HOME_SERVICES: "Home Services",
  ROOFING: "Roofing",
  REAL_ESTATE: "Real Estate",
  EQUIPTER_RENTAL: "Equipter Rental",
  OTHER: " Other"
};

export const callsToActionLinkClick = {
  CONTACT_US: "Contact Us",
  LEARN_MORE: "Learn More"
};

export const callsToActionLeads = {
  LEARN_MORE: "Learn More",
  SIGN_UP: "Sign Up",
  APPLY_NOW: "Apply Now",
  GET_QUOTE: "Get Quote"
};

export const contactSource = {
  SOCIAL_MEDIA: "Social Media",
  MANUAL_ENTRY: "Manual Entry"
};

export const arrayMove = (arr, a, b) => {
  let oldIndex = a;
  let newIndex = b;
  if (oldIndex < 0) {
    oldIndex += arr.length;
  }
  if (newIndex < 0) {
    newIndex += arr.length;
  }
  if (newIndex >= arr.length) {
    var k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};

export const arrayEquals = (a, b) => {
  if (!a && !b) {
    return true;
  } else if (!a) {
    return false;
  } else if (!b) {
    return false;
  }
  if (a.length !== b.length) {
    return false;
  }
  let equals = true;
  a.forEach((item, index) => {
    if (item instanceof Array && b[index] instanceof Array) {
      if (!arrayEquals(item, b[index])) {
        equals = false;
      }
    } else if (typeof item === "object" && typeof b[index] === "object") {
      if (!_.isEqual(item, b[index])) {
        equals = false;
      }
    } else if (item !== b[index]) {
      equals = false;
    }
  });
  return equals;
};

export const camelCaseConvert = str =>
  str
    .replace(/_+/g, " ")
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");

export const sanitizeString = (str, joinChar) =>
  str
    .toLowerCase()
    .split(" ")
    .map((word, index) => {
      const sanitized = word.replace(/[.,/#!$%^&*;:{}=\-_`<>'"~()]/g, "");
      if (index === 0) return sanitized;
      return sanitized.charAt(0).toUpperCase() + sanitized.slice(1);
    })
    .join(joinChar ? joinChar : "");
