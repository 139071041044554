import React from "react";
import axios from "axios";
import styled from "styled-components";
import AnimateHeight from "react-animate-height";
import WorkflowModal from "./WorkflowModal";
import FilterCampaigns from "./FilterCampaigns";
import FilterTags from "./FilterTags";
import FilterStatus from "./FilterStatus";
import { Button } from "../../styled/Buttons";
import { LoaderDotsSmall } from "../../elements/Loaders";
import {
  AddAction,
  ActionOptions,
  ActionGrid,
  ActionType,
  ActionIcon,
  ActionText,
  ActionEditButton,
  ActionEditDelete,
  FlowCard,
  Icon,
  FlowHeader,
  FlowHeaderToggle,
  FlowHeaderLeft,
  FlowHeaderRight,
  FlowInputLabel,
  H2,
  H3,
  AndOrDivider
} from "./styles";
import { getAllCompanyCampaigns } from "../../../utils/FacebookAPI";

const ActionBody = styled.div`
  text-align: left;
`;

const ActionItemWrapper = styled.div`
  padding: 20px 40px;
  border-bottom: 1px solid #d9d9da;
`;

const AddActionWrapper = styled.div`
  padding: 20px 40px;
  text-align: center;
`;

const UrgentText = styled.p`
  font-weight: 600;
  font-style: italic;
`;

const Filter = props => {
  const {
    filters = [],
    updateFilters,
    userProfile,
    userCompany,
    errMessages
  } = props;
  const [filtersLength, setFiltersLength] = React.useState(0);
  const [selectNewFilter, setNewFilterStatus] = React.useState(false);
  const [openFilterModal, setOpenFilterModal] = React.useState(false);
  const [openEditCampaign, setOpenEditCampaign] = React.useState(false);
  const [openEditTags, setOpenEditTags] = React.useState(false);
  const [openEditStatus, setOpenEditStatus] = React.useState(false);
  const [modalDeleteConfirm, setModalDeleteConfirm] = React.useState(false);
  const [currentDeleteType, setCurrentDeleteType] = React.useState("");
  const [currentDeleteIndex, setCurrentDeleteIndex] = React.useState("");
  const [deleting, setDeleteLoading] = React.useState(false);
  const [openOverlapModal, setOpenOverlapModal] = React.useState(false);
  const [campaigns, setCampaigns] = React.useState([]);
  const [filteredCampaigns, setFilteredCampaigns] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState("");
  const [triggerOpen, setTriggerOpen] = React.useState(true);
  const [height, setHeight] = React.useState("auto");

  React.useEffect(() => {
    const getCampaigns = async () => {
      try {
        const conversationResponse = await getAllCompanyCampaigns();
        setCampaigns(conversationResponse);
        setFilteredCampaigns(conversationResponse.data);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log("error getting conversation list", err.response.data);
        } else {
          console.log("error getting conversation list", err.message);
        }
      }
    };
    getCampaigns();
  }, [userProfile]);

  React.useEffect(() => {
    const length = filters.length;
    setFiltersLength(length);
  }, [filters]);

  const toggleFilterModal = action => {
    setNewFilterStatus(action);
  };

  const removeFilter = (type, index) => {
    if (!modalDeleteConfirm) {
      setCurrentDeleteType(type);
      setCurrentDeleteIndex(index);
      setModalDeleteConfirm(true);
    } else {
      console.log("remove filter: ", currentDeleteType, currentDeleteIndex);
      setDeleteLoading(true);
      const removeFilters = [...filters];
      removeFilters.splice(currentDeleteIndex, 1);
      const updatedFilters =
        removeFilters.length === 0
          ? {
              filters: [
                {
                  campaignIds: [],
                  tagIds: [],
                  statusIds: [],
                  type: "ALL_CONTACTS"
                }
              ]
            }
          : { filters: removeFilters };
      console.log(updatedFilters);
      updateFilters(updatedFilters);
      setDeleteLoading(false);
      setCurrentDeleteType("");
      setCurrentDeleteIndex("");
      setModalDeleteConfirm(false);
    }
  };

  const addFilter = addedFilter => {
    const removedFilters = filters.filter(item => item.type !== "ALL_CONTACTS");
    const exists = removedFilters.some(item => item.type === addedFilter.type);
    let newFilter = [];
    if (exists) {
      newFilter = removedFilters.map(item => {
        if (item.type === addedFilter.type) {
          return { ...addedFilter };
        } else {
          return item;
        }
      });
    } else {
      newFilter = [...removedFilters, addedFilter];
    }
    const newFilters = { filters: [...newFilter] };
    updateFilters(newFilters);
    setOpenEditCampaign(false);
    setOpenEditStatus(false);
    setOpenEditTags(false);
  };

  const toggleHeight = () => {
    const newHeight = height === 0 ? "auto" : 0;
    setHeight(newHeight);
    setTriggerOpen(!triggerOpen);
  };

  return (
    <FlowCard error={errMessages.filter ? true : false}>
      <FlowHeader triggerOpen={triggerOpen}>
        <FlowHeaderLeft>
          <Icon className='material-icons-outlined'>filter_alt</Icon>
          <H2>Create Filters</H2>
        </FlowHeaderLeft>
        <FlowHeaderRight>
          <FlowHeaderToggle
            triggerOpen={triggerOpen}
            onClick={() => toggleHeight()}
          >
            <span className='material-icons-outlined'>keyboard_arrow_up</span>
          </FlowHeaderToggle>
        </FlowHeaderRight>
      </FlowHeader>
      <AnimateHeight
        duration={200}
        height={height} // see props documentation below
      >
        <ActionBody>
          {filters.map((filterItem, index) => {
            const { type } = filterItem;
            if (type && type === "ALL_CONTACTS") {
              return (
                <ActionItemWrapper key={index}>
                  <H3>All Contacts Selected (No Filter Used)</H3>
                </ActionItemWrapper>
              );
            }
            if (type && type === "CAMPAIGN") {
              const campaignLength = filterItem.campaignIds.length;
              return (
                <ActionItemWrapper key={index}>
                  <H3>Campaign(s) Filter</H3>
                  <FlowInputLabel bold>
                    {campaignLength} Campaigns Selected
                  </FlowInputLabel>
                  <ActionEditButton>
                    <Button onClick={() => setOpenEditCampaign(true)} small>
                      Edit Campaigns
                    </Button>
                    <ActionEditDelete>
                      <span
                        className='material-icons'
                        onClick={() => removeFilter(type, index)}
                      >
                        delete
                      </span>
                    </ActionEditDelete>
                  </ActionEditButton>
                  {filtersLength - 1 > index && (
                    <AndOrDivider>And</AndOrDivider>
                  )}
                </ActionItemWrapper>
              );
            }
            if (type && type === "STATUS") {
              const statusLength = filterItem.statusIds.length;
              return (
                <ActionItemWrapper key={index}>
                  <H3>Contact Status Filter</H3>
                  <FlowInputLabel bold>
                    {`${statusLength} Contact Status${
                      statusLength > 1 ? "es" : ""
                    } Selected`}
                  </FlowInputLabel>
                  <ActionEditButton>
                    <Button onClick={() => setOpenEditStatus(true)} small>
                      Edit Status
                    </Button>
                    <ActionEditDelete>
                      <span
                        className='material-icons'
                        onClick={() => removeFilter(type, index)}
                      >
                        delete
                      </span>
                    </ActionEditDelete>
                  </ActionEditButton>
                  {filtersLength - 1 > index && (
                    <AndOrDivider>And</AndOrDivider>
                  )}
                </ActionItemWrapper>
              );
            }
            if (type && type === "TAGS") {
              const tagLength = filterItem.tagIds.length;
              return (
                <ActionItemWrapper key={index}>
                  <H3>Contact Tag(s) Filter</H3>
                  <FlowInputLabel bold>
                    {tagLength} Contact Tag(s) Selected
                  </FlowInputLabel>
                  <ActionEditButton>
                    <Button onClick={() => setOpenEditTags(true)} small>
                      Edit Tag(s)
                    </Button>
                    <ActionEditDelete>
                      <span
                        className='material-icons'
                        onClick={() => removeFilter(type, index)}
                      >
                        delete
                      </span>
                    </ActionEditDelete>
                  </ActionEditButton>
                  {filtersLength - 1 > index && (
                    <AndOrDivider>And</AndOrDivider>
                  )}
                </ActionItemWrapper>
              );
            }
          })}
          <AddActionWrapper>
            <AddAction>
              <span
                onClick={() => toggleFilterModal(true)}
                className='material-icons-outlined'
              >
                add_circle_outline
              </span>
            </AddAction>
          </AddActionWrapper>
        </ActionBody>
        <WorkflowModal
          open={selectNewFilter}
          title={
            <div>
              <Icon className='material-icons-outlined'>add</Icon>{" "}
              <H2>Add a Filter</H2>
            </div>
          }
          onCloseMethod={() => toggleFilterModal(false)}
          body={
            <div>
              <ActionOptions>
                <ActionGrid>
                  <ActionType
                    top
                    right
                    onClick={() => {
                      const exists = filters.some(
                        filter => filter.type === "CAMPAIGN"
                      );
                      if (!exists) {
                        setOpenEditCampaign(true);
                        toggleFilterModal(false);
                      }
                    }}
                    disabled={filters.some(
                      filter => filter.type === "CAMPAIGN"
                    )}
                  >
                    <ActionIcon
                      disabled={filters.some(
                        filter => filter.type === "CAMPAIGN"
                      )}
                    >
                      <span className='material-icons-outlined'>
                        add_circle
                      </span>
                    </ActionIcon>
                    <ActionText
                      disabled={filters.some(
                        filter => filter.type === "CAMPAIGN"
                      )}
                    >
                      Campaign
                    </ActionText>
                  </ActionType>
                  <ActionType
                    top
                    onClick={() => {
                      const exists = filters.some(
                        filter => filter.type === "STATUS"
                      );
                      if (!exists) {
                        setOpenEditStatus(true);
                        toggleFilterModal(false);
                      }
                    }}
                    disabled={filters.some(filter => filter.type === "STATUS")}
                  >
                    <ActionIcon
                      disabled={filters.some(
                        filter => filter.type === "STATUS"
                      )}
                    >
                      <span className='material-icons-outlined'>
                        add_circle
                      </span>
                    </ActionIcon>
                    <ActionText
                      disabled={filters.some(
                        filter => filter.type === "STATUS"
                      )}
                    >
                      Contact Status
                    </ActionText>
                  </ActionType>
                  <ActionType
                    right
                    onClick={() => {
                      const exists = filters.some(
                        filter => filter.type === "TAGS"
                      );
                      if (!exists) {
                        setOpenEditTags(true);
                        toggleFilterModal(false);
                      }
                    }}
                    disabled={filters.some(filter => filter.type === "TAGS")}
                  >
                    <ActionIcon
                      disabled={filters.some(filter => filter.type === "TAGS")}
                    >
                      <span className='material-icons-outlined'>
                        add_circle
                      </span>
                    </ActionIcon>
                    <ActionText
                      disabled={filters.some(filter => filter.type === "TAGS")}
                    >
                      Contact Tag
                    </ActionText>
                  </ActionType>
                </ActionGrid>
              </ActionOptions>
            </div>
          }
        />
        {openEditCampaign && (
          <FilterCampaigns
            setOpenEditCampaign={setOpenEditCampaign}
            filters={filters}
            addFilter={addFilter}
            timezone={userProfile.timezone}
          />
        )}
        {openEditStatus && (
          <FilterStatus
            industry={userCompany?.industry}
            setOpenEditStatus={setOpenEditStatus}
            filters={filters}
            addFilter={addFilter}
          />
        )}
        {openEditTags && (
          <FilterTags
            setOpenEditTags={setOpenEditTags}
            filters={filters}
            addFilter={addFilter}
          />
        )}
      </AnimateHeight>
      <WorkflowModal
        open={modalDeleteConfirm}
        title='Delete Filter'
        onCloseMethod={() => {
          setCurrentDeleteType("");
          setCurrentDeleteIndex("");
          setModalDeleteConfirm(false);
        }}
        body={
          <div>
            <p>
              Are you sure you want to delete this '{currentDeleteType}' filter?
            </p>
            <UrgentText>This action can't be undone!</UrgentText>
            <Button
              onClick={() => {
                setCurrentDeleteType("");
                setCurrentDeleteIndex("");
                setModalDeleteConfirm(false);
              }}
              disabled={deleting}
            >
              Cancel
            </Button>
            <Button solid onClick={() => removeFilter()} disabled={deleting}>
              Delete
              {deleting && <LoaderDotsSmall />}
            </Button>
          </div>
        }
      />
    </FlowCard>
  );
};

export default Filter;
