import React from "react";
import styled from "styled-components";
import {
  integrationGetByCompany,
  integrationUpdate
} from "../../../utils/IntegrationsAPI";
import BpModal from "../../elements/BpModal";
import {
  Dropdown,
  InputBox,
  InputBoxWrapper,
  InputContainer,
  InputLabel
} from "../../styled/Input";
import media from "../../styled/MediaQueries";

const FieldWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 30px;
  ${media.phone`
    grid-template-columns: 1fr;
  `}
`;

const ArrowIcon = styled.span`
  display: flex;
  justify-content: center;
  ${media.phone`
    span {
      transform: rotate(90deg)
    }
  `}
`;

const CustomFieldWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  ${media.phone`
    grid-template-columns: 1fr;
  `}
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const IntegrationContainer = styled(InputContainer)`
  display: grid;
  grid-template-columns: 50px 1fr;
`;

const IntegrationLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    /* border-radius: ${props => (props.boostpoint ? "unset" : "50%")}; */
    width: 80%;
  }
`;

const SourceField = styled(InputContainer)`
  ${media.phone`
    margin-bottom: 0;
  `}
`;

const bpContactFields = {
  firstName: "First Name",
  lastName: "Last Name",
  company: "Company",
  email: "Email",
  phone: "Phone Number",
  mobilePhone: "Mobile Phone",
  address: "Address",
  address2: "Address 2",
  city: "City",
  contactState: "State",
  zip: "Zip",
  website: "Website"
};

const FieldMapping = props => {
  const {
    newField,
    openFieldMapping,
    userCompany: { _id: companyId } = {}
  } = props;
  const [state, setState] = React.useState({
    customFields: [],
    fieldMap: "",
    integrationFields: {},
    integrationMapping: {},
    integrationLogos: {},
    customMapping: {},
    integrations: [],
    bpMapping: {},
    modalFieldMap: false,
    saving: false
  });

  React.useEffect(() => {
    const getIntegration = async () => {
      const integrations = await integrationGetByCompany(companyId);
      const integration = integrations.find(i => i.type === "FACEBOOK");
      console.log("integration", integration);
      if (integration) {
        const { fieldMappings = [] } = integration;
        const matching = fieldMappings.find(
          f => f.sourceField === newField.key
        );
        let fieldMap = "";
        if (matching) {
          fieldMap = matching.destinationField;
        }
        setState(prevState => ({
          ...prevState,
          customMapping: integration.fieldMappings,
          modalFieldMap: true,
          fieldMap
        }));
      }
    };
    if (openFieldMapping) {
      getIntegration();
    }
  }, [companyId, newField, openFieldMapping]);

  const changeField = input => {
    const { value, name } = input;
    console.log("value", value);
    const newMapping = state.customMapping.forEach(
      f => f.destinationField === value
    );
    if (newMapping) {
      newMapping.sourceField = name;
      setState({ ...state, customMapping: newMapping });
    }
  };

  const changeBPField = input => {
    const { bpMapping, selectedField } = state;
    let value = input.target.value;
    if (value === "Select One") {
      value = null;
    }
    bpMapping[selectedField.key] = value;
    setState({ ...state, bpMapping });
  };

  const saveMappings = async () => {
    const { customMapping, integrations } = state;
    try {
      const wait = [];
      integrations.forEach(integration => {
        if (customMapping[integration.type]) {
          integration.fields = {
            ...integration.fields,
            ...customMapping[integration.type]
          };
          wait(integrationUpdate(integration));
        }
      });
    } catch (err) {
      console.log("error saving field mapping", err);
    }
  };

  return (
    <BpModal
      open={state.modalFieldMap}
      title='Field Map'
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      contentLabel='Field Map'
      onCloseMethod={() => {
        setState({ ...state, modalFieldMap: false });
      }}
      primaryAction={{
        btnLabel: "Save",
        action: saveMappings,
        loading: state.saving
      }}
      secondaryAction={{
        btnLabel: "Cancel",
        action: () => setState({ ...state, modalFieldMap: false }),
        loading: state.saving
      }}
      body={
        newField ? (
          <div>
            <p>
              Map your new field so the field is imported correctly in your
              integrations.
            </p>
            <CustomFieldWrapper>
              <FieldContainer>
                <InputLabel>Source</InputLabel>
                <FieldWrapper>
                  <SourceField customWidth='auto'>
                    <InputBoxWrapper>
                      <InputBox type='text' readOnly value={newField.name} />
                    </InputBoxWrapper>
                  </SourceField>
                  <ArrowIcon>
                    <span className='material-icons'>arrow_right_alt</span>
                  </ArrowIcon>
                </FieldWrapper>
              </FieldContainer>
              <FieldContainer>
                <InputLabel>Destination</InputLabel>
                <IntegrationContainer customWidth='auto'>
                  <IntegrationLogo boostpoint>
                    <img
                      src='https://res.cloudinary.com/equipter/image/upload/c_scale,h_50/v1522846032/SolidLogo.png'
                      alt='Boostpoint logo'
                      title='Boostpoint'
                    />
                  </IntegrationLogo>
                  <InputBoxWrapper>
                    <Dropdown
                      name='boostpoint'
                      data-namereadable='Boostpoint'
                      value={state.fieldMap}
                      onChange={changeBPField}
                    >
                      <option>Select One</option>
                      {bpContactFields &&
                        Object.keys(bpContactFields).map((value, key) => (
                          <option key={key} value={value}>
                            {bpContactFields[value]}
                          </option>
                        ))}
                    </Dropdown>
                  </InputBoxWrapper>
                </IntegrationContainer>
                {state.integrations.map((integrationType, index) => (
                  <IntegrationContainer key={index}>
                    <IntegrationLogo>
                      <img
                        src={`https://res.cloudinary.com/equipter/image/upload/c_scale,h_50/v1592316065/Boostpoint_Images/${
                          state.integrationData[integrationType]
                            ? state.integrationData[integrationType].icon
                            : state.integrationLogos[integrationType]
                        }`}
                        alt={
                          state.integrationData[integrationType]
                            ? state.integrationData[integrationType]
                                .nameReadable
                            : integrationType
                        }
                        title={
                          state.integrationData[integrationType]
                            ? state.integrationData[integrationType]
                                .nameReadable
                            : integrationType
                        }
                      />
                    </IntegrationLogo>
                    <InputBoxWrapper>
                      <Dropdown
                        name={state.selectedField.key}
                        data-integrationtype={integrationType}
                        value={state.fieldMap}
                        onChange={changeField}
                      >
                        <option value=''>Select One</option>
                        {Object.keys(bpContactFields).map(sourceField => (
                          <option value={sourceField} key={sourceField}>
                            {bpContactFields[sourceField]}
                          </option>
                        ))}
                      </Dropdown>
                    </InputBoxWrapper>
                  </IntegrationContainer>
                ))}
              </FieldContainer>
            </CustomFieldWrapper>
          </div>
        ) : null
      }
    />
  );
};

export default FieldMapping;
