import React from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import styled from "styled-components";
import * as _ from "underscore";

import BpModal from "../../elements/BpModal";
import InputError from "../../styled/InputErrorForm";

import { stripeCreate } from "../../../utils/StripeAPI";

const CardWrapper = styled.div`
  margin: 33px 20px 0;
`;

const PaymentFix = props => {
  const {
    userProfile: { firstName: first_name, lastName: last_name, email } = {},
    userCompany: { _id: companyId } = {},
    alert,
    modalCorrectPaymentMethod,
    paymentFailed,
    paymentFailedMessage
  } = props;
  const [loading, setLoading] = React.useState(false);
  const [errMessage, setErrMessage] = React.useState(paymentFailedMessage);
  const [ccValid, setCcValid] = React.useState({});

  React.useEffect(() => {
    if (paymentFailedMessage) {
      setErrMessage(paymentFailedMessage);
    }
  }, [paymentFailedMessage]);

  const validateCC = () =>
    new Promise((resolve, reject) => {
      if (_.isEmpty(ccValid)) {
        reject("Please enter a credit card to continue");
      } else {
        if (!ccValid.complete) {
          if (ccValid.empty && !ccValid.error) {
            console.error(ccValid);
            reject("You need to enter a credit card to activate your account");
          }
          if (!ccValid.error) {
            console.error(ccValid);
            reject(
              "There is an issue with your credit card. Please try to re-enter it."
            );
          }
          console.log(ccValid);
          if (ccValid.error) {
            console.error(ccValid);
            reject(ccValid.error.message);
          }
        } else {
          resolve(ccValid);
        }
      }
    });

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await validateCC();
      console.log("submitting");
      const { token } = await props.stripe.createToken({});
      console.log("token", token.id);
      const createBody = {
        companyId,
        newSource: token.id,
        metadata: { first_name, last_name },
        email: email
      };
      console.log("createbody", createBody);
      const response = await stripeCreate(createBody);
      console.log("response", response);
      setLoading(false);
      setErrMessage("");
      props.refreshStripe();
    } catch (err) {
      console.log(err.message);
      setErrMessage(err.message);
      setLoading(false);
    }
  };

  return (
    <div>
      <BpModal
        open={modalCorrectPaymentMethod}
        title={
          paymentFailed
            ? "Your Payment Method Failed"
            : "Please add a payment method"
        }
        primaryAction={{
          btnLabel: "Submit",
          action: handleSubmit,
          loading
        }}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        contentLabel='Payment Method Fix'
        body={
          <div>
            {paymentFailed ? (
              <div>
                <p>
                  Your payment method failed. Please correct your payment method
                </p>
              </div>
            ) : (
              <p>Enter your payment information to continue</p>
            )}
            <CardWrapper>
              <CardElement onChange={cc => setCcValid(cc)} />
            </CardWrapper>
            <InputError>{errMessage}</InputError>
          </div>
        }
        width='auto'
      />
    </div>
  );
};

export default injectStripe(PaymentFix);
