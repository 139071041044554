import React from "react";
import * as _ from "underscore";
import styled from "styled-components";
import Checkbox from "../../../elements/Checkbox";

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  &.wrapper {
    margin: 10px 0;
  }
`;

const StatusSelectMultiple = props => {
  const { selected = [], contactStatuses = [] } = props;
  const [statuses, setStatuses] = React.useState({});

  React.useEffect(() => {
    if (contactStatuses && contactStatuses.length > 0) {
      const newStatus = {};
      contactStatuses.forEach(c => {
        if (!newStatus[c.type]) {
          newStatus[c.type] = [c];
        } else {
          newStatus[c.type].push(c);
        }
      });
      setStatuses(newStatus);
    }
  }, [contactStatuses]);

  return !_.isEmpty(statuses) ? (
    <div>
      {Object.keys(statuses).map(t => (
        <div key={t}>
          <span>
            <strong>{t}</strong>
          </span>
          {statuses[t].map(status => (
            <CheckboxWrapper className='wrapper' key={status._id}>
              <Checkbox
                checked={selected.includes(status._id)}
                onChange={() => props.onChange(status._id)}
              />
              <span>{status.status}</span>
            </CheckboxWrapper>
          ))}
        </div>
      ))}
    </div>
  ) : null;
};

export default StatusSelectMultiple;
