import React from "react";
import BpModal from "../elements/BpModal";
import Floater from "react-floater";

// Import Styled Components
import ModalWrapper from "../styled/ModalWrapper";
import ModalLargeWrapper from "../styled/ModalLargeWrapper";
import {
  assetDelete,
  assetImagesGetByCompany,
  cloudinaryFormat
} from "../../utils/MediaAPI";
import { getStockImages } from "../../utils/CloudinaryAPI";
import ImageModalHeader from "./ImageModalHeader";
import UploadImagePane from "./UploadImagePane";
import MyImagesPane from "./MyImagesPane";
import StockImagesPane from "./StockImagesPane";
import ImageModalFooter from "./ImageModalFooter";

const floaterStyles = {
  wrapper: {
    cursor: "pointer"
  },
  floater: {
    zIndex: 1001
  },
  container: {
    borderRadius: 5,
    color: "rgb(51, 51, 51)",
    padding: 15,
    textAlign: "center"
  }
};

class ImageSelector extends React.Component {
  state = {
    myImages: [],
    freeImages: [],
    selectedImages: [],
    selectedImagesNumber: 0,
    imageMenuSelection: "myImages",
    progressBarValue: 0,
    progressBarDisplay: "none",
    loading: true,
    cloudinaryFreeImages: [],
    selectionChanged: false,
    showChangeWarning: false,
    deletingImage: false,
    deleteModal: false,
    deleteAsset: {},
    imageSnapshot: null
  };

  async componentDidMount() {
    const {
      pendingCampaign: { media }
    } = this.props;
    const selectedFiles = media
      .filter(item => item.mediaType === "IMAGE")
      .map(i => i.filename);
    const { userCompany: { industry = "" } = {} } = this.props;
    try {
      const myImages = await this.loadUserImages();
      const cloudinaryFreeImages = await getStockImages(industry);
      const selectedImages = [...myImages, ...cloudinaryFreeImages].filter(
        i => {
          console.log("image", i.name, i.public_id);
          return (
            selectedFiles.includes(`${i.name}.${i.format}`) ||
            selectedFiles.includes(`${i.public_id}.${i.format}`)
          );
        }
      );
      this.setState({
        selectedImages,
        cloudinaryFreeImages,
        loading: false
      });
    } catch (err) {
      console.log(err);
    }
  }

  loadUserImages = async () => {
    const { userCompany } = this.props;
    try {
      this.setState({ loading: true });
      const imageResponse = await assetImagesGetByCompany(userCompany._id);
      const images = imageResponse.filter(image => image.status === "ACTIVE");
      console.log("my images", images);
      this.setState({ myImages: images, loading: false });
      return images;
    } catch (err) {
      console.log("error getting user images", err.message);
      return [];
    }
  };

  setMenu = imageMenuSelection => {
    this.setState({ imageMenuSelection });
  };

  handleUploadImagesMenu = () => {
    this.setState({
      imageMenuSelection: "uploadImages"
    });
  };

  handleMyImagesMenu = () => {
    this.setState({
      imageMenuSelection: "myImages"
    });
  };

  handleFreeImagesMenu = () => {
    this.setState({
      imageMenuSelection: "freeImages"
    });
  };

  handleMyImagesSelected = async selectedImages => {
    if (selectedImages.length > 6) {
      console.warn("The maximum number of images is 6");
      this.props.alert({
        type: "error",
        msg: "The maximum number of images is 6"
      });
    } else {
      this.setState({
        selectedImages,
        selectedImagesNumber: selectedImages.length,
        selectionChanged: true
      });
    }
  };

  handleImagesSelected = async selectedImages => {
    if (selectedImages.length > 6) {
      console.warn("The maximum number of images is 6");
      this.props.alert({
        type: "error",
        msg: "The maximum number of images is 6"
      });
    } else {
      console.log("selectedImages", selectedImages);
      this.setState({
        selectedImages,
        selectedImagesNumber: selectedImages.length,
        selectionChanged: true
      });
    }
  };

  imageObjectFormat = image => ({
    filename: `${image.name}.${image.format}`,
    mediaType: image.type,
    status: image.status,
    title: "",
    adId: "",
    inactiveReason: "",
    effectiveStatus: "",
    adReviewFeedback: ""
  });

  handleStockImagesSelected = async image => {
    const { _id } = this.props.userCompany;
    const newImage = {
      companyId: _id,
      name: image.public_id,
      format: image.format,
      type: "IMAGE",
      status: "ACTIVE",
      height: image.height,
      width: image.width,
      createdDate: image.created_at,
      userPending: true,
      usedCampaignIds: []
    };
    const imagesArray = this.state.selectedImages.filter(
      i => i.name === newImage.name
    );
    if (imagesArray.length > 0) {
      const removedArray = this.state.selectedImages.filter(
        i => i.name !== newImage.name
      );
      this.setState({
        selectedImages: removedArray,
        selectedImagesNumber: this.state.selectedImagesNumber - 1,
        selectionChanged: true
      });
    } else if (this.state.selectedImagesNumber < 6) {
      this.setState({
        selectedImages: [...this.state.selectedImages, newImage],
        selectedImagesNumber: this.state.selectedImages.length + 1,
        selectionChanged: true
      });
    } else {
      console.warn("The maximum number of images is 6.");
      this.props.alert({
        type: "error",
        msg: "The maximum number of images is 6."
      });
    }
  };

  handleClearSelected = () => {
    if (this.state.selectedImages) {
      this.setState({
        selectedImages: [],
        selectedImagesNumber: 0,
        selectionChanged: true
      });
    }
  };

  selectedSubmit = () => {
    const { selectedImages } = this.state;
    const { media } = this.props.pendingCampaign;
    const selectedVideo = media.find(item => item.mediaType === "VIDEO");
    const mediaImages = selectedImages.map(i => this.imageObjectFormat(i));
    const updatedSelection = selectedVideo
      ? [...mediaImages, selectedVideo]
      : [...mediaImages];
    this.props.updateMedia(updatedSelection);
  };

  imageObjectFormat = image => {
    const newImage = {
      mediaType: image.type,
      status: image.status,
      title: "",
      adId: "",
      inactiveReason: "",
      effectiveStatus: "",
      adReviewFeedback: ""
    };
    if (image.name) {
      newImage.filename = `${image.name}.${image.format}`;
    } else {
      newImage.filename = `${image.public_id}.${image.format}`;
    }
    return newImage;
  };

  closeImagesModal = () => {
    const { selectionChanged, showChangeWarning } = this.state;
    if (selectionChanged && showChangeWarning) {
      this.props.updateMedia(false);
    } else if (selectionChanged) {
      this.setState({ showChangeWarning: true });
    } else {
      this.props.updateMedia(false);
    }
  };

  deleteAssetClick = asset => {
    this.setState({
      deleteModal: true,
      deleteAsset: asset
    });
  };

  deleteAsset = async () => {
    const { selectedImages, deleteAsset: asset } = this.state;
    this.setState({ loading: true });
    if (asset) {
      try {
        await assetDelete(asset);
        const imageName = `${asset.name}.${asset.format}`;
        const removedArray = selectedImages.filter(i => i.name !== imageName);
        this.setState(
          {
            selectedImages: removedArray,
            selectedImagesNumber: removedArray.length,
            loading: false,
            deleteModal: false,
            deleteAsset: {}
          },
          () => {
            this.loadUserImages();
          }
        );
        this.props.alert({
          type: "success",
          msg: "The image has been deleted."
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  render() {
    const {
      showChangeWarning,
      loading,
      selectedImages,
      myImages,
      imageMenuSelection,
      cloudinaryFreeImages,
      deleteAsset: asset
    } = this.state;
    return (
      <ModalWrapper>
        <ModalLargeWrapper id='images-modal-container'>
          <ImageModalHeader
            menuSelection={imageMenuSelection}
            setMenu={this.setMenu}
          />
          <UploadImagePane
            show={imageMenuSelection === "uploadImages"}
            companyId={this.props.userCompany._id}
            email={this.props.userProfile.email}
            loadUserImages={this.loadUserImages}
            selectedImages={selectedImages}
            handleImagesSelected={this.handleImagesSelected}
            setMenu={this.setMenu}
          />
          <MyImagesPane
            show={imageMenuSelection === "myImages"}
            loading={loading}
            myImages={myImages}
            selectedImages={selectedImages}
            handleImagesSelected={this.handleImagesSelected}
            deleteAssetClick={this.deleteAssetClick}
            setMenu={this.setMenu}
            companyId={this.props.userCompany._id}
            email={this.props.userProfile.email}
          />
          <StockImagesPane
            show={imageMenuSelection === "freeImages"}
            freeImages={cloudinaryFreeImages}
            selectedImages={selectedImages}
            handleImagesSelected={this.handleImagesSelected}
          />

          {/* this section displays any images that have been selected through the campaign creation process */}
          <ImageModalFooter
            selectedImages={selectedImages}
            closeImagesModal={this.closeImagesModal}
            selectedSubmit={this.selectedSubmit}
            handleClearSelected={this.handleClearSelected}
          />
        </ModalLargeWrapper>
        <Floater
          content={
            <span>
              <strong>Warning!</strong> Clicking cancel will not save your
              changes. Click submit to save your selection. Click cancel to
              continue without saving.
            </span>
          }
          open={showChangeWarning}
          target='#cancelBtn'
          styles={floaterStyles}
        />

        <BpModal
          open={this.state.deleteModal}
          title={"Delete Image"}
          primaryAction={{
            btnLabel: "Delete Image",
            action: this.deleteAsset,
            loading
          }}
          secondaryAction={{
            btnLabel: "Cancel",
            action: () => {
              this.setState({ deleteModal: false, deleteAsset: {} });
            }
          }}
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
          contentLabel='Delete Image'
          body={
            asset && (
              <div>
                <p>
                  Are you sure you want to delete this image? This cannot be
                  undone.
                </p>
                <img
                  src={cloudinaryFormat({
                    filename: asset.public_id ? asset.public_id : asset.name,
                    format: asset.format,
                    height: 96,
                    width: 144,
                    mode: "fill",
                    quality: ""
                  })}
                  alt='to delete'
                />
              </div>
            )
          }
          width='auto'
          zIndex='1002'
        />
      </ModalWrapper>
    );
  }
}

export default ImageSelector;
