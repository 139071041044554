import React, { useContext } from "react";
import { useTable } from "react-table";
import styled from "styled-components";
import { firstBreakpoint } from "../../styled/PhoneNumberProvision";
import { ThemeContext } from "styled-components";
import WorkflowModal from "./WorkflowModal";

import { tagsGetByCompany } from "../../../utils/ContactAPI";

import {
  Icon,
  H3,
  FilterHeader,
  FilterHeaderLeft,
  FlowHeaderDivider
} from "./styles";
import { Button } from "../../styled/Buttons";

const TableStyle = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const Head = styled.thead`
  background-color: #ebe8ed;
  display: none;
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: table-header-group;
  }
`;

const TH = styled.th`
  color: #3a0ca2;
  font-weight: 500;
  padding: 20px 15px;
  display: none;
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: table-cell;
  }
  &.actions {
    text-align: right;
    padding-right: 25px;
  }
  &.active {
    text-align: center;
  }
`;

const THCheck = styled.th`
  padding: 0 13px;
  width: 48px;
  display: none;
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: table-cell;
  }
`;

const TDCheck = styled.td`
  padding: 0 13px;
  margin-top: 6px;
  width: 48px;
  display: none;
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: block;
  }
`;

const CheckboxWrapper = styled.div`
  input {
    cursor: pointer;
    opacity: 0;
  }
  label {
    cursor: pointer;
    position: relative;
    font-style: italic;
    &::before {
      content: "";
      display: inline-block;
      height: 20px;
      width: 20px;
      border-radius: 3px;
      border: 1px solid ${props => props.theme.TextC};
      position: absolute;
      top: -3px;
      left: -20px;
      background: ${props => props.theme.BGC};
    }
    &::after {
      content: "";
      display: inline-block;
      height: 7px;
      width: 12px;
      border-left: 2px solid;
      border-bottom: 2px solid;
      transform: rotate(-45deg);
      position: absolute;
      left: -16px;
      top: 2px;
      color: ${props => props.theme.HC1};
    }
  }
  input + label::after {
    content: none;
  }
  input:checked + label::before {
    background: white;
    border-color: ${props => props.theme.TextC};
  }
  input:checked + label::after {
    content: "";
  }
`;

const TD = styled.td`
  text-align: left;
  padding: 0 15px;
  display: ${props => (props.mobileHide ? "none" : "block")};
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: table-cell;
  }
  &.name {
    font-weight: 510;
  }
  &.name,
  &.created-by,
  &.date-updated {
    text-align: left;
  }
`;

const BodyRow = styled.tr`
  color: ${props => props.color};
  border-bottom: 1px solid #d9d9da;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: table-row;
  }
  &:last-of-type {
    border-bottom: none;
  }
  td {
    padding-top: 16px;
    padding-bottom: 16px;
  }
`;

const mobileHiddenColumns = [];

const EditTagsFilter = props => {
  const {
    columns,
    data,
    setOpenEditTags,
    selectedTags = [],
    updateSelectedTags,
    applyFilter
  } = props;

  const themeContext = useContext(ThemeContext);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <WorkflowModal
      open={true}
      onCloseMethod={() => setOpenEditTags()}
      body={
        <div>
          <FilterHeader>
            <FilterHeaderLeft>
              <Icon small className='material-icons-outlined'>
                filter_alt
              </Icon>
              <H3>Filters</H3>
              <FlowHeaderDivider></FlowHeaderDivider>
              <H3>Select Tags</H3>
            </FilterHeaderLeft>
          </FilterHeader>
          <TableStyle {...getTableProps()}>
            <Head>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  <THCheck></THCheck>
                  {headerGroup.headers.map(column => (
                    <TH
                      {...column.getHeaderProps()}
                      className={column.id ? column.id : ""}
                    >
                      {column.render("Header")}
                    </TH>
                  ))}
                </tr>
              ))}
            </Head>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row);
                return (
                  <BodyRow
                    {...row.getRowProps()}
                    color={
                      row.values.active === "draft"
                        ? themeContext.TextC
                        : themeContext.HC1
                    }
                  >
                    <TDCheck>
                      <CheckboxWrapper
                        onClick={() => updateSelectedTags(row.original.id)}
                      >
                        <input
                          type='checkbox'
                          checked={selectedTags.includes(row.original.id)}
                          onChange={() => updateSelectedTags(row.original.id)}
                        />
                        <label />
                      </CheckboxWrapper>
                    </TDCheck>
                    {row.cells.map(cell => (
                      <TD
                        {...cell.getCellProps()}
                        className={cell.column.id ? cell.column.id : ""}
                        mobileHide={
                          mobileHiddenColumns.includes(cell.column.id)
                            ? true
                            : false
                        }
                      >
                        {/* {console.log(cell)} */}
                        {cell.render("Cell")}
                      </TD>
                    ))}
                  </BodyRow>
                );
              })}
            </tbody>
          </TableStyle>
          <Button
            onClick={() => applyFilter()}
            small
            disabled={selectedTags.length === 0}
          >
            APPLY
          </Button>
        </div>
      }
    />
  );
};

const TagsList = props => {
  const { setOpenEditTags, filters, addFilter } = props;

  const [tagsList, setTagsList] = React.useState([]);
  const [selectedTags, setSelectedTags] = React.useState([]);

  React.useEffect(() => {
    const getCampaigns = async () => {
      const tags = await tagsGetByCompany();
      console.log("campaigns: ", tags);
      setTagsList(tags);
    };
    getCampaigns();
  }, []);

  React.useEffect(() => {
    filters.forEach(item => {
      if (item.type === "TAGS") {
        const { tagIds } = item;
        setSelectedTags(tagIds);
      }
    });
  }, [filters]);

  const updateSelectedTags = tagId => {
    console.log("tagId: ", tagId);
    let newSelectedTags = [...selectedTags];
    const exists = newSelectedTags.includes(tagId);
    console.log("exists: ", exists);
    if (!exists) {
      newSelectedTags = [...selectedTags, tagId];
      console.log("newSelectedTags: ", newSelectedTags);
    } else {
      const index = newSelectedTags.findIndex(id => id === tagId);
      console.log("index: ", index);
      newSelectedTags.splice(index, 1);
      console.log("newSelectedTags: ", newSelectedTags);
    }
    setSelectedTags(newSelectedTags);
  };

  const applyFilter = () => {
    const filter = {
      campaignIds: [],
      tagIds: [...selectedTags],
      statusIds: [],
      type: "TAGS"
    };
    addFilter(filter);
  };

  const headers = [
    {
      Header: "Name",
      accessor: "name",
      id: "name"
    }
  ];
  const tableData = tagsList.map(tag => ({
    id: tag._id,
    name: tag.name
  }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = React.useMemo(() => tableData, [tagsList]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = React.useMemo(() => headers, [tagsList]);

  return (
    <EditTagsFilter
      columns={columns}
      data={data}
      setOpenEditTags={setOpenEditTags}
      updateSelectedTags={updateSelectedTags}
      selectedTags={selectedTags}
      applyFilter={applyFilter}
      history={props.history}
    />
  );
};
export default TagsList;
