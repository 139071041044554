import styled from "styled-components";
import CloseIcon from "./icons/CloseIcon";
import ErrorIcon from "./icons/ErrorIcon";
import InfoIcon from "./icons/InfoIcon";
import SuccessIcon from "./icons/SuccessIcon";

const Alert = styled.div`
  background-color: ${props =>
    props.theme && props.theme.CTextC ? props.theme.CTextC : "#151515"};
  color: white;
  padding: 10px;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.03);
  min-width: 20em;
  max-width: 40em;
  box-shadow: border-box;
`;

const CloseBtn = styled.button`
  margin-left: 20px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;
  color: ${props =>
    props.theme && props.theme.ATextC ? props.theme.ATextC : "#FFFFFF"};
`;

const AlertTemplate = ({ message, options, style, close }) => {
  const { theme = {} } = options;
  return (
    <Alert style={style} theme={theme}>
      {options.type === "info" && <InfoIcon theme={theme} />}
      {options.type === "success" && <SuccessIcon theme={theme} />}
      {options.type === "error" && <ErrorIcon theme={theme} />}
      <span style={{ flex: 2 }}>{message}</span>
      <CloseBtn onClick={close} theme={theme}>
        <CloseIcon />
      </CloseBtn>
    </Alert>
  );
};

export default AlertTemplate;
