import React from "react";
import styled from "styled-components";
import Linkify from "react-linkify";
import MessageImage from "./MessageImage";
import { SecureLink } from "react-secure-link";

const MsgContainer = styled.div`
  background-color: ${props =>
    props.inbound ? props.theme.BGCAlt : props.theme.HC2};
  border-radius: ${props =>
    props.inbound ? "5px 5px 5px 0px" : "5px 5px 0px 5px"};
  color: ${props => (props.inbound ? props.theme.BTextC : props.theme.ATextC)};
  border: ${props => (props.inbound ? "1px solid rgb(242, 242, 242)" : "none")};
  padding: 1em;
  text-align: left;
  a {
    color: ${props => (props.inbound ? "inherit" : "white")};
  }
`;

const MsgBody = styled.div`
  margin: 0;
  img {
    max-width: 100%;
    max-height: 300px;
  }
  ${props =>
    props.msgLoading &&
    `
    display: flex;
    justify-content: right;
    padding-right: 20px;
    `}
`;

const MessageContent = props => {
  const { message } = props;

  return (
    <MsgContainer
      inbound={
        message.direction === "inbound" ||
        message.direction === "incoming"
      }
    >
      <MsgBody>
        {message.numMedia > 0 && (
          <MessageImage message={message} />
        )}
        <Linkify
          componentDecorator={(
            decoratedHref,
            decoratedText,
            i
          ) => (
            <SecureLink
              href={decoratedHref}
              target='_blank'
              rel='noopener noreferrer'
              key={i}
            >
              {decoratedText}
            </SecureLink>
          )}
        >
          {message.body}
        </Linkify>
      </MsgBody>
    </MsgContainer>
  )
};

export default MessageContent;
