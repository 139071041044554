import React from "react";
import { auth } from "../../../firebase/firebase";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { LoaderDotsSmall } from "../../elements/Loaders";

// Import Styled Components
import { Button } from "../../styled/Buttons";
import { Title } from "../../styled/Headers";
import { Card } from "../../styled/Layout";
import SigninForm from "../../styled/SigninForm";
import {
  InputBox,
  InputContainer,
  InputLabel,
  InputBoxWrapper
} from "../../styled/Input";

const FlexReverse = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
`;

const user_state = {
  email: "",
  password: "",
  loginStarted: false,
  error: null
};

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value
});

class Login extends React.Component {
  state = { ...user_state };

  onSubmit = event => {
    event.preventDefault();
    this.setState({ loginStarted: true });

    const { email, password } = this.state;

    auth.signInWithEmailAndPassword(email, password).catch(error => {
      this.setState({ error: error, loginStarted: false });
    });
  };

  render() {
    const { email, password, loginStarted, error } = this.state;

    const isInvalid = password === "" || email === "";

    return (
      <div id='loginWrapper'>
        <Card>
          <Title>Please Log In</Title>
          <SigninForm onSubmit={this.onSubmit}>
            <InputContainer alignment={"center"} customWidth={"25rem"}>
              <InputLabel>Email Address</InputLabel>
              <InputBoxWrapper>
                <InputBox
                  value={email}
                  onChange={event =>
                    this.setState(byPropKey("email", event.target.value))
                  }
                  type='email'
                  placeholder='Email'
                />
              </InputBoxWrapper>
            </InputContainer>
            <InputContainer alignment={"center"} customWidth={"25rem"}>
              <InputLabel>Password</InputLabel>
              <InputBoxWrapper>
                <InputBox
                  value={password}
                  onChange={event =>
                    this.setState(byPropKey("password", event.target.value))
                  }
                  type='password'
                  placeholder='Password'
                  id='passwd'
                />
              </InputBoxWrapper>
            </InputContainer>
            <FlexReverse>
              <Button solid disabled={isInvalid || loginStarted} type='submit'>
                Login
                {loginStarted && <LoaderDotsSmall />}
              </Button>
              <Button
                id='btnRegInit'
                href='#'
                onClick={() => {
                  this.props.history.push(`/create-account`);
                }}
              >
                <span>Register</span>
              </Button>
            </FlexReverse>
            <Link to={"/password-reset"}>
              <h6 style={{ color: "" }}>Forgot Password?</h6>
            </Link>
            {error && <p>{error.message}</p>}
          </SigninForm>
        </Card>
      </div>
    );
  }
}

export default Login;
