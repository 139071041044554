import React, { Children } from "react";
import Modal from "react-modal";
import styled from "styled-components";

import Button from "../../elements/Button";
import { LoaderDotsSmall } from "../Loaders";
import img from "../../../images/popup-header-bg.png";
import BubbleWrap from "../BubbleWrap";
import { BubbleProps } from "../BubbleWrap/Bubble/Bubble";

const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  background-image: url(${img});
  background-repeat: no-repeat;
  background-size: 600px 166px;
  top: 0;
  position: sticky;
  width: 100%;
  height: 166px;
  align-content: center;
  justify-content: center;
`;
const Header = styled.h1`
  font-weight: 600;
  font-size: 34px;
  color: white;
  margin: 0 4px;
  text-align: center;
  overflow-wrap: break-word;
  b {
    margin: 0 4px;
    font-weight: 600;
    font-size: 34px;
    font-style: italic;
    color: ${props => props.theme.HCSuc};
  }
`;
const HeaderAccent = styled.h1`
  margin: 0 4px;
  font-weight: 600;
  font-size: 34px;
  font-style: italic;
  text-align: center;
  overflow-wrap: break-word;
  color: ${props => props.theme.HCSuc};
`;
const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
`;
const SubHeading = styled.p`
  font-weight: 400;
  font-size: 17px;
  color: white;
  margin: 0 auto;
`;

const ModalBody = styled.div`
  flex-direction: column;
  margin: 42px 62px;
  font-weight: 400;
  font-size: 15px;
  text-align: left;
`;

const Close = styled.div`
  position: absolute;
  right: 0;
  padding: 16px;
  cursor: pointer;
  span {
    font-size: 30px;
    color: white;
  }
`;

const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 13px 62px;
  font-size: 15px;
  text-align: center;
`;

const ModalNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props =>
    Children.toArray(props.children).length > 1 ? "space-between" : "center"};
  width: 100%;
  margin: 42px 0 0 0;
`;

type ModalProps = {
  open: boolean;
  title: string | Array<string>;
  pages: Array<{
    body: JSX.Element;
    primaryAction?: {
      action: (...args: any) => any;
      btnLabel: string;
      loading?: boolean;
      bubble?: BubbleProps;
    };
    secondaryAction?: {
      action: (...args: any) => any;
      btnLabel: string;
      loading?: boolean;
      bubble?: BubbleProps;
    };
  }>;
  body: JSX.Element;
  primaryAction?: {
    action: (...args: any) => any;
    btnLabel: string;
    loading?: boolean;
    bubble?: BubbleProps;
  };
  secondaryAction?: {
    action: (...args: any) => any;
    btnLabel: string;
    loading?: boolean;
    bubble?: BubbleProps;
  };
};

const BpModal = (props: any) => {
  const {
    open,
    primaryAction,
    secondaryAction,
    title,
    body,
    shouldCloseOnEsc = true,
    shouldCloseOnOverlayClick = true,
    contentLabel = "",
    zIndex = "1000",
    fontSize = "1em",
    styleOverride = {},
    onAfterOpen,
    onCloseMethod,
    children,
    subheading,
    footer
  } = props;

  //I feel like we need a bit more directly on the modal styles for build this part out more.
  const {
    bodyStyles: {
      maxHeight = "44vh",
      minHeight = "10vh",
      allowOverflow = false
    } = {}
  } = styleOverride;
  const overlay = {
    backgroundColor: "rgba(0, 0, 0, .75)",
    zIndex
  };
  const content = {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: "600px",
    maxWidth: "90%",
    maxHeight: "90%",
    padding: "0",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "0",
    boxShadow: "0px 0px 9px 0px rgba(0, 0, 6, .1)",
    fontSize,
    overflow: "hidden auto"
  };
  const primaryLoading =
    primaryAction && primaryAction.loading !== null
      ? primaryAction.loading
      : false;
  const secondaryLoading =
    secondaryAction && secondaryAction.loading !== null
      ? secondaryAction.loading
      : false;
  return (
    <Modal
      isOpen={open}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc={shouldCloseOnEsc}
      style={{ overlay, content }}
      contentLabel={contentLabel}
      onAfterOpen={onAfterOpen ? onAfterOpen : null}
      onRequestClose={onCloseMethod ? onCloseMethod : null}
      appElement={
        (document.getElementById("root") && document.getElementById("root")) ||
        undefined
      }
    >
      {!!onCloseMethod && (
        <Close onClick={onCloseMethod}>
          <span className='material-icons'>close</span>
        </Close>
      )}
      <ModalHeader>
        <HeaderContainer>
          <Header>{Array.isArray(title) ? title[0] : title}</Header>
          <HeaderAccent>
            {Array.isArray(title) && title.length > 1 && title[1]}
          </HeaderAccent>
          <Header>
            {Array.isArray(title) && title.length > 2 && title[2]}
          </Header>
        </HeaderContainer>
        {subheading && <SubHeading>{subheading}</SubHeading>}
      </ModalHeader>
      <ModalBody
        maxHeight={maxHeight}
        minHeight={minHeight}
        allowOverflow={allowOverflow}
      >
        {body ? body : children}

        <ModalNav>
          {!!secondaryAction && (
            <Button
              onClick={secondaryAction.action}
              disabled={secondaryLoading}
            >
              {secondaryAction.btnLabel}
              {secondaryLoading && <LoaderDotsSmall />}
            </Button>
          )}
          {!!primaryAction &&
            (primaryAction.bubble ? (
              <BubbleWrap
                text={primaryAction.bubble.label}
                dir={primaryAction.bubble.dir || "top"}
              >
                <Button
                  onClick={primaryAction.action}
                  disabled={primaryLoading}
                >
                  {primaryAction.btnLabel}
                  {primaryLoading && <LoaderDotsSmall />}
                </Button>
              </BubbleWrap>
            ) : (
              <Button
                onClick={primaryAction.action}
                disabled={primaryLoading}
              >
                {primaryAction.btnLabel}
                {primaryLoading && <LoaderDotsSmall />}
              </Button>
            ))}
        </ModalNav>
        <ModalFooter>{footer}</ModalFooter>
      </ModalBody>
    </Modal>
  );
};

export default BpModal;
