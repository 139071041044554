import React from "react";
import styled from "styled-components";
import WorkflowModal from "./WorkflowModal";

import {
  Icon,
  H3,
  FilterHeader,
  FilterHeaderLeft,
  FlowHeaderDivider,
  InputWrapper
} from "./styles";
import { Button } from "../../styled/Buttons";
import StatusDropdown from "../contacts/contactStatus/StatusDropdown";

const StatusBody = styled.div``;

const OptionList = styled.div`
  padding: 35px;
  div {
    margin: auto;
  }
`;

const StatusList = props => {
  const { setOpenEditStatus, statusList, addStatus, currentEndAction } = props;

  const [selectedStatus, setSelectedStatus] = React.useState("");

  React.useEffect(() => {
    setSelectedStatus(currentEndAction.statusId);
  }, [currentEndAction]);

  return (
    <WorkflowModal
      open={true}
      onCloseMethod={() => setOpenEditStatus()}
      body={
        <div>
          <FilterHeader>
            <FilterHeaderLeft>
              <Icon small className='material-icons'>
                fact_check
              </Icon>
              <H3>Workflow Completion</H3>
              <FlowHeaderDivider></FlowHeaderDivider>
              <H3>Select a Status</H3>
            </FilterHeaderLeft>
          </FilterHeader>
          <StatusBody>
            <OptionList>
              <InputWrapper>
                <StatusDropdown
                  statusValue={selectedStatus}
                  contactStatuses={statusList}
                  onChange={setSelectedStatus}
                />
              </InputWrapper>
            </OptionList>
          </StatusBody>
          <Button
            onClick={() => addStatus(selectedStatus)}
            small
            disabled={selectedStatus.length === 0}
          >
            APPLY
          </Button>
        </div>
      }
    />
  );
};
export default StatusList;
