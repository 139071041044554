import styled from "styled-components";

const deviceSilver = "#e2e3e4";
const deviceSilverDark = `darken(${deviceSilver}, 10%)`;
const deviceSilverPanel = "#8c8c8c";
const deviceHeight = 868;
const deviceWidth = 428;
const borderRadius = 68;
const framePadding = 28;
const headerWidth = 204;
const headerHeight = 30;

export const DeviceIphoneX = styled.div`
  height: ${deviceHeight * 0.8}px;
  width: ${deviceWidth * 0.8}px;
  position: relative;
  transform: scale(1);
  z-index: 1;
  margin: 15px auto;
  /* max-height: 300px; */
  overflow: hidden;
  @media screen and (min-width: 800px) {
    height: ${deviceHeight * 0.6}px;
    width: ${deviceWidth * 0.6}px;
  }
  @media screen and (min-width: 950px) {
    height: ${deviceHeight * 0.8}px;
    width: ${deviceWidth * 0.8}px;
    max-height: none;
  }
  @media screen and (min-width: 1200px) {
    height: ${deviceHeight}px;
    width: ${deviceWidth}px;
  }
`;

export const DeviceFrame = styled.div`
  background: ${deviceSilverPanel};
  /* box-shadow: inset 0 0 2px 2px ${deviceSilverDark},
    inset 0 0 0 7px ${deviceSilver}; */
  height: ${deviceHeight * 0.8}px;
  padding: ${framePadding * 0.8}px;
  width: ${deviceWidth * 0.8}px;
  border-radius: ${borderRadius * 0.8}px;
  z-index: 1;
  @media screen and (min-width: 800px) {
    height: ${deviceHeight * 0.6}px;
    padding: ${framePadding * 0.6}px;
    width: ${deviceWidth * 0.6}px;
    border-radius: ${borderRadius * 0.6}px;
  }
  @media screen and (min-width: 950px) {
    height: ${deviceHeight * 0.8}px;
    padding: ${framePadding * 0.8}px;
    width: ${deviceWidth * 0.8}px;
    border-radius: ${borderRadius * 0.8}px;
  }
  @media screen and (min-width: 1200px) {
    height: ${deviceHeight}px;
    padding: ${framePadding}px;
    width: ${deviceWidth}px;
    border-radius: ${borderRadius}px;
  }
`;

export const DeviceContent = styled.div`
  border-radius: 40px;
  height: ${deviceHeight * 0.8 - framePadding * 0.8 * 2}px;
  width: ${deviceWidth * 0.8 - framePadding * 0.8 * 2}px;
  background-color: ${props => props.theme.BGC};
  background-position: center center;
  background-size: cover;
  object-fit: cover;
  position: relative;
  @media screen and (min-width: 800px) {
    height: ${deviceHeight * 0.6 - framePadding * 0.6 * 2}px;
    width: ${deviceWidth * 0.6 - framePadding * 0.6 * 2}px;
  }
  @media screen and (min-width: 950px) {
    height: ${deviceHeight * 0.8 - framePadding * 0.8 * 2}px;
    width: ${deviceWidth * 0.8 - framePadding * 0.8 * 2}px;
  }
  @media screen and (min-width: 1200px) {
    height: ${deviceHeight - framePadding * 2}px;
    width: ${deviceWidth - framePadding * 2}px;
  }
`;

export const DeviceHeader = styled.div`
  background: ${deviceSilverPanel};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  left: 50%;
  top: ${framePadding * 0.8 - 1}px;
  margin-left: -${(deviceWidth / 2 - headerWidth / 2 - framePadding / 4) * 0.8}px;
  height: ${headerHeight * 0.8}px;
  width: ${headerWidth * 0.8}px;
  position: absolute;
  @media screen and (min-width: 800px) {
    top: ${framePadding * 0.6 - 1}px;
    margin-left: -${(deviceWidth / 2 - headerWidth / 2 - framePadding / 4) * 0.6}px;
    height: ${headerHeight * 0.6}px;
    width: ${headerWidth * 0.6}px;
  }
  @media screen and (min-width: 950px) {
    top: ${framePadding * 0.8 - 1}px;
    margin-left: -${(deviceWidth / 2 - headerWidth / 2 - framePadding / 4) * 0.8}px;
    height: ${headerHeight * 0.8}px;
    width: ${headerWidth * 0.8}px;
  }
  @media screen and (min-width: 1200px) {
    width: ${headerWidth}px;
    height: ${headerHeight}px;
    top: ${framePadding}px;
    margin-left: -${deviceWidth / 2 - headerWidth / 2 - framePadding / 4}px;
  }

  &::after,
  &::before {
    content: "";
    height: 10px;
    position: absolute;
    top: 0;
    width: 10px;
  }
  &::after {
    background: radial-gradient(
      circle at bottom left,
      transparent 0,
      transparent 75%,
      ${deviceSilverPanel} 75%,
      ${deviceSilverPanel} 100%
    );
    left: -10px;
  }
  &::before {
    background: radial-gradient(
      circle at bottom right,
      transparent 0,
      transparent 75%,
      ${deviceSilverPanel} 75%,
      ${deviceSilverPanel} 100%
    );
    right: -10px;
  }
`;
