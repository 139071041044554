import React from "react";

import styled from "styled-components";
import { twilioGetNumbers } from "../../../../utils/MessagesAPI";
import {
  ContentWrapper,
  firstBreakpoint,
  secondBreakpoint
} from "../../../styled/PhoneNumberProvision";
import MobileButtons from "./MobileButtons";
import ModalHeader from "./ModalHeader";
import NumberResults from "./NumberResults";
import NumberSearch from "./NumberSearch";

const NumberWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  flex-direction: column;
  @media screen and (min-width: ${firstBreakpoint}px) {
    margin: 0 60px;
    padding-bottom: 60px;
    display: ${props => (props.showResults ? "grid" : "block")};
    grid-template-columns: 300px 1fr;
    grid-gap: 2em;
  }
  @media screen and (min-width: ${secondBreakpoint}px) {
    width: 800px;
  }
`;

const NumberChooser = props => {
  const [searchMethod, setSearchMethod] = React.useState("areaCode");
  const [state, setState] = React.useState({
    loading: false,
    numbersNotFound: false,
    notValid: false,
    showResults: false
  });
  const [fieldValues, setFieldValues] = React.useState({
    areaCode: "",
    inLocality: "",
    inRegion: "",
    inPostalCode: ""
  });
  const {
    alert,
    selectNumber,
    selectedNumber,
    setAvailableNumbers,
    availableNumbers,
    progress,
    loading
  } = props;

  const selectSearchType = input => {
    setSearchMethod(input.target.value);
  };

  const handleFieldChange = input => {
    const { name, value } = input.target;
    setFieldValues({ ...fieldValues, [name]: value });
  };

  const validate = () =>
    new Promise((resolve, reject) => {
      const { areaCode, inLocality, inPostalCode } = fieldValues;
      if (!searchMethod) {
        resolve(false);
      }
      if (searchMethod === "areaCode" && !areaCode) {
        resolve(false);
      }
      if (searchMethod === "inLocality" && !inLocality) {
        resolve(false);
      }
      if (searchMethod === "inPostalCode" && !inPostalCode) {
        resolve(false);
      }
      resolve(true);
    });

  const phoneSearch = async () => {
    try {
      setAvailableNumbers([]);
      setState({
        ...state,
        loading: true,
        numbersNotFound: false,
        notValid: false,
        showResults: true
      });
      const { areaCode, inLocality, inPostalCode, inRegion } = fieldValues;
      const valid = await validate();
      if (!valid) {
        setAvailableNumbers([]);
        setState({
          loading: false,
          numbersNotFound: false,
          notValid: true
        });
        return;
      }
      const numberResponse = await twilioGetNumbers({
        ...(areaCode && searchMethod === "areaCode" && { areaCode }),
        ...(inLocality && searchMethod === "inLocality" && { inLocality }),
        ...(inRegion && searchMethod === "inLocality" && { inRegion }),
        ...(inPostalCode && searchMethod === "inPostalCode" && { inPostalCode })
      });
      if (numberResponse.length > 0) {
        setAvailableNumbers(numberResponse);
        setState({
          ...state,
          loading: false,
          numbersNotFound: false,
          notValid: false,
          showResults: true
        });
      } else {
        setAvailableNumbers([]);
        setState({
          loading: false,
          numbersNotFound: true,
          notValid: false
        });
      }
    } catch (err) {
      console.log("error getting phone numbers", err.message);
      alert({
        type: "error",
        msg: err.message
      });
    }
  };

  const onKeyDown = event => {
    if (event.key === "Enter") {
      event.preventDefault();
      phoneSearch();
    }
  };

  const next = () => {
    if (!state.showResults) {
      phoneSearch();
    } else {
      props.next();
    }
  };

  const back = () => {
    if (state.showResults) {
      setState({ ...state, showResults: false });
    } else {
      props.back();
    }
  };

  return (
    <ContentWrapper>
      <ModalHeader
        back={props.back}
        title='Set up Messaging'
        progress={progress}
        showProgress={true}
      />
      <NumberWrapper
        showResults={state.showResults || availableNumbers.length > 0}
      >
        <NumberSearch
          searchMethod={searchMethod}
          areaCode={fieldValues.areaCode}
          inLocality={fieldValues.inLocality}
          inRegion={fieldValues.inRegion}
          inPostalCode={fieldValues.inPostalCode}
          handleFieldChange={handleFieldChange}
          onKeyDown={onKeyDown}
          selectSearchType={selectSearchType}
          phoneSearch={phoneSearch}
          compact={state.showResults || availableNumbers.length > 0}
          country={props.country}
        />
        <NumberResults
          loading={state.loading}
          availableNumbers={availableNumbers}
          selectNumber={selectNumber}
          selectedNumber={selectedNumber}
          back={phoneSearch}
          numbersNotFound={state.numbersNotFound}
          notValid={state.notValid}
          setAvailableNumbers={setAvailableNumbers}
          showResults={state.showResults}
        />
      </NumberWrapper>
      <MobileButtons next={next} back={back} loading={loading} />
    </ContentWrapper>
  );
};

export default NumberChooser;
