import styled from "styled-components";

const FbPreviewMore = styled.div`
  grid-column: 13 / -1;
  grid-row: 1;
  font-weight: bold;
  color: #606770;
  text-align: right;
`;

export default FbPreviewMore;
