import styled from "styled-components";

const CheckboxGroupLabel = styled.label`
  font-size: 15px;
  color: ${props =>
    props.disabled ? "lightgrey !important" : props.theme.HC1};
  font-weight: bold;
`;

export default CheckboxGroupLabel;
