import React from "react";

import Card from "./styled/Card";

class NotFound extends React.Component {
  render() {
    return (
      <Card>
        <span>Page Not Found</span>
      </Card>
    );
  }
}

export default NotFound;
