import React from "react";
import * as _ from "underscore";
import { Dropdown } from "../../../styled/Input";

const StatusDropdown = props => {
  const {
    name = "statusId",
    statusValue,
    contactStatuses = [],
    disabledStatus
  } = props;
  const [statuses, setStatuses] = React.useState({});

  React.useEffect(() => {
    if (contactStatuses && contactStatuses.length > 0) {
      const newStatus = {};
      contactStatuses.forEach(c => {
        if (!newStatus[c.type]) {
          newStatus[c.type] = [c];
        } else {
          newStatus[c.type].push(c);
        }
      });
      setStatuses(newStatus);
    }
  }, [contactStatuses]);

  return !_.isEmpty(statuses) ? (
    <Dropdown
      name={name}
      required
      data-namereadable='Status'
      value={statusValue}
      onChange={input => props.onChange(input.target.value, input.target.name)}
    >
      <option key='defaultValue'>Select One</option>
      {Object.keys(statuses).map(t => (
        <optgroup label={t} key={t}>
          {statuses[t].map(status => (
            <option
              key={status._id}
              value={status._id}
              disabled={disabledStatus === status._id}
            >
              {status.status}
            </option>
          ))}
        </optgroup>
      ))}
    </Dropdown>
  ) : null;
};

export default StatusDropdown;
