import React from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { firstBreakpoint } from "../../styled/PhoneNumberProvision";
import { withTheme } from "styled-components";
import H2 from "../../styled/H3";

const ModalWrapper = styled.div`
  padding: 0;
  position: relative;
  display: block;
  grid-gap: 1em;
  margin: 0 60px 30px 60px;
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: ${props => (props.showMenu ? "grid" : "block")};
    grid-template-columns: 3fr 4fr;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${props => props.theme.BGC};
  align-items: center;
  margin-top: 35px;
  h2 {
    font-weight: 600;
    letter-spacing: initial;
    font-size: 20px;
    color: #3a0ca2;
  }
  div {
    display: flex;
    align-items: center;
  }
`;

const ModalMenu = styled.div`
  background-color: ${props => props.theme.BGC};
  border-radius: 5px;
`;

const ModalBody = styled.div`
  background-color: ${props => props.theme.BGC};
  border-radius: 5px;
  height: auto;
  width: auto;
  @media screen and (min-width: ${firstBreakpoint}px) {
    max-height: ${props => (props.maxHeight ? props.maxHeight : "44vh")};
    min-height: ${props => (props.minHeight ? props.minHeight : "initial")};
  }
  padding: 0;
  img {
    width: 100%;
  }
  .infoIcon {
    width: auto;
  }
  p:first-of-type {
    margin-top: 0;
  }
  h4 {
    text-align: left;
    font-size: 1.2em;
    margin: 1.5em 1em;
  }
`;

const Close = styled.div`
  margin: 10px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  span {
    color: #3a0ca2;
    font-size: 30px;
  }
`;

class WorkflowModal extends React.Component {
  render() {
    const {
      open,
      title,
      body,
      menu = false,
      shouldCloseOnEsc = true,
      shouldCloseOnOverlayClick = true,
      contentLabel = "",
      zIndex = "1000",
      width = "44rem",
      fontSize = "1em",
      backgroundColor = "rgba(0, 0, 0, .75)",
      padding,
      styleOverride = {}
    } = this.props;
    //I feel like we need a bit more directly on the modal styles for build this part out more.
    const {
      bodyStyles: { maxHeight = "44vh" } = {},
      content: { maxHeight: maxHeightContent = "90%" } = {}
    } = styleOverride;
    const overlay = {
      backgroundColor,
      zIndex
    };
    const content = {
      textAlign: "center",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      width,
      maxWidth: "90%",
      maxHeight: maxHeightContent,
      padding: "0",
      transform: "translate(-50%, -50%)",
      border: "none",
      boxShadow: "none",
      fontSize,
      backgroundColor: this.props.theme.BGC
    };
    return (
      <Modal
        isOpen={open}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        shouldCloseOnEsc={shouldCloseOnEsc}
        style={{ overlay, content }}
        contentLabel={contentLabel}
        onAfterOpen={this.props.onAfterOpen ? this.props.onAfterOpen : null}
        onRequestClose={
          this.props.onCloseMethod ? this.props.onCloseMethod : null
        }
        appElement={document.getElementById("root")}
      >
        <ModalHeader>
          {!!title && <h2>{title}</h2>}
          {!!this.props.header && this.props.header}
          {!!this.props.onCloseMethod && (
            <Close onClick={this.props.onCloseMethod}>
              <span className='material-icons-outlined'>close</span>
            </Close>
          )}
        </ModalHeader>
        <ModalWrapper
          padding={padding}
          className='modal-content-wrapper'
          showMenu={menu ? true : false}
        >
          {!!menu && <ModalMenu>{menu}</ModalMenu>}
          <ModalBody maxHeight={maxHeight}>{body}</ModalBody>
        </ModalWrapper>
      </Modal>
    );
  }
}

export default withTheme(WorkflowModal);
