import React from "react";
import Floater from "react-floater";
import { CardElement, injectStripe } from "react-stripe-elements";
import styled from "styled-components";
import H3 from "./styled/H3";

import { creditsUpdate } from "../utils/CreditsAPI";
import {
  getStripeInfo,
  stripeCreateCard,
  stripeCreateInvoice,
  stripeGetPaymentMethods,
  stripeInfoUpdate,
  stripePayInvoice
} from "../utils/StripeAPI";
import BpModal from "./elements/BpModal";
import { SummaryContainer, SummaryLine } from "./styled/CreditSummary";
import {
  InputBox,
  InputBoxWrapper,
  InputContainer,
  InputLabel
} from "./styled/Input";
import InputError from "./styled/InputErrorForm";
import { deleteCreditInvoice } from "../utils/Helpers";

const SummaryContainerCustom = styled(SummaryContainer)`
  max-width: 325px;
`;

const SummaryLineTotal = styled(SummaryLine)`
  border-top: 1px solid lightgray;
  margin-top: 5px;
  padding-top: 5px;
`;

const CreditButton = styled.div`
  /* border: 1px solid gray; */
  display: flex;
  background: white;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  margin: 22px 3px;
  ${props =>
    props.selected
      ? `
        background: #CFCFCF;
        box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.25);
      `
      : ""};
  div {
    padding: 16px 28px;
    @media screen and (max-width: 599px) {
      padding: 16px 18px;
    }
  }
  .amount {
    border-right: 1px solid ${props => (props.selected ? "#b3b3b3" : "#cfcfcf")};
    min-width: 96px;
  }
`;

const SlideoutIndicatorWrapper = styled.div`
  display: inline-block;
`;

const SlideoutIndicator = styled.span`
  display: inline-block;
  position: relative;
  top: 3px;
  font-size: 1.5em;
  color: #aaa;
  transform: ${props => (props.open ? "rotate(-90deg)" : "none")};
  transition: all 0.3s ease-in-out;
  &:hover {
    color: darkgray;
  }
`;

const OrderItems = styled.div`
  div {
    display: flex;
    background: white;
    border: 1px solid #cfcfcf;
    justify-content: space-between;
    span {
      display: inline-block;
      padding: 16px 28px;
    }
  }
`;

const PaymentMethods = styled.div`
  margin: 20px 0;
`;

const PaymentMethod = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  span i {
    transition: 0.3s ease-in-out;
    /* position: relative;
    top: 5px; */
    ${props => (props.selected ? "transform: rotate(90deg);" : "")};
  }
  .payment-details {
    max-height: ${props => (props.selected ? "100px" : "0")};
    padding: ${props => (props.selected ? "20px 0" : "0")};
  }
  h3 {
    color: ${props => (props.selected ? "black" : "#fdb849")};
  }
`;

const PaymentTitle = styled.h3`
  color: #fdb849;
  font-weight: normal;
  font-size: 1em;
  padding: 18px 20px;
  margin: 0;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  span {
    display: block;
    position: relative;
  }
`;

const ArrowContainer = styled.span`
  min-width: 44px;
  text-align: right;
  i {
    position: absolute;
    right: 0;
    top: -3px;
  }
`;

const PaymentDetails = styled.div`
  overflow: hidden;
  transition: 0.3s ease-in-out;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
`;

const PaymentDetailsNew = styled(PaymentDetails)`
  border-bottom: 1px solid #e5e5e5;
`;

const CardWrapper = styled.div`
  margin: 0 20px;
`;

const Accordion = styled.div`
  border: 1px solid #ccc;
  margin: 20px 0;
`;

const BulkWrapper = styled.div`
  max-height: ${props => (props.open ? "500px" : "0px")};
  transition: all 0.3s ease-in-out;
  overflow: hidden;
`;

const BulkHeader = styled(H3)`
  background: #eee;
  padding: 10px;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr 30px;
  align-items: center;
`;

const Icon = styled.i`
  position: relative;
  top: 2px;
  font-size: 1em;
  color: lightgray;
  &:hover {
    color: darkgray;
  }
`;

const WhyLink = styled.span`
  display: block;
  font-size: 0.9em;
  font-style: italic;
`;

const SuccessIcon = styled.i`
  position: relative;
  top: 2px;
  background: #45d477;
  color: white;
  border-radius: 50%;
  padding: 6px;
  font-size: 2em;
`;

const BulkCreditButton = styled.div`
  display: flex;
  border-top: 1px solid #eee;
  ${props =>
    props.selected
      ? `
        background: #CFCFCF;
        box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.25);
      `
      : ""};
  div {
    padding: 16px 28px;
  }
  .amount {
    border-right: 1px solid ${props => (props.selected ? "#b3b3b3" : "#cfcfcf")};
    min-width: 96px;
  }
`;

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.03);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1005;
  cursor: pointer;
`;

const floaterStyles = {
  wrapper: {
    cursor: "pointer"
  },
  floater: {
    zIndex: 1001
  },
  container: {
    borderRadius: 5,
    color: "rgb(51, 51, 51)",
    padding: 15,
    textAlign: "center"
  }
};

const FloaterLink = styled.a`
  &:hover {
    text-decoration: underline;
  }
`;

const OrderCredits = props => {
  const {
    alert,
    adCredits = {},
    userProfile = {},
    creditsModalOpen,
    creditsRequired,
    creditsCampaignBudget,
    creditsPurpose
  } = props;

  const [state, setState] = React.useState({
    chooseStep: true,
    reviewStep: false,
    completedStep: false,
    loading: false,
    invoiceId: "",
    fieldsValid: true,
    errMessages: {},
    floaters: {
      available: false,
      newCredits: false,
      total: false,
      whyCredits: false
    },
    bulkOpen: false,
    stripeId: ""
  });
  const [credits, setCredits] = React.useState({
    addCreditsAmount: 0,
    addCreditsPrice: 0,
    creditAmountCustom: 0,
    totalAmount: 0,
    adCreditsAvailable: adCredits.messageCredits + adCredits.messageFixedCredits
  });
  const [paymentMethod, setPaymentMethod] = React.useState({
    ccNumber: "",
    ccExpiryMonth: "",
    ccExpiryYear: "",
    ccZip: "",
    ccBrand: "",
    ccExists: false,
    defaultSource: "",
    paymentMethodSelected: "default",
    ccValid: {}
  });
  const [stripeInfo, setStripeInfo] = React.useState({ stripeId: "" });

  React.useEffect(() => {
    console.log("mounting");
    const mounting = async () => {
      try {
        const newStripeInfo = await getStripeInfo();
        const success = await deleteCreditInvoice(newStripeInfo);
        if (success) {
          newStripeInfo.invoices = [];
        } else {
          console.log('error deleting invoices', newStripeInfo);
        }
        setStripeInfo(newStripeInfo);
      } catch (err) {
        console.log('error getting stripe info', err.message);
      }
    };
    mounting();
  }, []);

  const getCCList = React.useCallback(async () => {
    console.log("getting cc list");
    if (stripeInfo.stripeId) {
      try {
        const stripePaymentMethods = await stripeGetPaymentMethods(
          stripeInfo.stripeId
        );
        if (stripePaymentMethods.length < 1) {
          return { ccExists: false };
        }
        const { ccExists, details } = stripePaymentMethods[0];
        if (ccExists) {
          const {
            last4,
            exp_month,
            exp_year,
            address_zip = "",
            brand,
            id
          } = details;
          setPaymentMethod({
            ccNumber: last4,
            ccExpiryMonth: exp_month,
            ccExpiryYear: exp_year,
            ccZip: address_zip,
            ccBrand: brand,
            ccExists: true,
            defaultSource: id,
            paymentMethodSelected: "default"
          });
          return Promise.resolve(details);
        } else {
          return Promise.resolve({ ccExists: false });
        }
      } catch (err) {
        console.error(err);
        return Promise.reject(err);
      }
    }
  }, [stripeInfo]);

  React.useEffect(() => {
    const getData = async () => {
      if (stripeInfo.stripeId) {
        console.log("initializing cc list");
        try {
          setState(prevState => ({ ...prevState, loading: true }));
          const stripePaymentMethods = await stripeGetPaymentMethods(
            stripeInfo.stripeId
          );
          if (stripePaymentMethods.length < 1) {
            return { ccExists: false };
          }
          const { ccExists, details } = stripePaymentMethods[0];
          if (ccExists) {
            const {
              last4,
              exp_month,
              exp_year,
              address_zip = "",
              brand,
              id
            } = details;
            setPaymentMethod({
              ccNumber: last4,
              ccExpiryMonth: exp_month,
              ccExpiryYear: exp_year,
              ccZip: address_zip,
              ccBrand: brand,
              ccExists: true,
              defaultSource: id,
              paymentMethodSelected: "default"
            });
          } else {
            setPaymentMethod(prevMethod => ({
              ...prevMethod,
              paymentMethodSelected: "new"
            }));
          }
          setState(prevState => ({ ...prevState, loading: false }));
        } catch (err) {
          console.error(err);
          alert({
            type: "error",
            msg: "There was a problem loading your default card. Please contact Boostpoint support if you continue to receive this error.",
            timeout: 4000
          });
          setState(prevState => ({ ...prevState, loading: false }));
        }
      }
    };
    getData();
  }, [alert, stripeInfo.stripeId, state.completedStep]);

  React.useEffect(() => {
    if (stripeInfo.invoices && stripeInfo.invoices.length > 0) {
      const stripeInvoice = stripeInfo.invoices[0];
      const addCreditsPrice = stripeInvoice.invoiceAmount / 100;
      let addCreditsAmount = addCreditsPrice;
      if (addCreditsPrice === 2000) {
        addCreditsAmount = 2020;
      } else if (addCreditsPrice === 3000) {
        addCreditsAmount = 3060;
      } else if (addCreditsPrice === 4000) {
        addCreditsAmount = 4120;
      }
      setState(prevState => ({
        ...prevState,
        reviewStep: true,
        chooseStep: false
      }));
      setCredits(prevCredits => ({
        ...prevCredits,
        addCreditsAmount,
        addCreditsPrice,
        totalAmount: addCreditsPrice
      }));
    }
  }, [stripeInfo.invoices]);

  React.useEffect(() => {
    if (creditsRequired) {
      console.log("setting credits");
      selectCredits(creditsRequired, creditsRequired);
    }
  }, [creditsRequired]);

  React.useEffect(() => {
    const { settings: { creditInvoiceId = "" } = {} } = userProfile;
    if (creditInvoiceId) {
      console.log("setting invoice id");
      setState(prevState => ({ ...prevState, invoiceId: creditInvoiceId }));
    }
  }, [userProfile]);

  const handleFloaterClick = (e, clickTarget) => {
    e.preventDefault();
    const { floaters } = state;
    floaters[clickTarget] = !floaters[clickTarget];
    setState({ ...state, floaters });
  };

  const handleNext = () => {
    const { errMessages } = state;
    if (credits.addCreditsAmount > 0) {
      setState({ ...state, chooseStep: false, reviewStep: true });
    } else {
      setState({
        ...state,
        fieldsValid: false,
        errMessages: {
          ...errMessages,
          creditsSelection: "Please select a credit amount."
        }
      });
      alert({
        type: "error",
        msg: "Please select a credit amount",
        timeout: 4000
      });
    }
  };

  const handleBack = () => {
    setState({ ...state, chooseStep: true, reviewStep: false });
  };

  const handleCancel = async () => {
    setState({ ...state, loading: true });
    await deleteInvoice();
    props.closeCreditModal();
  };

  const stripeElementChange = cc => {
    console.log("element change is ", cc);
    setPaymentMethod({ ...paymentMethod, ccValid: cc });
  };

  const createPaymentMethod = async () => {
    try {
      setState({ ...state, loading: true });
      const { token } = await props.stripe.createToken({});
      const createResponse = await stripeCreateCard({
        stripeId: stripeInfo.stripeId,
        source: token.id
      });
      const { cardId } = createResponse;
      console.log("card source", createResponse);
      alert({
        type: "success",
        msg: "Credit Card Added Successfully"
      });
      getCCList();
      return cardId;
    } catch (err) {
      console.log(err.message);
    }
  };

  const switchPaymentMethod = React.useCallback(
    newMethod => {
      const { paymentMethodSelected } = paymentMethod;
      if (paymentMethodSelected !== newMethod) {
        setPaymentMethod({
          ...paymentMethod,
          paymentMethodSelected: newMethod
        });
      }
    },
    [paymentMethod]
  );

  const selectCredits = (addCreditsAmount, addCreditsPrice) => {
    setCredits({
      addCreditsAmount,
      addCreditsPrice,
      totalAmount: addCreditsPrice
    });
  };

  const handleSubmit = async () => {
    const { paymentMethodSelected } = paymentMethod;
    const { addCreditsPrice, addCreditsAmount } = credits;
    const { errMessages } = state;
    try {
      setState({ ...state, loading: true });
      let source = paymentMethod.defaultSource;
      if (paymentMethodSelected === "new") {
        source = await createPaymentMethod();
      }
      const invoiceAmount = parseInt(addCreditsPrice) * 100;
      let invoice =
        stripeInfo.invoices && stripeInfo.invoices[0]
          ? stripeInfo.invoices[0]
          : null;
      if (invoice && invoice.invoiceAmount !== invoiceAmount) {
        await deleteCreditInvoice(stripeInfo);
        invoice = null;
      }
      if (!invoice) {
        const invoiceResponse = await stripeCreateInvoice({
          stripeId: stripeInfo.stripeId,
          currency: 'usd',
          invoiceAmount: parseInt(addCreditsPrice) * 100,
          description: 'Boostpoint Ad Credits'
        });
        setStripeInfo(invoiceResponse);
        console.log("invoice created", invoiceResponse);
        invoice = invoiceResponse.invoices[0];
      }
      if (invoice && invoice.invoiceId) {
        console.log("source", source, paymentMethodSelected);
        const payment = await stripePayInvoice({
          invoiceId: invoice.invoiceId,
          source
        });
        console.log("invoice payment", payment);
        const newCredits = await creditsUpdate({
          ...adCredits,
          adBalance: parseInt(adCredits.adBalance) + parseInt(addCreditsAmount)
        });
        console.log("newCredits", newCredits);
        setState({
          ...state,
          loading: false,
          errMessages,
          reviewStep: false,
          completedStep: true
        });
        stripeInfoUpdate({
          ...stripeInfo,
          invoices: []
        });
      } else {
        console.log("something went wrong paying the invoice");
      }
      // props.getCCList();
    } catch (err) {
      setState({ ...state, loading: false });
      if (err.response && err.response.data) {
        const data = err.response.data;
        console.log(
          "error handling boostpoint credits submit",
          err.response.data
        );
        if (data.message) {
          if (data.invoiceID) {
            setState({ ...state, invoiceId: data.invoiceID }, () => {
              alert({ type: "error", msg: data.message, timeout: 5000 });
              errMessages.cardError = data.message;
              setState({ ...state, errMessages });
            });
          } else {
            alert({ type: "error", msg: data.message, timeout: 5000 });
            errMessages.cardError = data.message;
            setState({ ...state, errMessages });
          }
        } else {
          alert({ type: "error", msg: data.message, timeout: 5000 });
          errMessages.cardError = data;
          setState({ ...state, errMessages });
        }
      } else {
        console.log("error handling boostpoint credits submit", err);
        alert({
          type: "error",
          msg: err.message
        });
        errMessages.cardError = err;
        setState({ ...state, errMessages });
      }
    }
  };

  const deleteInvoice = async () => {
    await deleteCreditInvoice(stripeInfo);
    return true;
  };

  const handleCustomAmount = value => {
    setCredits({
      ...credits,
      addCreditsAmount: value.trim(),
      addCreditsPrice: value.trim(),
      totalAmount: value.trim()
    });
  };

  const toggleBulkCredits = () => {
    const { bulkOpen } = state;
    setState({ ...state, bulkOpen: !bulkOpen });
  };

  return (
    <div>
      <BpModal
        open={creditsModalOpen && state.chooseStep}
        title={`Boostpoint Credits`}
        primaryAction={{
          btnLabel: "Next",
          action: handleNext
        }}
        secondaryAction={{
          btnLabel: "Cancel",
          action: handleCancel
        }}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        contentLabel='Boostpoint Credits'
        styleOverride={{
          bodyStyles: {
            maxHeight: "74vh"
          }
        }}
        body={
          <div>
            {creditsPurpose === "campaignRequired" ? (
              <div>
                <p>
                  You need enough ad credits to cover the cost of this campaign.
                  <br />
                  Note: 1 credit = $1 of ad spend
                </p>
                <Floater
                  open={state.floaters.whyCredits}
                  content={
                    <span>
                      Credits are used to pay for your ad budget. You need{" "}
                      {creditsRequired} credits for the budget of this campaign.
                      <br />
                      <FloaterLink
                        solid
                        href='https://help.boostpoint.com/hc/en-us/articles/360045440591-What-are-Credits-'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        Learn more about credits.
                      </FloaterLink>
                    </span>
                  }
                  styles={floaterStyles}
                  placement='right'
                >
                  <WhyLink onClick={e => handleFloaterClick(e, "whyCredits")}>
                    <span>Why do I need credits?</span>
                    <Icon className='material-icons' name='floater-toggle'>
                      info
                    </Icon>{" "}
                  </WhyLink>
                  {state.floaters.whyCredits && (
                    <Overlay
                      onClick={e => handleFloaterClick(e, "whyCredits")}
                      className='overlay'
                    />
                  )}
                </Floater>
                <SummaryContainerCustom>
                  <SummaryLine>
                    <div className='creditsDescription'>
                      <strong>Available Credits </strong>
                      <Floater
                        open={state.floaters.available}
                        content='The available credits you have to use on new ads.'
                        styles={floaterStyles}
                        placement='right'
                      >
                        <Icon
                          className='material-icons'
                          onClick={e => handleFloaterClick(e, "available")}
                          name='floater-toggle'
                        >
                          info
                        </Icon>
                        {state.floaters.available && (
                          <Overlay
                            onClick={e => handleFloaterClick(e, "available")}
                            className='overlay'
                          />
                        )}
                      </Floater>
                    </div>
                    <div className='creditsAmount'>
                      <span>{adCredits.adBalance}</span>
                    </div>
                  </SummaryLine>
                  <SummaryLine>
                    <div className='creditsDescription'>
                      <strong>Campaign Budget </strong>
                      <Floater
                        open={state.floaters.budget}
                        content='The total budget for this campaign.'
                        styles={floaterStyles}
                        placement='right'
                      >
                        <Icon
                          className='material-icons'
                          onClick={e => handleFloaterClick(e, "budget")}
                          name='floater-toggle'
                        >
                          info
                        </Icon>
                        {state.floaters.budget && (
                          <Overlay
                            onClick={e => handleFloaterClick(e, "budget")}
                            className='overlay'
                          />
                        )}
                      </Floater>
                    </div>
                    <div className='creditsAmount'>{creditsCampaignBudget}</div>
                  </SummaryLine>
                  <SummaryLineTotal>
                    <div className='creditsDescription'>
                      <strong>Total Required </strong>
                      <Floater
                        open={state.floaters.total}
                        content='The credits required in order to submit this campaign.'
                        styles={floaterStyles}
                        placement='right'
                      >
                        <Icon
                          className='material-icons'
                          onClick={e => handleFloaterClick(e, "total")}
                          name='floater-toggle'
                        >
                          info
                        </Icon>
                        {state.floaters.total && (
                          <Overlay
                            onClick={e => handleFloaterClick(e, "total")}
                            className='overlay'
                          />
                        )}
                      </Floater>
                    </div>
                    <div className='creditsAmount'>{creditsRequired}</div>
                  </SummaryLineTotal>
                </SummaryContainerCustom>
              </div>
            ) : (
              <div>
                <p>
                  Add credits to your account to use on future campaigns.
                  <br />
                  Note: 1 credit = $1 of ad spend
                </p>
                <Floater
                  open={state.floaters.whyCredits}
                  content={
                    <span>
                      Credits are used to pay for your ads.
                      <br />
                      <FloaterLink
                        solid
                        href='https://help.boostpoint.com/hc/en-us/articles/360045440591-What-are-Credits-'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        Learn more about credits.
                      </FloaterLink>
                    </span>
                  }
                  styles={floaterStyles}
                  placement='right'
                >
                  <WhyLink onClick={e => handleFloaterClick(e, "whyCredits")}>
                    <span>Why do I need credits?</span>
                    <Icon className='material-icons' name='floater-toggle'>
                      info
                    </Icon>
                  </WhyLink>{" "}
                  {state.floaters.whyCredits && (
                    <Overlay
                      onClick={e => handleFloaterClick(e, "whyCredits")}
                      className='overlay'
                    />
                  )}
                </Floater>
                <SummaryContainerCustom>
                  <SummaryLine>
                    <div className='creditsDescription'>
                      <strong>Available Credits </strong>
                      <Floater
                        open={state.floaters.available}
                        content='The available credits you have to use on new ads.'
                        styles={floaterStyles}
                        placement='right'
                      >
                        <Icon
                          className='material-icons'
                          onClick={e => handleFloaterClick(e, "available")}
                          name='floater-toggle'
                        >
                          info
                        </Icon>
                        {state.floaters.available && (
                          <Overlay
                            onClick={e => handleFloaterClick(e, "available")}
                            className='overlay'
                          />
                        )}
                      </Floater>
                    </div>
                    <div className='creditsAmount'>
                      <span>{adCredits.adBalance}</span>
                    </div>
                  </SummaryLine>
                  <SummaryLine>
                    <div className='creditsDescription'>
                      <strong>New Credits </strong>
                      <Floater
                        open={state.floaters.newCredits}
                        content='The new credits to add.'
                        styles={floaterStyles}
                        placement='right'
                      >
                        <Icon
                          className='material-icons'
                          onClick={e => handleFloaterClick(e, "newCredits")}
                          name='floater-toggle'
                        >
                          info
                        </Icon>
                        {state.floaters.newCredits && (
                          <Overlay
                            onClick={e => handleFloaterClick(e, "newCredits")}
                            className='overlay'
                          />
                        )}
                      </Floater>
                    </div>
                    <div className='creditsAmount'>
                      {credits.addCreditsAmount}
                    </div>
                  </SummaryLine>
                  <SummaryLineTotal>
                    <div className='creditsDescription'>
                      <strong>New Total Credits </strong>
                      <Floater
                        open={state.floaters.total}
                        content='The new total credits after this purchase.'
                        styles={floaterStyles}
                        placement='right'
                      >
                        <Icon
                          className='material-icons'
                          onClick={e => handleFloaterClick(e, "total")}
                          name='floater-toggle'
                        >
                          info
                        </Icon>
                        {state.floaters.total && (
                          <Overlay
                            onClick={e => handleFloaterClick(e, "total")}
                            className='overlay'
                          />
                        )}
                      </Floater>
                    </div>
                    <div className='creditsAmount'>
                      {parseInt(adCredits.adBalance) +
                        parseInt(credits.addCreditsAmount)}
                    </div>
                  </SummaryLineTotal>
                </SummaryContainerCustom>
              </div>
            )}
            {state.errMessages && state.errMessages.creditsSelection && (
              <InputError>{state.errMessages.creditsSelection}</InputError>
            )}
            {creditsPurpose !== "campaignRequired" && (
              <InputContainer>
                <InputLabel>Custom Credits Amount</InputLabel>
                <InputBoxWrapper>
                  <InputBox
                    type='text'
                    name='creditAmountCustom'
                    data-namereadable='custom amount'
                    value={credits.addCreditsAmount}
                    onChange={input => handleCustomAmount(input.target.value)}
                  />
                </InputBoxWrapper>
              </InputContainer>
            )}
            {creditsRequired && creditsRequired > 0 ? (
              <div>
                <H3>Add More Credits</H3>
                <CreditButton
                  selected={credits.addCreditsAmount === creditsRequired}
                  onClick={() =>
                    selectCredits(creditsRequired, creditsRequired)
                  }
                >
                  <div className='amount'>{creditsRequired}</div>
                  <div>You'll Pay ${creditsRequired}</div>
                </CreditButton>
              </div>
            ) : (
              <Accordion>
                <BulkHeader onClick={toggleBulkCredits}>
                  <div>Bulk Discount Credits</div>
                  <SlideoutIndicatorWrapper className='slideoutIndicator'>
                    <SlideoutIndicator
                      className='material-icons'
                      open={state.bulkOpen}
                    >
                      keyboard_arrow_left
                    </SlideoutIndicator>
                  </SlideoutIndicatorWrapper>
                </BulkHeader>
                <BulkWrapper open={state.bulkOpen}>
                  <BulkCreditButton
                    selected={credits.addCreditsAmount === 2020}
                    onClick={() => selectCredits(2020, 2000)}
                  >
                    <div className='amount'>2020</div>
                    <div>You'll Pay $2000</div>
                  </BulkCreditButton>
                  <BulkCreditButton
                    selected={credits.addCreditsAmount === 3060}
                    onClick={() => selectCredits(3060, 3000)}
                  >
                    <div className='amount'>3060</div>
                    <div>You'll Pay $3000</div>
                  </BulkCreditButton>
                  <BulkCreditButton
                    selected={credits.addCreditsAmount === 4120}
                    onClick={() => selectCredits(4120, 4000)}
                  >
                    <div className='amount'>4120</div>
                    <div>You'll Pay $4000</div>
                  </BulkCreditButton>
                </BulkWrapper>
              </Accordion>
            )}
            <p>You won't be charged until the next section.</p>
          </div>
        }
        width='auto'
      />
      <BpModal
        open={creditsModalOpen && state.reviewStep}
        title={`Boostpoint Credits`}
        primaryAction={{
          btnLabel: "Submit",
          action: handleSubmit,
          loading: state.loading
        }}
        secondaryAction={{
          btnLabel: "Back",
          action: handleBack,
          loading: state.loading
        }}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        contentLabel='Boostpoint Credits'
        body={
          <div>
            <OrderItems>
              <div className='item'>
                <span>{credits.addCreditsAmount} Credits</span>
                <span>${credits.addCreditsPrice}</span>
              </div>
              <div className='total'>
                <span>ORDER TOTAL</span>
                <span>${credits.totalAmount}</span>
              </div>
            </OrderItems>
            <PaymentMethods>
              <PaymentMethod
                selected={paymentMethod.paymentMethodSelected === "default"}
                onClick={() => switchPaymentMethod("default")}
              >
                <PaymentTitle className='title'>
                  <span>Use Existing Credit Card</span>
                  <ArrowContainer>
                    <i className='material-icons'>keyboard_arrow_right</i>
                  </ArrowContainer>
                </PaymentTitle>
                <PaymentDetails className='payment-details'>
                  <span>Default CC Last 4 Digits: </span>
                  <span>{paymentMethod.ccNumber}</span>
                </PaymentDetails>
              </PaymentMethod>
              <PaymentMethod
                selected={paymentMethod.paymentMethodSelected === "new"}
                onClick={() => switchPaymentMethod("new")}
              >
                <PaymentTitle className='title'>
                  <span>Use New Credit Card</span>
                  <ArrowContainer>
                    <i className='material-icons'>keyboard_arrow_right</i>
                  </ArrowContainer>
                </PaymentTitle>
                <PaymentDetailsNew className='payment-details'>
                  <CardWrapper>
                    <CardElement onChange={stripeElementChange} />
                  </CardWrapper>
                </PaymentDetailsNew>
              </PaymentMethod>
            </PaymentMethods>
            <InputError>{state.errMessages.cardError}</InputError>
          </div>
        }
        width='auto'
      />
      <BpModal
        open={creditsModalOpen && state.completedStep}
        title={`Boostpoint Credits`}
        primaryAction={{
          btnLabel:
            creditsPurpose === "campaignRequired" ? "Continue" : "Close",
          action: props.closeCreditModal
        }}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        contentLabel='Boostpoint Credits'
        loading={state.loading}
        body={
          <div>
            <p>
              <SuccessIcon className='material-icons'>done</SuccessIcon>
            </p>
            <p>
              <strong>Charge Successful</strong>
            </p>
            <p>{credits.addCreditsAmount} credits added</p>
          </div>
        }
        width='auto'
      />
    </div>
  );
};

export default injectStripe(OrderCredits);
