import React from "react";
import styled from "styled-components";
import { PickerOverlay } from "filestack-react";
import Picker from "emoji-picker-react";
import Linkify from "react-linkify";
import { SecureLink } from "react-secure-link";
import Floater from "react-floater";
import { monthsAbr } from "../../../utils/Helpers";
import { InputBoxWrapper, TextArea } from "../../styled/Input";
import { Button } from "../../styled/Buttons";

import { firstBreakpoint } from "../../styled/PhoneNumberProvision";
import {
  DeviceIphoneX,
  DeviceFrame,
  DeviceHeader,
  DeviceContent
} from "../../styled/DeviceMock";
import WorkflowModal from "./WorkflowModal";

const MessageWrapper = styled.div`
  padding: 0;
  position: relative;
  display: block;
  grid-gap: 1em;
  overflow: hidden;
  min-height: 72vh;
  @media screen and (min-width: ${firstBreakpoint}px) {
    min-height: none;
    overflow: auto;
    display: grid;
    grid-template-columns: 3fr 4fr;
  }
`;

const ToggleWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 2px 20px 20px;
  border: 1px solid ${props => props.theme.TextC};
  padding: 6px;
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: none;
  }
`;

const Toggle = styled.div`
  padding: ${props => (props.selected ? "5px 0" : "5px")};
  margin-top: 4px;
  text-align: center;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    padding-bottom: 3px;
    border-bottom: ${props =>
      props.selected ? `4px solid ${props.theme.HC1}` : "none"};
    margin-bottom: ${props => (props.selected ? "0" : "4px")};
  }
  &:first-child {
    border-right: 1px solid ${props => props.theme.TextC};
  }
`;

const PreviewWrapper = styled.div`
  position: absolute;
  z-index: 5;
  top: ${props => (props.show ? "52px" : "100vh")};
  width: 100%;
  transition: all 0.3s ease-in-out;
  @media screen and (min-width: ${firstBreakpoint}px) {
    position: static;
  }
`;

const ResponseWrapper = styled.div`
  display: block;
  z-index: 20;
  margin: 0 30px;
`;

const AddField = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const FieldButton = styled.div`
  cursor: pointer;
`;

const FieldList = styled.div`
  z-index: 30;
  display: ${props => (props.visible ? "block" : "none")};
  position: absolute;
  right: 0;
  top: 30px;
  background: white;
  border: 1px solid ${props => props.theme.TextC};
  border-radius: 5px;
  padding: 5px 10px;
  min-width: 170px;
  &:after {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
    position: absolute;
    top: -10px;
    right: 11px;
  }
  &:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 11px solid ${props => props.theme.TextC};
    position: absolute;
    top: -11px;
    right: 10px;
  }
`;

const Field = styled.li`
  border-bottom: 1px solid ${props => props.theme.TextC};
  padding: 6px 0;
  &:last-of-type {
    border: none;
  }
`;

const MediaPickerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  margin: 15px 0 0 8px;
  /* cursor: pointer; */
  .fsp-picker {
    position: fixed;
    width: 90vw;
    height: 79vh;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  border: dashed 2px ${props => props.theme.TextC};
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  margin-left: 20px;
  cursor: pointer;
  &:hover {
    border-color: ${props => props.theme.HC1};
  }
  &:hover span {
    color: ${props => props.theme.HC1};
  }
`;

const ImageButton = styled.span`
  color: ${props => props.theme.TextC};
  cursor: pointer;
  border-radius: 5px;
  padding: 4px;
  font-size: 36px;
`;

const InputActions = styled.div`
  display: grid;
  grid-template-columns: 1fr 24px 61px;
  h4 {
    margin: 0;
  }
`;

const ResponseInput = styled(InputBoxWrapper)`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin: 10px;
  padding: 0;
  background-color: ${props => props.theme.BGC};
  border: 1px solid ${props => props.theme.TextC};
  border-radius: 3px;
  padding: 0;
  .react-emoji {
    align-items: flex-end;
  }
  .react-input-emoji--container {
    background-color: transparent;
    min-height: 200px;
    position: static;
  }
  .react-input-emoji--button {
    position: relative;
    /* left: -58px; */
    top: 0;
    /* margin-top: -15px; */
  }
  .react-input-emoji--button {
    padding-bottom: 6px;
  }
  .react-input-emoji--button svg {
    fill: ${props => props.theme.BGC};
    height: 30px;
    width: 30px;
  }
`;

const CharCount = styled.p`
  position: absolute;
  right: 11px;
  bottom: 6px;
  margin: 0;
  font-size: 0.8em;
`;

const SendMedia = styled.div`
  display: flex;
  /* width: 100%; */
  padding: 0 15px;
`;

const MediaWrapper = styled.div`
  position: relative;
  margin: 5px;
  max-width: 200px;
  .media-delete {
    opacity: 0.5;
  }
  &:hover .media-delete {
    opacity: 1;
  }
`;

const MediaDelete = styled.span`
  position: absolute;
  top: 0;
  display: block;
  left: 4px;
  top: 4px;
  padding: 3px;
  border-radius: 50%;
  color: ${props => props.theme.BGC};
  background-color: rgba(225, 225, 225, 0.8);
  cursor: pointer;
`;

const EmojiWrapper = styled.div`
  position: absolute;
  top: 0;
  z-index: 30;
  .emoji-picker-react {
    z-index: 31;
  }
`;

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.03);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 29;
  cursor: pointer;
`;

const ContentInner = styled.div`
  position: absolute;
  top: 30px;
  padding: 30px;
  z-index: 30;
  height: 97%;
  width: 100%;
`;

const MsgWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const AccountShort = styled.div`
  margin-top: 0.1em;
  display: inline-block;
`;

const AccountPicThmb = styled.img`
  height: 32px;
  margin: 0 0.4em;
  border-radius: 50%;
  &.thumbnail {
    width: 32px;
  }
`;

const ProfilePicPlaceholder = styled.i`
  height: 32px;
  margin: 0 15px;
  border-radius: 50%;
  display: block;
  font-size: 30px;
  color: #888;
`;

const MsgInner = styled.div`
  max-width: 700px;
  width: 100%;
`;

const MsgContainer = styled.div`
  background-color: ${props =>
    props.inbound ? props.theme.TextC : props.theme.HC1};
  color: ${props => (props.inbound ? props.theme.BGC : props.theme.ATextC)};
  border-radius: ${props =>
    props.inbound ? "5px 5px 5px 0px" : "5px 5px 0px 5px"};
  padding: 1em;
  text-align: left;
  a {
    color: ${props => (props.inbound ? "inherit" : "white")};
  }
`;

const MsgBody = styled.div`
  margin: 0;
  white-space: pre-wrap;
  div {
    white-space: pre-wrap;
  }
  img {
    max-width: 100%;
    max-height: 300px;
  }
`;

const MsgDate = styled.div`
  text-align: ${props => (props.inbound ? "left" : "right")};
  font-weight: bold;
  font-size: 0.7em;
  margin-top: 8px;
`;

const MsgDateContents = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: inherit;
  flex-direction: column;
  & > div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .material-icons {
    font-size: 19px;
  }
`;

const ImageWrapper = styled.div`
  .image-wrapper:last-child {
    margin-bottom: ${props => (props.bodyEmpty ? 0 : "1em")};
  }
`;

const ImageContainer = styled.div`
  position: relative;
  max-width: 100%;
  max-height: 300px;
  margin-bottom: 1em;
  .download-link {
    opacity: 0.5;
  }
  &:hover .download-link {
    opacity: 1;
  }
`;

const MessagePreview = styled.div`
  margin: 1rem;
  p {
    margin: 0 20px;
    @media screen and (min-width: ${firstBreakpoint}px) {
      margin: 0 40px;
    }
  }
`;

const FloaterIcon = styled.i`
  position: relative;
  top: 2px;
  font-size: 1em;
  color: lightgray;
  &:hover {
    color: darkgray;
  }
`;

const FloaterOverlay = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1005;
`;

const contactFields = {
  firstName: "First Name",
  lastName: "Last Name"
};

const floaterStyles = {
  wrapper: {
    cursor: "pointer",
    zIndex: 1010
  },
  container: {
    borderRadius: 5,
    color: "rgb(51, 51, 51)",
    padding: 15,
    textAlign: "center",
    zIndex: 1010
  },
  floater: {
    zIndex: 1010
  }
};

const EditSMS = props => {
  const {
    action,
    message = "",
    updateSMS,
    editSMSModal,
    setEditSMSModal,
    alert,
    userProfile = {},
    actionKey
  } = props;
  const [activeMessage, setActiveMessage] = React.useState(message);
  const [mediaUrl, setMediaUrl] = React.useState([]);
  const [showFileUploader, setShowFileUploader] = React.useState(false);
  const [showFields, setShowFields] = React.useState(false);
  const [showEmoji, setShowEmoji] = React.useState(false);
  const [cursorOffset, setCursorOffset] = React.useState(0);
  const [creditCount, setCreditCount] = React.useState(0);
  const [countFloater, setCountFloater] = React.useState(false);
  const [showPreview, setShowPreview] = React.useState(false);
  const refInput = React.useRef();

  React.useEffect(() => {
    if ((activeMessage, refInput && refInput.current && cursorOffset)) {
      refInput.current.selectionStart = refInput.current.selectionEnd =
        activeMessage.length - cursorOffset;
      setCursorOffset(0);
      if (!showEmoji) {
        refInput.current.focus();
      }
    }
    if (action.mediaUrl) {
      setMediaUrl(action.mediaUrl);
    }
  }, [cursorOffset, activeMessage, showEmoji, action.mediaUrl]);

  React.useEffect(() => {
    let newCount = 1;
    if (mediaUrl.length > 0) {
      newCount = 2;
    } else if (activeMessage.length > 160) {
      newCount = 2;
    }
    if (newCount > 2) {
      newCount = 2;
    }
    setCreditCount(newCount);
  }, [activeMessage, mediaUrl]);

  const updateActiveMessage = text => {
    setActiveMessage(text);
  };

  const removeMedia = index => {
    const media = [...mediaUrl];
    media.splice(index, 1);
    setMediaUrl(media);
  };

  const mediaError = err => {
    console.log("media error", err);
  };

  const chooseMedia = data => {
    if (data.filesFailed.length > 0) {
      console.log("files failed to upload", data.filesFailed);
      alert({
        type: "error",
        msg: "Some files failed to upload"
      });
    } else {
      const { mimetype, url } = data.filesUploaded[0];
      const media = [{ mimeType: mimetype, url }];
      setMediaUrl(media);
    }
    setShowFileUploader(false);
  };

  const onChange = input => {
    setCursorOffset(0);
    setShowEmoji(false);
    setShowFields(false);
    updateActiveMessage(input.target.value, actionKey);
  };

  const onEmojiClick = (event, emojiObject) => {
    const cursor = refInput.current.selectionStart;
    setCursorOffset(activeMessage.length - cursor);
    const text =
      activeMessage.slice(0, cursor) +
      emojiObject.emoji +
      activeMessage.slice(cursor);
    updateActiveMessage(text, actionKey);
  };

  const insertField = field => {
    const cursor = refInput.current.selectionStart;
    setCursorOffset(activeMessage.length - cursor);
    const text =
      activeMessage.slice(0, cursor) +
      `{#${field}#}` +
      activeMessage.slice(cursor);
    updateActiveMessage(text, actionKey);
  };

  const getFormattedDate = initDate => {
    const date = new Date(initDate);
    const dateMonthNum = date.getMonth();
    const dateMonthText = monthsAbr[dateMonthNum];
    const dateFormatted = `${dateMonthText} ${date.getDate()}, ${date.getFullYear()}`;
    let dateHours = date.getHours();
    const timePeriod = dateHours > 12 ? "PM" : "AM";
    if (dateHours > 12) {
      dateHours = dateHours - 12;
    }
    const dateMinutes = `${date.getMinutes()}`.padStart(2, "0");
    const dateString = date.toString();
    const dateParts = dateString.split(/[/(/)]/);
    const timeZoneStrings = dateParts[1].split(" ");
    let timeZone = "";
    timeZoneStrings.forEach(
      zoneString => (timeZone = `${timeZone}${zoneString.charAt(0)}`)
    );
    return `${dateFormatted} ${dateHours}:${dateMinutes} ${timePeriod}`;
  };

  const checkCursor = (string, cursor) => {
    const fieldLocations = [];
    let cursorLocation = 0;
    let fieldsFound = true;
    let setCursor = 0;
    let allow = false;
    if (!string.indexOf("{#") || !string || cursor === 0) {
      allow = true;
    }
    while (fieldsFound === true) {
      const start = string.indexOf("{#", cursorLocation);
      if (start < 0) {
        fieldsFound = false;
        allow = true;
      } else {
        const end = string.indexOf("#}", start + 2) + 2;
        if (end < start) {
          fieldsFound = false;
        }
        cursorLocation = end;
        if (cursor > start && cursor < end) {
          setCursor = end;
          allow = false;
        }
        fieldLocations.push({ start, end });
      }
    }
    return { allow, setCursor, fieldLocations };
  };

  const onKeyDown = e => {
    const key = e.key;
    const special = [
      "CapsLock",
      "Meta",
      "Shift",
      "Alt",
      "Control",
      "Shift",
      "Tab",
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "ArrowUp",
      "ArrowDown"
    ];
    if (special.includes(key)) {
      return;
    }
    const cursor = refInput.current.selectionStart;
    const allow = checkCursor(activeMessage, cursor);
    if (allow.allow !== true && key === "Enter") {
      e.preventDefault();
      return;
    }
    if (allow.allow !== true) {
      e.preventDefault();
      const text =
        activeMessage.slice(0, allow.setCursor) +
        key +
        activeMessage.slice(allow.setCursor);
      updateActiveMessage(text, actionKey);
      setCursorOffset(activeMessage.length - allow.setCursor);
    }
  };

  const replaceFields = () => {
    const placeholderName = { firstName: "Jane", lastName: "Doe" };
    const allow = checkCursor(activeMessage, 1);
    let processedMessage = activeMessage;
    if (allow.fieldLocations && allow.fieldLocations.length > 0) {
      allow.fieldLocations.reverse().forEach(location => {
        const regex = /{#|#}/g;
        const field = activeMessage
          .substring(location.start, location.end)
          .replace(regex, "");
        processedMessage =
          processedMessage.slice(0, location.start) +
          placeholderName[field] +
          processedMessage.slice(location.end);
      });
    }
    return processedMessage;
  };

  const countFloaterClick = e => {
    e.preventDefault();
    setCountFloater(!countFloater);
  };

  const moveUp = () => {
    props.setActionOrder("up", actionKey);
  };

  const moveDown = () => {
    props.setActionOrder("down", actionKey);
  };

  return (
    <WorkflowModal
      open={editSMSModal}
      title='Create a Message'
      onCloseMethod={() => setEditSMSModal(false)}
      width='90vw'
      styleOverride={{ bodyStyles: { maxHeight: "none" } }}
      // backgroundColor="#fafafa"
      body={
        <MessageWrapper>
          <ToggleWrapper>
            <Toggle
              onClick={() => setShowPreview(false)}
              selected={!showPreview}
            >
              <span>Message</span>
            </Toggle>
            <Toggle onClick={() => setShowPreview(true)} selected={showPreview}>
              <span>Preview</span>
            </Toggle>
          </ToggleWrapper>
          <PreviewWrapper show={showPreview}>
            <DeviceIphoneX className='device'>
              <DeviceFrame>
                <DeviceContent>
                  <ContentInner>
                    <MsgWrapper>
                      {userProfile.fbUserID ? (
                        <AccountShort>
                          <AccountPicThmb
                            src={`https://res.cloudinary.com/equipter/image/facebook/c_fill,h_32,w_32/${userProfile.fbUserID}.jpg`}
                            alt='User Profile'
                            className='thumbnail'
                          />
                        </AccountShort>
                      ) : (
                        <ProfilePicPlaceholder className='material-icons'>
                          account_circle
                        </ProfilePicPlaceholder>
                      )}
                      <MsgInner>
                        <MsgContainer>
                          <MsgBody>
                            {mediaUrl.length > 0 && (
                              <ImageWrapper bodyEmpty={!activeMessage}>
                                {mediaUrl.map((image, i) => (
                                  <ImageContainer
                                    key={i}
                                    className='image-wrapper'
                                  >
                                    {image.mimeType.includes("image") ? (
                                      <img
                                        src={image.url}
                                        data-pin-nopin='true'
                                        alt={activeMessage}
                                      />
                                    ) : image.mimeType.includes("video") ? (
                                      <video
                                        style={{
                                          maxWidth: "100%",
                                          maxHeight: "63vh"
                                        }}
                                        controls
                                      >
                                        <source
                                          src={image.url}
                                          type={image.mimeType}
                                        />
                                        Your browser does not support this video
                                        type
                                      </video>
                                    ) : (
                                      "Cannot preview media"
                                    )}
                                  </ImageContainer>
                                ))}
                              </ImageWrapper>
                            )}
                            <Linkify
                              componentDecorator={(
                                decoratedHref,
                                decoratedText,
                                i
                              ) => (
                                <SecureLink
                                  href={decoratedHref}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  key={i}
                                >
                                  {decoratedText}
                                </SecureLink>
                              )}
                            >
                              {replaceFields()}
                            </Linkify>
                          </MsgBody>
                        </MsgContainer>
                        <MsgDate>
                          <MsgDateContents>
                            {getFormattedDate(new Date())}
                          </MsgDateContents>
                        </MsgDate>
                      </MsgInner>
                    </MsgWrapper>
                  </ContentInner>
                </DeviceContent>
              </DeviceFrame>
              <DeviceHeader />
            </DeviceIphoneX>
          </PreviewWrapper>
          <div>
            <ResponseWrapper>
              <InputActions>
                <h4>Enter Your Message</h4>
                <span
                  className='material-icons'
                  onClick={() => setShowEmoji(true)}
                >
                  insert_emoticon
                </span>
                <AddField>
                  <FieldButton onClick={() => setShowFields(true)}>
                    <strong>&#123;</strong> ... <strong>&#125;</strong>
                  </FieldButton>
                  <FieldList visible={showFields}>
                    <ul>
                      {Object.keys(contactFields).map((field, key) => (
                        <Field key={key} onClick={() => insertField(field)}>
                          {contactFields[field]}
                        </Field>
                      ))}
                    </ul>
                  </FieldList>
                  {showFields && (
                    <Overlay
                      onClick={() => setShowFields(false)}
                      className='overlay'
                    />
                  )}
                </AddField>
              </InputActions>

              <ResponseInput>
                <TextArea
                  ref={refInput}
                  type='text'
                  value={activeMessage}
                  onChange={onChange}
                  onKeyDown={e => onKeyDown(e)}
                />
                {!!showEmoji && (
                  <EmojiWrapper>
                    <Picker onEmojiClick={onEmojiClick} />
                    <Overlay
                      onClick={() => setShowEmoji(false)}
                      className='overlay'
                    />
                  </EmojiWrapper>
                )}
                <CharCount>{activeMessage.length}/160</CharCount>
              </ResponseInput>
              <div>
                This will count as {creditCount} credit
                {creditCount > 1 && "s"}
                <Floater
                  open={countFloater}
                  content='Messages over 160 characters will be split into 2 messages when sent.'
                  styles={floaterStyles}
                  placement='right'
                >
                  <FloaterIcon
                    className='material-icons'
                    onClick={e => countFloaterClick(e)}
                    name='floater-toggle'
                  >
                    info
                  </FloaterIcon>
                  {countFloater && (
                    <FloaterOverlay
                      onClick={e => countFloaterClick(e)}
                      className='overlay'
                    />
                  )}
                </Floater>
              </div>
              <MediaPickerWrapper>
                {mediaUrl.length === 0 && <p>Add Media</p>}
                {mediaUrl.length > 0 && (
                  <SendMedia>
                    {mediaUrl.map((mediaItem, key) =>
                      mediaItem.mimeType.includes("image") ? (
                        <MediaWrapper key={key}>
                          <MediaDelete
                            className='material-icons media-delete'
                            onClick={() => removeMedia(key)}
                          >
                            delete
                          </MediaDelete>
                          <img
                            src={mediaItem.url}
                            alt='thumbnail'
                            data-pin-nopin='true'
                          />
                        </MediaWrapper>
                      ) : mediaItem.mimeType.includes("video") ? (
                        <MediaWrapper key={key}>
                          <MediaDelete
                            className='material-icons media-delete'
                            onClick={() => removeMedia(key)}
                          >
                            delete
                          </MediaDelete>
                          <video
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100px"
                            }}
                          >
                            <source
                              src={mediaItem.url}
                              type={mediaItem.mimeType}
                            />
                            Your browser does not support this video type
                          </video>
                        </MediaWrapper>
                      ) : null
                    )}
                  </SendMedia>
                )}
                {mediaUrl.length === 0 && (
                  <ButtonWrapper
                    onClick={() => {
                      setShowFileUploader(true);
                    }}
                  >
                    <ImageButton className='material-icons image-button'>
                      add_a_photo
                    </ImageButton>
                  </ButtonWrapper>
                )}
                {!!showFileUploader && (
                  <PickerOverlay
                    apikey='AF3TGK2TiG0ENSpQlYA1nz'
                    onSuccess={chooseMedia}
                    onError={mediaError}
                    pickerOptions={{
                      onClose: () => setShowFileUploader(false),
                      transformations: {
                        circle: false,
                        crop: true,
                        rotate: true
                      },
                      acceptFn: (file, options) =>
                        options
                          .mimeFromMagicBytes(file.originalFile)
                          .then(res => {
                            const mimeArray = res.split("/");
                            if (mimeArray[0] !== "image") {
                              return Promise.reject(
                                "Only images are allowed for automated messages."
                              );
                            } else if (
                              !["jpeg", "jpg", "png", "gif"].includes(
                                mimeArray[1]
                              )
                            ) {
                              return Promise.reject(
                                "That image type is not allowed. Only jpeg, png, and gif images are allowed."
                              );
                            }
                            return Promise.resolve();
                          })
                    }}
                  />
                )}
              </MediaPickerWrapper>
            </ResponseWrapper>
            <Button onClick={() => setEditSMSModal(false)}>Cancel</Button>
            <Button
              onClick={() => {
                updateSMS(activeMessage, mediaUrl, action);
              }}
              solid
            >
              Save
            </Button>
          </div>
        </MessageWrapper>
      }
    />
  );
};

export default EditSMS;
