import React from "react";
import {
  contactsGet,
  contactUpdate,
  statusCreate
} from "../../../../utils/ContactAPI";
import BpModal from "../../../elements/BpModal";
import { Button } from "../../../styled/Buttons";

const StatusOverride = props => {
  const { defaultStatuses, companyId } = props;

  const [modalConfirm, setModalConfirm] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleOverride = async () => {
    setLoading(true);
    const wait = [];
    defaultStatuses.forEach(async status => {
      const type = status.type ? status.type.toUpperCase() : "RECRUITING";
      const newStatus = await statusCreate({
        status: status.status,
        orderNumber: status.orderNumber,
        industry: status.industry,
        isDefault: status.isDefault,
        companyId,
        isStock: false,
        type: type
      });
      wait.push(newStatus);
      const contactsToUpdate = await contactsGet(status._id);
      contactsToUpdate.forEach(contact => {
        if (contact.statusId === status._id) {
          wait.push(contactUpdate({ ...contact, statusId: newStatus._id }));
        }
      });
    });
    await Promise.all(wait);
    setModalConfirm(false);
    props.handleOverride();
    setLoading(false);
  };

  return (
    <div>
      <Button onClick={() => setModalConfirm(true)}>
        Override Default Statuses
      </Button>
      <BpModal
        open={modalConfirm}
        title={`Confirm Default Status`}
        primaryAction={{
          btnLabel: "Continue",
          action: handleOverride,
          loading
        }}
        secondaryAction={{
          btnLabel: "Cancel",
          action: () => setModalConfirm(false),
          loading
        }}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        contentLabel='Confirm Default Status'
        body={<p>Are you sure you want to override the default statuses?</p>}
        width='auto'
      />
    </div>
  );
};

export default StatusOverride;
