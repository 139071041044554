import React from "react";
import styled from "styled-components";
import {
  InputBox,
  InputBoxWrapper,
  InputContainer
} from "../../../styled/Input";
import StatusDropdown from "./StatusDropdown";

const StatusType = styled.div`
  margin-top: 10px;
  font-weight: bold;
`;

const StatusMapping = styled.div`
  display: grid;
  grid-template-columns: 1fr 75px 1fr;
  align-items: center;
  justify-content: center;
  max-width: 536px;
  margin: 0 auto;
`;

const StatusMapWrapper = styled(InputContainer)`
  margin: 0;
  width: auto;
`;

const StatusArrow = styled.p`
  width: 75px;
`;

const StatusReassign = props => {
  const { contactStatuses, defaultStatuses, statusMigrate } = props;
  const [statusList, setStatusList] = React.useState({});

  React.useEffect(() => {
    if (contactStatuses && contactStatuses.length > 0) {
      const newStatus = {};
      contactStatuses.forEach(c => {
        if (!newStatus[c.type]) {
          newStatus[c.type] = [c];
        } else {
          newStatus[c.type].push(c);
        }
      });
      setStatusList(newStatus);
    }
  }, [contactStatuses]);

  const statusMatch = (value, name) => {
    const selectedStatus = defaultStatuses.find(status => status._id === value);
    statusMigrate[name] = selectedStatus;
    props.handleRevertStatusMatch(statusMigrate);
  };

  return Object.keys(statusList).map(type => (
    <div key={type}>
      <StatusType>{type}</StatusType>
      {statusList[type].map(status => (
        <StatusMapping key={status._id}>
          <InputBox
            type='text'
            name={status._id}
            readOnly
            value={status.status}
          />
          <StatusArrow>
            <span className='material-icons'>arrow_right_alt</span>
          </StatusArrow>
          <StatusMapWrapper>
            <InputBoxWrapper>
              <StatusDropdown
                statusValue={statusMigrate[status._id]?._id}
                contactStatuses={defaultStatuses}
                onChange={statusMatch}
                name={status._id}
              />
            </InputBoxWrapper>
          </StatusMapWrapper>
        </StatusMapping>
      ))}
    </div>
  ));
};

export default StatusReassign;
