import React from "react";
import styled from "styled-components";
import queryString from "query-string";
import * as _ from "underscore";
import { LoaderDotsSmall } from "../../elements/Loaders";
import MessagingConversationList from "./MessagingConversationList";
import ContactMessages from "../contacts/ContactMessages";
import NewMessageResults from "./NewMessageResults";
import Floater from "react-floater";
import { errorHandler, handleFloaterClick } from "../../../utils/Helpers";
import WorkflowModal from "../workflows/WorkflowModal";
// Styled Components
import { Button } from "../../styled/Buttons";
import Card from "../../styled/Card";
import { ContactLoading } from "../../styled/Contacts";
import Search from "../../elements/Search";
import NoConnectModal from "../../NoConnectModal";
import {
  messagesGetConversations,
  messagesGetThread,
  messagesSend,
  messagesMarkRead
} from "../../../utils/MessagesAPI";
import { getStripeInfo, isConnectActive } from "../../../utils/StripeAPI";
import {
  contactGetById,
  contactsGet,
  deletedStatus
} from "../../../utils/ContactAPI";
import MessageSearch from "./MessageSearch";

const floaterStyles = {
  wrapper: {
    cursor: "pointer"
  },
  container: {
    borderRadius: 5,
    color: "rgb(51, 51, 51)",
    padding: 15,
    textAlign: "center"
  }
};

const ContactCard = styled(Card)`
  margin: ${props =>
    props.layoutPosition === "left"
      ? "0 1.5em 2.5em 2.5em"
      : "0 2.5em 2.5em 1.5em"};
  padding: 1em;
  @media only screen and (max-width: 600px) {
    max-width: 87vw;
    margin: 0 auto 1.5em;
  }
  @media (max-width: 900px) {
    box-shadow: none;
  }
`;

const MsgPageContainer = styled.div`
  box-sizing: border-box;
  height: 100%;
  padding: 20px;
`;

const MsgPageWrapper = styled.div`
  display: grid;
  border-sizing: border-box;
  padding: 5px;
  position: relative;
  height: 100%;
  overflow: hidden;
  @media (max-width: 900px) {
    padding: 0;
    background-color: white;
    box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
      0 1px 3px 1px rgba(60, 64, 67, 0.16);
  }
  @media only screen and (min-width: 901px) {
    height: 100%;
    margin-left: 10px;
    grid-template-columns: 322px 1fr;
    grid-gap: 12px;
  }
  @media (max-width: 1199px) {
    top: -22px;
  }
`;

const MessagesHeader = styled.div`
  display: block;
  margin: 3px;
`;

const MessagesTitle = styled.div`
  display: flex;
  margin: 0 7px;
  flex-direction: column;
  align-items: center;
`;

const HeaderDescription = styled.div`
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${props => props.theme.TextC};
`;

const PurchaseLink = styled.p`
  margin: 0;
  cursor: pointer;
  color: ${props => props.theme.HC2};
`;

const MessageTitle = styled.h1`
  font-weight: normal;
  margin: 9px 0 0;
  flex-direction: row;
  font-size: 26px;
  @media (min-width: 890px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const ThreadCard = styled(ContactCard)`
  padding: 0;
  margin: 0;
  height: 100%;
  min-width: 300px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 900px) {
    width: auto;
    z-index: 0;
    position: relative;
    top: 0;
    margin: 0;
  }
  @media only screen and (max-width: 600px) {
    box-shadow: none;
    max-width: none;
  }
`;

const MsgWrapperCard = styled.div`
  overflow: auto;
  height: 100%;
  transition: all 0.3s ease-in-out;
  position: fixed;
  z-index: 10;
  max-width: none;
  margin: 0 0 0 1000px;
  overflow: hidden;
  @media only screen and (min-width: 901px) {
    height: 100%;
    margin-left: 0;
    position: relative;
  }
  &.visible {
    width: 92vw;
    height: 78vh;
    position: absolute;
    @media only screen and (min-width: 901px) {
      width: 100%;
      height: auto;
      position: relative;
    }
    margin-left: 0;
    @media only screen and (max-height: 400px) {
      height: calc(var(--vh, 1vh) * 78);
    }
  }
  &.hidden {
    margin-left: 1000px;
    @media only screen and (min-width: 901px) {
      margin-left: 0;
    }
  }
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
    0 1px 3px 1px rgba(60, 64, 67, 0.16);
  @media only screen and (min-width: 901px) {
    position: static;
    width: 100%;
  }
`;

const MessageActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  .search-wrapper {
    width: 100%;
  }
`;

const ConversationListWrapper = styled.div`
  position: relative;
  overflow-y: scroll;
  height: 100%;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Icon = styled.i`
  position: relative;
  top: 2px;
  font-size: 1em;
  padding: 5px;
  color: ${props => (props.warning ? props.theme.HCErr : props.theme.TextC)};
  &:hover {
    color: darkgray;
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 901px) {
    flex-direction: row;
  }
`;

const Overlay = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1005;
`;

class Messaging extends React.Component {
  state = {
    contact: {},
    contactMessages: [],
    loadingContact: false,
    conversationList: [],
    conversationListLoading: true,
    messagesLoading: false,
    contactId: "",
    contactPhone: "",
    errMessages: {},
    errFields: [],
    contactSnapshot: null,
    floaters: {
      smsCredits: false
    },
    newMessageList: [],
    filteredMessageList: [],
    nmSearchValue: "",
    newMessageModal: false,
    noConnect: false,
    modalPurchaseCredits: false,
    threadListPage: 0,
    contacts: [],
    sending: false,
    conversationMore: false
  };

  async componentDidMount() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    const query = queryString.parse(this.props.location.search);
    const { contactId, contactPhone, page = 0 } = query;
    if (page) {
      this.setState({ threadListPage: Number(page) });
    }
    const updateDebounce = _.debounce(this.updateConversations, 100);
    if (contactId && contactId !== "undefined" && contactPhone) {
      this.setState({ contactId, contactPhone }, () => {
        updateDebounce();
      });
      this.getContact(contactId);
    } else {
      this.getContacts();
    }

    this.newMessageList();

    const scrollDebounce = _.debounce(this.heightUpdate, 100);
    window.addEventListener("resize", () => {
      // We execute the same script as before
      scrollDebounce();
    });
    this.updateConversationList(Number(page));
    this.checkSubscriptions();
  }

  componentDidUpdate = prevProps => {
    const { unreadUpdated } = this.props;
    const { contactId } = this.state;
    const { unreadUpdated: prevUnreadUpdated } = prevProps;
    if (unreadUpdated.getTime() > prevUnreadUpdated.getTime()) {
      if (contactId) {
        this.getContact(contactId);
        const updateDebounce = _.debounce(this.updateConversations, 100);
        updateDebounce();
      }
      this.updateConversationList();
    }
  };

  heightUpdate = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  checkSubscriptions = async () => {
    const stripeInfo = await getStripeInfo();
    const connectActive = isConnectActive(stripeInfo.subscriptions);
    this.setState({ noConnect: !connectActive });
  };

  updateConversations = async () => {
    const { userProfile } = this.props;
    const { contactId, contactPhone } = this.state;
    if (contactId) {
      const contactMessages = await this.getConversation(
        userProfile.companyID,
        contactId,
        contactPhone
      );
      this.setState({ contactMessages }, async () => {
        await this.markRead(contactId);
        this.props.updateUnreadMsgCount();
      });
    } else {
      this.setState({ loadingContact: false });
    }
  };

  conversationLoadNext = () => {
    const { threadListPage, contactId, contactPhone } = this.state;
    this.setState({ threadListPage: threadListPage + 1 }, () => {
      const queryParams = [`page=${threadListPage + 1}`];
      if (contactId) {
        queryParams.push(`contactId=${contactId}`);
      }
      if (contactPhone) {
        queryParams.push(`contactPhone=${encodeURIComponent(contactPhone)}`);
      }

      this.props.history.push(
        `/messages${queryParams.length > 0 ? `?${queryParams.join("&")}` : ""}`
      );
      this.loadConversationList();
    });
  };

  loadConversationList = async () => {
    const { threadListPage, conversationList } = this.state;
    const take = 10;
    try {
      const conversation = await messagesGetConversations({
        take,
        page: threadListPage
      });
      const newConversationList = [...conversationList, ...conversation];
      this.setState({
        conversationList: newConversationList,
        conversationListLoading: false,
        conversationMore: conversation.length === take
      });
    } catch (err) {
      this.setState({ conversationListLoading: false });
      console.log("error loading conversation list", err.message);
    }
  };

  updateConversationList = async page => {
    const { threadListPage } = this.state;
    const take = 10;
    try {
      // this.setState({ conversationListLoading: true });
      let conversationList = [];
      const pageCount = page ? page : threadListPage;
      for (let step = 0; step <= pageCount; step++) {
        const conversation = await messagesGetConversations({
          take,
          page: step
        });
        conversationList = [...conversationList, ...conversation];
      }
      const totalMessages = take * (threadListPage + 1);
      this.setState({
        conversationList,
        conversationListLoading: false,
        conversationMore: conversationList.length === totalMessages
      });
    } catch (err) {
      this.setState({ conversationListLoading: false });
      console.log("err getting conversations", `${err.message}`);
    }
  };

  getContacts = async () => {
    try {
      const contacts = await contactsGet();
      this.setState({ contacts });
      return contacts;
    } catch (err) {
      console.log("err", err.message);
    }
  };

  getContact = async contactId => {
    try {
      if (contactId) {
        const contact = await contactGetById(contactId);
        this.setState({ contact });
        return contact;
      } else {
        console.log("contactId is missing");
      }
    } catch (err) {
      const { status, message } = errorHandler(err);
      console.log("err", `${status}: ${message}`);
    }
  };

  getConversation = async (companyId, contactId, contactPhone) => {
    try {
      const conversationResponse = await messagesGetThread({
        companyId,
        contactId
      });
      return conversationResponse;
    } catch (err) {
      console.log("error getting conversation list", err);
    }
  };

  markRead = async contactId => {
    const {
      userCompany: { _id: companyId }
    } = this.props;
    try {
      const msgResponse = await messagesMarkRead({ contactId, companyId });
      return msgResponse;
    } catch (err) {
      const { status, message } = errorHandler(err);
      console.log(status, message);
      throw new Error(message);
    }
  };

  validateMsg = () => {
    const { contact, contactPhone, noConnect } = this.state;
    const { userCompany, alert, smsCredits } = this.props;
    const { twilioPhone } = userCompany;
    const to = contactPhone
      ? contactPhone
      : contact.mobilePhone
      ? contact.mobilePhone
      : contact.phone;
    if (noConnect) {
      alert({
        type: "error",
        msg: (
          <span>
            You do not have an active Boostpoint Connect subscription.{" "}
            <a
              href='/integrations/BoostpointConnect?signup=true'
              style={{ color: "white" }}
            >
              Please sign up for Boostpoint Connect.
            </a>
          </span>
        )
      });
      return false;
    }
    if (!twilioPhone) {
      alert({
        type: "error",
        msg: (
          <span>
            Your phone number is missing.{" "}
            <a
              href='/integrations/BoostpointConnect?signup=true'
              style={{ color: "white" }}
            >
              Please complete the sign-up process to select a phone number.
            </a>
          </span>
        )
      });
      return false;
    }
    if (smsCredits < 1) {
      alert({
        type: "error",
        msg: (
          <span>
            You are out of message credits. Please contact us at{" "}
            <a
              href='mailto:help@boostpoint.com'
              target='_blank'
              rel='noopener noreferrer'
              style={{ color: "white" }}
            >
              help@boostpoint.com
            </a>{" "}
            to get more.
          </span>
        )
      });
      return false;
    }
    if (to === twilioPhone) {
      console.log("error sending message - to matches smsPhone");
      alert({
        type: "error",
        msg: (
          <span>
            Error sending message. Please reload page. If you continue to get
            this error, contact us at{" "}
            <a
              href='mailto:help@boostpoint.com'
              target='_blank'
              rel='noopener noreferrer'
              style={{ color: "white" }}
            >
              help@boostpoint.com
            </a>
          </span>
        )
      });
      return false;
    }
    return true;
  };

  sendMessage = async ({ msgBody, mediaUrl = [] }) => {
    const { contact, contactMessages, contactPhone } = this.state;
    const { _id: contactId } = contact;
    const { userCompany } = this.props;
    const { _id: companyId, twilioPhone } = userCompany;
    const valid = this.validateMsg();
    if (!valid) {
      return false;
    }
    const to = contactPhone
      ? contactPhone
      : contact.mobilePhone
      ? contact.mobilePhone
      : contact.phone;
    try {
      this.setState({ sending: true });
      const newMessage = {
        body: msgBody,
        direction: "outbound-api",
        from: twilioPhone,
        to,
        smsStatus: "queued",
        companyId,
        contactId
      };
      if (mediaUrl.length > 0) {
        newMessage.mediaUrl = mediaUrl;
      } else {
        newMessage.mediaUrl = [];
      }
      contactMessages.splice(0, 0, newMessage);
      this.setState({ contactMessages });
      const msgResponse = await messagesSend({
        body: msgBody,
        from: twilioPhone,
        to,
        companyId: companyId.toString(),
        contactId: contactId.toString(),
        mediaUrl,
        env: process.env.NODE_ENV
      });
      contactMessages.splice(0, 1, msgResponse);
      this.setState({ contactMessages, sending: false });
      this.updateConversationList();
      this.props.updateUnreadMsgCount();
      this.props.getCredits();
      return msgResponse;
    } catch (err) {
      console.log("error sending message", err.message);
    }
  };

  loadNewContact = async (contactId, contactPhone) => {
    this.setState({
      newMessageModal: false,
      loadingContact: true,
      messagesLoading: true
    });
    const { threadListPage, conversationList } = this.state;
    const { userProfile: { companyId } = {} } = this.props;
    const queryParams = [
      `contactId=${contactId}`,
      `contactPhone=${encodeURIComponent(contactPhone)}`
    ];
    if (threadListPage) {
      queryParams.push(`page=${threadListPage}`);
    }
    this.props.history.push(
      `/messages${queryParams.length > 0 ? `?${queryParams.join("&")}` : ""}`
    );
    this.setState({ contactId, contactPhone });
    // this.getContact(contactId);
    const contactMessages = await this.getConversation(companyId, contactId);
    await this.getContact(contactId);
    const updatedConversationList = conversationList.map(c => {
      if (c._id === contactId) {
        c.lastRead = true;
        c.message.read = true;
        c.unread = 0;
      }
      return c;
    });
    this.setState(
      {
        contactMessages,
        loadingContact: false,
        messagesLoading: false
      },
      async () => {
        await this.markRead(contactId);
        this.setState({ conversationList: updatedConversationList });
        this.props.updateUnreadMsgCount();
      }
    );
  };

  unLoadContact = () => {
    this.props.history.push(`/messages`);
    this.setState({
      contactId: null,
      contactPhone: null,
      contactMessages: [],
      contact: {}
    });
  };

  onKeyDown = event => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.search();
    }
  };

  handleFloaterClick = (e, clickTarget) => {
    e.preventDefault();
    const { floaters } = this.state;
    const newFloaters = handleFloaterClick(clickTarget, floaters);
    this.setState({ floaters: newFloaters });
  };

  newMessageList = async () => {
    try {
      const results = await this.getContacts();
      results
        .filter(
          r => r.statusId !== deletedStatus || (!r.mobilePhone && !r.phone)
        )
        .sort(function (a, b) {
          var nameA = a.lastName ? a.lastName.toLowerCase() : null; // ignore upper and lowercase
          var nameB = b.lastName ? b.lastName.toLowerCase() : null; // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          } else if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
      this.setState({
        newMessageList: results,
        filteredMessageList: results
      });
    } catch (err) {
      console.log("error getting search results", err);
      this.setState({
        newMessageList: [],
        filteredMessageList: []
      });
    }
  };

  newMessageSearch = () => {
    const { newMessageList, nmSearchValue } = this.state;
    this.setState({
      filteredMessageList: newMessageList.filter(
        contact =>
          (contact.firstName &&
            contact.firstName
              .toLowerCase()
              .includes(nmSearchValue.toLowerCase())) ||
          (contact.lastName &&
            contact.lastName
              .toLowerCase()
              .includes(nmSearchValue.toLowerCase()))
      )
    });
  };

  clearNewMessageSearch = () => {
    const { newMessageList } = this.state;
    this.setState({ nmSearchValue: "", filteredMessageList: newMessageList });
  };

  onNewMessageSearchChange = value => {
    if (!value) {
      this.clearNewMessageSearch();
    } else {
      this.setState({ nmSearchValue: value }, this.newMessageSearch());
    }
  };

  toggleCreditPurchase = () => {
    this.props.openCreditModal();
  };

  render() {
    const {
      contact,
      contactMessages,
      conversationList = [],
      conversationListLoading,
      contacts,
      contactId,
      floaters,
      nmSearchValue,
      filteredMessageList,
      newMessageModal,
      messagesLoading
    } = this.state;
    const { userProfile, userCompany, alert, unreadMessages, smsCredits } =
      this.props;
    return (
      <MsgPageContainer>
        <MsgPageWrapper>
          <NoConnectModal
            showModal={this.state.noConnect}
            history={this.props.history}
          ></NoConnectModal>
          <ThreadCard
            layoutPosition='left'
            className={!contactId ? "visible" : "hidden"}
            creditsLow={smsCredits < 30}
          >
            <MessagesHeader className='MessagesHeader'>
              <MessagesTitle>
                {/* <MessageIcon className="material-icons">chat</MessageIcon> */}
                <MessageTitle>Messages</MessageTitle>
                <HeaderDescription>
                  {unreadMessages} Unread <br />
                  {smsCredits} Available Messages
                  <Floater
                    open={floaters.smsCredits}
                    content={"The number of messages you can send and receive."}
                    styles={floaterStyles}
                    event='hover'
                    placement='right'
                  >
                    <Icon
                      className={`material-icons`}
                      onClick={e => this.handleFloaterClick(e, "smsCredits")}
                      name='floater-toggle'
                      warning={smsCredits < 30}
                    >
                      info
                    </Icon>
                    {floaters.smsCredits && (
                      <Overlay
                        onClick={e => this.handleFloaterClick(e, "smsCredits")}
                        className='overlay'
                      />
                    )}
                  </Floater>
                  {smsCredits < 30 && (
                    <PurchaseLink onClick={this.toggleCreditPurchase}>
                      Purchase more credits
                    </PurchaseLink>
                  )}
                </HeaderDescription>
              </MessagesTitle>
              <MessageActions>
                <MessageSearch
                  contacts={contacts}
                  loadNewContact={this.loadNewContact}
                  alert={this.props.alert}
                />
                <Button
                  onClick={() => {
                    this.setState({ newMessageModal: true });
                  }}
                  small
                >
                  New Message
                </Button>
              </MessageActions>
            </MessagesHeader>
            <ConversationListWrapper className='conversation-wrapper'>
              <MessagingConversationList
                conversationList={conversationList}
                contactList={contacts}
                unreadMessages={unreadMessages}
                conversationListLoading={conversationListLoading}
                loadNewContact={this.loadNewContact}
                contactId={contactId}
                contactPhone={this.state.contactPhone}
                conversationMore={this.state.conversationMore}
                conversationLoadNext={this.conversationLoadNext}
              />
            </ConversationListWrapper>
          </ThreadCard>
          {!!contact && (
            <MsgWrapperCard
              className={`cardSection__messages${
                contactId ? " visible" : " hidden"
              }`}
            >
              {!!messagesLoading && (
                <ContactLoading>
                  <LoaderDotsSmall message={false} />
                </ContactLoading>
              )}
              <ContactMessages
                contact={contact}
                contactId={contactId}
                contactPhone={this.state.contactPhone}
                contactMessages={contactMessages}
                userProfile={userProfile}
                userCompany={userCompany}
                sendMessage={this.sendMessage}
                alert={alert}
                unLoadContact={this.unLoadContact}
                updateConversations={this.updateConversations}
                sending={this.state.sending}
                loadNewContact={this.loadNewContact}
                page={this.state.threadListPage}
                updateConversationList={this.updateConversationList}
              />
            </MsgWrapperCard>
          )}
          <WorkflowModal
            open={newMessageModal}
            header={
              <SearchWrapper>
                <h6>New Message</h6>
                <Search
                  position={"absolute"}
                  searchValue={nmSearchValue}
                  onEnter={this.onKeyDown}
                  onSearch={this.newMessageSearch}
                  onSearchChange={this.onNewMessageSearchChange}
                  showActive={
                    filteredMessageList.length !==
                    this.state.newMessageList.length
                  }
                  showClear={
                    filteredMessageList.length !==
                    this.state.newMessageList.length
                  }
                  clearSearch={this.clearNewMessageSearch}
                  compact
                />
              </SearchWrapper>
            }
            onCloseMethod={() => {
              this.setState({ newMessageModal: false });
            }}
            body={
              <div>
                <NewMessageResults
                  searchResults={filteredMessageList}
                  loadNewContact={this.loadNewContact}
                  clearSearch={this.clearNewMessageSearch}
                />
              </div>
            }
          />
        </MsgPageWrapper>
      </MsgPageContainer>
    );
  }
}

export default Messaging;
