import React from "react";
import Floater from "react-floater";
import LineChart from "../../elements/charts/LineChart";
import LoadingBar from "../../elements/LoadingBar";
import {
  ChartTitle,
  ChartTitleNumber,
  DashboardModuleTitle,
  floaterStyles,
  Icon,
  Overlay,
  Section
} from "./DashboardStyles";

const AdSpendGraph = props => {
  const {
    floaters,
    currentAdAccount,
    chartsLoading,
    currentIncDate,
    adSpendData,
    prevAdSpendData,
    prevIncDate
  } = props;

  return (
    <Section columns='3/5'>
      <DashboardModuleTitle>
        <ChartTitle>
          <h6>Ad Spend</h6>
          <Floater
            open={floaters.adSpend}
            content='Total ad spend for all campaigns.'
            styles={floaterStyles}
            event='hover'
            placement='right'
          >
            <Icon
              className='material-icons'
              onClick={e => props.handleFloaterClick(e, "adSpend")}
              name='floater-toggle'
            >
              info
            </Icon>
            {floaters.adSpend && (
              <Overlay
                onClick={e => props.handleFloaterClick(e, "adSpend")}
                className='overlay'
              />
            )}
          </Floater>
        </ChartTitle>
        {chartsLoading ? null : (
          <ChartTitle>
            <ChartTitleNumber className='color-yellow'>
              $
              {currentAdAccount.spend && currentAdAccount.spend !== "NaN"
                ? currentAdAccount.spend
                : 0}
            </ChartTitleNumber>
          </ChartTitle>
        )}
      </DashboardModuleTitle>
      {chartsLoading ? (
        <LoadingBar />
      ) : (
        <LineChart
          backgroundColor='rgba(254, 185, 76, 0.2)'
          borderColor='rgba(254,185,76,1)'
          labelValue={currentIncDate}
          dataValue={adSpendData}
          prevBackgroundColor='rgba(235, 239, 239, 0.2)'
          prevBorderColor='rgba(211, 224, 224, 1)'
          prevLabelValue={prevIncDate}
          prevDataValue={prevAdSpendData}
        />
      )}
    </Section>
  );
};

export default AdSpendGraph;
