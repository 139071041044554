import styled from "styled-components";

const ImageMenuWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 3em;
  border-bottom: 1px solid lightgrey;
  min-width: 13em;

  .button {
    min-width: 13em;
    color: ${props => props.theme.TextC};
    align-items: center;
    margin: 0;
    font: 400 11px system-ui;
    padding: 8px 7px 2px;
    button {
      color: inherit;
    }
  }

  .button:hover button {
    transform: scale(1.05);
  }

  .button.selected {
    color: ${props => props.theme.ATextC};
    background-color: ${props => props.theme.HC3};
    box-shadow: rgba(0, 0, 0, 0.1) 0px -2px 5px;
  }

  .button h2 {
    margin: 0 0.5em;
  }

  @media screen and (max-width: 580px) {
    .button {
      min-width: 50vw;
    }
  }
`;

export default ImageMenuWrapper;
