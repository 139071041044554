import styled from "styled-components";

const EditMobileMenu = styled.div`
  display: none;
  @media screen and (max-width: 580px) {
    display: flex;
    justify-content: space-between;
  }
`;

export default EditMobileMenu;
