import React from "react";
import styled from "styled-components";
import Masonry from "react-masonry-css";

// Styled Components
import Card from "../../styled/Card";
import { TitleWrapper, TitleLeft } from "../../styled/Layout";
import { H1 } from "../../styled/Fonts";
import { isConnectActive } from "../../../utils/StripeAPI";

const Clear = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  padding: 2px 2px 2px 10px;
`;

const CardIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-size: 50px;
    color: ${props => props.theme.TextC};
  }
`;

const CardTitle = styled.h1`
  /* font-weight: normal; */
  font-size: 22px;
  text-transform: uppercase;
`;

const Tags = styled.ul`
  list-style: none;
  text-align: center;
`;

const Tag = styled.li`
  display: inline-block;
  background: ${props =>
    props.selected ? props.theme.TextC : props.theme.ATextC};
  color: ${props => (props.selected ? "white" : props.theme.TextC)};
  cursor: ${props => (props.selected ? "default" : "pointer")};
  border-radius: 1.4rem;
  padding: 11px 1.5rem;
  margin: 0.2em 1em;
`;

const Integrations = styled.div`
  height: 100%;
  // Masonry container
  .integrations-grid {
    /* cspell: disable-next-line */
    display: -webkit-box; /* Not needed if autoprefixing */
    /* cspell: disable-next-line */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin: 40px 40px 20px 0; /* gutter size offset */
    width: auto;
  }
  .integrations-grid_column {
    padding-left: 40px; /* gutter size */
    background-clip: padding-box;
  }
  .integrations-grid_column > a {
    background: ${props => props.theme.BGC};
    box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
      0 1px 3px 1px rgba(60, 64, 67, 0.16);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  &hover {
    box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.16),
      0 1px 3px 1px rgba(60, 64, 67, 0.7);
  }
`;

const IntegrationImg = styled.div`
  width: 180px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
  img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
  }
  .material-icons {
    font-size: 46px;
    color: ${props => props.theme.TextC};
  }
`;

const IntegrationName = styled.p`
  color: ${props => props.theme.TextC};
  font-weight: bold;
  text-transform: uppercase;
  margin: 1em 1em 0 1em;
`;

const IntegrationType = styled.p`
  color: ${props => props.theme.TextC};
  font-size: 0.9em;
  margin: 0.4em 1em 1em 0.8em;
`;

const IntegrationActive = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 15px;
  padding: 5px;
  text-transform: uppercase;
  font-size: 0.7em;
  font-weight: bold;
  color: ${props => props.theme.ATextC};
  background-color: ${props => props.theme.HCSuc};
  border-radius: 5px 0px 0px 5px;
`;

const breakpointColumnsObj = {
  default: 4,
  1200: 3,
  500: 2
};

const ConnectIntegration = {
  name: "Boostpoint Connect",
  path: "BoostpointConnect",
  icon: {
    type: "icon",
    code: "chat",
    url: ""
  },
  type: "SMS",
  active: false
};

const IntegrationList = props => {
  const { userProfile = {}, userCompany = {} } = props;
  const [tags, setTags] = React.useState(["Active", "CRM", "SMS"]);
  const [integrations, setIntegrations] = React.useState({
    all: [ConnectIntegration],
    filtered: [ConnectIntegration]
  });
  const [stripeInfo, setStripeInfo] = React.useState({});
  const [state, setState] = React.useState({
    filterEmpty: false,
    tagSelected: "",
    searchValue: ""
  });

  // React.useEffect(() => {
  //   const i = async () => {
  //     try {
  //       const stripeInfoReturn = await getStripeInfo();
  //       setStripeInfo(stripeInfoReturn);
  //       const integrationsReturn = await integrationGetByCompany();
  //       console.log('integrations', integrationsReturn);
  //       const allIntegrations = [
  //         ConnectIntegration,
  //         ...integrationsReturn.filter(
  //           integration => integration.type !== 'FACEBOOK'
  //         )
  //       ];
  //       setIntegrations({
  //         all: allIntegrations,
  //         filtered: allIntegrations
  //       });
  //     } catch (err) {
  //       console.log('error getting integrations', err.message);
  //     }
  //   };
  //   i();
  // }, []);

  React.useEffect(() => {
    if (stripeInfo.subscriptions && integrations.all.length > 0) {
      const connectActive = isConnectActive(stripeInfo.subscriptions);
      const connectIntegration = integrations.all.find(
        i => i.path === "BoostpointConnect"
      );
      setIntegrations(prevIntegrations => ({
        ...prevIntegrations,
        filtered: [
          ...prevIntegrations.filtered.filter(
            i => i.path !== "BoostpointConnect"
          ),
          {
            ...connectIntegration,
            active: connectActive
          }
        ]
      }));
    }
  }, [integrations.all, stripeInfo]);

  return (
    <div>
      <TitleWrapper>
        <TitleLeft>
          <H1>{`Integrations & Add-Ons`}</H1>
        </TitleLeft>
      </TitleWrapper>
      <Card>
        {/* <Search
            searchValue={searchValue}
            onEnter={this.onEnter}
            onSearch={this.search}
            onSearchChange={this.onSearchChange}
            clearSearch={this.clearSearch}
            // showSearch={showSearch}
            showMobileView={false}
            placeholder="Search integrations"
            compact={true}
          />
          <Tags>
            <Tag selected={!tagSelected} onClick={() => this.selectTag('All')}>
              All
            </Tag>
            {tags.map((tag, index) => (
              <Tag
                key={index}
                selected={tagSelected === tag}
                onClick={() => this.selectTag(tag)}
              >
                {tag}
              </Tag>
            ))}
          </Tags> */}
        <Integrations>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className='integrations-grid'
            columnClassName='integrations-grid_column'
          >
            {integrations.filtered.length > 0 &&
              integrations.filtered.map(integration => (
                <a
                  key={integration.path}
                  href={`/integrations/${integration.path}`}
                >
                  {integration.icon && integration.icon.type === "image" ? (
                    <IntegrationImg>
                      <img
                        src={`https://res.cloudinary.com/equipter/image/upload/v1616090520/Boostpoint_Images/${integration.image.url}`}
                        alt={integration.name}
                      />
                    </IntegrationImg>
                  ) : integration.icon && integration.icon.type === "icon" ? (
                    <IntegrationImg>
                      <span className='material-icons'>
                        {integration.icon.code}
                      </span>
                    </IntegrationImg>
                  ) : (
                    <IntegrationImg>
                      <span className='material-icons'>compare_arrows</span>
                    </IntegrationImg>
                  )}
                  {integration.active && (
                    <IntegrationActive>Active</IntegrationActive>
                  )}
                  <IntegrationName>{integration.name}</IntegrationName>
                  <IntegrationType>{integration.type}</IntegrationType>
                </a>
              ))}
          </Masonry>
          {integrations.filtered.length === 0 && <div>Filter empty</div>}
        </Integrations>
      </Card>
    </div>
  );
};

export default IntegrationList;
