import React from 'react';
import Modal from 'react-modal';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(250, 250, 250, .75)',
    zIndex: '1000',
    overflow: 'scroll',
    overflowScrolling: 'touch',
    WebkitOverflowScrolling: 'touch'
  },
  content: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    top: '0',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    maxWidth: '75em',
    padding: '3em 2em 2em',
    fontSize: '14px',
    transform: 'translate(-50%, 0)',
    boxShadow: '0px 0px 9px 0px rgba(0, 0, 6, .1)'
  }
};
const customStylesLeft = {
  overlay: {
    backgroundColor: 'rgba(250, 250, 250, .75)',
    zIndex: '1000',
    overflow: 'scroll',
    overflowScrolling: 'touch',
    WebkitOverflowScrolling: 'touch'
  },
  content: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'left',
    top: '0',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    maxWidth: '75em',
    padding: '3em 2em 2em',
    fontSize: '14px',
    transform: 'translate(-50%, 0)',
    boxShadow: '0px 0px 9px 0px rgba(0, 0, 6, .1)'
  }
};

class UserAgreement extends React.Component {
  state = {
    openTermsOfUse: false,
    openBetaAgreement: false,
    openCDA: false
  };

  // handleCloseModal = () => {
  //   this.props.closeUserAgreement();
  // };

  render() {
    const { openTermsOfUse, openBetaAgreement, openCDA } = this.props;

    return (
      <div>
        {/* Pop up modal for campaign name at submission */}
        <Modal
          isOpen={openTermsOfUse}
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
          onRequestClose={this.props.closeUserAgreement}
          style={customStyles}
          contentLabel="Terms of Use"
          appElement={document.getElementById('root')}
        >
          <button
            onClick={this.props.closeUserAgreement}
            style={{ position: 'absolute', top: '3em', right: '0' }}
          >
            <i
              className="material-icons"
              style={{ color: 'rgb(117, 117, 117)' }}
            >
              cancel
            </i>
          </button>
          <p>
            <b>Boostpoint Software as a Service (“SAAS”) Agreement</b>
          </p>
          <p>
            BY ACCEPTING THIS AGREEMENT OR USING THE BOOSTPOINT STREAMLINED AD
            PLATFORM (AS DEFINED BELOW), YOU (“
            <b>CUSTOMER</b>
            ”) AGREE TO THESE TERMS AND CONDITIONS. IF YOU ARE ENTERING INTO
            THIS AGREEMENT ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU
            REPRESENT THAT YOU HAVE THE AUTHORITY TO BIND SUCH ENTITY AND ITS
            AFFILIATES TO THESE TERMS AND CONDITIONS, IN WHICH CASE THE TERM
            “CUSTOMER” SHALL REFER TO SUCH ENTITY. IF YOU DO NOT HAVE SUCH
            AUTHORITY, OR IF YOU DO NOT AGREE WITH THESE TERMS AND CONDITIONS,
            YOU MUST NOT ACCEPT THIS AGREEMENT AND MAY NOT USE THE BOOSTPOINT
            PLATFORM.
          </p>
          <ol style={{ paddingInlineStart: '20px' }}>
            {' '}
            <li>Definitions.</li>
            <ol style={{ paddingInlineStart: '20px' }} type="a">
              {' '}
              <li>
                “<b>Aggregated Statistics</b>” means data and information
                related to Customer’s use of the Services that is used by
                Boostpoint in an aggregate and anonymized manner, including to
                compile statistical and performance information related to the
                provision and operation of the Services.
              </li>
              <li>
                “<b>Authorized User</b>” means Customer’s employees,
                consultants, contractors, and agents (i) who are authorized by
                Customer to access and use the Services under the rights granted
                to Customer pursuant to this Agreement and (ii) for whom access
                to the Services has been purchased hereunder.
              </li>
              <li>
                “<b>Customer Content</b>” means information, data, and other
                content, in any form or medium, that is submitted, posted, or
                otherwise transmitted by or on behalf of Customer or an
                Authorized User through the Services.
              </li>
              <li>
                “<b>Documentation</b>” means Boostpoint’s user manuals,
                handbooks, and guides relating to the Services provided by
                Boostpoint to Customer either electronically or in hard copy
                form/end user documentation relating to the Services available
                at [URL].
              </li>
              <li>
                “<b>Boostpoint IP</b>” means the Services, the Documentation,
                and any and all intellectual property provided to Customer or
                any Authorized User in connection with the foregoing. For the
                avoidance of doubt, Boostpoint IP includes Aggregated Statistics
                and any information, data, or other content derived from
                Boostpoint’s monitoring of Customer’s access to or use of the
                Services, but does not include Customer Content.
              </li>
              <li>
                “<b>Services</b>” means the Boost Point Streamlined Ad Platform
                as such may be updated or modified from time to time. The
                Services includes the Work Product and the Services Data, as
                hereafter defined.
              </li>
              <li>
                <b>“Work Product”</b> means all writings, images, logos, video,
                audio, html and other code, and all other content specifically
                created through Customer’s use of the Services, including all
                writings, images, logos, video, audio, and all other materials.{' '}
              </li>
              <li>
                <b>“Services Data”</b> means data that is tracked by the
                Services including data related to social media exposure, search
                engine optimization, customer feedback, web site hits, and other
                data and information related to the use and performance of the
                Work Product.{' '}
              </li>
            </ol>{' '}
            <li>Access and Use.</li>
            <ol style={{ paddingInlineStart: '20px' }} type="a">
              {' '}
              <li>
                Provision of Access. Subject to and conditioned on Customer’s
                payment of Fees and compliance with all the terms and conditions
                of this Agreement, Boostpoint hereby grants Customer a
                non-exclusive, non-transferable right to access and use the
                Services during the Term, solely for use by Authorized Users in
                accordance with the terms and conditions herein. Such use is
                limited to Customer’s internal business use.{' '}
              </li>
              <li>
                Documentation License. Subject to the terms and conditions
                contained in this Agreement, Boostpoint hereby grants to
                Customer a non-exclusive, non-sublicenseable, non-transferable
                license to use the Documentation during the Term solely for
                Customer’s internal business purposes in connection with its use
                of the Services.
              </li>
              <li>
                Use Restrictions. Customer shall not use the Services for any
                purposes beyond the scope of the access granted in this
                Agreement. Customer shall not at any time, directly or
                indirectly, and shall not permit any Authorized Users to: (i)
                copy, modify, or create derivative works of the Services or
                Documentation, in whole or in part; (ii) rent, lease, lend,
                sell, license, sublicense, assign, distribute, publish,
                transfer, or otherwise make available the Services or
                Documentation; (iii) reverse engineer, disassemble, decompile,
                decode, adapt, or otherwise attempt to derive or gain access to
                any software component of the Services, in whole or in part;
                (iv) remove any proprietary notices from the Services or
                Documentation; or (v) use the Services or Documentation in any
                manner or for any purpose that infringes, misappropriates, or
                otherwise violates any intellectual property right or other
                right of any person, or that violates any applicable law.
              </li>
              <li>
                Reservation of Rights. Boostpoint reserves all rights not
                expressly granted to Customer in this Agreement. Except for the
                limited rights and licenses expressly granted under this
                Agreement, nothing in this Agreement grants, by implication,
                waiver, estoppel, or otherwise, to Customer or any third party
                any intellectual property rights or other right, title, or
                interest in or to the Boostpoint IP.
              </li>
              <li>
                Suspension. Notwithstanding anything to the contrary in this
                Agreement, Boostpoint may temporarily suspend or revoke
                Customer’s and any Authorized User’s access to any portion or
                all of the Services if: (i) Boostpoint reasonably determines
                that (A) there is a threat or attack on any of the Boostpoint
                IP; (B) Customer’s or any Authorized User’s use of the Services
                disrupts or poses a security risk to the Services or to any
                other party; (C) Customer, or any Authorized End User, is using
                the Services for fraudulent or illegal activities; (D) subject
                to applicable law, Customer has ceased to continue its business
                in the ordinary course, made an assignment for the benefit of
                creditors or similar disposition of its assets, or become the
                subject of any bankruptcy, reorganization, liquidation,
                dissolution, or similar proceeding; or (E) Boostpoint’s
                provision of the Services to Customer or any Authorized End User
                is prohibited by applicable law; (ii) any vendor of Boostpoint
                has suspended or terminated Boostpoint’s access to or use of any
                third-party services or products required to enable Customer to
                access the Services; or (iii) Customer defaults on any payment
                obligation or any other term or condition of this Agreement.{' '}
              </li>
              <li>
                Aggregated Statistics. Notwithstanding anything to the contrary
                in this Agreement, Boostpoint may monitor Customer’s use of the
                Services and collect and compile Aggregated Statistics. As
                between Boostpoint and Customer, all right, title, and interest
                in Aggregated Statistics, and all intellectual property rights
                therein, belong to and are retained solely by Boostpoint.
                Customer acknowledges that Boostpoint may compile Aggregated
                Statistics based on Customer Content input into the Services.
                Customer agrees that Boostpoint may (i) make Aggregated
                Statistics publicly available in compliance with applicable law,
                and (ii) use Aggregated Statistics to the extent and in the
                manner permitted under applicable law; provided that such
                Aggregated Statistics do not identify Customer or Customer’s
                Confidential Information.
              </li>
            </ol>{' '}
            <li>
              Customer Responsibilities. Customer is responsible and liable for
              all uses of the Services and Documentation resulting from access
              provided by Customer, directly or indirectly, whether such access
              or use is permitted by or in violation of this Agreement. Without
              limiting the generality of the foregoing, Customer is responsible
              for all acts and omissions of Authorized Users, and any act or
              omission by an Authorized User that would constitute a breach of
              this Agreement if taken by Customer will be deemed a breach of
              this Agreement by Customer. Customer shall use reasonable efforts
              to make all Authorized Users aware of this Agreement’s provisions
              as applicable to such Authorized User’s use of the Services, and
              shall cause Authorized Users to comply with such provisions.
            </li>
            <li>Fees and Payment.</li>
            <ol style={{ paddingInlineStart: '20px' }} type="a">
              {' '}
              <li>
                Fees. Customer shall pay Boostpoint the fees (“
                <b>Fees</b>
                ”) set forth in Boostpoint’s current fee schedule, as such may
                be modified or updated from time to time, without offset or
                deduction. All Fees shall be paid in US dollars on or before the
                due date set forth in the fee schedule, and shall be made by
                credit card or other approved payment method.
              </li>
              <li>
                Taxes. All Fees and other amounts payable by Customer under this
                Agreement are exclusive of taxes and similar assessments.
                Customer is responsible for all sales, use, and excise taxes,
                and any other similar taxes, duties, and charges of any kind
                imposed by any federal, state, or local governmental or
                regulatory authority on any amounts payable by Customer
                hereunder, other than any taxes imposed on Boostpoint’s income.
              </li>
            </ol>{' '}
            <li>
              Confidential Information. From time to time during the Term of
              this Agreement, either Party may disclose or make available to the
              other Party information about its business affairs, products,
              confidential intellectual property, trade secrets, third-party
              confidential information, and other sensitive or proprietary
              information, whether orally or in written, electronic, or other
              form or media, whether or not marked, designated or otherwise
              identified as “confidential” (collectively, “
              <b>Confidential Information</b>
              ”). Confidential Information does not include information that, at
              the time of disclosure is: (a) in the public domain; (b) known to
              the receiving Party at the time of disclosure; (c) rightfully
              obtained by the receiving Party on a non-confidential basis from a
              third party; or (d) independently developed by the receiving
              Party. The receiving Party shall not disclose the disclosing
              Party’s Confidential Information to any person or entity, except
              to the receiving Party’s employees who have a need to know the
              Confidential Information for the receiving Party to exercise its
              rights or perform its obligations hereunder. Notwithstanding the
              foregoing, each Party may disclose Confidential Information to the
              limited extent required (i) in order to comply with the order of a
              court or other governmental body, or as otherwise necessary to
              comply with applicable law, provided that the Party making the
              disclosure pursuant to the order shall first have given written
              notice to the other Party and made a reasonable effort to obtain a
              protective order; or (ii) to establish a Party’s rights under this
              Agreement, including to make required court filings. On the
              expiration or termination of the Agreement, the receiving Party
              shall promptly return to the disclosing Party all copies, whether
              in written, electronic, or other form or media, of the disclosing
              Party’s Confidential Information, or destroy all such copies and
              certify in writing to the disclosing Party that such Confidential
              Information has been destroyed. Each Party’s obligations of
              non-disclosure with regard to Confidential Information will expire
              five years from the date the Confidential Information is first
              disclosed to the receiving Party; provided, however, with respect
              to any Confidential Information that constitutes a trade secret
              (as determined under applicable law), such obligations of
              non-disclosure will survive the termination or expiration of this
              Agreement for as long as such Confidential Information remains
              subject to trade secret protection under applicable law.
            </li>
            <li>Intellectual Property Ownership.</li>
            <ol style={{ paddingInlineStart: '20px' }} type="a">
              {' '}
              <li>
                Boostpoint IP. Customer acknowledges that, as between Customer
                and Boostpoint, Boostpoint owns all right, title, and interest,
                including all intellectual property rights, in and to the
                Boostpoint IP.
              </li>
              <li>
                Customer Content. Boostpoint acknowledges that, as between
                Boostpoint and Customer, Customer owns all right, title, and
                interest, including all intellectual property rights, in and to
                the Customer Content. Customer hereby grants to Boostpoint a
                non-exclusive, royalty-free, worldwide license to reproduce,
                distribute, and otherwise use and display the Customer Content
                and perform all acts with respect to the Customer Content as may
                be necessary for Boostpoint to provide the Services to Customer.
              </li>
            </ol>{' '}
            <li>
              Privacy Policy. Customer’s use of the Services shall be subject to
              and governed by Boostpoint’s Privacy Policy, as amended and
              supplemented to date. A copy of Boostpoint’s current Privacy
              Policy can be accessed here [Privacy Policy Link].
            </li>
            <li>Limited Warranty and Warranty Disclaimer.</li>
            <ol style={{ paddingInlineStart: '20px' }} type="a">
              {' '}
              <li>
                Boostpoint warrants that the Services will conform in all
                material respects to the specifications set forth in the
                Documentation when the Services are accessed and used in
                accordance with the Documentation. Boostpoint does not make any
                representations or guarantees regarding uptime or availability
                of the Services.{' '}
              </li>
              <li>
                EXCEPT FOR THE LIMITED WARRANTY SET FORTH ABOVE, THE SERVICES
                ARE PROVIDED “AS IS” AND BOOSTPOINT HEREBY DISCLAIMS ALL
                WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE.
                BOOSTPOINT SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND
                NON-INFRINGEMENT, AND ALL WARRANTIES ARISING FROM COURSE OF
                DEALING, USAGE, OR TRADE PRACTICE. BOOSTPOINT MAKES NO WARRANTY
                OF ANY KIND THAT THE BOOSTPOINT IP, OR ANY PRODUCTS OR RESULTS
                OF THE USE THEREOF, WILL MEET CUSTOMER’S OR ANY OTHER PERSON’S
                REQUIREMENTS, OPERATE WITHOUT INTERRUPTION, ACHIEVE ANY INTENDED
                RESULT, BE COMPATIBLE OR WORK WITH ANY SOFTWARE, SYSTEM OR OTHER
                SERVICES, OR BE SECURE, ACCURATE, COMPLETE, FREE OF HARMFUL
                CODE, OR ERROR FREE.{' '}
              </li>
            </ol>{' '}
            <li>Indemnification.</li>
            <ol style={{ paddingInlineStart: '20px' }} type="a">
              {' '}
              <li>Boostpoint Indemnification.</li>
              <ol style={{ paddingInlineStart: '20px' }} type="i">
                {' '}
                <li>
                  Boostpoint shall indemnify, defend, and hold harmless Customer
                  from and against any and all losses, damages, liabilities,
                  costs (including reasonable attorneys’ fees) incurred by
                  Customer resulting from any third-party claim, suit, action,
                  or proceeding that the Services, or any use of the Services in
                  accordance with this Agreement, infringes or misappropriates
                  such third party’s intellectual property rights, provided that
                  Customer promptly notifies Boostpoint in writing of the claim,
                  cooperates with Boostpoint, and allows Boostpoint sole
                  authority to control the defense and settlement of such claim.
                </li>
                <li>
                  If such a claim is made or threatened, Customer agrees to
                  permit Boostpoint, at Boostpoint’s sole discretion, to (A)
                  modify or replace the Services, or component or part thereof,
                  to make it non-infringing, or (B) obtain the right for
                  Customer to continue use. If Boostpoint determines that
                  neither alternative is reasonably available, Boostpoint may
                  terminate this Agreement, in its entirety or with respect to
                  the affected component or part, effective immediately on
                  written notice to Customer and with no further liability to
                  Customer.
                </li>
                <li>
                  Boostpoint’s obligation of indemnification under this section
                  will not apply to the extent that the alleged infringement
                  arises from: (A) use of the Services in combination with data,
                  software, hardware, equipment, or technology not provided by
                  Boostpoint or authorized by Boostpoint in writing; (B)
                  modifications to the Services not made by Boostpoint; or (C)
                  Customer Content.
                </li>
              </ol>{' '}
              <li>
                Customer Indemnification. Customer shall indemnify, hold
                harmless, and, at Boostpoint’s option, defend Boostpoint from
                and against any Losses resulting from any Third-Party Claim that
                the Customer Content, or any use of the Customer Content in
                accordance with this Agreement, infringes or misappropriates
                such third party’s intellectual property rights and any
                Third-Party Claims based on Customer’s or any Authorized User’s
                (i) negligence or willful misconduct; (ii) use of the Services
                in a manner not authorized by this Agreement; (iii) use of the
                Services in combination with data, software, hardware, equipment
                or technology not provided by Boostpoint or authorized by
                Boostpoint in writing; or (iv) modifications to the Services not
                made by Boostpoint, provided that Customer may not settle any
                Third-Party Claim against Boostpoint unless Boostpoint consents
                to such settlement, and further provided that Boostpoint will
                have the right, at its option, to defend itself against any such
                Third-Party Claim or to participate in the defense thereof by
                counsel of its own choice.
              </li>
              <li>
                Sole Remedy. THIS SECTION SETS FORTH CUSTOMER’S SOLE REMEDIES
                AND BOOSTPOINT’S SOLE LIABILITY AND OBLIGATION FOR ANY ACTUAL,
                THREATENED, OR ALLEGED CLAIMS THAT THE SERVICES INFRINGE,
                MISAPPROPRIATE, OR OTHERWISE VIOLATE ANY INTELLECTUAL PROPERTY
                RIGHTS OF ANY THIRD PARTY. IN NO EVENT WILL BOOSTPOINT’S
                LIABILITY UNDER THIS SECTION 9 EXCEED $[AMOUNT].
              </li>
            </ol>{' '}
            <li>
              Limitations of Liability. IN NO EVENT WILL BOOSTPOINT BE LIABLE
              UNDER OR IN CONNECTION WITH THIS AGREEMENT UNDER ANY LEGAL OR
              EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING
              NEGLIGENCE), STRICT LIABILITY, AND OTHERWISE, FOR ANY: (a)
              CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, ENHANCED,
              OR PUNITIVE DAMAGES; (b) INCREASED COSTS, DIMINUTION IN VALUE OR
              LOST BUSINESS, PRODUCTION, REVENUES, OR PROFITS; (c) LOSS OF
              GOODWILL OR REPUTATION; (d) USE, INABILITY TO USE, LOSS,
              INTERRUPTION, DELAY OR RECOVERY OF ANY DATA, OR BREACH OF DATA OR
              SYSTEM SECURITY; OR (e) COST OF REPLACEMENT GOODS OR SERVICES, IN
              EACH CASE REGARDLESS OF WHETHER BOOSTPOINT WAS ADVISED OF THE
              POSSIBILITY OF SUCH LOSSES OR DAMAGES OR SUCH LOSSES OR DAMAGES
              WERE OTHERWISE FORESEEABLE. IN NO EVENT WILL BOOSTPOINT’S
              AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO THIS AGREEMENT
              UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT,
              TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, AND OTHERWISE
              EXCEED THE TOTAL AMOUNTS PAID TO BOOSTPOINT UNDER THIS AGREEMENT
              IN THE ONE MONTH PERIOD PRECEDING THE EVENT GIVING RISE TO THE
              CLAIM.{' '}
            </li>
            <li>Miscellaneous.</li>
            <ol style={{ paddingInlineStart: '20px' }} type="a">
              {' '}
              <li>
                Entire Agreement. This Agreement, as modified from time to time,
                constitutes the sole and entire agreement of the Parties with
                respect to the subject matter of this Agreement and supersedes
                all prior and contemporaneous understandings, agreements, and
                representations and warranties, both written and oral, with
                respect to such subject matter.{' '}
              </li>
              <li>
                Amendment and Modification; Waiver. No amendment to or
                modification of this Agreement is effective unless it is in
                writing. No waiver by any Party of any of the provisions hereof
                will be effective unless explicitly set forth in writing and
                signed by the Party so waiving. Except as otherwise set forth in
                this Agreement, (i) no failure to exercise, or delay in
                exercising, any rights, remedy, power, or privilege arising from
                this Agreement will operate or be construed as a waiver thereof
                and (ii) no single or partial exercise of any right, remedy,
                power, or privilege hereunder will preclude any other or further
                exercise thereof or the exercise of any other right, remedy,
                power, or privilege.
              </li>
              <li>
                Severability. If any provision of this Agreement is invalid,
                illegal, or unenforceable in any jurisdiction, such invalidity,
                illegality, or unenforceability will not affect any other term
                or provision of this Agreement or invalidate or render
                unenforceable such term or provision in any other jurisdiction.
                Upon such determination that any term or other provision is
                invalid, illegal, or unenforceable, the Parties shall negotiate
                in good faith to modify this Agreement so as to effect their
                original intent as closely as possible in a mutually acceptable
                manner in order that the transactions contemplated hereby be
                consummated as originally contemplated to the greatest extent
                possible.
              </li>
              <li>
                Governing Law; Submission to Jurisdiction. This Agreement is
                governed by and construed in accordance with the internal laws
                of the State of [STATE] without giving effect to any choice or
                conflict of law provision or rule that would require or permit
                the application of the laws of any jurisdiction other than those
                of the State of [STATE]. Any legal suit, action, or proceeding
                arising out of [or related to] this Agreement or the licenses
                granted hereunder [will/may] be instituted [exclusively] in the
                federal courts of the United States or the courts of the State
                of [STATE] in each case located in the city of [CITY] and County
                of [COUNTY], and each Party irrevocably submits to the
                [exclusive] jurisdiction of such courts in any such suit,
                action, or proceeding.
              </li>
              <li>
                Assignment. Customer may not assign any of its rights or
                delegate any of its obligations hereunder, in each case whether
                voluntarily, involuntarily, by operation of law or otherwise,
                without the prior written consent of Boostpoint [, which consent
                shall not be unreasonably withheld, conditioned, or delayed].
                Any purported assignment or delegation in violation of this
                Section will be null and void. No assignment or delegation will
                relieve the assigning or delegating Party of any of its
                obligations hereunder. This Agreement is binding upon and inures
                to the benefit of the Parties and their respective permitted
                successors and assigns.
              </li>
              <li>
                Export Regulation. The Services utilize software and technology
                that may be subject to US export control laws, including the US
                Export Administration Act and its associated regulations.
                Customer shall not, directly or indirectly, export, re-export,
                or release the Services or the underlying software or technology
                to, or make the Services or the underlying software or
                technology accessible from, any jurisdiction or country to which
                export, re-export, or release is prohibited by law, rule, or
                regulation. Customer shall comply with all applicable federal
                laws, regulations, and rules, and complete all required
                undertakings (including obtaining any necessary export license
                or other governmental approval), prior to exporting,
                re-exporting, releasing, or otherwise making the Services or the
                underlying software or technology available outside the US.
              </li>
              <li>
                US Government Rights. Each of the Documentation and the software
                components that constitute the Services is a “commercial item”
                as that term is defined at 48 C.F.R. § 2.101, consisting of
                “commercial computer software” and “commercial computer software
                documentation” as such terms are used in 48 C.F.R. § 12.212.
                Accordingly, if Customer is an agency of the US Government or
                any contractor therefor, Customer only receives those rights
                with respect to the Services and Documentation as are granted to
                all other end users, in accordance with (a) 48 C.F.R. § 227.7201
                through 48 C.F.R. § 227.7204, with respect to the Department of
                Defense and their contractors, or (b) 48 C.F.R. § 12.212, with
                respect to all other US Government users and their contractors.
              </li>
              <li>
                Equitable Relief. Each Party acknowledges and agrees that a
                breach or threatened breach by such Party of any of its
                obligations under Section 6 or, in the case of Customer, Section
                2(c), would cause the other Party irreparable harm for which
                monetary damages would not be an adequate remedy and agrees
                that, in the event of such breach or threatened breach, the
                other Party will be entitled to equitable relief, including a
                restraining order, an injunction, specific performance and any
                other relief that may be available from any court, without any
                requirement to post a bond or other security, or to prove actual
                damages or that monetary damages are not an adequate remedy.
                Such remedies are not exclusive and are in addition to all other
                remedies that may be available at law, in equity or otherwise.
              </li>
            </ol>{' '}
          </ol>
          <p>
            <b>EXHIBIT A</b>
          </p>
          <p>
            Capitalized terms used but not defined in this Exhibit A have the
            meaning given to those terms in the Agreement.
          </p>
          <p>A. DESCRIPTION OF SERVICES: [DESCRIPTION]</p>
          <p>
            B. FEES: [FEES AND CORRESPONDING SUBSCRIPTION PERIOD LENGTH, PAYMENT
            REQUIREMENTS, INCLUDING INVOICING AND ANY RENEWAL FEE NOTIFICATION
            PROCESS OR RENEWAL FEE INCREASE LIMITATIONS.]
          </p>
          <p>C. [AUTHORIZED USERS: [NUMBER]]</p>
          <p>
            D. [THIRD-PARTY PRODUCTS: [DESCRIPTION, FLOW THROUGH PROVISIONS, URL
            LINKS TO LICENSES.]]
          </p>
        </Modal>
        <Modal
          isOpen={openBetaAgreement}
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
          onRequestClose={this.props.closeUserAgreement}
          style={customStylesLeft}
          contentLabel="Beta Agreement"
          appElement={document.getElementById('root')}
        >
          <button
            onClick={this.props.closeUserAgreement}
            style={{ position: 'absolute', top: '3em', right: '0' }}
          >
            <i
              className="material-icons"
              style={{ color: 'rgb(117, 117, 117)' }}
            >
              cancel
            </i>
          </button>
          <p style={{ alignSelf: 'center' }}>
            <b>Boostpoint Software as a Service (“SaaS”) Beta Agreement</b>
          </p>

          <p>
            PLEASE READ THIS AGREEMENT CAREFULLY BEFORE USING THE SERVICES. BY
            ACCESSING OR USING ALL OR ANY PORTION OF THE SERVICES OR BY CLICKING
            THE "I ACCEPT" BUTTON AT THE END OF THE AGREEMENT, YOU ACKNOWLEDGE
            YOUR ACCEPTANCE OF THE AGREEMENT AND REPRESENT THAT YOU ARE
            AUTHORIZED TO ENTER INTO THE AGREEMENT ON BEHALF OF CUSTOMER. YOU
            AGREE THAT THIS AGREEMENT IS ENFORCEABLE LIKE ANY WRITTEN NEGOTIATED
            AGREEMENT SIGNED BY YOU. IF YOU DO NOT AGREE TO THESE TERMS AND
            CONDITIONS, PROVIDER DOES NOT GRANT YOU THE RIGHT TO USE THE
            SERVICES AND YOU SHOULD CLICK ON THE "I DO NOT ACCEPT" BUTTON AND
            CANCEL OPERATION OF THE SERVICES.
          </p>

          <p>
            THE TERMS AND CONDITIONS OF THIS AGREEMENT APPLY TO ANY AND ALL USE
            OF THE SERVICES BY YOU AND YOU AGREE TO BE BOUND BY THESE TERMS AND
            CONDITIONS REGARDLESS OF THE TYPE OF USE OF THE SERVICES BY YOU.
          </p>

          <p>
            This Boostpoint SaaS Beta Agreement, as amended from time to time,
            (the "Agreement") is between you or the entity you represent
            ("Customer") and Boostpoint, LLC with its principal place of
            business at 49 Eagle Dr. , Leola, PA 17540 ("Provider").
          </p>

          <p>
            1. <b>Definitions</b>
          </p>

          <p>
            As used in this Agreement, the following terms shall have the
            following meaning:
          </p>

          <p>
            1.1 <b>"Beta Test"</b> means the testing and evaluation of the
            Services by Customer during the Term.
          </p>

          <p>
            1.2 <b>"Content"</b> means the audio and visual information,
            documents, software, products, and services made available to
            Customer in the course of using the Services.
          </p>

          <p>
            1.3 <b>"Customer Data"</b> means any data, information or material
            provided, input or submitted by Customer or its Users to the
            Services in the course of using the Services.
          </p>

          <p>
            1.4 <b>"Effective Date"</b> means the date upon which the Customer
            clicks an "I Accept" button at the end of the Agreement or, if
            earlier, when Customer accesses or uses any of the Services.
          </p>

          <p>
            1.5 <b>"Intellectual Property Rights"</b> means any unpatented
            inventions, patent applications, patents, design rights, copyrights,
            trademarks, service marks, trade names, domain name rights, mask
            work rights, know-how and other trade secret rights, and all other
            intellectual property rights, derivatives thereof, and forms of
            protection of a similar nature anywhere in the world.
          </p>

          <p>
            1.6 <b>"Services" </b>
            means Provider's online tracking software services, developed by
            Provider, accessible via Boostpoint or ancillary online or offline
            products and services provided to Customer by Provider, to which
            Customer is being granted access under this Agreement, including the
            Provider Technology and the Content.
          </p>

          <p>
            1.7 <b>"Third Party Vendor(s)"</b> means any Third Party Vendor of
            products or services to Customer.
          </p>

          <p>
            1.8 <b>"Third Party Vendor Data"</b> means any data, information or
            material provided or submitted to Provider by a Third Party Vendor.
          </p>

          <p>
            1.9 <b>"User(s)"</b> means Customer employees, representatives,
            consultants, contractors, or agents who are authorized to use the
            Services and have been supplied user identifications and passwords
            by Customer (or by Provider at Customer's request).
          </p>

          <p>
            1.10 <b>"Provider Technology"</b> means all of Provider's
            proprietary technology (including software, hardware, products,
            processes, algorithms, user interfaces, know-how, techniques,
            designs, and other tangible or intangible technical material or
            information) made available to Customer by Provider in providing the
            Services.
          </p>

          <p>
            2. <b>Use of the Services</b>
          </p>

          <p>
            2.1 Subject to the terms and conditions of this Agreement, Provider
            hereby grants Customer a nonexclusive, nontransferable,
            nonsublicenseable, revocable, limited right to access and use the
            Services, solely as part of, and in connection with, the Beta Test.
            All rights not expressly granted to Customer herein are reserved by
            Provider and its licensors.
          </p>

          <p>
            2.2 Customer may not (i) license, sublicense, sell, resell,
            transfer, assign, distribute, or otherwise commercially exploit or
            make available to any third party the Services or the Content in any
            way; (ii) modify, alter, tamper with, repair or make derivative
            works based upon the Services or the Content; (iii) create Internet
            "links" to the Services or "frame" or "mirror" any Content on any
            other server or wireless or Internet-based device; (iv) reverse
            engineer, disassemble, decompile, translate or reproduce the
            Services; or (v) access the Services in order to (a) build a
            competitive product or service, (b) build a product using similar
            ideas, features, functions or graphics of the Services, or (c) copy
            any ideas, features, functions or graphics of the Services.
          </p>

          <p>
            2.3 Customer may use the Services only as part of, and in connection
            with, the Beta Test and shall not: (i) send spam or otherwise
            duplicative or unsolicited messages in violation of applicable laws;
            (ii) send or store infringing, obscene, threatening, libelous, or
            otherwise unlawful or tortious material, including material harmful
            to children or violative of third party privacy rights; (iii) send
            or store material containing software viruses, worms, Trojan horses,
            or other harmful computer code, files, scripts, agents, or programs;
            (iv) interfere with or disrupt the integrity or performance of the
            Services or the data contained therein; or (v) attempt to gain
            unauthorized access to the Services or its related systems or
            networks.
          </p>

          <p>
            2.4 Provider will not provide any support or services to Customer
            pursuant to this Agreement.
          </p>

          <p>
            3. <b>Customer Responsibilities</b>
          </p>

          <p>
            3.1 Customer shall (i) provide Provider with information as to any
            errors or defects found by Customer in the Services; (ii) inform
            Provider of any suggested changes, modifications or improvements to
            the Services; and (iii) provide Provider with all information and
            documentation necessary for Provider to evaluate the results of Beta
            Test (collectively, the "Beta Test Data").
          </p>

          <p>
            3.2 Customer is responsible for all activity occurring under
            Customer's User accounts and shall abide by all applicable local,
            state, national, and foreign, laws, treaties and regulations in
            connection with Customer's use of the Services, including those
            related to data privacy, data security and the transmission of
            technical or personal data.
          </p>

          <p>
            3.3 Customer is solely responsible for the development, content,
            operation, maintenance, and use of Customer Data, including but not
            limited to: (i) the technical operation of Customer Data; (ii)
            compliance of Customer Data with the law; (iii) any claims relating
            to Customer Data; and (iv) properly handling and processing notices
            sent to Customer (or any of Customer's affiliates) by any person
            claiming that Customer Data violate such person's rights.
          </p>

          <p>
            3.4 Customer is responsible for properly using the Services and
            taking steps to maintain appropriate security, protection and backup
            of Customer Data, which may include the use of encryption technology
            to protect Customer Data from unauthorized access and routine
            archiving Customer Data. Customer shall: (i) safeguard all passwords
            relating to the Services, keep all such passwords strictly
            confidential and provide such passwords only to authorized employees
            or personnel who need to know such information for the purpose of
            using the Services; (ii) notify Provider immediately of any
            unauthorized use of any password or account or any other known or
            suspected breach of security; (iii) report to Provider immediately
            and use reasonable efforts to stop immediately any copying or
            distribution of Content that is known or suspected by Customer or
            Customer Users; and (iv) not impersonate another Provider user or
            provide false identity information to gain access to or use the
            Services.
          </p>

          <p>
            3.5 Any breach of Customer's obligations under this Agreement will
            be deemed a material breach.
          </p>

          <p>
            4. <b>Account Information and Data</b>
          </p>

          <p>
            Provider does not own any Customer Data or Third Party Vendor Data.
            Customer consents to Provider's use of Customer Data to provide the
            Services to Customer and Provider's use of aggregated Customer Data
            for marketing and data analysis purposes. Customer, not Provider,
            shall have sole responsibility for the accuracy, quality, integrity,
            legality, reliability, appropriateness, and intellectual property
            ownership or right to use of all Customer Data or Third Party Vendor
            Data, and Provider shall not be responsible or liable for the
            deletion, correction, destruction, damage, loss or failure to store
            any Customer Data or Third Party Vendor Data. To the extent Customer
            participates in Provider's Boostpoint platform (or any similar
            successor program), Customer shall continue to be notified under
            such program of any expiration dates of products purchased by
            Customer from Provider. Provider may disclose Customer Data and
            Third Party Vendor Data to provide the Services to Customer or to
            comply with any request of a governmental or regulatory body
            (including subpoenas or court orders), and Provider may disclose
            aggregated Customer Data and Third Party Vendor Data for marketing
            and data analysis purposes. Upon termination, Customer's right to
            access or use Customer Data through the Services immediately ceases,
            and Provider shall have no obligation to maintain or forward any
            Customer Data and may delete such Customer Data immediately upon
            termination. Any post-termination data retrieval assistance from
            Provider is subject to agreement by Provider and Customer.
          </p>

          <p>
            <b>5. Intellectual Property Ownership</b>
          </p>

          <p>
            This Agreement is not a sale and does not convey to Customer any
            rights of ownership in or related to the Content, the Services, the
            Provider Technology or the Intellectual Property Rights owned by
            Provider. Provider's name, Provider's logo, and the product names
            associated with the Services are trademarks of Provider or third
            parties (collectively, the "Provider Trademarks"), Customer agrees
            not to use the Provider Trademarks for any purpose or to take any
            actions which are harmful to or inconsistent with Provider's rights
            in and to the Provider Trademarks. Customer acknowledges and agrees
            that Provider (and its licensors, where applicable) shall
            exclusively own and retain all right, title, and interest, including
            but not limited to all Intellectual Property Rights, in and to the
            Provider Technology, the Content and the Services and any Beta Test
            Data and other suggestions, ideas, enhancement requests, feedback,
            recommendations, and other information provided by Customer or any
            other party relating to the Services. To the extent any Intellectual
            Property Rights in or to any of the foregoing vest in Customer,
            Customer does hereby assign all right, title and interest therein to
            Provider, irrevocably and for valid consideration which is hereby
            acknowledged and received.
          </p>

          <p>
            <b>6. Third Party Interactions</b>
          </p>

          <p>
            6.1 During use of the Services, Customer may enter into
            correspondence with, purchase goods and/or services from, or
            participate in promotions of Third Party Vendors showing their goods
            and/or services through the Services. Any such activity, and any
            terms, conditions, warranties, or representations associated with
            such activity, is solely between Customer and the applicable Third
            Party Vendor. Provider shall have no liability, obligation, or
            responsibility for any such correspondence, purchase, or promotion
            between Customer and any such Third Party Vendor. Customer shall
            have sole responsibility for the accuracy, quality, integrity,
            legality, reliability, appropriateness, and intellectual property
            ownership or right to use of all Third Party Vendor Data, and
            Provider shall not be responsible or liable for the deletion,
            correction, destruction, damage, loss or failure to store any Third
            Party Vendor Data. Provider does not endorse any sites on the
            Internet that are linked through the Services. Provider provides
            these links to Customer only as a matter of convenience, and in no
            event shall Provider or its licensors be responsible for any
            content, products, or other materials on or available from such
            sites. Provider provides the Services to Customer pursuant to the
            terms and conditions of this Agreement. Customer recognizes,
            however, that certain Third Party Vendors may provide ancillary
            software, hardware, or services that may require Customer's
            agreement to additional or different license or other terms prior to
            Customer's use of or access to such software, hardware or services.
          </p>

          <p>
            6.2 A portion of the Services may be provided by a third party.
            Provider will not be responsible for the actions or omissions of any
            third party.
          </p>

          <p>
            <b>7. Term; Termination</b>
          </p>

          <p>
            7.1 This Agreement commences on the Effective Date and shall
            continue for a period of ninety (90) days (the "Term"), unless
            otherwise terminated earlier as provided herein.
          </p>

          <p>
            7.2 This Agreement may be terminated at any time by Provider.
            Provider, in its sole discretion, may terminate Customer's password,
            account or use of the Services at any time. Customer agrees and
            acknowledges that Provider has no obligation to retain the Customer
            Data, and may delete such Customer Data immediately upon
            termination. Any post-termination data retrieval assistance from
            Provider is subject to mutual agreement between Provider and
            Customer; provided, however, the parties are in no way obligated to
            come to such an agreement.
          </p>

          <p>
            <b>8. Confidentiality</b>
          </p>

          <p>
            The Services and the Content are confidential and proprietary to
            Provider and its licensor's. Customer agrees not to disclose any
            part of the Services, the Content or any information relating to the
            Services (including without limitation the results of use or the
            Beta Test Data) to any third party without Provider's prior written
            permission. Customer acknowledges and agrees that unauthorized use
            or disclosure of such information would cause irreparable harm and
            significant injury to Provider that would be difficult to ascertain;
            accordingly Customer agrees that Provider shall have the right to
            obtain injunctive or other equitable relief to enforce obligations
            under this Agreement without limiting any other rights or remedies.
          </p>

          <p>
            <b>9. Representations and Warranties</b>
          </p>

          <p>
            Each party represents and warrants that it has the legal power and
            authority to enter into this Agreement.
          </p>

          <p>
            <b>10. Indemnification</b>
          </p>

          <p>
            Customer will defend, indemnify, and hold harmless Provider,
            Provider's affiliates and licensors, and each of their respective
            employees, officers, directors, and representatives from and against
            any claims, damages, losses, liabilities, costs, and expenses
            (including reasonable attorneys" fees) arising out of or relating to
            any third party claim concerning: (a) Customer's or any User's use
            of the Services; (b) breach of this Agreement or violation of
            applicable law by Customer or any User; or (c) allegations that the
            use of the Customer Data or Third Party Vendor Data infringes the
            rights of, or has caused harm to, a third party. If Provider or
            Provider's affiliates are obligated to respond to a third party
            subpoena or other compulsory legal order or process described above,
            Customer will also reimburse Provider for reasonable attorneys"
            fees, as well as our employees" and contractors" time and materials
            spent responding to the third party subpoena or other compulsory
            legal order or process.
          </p>

          <p>
            <b>11. Disclaimer of Warranties</b>
          </p>

          <p>
            11.1 PROVIDER DOES NOT SUPPORT THE SERVICES OR THE CONTENT. PROVIDER
            AND ITS LICENSORS MAKE NO REPRESENTATION, WARRANTY, OR GUARANTY AS
            TO THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY, AVAILABILITY,
            ACCURACY, OR COMPLETENESS OF THE SERVICES OR ANY CONTENT. THE
            SERVICES AND ALL CONTENT ARE PROVIDED TO CUSTOMER STRICTLY ON AN "AS
            IS" BASIS. ALL CONDITIONS, REPRESENTATIONS AND WARRANTIES, WHETHER
            EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, WITHOUT
            LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, AND NON-INFRINGEMENT OF THIRD PARTY RIGHTS, ARE
            HEREBY DISCLAIMED TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW
            BY PROVIDER AND ITS LICENSORS.
          </p>

          <p>
            11.2 LICENSEE ACKNOWLEDGES AND AGREES THAT: (A) THE SERVICES ARE NOT
            IN FINAL FORM, HAVE NOT BEEN RELEASED FOR SALE BY THE COMPANY AND
            MAY CONTAIN DEFECTS, ERRORS, DESIGN FLAWS OR OTHER PROBLEMS WHICH
            CANNOT OR WILL NOT BE CORRECTED; (B) THE USE OF THE SERVICES MAY NOT
            BE SECURE, TIMELY, UNINTERRUPTED, OR ERROR-FREE OR OPERATE IN
            COMBINATION WITH ANY OTHER HARDWARE, SOFTWARE, SYSTEM, OR DATA; (C)
            ANY STORED DATA MAY NOT BE ACCURATE OR RELIABLE; (D) THE SERVICES OR
            THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER
            MATERIAL PURCHASED OR OBTAINED BY CUSTOMER THROUGH THE SERVICES MAY
            NOT MEET CUSTOMER'S REQUIREMENTS OR EXPECTATIONS; (E) THE SERVICES
            OR THE SERVER(S) THAT MAKE THE SERVICES AVAILABLE MAY NOT BE FREE OF
            VIRUSES OR OTHER HARMFUL COMPONENTS; OR (F) THE SERVICES MAY BE
            SUBJECT TO LIMITATIONS, DELAYS, AND OTHER PROBLEMS INHERENT IN THE
            USE OF THE INTERNET AND ELECTRONIC COMMUNICATIONS.
          </p>

          <p>
            <b>12. Limitation of Liability</b>
          </p>

          <p>
            IN NO EVENT SHALL PROVIDER OR PROVIDER'S LICENSORS BE LIABLE FOR ANY
            DAMAGES WHATSOEVER, INCLUDING BUT NOT LIMITED TO ANY DIRECT,
            INDIRECT, PUNITIVE, SPECIAL, EXEMPLARY, INCIDENTAL, CONSEQUENTIAL,
            OR OTHER DAMAGES OF ANY TYPE OR KIND ARISING OUT OF OR IN ANY WAY
            CONNECTED WITH (A) THIS AGREEMENT; (B) THE SERVICES, INCLUDING BUT
            NOT LIMITED TO THE USE OR INABILITY TO USE THE SERVICES; (C) THE
            CONTENT; OR (D), ANY INTERRUPTION, INACCURACY, ERROR, OR OMISSION,
            REGARDLESS OF CAUSE IN THE SERVICES OR CONTENT; EVEN IF PROVIDER OR
            PROVIDER'S LICENSORS HAVE BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY
            OF SUCH DAMAGES.
          </p>

          <p>
            <b>13. Territory/No Export</b>
          </p>

          <p>
            13.1 Customer acknowledges and agrees that the software and Services
            shall not be used, and none of the underlying information may be
            transferred or otherwise exported outside the United States (the
            "Territory"). By using the Services, Customer represents and
            warrants that Customer's use of the Services is located solely in
            the Territory.
          </p>

          <p>
            13.2 Provider and its licensors make no representation that the
            Services are appropriate or available for use in outside the
            Territory. Any use of the Services outside of the Territory or
            diversion of the Services, Content or Customer Data contrary to
            United States law is prohibited. Customer is solely responsible for
            compliance with all applicable laws, including without limitation
            export and import regulations of other countries.
          </p>

          <p>
            <b>14. Modification to Terms</b>
          </p>

          <p>
            Provider reserves the right to modify the terms and conditions of
            this Agreement or its policies relating to the Services at any time,
            effective upon posting of an updated version of this Agreement on
            the Services. Customer is responsible for regularly reviewing this
            Agreement. Continued use of the Services after any such changes
            shall constitute Customer's consent to such changes.
          </p>

          <p>
            <b>15. Assignment</b>
          </p>

          <p>
            This Agreement may not be assigned by Customer without the prior
            written approval of Provider.
          </p>

          <p>
            <b>16. General</b>
          </p>

          <p>
            16.1 This Agreement shall be governed by the laws of the
            Commonwealth of Pennsylvania, without reference to conflict of laws
            principles. The parties irrevocably submit to the jurisdiction of
            the federal courts sitting in the Eastern and Middle Districts of
            Pennsylvania or any Pennsylvania state courts in the counties of
            Lancaster and York, for the purpose of any suit, action or
            proceeding arising out of this Agreement. The parties hereby
            irrevocably waive the defense of an inconvenient forum to the
            maintenance of any such suit, action or proceeding.
          </p>

          <p>
            16.2 Provider may provide Customer with notice, including notice
            regarding changes to the terms of this Agreement, by email, by
            regular mail or by postings on the Services. Notices provided by
            Provider by posting on the Services will be effective upon posting,
            and notices sent by email or regular mail will be effective when
            sent by Provider. Customer may give notice to Provider at any time
            by personal delivery, overnight courier or registered or certified
            mail delivered to Provider at the following address: Attention
            ______________, 49 EAGLE DRIVE LEOLA Lancaster PA 17540. Notices
            provided by Customer shall be deemed effective when received by
            Provider.
          </p>

          <p>
            16.3 No text or information set forth on any other document shall
            add to or vary the terms and conditions of this Agreement. If any
            provision of this Agreement is held by a court of competent
            jurisdiction to be invalid or unenforceable, then such provision(s)
            shall be construed, as nearly as possible, to reflect the intentions
            of the invalid or unenforceable provision(s), with all other
            provisions remaining in full force and effect. No joint venture,
            partnership, employment, or agency relationship exists between
            Customer and Provider as a result of this Agreement or the use of
            the Services. The failure of Provider to enforce any right or
            provision in this Agreement shall not constitute a waiver of such
            right or provision unless acknowledged and agreed to by Provider in
            writing. This Agreement comprises the entire agreement between
            Customer and Provider and supersedes all prior or contemporaneous
            negotiations, discussions or agreements, whether written or oral,
            between the parties regarding the subject matter contained herein.
          </p>
        </Modal>
        <Modal
          isOpen={openCDA}
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
          onRequestClose={this.props.closeUserAgreement}
          style={customStyles}
          contentLabel="Terms of Use"
          appElement={document.getElementById('root')}
        >
          <button
            onClick={this.props.closeUserAgreement}
            style={{ position: 'absolute', top: '3em', right: '0' }}
          >
            <i
              className="material-icons"
              style={{ color: 'rgb(117, 117, 117)' }}
            >
              cancel
            </i>
          </button>
          <p>CONFIDENTIAL DISCLOSURE AGREEMENT</p>

          <p>
            {' '}
            (“Receiving Party”) of. ______________, Pennsylvania and Boostpoint,
            LLC (“Disclosing Party”) of 49 Eagle Dr., Leola, PA 1540 are
            interested in a transfer of information relating to an evaluation of
            a software as a service (“SaaS”) platform being beta tested by the
            receiving party (“Beta Test”), which includes information
            proprietary to the Disclosing Party (“Information”). Both parties
            understand that any Information received hereunder is to be used by
            the Receiving Party only in relation to the aforesaid Bets Test.
          </p>

          <p>
            In view of the above-stated intentions, the parties agree as
            follows:
          </p>

          <ol>
            {' '}
            <li>
              The Receiving Party will exercise the same degree of care to
              prevent disclosure of the Information for the period specified
              below as it takes to preserve and safeguard its own proprietary
              data but, in any event, no less than a reasonable degree of care.
            </li>
            <li>
              The obligations of the Receiving Party, contained in paragraph 1
              above, shall not apply to any Information which:
            </li>
            <ol type="a">
              {' '}
              <li>
                is already known to the Receiving Party or independently
                developed by it;
              </li>
              <li>
                is publicly available or becomes publicly available without a
                breach of agreement by the Receiving Party;
              </li>
              <li>
                is rightfully received by the Receiving Party from a third
                party;
              </li>
              <li>
                is furnished by the Disclosing Party to a third party without a
                similar restriction of the third party’s rights;
              </li>
              <li>
                is not either (i) first disclosed in writing and identified
                thereon as confidential or proprietary, or (ii) if first
                disclosed orally, identified as confidential or proprietary at
                the time of oral disclosure, reduced to writing and identified
                thereon as confidential or proprietary by the Disclosing Party
                and the writing is delivered to the Receiving Party within
                thirty (30) days after oral disclosure.
              </li>
            </ol>{' '}
            <li>
              All Information which is disclosed by the Disclosing Party
              hereunder during the term hereof, shall be safeguarded as required
              by paragraph 1 above by the Receiving Party for a period of three
              (3) years from date of disclosure, unless earlier specifically
              released by the Disclosing Party in a duly executed writing or
              made available from examination of a product made publicly
              available by the Disclosing Party.{' '}
            </li>
            <li>
              The term of this Agreement shall be indefinite and it shall be
              terminable without cause by either party upon thirty (30) days
              advance written notice given to the other party; provided,
              however, the obligations under paragraphs 1 and 3 above, incurred
              during the term of this Agreement, shall survive termination
              hereof.
            </li>
            <li>
              No rights or obligations other than those expressly recited herein
              are to be implied from this Agreement. Nothing herein shall in any
              way affect the present or prospective rights of the parties under
              the patent laws of any country, or be construed as granting any
              license under any present or future patent or application therefor
              of either party, or preclude the filing of any patent applications
              derived through the program efforts, or the marketing of any
              product of a party, unless such marketing will constitute an
              unauthorized disclosure of Information.
            </li>
            <li>
              In the event of a breach of any of the obligations stated above,
              the injured party may proceed against the other party in law or in
              equity for such damages or other relief as a court may deem
              appropriate, consequential, and indirect damages excepted.
            </li>
            <li>
              The parties agree that no Information exchanged hereunder shall be
              disclosed by the Receiving Party outside of the United States of
              America.
            </li>
            <li>
              Neither party shall have the right to assign or otherwise transfer
              this Agreement, nor any rights or obligations contained herein, to
              any third party without the prior written approval of the other
              party hereto.
            </li>
            <li>
              This Agreement shall be governed and construed under the laws of
              the Commonwealth of Pennsylvania (excluding its conflict of law
              rules).
            </li>
            <li>
              {' '}
              The undersigned represents and warrants that he/she has the full
              corporate power and authority to enter into and perform this
              Agreement and that this Agreement is valid, binding and
              enforceable against the Receiving Party in accordance with its
              terms.
            </li>
          </ol>
          <p>
            The parties hereto, intending to be legally bound hereby, have
            caused this Agreement to be duly executed as of the latest date
            written below.
          </p>

          <p> Boostpoint, LLC</p>
        </Modal>
      </div>
    );
  }
}

export default UserAgreement;
