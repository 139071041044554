import React from "react";
import styled from "styled-components";
import BpModal from "../../../elements/BpModal";
import { InputBox, InputBoxWrapper } from "../../../styled/Input";

const ContactBoxWrapper = styled(InputBoxWrapper)`
  display: flex;
  input {
    width: 18rem;
  }
`;

const StatusEditModal = props => {
  const { modalStatusEdit, loadingLeads, status } = props;
  const [statusLabel, setStatusLabel] = React.useState("");

  React.useEffect(() => {
    if (status && status.status) {
      setStatusLabel(status.status);
    }
  }, [status]);

  return (
    <BpModal
      open={modalStatusEdit}
      title={`Edit status`}
      primaryAction={{
        btnLabel: "Save",
        action: () => props.handleStatusChange(statusLabel),
        loading: loadingLeads
      }}
      secondaryAction={{
        btnLabel: "Cancel",
        action: props.statusModalClose,
        loading: loadingLeads
      }}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      contentLabel='Edit status'
      body={
        <div>
          <ContactBoxWrapper>
            <InputBox
              type='text'
              required
              value={statusLabel}
              onChange={input => setStatusLabel(input.target.value)}
            />
          </ContactBoxWrapper>
        </div>
      }
      width='auto'
    />
  );
};

export default StatusEditModal;
