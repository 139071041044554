import styled from "styled-components";
import { userLogout } from "../utils/UserAPI";

const ImitatingWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  background: ${props => props.theme.HC1};
  color: white;
  @media only screen and (max-width: 600px) {
    font-size: 0.8em;
  }
`;

const Link = styled.span`
  font-weight: bold;
  text-decoration: underline;
  color: ${props => props.theme.HCSuc};
`;

const ImitatingUserNotice = props => {
  const { name = "", company = "" } = props;

  const logout = async () => {
    await userLogout();
    window.location.reload(false);
  };

  return (
    <ImitatingWrapper>
      <p>
        Imitating User {name} ({company}) <Link onClick={logout}>Log Out</Link>
      </p>
    </ImitatingWrapper>
  );
};

export default ImitatingUserNotice;
