import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: 1px solid ${props => props.theme.ATextC};
  padding: 20px 0;
  &:last-of-type {
    border: none;
  }
  span {
    font-weight: bold;
    font-size: 1.2em;
  }
`;

const BpConnectDetails = props => {
  const { userCompany, smsCredits } = props;
  return (
    <Wrapper>
      <Inner>
        <span>{userCompany.twilioPhone}</span>
        Your Phone Number
      </Inner>
      <Inner>
        <span>{smsCredits}</span> credits remaining
      </Inner>
    </Wrapper>
  );
};

export default BpConnectDetails;
