import React from "react";
import styled from "styled-components";
import InputEmoji from "react-input-emoji";
import MessageFileSelector from "./MessageFileSelector";
import MessageMedia from "./MessageMedia";
import {InputBoxWrapper} from "../../styled/Input";

const ResponseWrapper = styled.div`
  border-top: 1px solid ${props => props.theme.Border};
  display: grid;
  grid-template-columns: 65px 1fr 64px;
  z-index: 20;
  box-shadow: 0 -1px 1px 0 rgba(60, 64, 67, 0.08),
    0 -1px 3px 1px rgba(60, 64, 67, 0.16);
  position: relative;
`;

const ResponseInput = styled(InputBoxWrapper)`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin: 10px;
  padding: 0;
  background-color: ${props => props.theme.BGC};
  border: 1px solid ${props => props.theme.Border};
  border-radius: 3px;
  padding: 0;
  margin-left: 50px;
  .react-emoji {
    flex-direction: row-reverse;
    position: static;
  }
  .react-emoji-picker--wrapper {
    left: -50px;
    right: auto;
  }
  .react-input-emoji--container {
    background-color: transparent;
  }
  .react-input-emoji--button {
    position: absolute;
    left: -58px;
    top: 50%;
    margin-top: -15px;
  }
  .react-input-emoji--button svg {
    fill: ${props => props.theme.HC1};
    height: 30px;
    width: 30px;
  }
  .react-input-emoji--placeholder {
    @media screen and (max-width: 799px) {
      left: -10px;
    }
  }
  overflow-wrap: break-word;
`;

const SendIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  span {
    font-size: 36px;
    color: ${props => props.theme.HC1};
  }
`;

const MediaPickerWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  cursor: pointer;
  &:hover .image-button {
    padding: 3px;
    border: 1px solid ${props => props.theme.Border};
    box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
      0 1px 3px 1px rgba(60, 64, 67, 0.16);
  }
`;

const ImageButton = styled.span`
  color: ${props => props.theme.HC1};
  cursor: pointer;
  border-radius: 5px;
  padding: 4px;
  font-size: 36px;
`;

const MessageSendMessage = props => {
  const {msgBody, msgRows, mediaUrls} = props;
  
  const [showFileUploader, setShowFileUploader] = React.useState(false);

  const toggleShowUploader = forceClose => {
    if (forceClose) {
      setShowFileUploader(false);
    } else {
      setShowFileUploader(!showFileUploader);
    }
  };

  const handleTextChange = value => {
    let newMsgRows = Math.ceil(value.length / 115);
    if (newMsgRows === 0) {
      newMsgRows = 1;
    } else if (msgRows > 3) {
      newMsgRows = 3
    } 
    props.updateMsgBody(value, newMsgRows)
    props.scrollToBottom(true);
  };

  const chooseMedia = data => {
    console.log("chosen image", data);
    const { alert } = props;
    toggleShowUploader(true);
    if (data.filesFailed.length > 0) {
      console.log("files failed to upload", data.filesFailed);
      alert({ type: "error", msg: "some files failed to upload" });
    } else {
      props.updateMedia([...mediaUrls, ...data.filesUploaded])
    }
  };

  const removeMedia = index => {
    props.updateMedia(mediaUrls.splice(index, 1));
  };
  
  const onEnter = () => {
    if (msgBody) {
      props.sendMessage(msgBody, mediaUrls);
    }
  };

  return (
    <ResponseWrapper className='response'>
      <MediaPickerWrapper>
        <ImageButton
          className='material-icons image-button'
          onClick={() => toggleShowUploader()}
        >
          insert_photo
        </ImageButton>
        {!!showFileUploader && (
          <MessageFileSelector 
            chooseMedia={chooseMedia}
            toggleShowUploader={toggleShowUploader}
          />
        )}
      </MediaPickerWrapper>
      <ResponseInput>
        <InputEmoji
          type='text'
          name='msgBody'
          data-namereadable='first name'
          value={msgBody}
          msgRows={msgRows}
          placeholder='Start a new message...'
          onChange={handleTextChange}
          onEnter={onEnter}
          borderColor='transparent'
          borderRadius={0}
        />
        {mediaUrls.length > 0 && (
          <MessageMedia
            mediaUrl={mediaUrls}
            removeMedia={removeMedia}
            toggleShowUploader={toggleShowUploader}
          />
        )}
      </ResponseInput>

      <SendIcon onClick={onEnter}>
        <span className='material-icons'>send</span>
      </SendIcon>
    </ResponseWrapper>
  )
}

export default MessageSendMessage;
