import styled from "styled-components";

const SocialSelect = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em;
`;

export default SocialSelect;
