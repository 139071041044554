import styled from "styled-components";

// Base Styled Button Component
export const PreviewContainer = styled.div`
  width: 300px;
  max-width: 80%;
  margin: 0 auto;
  background: #ebedf0;
  outline: 1px solid lightgray;
  font-family: Segoe UI, San Francisco, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif, sans-serif;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  @media screen and (min-width: 759px) {
    max-width: none;
  }
`;

export const FormCard = styled.div`
  border-radius: 10px;
  background: white;
  padding: 10px 15px 10px 19px;
  margin: ${props => (props.contextTitle ? "-25px 20px 20px" : "20px")};
  position: relative;
  z-index: 5;
`;

export const HeadlineImage = styled.img`
  width: 100%;
  position: relative;
  z-index: 3;
`;

export const PageImageWrapper = styled.div`
  text-align: center;
`;

export const BusinessPageImage = styled.img`
  width: 53px;
  display: inline-block;
  margin-right: 7px;
  border-radius: 50%;
  border: 3px solid white;
  margin-top: -33px;
`;

export const BusinessPageName = styled.p`
  margin-bottom: 20px;
  font-size: 0.8em;
  color: #1e1e1e;
`;

export const TextWrapper = styled.div`
  padding: 20px;
  position: relative;
  text-align: left;
  color: #4f4f4f;
`;

export const Headline = styled.h2`
  text-align: center;
  font-size: 1em;
  font-weight: bold;
`;

export const Description = styled.p`
  font-size: 0.8em;
  text-align: left;
`;

export const ContextDescription = styled(Description)`
  font-size: 0.9em;
  text-align: center;
`;

export const Field = styled.div`
  margin-bottom: 15px;
`;

export const CustomField = styled(Field)`
  margin-bottom: 15px;
  text-align: left;
`;

export const Label = styled.label`
  display: block;
  text-align: left;
  font-size: 0.8em;
  margin-bottom: 5px;
`;

export const CustomLabel = styled.label`
  font-weight: bold;
  font-size: 0.9em;
  display: inline-block;
  margin: 1em auto;
`;

export const Input = styled.input`
  &[type="text"],
  &[type="email"],
  &[type="tel"] {
    background-color: transparent;
    border-radius: 0;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px inset #939393;
    width: 100%;
    font-size: 0.9em;
  }
`;

export const SubmitButton = styled.div`
  background-color: #5990ff;
  color: white;
  border-radius: 5px;
  margin: 10px;
  padding: 15px 30px;
`;
