import React from "react";

import ProfileImage from "./ProfileImage";

import { BusinessManagerConnectProps } from "./BusinessManagerConnectProps";
import styled from "styled-components";
import BpModal from "../../../elements/BpModal";

const BMName = styled.b`
  color: ${props => props.theme.HC2};
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const BusinessManagerConnect = (props: BusinessManagerConnectProps) => {
  const { open, close } = props;
  const { id, name, image } = props?.businessManager || {
    id: "",
    name: "",
    image: ""
  };
  const url = `https://business.facebook.com/settings/requests?business_id=${id}`;
  return (
    <BpModal
      open={open}
      onCloseMethod={close}
      title={["You're", "almost there!"]}
      subheading='We just need one more thing.'
      primaryAction={{
        action: () => {
          navigator.clipboard.writeText(url);
        },
        btnLabel: "COPY URL",
        bubble: { label: "URL Copied" }
      }}
      footer={url}
    >
      <>
        <p>
          All of your permissions have been set up properly. We just need your
          page’s <b>business manager admin</b> to approve us so that we can
          advertise on your behalf.
        </p>
        <p>
          Please click the button below and send the copied URL to{" "}
          <BMName>{name}</BMName> so they can provide approval.
        </p>
        <ProfileContainer>
          <ProfileImage
            img={image}
            name={name}
          />
          <BMName>{name}</BMName>
        </ProfileContainer>
      </>
    </BpModal>
  );
};

export default BusinessManagerConnect;
