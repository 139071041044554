import styled from "styled-components";

const FbPreviewText = styled.h6`
  background-color: ${props => (props.PH ? "lightgrey" : "")};
  grid-column: 1 / -1;
  grid-row: 5;
  text-align: left;
  margin: 0;
  font-size: 15px;
  white-space: pre-wrap;
  .more {
    color: #385898;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export default FbPreviewText;
