import * as React from "react";
import { SVGProps } from "react";
import styled from "styled-components";

const SVG = styled.svg`
  filter: drop-shadow(0 2px 2px rgb(0 0 0 / 0.1));
  color: white;
  align-content: center;
  justify-content: center;
  text {
    color: #7211b6;
    font-weight: 50;
    font-size: 1em;
  }
`;

const SvgComponent = (props: BubbleProps) => {
  const { dir, text } = props;
  return (
    <SVG
      {...props}
      viewBox='0 0 200 100'
      preserveAspectRatio
      width='180px'
    >
      {dir === "left" || dir === "right" ? (
        <path
          d='M0 8.344C0 3.737 4.995 0.000999928 11.156 0.000999928L107.262 0C113.423 0 118.418 3.736 118.418 8.344V10C118.418 13.104 121.636 15.978 123.627 17.578L125.002 18.678C125.199 18.869 125.356 19.0977 125.463 19.3505C125.57 19.6033 125.625 19.875 125.625 20.1495C125.625 20.424 125.57 20.6957 125.463 20.9485C125.356 21.2013 125.199 21.43 125.002 21.621L123.631 22.722C121.64 24.319 118.422 27.2 118.422 30.3L118.418 32.389C118.418 36.997 113.423 40.733 107.262 40.733C68.4297 40.733 48.9364 40.737 8.8 40.737C3.938 40.737 0 37.788 0 34.154V8.344Z'
          fill='currentColor'
          transform={dir === "right" ? "scale(-1,1) translate(-130,0)" : ""}
        />
      ) : (
        <path
          d='M11.656.501C5.495.501.5 4.237.5 8.844v25.81c0 3.634 3.938 6.583 8.8 6.583h42.521c3.1 0 5.981 3.218 7.578 5.209l1.101 1.371a2.048 2.048 0 0 0 2.943 0l1.1-1.375c1.6-1.991 4.474-5.209 7.578-5.209h35.641c6.161 0 11.156-3.736 11.156-8.344V8.844c0-4.608-4.995-8.344-11.156-8.344L11.656.501Z'
          fill='currentColor'
          transform={dir === "bottom" ? "scale(1,-1) translate(0,-50)" : ""}
        />
      )}
      <text
        stroke='currentColor'
        fill='currentColor'
        x='30%'
        y='22%'
        dominant-baseline='middle'
        text-anchor='middle'
        transform={
          dir === "bottom"
            ? "translate(0,10)"
            : dir === "right"
            ? "translate(10,0)"
            : ""
        }
      >
        {text}
      </text>
    </SVG>
  );
};

export type BubbleProps = SVGProps<SVGSVGElement> & {
  dir?: "top" | "bottom" | "left" | "right";
  text: string;
};
export default SvgComponent;
