export const standardModal = {
  overlay: {
    // dark them will use opposite rgba(250, 250, 250, .75)
    backgroundColor: 'rgba(0, 0, 0, .75)',
    zIndex: '1000'
  },
  content: {
    textAlign: 'center',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '50rem',
    maxWidth: '90%',
    maxHeight: '90%',
    padding: '1em 1em 2em',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    borderRadius: '0',
    boxShadow: '0px 0px 9px 0px rgba(0, 0, 6, .1)'
  }
};

export const fbPagesModal = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, .75)',
    zIndex: '1004'
  },
  content: {
    textAlign: 'center',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '55vw',
    minWidth: '325px',
    maxHeight: '90vh',
    padding: '0',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    borderRadius: '0',
    boxShadow: '0px 0px 9px 0px rgba(0, 0, 6, .1)'
  }
};

export const recentTransactionsModal = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, .75)',
    zIndex: '1000'
  },
  content: {
    textAlign: 'center',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    maxWidth: '90%',
    minWidth: '300px',
    maxHeight: '90%',
    padding: '1em 1em 2em',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    borderRadius: '0',
    boxShadow: '0px 0px 9px 0px rgba(0, 0, 6, .1)'
  }
};
