import moment from "moment";
import React from "react";
import styled from "styled-components";
import * as _ from "underscore";
import { errorHandler } from "../../../utils/Helpers";
import BpModal from "../../elements/BpModal";
import FbConnect from "../settings/fbConnect/FbConnect";
// Images
import AreaIcon from "../../../images/area_campaign_ico.png";

// Styled Components
import {
  campaignCreate,
  campaignDelete,
  campaignGetDrafts,
  targetingGetByIndustry
} from "../../../utils/FacebookAPI";
import { Button } from "../../styled/Buttons";
import { Subtitle, Title } from "../../styled/Headers";
import { Card, CardTitle } from "../../styled/Layout";
import CampaignTargetingSelect from "../campaignHistory/CampaignTargetingSelect";
import IndustrySelectModal from "./IndustrySelectModal";

const campaignTypes = [
  {
    id: "LinkClick",
    icon: "desktop_mac",
    title: "Increase Web Traffic",
    description:
      "Target a specific area where you would like to send people to your website or landing page."
  },
  {
    id: "LeadAd",
    icon: "contact_mail",
    title: "Generate Leads",
    description:
      "Target a specific area where you would like to generate your own leads."
  }
];

const CampaignSelectArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1024px;
  margin: 0 auto;

  @media screen and (max-width: 634px) {
    flex-flow: column;
  }
  background: ${props => props.theme.BGC};
`;

const CampaignSelectWrapper = styled.div`
  padding: ${props => (props.selected ? "1.5em" : "2.5em")} 2%;
  width: 46%;
  transition: 0.5s ease-in-out;
  transform-origin: 0 0;
  position: relative;
  background: ${props => props.theme.BGC};
  @media screen and (max-width: 634px) {
    width: 100%;
  }
  &.selected {
    background: ${props => props.theme.BGC};
    box-shadow: 0px 0px 9px -4px rgba(0, 0, 0, 0.75);
    z-index: 1001;
    width: 470px;
    top: 10px;
    position: fixed;
    margin: 0;
    @media (max-width: 36em) {
      width: 90%;
    }
    @media (max-height: 450px) {
      max-width: 96vw;
      width: auto;
      max-height: 96vh;
      transform: none;
      top: 10px;
      display: grid;
      grid-template-columns: 180px 1fr;
    }
  }
`;

const AdTypeSelectorWrapper = styled.div`
  position: relative;
`;

const AdTypeSelector = styled.div`
  transition: ${props => (props.open ? "0.2s ease-in 0.5s" : "0.2s ease-in")};
  max-height: ${props => (props.open ? "600px" : "0")};
  overflow: ${props => (props.open ? "scroll" : "hidden")};
  position: relative;
  background: ${props => props.theme.BGC};
  left: 0;
  padding: ${props => (props.open ? "20px" : "0")};
  width: 100%;
  @media screen and (max-height: 800px) {
    max-height: ${props => (props.open ? "335px" : "0")};
  }
  @media screen and (max-height: 450px) {
    max-height: ${props => (props.open ? "65vh" : "0")};
  }
`;

const MoreArrow = styled.div`
  position: ${props => (props.open ? "absolute" : "static")};
  display: ${props => (props.open ? "block" : "none")};
  width: 100%;
  left: 0;
  bottom: ${props => (props.position === "bottom" ? "0" : "auto")};
  top: ${props => (props.position === "top" ? "0" : "auto")};
  z-index: 1001;
  pointer-events: none;
  i {
    background: #675da9;
    color: white;
    /* border-radius: 7px; */
    border-top-left-radius: ${props =>
      props.position === "bottom" ? "7px" : "0"};
    border-top-right-radius: ${props =>
      props.position === "bottom" ? "7px" : "0"};
    border-bottom-right-radius: ${props =>
      props.position === "top" ? "7px" : "0"};
    border-bottom-left-radius: ${props =>
      props.position === "top" ? "7px" : "0"};
    opacity: 0.3;
    cursor: pointer;
    display: block;
    width: 29px;
    margin: 0 auto;
    transition: all 0.3s;
    pointer-events: all;
    :hover {
      opacity: 1;
    }
  }
`;

const AdTypeSelectorButton = styled.div`
  max-height: ${props => (props.open ? "auto" : "0")};
  overflow: hidden;
  grid-column: span 2;
`;

const CampaignBoxOverlay = styled.div`
  transition: 0.5s ease-in;
  pointer-events: none;
  z-index: 1000;
  position: fixed;
  background: transparent;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  ${props =>
    props.selected
      ? `
        pointer-events: auto;
        background: rgba(0,0,0,.75);
        backdrop-filter: blur(2px);
      `
      : ""};
`;

const CampaignItem = styled.div`
  color: #969696;
`;

const AreaSelect = styled(CampaignItem)`
  transition: 0.3s;
  :hover h4 {
    color: ${props => props.theme.HC1};
    transition: 1s;
  }
  :hover p {
    color: rgb(56, 56, 56);
    transition: 1s;
  }
  i.material-icons {
    color: ${props => props.theme.HC1};
    font-size: ${props => (props.open ? "5em" : "6em")};
  }
  @media (pointer: coarse) {
    #areaSelect {
      height: 7em;
      background: url(${AreaIcon}) no-repeat center;
      background-size: 8em;
    }
    #areaSelect h4 {
      color: ${props => props.theme.HC1};
    }
    #areaSelect p {
      color: rgb(56, 56, 56);
    }
  }
`;

const CampaignLink = styled.div`
  cursor: pointer;
  flex-basis: 180px;
  @media (max-height: 450px) {
    ${props =>
      props.open
        ? `
      border-right: 1px solid rgba(0, 0, 0, 0.2);
    padding-right: 10px;
      `
        : ""};
  }
`;

class CampaignSelect extends React.Component {
  state = {
    modalExistingCampaign: false,
    campaignType: null,
    redirectToArea: false,
    redirectToCustomer: false,
    tourRun: false,
    modalPermissionsOpen: false,
    segments: [],
    windowWidth: window.innerWidth,
    windowWidthCenter: window.innerWidth / 2,
    windowHeight: window.innerHeight,
    windowHeightCenter: window.innerHeight / 2,
    scrollPosition: window.pageYOffset,
    campaignBoxes: {
      LinkClick: {
        width: 0,
        height: 0,
        top: 0,
        left: 0,
        leftCenter: 0,
        topCenter: 0,
        selected: false,
        moreBelow: false,
        moreAbove: false
      },
      LeadAd: {
        width: 0,
        height: 0,
        top: 0,
        left: 0,
        leftCenter: 0,
        topCenter: 0,
        selected: false,
        moreBelow: false,
        moreAbove: false
      }
    },
    segmentSelected: false,
    modalIndustrySelect: false,
    existingCampaignId: ""
  };

  async componentDidMount() {
    const { userCompany, companyLoading } = this.props;
    const { industry } = userCompany;
    if (industry && !companyLoading) {
      this.getSegments(industry);
    } else if (!companyLoading) {
      this.setState({ modalIndustrySelect: true });
    }
    this.getCampaign();
    this.props.checkPermissions();
    this.getWindowDimensions();
    const throttleResize = _.debounce(this.getWindowDimensions, 100);
    window.addEventListener("resize", throttleResize);
    window.addEventListener("orientationchange", throttleResize);
    const throttleScroll = _.debounce(this.updateScrollDimensions, 100);
    window.addEventListener("scroll", throttleScroll);
  }

  componentWillUnmount = () => {
    const throttleResize = _.debounce(this.getWindowDimensions, 100);
    window.removeEventListener("resize", throttleResize);
    window.removeEventListener("orientationchange", throttleResize);
    const throttleScroll = _.debounce(this.updateScrollDimensions, 100);
    window.removeEventListener("scroll", throttleScroll);
  };

  componentDidUpdate = prevProps => {
    const { userCompany: prevCompany = {} } = prevProps;
    const { userCompany = {}, companyLoading } = this.props;
    if (!_.isEqual(prevCompany, userCompany) && !companyLoading) {
      const { industry } = userCompany;
      if (industry) {
        this.setState({ modalIndustrySelect: false });
        this.getSegments(industry);
      } else {
        this.setState({ modalIndustrySelect: true });
      }
    }
  };

  getCampaign = async () => {
    try {
      const campaigns = await campaignGetDrafts();
      if (campaigns.length > 0) {
        const { type: campaignType = "LeadAd", _id: existingCampaignId } =
          campaigns[0];
        this.setState({
          campaignType,
          modalExistingCampaign: true,
          existingCampaignId
        });
      }
    } catch (err) {
      console.log("error getting campaign", err.response);
    }
  };

  getSegments = async industry => {
    try {
      const segments = await targetingGetByIndustry(industry);
      segments.sort((a, b) => {
        if (a.index === b.index) {
          return a.name - b.name;
        }
        return a.index - b.index;
      });
      this.setState({ segments });
    } catch (err) {
      const { status, message } = errorHandler(err);
      console.log("err", `${status}: ${message}`);
    }
  };

  openModal = () => {
    this.setState({ modalExistingCampaign: true });
  };

  handleContinue = () => {
    // close modal by setting state back to false
    const { campaignType } = this.state;
    if (campaignType) {
      const location = {
        pathname: "/area-campaign",
        state: {
          campaignType
        }
      };
      this.props.history.push(location);
    }
  };

  handleStartOver = async () => {
    // close modal by setting state back to false
    this.setState({ modalExistingCampaign: false });
    try {
      const campaigns = await campaignGetDrafts();
      const campaignIds = campaigns.map(c => c._id);
      if (campaignIds.length > 0) {
        const wait = [];
        campaignIds.forEach(c => wait.push(campaignDelete(c)));
        await Promise.all(wait);
      }
      this.props.history.push({ pathname: "/campaign-select" });
    } catch (err) {
      console.log(err);
    }
  };

  redirectToLinkClick = () => {
    const location = {
      pathname: "/area-campaign",
      state: {
        campaignType: "LinkClick"
      }
    };
    this.props.history.push(location);
  };

  redirectToLeadAd = () => {
    const { fbPermissions, fbAccountActive } = this.props.userProfile;
    if (fbAccountActive && fbPermissions && fbPermissions.leads_retrieval) {
      const location = {
        pathname: "/area-campaign",
        state: {
          campaignType: "LeadAd"
        }
      };
      this.props.history.push(location);
    } else {
      this.setState({ modalPermissionsOpen: true });
    }
  };

  getWindowDimensions = () => {
    const { innerWidth, innerHeight, pageYOffset } = window;
    const newState = {
      windowWidth: innerWidth,
      windowHeight: innerHeight,
      windowWidthCenter: innerWidth / 2,
      windowHeightCenter: innerHeight / 2,
      scrollPosition: pageYOffset
    };
    let maxBoxHeight = 600;
    if (innerHeight < 800) {
      maxBoxHeight = 335;
    }
    const { campaignBoxes } = this.state;
    campaignTypes.forEach(segment => {
      const selected = campaignBoxes[segment.id].selected;
      const element = document.getElementById(`box${segment.id}`);
      if (element) {
        const boxDim = element.getBoundingClientRect();
        const top = selected
          ? boxDim.top - campaignBoxes[segment.id].topCenter
          : boxDim.top;
        const left = selected
          ? boxDim.left - campaignBoxes[segment.id].leftCenter
          : boxDim.left;
        const width = boxDim.width;
        const height = boxDim.height;
        const leftCenter = Math.round(innerWidth / 2 - left - width / 2);
        let topCenter = Math.round(
          innerHeight / 2 - top - top / 2 - height - pageYOffset
        );
        if (Math.abs(topCenter) > top) {
          topCenter = Math.round(
            innerHeight / 2 - top - height / 2 - pageYOffset
          );
          if (Math.abs(topCenter) > top) {
            topCenter = 0;
          }
        }
        const newBox = {
          width,
          height,
          top,
          left,
          leftCenter,
          topCenter,
          selected: campaignBoxes[segment.id].selected,
          moreBelow: campaignBoxes[segment.id].moreBelow,
          moreAbove: campaignBoxes[segment.id].moreAbove,
          topArrow: top,
          bottomArrow: top + maxBoxHeight
        };
        campaignBoxes[segment.id] = newBox;
      }
    });
    newState.campaignBoxes = campaignBoxes;
    this.setState({
      ...newState
    });
  };

  updateScrollDimensions = () => {
    const { pageYOffset } = window;
    const {
      campaignBoxes,
      scrollPosition: oldScrollPosition,
      windowHeight
    } = this.state;
    const newState = {
      scrollPosition: pageYOffset
    };
    campaignTypes.forEach(segment => {
      const selected = campaignBoxes[segment.id].selected;
      let boxHeight = campaignBoxes[segment.id].height;
      if (selected) {
        const element = document.getElementById(`box${segment.id}`);
        if (element) {
          const boxDim = element.getBoundingClientRect();
          boxHeight = boxDim.height;
          campaignBoxes[segment.id].openedHeight = boxHeight;
        }
      }
      if (
        (windowHeight >= campaignBoxes[segment.id].height &&
          !campaignBoxes[segment.id].openedHeight) ||
        windowHeight >= campaignBoxes[segment.id].openedHeight
      ) {
        let { topCenter } = campaignBoxes[segment.id];
        const scrollDifference = pageYOffset - oldScrollPosition;
        if (campaignBoxes[segment.id].selected && windowHeight > boxHeight) {
          topCenter = campaignBoxes[segment.id].topCenter + scrollDifference;
        } else if (topCenter > campaignBoxes[segment.id].top - pageYOffset) {
          topCenter = campaignBoxes[segment.id].top - pageYOffset;
        }
        campaignBoxes[segment.id] = {
          ...campaignBoxes[segment.id],
          topCenter: topCenter
        };
        newState.campaignBoxes = campaignBoxes;
        this.setState({
          ...newState
        });
      }
    });
  };

  handleCampaignSelectClick = (e, boxNumber) => {
    const { campaignBoxes } = this.state;
    const classes = e.target.className.split(" ");
    e.stopPropagation();
    if (
      !boxNumber ||
      !campaignBoxes[boxNumber] ||
      !campaignBoxes[boxNumber].selected ||
      classes.includes("overlay")
    ) {
      let segmentSelected = false;
      Object.keys(campaignBoxes).forEach(key => {
        if (key === boxNumber) {
          const selected = !campaignBoxes[key].selected;
          campaignBoxes[key].selected = selected;
          segmentSelected = selected;
        } else {
          campaignBoxes[key].selected = false;
        }
      });
      this.setState({ campaignBoxes, segmentSelected }, () => {
        this.handleAdTypeOpen(boxNumber, segmentSelected);
      });
    }
  };

  handleAdTypeOpen = (id, opened) => {
    const { windowHeight, campaignBoxes } = this.state;
    let maxBoxHeight = 600;
    if (windowHeight < 800) {
      maxBoxHeight = 335;
    }
    if (
      this[`box${id}AdSelector`] &&
      this[`box${id}AdSelector`].current &&
      opened
    ) {
      const boxHeight = this[`box${id}AdSelector`].current.clientHeight;
      if (boxHeight > maxBoxHeight) {
        campaignBoxes[id].moreBelow = true;
      }
      this.setState({ campaignBoxes });
      const adTypeScroll = _.debounce(this.handleAdTypeScroll, 100);
      this[`box${id}AdWrapper`].current.addEventListener("scroll", () =>
        adTypeScroll(id)
      );
    }
    if (!opened) {
      const adTypeScroll = _.debounce(this.handleAdTypeScroll, 100);
      Object.keys(campaignBoxes).forEach(key => {
        campaignBoxes[key].moreBelow = false;
        if (this[`box${key}AdWrapper`]) {
          this[`box${key}AdWrapper`].current.removeEventListener(
            "scroll",
            adTypeScroll
          );
        }
      });
      this.setState({ campaignBoxes });
    }
  };

  handleAdTypeScroll = id => {
    const { windowHeight, campaignBoxes } = this.state;
    let maxBoxHeight = 600;
    if (windowHeight < 800) {
      maxBoxHeight = 335;
    }
    const boxHeight = this[`box${id}AdSelector`].current.clientHeight;
    const element = document.getElementById(`box${id}AdSelector`);
    if (element) {
      const boxDim = element.getBoundingClientRect();
      campaignBoxes[id].topArrow = boxDim.top;
      campaignBoxes[id].bottomArrow = boxDim.top + maxBoxHeight;
    }
    const scrollHeight = this[`box${id}AdWrapper`].current.scrollTop;
    if (boxHeight > scrollHeight && scrollHeight) {
      campaignBoxes[id].moreAbove = true;
    } else {
      campaignBoxes[id].moreAbove = false;
    }
    if (scrollHeight + maxBoxHeight - 40 >= boxHeight - 10) {
      // 40 is the box padding. It is not exact so I subtracted 10 to give it some wiggle room
      campaignBoxes[id].moreBelow = false;
    } else {
      campaignBoxes[id].moreBelow = true;
    }
    this.setState({ campaignBoxes });
  };

  handleScrollClick = (e, id, direction) => {
    e.stopPropagation();
    const { windowHeight } = this.state;
    let maxBoxHeight = 600;
    if (windowHeight < 800) {
      maxBoxHeight = 335;
    }
    let scrollTop = this[`box${id}AdWrapper`].current.scrollTop;
    if (this[`box${id}AdWrapper`] && this[`box${id}AdWrapper`].current) {
      if (direction === "up") {
        scrollTop = scrollTop - maxBoxHeight;
      } else if (direction === "down") {
        scrollTop = scrollTop + maxBoxHeight;
      }
      this[`box${id}AdWrapper`].current.scrollTop = scrollTop;
    }
  };

  handleSegmentSelect = async (e, targeting, type) => {
    e.stopPropagation();
    const { _id: userId } = this.props.userProfile;
    const { _id: companyId } = this.props.userCompany;
    const { _id: targetingId, specialAdCategories } = targeting;
    if (targetingId) {
      try {
        const campaignResponse = await campaignCreate({
          userId,
          companyId,
          startDate: moment().startOf("day").toDate(),
          endDate: moment().add(4, "weeks").endOf("day").toDate(),
          type,
          targetingId,
          name: "untitled",
          radius: specialAdCategories.includes("EMPLOYMENT") ? 15 : 10,
          credits: {
            original: 100,
            spent: 0,
            transferred: 0,
            holdExpiration: ""
          }
        });
        console.log("campaignResponse", campaignResponse);
        this.props.history.push("/area-campaign");
      } catch (err) {
        const { status, message } = errorHandler(err);
        console.log("err", `${status}: ${message}`);
        return false;
      }
    }
  };

  toggleSaveIndustry = () => {
    const { savingIndustry } = this.state;
    this.setState({ savingIndustry: !savingIndustry });
  };

  handleSaveIndustry = async userCompany => {
    const { alert } = this.props;
    try {
      this.props.updateCompanyDoc(userCompany);
      this.setState({ modalIndustrySelect: false });
      alert({
        type: "success",
        msg: "Industry successfully saved."
      });
    } catch (err) {
      console.log("error setting industry", err.message);
    }
    this.toggleSaveIndustry();
  };

  render() {
    const { userCompany } = this.props;
    const {
      campaignType,
      modalExistingCampaign,
      modalPermissionsOpen,
      segments,
      campaignBoxes,
      modalIndustrySelect,
      savingIndustry
    } = this.state;
    return (
      <Card>
        <div className='cardSection__campaign'>
          <CardTitle>
            <Title>Campaigns</Title>
          </CardTitle>
          <Subtitle>Hyper-Targeted Campaigns</Subtitle>
          <h5>Choose which type of campaign you would like to create.</h5>
          <div>
            <CampaignSelectArea className='select-campaigns'>
              {campaignTypes.map((box, index) => (
                <CampaignSelectWrapper
                  key={index}
                  className={campaignBoxes[box.id].selected ? "selected" : ""}
                  leftCenter={campaignBoxes[box.id].leftCenter}
                  topCenter={campaignBoxes[box.id].topCenter}
                  onClick={e => this.handleCampaignSelectClick(e, box.id)}
                  id={`box${box.id}`}
                  windowHeight={this.state.windowHeightCenter}
                  windowWidth={this.state.windowWidthCenter}
                  boxHeight={campaignBoxes[box.id].height}
                  boxWidth={campaignBoxes[box.id].width}
                >
                  <CampaignLink
                    className='hyper-targeted-campaign'
                    open={campaignBoxes[box.id].selected}
                  >
                    <AreaSelect open={campaignBoxes[box.id].selected}>
                      <i className='material-icons'>{box.icon}</i>
                      <h4>{box.title}</h4>
                      <p>{box.description}</p>
                    </AreaSelect>
                  </CampaignLink>
                  <AdTypeSelectorWrapper>
                    {campaignBoxes[box.id].moreAbove && (
                      <MoreArrow
                        position='top'
                        open={campaignBoxes[box.id].selected}
                        className='arrowUp'
                      >
                        <i
                          className='material-icons'
                          onClick={e => this.handleScrollClick(e, box.id, "up")}
                        >
                          keyboard_arrow_up
                        </i>
                      </MoreArrow>
                    )}
                    <AdTypeSelector open={campaignBoxes[box.id].selected}>
                      <CampaignTargetingSelect
                        segments={segments}
                        type={box.id}
                        handleSegmentSelect={this.handleSegmentSelect}
                      />
                    </AdTypeSelector>
                    {campaignBoxes[box.id].moreBelow && (
                      <MoreArrow
                        position='bottom'
                        open={campaignBoxes[box.id].selected}
                        className='arrowDown'
                      >
                        <i
                          className='material-icons'
                          onClick={e =>
                            this.handleScrollClick(e, box.id, "down")
                          }
                        >
                          keyboard_arrow_down
                        </i>
                      </MoreArrow>
                    )}
                  </AdTypeSelectorWrapper>
                  <AdTypeSelectorButton open={campaignBoxes[box.id].selected}>
                    <Button
                      onClick={e => this.handleCampaignSelectClick(e, "")}
                    >
                      Cancel
                    </Button>
                  </AdTypeSelectorButton>
                </CampaignSelectWrapper>
              ))}
            </CampaignSelectArea>
          </div>
          <CampaignBoxOverlay
            selected={this.state.segmentSelected}
            onClick={e => this.handleCampaignSelectClick(e, "")}
            className='overlay'
          ></CampaignBoxOverlay>
          <BpModal
            open={modalExistingCampaign}
            title={`You have an existing ${campaignType} campaign`}
            primaryAction={{
              btnLabel: "Continue",
              action: this.handleContinue
            }}
            secondaryAction={{
              btnLabel: "Start Over",
              action: this.handleStartOver
            }}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
            contentLabel='Existing Campaign'
            body={<p>Would you like to continue with this campaign?</p>}
            width='auto'
          />
          <BpModal
            open={modalPermissionsOpen}
            title={"You do not have the correct permissions to receive leads."}
            primaryAction={{
              btnLabel: "Done!",
              action: () => this.setState({ modalPermissionsOpen: false })
            }}
            secondaryAction={{
              btnLabel: "Cancel",
              action: () => this.setState({ modalPermissionsOpen: false })
            }}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
            contentLabel='Existing Campaign'
            body={
              <div>
                <p>
                  Please <strong>reconnect your Facebook page</strong> by
                  clicking the toggle button and then turn it back on.
                </p>
                <FbConnect alert={this.alert} {...this.props} />
              </div>
            }
            width='auto'
          />
          <IndustrySelectModal
            modalIndustrySelect={modalIndustrySelect}
            loading={savingIndustry}
            saveAction={this.handleSaveIndustry}
            saving={this.toggleSaveIndustry}
            onClose={() => this.setState({ modalIndustrySelect: false })}
            userCompany={userCompany}
            msg='Before starting a campaign, please select your industry to get
            accurate ad targeting and ad template recommendations. You can
            update this at any time from the settings page.'
          />
        </div>
      </Card>
    );
  }
}

export default CampaignSelect;
