import styled from "styled-components";

const FbPreviewTitleWrapper = styled.div`
  background-color: #e8eaee;
  grid-column: 1 / -1;
  grid-row: 8;
  min-height: 60px;
  display: grid;
  grid-template-columns: 5fr 3fr;
  padding: 0;
  text-align: left;
`;

export default FbPreviewTitleWrapper;
