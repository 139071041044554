/* global cloudinary */

import React from "react";
import styled from "styled-components";

const SegmentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top: ${props =>
    props.first ? "1px solid rgba(0, 0, 0, 0.2)" : "none"};
  cursor: pointer;
  p {
    transition: transform 0.2s ease-in;
    @media (max-height: 450px) {
      margin-top: 10px;
    }
  }
  :hover {
    color: ${props => props.theme.HC1};
    i {
      transform: scale(1.1);
    }
    .selector {
      transform: scale(1.1) translateX(3px);
    }
  }
`;

const SegmentSelector = styled.div`
  transition: transform 0.2s ease-in;
  min-width: 60px;
  i {
    font-size: 44px;
  }
`;

const SegmentIcon = styled.i`
  transition: transform 0.2s ease-in;
  min-width: 60px;
  font-size: 44px;
`;

const SegmentTitle = styled.h3`
  transition: color 0.2s ease-in;
  @media (max-height: 450px) {
    margin-bottom: 10px;
  }
`;

const CampaignTargetingSelect = props => {
  const { segments, type } = props;

  return (
    <div>
      {segments.length > 0 &&
        segments.map((s, indexS) => (
          <SegmentWrapper
            key={s.name}
            first={indexS === 0}
            onClick={e => props.handleSegmentSelect(e, s, type)}
          >
            <SegmentIcon
              className='material-icons'
              onClick={e => props.handleSegmentSelect(e, s, type)}
            >
              {s.icon}
            </SegmentIcon>
            <div>
              <SegmentTitle
                onClick={e => props.handleSegmentSelect(e, s, type)}
              >
                {s.name}
              </SegmentTitle>
              <p onClick={e => props.handleSegmentSelect(e, s, type)}>
                {s.description}
              </p>
            </div>
            <SegmentSelector>
              <i className='material-icons selector'>keyboard_arrow_right</i>
            </SegmentSelector>
          </SegmentWrapper>
        ))}
    </div>
  );
};

export default CampaignTargetingSelect;
