import React from "react";
import styled from "styled-components";
import AnimateHeight from "react-animate-height";
import WorkflowModal from "./WorkflowModal";
import EditSMS from "./EditSMS";
import { LoaderDotsSmall } from "../../elements/Loaders";
import { Button } from "../../styled/Buttons";
import {
  ActionEditButton,
  ActionEditDelete,
  FlowCard,
  FlowHeader,
  FlowHeaderToggle,
  FlowHeaderTitle,
  FlowHeaderRight,
  FlowHeaderLeft,
  FlowHeaderDivider,
  Icon,
  H2,
  H3,
  WorkflowError
} from "./styles";
import { Dropdown, InputBox } from "../../styled/Input";

const ActionBody = styled.div`
  text-align: left;
`;

const ActionItemWrapper = styled.div`
  padding: 20px 40px;
  border-bottom: 1px solid #d9d9da;
`;

const ActionMediaSelected = styled.div`
  font-size: smaller;
  font-weight: bold;
  margin-top: 10px;
`;

const EditOptionWrapper = styled.div`
  display: flex;
  align-items: center;
  select {
    margin: 0 5px;
  }
`;

export const FauxTextArea = styled.div`
  border: 1px solid #7211b6;
  padding: 20px 10px;
  font-size: 15px;
  border-radius: 3px;
`;

export const EmailSubject = styled.div`
  border: 1px solid #7211b6;
  padding: 10px;
  font-size: 15px;
  border-radius: 3px;
`;

const UrgentText = styled.p`
  font-weight: 600;
  font-style: italic;
`;

const Actions = props => {
  const [triggerOpen, setTriggerOpen] = React.useState(true);
  const [height, setHeight] = React.useState("auto");
  const [activeDelayValue, setActiveDelayValue] = React.useState(0);
  const [activeDelayInterval, setActiveDelayInterval] = React.useState("");
  const [modalDeleteConfirm, setModalDeleteConfirm] = React.useState(false);
  const [currentDeleteAction, setCurrentDeleteAction] = React.useState({});
  const [deleting, setDeleteLoading] = React.useState(false);
  const [delayEdit, setDelayEdit] = React.useState(false);
  const [editSMSModal, setEditSMSModal] = React.useState(false);

  const {
    actions,
    updateActions,
    deleteAction,
    errors,
    resetValidate,
    workflowActive
  } = props;

  const toggleHeight = () => {
    const newHeight = height === 0 ? "auto" : 0;
    setHeight(newHeight);
    setTriggerOpen(!triggerOpen);
  };

  const toggleDelayEdit = action => {
    updateDelay(action);
    setDelayEdit(!delayEdit);
  };

  const updateDelay = action => {
    resetValidate("actions");
    const { delayMinutes, delayInterval } = action;
    const minToValue = convertMinutesToValue(delayMinutes, delayInterval);
    const newDelayInterval = delayInterval.toUpperCase();
    const newInterval =
      activeDelayInterval !== ""
        ? activeDelayInterval
        : newDelayInterval
        ? newDelayInterval
        : (delayMinutes / 60) % 1 !== 0
        ? "HOUR"
        : "DAY";
    const newValue =
      activeDelayValue > 0
        ? convertValueToMinutes(activeDelayValue, newInterval)
        : convertValueToMinutes(minToValue, newInterval);
    const updatedAction = {
      ...action,
      delayMinutes: newValue,
      delayInterval: newInterval
    };
    updateActions(updatedAction);
    setActiveDelayValue(0);
    setActiveDelayInterval(newInterval);
  };

  const convertValueToMinutes = (minutes, interval) => {
    const conversion = interval === "DAY" ? minutes * 1440 : minutes * 60;
    return conversion;
  };

  const convertMinutesToValue = (minutes, interval) => {
    const conversion = interval === "DAY" ? minutes / 1440 : minutes / 60;
    return conversion;
  };

  const updateSMS = (body, mediaUrl, action) => {
    const newBody = body.trim();
    if (!newBody && workflowActive) {
      props.alert({ type: "error", msg: "The message cannot be blank." });
      return;
    }
    resetValidate("actions");
    const updatedAction = { ...action, body: newBody, mediaUrl };
    setEditSMSModal(false);
    updateActions(updatedAction);
  };

  const removeAction = () => {
    resetValidate("actions");
    setDeleteLoading(true);
    deleteAction(currentDeleteAction);
    setDeleteLoading(false);
    setCurrentDeleteAction({});
    setModalDeleteConfirm(false);
  };

  return (
    <div>
      {actions.map((action, index) => {
        if (action.type && action.type === "DELAY") {
          const { delayMinutes, delayInterval } = action;
          const delay = convertMinutesToValue(delayMinutes, delayInterval);
          return (
            <FlowCard
              key={index}
              hasError={
                errors.actions &&
                errors.actions.some(error => error.index === action.stepNo - 1)
              }
            >
              {errors.actions &&
                errors.actions.map(error => {
                  if (error.index === action.stepNo - 1) {
                    return <WorkflowError>{error.message}</WorkflowError>;
                  }
                })}
              <FlowHeader triggerOpen={false}>
                <FlowHeaderLeft>
                  <Icon className='material-icons-outlined'>schedule</Icon>
                  <H2>Delay</H2>
                  <FlowHeaderDivider></FlowHeaderDivider>
                  {delayEdit ? (
                    <EditOptionWrapper key={index}>
                      <InputBox
                        type='text'
                        onKeyPress={event => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        defaultValue={delay}
                        onChange={e => setActiveDelayValue(e.target.value)}
                        compact
                      />
                      <Dropdown
                        type='text'
                        name='delayInterval'
                        defaultValue={
                          activeDelayInterval
                            ? activeDelayInterval
                            : "Delay Type"
                        }
                        compact
                        onChange={e => setActiveDelayInterval(e.target.value)}
                      >
                        <option value='Delay Type' disabled>
                          Delay Type
                        </option>
                        <option key={1} value='HOUR'>
                          {`Hour${delay > 1 ? "S" : ""}`}
                        </option>
                        <option key={2} value='DAY'>
                          {`Day${delay > 1 ? "S" : ""}`}
                        </option>
                      </Dropdown>
                    </EditOptionWrapper>
                  ) : (
                    <FlowHeaderTitle key={index}>{`${delay} ${delayInterval}${
                      delay > 1 ? "S" : ""
                    }`}</FlowHeaderTitle>
                  )}
                </FlowHeaderLeft>
                <FlowHeaderRight>
                  <Button onClick={() => toggleDelayEdit(action)} small>
                    {delayEdit ? "Save" : "Edit"}
                  </Button>
                  <ActionEditDelete>
                    <span
                      className='material-icons'
                      onClick={() => {
                        setCurrentDeleteAction(action);
                        setModalDeleteConfirm(true);
                      }}
                    >
                      delete
                    </span>
                  </ActionEditDelete>
                </FlowHeaderRight>
              </FlowHeader>
            </FlowCard>
          );
        }
        if (action.type && action.type === "SMS") {
          const comma = actions.length > 1 && index === 0;
          return (
            <FlowCard
              key={index}
              hasError={
                errors.actions &&
                errors.actions.some(error => error.index === action.stepNo - 1)
              }
            >
              <FlowHeader triggerOpen={triggerOpen}>
                <FlowHeaderLeft>
                  <Icon className='material-icons-outlined'>add</Icon>
                  <H2>Action</H2>
                  <FlowHeaderDivider></FlowHeaderDivider>
                  <FlowHeaderTitle>
                    {`${action.type}`}
                    {comma && ","}&nbsp;
                  </FlowHeaderTitle>
                </FlowHeaderLeft>
                <FlowHeaderRight>
                  <FlowHeaderToggle
                    triggerOpen={triggerOpen}
                    onClick={() => toggleHeight()}
                  >
                    <span className='material-icons-outlined'>
                      keyboard_arrow_up
                    </span>
                  </FlowHeaderToggle>
                </FlowHeaderRight>
              </FlowHeader>
              <AnimateHeight
                duration={200}
                height={height} // see props documentation below
              >
                <ActionBody>
                  {errors.actions &&
                    errors.actions.map(error => {
                      if (error.index === action.stepNo - 1) {
                        return <WorkflowError>{error.message}</WorkflowError>;
                      }
                    })}
                  <ActionItemWrapper>
                    <H3>Send a Text Message</H3>
                    <FauxTextArea>
                      <span>{action.body}</span>
                    </FauxTextArea>
                    {action.mediaUrl && action.mediaUrl.length > 0 && (
                      <ActionMediaSelected>
                        {action.mediaUrl.length} Image/Video Used
                      </ActionMediaSelected>
                    )}
                    <ActionEditButton>
                      <Button onClick={() => setEditSMSModal(true)} small>
                        Edit Message
                      </Button>
                      <ActionEditDelete>
                        <span
                          className='material-icons'
                          onClick={() => {
                            setCurrentDeleteAction(action);
                            setModalDeleteConfirm(true);
                          }}
                        >
                          delete
                        </span>
                      </ActionEditDelete>
                    </ActionEditButton>
                    {editSMSModal && (
                      <EditSMS
                        action={action}
                        message={action.body}
                        updateSMS={updateSMS}
                        mediaUrl={action.mediaUrl}
                        actionKey={index}
                        editSMSModal={editSMSModal}
                        setEditSMSModal={setEditSMSModal}
                        alert={props.alert}
                        // userProfile={userProfile}
                        saveWorkflows={props.saveWorkflows}
                        // errMessages={showErrors ? errMessages : {}}
                      />
                    )}
                  </ActionItemWrapper>
                </ActionBody>
              </AnimateHeight>
            </FlowCard>
          );
          {
            /* TODO: Add this back in if we decide to allow multiple conditions
            if (action.type && action.type === 'Email') {
              return (
                <ActionItemWrapper>
                  <H3>Send an Email</H3>
                  <FlowInputLabel bold>Subject Line</FlowInputLabel>
                  <EmailSubject>{action.subject}</EmailSubject>
                  <FlowInputLabel bold>Message</FlowInputLabel>
                  <FauxTextArea>{action.message}</FauxTextArea>
                  <ActionEditButton>
                    <Button small>Edit Message</Button>
                    <ActionEditDelete>
                      <span className="material-icons">delete</span>
                    </ActionEditDelete>
                  </ActionEditButton>
                </ActionItemWrapper>
              );
            }
            if (action.type && action.type === 'Delay') {
              return (
                <div>
                  <H3>Delay</H3>
                  <span>{`${action.delayValue} ${action.delayInterval}`}</span>
                </div>
              );
            }
            <AddActionWrapper>
      <AddAction>
        <span
          onClick={() => toggleActionModal(true, true, currentStepNo)}
          className="material-icons-outlined"
        >
          add_circle_outline
        </span>
      </AddAction>
    </AddActionWrapper> */
          }
        }
      })}
      <WorkflowModal
        open={modalDeleteConfirm}
        title='Delete Action'
        onCloseMethod={() => {
          setCurrentDeleteAction({});
          setModalDeleteConfirm(false);
        }}
        body={
          <div>
            <p>
              Are you sure you want to delete this '{currentDeleteAction.type}'
              action?
            </p>
            <UrgentText>This action can't be undone!</UrgentText>
            <Button
              onClick={() => {
                setCurrentDeleteAction({});
                setModalDeleteConfirm(false);
              }}
              disabled={deleting}
            >
              Cancel
            </Button>
            <Button solid onClick={() => removeAction()} disabled={deleting}>
              Delete
              {deleting && <LoaderDotsSmall />}
            </Button>
          </div>
        }
      />
    </div>
  );
};

export default Actions;
