import styled from "styled-components";

const FbPreviewNewsfeedLinkDesc = styled.h6`
  background-color: ${props => (props.PH ? "lightgrey" : "")};
  color: #606770;
  /* grid-column: 1 / -1;
  grid-row: 11; */
  margin: 0;
  font-size: 0.9em;
`;

export default FbPreviewNewsfeedLinkDesc;
