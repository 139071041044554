import React from "react";
import styled from "styled-components";
import moment from "moment-timezone";
import { Button } from "../../styled/Buttons";
import BpModal from "../../elements/BpModal";
import {
  campaignCreate,
  campaignDelete,
  campaignGetDrafts,
  targetingGetByIndustry
} from "../../../utils/FacebookAPI";
import { LoaderDotsSmall } from "../../elements/Loaders";
import CampaignTargetingSelect from "./CampaignTargetingSelect";

const UrgentText = styled.p`
  font-weight: 600;
  font-style: italic;
`;

const CampaignDuplicate = props => {
  const { duplicating, campaign, industry } = props;
  const [duplicateModals, setDuplicateModals] = React.useState({
    confirm: false,
    targeting: false
  });
  const [draftCampaigns, setDraftCampaigns] = React.useState({});
  const [targetingId, setTargetingId] = React.useState(campaign.targetingId);
  const [loading, setLoading] = React.useState(false);
  const [segments, setSegments] = React.useState([]);

  React.useEffect(() => {
    if (campaign.targetingId) {
      setTargetingId(campaign.targetingId);
    }
  }, [campaign.targetingId]);

  React.useEffect(() => {
    const i = async () => {
      const getDrafts = await campaignGetDrafts();
      if (getDrafts.length > 0) {
        setDraftCampaigns(getDrafts);
      }
      setDuplicateModals(prevModals => ({
        ...prevModals,
        confirm: true
      }));
    };
    if (duplicating) {
      i();
    }
  }, [duplicating]);

  const toggleConfirm = () => {
    setDuplicateModals({ ...duplicateModals, confirm: true });
  };

  const duplicateCampaign = React.useCallback(async () => {
    const {
      startDate,
      endDate,
      credits: { original }
    } = campaign;
    if (!targetingId) {
      setDuplicateModals(prevModals => ({ ...prevModals, targeting: true }));
      return;
    }
    try {
      setLoading(true);
      if (draftCampaigns && draftCampaigns.length > 0) {
        draftCampaigns.forEach(c => campaignDelete(c._id));
      }
      const originalStart = moment(startDate);
      const originalEnd = moment(endDate);
      const daysBetween = originalEnd.diff(originalStart, "days") + 1;
      const newStart = moment.utc(new Date()).format();
      const newEnd = moment.utc(newStart).add(daysBetween, "days").format();
      const media = campaign.media.map(m => ({
        ...m,
        adId: "",
        inactiveReason: "",
        effectiveStatus: "",
        adReviewFeedback: ""
      }));
      const newCampaign = {
        ...campaign,
        media,
        credits: { original, spent: 0, transferred: 0, holdExpiration: "" },
        startDate: newStart,
        endDate: newEnd,
        status: "DRAFT",
        name: "untitled",
        isDraft: true,
        isActive: false,
        fbCampaignId: "",
        inactiveReason: "",
        effectiveStatus: "",
        adReviewFeedback: "",
        targetingId
      };
      delete newCampaign._id;
      if (newCampaign.migrationId) {
        delete newCampaign.migrationId;
      }
      console.log("creating campaign", newCampaign);
      const createCampaign = await campaignCreate(newCampaign);
      if (createCampaign) {
        props.onClose(createCampaign._id);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("error creating campaign", err.message);
    }
  }, [campaign, draftCampaigns, props, targetingId]);

  React.useEffect(() => {
    if (targetingId && duplicateModals.targeting) {
      console.log("newid", targetingId);
      setDuplicateModals(prevModals => ({ ...prevModals, targeting: false }));
      duplicateCampaign();
    }
  }, [duplicateCampaign, duplicateModals.targeting, targetingId]);

  const getSegments = React.useCallback(async () => {
    try {
      const segmentsReturn = await targetingGetByIndustry(industry);
      segmentsReturn.sort((a, b) => {
        if (a.index === b.index) {
          return a.name - b.name;
        }
        return a.index - b.index;
      });
      console.log("sedmentsReturn", segmentsReturn);
      setSegments(segmentsReturn);
    } catch (err) {
      console.log("error getting segments", err.message);
    }
  }, [industry]);

  const toggleTargeting = React.useCallback(() => {
    setDuplicateModals({
      ...duplicateModals,
      targeting: !duplicateModals.targeting
    });
  }, [duplicateModals]);

  React.useEffect(() => {
    if (duplicateModals.targeting) {
      getSegments();
    }
  }, [duplicateModals, getSegments]);

  const handleSegmentSelect = (e, value) => {
    e.stopPropagation();
    console.log("selected", value);
    setTargetingId(value._id);
    duplicateCampaign();
  };

  return (
    <div>
      <BpModal
        open={duplicateModals.confirm}
        title={"Duplicate Campaign"}
        onCloseMethod={toggleConfirm}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        contentLabel='Duplicate Campaign'
        body={
          <div>
            <p>
              Are you sure you want to duplicate this '{campaign.name}'
              campaign?
            </p>
            {draftCampaigns && draftCampaigns[0]?._id && (
              <UrgentText>
                You currently have another pending draft campaign.
                <br />
                <br />
                This will overwrite your current pending draft if you continue!
              </UrgentText>
            )}
            <Button solid onClick={duplicateCampaign} disabled={loading}>
              Continue
              {loading && <LoaderDotsSmall />}
            </Button>
          </div>
        }
      />
      <BpModal
        open={duplicateModals.targeting}
        title={"Choose Targeting Options"}
        onCloseMethod={toggleTargeting}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        contentLabel='Choose Targeting Options'
        body={
          <div>
            <p>Please select targeting options for the new campaign.</p>
            <CampaignTargetingSelect
              segments={segments}
              handleSegmentSelect={handleSegmentSelect}
            />
            <Button solid onClick={duplicateCampaign} disabled={loading}>
              Continue
              {loading && <LoaderDotsSmall />}
            </Button>
          </div>
        }
      />
    </div>
  );
};

export default CampaignDuplicate;
