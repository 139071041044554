import React from "react";
import * as Sentry from "@sentry/browser";

class ErrorBoundary extends React.Component {
  state = { hasError: false };

  componentDidCatch(error, errorInfo) {
    // Display fallback UI
    this.setState({ hasError: true });
    // Log the error to Sentry
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <h1>Something went wrong. Please refresh the page and try again.</h1>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
