import GeneralSuccess from "./GeneralSuccess";
import React from "react";
import { Elements, StripeProvider } from "react-stripe-elements";
import styled from "styled-components";
import { twilioNumberProvision } from "../../../utils/MessagesAPI";
import {
  getStripeInfo,
  isConnectActive,
  stripeCreateSubscriptions
} from "../../../utils/StripeAPI";
import { Button } from "../../styled/Buttons";
import H3 from "../../styled/H3";
import {
  Dropdown,
  InputBox,
  InputBoxWrapper,
  InputContainer
} from "../../styled/Input";
import { firstBreakpoint } from "../../styled/PhoneNumberProvision";
import CardTitle from "../../styled/TitleCard";
import IntegrationModal from "./IntegrationModal";
import ConfirmPurchase from "./BPConnectSetup/ConfirmPurchase";
import NumberChooser from "./BPConnectSetup/NumberChooser";
import NumberSelect from "./BPConnectSetup/NumberSelect";
import Pricing from "./BPConnectSetup/Pricing";
import PurchaseSuccessful from "./BPConnectSetup/PurchaseSuccessful";
import Start from "./BPConnectSetup/Start";
import GeneralHeader from "./GeneralHeader";
import GeneralStart from "./GeneralStart";
const Container = styled.div`
  padding: ${props => (props.noPadding ? "0" : "1em")};
  height: 100%;
  width: 100%;
`;
const Flex = styled.div`
  display: flex;
`;
const Wrapper = styled.div`
  text-align: left;
  margin: 1em;
`;
const H4 = styled.h4`
  margin: 1em, 1em, 0.2em, 1em;
`;
const TitleIcon = styled.span`
  display: block;
  color: ${props => props.theme.TextC};
  text-align: center;
  font-size: 4rem;
`;
const InputContainerField = styled(InputContainer)`
  margin-bottom: 5px;
`;
const IntegrationIcon = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  img {
    border-radius: 100%;
    margin-right: 10px;
    width: 2em;
  }
  @media only screen and (min-width: 700px) {
    width: 18rem;
    margin: 0 auto 20px;
  }
`;
const ArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  @media only screen and (min-width: 700px) {
    margin-top: 8px;
  }
`;

const ArrowIcon = styled.span`
  transform: rotate(90deg);
  @media only screen and (min-width: 700px) {
    transform: none;
  }
`;

const CancelWrapper = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (min-width: ${firstBreakpoint}px) {
    margin: 50px 70px;
  }
  h2 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
  }
  button {
    margin: 0.8rem 1rem;
    @media screen and (min-width: ${firstBreakpoint}px) {
      margin: 0 1rem;
    }
  }
`;

const steps = [
  {
    name: "Boostpoint Messaging Sign Up",
    id: "start",
    primaryBtnText: "Start",
    showHeader: false
  },
  {
    name: "Select Pricing Plan",
    id: "selectPricing",
    primaryBtnText: "Next",
    showHeader: true
  },
  {
    name: "Phone Number Search",
    id: "numberSearch",
    primaryBtnText: "Search",
    showHeader: true
  },
  {
    name: "Selected Phone Number",
    id: "selectNumber",
    primaryBtnText: "Continue",
    showHeader: false
  },
  {
    name: "Confirm Purchase",
    id: "confirmPurchase",
    primaryBtnText: "Purchase",
    showHeader: false
  },
  {
    name: "Finished",
    id: "done",
    primaryBtnText: "Finish",
    showHeader: false
  }
];

const BoostpointConnectConnect = props => {
  const {
    showModal,
    userProfile: { firstName, lastName },
    userCompany,
    alert
  } = props;
  const country = userCompany.country || "us";

  const [currentStep, setCurrentStep] = React.useState("start");
  const [search, setSearch] = React.useState({
    searchMethod: "areaCode",
    availableNumbers: [],
    selectedNumber: {}
  });
  const [selectedPlan, setSelectedPlan] = React.useState({});
  const [paymentMethod, setPaymentMethod] = React.useState({});
  const [stripeInfo, setStripeInfo] = React.useState({});
  const [isActive, setIsActive] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [modalCancel, setModalCancel] = React.useState(false);
  const [progress, setProgress] = React.useState(
    Math.ceil((1 / steps.length) * 10) * 10
  );

  React.useEffect(() => {
    const i = async () => {
      const stripeResponse = await getStripeInfo();
      setStripeInfo(stripeResponse);
      setIsActive(isConnectActive(stripeResponse.subscriptions));
    };
    i();
  }, [isActive]);

  const next = () => {
    const index = steps.findIndex(s => s.id === currentStep);
    if (currentStep === "done") {
      props.onClose();
      return;
    }
    if (isActive && currentStep === "start") {
      setCurrentStep("numberSearch");
      return;
    }
    if (isActive && currentStep === "selectNumber") {
      confirmNumberWithoutSub();
      return;
    }
    const current = steps.findIndex(s => s.id === currentStep);
    setCurrentStep(steps[current + 1].id);
    setProgress(Math.ceil((index / steps.length) * 10) * 10);
  };

  const back = () => {
    if (currentStep === "start") {
      props.onClose();
      setCurrentStep("start");
      setLoading(false);
      return;
    }
    if (isActive && currentStep === "numberSearch") {
      setCurrentStep("start");
      setLoading(false);
    }
  };

  const goTo = stepItem => {
    setCurrentStep(stepItem);
    setLoading(false);
  };

  const handlePricing = data => {
    const { paymentMethod: newMethod } = data;
    setPaymentMethod(newMethod);
    next();
  };

  const selectNumber = (selectedNumber, nextPage = true) => {
    setSearch({
      ...search,
      selectedNumber
    });
    if (nextPage) {
      next();
    }
  };

  const provisionNumber = async () => {
    try {
      if (!search.selectedNumber) {
        throw new Error("selected number is missing");
      }
      const friendlyName = `${firstName} ${lastName}`;
      const numberResponse = await twilioNumberProvision({
        phone: search.selectedNumber.phoneNumber,
        friendlyName: friendlyName ? friendlyName : userCompany.name
      });
      console.log("number response", numberResponse);
      if (numberResponse) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log("err provisioning number", err.message);
    }
  };

  const setAvailableNumbers = availableNumbers => {
    setSearch({
      ...search,
      availableNumbers
    });
  };

  const confirmSubscription = async () => {
    try {
      setLoading(true);
      const subReturn = await createSubscription();
      if (!subReturn) {
        console.log("subscription failed");
        setCurrentStep("selectPricing");
        setLoading(false);
        return;
      }
      const numberReturn = await provisionNumber();
      console.log("numberReturn", numberReturn);
      if (!numberReturn) {
        console.log("provision number failed");
        return;
      }
      props.companyGet();
      setLoading(false);
      next();
    } catch (err) {
      console.log("error", err.message);
    }
  };

  const createSubscription = async () => {
    const { stripeId } = stripeInfo;
    try {
      const subResponse = await stripeCreateSubscriptions({
        stripeId,
        plan: selectedPlan.planID
      });
      console.log("subResponse", subResponse);
      return subResponse;
    } catch (err) {
      console.log(err.message);
      alert({
        type: "error",
        msg: err.message
      });
      return false;
    }
  };

  const confirmNumberWithoutSub = async () => {
    setLoading(true);
    const numberReturn = await provisionNumber();
    setLoading(false);
    if (!numberReturn) {
      console.log("provision number failed");
      return;
    }
    setCurrentStep("done");
  };

  const onClose = () => {
    if (currentStep === "done") {
      props.onClose();
      return;
    }
    if (!modalCancel) {
      setModalCancel(true);
    } else {
      setModalCancel(false);
      props.onClose();
    }
  };

  return (
    <div>
      <IntegrationModal
        open={showModal}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        contentLabel={steps[currentStep]?.name}
        onCloseMethod={onClose}
        body={
          <Container noPadding>
            {currentStep === "start" && <Start next={next} />}
            {currentStep === "selectPricing" && (
              <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
                <Elements>
                  <Pricing
                    selectedPlan={selectedPlan}
                    selectPlan={setSelectedPlan}
                    stripeId={stripeInfo.stripeId}
                    handlePricing={handlePricing}
                    alert={props.alert}
                    next={next}
                    back={back}
                    progress={progress}
                  />
                </Elements>
              </StripeProvider>
            )}
            {currentStep === "numberSearch" && (
              <NumberChooser
                next={next}
                selectedNumber={search.selectedNumber}
                selectNumber={selectNumber}
                availableNumbers={search.availableNumbers}
                setAvailableNumbers={setAvailableNumbers}
                alert={props.alert}
                progress={progress}
                back={back}
                country={country}
                loading={loading}
              />
            )}
            {currentStep === "selectNumber" && (
              <NumberSelect
                selectedNumber={search.selectedNumber}
                bpConnectActive={isActive}
                next={next}
                back={back}
                loading={loading}
              />
            )}
            {currentStep === "confirmPurchase" && (
              <ConfirmPurchase
                selectedNumber={search.selectedNumber}
                paymentMethod={paymentMethod}
                selectedPlan={selectedPlan}
                loading={loading}
                next={confirmSubscription}
                back={back}
                goTo={goTo}
              />
            )}
            {currentStep === "done" && (
              <PurchaseSuccessful
                close={props.onClose}
                history={props.history}
              />
            )}
          </Container>
        }
        width='auto'
        styleOverride={{
          bodyStyles: {
            maxHeight: "80vh"
          }
        }}
      />
      <IntegrationModal
        open={modalCancel}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        contentLabel={"Are you sure"}
        body={
          <CancelWrapper>
            <h2>Are you sure you want to quit?</h2>
            <p>
              All setup progress on Boostpoint Messaging will be lost and you'll
              need to go through the signup process again.
            </p>
            <div>
              <Button onClick={onClose}>Yes, Exit</Button>
              <Button onClick={() => setModalCancel(false)} solid>
                No, Continue
              </Button>
            </div>
          </CancelWrapper>
        }
      />
    </div>
  );
};

class NullConnect extends React.Component {
  render() {
    const { showModal } = this.props;
    return (
      <IntegrationModal
        open={showModal}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={false}
        onCloseMethod={this.props.onClose}
        title={"Not Yet Implemented"}
        body={
          <Container>
            This integration signup has not been implemented yet. {"\n"}
            Please contact a Boostpoint representative to manually set up this
            integration. Thank you!
          </Container>
        }
        width='auto'
        styleOverride={{
          bodyStyles: {
            maxHeight: "80vh"
          }
        }}
      />
    );
  }
}

class JobNimbusConnect extends React.Component {
  state = {
    step: [
      {
        name: "Job Nimbus Integration",
        id: "start",
        modal: true,
        primaryBtnText: "Start",
        showHeader: false
      },
      {
        name: "Job Nimbus Integration",
        id: "connect",
        modal: true,
        primaryBtnText: "Start",
        showHeader: false
      },
      {
        name: "Job Nimbus Integration",
        id: "field",
        modal: true,
        primaryBtnText: "Start",
        showHeader: false
      },
      {
        name: "Finished",
        id: "done",
        modal: false,
        primaryBtnText: "Finish",
        showHeader: false
      }
    ],
    integration: {
      fieldMappings: [],
      API_Token: "", // the API Token provided by customers JobNimbus setup
      record_type_name: "", // The EXACT workflow name that you would like your leads added to your account under. (This should already be defined in your JobNimbus contact workflow settings)
      status_name: "Active",
      source_name: "",
      integrationType: "JobNimbus"
    },
    loading: false,
    integrationData: {},
    integrationFields: {},
    mappingChanged: false,
    selectedField: {},
    customFields: []
  };

  next = () => {
    console.log("next");
    const step = [...this.state.step];
    const index = step.findIndex(i => i.modal);
    if (index === step.length - 1) {
      step[index].modal = false;
      step[0].modal = true;
      this.props.onClose();
    } else {
      step[index].modal = false;
      step[index + 1].modal = true;
    }
    this.setState({ step });
  };

  back = () => {
    const step = [...this.state.step];
    const index = step.findIndex(i => i.modal);
    if (index === 0) {
      this.props.onClose();
    } else {
      step[index].modal = false;
      step[index - 1].modal = true;
    }
    this.setState({
      step,
      loading: false
    });
  };

  // getCustomFields = async () => {
  //   const { userProfile } = this.props;
  //   let customFields = [];
  //   let customMapping = [];
  //   try {
  //     const fieldCol = await axios.get(
  //       `${BP_NODE_SERVER}/v1/users/customFields?uid=${userProfile.uid}`
  //     );
  //     fieldCol.data.forEach(async doc => {
  //       if (doc.status !== "deleted") {
  //         customFields = [...customFields, doc];
  //         customMapping = [
  //           ...customMapping,
  //           { key: doc.key, label: doc.label, mapping: "" }
  //         ];
  //       }
  //     });
  //     this.setState({ customFields });
  //     return customMapping;
  //   } catch (err) {
  //     console.log("error getting custom Fields", err);
  //   }
  // };

  render() {
    const { showModal, integrationData } = this.props;
    const { step, loading } = this.state;
    const selectedStep = step.filter(i => i.modal);
    const { integrationFields, mappingChanged, customFields, selectedField } =
      this.state;
    return (
      <IntegrationModal
        open={showModal}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        onCloseMethod={this.props.onClose}
        body={
          <Container noPadding>
            {selectedStep[0].id === "start" && (
              <GeneralStart next={this.next} name={"Job Nimbus"} />
            )}
            {selectedStep[0].id === "connect" && (
              <Container>
                <GeneralHeader
                  back={this.back}
                  title={"Set up Job Nimbus"}
                  progress={50}
                  showProgress={true}
                />
                <Wrapper>
                  <H4>API Key</H4>
                  <InputBox placeholder={"API Key"} name={"apiKey"}></InputBox>
                </Wrapper>
                <Wrapper>
                  <H4>Workflow Name</H4>
                  <InputBox
                    placeholder={"Workflow Name"}
                    name={"apiKey"}
                  ></InputBox>
                </Wrapper>
                <Button onClick={this.back}>Back</Button>
                <Button solid onClick={this.next} disabled={loading}>
                  Next
                </Button>
              </Container>
            )}
            {selectedStep[0].id === "field" && (
              <Container>
                <CardTitle>
                  <H3 uppercase>
                    <TitleIcon className='material-icons'>
                      compare_arrows
                    </TitleIcon>
                    Field Connections
                  </H3>
                </CardTitle>
                <Flex>
                  <IntegrationIcon>
                    <img
                      src='https://res.cloudinary.com/equipter/image/upload/c_scale,h_28/v1522846032/SolidLogo.png'
                      alt='Boostpoint logo'
                      title='Boostpoint'
                    />
                    <br />
                    <strong>Boostpoint Field</strong>
                  </IntegrationIcon>
                  <IntegrationIcon>
                    <img
                      src={`https://res.cloudinary.com/equipter/image/upload/c_scale,h_28/v1592316065/Boostpoint_Images/${integrationData.icon}`}
                      alt={integrationData.nameReadable}
                      title={integrationData.nameReadable}
                    />
                    <br />
                    <strong>{integrationData.nameReadable} Field</strong>
                  </IntegrationIcon>
                </Flex>
                <Flex>
                  <InputContainerField>
                    <InputBoxWrapper>
                      <Dropdown
                        type='text'
                        name='fieldSelector'
                        data-namereadable={selectedField.label}
                        value={selectedField.key}
                        onChange={input => this.selectField(input)}
                      >
                        <option>Select One</option>
                        {customFields.map(field => (
                          <option key={field.key} value={field.key}>
                            {field.label}
                          </option>
                        ))}
                      </Dropdown>
                    </InputBoxWrapper>
                  </InputContainerField>
                  <ArrowContainer>
                    <ArrowIcon className='material-icons'>
                      arrow_right_alt
                    </ArrowIcon>
                  </ArrowContainer>
                  <InputContainerField>
                    <InputBoxWrapper>
                      <Dropdown
                        type='text'
                        name={selectedField.key}
                        data-namereadable={selectedField.label}
                        value={selectedField.mapping}
                        onChange={input => this.changeField(input)}
                      >
                        <option>Select One</option>
                        {Object.keys(integrationFields).map(
                          (integrationM, key) => (
                            <option key={key} value={integrationM}>
                              {integrationFields[integrationM]}
                            </option>
                          )
                        )}
                      </Dropdown>
                    </InputBoxWrapper>
                  </InputContainerField>
                </Flex>
                {!!mappingChanged && (
                  <div>
                    <Button onClick={this.clearMapping}>
                      Cancel Mapping Changes
                    </Button>
                    <Button solid onClick={this.saveMapping}>
                      Save Mapping
                    </Button>
                  </div>
                )}
              </Container>
            )}
            {selectedStep[0].id === "done" && (
              <GeneralSuccess name={"Job Nimbus"} close={this.props.onClose} />
            )}
          </Container>
        }
        width='auto'
        styleOverride={{
          bodyStyles: {
            maxHeight: "80vh"
          }
        }}
      />
    );
  }
}

export { BoostpointConnectConnect, JobNimbusConnect, NullConnect };
