import React from "react";
import { auth } from "../../../firebase/firebase";
import styled from "styled-components";
import H3 from "../../styled/H3";
// Styled Components
import { Button } from "../../styled/Buttons";
import Card from "../../styled/Card";
import InputError from "../../styled/InputErrorForm";
import {
  InputBox,
  InputContainer,
  InputLabel,
  InputBoxWrapper
} from "../../styled/Input";
import { Title } from "../../styled/Headers";

const InputInfo = styled.span`
  font-size: 13px;
`;

class PasswordReset extends React.Component {
  state = {
    email: "",
    errMessage: ""
  };

  handleEmailChange = input => {
    this.setState({
      email: input.target.value
    });
  };

  handleResetSubmit = () => {
    auth
      .sendPasswordResetEmail(this.state.email)
      .then(() => {
        // Email sent.
        this.setState({
          errMessage: "",
          infoMessage:
            "A password reset email has been sent. Please check your email and follow the instructions. When complete, you can return to the login page to log into your account."
        });
      })
      .catch(err => {
        // An error happened.
        // console.error(err.message)
        if (err.message === `The email address is badly formatted.`) {
          this.setState({
            infoMessage: "",
            errMessage: "Please enter a valid email address"
          });
        }
        if (
          err.message ===
          `There is no user record corresponding to this identifier. The user may have been deleted.`
        ) {
          this.setState({
            infoMessage: "",
            errMessage: "This email address does not exist!"
          });
        }
      });
  };

  render() {
    return (
      <Card>
        <Title>Forgot Your Password?</Title>
        <p style={{ maxWidth: "30em", margin: "2em auto" }}>
          To reset your password, enter your email address in the box below and
          then click on the 'Reset Password' button.
        </p>
        <InputContainer customWidth={"25rem"}>
          <InputBox
            value={this.state.email}
            onChange={this.handleEmailChange}
            type='email'
            placeholder='Email Address'
          />
        </InputContainer>
        <div>
          {/* Success info message for this input */}
          <InputInfo>{this.state.infoMessage}</InputInfo>
          {/* Error message for this input */}
          <InputError>{this.state.errMessage}</InputError>
        </div>
        <Button solid onClick={this.handleResetSubmit}>
          <span>Reset</span>
        </Button>
      </Card>
    );
  }
}

export default PasswordReset;
