import React from "react";
import styled from "styled-components";
import { Button } from "../../../styled/Buttons";

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  margin: 50px 60px 80px;
  a {
    color: ${props => props.theme.HC1};
    text-decoration: underline;
    font-style: italic;
  }
`;

const Icon = styled.span`
  color: ${props => props.theme.HC1};
  font-size: 4em;
  display: block;
`;

const Header = styled.h1`
  font-size: 1.2em;
  text-transform: uppercase;
  margin: 1em 1em 2em;
`;

const Start = props => (
  <Container>
    <Wrapper>
      <Icon className='material-icons'>chat</Icon>
      <Header>Set up {props.name} integration</Header>
      <p>
        Are you ready to connect your {props.name} account with Boostpoint?
        Click Start to get started.
      </p>
      <Button onClick={props.next} solid>
        Start
      </Button>
    </Wrapper>
  </Container>
);

export default Start;
