import {
  campaignSubmitInit,
  campaignUpdate,
  leadFormSubmit,
  targetingGetById
} from "../../../utils/FacebookAPI";

export const HelperSubmit = async data => {
  const { pendingCampaign, formName, businessPage } = data;
  const { targetingId, form, endDate, _id: campaignId } = pendingCampaign;
  const endDateObject = new Date(endDate);
  if (endDateObject < new Date()) {
    throw new Error(
      "The end date needs to be in the future. Go back and choose and new end date."
    );
  }

  const { pageId } = businessPage;
  //get targeting spec
  const segment = await targetingGetById(targetingId);
  console.log("segment", segment);
  const { specialAdCategories = ["NONE"], addresses = [] } = segment;
  const allRadius = addresses.map(a => a.radius);
  const minimumRadius = Math.min(...allRadius);
  console.log("minimum radius", minimumRadius, specialAdCategories);
  if (!specialAdCategories.includes("NONE") && minimumRadius < 15) {
    throw new Error(
      "This type of ad cannot have an area radius of less than 15 miles. Please update the radius to be 15 miles or more and try again."
    );
  }

  const campaignSubmit = {
    campaignId
  };
  try {
    if (pendingCampaign.type === "LeadAd") {
      if (!form) {
        console.log("Missing data ", form);
        return "Missing data";
      }
      const thankYouButtonText =
        form.thankyouButtonTxt === "call" ? "Call Business" : "Visit Website";
      const {
        fields,
        headline: formHeadline,
        description: formDescription,
        questionTitle: formTitle,
        privacyPolicyUrl,
        thankyouTitle: thankYouTitle,
        thankyouBody: thankYouMessage,
        thankyouURL: thankYouButtonDest,
        thankyouPhone: thankYouBusinessPhone
      } = form;
      const questions = fields.map(field =>
        field.type === "CUSTOM"
          ? { type: "CUSTOM", label: field.name }
          : { type: field.type }
      );
      const submitForm = {
        pageId,
        questions,
        formHeadline,
        formDescription,
        formTitle,
        formName,
        privacyPolicyUrl,
        thankYouTitle,
        thankYouMessage,
        thankYouButtonText,
        thankYouButtonDest,
        thankYouBusinessPhone,
        leadFormOnly: true,
        type: pendingCampaign.type
      };

      const formResponse = await leadFormSubmit(submitForm);
      await campaignUpdate({
        ...pendingCampaign,
        form: { ...form, leadFormId: formResponse }
      });
    }

    const submitResponse = await campaignSubmitInit(campaignSubmit);
    console.log("submitResponse", submitResponse);
    return submitResponse;
  } catch (err) {
    console.log("err", err.message);
    throw new Error(err.message);
    // if (err.response && err.response.type === 'validation') {
    //   // TODO: change this to send the array of errors over and we will deal with all the errors on the front end
    //   throw new Error({
    //     error_user_msg:
    //       'Not all campaigns fields passed validation. Please check your field inputs and try again.'
    //   });
    // } else if (err.response.data && err.response.data.error_user_msg) {
    //   throw new Error(err.response.data.error_user_msg);
    // } else if (err.response.data && err.response.data.message) {
    //   throw new Error(err.response.data.message);
    // } else {
    //   throw new Error(err.response.data);
    // }
  }
};
