import React from "react";
import styled from "styled-components";
import * as _ from "underscore";
import WorkflowList from "./WorkflowList";
import {
  createNewWorkflow,
  getAllCompanyWorkflows,
  deleteWorkflowById
} from "../../../utils/WorkflowAPI";
import { getUserById } from "../../../utils/UserAPI";
import { LoaderDotsSmall } from "../../elements/Loaders";

import { Button } from "../../styled/Buttons";
import WorkflowModal from "./WorkflowModal";
import NoConnectModal from "../../NoConnectModal";
import { getStripeInfo, isConnectActive } from "../../../utils/StripeAPI";

const UrgentText = styled.p`
  font-weight: 600;
  font-style: italic;
`;

class Workflows extends React.Component {
  headerRef = React.createRef();
  state = {
    workflows: [],
    filteredWorkflows: [],
    workflowUsers: [],
    selectAll: false,
    selectedWorkflows: [],
    selectedWorkflowCount: 0,
    loading: true,
    modalDeleteConfirm: false,
    deleteWorkflowID: "",
    deleteWorkflowName: "",
    deleting: false,
    showMoreOptions: false,
    modalDeleteConfirmMultiple: false,
    headerFixed: false,
    noConnect: false
  };

  componentDidMount = async () => {
    const workflows = await this.getWorkflows();
    workflows.sort(a => (a.status === "draft" ? -1 : 1));
    const stripeInfo = await getStripeInfo();
    const connectActive = isConnectActive(stripeInfo.subscriptions);
    this.setState(
      {
        workflows,
        filteredWorkflows: workflows,
        noConnect: !connectActive,
        loading: false
      },
      () => this.getWorkflowUsers(workflows)
    );
  };

  componentDidUpdate = prevProps => {
    const { userCompany } = this.props;
    if (!_.isEqual(userCompany, prevProps.userCompany)) {
      this.setState({
        noConnect: !userCompany.bpConnectActive
      });
    }
  };

  throttleScroll = () => {
    const { userCompany } = this.props;
    _.debounce(this.onScroll, 100);
    this.setState({ noConnect: !userCompany.bpConnectActive });
  };

  checkExistingName = name => {
    const { workflows } = this.state;
    const exists = workflows.some(workflow => workflow.name === name);
    return exists;
  };

  createWorkflow = async () => {
    const name = "Untitled Workflow";
    let newName = "Untitled Workflow";
    let check = true;
    let number = 0;
    do {
      check = this.checkExistingName(newName);
      if (check) {
        number = number + 1;
        newName = `${name} (${number})`;
      }
    } while (check);
    try {
      const newWorkflow = await createNewWorkflow({ name: newName });
      const { _id } = newWorkflow;
      this.props.history.push(`/workflows/${_id}`);
    } catch (err) {
      console.log(err);
      // throw new Error('error getting integrations', err.message);
    }
  };

  duplicateWorkflow = async workflowID => {
    const { workflows } = this.state;
    const workflowToDupe = workflows.filter(
      workflow => workflow._id === workflowID
    );
    if (workflowToDupe.length === 1) {
      const newWorkflow = workflowToDupe[0];
      const oldName = newWorkflow.name;
      delete newWorkflow._id;
      delete newWorkflow.companyId;
      delete newWorkflow.createdDate;
      delete newWorkflow.modifiedDate;
      delete newWorkflow.createdBy;
      newWorkflow.status = "DRAFT";
      let { name = "Untitled Workflow" } = newWorkflow;
      name = `${name} (copy)`;
      let newName = name;
      let check = true;
      let number = 0;
      do {
        check = this.checkExistingName(newName);
        if (check) {
          number = number + 1;
          newName = `${name} (${number})`;
        }
      } while (check);
      const workflowWithName = { ...newWorkflow, name: newName };
      try {
        const workflowResponse = await createNewWorkflow(workflowWithName);
        const { _id } = workflowResponse;
        this.props.alert({
          type: "info",
          msg: `Successfully Copied from '${oldName}'`
        });
        this.props.history.push(`/workflows/${_id}`);
      } catch (err) {
        console.log(err);
        // throw new Error('error getting integrations', err.message);
      }
    }
  };

  getWorkflows = async () => {
    try {
      const workflows = await getAllCompanyWorkflows();
      return workflows;
    } catch (err) {
      console.log(err);
      return [];
      // throw new Error('error getting integrations', err.message);
    }
  };

  getWorkflowUsers = workflows => {
    try {
      const userIds = workflows
        .map(w => w.createdBy)
        .filter((v, i, a) => a.indexOf(v) === i);
      if (userIds.length < 1) {
        return;
      }
      const userList = userIds.map(userId => getUserById(userId));
      Promise.all(userList).then(values => {
        const workflowsUpdated = workflows.map(w => {
          const wUser = values.find(u => w.createdBy === u._id);
          if (wUser) {
            w.user = wUser;
          }
          return w;
        });
        this.setState({
          workflowUsers: values,
          workflows: workflowsUpdated,
          filteredWorkflows: workflowsUpdated
        });
      });
    } catch (err) {
      console.log("err getting workflow users", err.message);
    }
  };

  onScroll = () => {
    console.log("scrolling");
    const header = this.headerRef.current.getBoundingClientRect();
    console.log("header", header);
  };

  toggleWorkflowSelect = workflowID => {
    const { selectedWorkflows, selectAll, workflows } = this.state;
    let newSelectedWorkflows = [];
    if (selectAll) {
      newSelectedWorkflows = workflows.map(flow => flow.workflowID);
      newSelectedWorkflows = newSelectedWorkflows.filter(
        flow => flow !== workflowID
      );
    } else if (selectedWorkflows.includes(workflowID)) {
      newSelectedWorkflows = selectedWorkflows.filter(
        flow => flow !== workflowID
      );
    } else {
      newSelectedWorkflows = [...selectedWorkflows, workflowID];
    }
    const selectedWorkflowCount = newSelectedWorkflows.length;
    this.setState({
      selectedWorkflows: newSelectedWorkflows,
      selectedWorkflowCount,
      selectAll: false
    });
  };

  toggleWorkflowSelectAll = () => {
    const { selectAll } = this.state;
    this.setState({ selectAll: !selectAll, selectedWorkflows: [] });
  };

  openDeleteConfirm = workflowId => {
    const { workflows } = this.state;
    console.log(workflowId);
    console.log(workflows);
    const deletingWorkflow = workflows.find(
      workflow => workflow._id === workflowId
    );
    const { name } = deletingWorkflow;
    this.setState({
      deleteWorkflowID: workflowId,
      deleteWorkflowName: name,
      modalDeleteConfirm: true
    });
  };

  deleteWorkflow = async () => {
    const { workflows, deleteWorkflowID, workflowUsers } = this.state;
    const workflow = workflows.find(item => item._id === deleteWorkflowID);
    const { name } = workflow;
    try {
      this.setState({ deleting: true });
      await deleteWorkflowById(deleteWorkflowID);
      const newWorkflows = await this.getWorkflows();
      newWorkflows.sort(a => (a.status === "draft" ? -1 : 1));
      const workflowsUpdated = newWorkflows.map(w => {
        const wUser = workflowUsers.find(u => w.createdBy === u._id);
        if (wUser) {
          w.user = wUser;
        }
        return w;
      });
      this.setState({
        workflows: workflowsUpdated,
        filteredWorkflows: workflowsUpdated,
        modalDeleteConfirm: false,
        selectedWorkflows: [],
        selectedWorkflowCount: 0,
        deleting: false
      });
      this.props.alert({ type: "info", msg: `Deleted Workflow '${name}'` });
    } catch (err) {
      console.log("error deleting workflow", err);
    }
  };

  cancelDelete = () => {
    this.setState({
      deleteWorkflowID: "",
      modalDeleteConfirm: false,
      modalDeleteConfirmMultiple: false
    });
  };

  deleteMultiple = async () => {
    const { modalDeleteConfirmMultiple, selectedWorkflows, workflowUsers } =
      this.state;
    if (!modalDeleteConfirmMultiple) {
      this.setState({
        modalDeleteConfirmMultiple: true,
        showMoreOptions: false
      });
    } else {
      this.setState({ deleting: true });
      const deleting = [];
      console.log("deleting selectedWorkflows: ", selectedWorkflows);
      selectedWorkflows.forEach(workflowId => {
        deleting.push(deleteWorkflowById(workflowId));
      });
      await Promise.all(deleting);
      this.props.alert({ type: "info", msg: "Workflows deleted" });

      const workflows = await this.getWorkflows();
      workflows.sort(a => (a.status === "draft" ? -1 : 1));
      const workflowsUpdated = workflows.map(w => {
        const wUser = workflowUsers.find(u => w.createdBy === u._id);
        if (wUser) {
          w.user = wUser;
        }
        return w;
      });
      this.setState({
        workflows: workflowsUpdated,
        filteredWorkflows: workflowsUpdated,
        modalDeleteConfirmMultiple: false,
        deleting: false,
        selectedWorkflows: [],
        selectedWorkflowCount: 0
      });
    }
  };

  search = searchValue => {
    const { workflows } = this.state;
    let filteredWorkflows = [];
    filteredWorkflows = workflows.filter(flow => {
      const flowName = flow.name.trim().toLowerCase();
      if (flowName.includes(searchValue.trim().toLowerCase())) {
        return true;
      } else {
        return false;
      }
    });
    this.setState({ filteredWorkflows });
  };

  toggleActions = () => {
    const { showMoreOptions } = this.state;
    this.setState({ showMoreOptions: !showMoreOptions });
  };

  setFilteredWorkflows = workflows => {
    if (workflows === "reset") {
      this.setState({ filteredWorkflows: this.state.workflows });
    } else {
      this.setState({ filteredWorkflows: workflows });
    }
  };

  render() {
    const {
      filteredWorkflows,
      workflowUsers,
      selectAll,
      selectedWorkflows,
      selectedWorkflowCount,
      modalDeleteConfirm,
      deleting,
      modalDeleteConfirmMultiple,
      loading,
      workflows
    } = this.state;
    const { timezone = "America/New_York" } = this.props.userProfile;

    return (
      <div>
        <NoConnectModal
          showModal={this.state.noConnect}
          history={this.props.history}
        ></NoConnectModal>
        <WorkflowList
          workflowList={filteredWorkflows}
          workflowUsers={workflowUsers}
          toggleWorkflowSelect={this.toggleWorkflowSelect}
          toggleWorkflowSelectAll={this.toggleWorkflowSelectAll}
          selectAll={selectAll}
          selectedWorkflows={selectedWorkflows}
          cancelMultipleSelection={this.cancelMultipleSelection}
          openDeleteConfirm={this.openDeleteConfirm}
          duplicateWorkflow={this.duplicateWorkflow}
          history={this.props.history}
          timezone={timezone}
          search={this.search}
          selectedWorkflowCount={selectedWorkflowCount}
          setFilteredWorkflows={this.setFilteredWorkflows}
          workflowsCount={workflows.length}
          filteredWorkflowsCount={filteredWorkflows.length}
          createWorkflow={this.createWorkflow}
          deleteMultiple={this.deleteMultiple}
        />
        <WorkflowModal
          open={modalDeleteConfirm}
          title='Delete your workflow'
          onCloseMethod={() => this.cancelDelete()}
          body={
            <div>
              <p>
                Are you sure you want to delete the workflow "
                {this.state.deleteWorkflowName}"?
              </p>
              <UrgentText>This action can't be undone!</UrgentText>
              <Button onClick={this.cancelDelete} disabled={deleting}>
                Cancel
              </Button>
              <Button
                solid
                onClick={() => this.deleteWorkflow()}
                disabled={deleting}
              >
                Delete
                {deleting && <LoaderDotsSmall />}
              </Button>
            </div>
          }
        />
        <WorkflowModal
          open={modalDeleteConfirmMultiple}
          title='Delete your workflows'
          onCloseMethod={() => this.cancelDelete()}
          body={
            <div>
              <p>
                Are you sure you want to delete the {selectedWorkflowCount}{" "}
                selected workflows?
              </p>
              <UrgentText>This action can't be undone!</UrgentText>
              <Button onClick={this.cancelDelete} disabled={deleting}>
                Cancel
              </Button>
              <Button
                solid
                onClick={() => this.deleteMultiple()}
                disabled={deleting}
              >
                Delete
                {deleting && <LoaderDotsSmall />}
              </Button>
            </div>
          }
        />
      </div>
    );
  }
}

export default Workflows;
