import React from "react";
import styled from "styled-components";
import BpModal from "../../../elements/BpModal";
import {
  Dropdown,
  InputBox,
  InputBoxWrapper,
  InputLabel
} from "../../../styled/Input";
import { statusCreate } from "../../../../utils/ContactAPI";

const AddIcon = styled.p`
  margin: 0;
  font-size: 2em;
  color: ${props => props.theme.HC1};
  cursor: pointer;
`;

const ContactBoxWrapper = styled(InputBoxWrapper)`
  display: grid;
  grid-template-columns: 118px 1fr;
  margin: 20px;
  input {
    width: 18rem;
  }
`;

const ContactLabel = styled(InputLabel)`
  display: flex;
  align-items: center;
`;

const StatusAddModal = props => {
  const {
    loadingLeads,
    userCompany: { _id: companyId, industry } = {},
    orderNumber
  } = props;
  const [modalStatusAdd, setModalStatusAdd] = React.useState(false);
  const [statusLabel, setStatusLabel] = React.useState("");
  const [statusType, setStatusType] = React.useState("RECRUITING");
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (industry) {
      switch (industry) {
        case "SENIOR_CARE":
        case "MANUFACTURING":
        case "FOOD_SERVICE":
        case "OTHER":
          setStatusType("RECRUITING");
          break;
        default:
          setStatusType("CUSTOMER");
      }
    }
  }, [industry]);

  const saveStatus = async () => {
    setLoading(true);
    const newStatus = {
      status: statusLabel.trim(),
      orderNumber,
      isStock: false,
      companyId,
      isDefault: false,
      industry,
      type: statusType
    };
    const updatedStatus = await statusCreate(newStatus);
    setModalStatusAdd(false);
    props.statusAdd(updatedStatus);
    setStatusLabel('');
    setLoading(false);
  };

  return (
    <div>
      <AddIcon
        className='material-icons'
        onClick={() => setModalStatusAdd(true)}
      >
        add_circle
      </AddIcon>
      <p onClick={() => setModalStatusAdd(true)}>Add New Status</p>
      <BpModal
        open={modalStatusAdd}
        title={`Add status`}
        primaryAction={{
          btnLabel: "Save",
          action: saveStatus,
          loading: loadingLeads || loading
        }}
        secondaryAction={{
          btnLabel: 'Cancel',
          action: () => setModalStatusAdd(false),
          loading: loadingLeads || loading
        }}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        contentLabel='Add status'
        body={
          <div>
            <ContactBoxWrapper>
              <ContactLabel>Status Label</ContactLabel>
              <InputBox
                type='text'
                required
                value={statusLabel}
                onChange={input => setStatusLabel(input.target.value)}
              />
            </ContactBoxWrapper>
            <ContactBoxWrapper>
              <ContactLabel>Status Type</ContactLabel>
              <Dropdown
                value={statusType}
                onChange={input => setStatusType(input.target.value)}
              >
                <option value='RECRUITING'>Recruiting Campaigns</option>
                <option value='CUSTOMER'>New Customer Campaigns</option>
              </Dropdown>
            </ContactBoxWrapper>
          </div>
        }
        width='auto'
      />
    </div>
  );
};

export default StatusAddModal;
