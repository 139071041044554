import moment from "moment-timezone";
import React from "react";

const TimeDisplay = props => {
  const { timezone: zone = "America/New_York" } = props;
  let timezone = zone;
  if (!timezone) {
    timezone = "America/New_York";
  }
  const formatDate = () => {
    const createdMoment = moment(new Date()).tz(timezone);
    return createdMoment.format("MMM DD, YYYY h:mm A z");
  };

  const [currentTime, setCurrentTime] = React.useState(formatDate);

  React.useEffect(() => {
    const interval = setInterval(() => {
      const createdMoment = moment(new Date()).tz(timezone);
      setCurrentTime(createdMoment.format("MMM DD, YYYY h:mm A z"));
    }, 1000);
    return () => clearInterval(interval);
  }, [timezone]);

  return <span>{currentTime}</span>;
};

export default TimeDisplay;
