import axios from "axios";
import { axiosResponse, errorHandler } from "./Helpers";

const BP_API = process.env.REACT_APP_BP_API;

export const notificationsGet = async () => {
  try {
    const notifications = axiosResponse(
      await axios.get(`${BP_API}/v1/notification/settings/company`)
    );
    return notifications;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

// Notification Types
// LEAD
// MESSAGE
// BILLING
// CAMPAIGN
// INTEGRATION
// WORKFLOW
export const notificationCreate = async notificationDoc => {
  const newNotification = {
    companyId: "",
    smsActive: false,
    emailActive: true,
    appActive: true,
    notificationType: "",
    smsContacts: [],
    emailContacts: [],
    isActive: true
  };
  try {
    const notifications = axiosResponse(
      await axios.post(`${BP_API}/v1/notification/settings`, {
        ...newNotification,
        ...notificationDoc
      })
    );
    return notifications;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const notificationUpdate = async notificationDoc => {
  try {
    const notifications = axiosResponse(
      await axios.put(`${BP_API}/v1/notification/settings`, notificationDoc)
    );
    return notifications;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const notificationDelete = async notificationId => {
  try {
    const notifications = axiosResponse(
      await axios.delete(`${BP_API}/v1/notification/settings/${notificationId}`)
    );
    return notifications;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};

export const emailSendBasic = async body => {
  try {
    console.log("boty", body);
    const notifications = axiosResponse(
      await axios.post(`${BP_API}/v1/notification/email/basic`, body)
    );
    return notifications;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(message);
  }
};
