import styled from "styled-components";

const TemplateNameInput = styled.input`
  display: block;
  margin: 1em auto;
  width: 100%;
  max-width: 20rem;
`;

export default TemplateNameInput;
