import React from "react";
import { HorizontalBar } from "react-chartjs-2";
import styled from "styled-components";

const Module = styled.div`
  /* width: 100%; */
  margin: 0 30px 30px;
  height: 100%;
  min-height: 0;
  position: relative;
`;

const ChartNoData = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  width: 30em;
  max-width: 100%;
  height: 7em;
  margin: 4em auto;
  text-align: center;
  font-size: 14px;
  background: url("../images/sample_chart_green_half.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.75);
  background-blend-mode: lighten;
`;
const ChartTitle = styled.span`
  font-weight: 600;
  width: 100%;
  text-transform: uppercase;
`;

const ChartWrapper = styled.div`
  max-height: 292px;
  height: 100%;
  position: relative;
`;

class BarChart extends React.Component {
  render() {
    const {
      labelValue = [],
      dataValue = [],
      backgroundColor,
      borderColor
    } = this.props;

    const data = {
      labels: labelValue,
      datasets: [
        {
          label: "Contact Count",
          backgroundColor: backgroundColor,
          borderColor: borderColor,
          borderWidth: 1,
          data: dataValue
        }
      ]
    };
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        callbacks: {
          label: (tooltipItem, items) => {
            const index = tooltipItem.datasetIndex;
            return `${index > 0 ? " Previous " : ""}${tooltipItem.label}: ${
              tooltipItem.value
            }`;
          }
        }
      },
      elements: {
        bar: {
          borderWidth: 1
        }
      },
      legend: {
        display: false
      }
    };
    if (this.props.aspectRatio) {
      options.maintainAspectRatio = true;
      options.aspectRatio = this.props.aspectRatio;
    }

    return (
      <Module>
        {dataValue.length > 0 ? (
          <ChartWrapper className="chart-container">
            <HorizontalBar data={data} options={options} />
          </ChartWrapper>
        ) : (
          <ChartNoData className='not-enough-data'>
            <ChartTitle>Not Enough Data</ChartTitle>
            <span>
              Please select another date range or start by running a new
              campaign.
            </span>
          </ChartNoData>
        )}
      </Module>
    );
  }
}

export default BarChart;
