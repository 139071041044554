import React from "react";
import { withRouter } from "react-router-dom";
import { auth } from "../../firebase/firebase";
import { userLogout } from "../../utils/UserAPI";

//style import
import { LoaderDotsSmall } from "../elements/Loaders";
import { Button } from "../styled/Buttons";

class SignOutText extends React.Component {
  state = { logoutStarted: false };

  signOutProcess = () => {
    this.setState({ logoutStarted: true });
    userLogout().then(() => {
      auth
        .signOut()
        .then(() => {
          this.props.clearDataOnLogout();
          this.props.toggleSlideOutMenu(false);
          this.props.toggleMenu();
        })
        .then(() => {
          this.props.history.push(`/login`);
          this.setState({ logoutStarted: false });
        });
    });
  };

  render() {
    const { logoutStarted } = this.state;

    return (
      <Button
        disabled={logoutStarted}
        onClick={() => this.signOutProcess()}
        title='Log Out'
      >
        Log Out {logoutStarted && <LoaderDotsSmall />}
      </Button>
    );
  }
}

export default withRouter(SignOutText);
