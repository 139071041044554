import React from "react";
import styled from "styled-components";
import { LoaderDotsSmall } from "../../../elements/Loaders";
import { Button } from "../../../styled/Buttons";
import { firstBreakpoint } from "../../../styled/PhoneNumberProvision";

const NumberResultsWrapper = styled.div`
  display: ${props => (props.showResults ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  @media screen and (min-width: ${firstBreakpoint}px) {
    width: auto;
    padding-left: 40px;
    display: flex;
    border-left: 2px solid ${props => props.theme.ATextC};
  }
  /* .loader {
    min-height: 21px;
  } */
`;

const AvailableList = styled.ul`
  width: 100%;
  overflow-y: scroll;
  max-height: 500px;
`;

const AvailableNum = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.ATextC};
  padding: 10px 20px 6px;
  background: ${props =>
    props.selected ? "rgba(103,93,168,.2)" : "transparent"};
  @media screen and (min-width: ${firstBreakpoint}px) {
    padding: 10px 0 6px;
    background: transparent;
  }
`;

const AvailableTxt = styled.p`
  margin: 0;
  span {
    display: block;
  }
`;

const Loading = styled.div`
  position: absolute;
`;

const SelectButton = styled(Button)`
  margin: 0.5rem;
  @media screen and (min-width: ${firstBreakpoint}px) {
    margin: 1rem;
  }
`;

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
};

const NumberResults = props => {
  const {
    loading,
    availableNumbers,
    selectNumber,
    numbersNotFound,
    notValid,
    showResults,
    selectedNumber
  } = props;
  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );
  return (
    <NumberResultsWrapper showResults={showResults}>
      {!!numbersNotFound && (
        <p>
          No numbers were returned with this search. Please try a different
          search option or search value.
        </p>
      )}
      {!!notValid && <p>Please enter a search option and a search value.</p>}
      {!!loading && (
        <Loading>
          <LoaderDotsSmall />
        </Loading>
      )}
      {!notValid && !numbersNotFound && (
        <AvailableList>
          {availableNumbers.map(available => (
            <AvailableNum
              key={available.phoneNumber}
              onClick={() =>
                selectNumber(
                  available,
                  windowDimensions.width >= parseInt(firstBreakpoint)
                )
              }
              selected={selectedNumber.phoneNumber === available.phoneNumber}
            >
              <span className='material-icons'>smartphone</span>
              <AvailableTxt>
                <span>
                  <strong>{available.friendlyName}</strong>
                </span>
                <span>
                  {available.locality}, {available.region}
                </span>
              </AvailableTxt>
              <SelectButton
                small
                solid={
                  selectedNumber.phoneNumber === available.phoneNumber
                    ? true
                    : false
                }
              >
                {selectedNumber.phoneNumber === available.phoneNumber
                  ? "Continue"
                  : "Select"}
              </SelectButton>
            </AvailableNum>
          ))}
        </AvailableList>
      )}
    </NumberResultsWrapper>
  );
};

export default NumberResults;
