import React from "react";
import styled from "styled-components";
import Icon from "../../elements/Icon";

import media from "../../styled/MediaQueries";

const DraggableWrapper = styled.div`
  max-width: 500px;
  margin: 2.5em auto;
  .droppable {
    margin: 20px;
    min-height: 50px;
    border: 2px solid transparent;
    ${media.phone`
      margin: 5px;
    `}
  }
  .hoverOn {
    /* background-color: #f4f4f4; */
    border: dashed 2px #a9a7a7;
    border-image: url("/dotted-border.png") 2 round;
    .draggable .drag-indicator {
      color: transparent;
    }
  }
  .sortable-chosen {
    border: 2px solid #675da8;
    /* grid-template-columns: 1fr; */
    min-height: auto;
    input {
      display: none;
    }
    .icon-wrapper {
      i {
        color: transparent !important;
      }
    }
    label {
      font-size: 18px;
      font-weight: bold;
    }
    .field-wrapper {
      width: auto;
      text-align: center;
    }
  }
  .sortable-ghost {
    box-shadow: none;
    /* grid-template-columns: 1fr; */
    min-height: auto;
    border-top: dashed 2px #675da8;
    border-image: url("/dotted-border-purple.png") 2 round;
    margin: 18px 30px;
    height: 0px;
    overflow: hidden;
    input {
      visibility: hidden;
    }
    .icon-wrapper {
      i {
        color: transparent;
      }
    }
    label {
      font-size: 18px;
      font-weight: bold;
    }
    .field-wrapper {
      width: auto;
      text-align: center;
      padding: 10px;
      min-width: auto;
    }
  }
`;

const SortableItem = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr 95px;
  border-bottom: 1px solid #e5e5e5;
  &:hover {
    outline: 1px solid #f4f4f4;
  }
  &:hover .drag-indicator {
    color: #e5e5e5;
  }
  &:first-child {
    border-top: 1px solid #e5e5e5;
  }
  &:first-child .arrow-up {
    color: transparent;
  }
  &:last-child {
    border-bottom: none;
  }
  &:last-child .arrow-down {
    color: transparent;
  }
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconWrapper = styled.div`
  height: 100%;
  cursor: pointer;
  text-align: right;
  margin-right: 5px;
  display: flex;
  align-items: center;
`;

const SortIconWrapper = styled.div`
  display: grid;
`;

const DefaultIcon = styled.i`
  color: ${props => (props.default ? "gray" : "#e5e5e5")};
  cursor: pointer;
  padding: 2px 4px;
  &:hover {
    color: gray;
  }
`;

const Type = styled.p`
  font-weight: bold;
  &.status-type {
    margin: 1em 0 1.2em;
    text-align: left;
  }
`;

const StatusDraggable = props => {
  const { sortableItems } = props;

  const [statuses, setStatuses] = React.useState({});

  React.useEffect(() => {
    if (sortableItems && sortableItems.length > 0) {
      const newStatus = {};
      sortableItems.forEach(c => {
        if (!newStatus[c.type]) {
          newStatus[c.type] = [c];
        } else {
          newStatus[c.type].push(c);
        }
      });
      setStatuses(newStatus);
    }
  }, [sortableItems]);

  return (
    <div>
      <DraggableWrapper id='fields-wrapper'>
        {Object.keys(statuses).map(type => (
          <div key={type}>
            <Type className='status-type'>{type}</Type>
            {statuses[type].map((item, index) => (
              <SortableItem
                key={item._id}
                data-id={item._id}
                className='draggable'
              >
                <SortIconWrapper className='icon-wrapper' position='left'>
                  <Icon
                    className={["arrow-up"]}
                    onClick={() =>
                      props.handleSortChange(index, index - 1, item._id)
                    }
                    position='top'
                    icon='keyboard_arrow_up'
                    fontSize='24px'
                  />
                  <Icon
                    className={["arrow-down"]}
                    onClick={() =>
                      props.handleSortChange(index, index + 1, item._id)
                    }
                    position='bottom'
                    icon='keyboard_arrow_down'
                    fontSize='24px'
                  />
                </SortIconWrapper>
                <Label>
                  <span>{item.status}</span>
                </Label>
                <IconWrapper className='icon-wrapper' position='right'>
                  <DefaultIcon
                    className='material-icons'
                    title={item.isDefault ? "Default" : "Make Default"}
                    onClick={() => props.handleChangeDefault(item._id)}
                    default={item.isDefault}
                  >
                    {item.default ? "star" : "star_outline"}
                  </DefaultIcon>
                  <Icon
                    onClick={() => props.handleToggleEdit(item._id)}
                    icon='edit'
                    fontSize='24px'
                  />
                  <Icon
                    onClick={() => props.handleRemove(item._id)}
                    icon='delete'
                    fontSize='24px'
                  />
                </IconWrapper>
              </SortableItem>
            ))}
          </div>
        ))}
      </DraggableWrapper>
    </div>
  );
};

export default StatusDraggable;
