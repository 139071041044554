import React from "react";
import styled from "styled-components";
import { arrayEquals, sanitizeString } from "../../../utils/Helpers";
import FieldMappingModal from "./FieldMappingModal";
import LeadAdFormField from "./LeadAdFormField";

import BpModal from "../../elements/BpModal";
import { Button } from "../../styled/Buttons";
import { Title } from "../../styled/Headers";
import { InputBox } from "../../styled/Input";
import InputError from "../../styled/InputErrorForm";
import media from "../../styled/MediaQueries";
import SectionGroup from "../../styled/SectionGroup";
import * as _ from "underscore";
import {
  customFieldsCreate,
  customFieldsGetByCompany,
  customFieldsUpdate
} from "../../../utils/FacebookAPI";
import AvailableField from "./AvailableField";

const DraggableWrapper = styled.div`
  max-width: 1740px;
  margin: 2.5em;
  @supports (display: grid) and (display: flex) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 2.8em;
    /* padding: 0 8px 0 0; */
    /* margin: 0 2.5em 1em; */
    ${media.tablet`
      grid-template-columns: 1fr;
      padding: 0;
    `}
  }
  @media screen and (min-width: 1820px) {
    margin: 2.5em auto;
  }
  @media screen and (max-width: 450px) {
    margin: 2.5em 0.5em;
  }
  .droppable {
    margin: 20px;
    min-height: 50px;
    border: 2px solid transparent;
    &.formFields {
      padding-bottom: 40px;
    }
    ${media.phone`
      margin: 5px;
    `}
  }
  .hoverOn {
    /* background-color: #f4f4f4; */
    border: dashed 2px #a9a7a7;
    border-image: url("/dotted-border.png") 2 round;
  }
  .sortable-chosen {
    border: 2px solid #675da8;
    /* grid-template-columns: 1fr; */
    min-height: auto;
    input {
      display: none;
    }
    .icon-wrapper {
      i {
        color: transparent !important;
      }
    }
    label {
      font-size: 18px;
      font-weight: bold;
    }
    .field-wrapper {
      width: auto;
      text-align: center;
    }
  }
  .sortable-ghost {
    box-shadow: none;
    /* grid-template-columns: 1fr; */
    min-height: auto;
    border-top: dashed 2px #675da8;
    border-image: url("/dotted-border-purple.png") 2 round;
    margin: 18px 30px;
    height: 0px;
    overflow: hidden;
    input {
      visibility: hidden;
    }
    .icon-wrapper {
      i {
        color: transparent;
      }
    }
    label {
      font-size: 18px;
      font-weight: bold;
    }
    .field-wrapper {
      width: auto;
      text-align: center;
      padding: 10px;
      min-width: auto;
    }
  }
`;

const CardTitle = styled(Title)`
  margin: 40px auto 1em;
`;

const FieldTitle = styled.h4`
  color: #fbb74c;
  font-size: 18px;
  font-weight: bold;
`;

const AvailableFields = styled(SectionGroup)`
  float: left;
  width: 32.3vw;
  margin: 0 0 0 0.5em;
  @supports (display: grid) {
    margin: 0;
    width: auto;
  }
  ${media.phone`
    float: none;
    width: auto;
  `}
`;

const FormFields = styled(SectionGroup)`
  float: left;
  width: 55vw;
  margin: 0 0 0 0.5em;
  @supports (display: grid) {
    margin: 0;
    width: auto;
  }
  ${media.phone`
    float: none;
    width: auto;
  `}
  .droppable > div:first-of-type .arrow-up, .droppable > div:nth-last-child(2) .arrow-down {
    color: #f7f7f7;
    &:hover {
      color: #f7f7f7;
    }
  }
`;

const CustomInputWrapper = styled.div`
  margin: 20px;
`;

const FieldMessage = styled.p`
  padding: ${props => (props.empty ? "0" : "1em")};
  color: ${props =>
    props.status === "error"
      ? "rgba(237,41,57, 1)"
      : props.status === "warning"
      ? "rgb(229, 188, 25)"
      : "inherit"};
  background-color: ${props =>
    props.status === "error"
      ? "rgba(237,41,57, .1)"
      : props.status === "warning"
      ? "rgba(229, 188, 25, .1)"
      : "inherit"};
`;

const requiredFields = ["name", "email", "phone"];

const startingFields = [
  {
    key: "name",
    name: "Name",
    type: "FULL_NAME",
    index: 0
  },
  {
    key: "email",
    name: "Email",
    type: "EMAIL",
    index: 1
  },
  {
    key: "phone",
    name: "Phone Number",
    type: "PHONE",
    index: 2
  },
  {
    key: "address",
    name: "Street Address",
    type: "STREET_ADDRESS",
    specialCategoryHide: true,
    index: -1
  },
  {
    key: "city",
    name: "City",
    type: "CITY",
    specialCategoryHide: true,
    index: -1
  },
  {
    key: "state",
    name: "State",
    type: "STATE",
    index: -1
  },
  {
    key: "zip",
    name: "Zip Code",
    type: "ZIP",
    specialCategoryHide: true,
    index: -1
  }
];

class LeadAdForm extends React.Component {
  customFieldsRef = React.createRef();
  state = {
    columns: {
      basicFields: {
        id: "basicFields",
        title: "Basic Fields"
      },
      customFields: {
        id: "customFields",
        title: "Custom Fields"
      }
    },
    fields: [...startingFields],
    selectedFields: [
      {
        key: "name",
        name: "Name",
        type: "FULL_NAME",
        index: 0
      },
      {
        key: "email",
        name: "Email",
        type: "EMAIL",
        index: 1
      },
      {
        key: "phone",
        name: "Phone Number",
        type: "PHONE",
        index: 2
      }
    ],
    hoverClass: "hoverOff",
    customFieldVisible: false,
    customFieldValue: "",
    customFieldWidth: 0,
    openFieldMapping: false,
    customFields: [],
    modalDeleteFieldConfirm: false,
    fieldToDelete: {},
    processing: false,
    loadingCustomFields: true
  };

  async componentDidMount() {
    await this.getCustomFields();
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  getCustomFields = async () => {
    const customFields = await customFieldsGetByCompany();
    const { fields = [] } = this.state;
    let newCustomFields = [];
    if (customFields && customFields.fields) {
      newCustomFields = customFields.fields
        .filter(f => f.status !== "DELETED")
        .map(f => ({ ...f, index: -1 }));
    }
    const nonCustom = fields.filter(f => f.type !== "CUSTOM");
    const oldCustomFields = fields.filter(f => f.type === "CUSTOM");
    const updatedCustom = newCustomFields.map(f => {
      const cField = oldCustomFields.find(cf => cf.key === f.key);
      if (cField) {
        return {
          ...f,
          index: cField.index
        };
      } else {
        return f;
      }
    });
    const newFields = [...nonCustom, ...updatedCustom];
    this.setState({
      customFields: customFields,
      fields: this.props.filterFields(newFields),
      selectedFields: newFields.filter(f => f.index >= 0),
      loadingCustomFields: false
    });
    return updatedCustom;
  };

  fieldNameDisplay = name => {
    const { customFieldWidth } = this.state;
    const containerWidth = customFieldWidth - 96;
    const fontSize = 18;
    const lengthMax = Math.ceil(containerWidth / fontSize);
    let displayLabel = name;
    if (name.length > lengthMax) {
      const newName = name.substring(0, lengthMax);
      displayLabel = `${newName}&hellip;`;
    }
    return displayLabel;
  };

  updateWindowDimensions = () => {
    var width = 0;
    if (
      this.customFieldsRef.current &&
      this.customFieldsRef.current.offsetWidth
    ) {
      width += this.customFieldsRef.current.offsetWidth;
      this.setState({
        customFieldWidth: width
      });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  componentDidUpdate = async (prevProps, prevState) => {
    const { formFields = [] } = this.props;
    const {
      loadingCustomFields,
      customFields: { fields: customFields = [] }
    } = this.state;
    const { loadingCustomFields: prevLoadingCustomFields } = prevState;
    const { formFields: prevFormFields = [] } = prevProps;
    if (
      (prevFormFields.length !== formFields.length &&
        !arrayEquals(formFields, prevFormFields) &&
        !loadingCustomFields) ||
      loadingCustomFields !== prevLoadingCustomFields
    ) {
      const fields = [
        ...startingFields,
        ...customFields.filter(f => f.status === "ACTIVE")
      ];
      const newFields = fields.map(field => {
        const selectedFieldIndex = formFields.findIndex(
          f => f.key === field.key
        );
        return {
          ...field,
          index: selectedFieldIndex
        };
      });
      newFields.sort(this.fieldSort);
      const seen = new Set();
      const updatedFields = [
        ...this.props.filterFields(newFields, false),
        {
          key: "name",
          name: "Name",
          type: "FULL_NAME",
          index: 0
        },
        {
          key: "email",
          name: "Email",
          type: "EMAIL",
          index: 1
        },
        {
          key: "phone",
          name: "Phone Number",
          type: "PHONE",
          index: 2
        }
      ].filter(item => {
        if(!seen.has(item.key)){
          if(!["phone","name","email"].includes(item.key)|| item.index > -1){
            seen.add(item.key);
            return true;
          }
        }
        return false;
      });
      this.setState({
        fields: updatedFields,
        selectedFields: updatedFields
          .filter(f => f.index >= 0)
          .sort(this.fieldSort)
      });
    }
  };

  fieldSort = (a, b) => {
    if (a.index !== b.index) {
      return a.index - b.index;
    } else {
      return a.name - b.name;
    }
  };

  moveField = (fieldKey, direction) => {
    const { fields } = this.state;
    let newFields = [];
    if (direction === "remove") {
      newFields = fields.map(f => {
        if (f.key === fieldKey) {
          f.index = -1;
        }
        return f;
      });
    } else if (direction === "add") {
      const selectedCount = fields.reduce((prev, curr) => {
        if (curr.index >= 0) {
          return prev + 1;
        }
        return prev;
      }, 0);
      newFields = fields.map(f => {
        if (f.key === fieldKey) {
          f.index = selectedCount;
        }
        return f;
      });
    } else {
      const fieldIndex = fields.find(f => f.key === fieldKey);
      const newIndex =
        direction === "up" && fieldIndex.index > 0
          ? fieldIndex.index - 1
          : direction === "down" &&
            fieldIndex.index < fields.length &&
            fieldIndex.index + 1;
      newFields = this.moveFieldIndex(
        fieldIndex.index,
        newIndex,
        fieldIndex.key
      );
    }
    const filteredFields = this.props.filterFields(newFields);
    const newOrder = this.cleanupFieldOrder(filteredFields);
    this.updateFields(newOrder.filter(f => f.index >= 0));
    this.setState({
      fields: newOrder,
      selectedFields: newOrder.filter(f => f.index >= 0)
    });
  };

  onSortChange = (order, sortable, evt, column) => {
    const {
      newIndex,
      oldIndex,
      item,
      from: {
        classList: { value: fromValue }
      },
      to: {
        classList: { value: toValue }
      }
    } = evt;
    const movedItemKey = item.getAttribute("data-id");
    const { fields } = this.state;
    let newOrder = fields;
    if (
      fromValue === toValue &&
      toValue.includes("formFields") &&
      column === "form"
    ) {
      if (typeof newIndex === "number" && typeof oldIndex === "number") {
        newOrder = this.moveFieldIndex(oldIndex, newIndex, movedItemKey);
        this.updateFields(newOrder.filter(f => f.index >= 0));
      }
    } else if (fromValue.includes("formFields") && column === "form") {
      newOrder = newOrder.map(f => {
        if (f.key === movedItemKey) {
          f.index = -1;
        }
        return f;
      });
      this.updateFields(newOrder.filter(f => f.index >= 0));
    } else if (column === "form") {
      const selectedCount = fields.reduce((prev, curr) => {
        if (curr.index >= 0) {
          return prev + 1;
        }
        return prev;
      }, 0);
      newOrder = fields.map(f => {
        if (f.key === movedItemKey) {
          f.index = selectedCount;
        }
        return f;
      });
    }
    this.setState({
      fields: newOrder,
      selectedFields: newOrder.filter(f => f.index >= 0)
    });
  };

  moveFieldIndex = (oldIndex, newIndex, movedKey) => {
    const { fields } = this.state;
    const difference = newIndex - oldIndex;
    let newFields = [];
    if (difference === 1) {
      newFields = fields.map(f => {
        if (f.key === movedKey) {
          f.index = newIndex;
        } else if (f.index === newIndex) {
          f.index = oldIndex;
        }
        return f;
      });
    } else if (difference > 0) {
      newFields = fields.map(f => {
        if (f.key === movedKey) {
          f.index = newIndex;
        } else if (f.index >= oldIndex) {
          f.index = f.index - 1;
        }
        return f;
      });
    } else {
      newFields = fields.map(f => {
        if (f.key === movedKey) {
          f.index = newIndex;
        } else if (f.index >= newIndex) {
          f.index = f.index + 1;
        }
        return f;
      });
    }
    return this.cleanupFieldOrder(newFields);
  };

  cleanupFieldOrder = fields => {
    const selectedFields = fields.filter(f => f.index >= 0);
    const unselectedFields = fields.filter(f => f.index < 0);
    selectedFields.sort((a, b) => {
      if (a.index === b.index) {
        return a.name - b.name;
      }
      return a.index - b.index;
    });
    const newSelectedFields = selectedFields.map((f, index) => ({
      ...f,
      index
    }));
    unselectedFields.sort((a, b) => a.name - b.name);
    return [...unselectedFields, ...newSelectedFields];
  };

  onStart = () => {
    this.setState({ hoverClass: "hoverOn" });
  };
  onEnd = () => {
    this.setState({ hoverClass: "hoverOff" });
  };

  toggleCustomField = () => {
    const { customFieldVisible } = this.state;
    this.setState({ customFieldVisible: !customFieldVisible });
  };

  changeCustomField = input => {
    this.setState({ customFieldValue: input.target.value });
  };

  saveCustomField = async () => {
    const { customFieldValue, fields, customFields, selectedFields } =
      this.state;
    const {
      alert,
      userCompany: { _id: companyId }
    } = this.props;
    if (customFieldValue) {
      const fieldSanitized = sanitizeString(customFieldValue, "_");
      if (!fieldSanitized) {
        alert({
          type: "error",
          msg: "There was a problem adding your field. Please make sure your label has valid characters."
        });
        return;
      }
      const newField = {
        key: fieldSanitized,
        name: customFieldValue,
        isCustom: true,
        type: "CUSTOM",
        status: "ACTIVE"
      };
      const newFields = [
        ...fields,
        { ...newField, index: selectedFields.length }
      ];
      const selectedFieldsNew = newFields.filter(f => f.index >= 0);
      let newCustomFields = {};
      if (customFields && customFields._id) {
        newCustomFields = await customFieldsUpdate({
          ...customFields,
          fields: [...customFields.fields, newField]
        });
      } else {
        newCustomFields = await customFieldsCreate({
          companyId,
          fields: [newField]
        });
      }
      this.setState(
        {
          fields: newFields,
          selectedFields: selectedFieldsNew,
          customFields: newCustomFields,
          newField,
          customFieldValue: "",
          openFieldMapping: true
        },
        () => {
          this.updateFields(selectedFieldsNew, true);
        }
      );

      // this.getCustomFields();
      this.toggleCustomField();
    } else {
      alert({
        type: "error",
        msg: "You cannot add a field with a blank label."
      });
    }
  };

  cancelCustomField = () => {
    this.setState({ customFieldValue: "", customFieldVisible: false });
  };

  deleteCustomField = async field => {
    const { fields, customFields, modalDeleteFieldConfirm, fieldToDelete } =
      this.state;
    const { alert } = this.props;
    if (!modalDeleteFieldConfirm) {
      this.setState({ modalDeleteFieldConfirm: true, fieldToDelete: field });
      return;
    }
    this.setState({ processing: true });
    const newFields = fields.filter(f => f.key !== fieldToDelete.key);
    this.setState({
      fields: newFields
    });

    try {
      const updatedFields = customFields.fields.map(f => {
        if (f.key === fieldToDelete.key) {
          return { ...f, status: "DELETED" };
        } else {
          return f;
        }
      });
      await customFieldsUpdate({
        ...customFields,
        fields: updatedFields
      });
      alert({
        type: "success",
        msg: `The custom field ${fieldToDelete.name} is deleted`
      });
      this.setState({ processing: false, modalDeleteFieldConfirm: false });
      this.getCustomFields();
    } catch (err) {
      console.log(err.message);
    }
  };

  updateFields = (formFields, skipFilter) => {
    if (!skipFilter) {
      const newFields = this.props.filterFields(formFields);
      this.props.updateFields(newFields);
    } else {
      this.props.updateFields(formFields);
    }
  };

  closeFieldMapping = () => {
    this.setState({ newField: {}, openFieldMapping: false });
  };

  render() {
    const {
      columns,
      fields = [],
      selectedFields = [],
      hoverClass,
      customFieldVisible,
      customFieldValue
    } = this.state;
    const {
      errMessages,
      fieldsMessage,
      fieldCountStatus,
      formFields = []
    } = this.props;
    const { basicFields, customFields } = columns;
    const formFieldsClass = formFields.length === 0 ? "form-fields-empty" : "";
    return (
      <div>
        <DraggableWrapper id='fields-wrapper'>
          <AvailableFields id='availableFields'>
            <CardTitle>Available</CardTitle>
            <FieldTitle>{basicFields.title}</FieldTitle>
            {/* <Sortable
              tag="div"
              options={{
                group: 'shared',
                onStart: this.onStart,
                onEnd: this.onEnd,
                ghostClass: 'sortable-ghost', // Class name for the drop placeholder
                chosenClass: 'sortable-chosen', // Class name for the chosen item
                dragClass: 'sortable-drag', // Class name for the dragging item
                delay: 500,
                delayOnTouchOnly: true
              }}
              onChange={(order, sortable, evt) => {
                this.onSortChange(order, sortable, evt, 'basic');
              }}
              className={`droppable basicFields ${hoverClass}`}
            > */}
            <div className={`droppable basicFields ${hoverClass}`}>
              {fields
                .filter(f => f.index < 0 && f.type !== "CUSTOM")
                .map(field => (
                  <AvailableField
                    key={field.key}
                    field={field}
                    custom={false}
                    moveField={this.moveField}
                  />
                ))}
            </div>
            {/* </Sortable> */}
            <FieldTitle ref={this.customFieldsRef}>
              {customFields.title}
            </FieldTitle>
            {/* <Sortable
              tag="div"
              options={{
                group: 'shared',
                onStart: this.onStart,
                onEnd: this.onEnd,
                ghostClass: 'sortable-ghost', // Class name for the drop placeholder
                chosenClass: 'sortable-chosen', // Class name for the chosen item
                dragClass: 'sortable-drag', // Class name for the dragging item
                delay: 500,
                delayOnTouchOnly: true
              }}
              onChange={(order, sortable, evt) => {
                this.onSortChange(order, sortable, evt, 'custom');
              }}
              className={`droppable customFields ${hoverClass}`}
            > */}
            <div className={`droppable customFields ${hoverClass}`}>
              {fields
                .filter(f => f.index < 0 && f.type === "CUSTOM")
                .map(field => (
                  <AvailableField
                    key={field.key}
                    field={field}
                    custom={true}
                    deleteCustomField={this.deleteCustomField}
                    moveField={this.moveField}
                  />
                ))}
            </div>
            {/* </Sortable> */}
            <div id='add-custom-fields'>
              {customFieldVisible ? (
                <div>
                  <CustomInputWrapper>
                    <InputBox
                      type='text'
                      value={customFieldValue}
                      onChange={input => this.changeCustomField(input)}
                    />
                  </CustomInputWrapper>
                  <p>
                    <Button onClick={this.cancelCustomField}>Cancel</Button>
                    <Button solid onClick={this.saveCustomField}>
                      Save Field
                    </Button>
                  </p>
                </div>
              ) : (
                <Button onClick={this.toggleCustomField}>
                  Add new Custom Field
                </Button>
              )}
            </div>
          </AvailableFields>

          <FormFields id='formFields'>
            <CardTitle>Chosen Fields</CardTitle>
            <FieldMessage
              status={fieldCountStatus}
              empty={fieldsMessage ? false : true}
            >
              {fieldsMessage}
            </FieldMessage>
            {/* <Sortable
              tag="div"
              options={{
                group: 'shared',
                onStart: this.onStart,
                onEnd: this.onEnd,
                ghostClass: 'sortable-ghost', // Class name for the drop placeholder
                chosenClass: 'sortable-chosen', // Class name for the chosen item
                dragClass: 'sortable-drag', // Class name for the dragging item
                handle: '.field-wrapper',
                delay: 500,
                delayOnTouchOnly: true
              }}
              onChange={(order, sortable, evt) => {
                this.onSortChange(order, sortable, evt, 'form');
              }}
              className={`droppable formFields ${hoverClass} ${formFieldsClass}`}
            > */}
            <div
              className={`droppable formFields ${hoverClass} ${formFieldsClass}`}
            >
              {selectedFields.map(field => (
                <LeadAdFormField
                  required={requiredFields.includes(field.key)}
                  key={field.key}
                  fieldName={field.key}
                  field={field}
                  moveField={this.moveField}
                  handleFieldsFiltered={this.props.handleFieldsFiltered}
                />
              ))}
            </div>
            <InputError>{errMessages.fields}</InputError>
            {/* </Sortable> */}
          </FormFields>
        </DraggableWrapper>
        <FieldMappingModal
          userProfile={this.props.userProfile}
          newField={this.state.newField}
          openFieldMapping={this.state.openFieldMapping}
          closeFieldMapping={this.closeFieldMapping}
        />
        <BpModal
          open={this.state.modalDeleteFieldConfirm}
          title='Are you sure?'
          primaryAction={{
            btnLabel: "Delete Field",
            action: this.deleteCustomField,
            loading: this.state.processing
          }}
          secondaryAction={{
            btnLabel: "Close",
            action: () =>
              this.setState({
                modalDeleteFieldConfirm: false,
                fieldToDelete: {}
              }),
            loading: this.state.processing
          }}
          body={<p>Are you sure you want to delete this field?</p>}
        />
      </div>
    );
  }
}

export default LeadAdForm;
