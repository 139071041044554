import React from "react";
import { Redirect, Route } from "react-router-dom";

function PrivateRoute({
  component: Component,
  userProfile,
  authUser,
  path,
  location,
  ...rest
}) {
  return (
    <Route
      render={props =>
        authUser === true ? (
          <Component
            {...props}
            userProfile={userProfile}
            authUser={authUser}
            {...rest}
          />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { referrer: path, search: location.search || "" }
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
