import React from "react";
import styled from "styled-components";
import * as _ from "underscore";
import media from "../../styled/MediaQueries";

const FieldWrapper = styled.div`
  position: relative;
  min-height: 60px;
  &:hover {
    outline: 1px solid #f4f4f4;
    .icon-wrapper {
      div {
        i {
          color: lightgray;
          &:hover {
            color: #675da8;
          }
        }
      }
    }
  }
  @supports (display: grid) {
    display: grid;
    grid-template-columns: 34px 1fr 34px;
    @media screen and (max-width: 350px) {
      grid-template-columns: 1fr;
    }
  }
`;
const IconWrapper = styled.div`
  position: absolute;
  width: 34px;
  height: 100%;
  max-height: 64px;
  ${props => (props.position === "left" ? "left: 0;" : "right:0; top: 0")}
  cursor: pointer;
  @media screen and (max-width: 350px) {
    width: auto;
  }
  @supports (display: grid) and (display: flex) {
    position: static;
    height: auto;
    max-height: none;
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      display: flex;
      align-content: center;
      /* height: 100%;
      max-height: 64px; */
      flex-direction: column;
      @media screen and (max-width: 350px) {
        align-content: normal;
        flex-direction: row;
        justify-content: center;
      }
    }
  }
`;

const FieldContent = styled.div`
  padding: 10px 20px;
  /* text-align: left; */
  width: 35vw;
  min-width: 220px;
  max-width: 85%;
  margin: 0 auto;
  /* cursor: move; */
  @media screen and (max-width: 350px) {
    min-width: 70vw;
  }
  ${media.phone`
    padding: 10px;
    input {
      border-width: 1px;
      border-color: rgb(238, 238, 238);
    }
  `}
  @supports (display: grid) {
    width: auto;
    max-width: none;
    margin: 0;
  }
  textarea,
  input {
    width: 100%;
  }
`;
const FieldLabel = styled.label`
  display: block;
  grid-column: 1/4;
  margin: 15px 0 0 0;
  color: #fdb849;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 2.5px;
`;

const Icon = styled.i`
  position: absolute;
  color: #e5e5e5;
  top: ${props => (props.position === "top" ? "4px" : "auto")};
  bottom: ${props => (props.position === "bottom" ? "4px" : "auto")};
  left: 4px;
  &:hover {
    color: gray;
  }
  @supports (display: grid) and (display: flex) {
    position: static;
    display: block;
  }
`;

const IconRemove = styled.i`
  position: absolute;
  right: 4px;
  top: 17px;
  color: #e5e5e5;
  padding: 10px 5px;
  cursor: pointer;
  &:hover {
    color: gray;
  }
  @supports (display: grid) and (display: flex) {
    position: static;
    display: block;
  }
`;

const Input = styled.input`
  pointer-events: none;
`;

const LeadAdFormField = props => {
  const { field, required } = props;

  const moveFieldUp = () => {
    props.moveField(field.key, "up");
  };

  const moveFieldDown = () => {
    props.moveField(field.key, "down");
  };

  const removeField = () => {
    props.moveField(field.key, "remove");
  };

  return field && field.name ? (
    <FieldWrapper key={field.key} data-id={field.key}>
      <FieldLabel>{field.name}</FieldLabel>
      <IconWrapper className='icon-wrapper' position='left'>
        <div>
          <Icon className='material-icons' onClick={moveFieldUp} position='top'>
            keyboard_arrow_up
          </Icon>
          <Icon
            className='material-icons'
            onClick={moveFieldDown}
            position='bottom'
          >
            keyboard_arrow_down
          </Icon>
        </div>
      </IconWrapper>
      <FieldContent className='field-wrapper'>
        <Input type={field.inputType} disabled />
      </FieldContent>
      {!required && (
        <IconWrapper className='icon-wrapper' position='right'>
          <IconRemove className='material-icons' onClick={removeField}>
            clear
          </IconRemove>
        </IconWrapper>
      )}
    </FieldWrapper>
  ) : null;
};

export default LeadAdFormField;
