import React from "react";
import styled from "styled-components";
import * as _ from "underscore";
import { twilioGetMedia } from "../../../utils/MessagesAPI";
import { firstBreakpoint } from "../../styled/PhoneNumberProvision";

const ImageWrapper = styled.div`
  .image-wrapper:last-child {
    margin-bottom: ${props => (props.bodyEmpty ? 0 : "1em")};
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 200px;
  height: 200px;
  margin-bottom: 1em;
  .download-link {
    opacity: 0.5;
  }
  &:hover .download-link {
    opacity: 1;
  }
  img {
    object-fit: cover;
    width: 200px;
    height: 200px;
    border-radius: 5px;
  }
  @media screen and (min-width: ${firstBreakpoint}px) {
    width: 300px;
    height: 300px;
    img {
      width: 300px;
      height: 300px;
    }
  }
`;

const ImageLink = styled.a`
  display: block;
  position: absolute;
  left: 4px;
  top: 4px;
  padding: 3px;
  border-radius: 50%;
  color: ${props => props.theme.HC1};
  background-color: rgba(225, 225, 225, 0.8);
  cursor: pointer;
`;

const MessageImage = props => {
  const { message } = props;
  const [images, setImages] = React.useState([]);

  // React.useEffect(() => {
  //   const getImages = async () => {
  //     console.log('message.media', message.media);
  //     const imagesResponse = await twilioGetMedia(
  //       `https://api.twilio.com${message.media.url}`
  //     );
  //     console.log('imagesReponse', imagesResponse);
  //   };
  //   getImages();
  // }, [message]);
  return (
    <ImageWrapper bodyEmpty={!message.body}>
      {message.media && (
        <ImageContainer className='image-wrapper'>
          {message?.media[0]?.url && (
            <ImageLink
              href={message.media[0].url}
              target='_blank'
              rel='noopener noreferrer'
              className='material-icons download-link'
            >
              open_in_new
            </ImageLink>
          )}

          {message?.media[0]?.mimeType?.includes("image") ? (
            <img
              src={message.media[0].url}
              data-pin-nopin='true'
              alt={message.body}
            />
          ) : message?.media[0]?.mimeType?.includes("video") ? (
            <video
              style={{
                maxWidth: "100%",
                maxHeight: "63vh"
              }}
              controls
            >
              <source src={message.media[0].url} />
              Your browser does not support this video type
            </video>
          ) : (
            <img
              src={message?.media[0]?.url}
              data-pin-nopin='true'
              alt={message.body}
            />
          )}
        </ImageContainer>
      )}
    </ImageWrapper>
  );
};

export default MessageImage;
