import React from "react";
import { Redirect, Route } from "react-router-dom";

// Styled Components
import LoadingCircle from "./styled/LoadingCircle";

function PublicRouteHidden({
  component: Component,
  loading,
  authUser,
  location,
  ...rest
}) {
  let redirectTo = "/member-settings";
  if (
    location.state &&
    location.state.referrer &&
    location.state.referrer !== "/login"
  ) {
    redirectTo = location.state.referrer;
    if (location.state.search) {
      redirectTo = location.state.referrer + location.state.search;
    }
  }
  return loading === true ? (
    <LoadingCircle />
  ) : (
    <Route
      {...rest}
      render={props =>
        (authUser === false && !props.registering) ||
        (props.userProfile && props.userProfile.stripeID) ? (
          <Component
            {...props}
            {...rest}
          />
        ) : (
          <Redirect to={redirectTo} />
        )
      }
    />
  );
}

export default PublicRouteHidden;
