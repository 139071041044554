import React from "react";
import Floater from "react-floater";
import LineChart from "../../elements/charts/LineChart";
import LoadingBar from "../../elements/LoadingBar";
import ChartHeader from "./ChartHeader";
import {
  ChartTitle,
  ChartTitleChange,
  ChartTitleNumber,
  DashboardModuleTitle,
  floaterStyles,
  Icon,
  Overlay,
  Section
} from "./DashboardStyles";

const Reach = props => {
  const {
    chartsLoading,
    floaters,
    currentIncDate,
    currentIncReach,
    prevIncDate,
    prevIncReach,
    reach,
    theme
  } = props;

  const [reachChange, setReachChange] = React.useState(0);
  const [reachFormatted, setReachFormatted] = React.useState(0);

  React.useEffect(() => {
    const reachDiff = reach.current - reach.prev;
    setReachFormatted(
      reach.prev ? Math.round((reachDiff / reach.prev) * 100 * 100) / 100 : 0
    );
    setReachChange(
      reach.current ? reach.current.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0
    );
  }, [reach]);

  return (
    <Section columns='1/3'>
      <DashboardModuleTitle>
        <ChartTitle>
          <h6>Reach</h6>
          <Floater
            open={floaters.reach}
            content='The number of people who saw your ads at least once. Reach is different from impressions, which may include multiple views of your ads by the same people.'
            styles={floaterStyles}
            event='hover'
            placement='right'
          >
            <Icon
              className='material-icons'
              onClick={e => props.handleFloaterClick(e, "reach")}
              name='floater-toggle'
            >
              info
            </Icon>
            {floaters.reach && (
              <Overlay
                onClick={e => props.handleFloaterClick(e, "reach")}
                className='overlay'
              />
            )}
          </Floater>
        </ChartTitle>
        {chartsLoading ? null : (
          <ChartTitle>
            <ChartTitleNumber className='color-yellow'>
              {reachFormatted}
            </ChartTitleNumber>
            {!!reachChange &&
              (reachChange >= 0 ? (
                <ChartTitleChange>
                  (+
                  {reachChange}
                  %)
                </ChartTitleChange>
              ) : (
                <ChartTitleChange style={{ color: "red" }}>
                  ({reachChange}
                  %)
                </ChartTitleChange>
              ))}
          </ChartTitle>
        )}
      </DashboardModuleTitle>
      {chartsLoading ? (
        <LoadingBar />
      ) : (
        <LineChart
          backgroundColor={
            theme.HC2 +
            Math.round(Math.min(Math.max(0.2 || 1, 0), 1) * 255)
              .toString(16)
              .toUpperCase()
          }
          borderColor={theme.HC2}
          labelValue={currentIncDate}
          dataValue={currentIncReach}
          prevBackgroundColor='rgba(235, 239, 239, 0.2)'
          prevBorderColor='rgba(211, 224, 224, 1)'
          prevLabelValue={prevIncDate}
          prevDataValue={prevIncReach}
        />
      )}
    </Section>
  );
};

export default Reach;
