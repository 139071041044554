import React from "react";
import {
  InputContainer,
  InputBoxWrapper,
  InputBox,
  InputLabel,
  FormWrapper,
  FormDescription
} from "../../../styled/Input";

export const ZohoCoreSettings = props => {
  const { integration } = props;

  return (
    <FormWrapper single>
      <InputContainer slimHeight customWidth='100%'>
        <InputLabel>Refresh Token</InputLabel>
        <InputBoxWrapper>
          <InputBox
            type='text'
            name='refresh_token'
            readOnly
            value={integration.refresh_token}
          />
        </InputBoxWrapper>
      </InputContainer>
      <FormDescription>
        Please contact your Support Representative to update these values.
      </FormDescription>
    </FormWrapper>
  );
};

export const ZohoOptionalSettings = props => {
  const { integration } = props;

  return (
    <FormWrapper>
      <InputContainer customWidth='100%'>
        <InputLabel>Lead Source</InputLabel>
        <InputBox
          type='text'
          name='Lead_Source'
          readOnly
          value={integration.Lead_Source}
        />
      </InputContainer>
      <InputContainer customWidth='100%'>
        <InputLabel>Lead Status</InputLabel>
        <InputBox
          type='text'
          name='Lead_Status'
          readOnly
          value={integration.Lead_Status}
        />
      </InputContainer>

      <FormDescription evenContainer>
        Please contact your Support Representative to update these values.
      </FormDescription>
    </FormWrapper>
  );
};
