import React from "react";
import Floater from "react-floater";
import LineChart from "../../elements/charts/LineChart";
import LoadingBar from "../../elements/LoadingBar";
import ChartHeader from "./ChartHeader";
import {
  ChartTitleChange,
  ChartTitle,
  ChartTitleNumber,
  DashboardModuleTitle,
  floaterStyles,
  Icon,
  Overlay,
  Section
} from "./DashboardStyles";

const Frequency = props => {
  const {
    chartsLoading,
    floaters,
    currentAdAccount,
    currentIncDate,
    currentIncFrequency,
    prevIncDate,
    prevIncFrequency,
    frequency = {},
    theme
  } = props;

  const [frequencyChange, setFrequencyChange] = React.useState(0);

  React.useEffect(() => {
    const frequencyDiff = frequency.current - frequency.prev;
    setFrequencyChange(
      frequency.prev
        ? Math.round((frequencyDiff / frequency.prev) * 100 * 100) / 100
        : 0
    );
  }, [frequency]);

  return (
    <Section columns='3/5'>
      <DashboardModuleTitle>
        <ChartTitle>
          <h6>Frequency</h6>
          <Floater
            open={floaters.frequency}
            content='The average number of times each person saw your ad.'
            styles={floaterStyles}
            event='hover'
            placement='right'
          >
            <Icon
              className='material-icons'
              onClick={e => props.handleFloaterClick(e, "frequency")}
              name='floater-toggle'
            >
              info
            </Icon>
            {floaters.frequency && (
              <Overlay
                onClick={e => props.handleFloaterClick(e, "frequency")}
                className='overlay'
              />
            )}
          </Floater>
        </ChartTitle>
        {chartsLoading ? null : (
          <ChartTitle>
            <ChartTitleNumber className='color-yellow'>
              {currentAdAccount.frequency
                ? parseFloat(currentAdAccount.frequency).toFixed(2)
                : 0}
            </ChartTitleNumber>
            {!!frequencyChange &&
              (frequencyChange >= 0 ? (
                <ChartTitleChange>
                  (+
                  {frequencyChange}
                  %)
                </ChartTitleChange>
              ) : (
                <ChartTitleChange style={{ color: "red" }}>
                  ({frequencyChange}
                  %)
                </ChartTitleChange>
              ))}
          </ChartTitle>
        )}
      </DashboardModuleTitle>
      {chartsLoading ? (
        <LoadingBar />
      ) : (
        <LineChart
          backgroundColor={
            theme.HC1 +
            Math.round(Math.min(Math.max(0.2 || 1, 0), 1) * 255)
              .toString(16)
              .toUpperCase()
          }
          borderColor={theme.HC1}
          labelValue={currentIncDate}
          dataValue={currentIncFrequency}
          prevBackgroundColor='rgba(235, 239, 239, 0.2)'
          prevBorderColor='rgba(211, 224, 224, 1)'
          prevLabelValue={prevIncDate}
          prevDataValue={prevIncFrequency}
        />
      )}
    </Section>
  );
};

export default Frequency;
