import React from "react";
import styled from "styled-components";
import CampaignHistoryList from "./CampaignHistoryList";
import { getAllCompanyCampaigns } from "../../../utils/FacebookAPI";

import { TitleWrapper, TitleLeft, TitleRight } from "../../styled/Layout";
import { ContactViewWrapper, IntegrationCard } from "../../styled/Integrations";
import { H1 } from "../workflows/styles";
import { Button } from "../../styled/Buttons";
import CampaignDuplicate from "./CampaignDuplicate";

const CreateButton = styled.a`
  grid-column: 1/3;
`;

const TableCard = styled(IntegrationCard)`
  padding: 0;
`;

const sortOrder = {
  IN_PROCESS: 1,
  PENDING_REVIEW: 2,
  ACTIVE: 3,
  DISAPPROVED: 4
};

const CampaignHistory = props => {
  const { userProfile } = props;

  const [isLoading, setLoading] = React.useState(true);
  const [campaignList, setCampaignList] = React.useState([]);
  const [duplicateCampaign, setDuplicateCampaign] = React.useState({});
  const [duplicating, setDuplicating] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      const getCampaigns = await getAllCompanyCampaigns();
      const filteredCampaigns = getCampaigns.filter(
        campaign => campaign.status !== "DRAFT"
      );
      const sortedCampaigns = filteredCampaigns.sort(function (a, b) {
        let aCampaignActive = 1;
        let bCampaignActive = 0;
        if (sortOrder[a.status]) {
          aCampaignActive = sortOrder[a.status];
        } else {
          aCampaignActive = Object.keys(sortOrder).length + 1;
        }
        if (sortOrder[b.status]) {
          bCampaignActive = sortOrder[b.status];
        } else {
          bCampaignActive = Object.keys(sortOrder).length + 1;
        }
        if (aCampaignActive === bCampaignActive) {
          const aDate = new Date(a.startDate).getTime();
          const bDate = new Date(b.startDate).getTime();
          return bDate - aDate;
        }
        return aCampaignActive - bCampaignActive;
      });
      setCampaignList(sortedCampaigns);
      setLoading(false);
    })();
  }, []);

  const handleDuplicateCampaign = campaignToDelete => {
    const matchingCampaign = campaignList.find(c => c._id === campaignToDelete);
    if (matchingCampaign) {
      setDuplicateCampaign(matchingCampaign);
      setDuplicating(true);
    }
  };

  const goToCampaign = value => {
    if (value) {
      props.history.push("/area-campaign");
    }
  };

  return (
    <div>
      <TitleWrapper>
        <TitleLeft>
          <H1>Campaign History</H1>
          {/* <Search
            searchValue={searchValue}
            onEnter={this.onEnter}
            onSearch={this.search}
            onSearchChange={this.onSearchChange}
            clearSearch={this.clearSearch}
            showSearch={showSearch}
            compact={true}
            placeholder="Search workflows"
            showActive={
              !showSearch &&
              filteredWorkflows.length !== this.state.workflows.length
            }
            showClear={
              showSearch &&
              filteredWorkflows.length !== this.state.workflows.length
            }
          /> */}
        </TitleLeft>
        <TitleRight>
          <CreateButton onClick={() => props.history.push(`/campaign-select`)}>
            <Button solid>New Campaign</Button>
          </CreateButton>
        </TitleRight>
      </TitleWrapper>
      <ContactViewWrapper>
        <TableCard layoutPosition='full' displayAll>
          {!isLoading && (
            <CampaignHistoryList
              campaignList={campaignList}
              userProfile={userProfile}
              duplicateCampaign={handleDuplicateCampaign}
              {...props}
            />
          )}
        </TableCard>
      </ContactViewWrapper>
      <CampaignDuplicate
        duplicating={duplicating}
        campaign={duplicateCampaign}
        industry={props.userCompany?.industry}
        onClose={goToCampaign}
      />
    </div>
  );
};

export default CampaignHistory;
