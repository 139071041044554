import React from "react";
// import Card from '../../styled/Card';
// import CardTitle from '../../styled/TitleCard';
// import H3 from '../../styled/H3';
import { Card, CardTitle } from "../../styled/Layout";
import { Title } from "../../styled/Headers";
import { Button } from "../../styled/Buttons";
import styled from "styled-components";

const TitleIcon = styled.span`
  display: block;
  color: ${props => props.theme.TextC};
  text-align: center;
  font-size: 4rem;
  padding-bottom: 0.25em;
`;

const Description = styled.div`
  margin-top: -2em;
  text-align: left;
  padding: 0em 2em 2em 2em;
`;

const Video = styled.div`
  overflow: hidden;
  padding-bottom: 66.25%;
  position: relative;
  height: 0;
  iframe {
    padding: 0;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
`;

const Use = styled.div`
  text-align: left;
  padding: 1em;
  margin: 1em;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
`;

const Icon = styled.div`
  vertical-align: middle;
  font-size: 2em;
  display: inline-block;
  color: ${props => props.theme.HC1};
  cursor: pointer;
  padding: 0 0.5em 0 0;
  margin: 0;
`;

class ConstantContactAbout extends React.Component {
  render() {
    return (
      <Card>
        <CardTitle>
          <Title uppercase>
            <TitleIcon className='material-icons'>description</TitleIcon>
            Description
          </Title>
        </CardTitle>
        This is a description about Constant Contact.
      </Card>
    );
  }
}

class EZTextingAbout extends React.Component {
  render() {
    return (
      <Card>
        <CardTitle>
          <Title uppercase>
            <TitleIcon className='material-icons'>description</TitleIcon>
            Description
          </Title>
        </CardTitle>
        This is a description about EZ Texting.
      </Card>
    );
  }
}

class HatchAbout extends React.Component {
  render() {
    return (
      <Card>
        <CardTitle>
          <Title uppercase>
            <TitleIcon className='material-icons'>description</TitleIcon>
            Description
          </Title>
        </CardTitle>
        This is a description about Hatch.
      </Card>
    );
  }
}

class JobNimbusAbout extends React.Component {
  render() {
    return (
      <Card>
        <CardTitle>
          <Title uppercase>
            <TitleIcon className='material-icons'>description</TitleIcon>
            Description
          </Title>
        </CardTitle>
        <Description>
          <Video>
            <iframe
              title='JobNimbus Demo (2019)'
              src='https://player.vimeo.com/video/323540526?app_id=122963'
              frameBorder='0'
              allow='autoplay; fullscreen; picture-in-picture'
              allowFullScreen=''
              name='fitvid0'
            ></iframe>
          </Video>
          <b>JobNimbus is an all-in-one project management hub.</b>
          <p>
            With JobNimbus, you can track sales, jobs, and tasks from a single,
            simplified interface.
          </p>
          <p>
            Because JobNimbus is mobile, you can access your information
            wherever you are, get everyone on the same page, and grow your
            business.
          </p>
          <ul>
            <li>Track jobs with interactive boards</li>
            <li>Generate estimates and invoices</li>
            <li>Manage lead contacts</li>
          </ul>
        </Description>
      </Card>
    );
  }
}
class MarketSharpAbout extends React.Component {
  render() {
    return (
      <>
        <Card>
          <CardTitle>
            <Title uppercase>
              <TitleIcon className='material-icons'>description</TitleIcon>
              Description
            </Title>
          </CardTitle>
          This is a description about MarketSharp.
        </Card>
        <Card>
          <CardTitle>
            <Title uppercase>
              <TitleIcon className='material-icons'>build</TitleIcon>
              Uses
            </Title>
          </CardTitle>
        </Card>
      </>
    );
  }
}

class ZohoAbout extends React.Component {
  render() {
    return (
      <>
        <Card>
          <CardTitle>
            <Title uppercase>
              <TitleIcon className='material-icons'>description</TitleIcon>
              Description
            </Title>
          </CardTitle>
          This is a description about Zoho.
        </Card>
        <Card>
          <CardTitle>
            <Title uppercase>
              <TitleIcon className='material-icons'>build</TitleIcon>
              Uses
            </Title>
          </CardTitle>
        </Card>
      </>
    );
  }
}

class NullAbout extends React.Component {
  render() {
    return (
      <>
        <Card>
          <CardTitle>
            <Title uppercase>
              <TitleIcon className='material-icons'>description</TitleIcon>
              Description
            </Title>
          </CardTitle>
          This is a description about Zoho.
        </Card>
        <Card>
          <CardTitle>
            <Title uppercase>
              <TitleIcon className='material-icons'>build</TitleIcon>
              Uses
            </Title>
          </CardTitle>
        </Card>
      </>
    );
  }
}
class BoostpointConnectAbout extends React.Component {
  render() {
    return (
      <Card>
        <CardTitle>
          <Title uppercase>Information &amp; Use</Title>
        </CardTitle>
        <p>
          Boostpoint Connect complements our core advertising software by
          engaging new contacts through text messaging. The new automated
          follow-up feature includes customizable workflows, that allow you to
          craft unique responses for each campaign. After a contact responds to
          your campaign, you can personally interact with them via text using
          our integrated messaging solution.
        </p>
        <ul>
          <li>Automatically message leads using customizable workflows</li>
          <li>Connect with contacts within the Boostpoint app</li>
          <li>Manage lead contacts</li>
        </ul>
        <p>
          <Button onClick={this.props.toggleContact}>CONTACT SUPPORT</Button>
          <Button solid>
            <a
              href='https://help.boostpoint.com/hc/en-us/categories/360003560031-Boostpoint-Connect'
              target='_blank'
              rel='noopener noreferrer'
            >
              HELP DOCUMENTATION
            </a>
          </Button>
        </p>
      </Card>
    );
  }
}
export {
  ZohoAbout,
  MarketSharpAbout,
  JobNimbusAbout,
  HatchAbout,
  EZTextingAbout,
  ConstantContactAbout,
  BoostpointConnectAbout,
  NullAbout
};
