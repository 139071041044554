/* global google */
import * as Sentry from "@sentry/browser";
import moment from "moment";
import React from "react";
import Geocode from "react-geocode";
import { Redirect } from "react-router-dom";
import styled, { withTheme } from "styled-components";
import * as _ from "underscore";
import { errorHandler, formatMoney, getDaysDiff } from "../../../utils/Helpers";
import BpModal from "../../elements/BpModal";
import DateRangePickerWrapper from "../../elements/PresetDateRangePicker";
import GoogleRadiusMap from "./GoogleMap";
// Styled Components
import {
  campaignDelete,
  campaignGetDrafts,
  campaignUpdate,
  estimatedReachGet,
  targetingGetById
} from "../../../utils/FacebookAPI";
import Checkbox from "../../elements/Checkbox";
import { LoaderDotsSmall } from "../../elements/Loaders";
import AddressResults from "../../styled/AddressResults";
import { Button, SubmitButton } from "../../styled/Buttons";
import { H1 } from "../../styled/Fonts";
import H5 from "../../styled/H5";
import { Subtitle, Title } from "../../styled/Headers";
import { InputBox, InputLabel, NumberDisplay } from "../../styled/Input";
import InputError from "../../styled/InputErrorForm";
import { Card, CardTitle, TitleLeft, TitleWrapper } from "../../styled/Layout";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);

const ReachWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  max-width: 950px;
  width: 68%;
  margin: auto;
  grid-gap: 100px;
`;

const Disclaimer = styled.div`
  font-size: 0.67em;
  max-width: 400px;
  margin: 1em auto;
  text-align: left;
`;

const SliderInput = styled(InputBox)`
  margin: 24px 0 0 15px;
  width: auto;
`;

const AddressSearch = styled.div`
  max-width: 950px;
  width: 68%;
  margin: 0 auto;
  form {
    display: flex;
  }
`;

const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  width: 100%;
`;

const AddressSubmit = styled.div`
  display: flex;
  align-items: flex-end;
`;

const AddressWrapper = styled.div`
  max-width: 950px;
  margin: auto;
`;

const AddressRemove = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    color: ${props => props.theme.HCErr2};
  }
`;

const AddressNumb = styled.span`
  background-color: ${props => props.theme.HC1};
  color: ${props => props.theme.BGC};
  border-radius: 50%;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  min-width: 30px;
  padding-bottom: 2px;
  margin: 0 20px 0 30px;
`;

const AddressListItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 84px;
  margin: 0 auto;
  border-bottom: 2px solid ${props => props.theme.Border};
  padding: 15px;
  &:last-child {
    border: none;
  }
  @media only screen and (min-width: 800px) {
    grid-template-columns: 1fr 1fr 84px;
    padding: 28px 10px;
  }
`;

const AddressListContent = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 0.5em;
`;

const AddressRadiusSlider = styled.div`
  min-height: 28px;
  grid-row-start: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (min-width: 800px) {
    grid-row-start: auto;
  }
`;

const RadiusDescription = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 0 10px;
  grid-row-start: 1;
  grid-row-end: span 2;
  @media only screen and (min-width: 800px) {
    grid-row-start: auto;
    grid-row-end: auto;
  }
`;

const RadiusLinkedWrapper = styled.div`
  font-size: 0.9em;
  margin: 20px 0;
  display: flex;
  input {
    margin: 0 6px 0;
  }
`;

const MapWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  margin: auto;
`;

const InfoContainer = styled.div`
  margin: 3em 0;
  width: 50%;
`;

const SlideWrapper = styled.div`
  display: grid;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin: ${props => (props.margin ? "0 1em" : "0")}
  grid-template-columns: 80px 1fr 80px;
`;

const CampaignBudgetWrapper = styled(SlideWrapper)`
  align-items: flex-end;
`;

const SlideLabel = styled.span`
  font-size: 0.9em;
`;

const NumberSlide = styled.input`
  -webkit-appearance: none;
  width: 93%;
  height: 3px;
  border-radius: 5px;
  /* margin: 0 15px; */
  margin: auto;
  background: #d3d3d3;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: ${props =>
      props.readOnly ? props.theme.TextC : props.theme.HC1};
    cursor: ${props => (props.readOnly ? "no-drop" : "pointer")};
    /* box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
      0 1px 3px 1px rgba(60, 64, 67, 0.16); */
  }
  ::-moz-range-thumb {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: ${props =>
      props.readOnly ? props.theme.TextC : props.theme.HC1};
    cursor: ${props => (props.readOnly ? "no-drop" : "pointer")};
    /* box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
      0 1px 3px 1px rgba(60, 64, 67, 0.16); */
  }
`;

const NumberSlideLabel = styled.div`
  position: relative;
  height: 2em;
  margin-left: 1em;
  margin-right: 0.6em;
`;

const NumberSlideText = styled.div`
  margin-left: -1em;
  width: 2em;
  position: absolute;
  font-size: 18px;
  font-weight: 400;
`;

const BudgetWrapper = styled.div`
  margin: 3em auto;
  max-width: 950px;
  display: flex;
  justify-content: center;
  align-items: baseline;
`;

const BudgetContainer = styled.div`
  margin: 0 3em;
  max-width: 290px;
  p {
    text-align: center;
  }
`;

const ReachContainer = styled.div`
  margin: 0 3em;
  p {
    text-align: center;
  }
`;

const ReachError = styled.p`
  margin: 0;
  font-size: 0.8em;
`;

class AreaCampaign extends React.Component {
  // addressForm = React.createRef();
  state = {
    loading: true,
    mapOptions: {
      zoom: 10,
      center: {
        lat: 40.089195,
        lng: -76.1604385
      }
    },
    pendingCampaign: {},
    mapsMarkers: [],
    mapDraggable: true,
    modalExistingCampaign: false,
    redirectToCustomer: false,
    redirectStartOver: false,
    didMountDone: false,
    errMessages: {},
    errFields: [],
    addressLoading: false,
    potentialReach: 0,
    potentialReachRaw: 0,
    reachPercentage: 0,
    segment: {},
    segmentSpecialAdCategory: "NONE",
    floaters: {
      photoAd: false,
      videoAd: false,
      reachWarning: false
    },
    averageLeadCost: 50,
    estimatedLeads: 0,
    budgetPercent: 0
  };

  async componentDidMount() {
    await this.getCampaign();
    // get targeting segment
    await this.getSegment();
    new google.maps.places.Autocomplete(this.address);
    this.props.checkPermissions();
    this.handleAveLeadEstimate();
  }

  async componentDidUpdate(prevProps) {
    const { businessPage: prevBusinessPage } = prevProps;
    const { businessPage } = this.props;
    if (businessPage !== prevBusinessPage) {
      await this.getCampaign();
    }
  }

  getCampaign = async () => {
    const { history } = this.props;
    try {
      const campaigns = await campaignGetDrafts();
      if (campaigns.length === 0) {
        //console.log('no campaigns');
        history.push("/campaign-select");
      }
      if (history.action === "POP") {
        this.setState({
          modalExistingCampaign: true
        });
      }
      const { credits = {} } = campaigns[0];
      let budgetPercent = 0;
      if (credits.original) {
        budgetPercent = ((credits.original - 60) * 100) / 5000;
      }
      this.setState({ pendingCampaign: campaigns[0], budgetPercent }, () => {
        const reachThrottle = _.debounce(this.getPotentialReach, 100);
        reachThrottle();
      });
    } catch (err) {
      const { status, message } = errorHandler(err);
      Sentry.captureException(err);
      // console.log('err', `${status}: ${message}`);
    }
  };

  updateCampaign = async campaign => {
    try {
      campaignUpdate(campaign);
      this.setState({ pendingCampaign: campaign }, () => {
        const reachThrottle = _.debounce(this.getPotentialReach, 100);
        reachThrottle();
      });
    } catch (err) {
      const { status, message } = errorHandler(err);
      Sentry.captureException(err);
      // console.log('err', `${status}: ${message}`);
    }
  };

  getSegment = async () => {
    const { targetingId } = this.state.pendingCampaign;
    try {
      const targeting = await targetingGetById(targetingId);
      const { averageLeadCost = 50, specialAdCategories } = targeting;
      const segmentSpecialAdCategory = specialAdCategories[0];
      this.setState({
        segment: targeting,
        averageLeadCost,
        segmentSpecialAdCategory: segmentSpecialAdCategory || "NONE"
      });
    } catch (err) {
      const { status, message } = errorHandler(err);
      Sentry.captureException(err);
      // console.log('err', `${status}: ${message}`);
    }
  };

  newAddressToMap = () => {
    this.setState({ addressLoading: true });
    const { alert } = this.props;
    const address = this.address.value;
    const { pendingCampaign, segmentSpecialAdCategory } = this.state;
    const { addresses = [], radiusLinked } = pendingCampaign;
    if (
      addresses.some(thisAddress => thisAddress.formattedAddress === address)
    ) {
      alert({ type: "error", msg: "Address already added." });
      this.setState({ addressLoading: false });
      return;
    }
    // Get latitude & longitude from address.
    Geocode.fromAddress(address).then(
      data => {
        const { results, status } = data;
        if (status === `OK`) {
          const latLng = results[0].geometry.location;
          addresses.push({
            formattedAddress: address,
            ...latLng,
            ...(radiusLinked && addresses[0]
              ? { radius: addresses[0].radius }
              : { radius: segmentSpecialAdCategory === "NONE" ? 5 : 15 })
          });
          this.updateCampaign({
            ...pendingCampaign,
            addresses
          });
        } else {
          // TODO: refactor to use alert library
          alert({
            type: "error",
            msr: `Geocode was not successful for the following reason: ${status}`
          });
        }
      },
      error => {
        Sentry.captureException(error);
        //console.error(error);
        alert({
          type: "error",
          msg: `Geocode was not successful for the following reason: ${error.message}`
        });
      }
    );
    this.setState({ addressLoading: false });
    this.addressForm.reset();
  };

  addAddress = async ({ lat, lng }) => {
    const { pendingCampaign, segmentSpecialAdCategory } = this.state;
    const { addresses = [], radiusLinked } = pendingCampaign;
    const formattedAddress = await this.getAddress({ lat, lng });
    addresses.push({
      formattedAddress,
      lat: lat,
      lng: lng,
      ...(radiusLinked && addresses[0]
        ? { radius: addresses[0].radius }
        : { radius: segmentSpecialAdCategory === "NONE" ? 5 : 15 })
    });
    this.updateCampaign({
      ...pendingCampaign,
      addresses
    });
  };

  updateAddress = async (latLng, index) => {
    const { pendingCampaign } = this.state;
    const { addresses = [] } = pendingCampaign;
    const formattedAddress = await this.getAddress(latLng);
    if (addresses[index]) {
      addresses[index] = {
        ...addresses[index],
        formattedAddress,
        lat: latLng.lat,
        lng: latLng.lng
      };
    }
    this.updateCampaign({
      ...pendingCampaign,
      addresses
    });
  };

  getAddress = async ({ lat, lng }) => {
    try {
      const response = await Geocode.fromLatLng(lat, lng);
      const addresses = response.results.filter(
        result => result.geometry.location_type === "APPROXIMATE"
      );
      return addresses[0].formatted_address;
    } catch (err) {
      Sentry.captureException(err);
      // console.log('error getting address', err.message);
    }
  };

  setMapDraggable = draggable => {
    this.setState({ mapDraggable: draggable });
  };

  sliderBudgetChange = async value => {
    const budget = parseInt(value);
    const { pendingCampaign = {} } = this.state;
    const { credits } = pendingCampaign;
    if ((typeof budget !== "number" || isNaN(budget)) && value) {
      // console.log('budget is not a number');
      return;
    }
    let budgetPercent;
    if (budget > 0 && typeof budget === "number") {
      budgetPercent = ((budget - 60) * 100) / 5000;
    } else {
      budgetPercent = ((0 - 60) * 100) / 5000;
    }
    this.setState({ budgetPercent });
    try {
      await this.updateCampaign({
        ...pendingCampaign,
        credits: {
          ...credits,
          original: !isNaN(budget) ? budget : ""
        }
      });
      this.handleAveLeadEstimate();
      this.handleFieldValidation();
    } catch (err) {
      Sentry.captureException(err);
      // console.error('Error writing document: ', err);
    }
  };

  sliderRadiusChange = async event => {
    const areaRadius = parseInt(event.target.value);
    const { pendingCampaign } = this.state;
    const { addresses = [] } = pendingCampaign;
    const newAddresses = addresses.map(address => {
      address.radius = areaRadius;
      return address;
    });
    const sliderDebounce = _.debounce(this.updateCampaign, 100);
    sliderDebounce({
      ...pendingCampaign,
      addresses: newAddresses
    });
  };

  addressRadiusChange = async (event, index) => {
    const { pendingCampaign } = this.state;
    const newRadius = parseInt(event.target.value);
    const { addresses = [] } = pendingCampaign;
    addresses[index].radius = newRadius;
    const sliderDebounce = _.debounce(this.updateCampaign, 100);
    sliderDebounce({
      ...pendingCampaign,
      addresses
    });
  };

  handleAdType = type => {
    const { pendingCampaign } = this.state;
    let { media = [] } = pendingCampaign;
    const mediaExists = media.some(item => item.mediaType === type);
    if (mediaExists) {
      media = media.filter(item => item.mediaType !== type);
    } else {
      media.push({
        filename: "",
        mediaType: type,
        status: "ready",
        videoId: null,
        title: ""
      });
    }
    try {
      this.updateCampaign({
        ...pendingCampaign,
        media
      });
    } catch (err) {
      Sentry.captureException(err);
      //console.log('error updating campaign', err.response);
    }
  };

  handleRemoveAddress = index => {
    const { pendingCampaign } = this.state;
    const { addresses = [] } = pendingCampaign;
    const newAddresses = addresses;
    newAddresses.splice(index, 1);
    this.updateCampaign({
      ...pendingCampaign,
      addresses: newAddresses
    });
  };

  getPotentialReach = async () => {
    const { pendingCampaign } = this.state;
    const { addresses, specialAdCategories = ["NONE"] } = pendingCampaign;
    const { adAccountId } = this.props.businessPage;
    const { segment } = this.state;
    const { targeting_spec: targeting = {} } = segment;
    if (addresses.length > 0 && adAccountId) {
      try {
        const potentialReachReturn = await estimatedReachGet({
          address: addresses,
          adAccountId,
          targeting,
          specialAdCategories
        });
        const { minReach = 5000, maxReach = 250000 } = segment;
        const { users_lower_bound, users_upper_bound } = potentialReachReturn;
        if (!users_lower_bound || !users_upper_bound) {
          throw new Error("failed to get estimated reach data");
        }
        const potentialReach =
          (users_lower_bound + users_upper_bound) / 2 / 1000 + "K";
        const potentialReachRaw = (users_lower_bound + users_upper_bound) / 2;
        let potentialReachRawCalc = potentialReachRaw;
        if (potentialReachRawCalc > maxReach) {
          potentialReachRawCalc = maxReach;
        }
        let reachPercentage = Math.round(
          (potentialReachRawCalc / maxReach) * 100
        );
        if (potentialReachRawCalc <= minReach) {
          reachPercentage = 9;
        }
        if (potentialReachRawCalc > minReach) {
          if (
            reachPercentage < 30 &&
            reachPercentage < 50 &&
            potentialReachRawCalc < maxReach
          ) {
            reachPercentage = 30;
          } else if (
            reachPercentage < 50 &&
            reachPercentage < 70 &&
            potentialReachRawCalc < maxReach
          ) {
            reachPercentage = 50;
          } else if (reachPercentage < 94 && potentialReachRawCalc < maxReach) {
            reachPercentage = 70;
          } else if (potentialReachRawCalc >= maxReach) {
            reachPercentage = 94;
          }
        }
        this.setState({
          potentialReach,
          potentialReachRaw,
          reachPercentage
        });
      } catch (err) {
        const { status, message } = errorHandler(err);
        Sentry.captureException(err);
        //console.log('err', `${status}:`, message);
      }
    }
  };

  handleDateRangeClose = async (start, end) => {
    const { pendingCampaign } = this.state;
    const startMoment = moment(new Date(start));
    const endMoment = moment(new Date(end));
    const startDate = startMoment.format("YYYY-MM-DD");
    const endDate = endMoment.format("YYYY-MM-DD");
    const startDate_timestamp = moment(new Date(start)).startOf("day").toDate();
    const endDate_timestamp = moment(new Date(end)).endOf("day").toDate();
    this.updateCampaign({
      ...pendingCampaign,
      startDate: start,
      endDate: end
    });
  };

  handleNextButton = async () => {
    await this.setState({
      errMessages: {},
      errFields: []
    });
    this.handleFieldValidation(() => {})
      .then(isValid => {
        if (isValid) {
          const { media } = this.state.pendingCampaign;
          this.props.history.push("/create-ad");
          // if (media.some(item => item.mediaType === 'IMAGE')) {
          //   this.props.history.push('/create-image-ad');
          // } else {
          //   this.props.history.push('/create-video-ad');
          // }
        } else {
          // alert("Form has errors.")
          const { errFields } = this.state;
          if (errFields.length > 0) {
            let errFieldsString = "";
            if (errFields.length === 1) {
              errFieldsString = errFields[0];
            } else if (errFields.length === 2) {
              errFieldsString = errFields.join(" and ");
            } else {
              errFields[errFields.length - 1] =
                "and " + errFields[errFields.length - 1];
              errFieldsString = errFields.join(", ");
            }
            this.props.alert({
              type: "error",
              msg: `You are missing ${errFieldsString}`
            });
          }
          this.props.alert({
            type: "error",
            msg: "Some required fields are missing"
          });
        }
      })
      .catch(reject => {
        Sentry.captureException(reject);
        //console.error('reject ', reject);
      });
  };

  handleFieldValidation = async () => {
    let fieldsAreValid = true;
    const { segmentSpecialAdCategory } = this.state;
    const {
      addresses = [],
      credits,
      startDate,
      endDate
    } = this.state.pendingCampaign;
    let errMessages = {};
    const errFields = [];
    try {
      if (addresses.length === 0) {
        fieldsAreValid = false;
        errMessages = {
          ...errMessages,
          noAddress: "Please select at least one address for your campaign"
        };
        errFields.push("at least one address");
      }
    } catch (err) {
      // alert an error of something wrong with checking this field
      Sentry.captureException(err);
      //console.error(err);
    }
    try {
      //TODO: calculation should be moved to API
      const budgetActualSpend = credits.original * 0.9;
      const daysDiffValue = getDaysDiff({
        endDate,
        startDate
      });
      const budgetPerDay = budgetActualSpend / daysDiffValue;
      //TODO: calculation should be moved to API
      const budgetMinimum = formatMoney(Math.ceil((daysDiffValue * 5) / 0.9));
      if (budgetPerDay < 5) {
        fieldsAreValid = false;
        errMessages = {
          ...errMessages,
          budgetMin:
            credits.original === 0
              ? `It looks like your total budget is zero. Boostpoint requires a minimum ad spend of $5.55 per day.`
              : `Your total budget is below the minimum of ${budgetMinimum}. Boostpoint requires a minimum ad spend of $5.55 per day. You can either increase your budget or decrease the number of days selected.`
        };
        errFields.push("a valid budget");
      }
      const endDateObject = new Date(endDate);
      if (endDateObject < new Date()) {
        fieldsAreValid = false;
        errMessages = {
          ...errMessages,
          dateError: "The end date needs to be in the future."
        };
        errFields.push("a valid end date");
      }
    } catch (err) {
      // alert an error of something wrong with checking this field
      Sentry.captureException(err);
      //console.error(err);
    }
    try {
      if (segmentSpecialAdCategory !== "NONE") {
        const minimumRadius = Math.min(...addresses.map(a => a.radius));
        if (minimumRadius < 15) {
          fieldsAreValid = false;
          errMessages = {
            ...errMessages,
            radiusError: `It is against Facebook's Discrimination Policy to have a radius less than 15 miles when running a campaign with a Special Ad Category. Please change the radius to be higher than or equal to 15 miles.`
          };
          errFields.push("a radius higher than 15 miles");
        }
      }
    } catch (err) {
      // alert an error of something wrong with checking this field
      //console.error(err);
      Sentry.captureException(err);
    }
    this.setState({ errMessages, errFields });
    return fieldsAreValid;
  };

  handleContinue = async () => {
    try {
      this.setState({ modalExistingCampaign: false });
    } catch (err) {
      //console.log(err);
      Sentry.captureException(err);
    }
  };

  handleStartOver = async () => {
    try {
      const campaigns = await campaignGetDrafts();
      const campaignIds = campaigns.map(c => c._id);
      if (campaignIds.length > 0) {
        const wait = [];
        campaignIds.forEach(c => wait.push(campaignDelete(c)));
        await Promise.all(wait);
      }
      this.setState({ modalExistingCampaign: false, redirectStartOver: true });
    } catch (err) {
      const { status, message } = errorHandler(err);
      //console.log('err', `${status}: ${message}`);
      Sentry.captureException(err);
    }
  };

  handleAveLeadEstimate = () => {
    const { averageLeadCost, pendingCampaign } = this.state;
    const { credits: { original: totalBudget = 0 } = {} } = pendingCampaign;
    const estimatedLeads = Math.floor(totalBudget / averageLeadCost);
    const percentChange = 0.25;
    let estimatedLeadsMin = Math.floor(
      totalBudget / (averageLeadCost + averageLeadCost * percentChange)
    );
    if (estimatedLeadsMin < 0) {
      estimatedLeadsMin = 0;
    }
    const estimatedLeadsMax = Math.floor(
      totalBudget / (averageLeadCost - averageLeadCost * percentChange)
    );
    this.setState({ estimatedLeads, estimatedLeadsMin, estimatedLeadsMax });
  };

  handleFloaterClick = (e, clickTarget) => {
    e.preventDefault();
    const { floaters } = this.state;
    floaters[clickTarget] = !floaters[clickTarget];
    this.setState({ floaters });
  };

  handleRadiusUnlink = async () => {
    const { pendingCampaign } = this.state;
    const unlinkedCampaign = {
      ...pendingCampaign,
      radiusLinked: false
    };
    this.updateCampaign({
      ...unlinkedCampaign
    });
  };

  handleRadiusLink = async () => {
    const { pendingCampaign, segmentSpecialAdCategory } = this.state;
    const { addresses = [] } = pendingCampaign;
    let areaRadius = addresses[0]
      ? addresses[0].radius
      : segmentSpecialAdCategory === "NONE"
      ? 5
      : 15;
    const newAddresses = addresses.map((address, index) => {
      if (index === 0) {
        areaRadius = address.radius;
      }
      address.radius = areaRadius;
      return address;
    });
    try {
      this.updateCampaign({
        ...pendingCampaign,
        radiusLinked: true,
        addresses: newAddresses
      });
    } catch (err) {
      Sentry.captureException(err);
      //console.error('Error writing document: ', err);
    }
  };

  render() {
    const { businessPage = {} } = this.props;
    const {
      pendingCampaign: {
        startDate,
        endDate,
        credits: { original: budgetTotal = 0 } = {},
        areaRadius,
        addresses = [],
        type,
        radiusLinked = true
      } = {},
      averageLeadCost,
      estimatedLeadsMin,
      estimatedLeadsMax,
      budgetPercent,
      segmentSpecialAdCategory
    } = this.state;
    const { addressLoading, potentialReach, mapOptions, segment } = this.state;
    const daysDiff = moment(endDate, "YYYY-MM-DD").diff(
      moment(startDate, "YYYY-MM-DD"),
      "days"
    );

    return (
      <div id='custCampaignWrapper'>
        <TitleWrapper>
          <TitleLeft>
            <H1>{segment && segment.name ? segment.name : "New"} Campaign</H1>
          </TitleLeft>
        </TitleWrapper>
        <Card id='area-information'>
          <CardTitle>
            <Title>Area Information</Title>
            <H5>
              Choose the area you would like to target. Boostpoint will
              automatically show your ads to people in those areas.
            </H5>
          </CardTitle>
          <AddressSearch id='cust-camp-form' ref='areaCampForm'>
            <form
              ref={input => {
                this.addressForm = input;
              }}
            >
              <AddressContainer campaign>
                <InputLabel>Address</InputLabel>
                <InputBox
                  ref={input => {
                    this.address = input;
                  }}
                  type='text'
                  name='address'
                  placeholder='Address'
                  id='address-search'
                  onKeyDown={e => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                      this.newAddressToMap();
                    }
                  }}
                />
              </AddressContainer>
              <AddressSubmit campaign>
                <Button
                  customMargin={"0"}
                  type='button'
                  onClick={() => {
                    this.newAddressToMap();
                  }}
                  disabled={addressLoading}
                >
                  Submit {addressLoading && <LoaderDotsSmall />}
                </Button>
              </AddressSubmit>
            </form>
            {/* Error message for this input */}
            <InputError>{this.state.errMessages.noAddress}</InputError>
          </AddressSearch>
          <ReachWrapper>
            <InfoContainer className='radius-wrapper'>
              <p>
                <strong>Potential Reach:</strong>
              </p>
              <Subtitle>{potentialReach} people</Subtitle>
              {!businessPage.adAccountId && (
                <ReachError>
                  Cannot get potential reach because your Facebook account is
                  not connected or is incomplete.
                </ReachError>
              )}
            </InfoContainer>
            {radiusLinked ? (
              <InfoContainer className='radius-wrapper'>
                <p>
                  <strong>Radius (Miles):</strong>
                  <NumberDisplay
                    onChange={this.sliderRadiusChange}
                    onBlur={this.handleFieldValidation}
                    type='text'
                    value={
                      radiusLinked && addresses[0]
                        ? addresses[0].radius
                        : segmentSpecialAdCategory === "NONE"
                        ? 5
                        : 15
                    }
                    htmlFor='rangeInput'
                    readOnly
                    disabled={!radiusLinked}
                  />
                </p>
                <SlideWrapper>
                  <SlideLabel>Minimum</SlideLabel>
                  <NumberSlide
                    onChange={this.sliderRadiusChange}
                    type='range'
                    min={
                      !_.isEmpty(segment) &&
                      segmentSpecialAdCategory &&
                      segmentSpecialAdCategory !== "NONE"
                        ? "15"
                        : "1"
                    }
                    max='50'
                    value={
                      radiusLinked && addresses[0]
                        ? addresses[0].radius
                        : segmentSpecialAdCategory === "NONE"
                        ? 5
                        : 15
                    }
                    step='1'
                    disabled={!radiusLinked}
                  />
                  <SlideLabel>Maximum</SlideLabel>
                </SlideWrapper>
                <RadiusLinkedWrapper className='radius-linked'>
                  <Checkbox
                    type='checkbox'
                    value='radiusLinked'
                    name='radiusLinked'
                    onChange={this.handleRadiusUnlink}
                    checked={radiusLinked}
                  />
                  <label onClick={this.handleRadiusUnlink}>
                    Uncheck this option to set individual address radius' below.
                  </label>
                </RadiusLinkedWrapper>
                <InputError>{this.state.errMessages.radiusError}</InputError>
              </InfoContainer>
            ) : (
              <InfoContainer className='radius-wrapper'>
                <SlideWrapper>
                  <SlideLabel>Minimum</SlideLabel>
                  <NumberSlide
                    readOnly={true}
                    type='range'
                    min={
                      !_.isEmpty(segment) &&
                      segmentSpecialAdCategory &&
                      segmentSpecialAdCategory !== "NONE"
                        ? "15"
                        : "1"
                    }
                    max='50'
                    value={areaRadius}
                    step='1'
                    disabled={!radiusLinked}
                  />
                  <SlideLabel>Maximum</SlideLabel>
                </SlideWrapper>

                <RadiusLinkedWrapper>
                  <Checkbox
                    type='checkbox'
                    value='radiusLinked'
                    name='radiusLinked'
                    onChange={this.handleRadiusLink}
                    checked={radiusLinked}
                  />
                  <label onClick={this.handleRadiusLink}>
                    Using address radius sliders below. Check this option to use
                    the master radius slider above and set all address radiuses
                    the same.
                  </label>
                </RadiusLinkedWrapper>
                <InputError>{this.state.errMessages.radiusError}</InputError>
              </InfoContainer>
            )}
          </ReachWrapper>
          <MapWrapper>
            <GoogleRadiusMap
              addresses={[...addresses]}
              radius={areaRadius}
              options={mapOptions}
              addAddress={this.addAddress}
              mapDraggable={this.state.mapDraggable}
              setMapDraggable={this.setMapDraggable}
              updateAddress={this.updateAddress}
              moveMarker={this.moveMarker}
            />
          </MapWrapper>
          <AddressResults>
            <AddressWrapper id='addressList'>
              {addresses.map((address, index) => (
                <AddressListItem key={`address${index}`}>
                  <AddressListContent>
                    <AddressRemove>
                      <i
                        className='material-icons'
                        title='Remove Address'
                        onClick={() => {
                          this.handleRemoveAddress(index);
                        }}
                      >
                        remove_circle_outline
                      </i>
                    </AddressRemove>
                    <AddressNumb>{index + 1}</AddressNumb>
                    <div name='address'>{address.formattedAddress}</div>
                  </AddressListContent>
                  <AddressRadiusSlider>
                    {radiusLinked ? (
                      <SlideWrapper margin={true}>
                        <span>Minimum</span>
                        <NumberSlide
                          type='range'
                          readOnly={true}
                          min={
                            !_.isEmpty(segment) &&
                            segmentSpecialAdCategory &&
                            segmentSpecialAdCategory !== "NONE"
                              ? "15"
                              : "1"
                          }
                          max='50'
                          value={areaRadius}
                          step='1'
                          disabled={radiusLinked}
                        />
                        <span>Maximum</span>
                      </SlideWrapper>
                    ) : (
                      <SlideWrapper margin={true}>
                        <span>Minimum</span>
                        <NumberSlide
                          onChange={e => this.addressRadiusChange(e, index)}
                          type='range'
                          min={
                            !_.isEmpty(segment) &&
                            segmentSpecialAdCategory &&
                            segmentSpecialAdCategory !== "NONE"
                              ? "15"
                              : "1"
                          }
                          max='50'
                          value={address.radius}
                          step='1'
                        />
                        <span>Maximum</span>
                      </SlideWrapper>
                    )}
                  </AddressRadiusSlider>
                  <RadiusDescription>
                    <NumberDisplay
                      type='text'
                      value={address.radius}
                      htmlFor='rangeInput'
                      readOnly
                    />
                    {/* {!radiusLinked && <span>{address.radius} mile radius</span>} */}
                  </RadiusDescription>
                </AddressListItem>
              ))}
              {addresses.length === 0 && <li>No Addresses Added.</li>}
            </AddressWrapper>
            <InputError>{this.state.errMessages.addressRadius}</InputError>
          </AddressResults>
        </Card>
        <Card>
          <CardTitle>
            <Title>Ad Budget</Title>
            <Subtitle>Set a budget for the life of your campaign.</Subtitle>
          </CardTitle>
          <BudgetWrapper>
            <BudgetContainer>
              <p>Total Campaign Budget: </p>
              <CampaignBudgetWrapper>
                <SlideLabel>Minimum</SlideLabel>
                <div>
                  <NumberSlideLabel>
                    <NumberSlideText style={{ left: `${budgetPercent}%` }}>
                      {budgetTotal}
                    </NumberSlideText>
                  </NumberSlideLabel>
                  <NumberSlide
                    onChange={input =>
                      this.sliderBudgetChange(input.target.value)
                    }
                    type='range'
                    min='60'
                    max='5000'
                    value={budgetTotal}
                    step='20'
                  />
                </div>
                <SlideLabel>Maximum</SlideLabel>
              </CampaignBudgetWrapper>
              <label>Total Budget</label>
              <SliderInput
                type='text'
                name='budget'
                value={budgetTotal}
                onChange={input => this.sliderBudgetChange(input.target.value)}
                size='10'
              />
              <InputError>{this.state.errMessages.budgetMin}</InputError>
            </BudgetContainer>
            {type === "LeadAd" && (
              <ReachContainer>
                <p>
                  <strong>Estimated Results</strong>
                  <br />
                  {estimatedLeadsMin && estimatedLeadsMax && (
                    <span>
                      {estimatedLeadsMin} - {estimatedLeadsMax} Leads
                    </span>
                  )}
                </p>
                <Disclaimer>
                  The estimated results are calculated from an industry average
                  of ${averageLeadCost} per lead. This number is only an
                  estimate and doesn't guarantee results. Factors like audience
                  size and ad quality might affect your results.
                </Disclaimer>
              </ReachContainer>
            )}
          </BudgetWrapper>
        </Card>
        <Card>
          {/* <SectionGroup id="fbCheckedResult" className="checkedBoxResults DateRangePicker-centered"> */}
          <CardTitle>
            <Title>Ad Schedule</Title>
            <h6>
              Choose the date range schedule for your ad to run. We have
              selected a default schedule based on your budget selection above.
            </h6>
          </CardTitle>
          <div className='DateRangePicker-centered'>
            {/* <AdScheduleRange pendingCampaign={this.state.pendingCampaign} userProfile={this.props.userProfile} /> */}

            <DateRangePickerWrapper
              onClose={(startDateClose, endDateClose) =>
                this.handleDateRangeClose(startDateClose, endDateClose)
              }
              isAdSchedule
              initStartDate={startDate}
              initEndDate={endDate}
            />

            <h4>{daysDiff} Days</h4>
            <InputError>{this.state.errMessages.dateError}</InputError>
          </div>
          <div>
            <SubmitButton
              className='next'
              type='button'
              onClick={this.handleNextButton}
            >
              Next -&gt;
            </SubmitButton>
          </div>
        </Card>
        <BpModal
          open={this.state.modalExistingCampaign}
          title={`You have an existing ${type} campaign`}
          primaryAction={{
            btnLabel: "Continue",
            action: this.handleContinue
          }}
          secondaryAction={{
            btnLabel: "Start Over",
            action: this.handleStartOver
          }}
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
          contentLabel='Existing Campaign'
          body={<p>Would you like to continue with this campaign?</p>}
          width='auto'
        />
        {this.state.redirectStartOver && (
          <Redirect to='/campaign-select' push />
        )}
      </div>
    );
  }
}

export default withTheme(AreaCampaign);
