import React from "react";
import styled from "styled-components";
import { customFieldsGetByCompany } from "../../../utils/FacebookAPI";
import {
  integrationCreate,
  integrationFieldsByType,
  integrationGetById,
  integrationUpdate
} from "../../../utils/IntegrationsAPI";
import IntegrationModal from "./IntegrationModal";
import { LoaderDotsSmall } from "../../elements/Loaders";
import { Button, TxtButton } from "../../styled/Buttons";
import { Title } from "../../styled/Headers";
import {
  Dropdown,
  InputBox,
  InputBoxWrapper,
  InputContainer
} from "../../styled/Input";
import { Card, CardTitle } from "../../styled/Layout";
import LoadingCircle from "../../styled/LoadingCircle";

const CardResponsive = styled.div`
  background-color: ${props => props.theme.BGC};
  margin: 2.5em;
  padding: 0 0 45px;
  box-shadow: 0px 0px 16px #00000015;
  display: ${props => (props.showOnMobileOnly ? "flex" : "none")};
  flex-direction: column;
  @media only screen and (max-width: 1060px) {
    margin: 2.5em 0.5em;
  }
  @media only screen and (min-width: 800px) {
    display: ${props => (props.showOnMobileOnly ? "none" : "flex")};
  }
  p {
    max-width: 768px;
    margin: 0 auto 20px;
    text-align: left;
  }
  ul {
    list-style: disc;
    list-style-position: inside;
    margin-top: 20px;
  }
`;

const IntegrationIcon = styled.div`
  text-align: center;
  margin: 0 auto 16px;
  img {
    margin-bottom: 10px;
    width: 2em;
    border-radius: 5px;
  }
  @media only screen and (min-width: 700px) {
    margin: 0 auto 20px;
  }
`;

const ArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  @media only screen and (min-width: 700px) {
    margin: 0px 0 20px;
  }
`;

const ArrowIcon = styled.span`
  transform: rotate(90deg);
  @media only screen and (min-width: 700px) {
    transform: none;
  }
`;

const InputContainerField = styled(InputContainer)`
  margin-bottom: 5px;
  width: auto;
  margin: 0 65px 30px;
  @media only screen and (min-width: 700px) {
    margin: 0 auto 30px;
    width: 100%;
  }
`;

const MappingRow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin: 36px auto 0;
  border-bottom: 1px solid gray;
  padding-bottom: 30px;
  @media only screen and (min-width: 700px) {
    grid-template-columns: 1fr 25px 1fr;
    margin: 15px 155px;
    padding: 0 30px;
    border: none;
  }
`;

const bpContactFields = {
  firstName: "First Name",
  lastName: "Last Name",
  company: "Company",
  email: "Email",
  phone: "Phone Number",
  mobilePhone: "Mobile Phone",
  address: "Address",
  address2: "Address 2",
  city: "City",
  contactState: "State",
  zip: "Zip",
  website: "Website"
};

const logos = {
  MARKET_SHARP: { name: "Market Sharp", logo: "marketsharp_logo" },
  JOB_NIMBUS: { name: "Job Nimbus", logo: "jobnimbus_logo" },
  ZOHO: "Zoho_logo",
  HATCH: "hatch_logo",
  EZ_TEXT: "EZTexting_logo",
  BOOSTPOINT_SMS: "boostpoint_sms",
  CONSTANT_CONTACT: "ConstantContact_logo"
};

const FieldMapping = props => {
  // const { integration } = props;
  const [integration, setIntegration] = React.useState({});

  const [fields, setFields] = React.useState([]);
  const [fieldMappings, setFieldMappings] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const getFields = async () => {
      const customFields = await customFieldsGetByCompany();
      console.log("fields", customFields);
      if (customFields && customFields.fields) {
        setFields(customFields.fields.filter(f => f.status === "ACTIVE"));
      }
      getIntegration();
    };
    getFields();
  }, []);

  const getIntegration = async () => {
    // const integrationData = await integrationGetById(
    //   '62742dc45d3208211c5deb53'
    // );
    // setIntegration(integrationData);
    // setLoading(false);
  };

  React.useEffect(() => {
    setLoading(true);
    const { fieldMappings: newMapping = [] } = integration;
    setFieldMappings(newMapping);
    setLoading(false);
  }, [integration]);

  const changeField = mapping => {
    setFieldMappings(mapping);
  };

  const resetFields = () => {
    const { fieldMappings: newMapping = [] } = integration;
    setFieldMappings(newMapping);
  };

  const saveMapping = async () => {
    const update = { ...integration, fieldMappings };
    console.log("update", update);
    const newIntegration = await integrationUpdate(update);
    console.log("newIntegration", newIntegration);
    setIntegration(newIntegration);
  };

  return loading ? (
    <Card>
      <LoadingCircle />
    </Card>
  ) : (
    <div>
      <FieldMappingMobile
        integration={integration}
        fields={fields}
        fieldMappings={fieldMappings}
        integrationFields={bpContactFields}
        loading={loading}
        changeField={changeField}
        resetFields={resetFields}
        saveMapping={saveMapping}
      />
      <FieldMappingDesktop
        integration={integration}
        fields={fields}
        fieldMappings={fieldMappings}
        integrationFields={bpContactFields}
        loading={loading}
        changeField={changeField}
        resetFields={resetFields}
        saveMapping={saveMapping}
      />
    </div>
  );
};

export default FieldMapping;

const FieldMappingMobile = props => {
  const {
    integration,
    fields = [],
    fieldMappings = [],
    integrationFields = {}
  } = props;
  const [selectedField, setSelectedField] = React.useState({});
  const [selectedMap, setSelectedMap] = React.useState({});
  const [mappingChanged, setMappingChanged] = React.useState(false);

  React.useEffect(() => {
    const getFields = async () => {
      if (fields[0]) {
        setSelectedField(fields[0]);
      }
    };
    getFields();
  }, [fields]);

  const changeSelectedField = value => {
    const selected = fields.find(f => f.key === value);
    if (selected) {
      setSelectedField(selected);
      const custom = fieldMappings.find(f => f.sourceField === selected.key);
      if (custom) {
        setSelectedMap(custom);
      } else {
        setSelectedMap({});
      }
    }
    console.log("selected", selectedField, selectedMap);
  };

  const changeField = value => {
    console.log("value", value);
    setMappingChanged(true);
    const newMapping = fieldMappings.find(f => f.destinationField === value);
    if (newMapping) {
      newMapping.sourceField = selectedField.key;
      props.changeField([
        ...fieldMappings.filter(f => f.destinationField !== value),
        newMapping
      ]);
      setSelectedMap(newMapping);
    } else {
      setSelectedMap({
        sourceField: selectedField.key,
        destinationField: value
      });
      props.changeField([
        ...fieldMappings.filter(f => f.destinationField !== value),
        { sourceField: selectedField.key, destinationField: value }
      ]);
    }
  };

  const clearMapping = async () => {
    const { fieldMappings: newMapping = [] } = integration;
    props.resetFields();
    setMappingChanged(false);
    const currentMap = newMapping.find(
      f => f.destinationField === selectedMap.destinationField
    );
    if (currentMap) {
      setSelectedMap(currentMap);
    } else {
      setSelectedMap({});
    }
  };

  return (
    <CardResponsive showOnMobileOnly={true}>
      {/* mobile version */}
      <CardTitle>
        <Title>Field Mapping</Title>
      </CardTitle>
      <InputContainerField>
        <IntegrationIcon>Source Field</IntegrationIcon>
        <InputBoxWrapper>
          <Dropdown
            type='text'
            value={selectedMap.destinationField || ""}
            onChange={input => changeField(input.target.value)}
          >
            <option value=''>Not Assigned</option>
            {Object.keys(integrationFields).map(key => (
              <option key={key} value={key}>
                {integrationFields[key]}
              </option>
            ))}
          </Dropdown>
        </InputBoxWrapper>
      </InputContainerField>
      <ArrowContainer>
        <ArrowIcon className='material-icons'>arrow_right_alt</ArrowIcon>
      </ArrowContainer>
      <InputContainerField>
        <IntegrationIcon>
          <img
            src='https://res.cloudinary.com/equipter/image/upload/c_scale,h_28/v1522846032/SolidLogo.png'
            alt='Boostpoint logo'
            title='Boostpoint'
          />
          <br />
          <strong>Boostpoint Field</strong>
        </IntegrationIcon>
        <InputBoxWrapper>
          <Dropdown
            type='text'
            value={selectedField.key}
            onChange={input => changeSelectedField(input.target.value)}
          >
            {fields.map(field => (
              <option key={field.key} value={field.key}>
                {field.name}
              </option>
            ))}
          </Dropdown>
        </InputBoxWrapper>
      </InputContainerField>

      {!!mappingChanged && (
        <div>
          <Button onClick={clearMapping}>Cancel Mapping Changes</Button>
          <Button solid onClick={props.saveMapping}>
            Save Mapping
          </Button>
        </div>
      )}
    </CardResponsive>
  );
};

const FieldMappingDesktop = props => {
  const { fields = [], fieldMappings = [], integrationFields = {} } = props;
  const [mappingChanged, setMappingChanged] = React.useState(false);
  const [mapped, setMapped] = React.useState(fieldMappings);

  React.useEffect(() => {
    console.log("fields updated");
    const newMapping = fields.map(f => {
      const matchingField = fieldMappings.find(m => m.sourceField === f.key);
      if (matchingField) {
        f.mappedField = matchingField.destinationField;
      } else {
        f.mappedField = "";
      }
      return f;
    });
    setMapped(newMapping);
  }, [fields, fieldMappings]);

  const changeField = input => {
    const { value, name } = input.target;
    setMappingChanged(true);
    const newMapping = fieldMappings.find(f => f.destinationField === value);
    if (newMapping) {
      newMapping.sourceField = name;
      props.changeField([
        ...fieldMappings.filter(f => f.destinationField !== value),
        newMapping
      ]);
    } else {
      props.changeField([
        ...fieldMappings.filter(f => f.destinationField !== value),
        { sourceField: name, destinationField: value }
      ]);
    }
  };

  const resetFields = () => {
    setMappingChanged(false);
    props.resetFields();
  };

  const saveMapping = () => {
    setMappingChanged(false);
    props.saveMapping();
  };

  return (
    <CardResponsive showOnMobileOnly={false}>
      <CardTitle>
        <Title>Field Mapping</Title>
      </CardTitle>
      <MappingRow>
        <IntegrationIcon>
          <img
            src='https://res.cloudinary.com/equipter/image/upload/c_scale,h_28/v1522846032/SolidLogo.png'
            alt='Boostpoint logo'
            title='Boostpoint'
          />
          <br />
          <strong>Boostpoint Field</strong>
        </IntegrationIcon>
        <div></div>
        <IntegrationIcon>Source Field</IntegrationIcon>
      </MappingRow>
      {mapped.map(field => (
        <MappingRow key={field.key}>
          <InputContainerField customWidth='25rem'>
            <InputBoxWrapper>
              <InputBox type='text' readOnly value={field.name} />
            </InputBoxWrapper>
          </InputContainerField>
          <ArrowContainer>
            <ArrowIcon className='material-icons'>arrow_right_alt</ArrowIcon>
          </ArrowContainer>
          <InputContainerField customWidth='25rem'>
            <InputBoxWrapper>
              <Dropdown
                type='text'
                name={field.key}
                data-namereadable={field.name}
                value={field.mappedField || ""}
                onChange={input => changeField(input)}
              >
                <option value=''>Select One</option>
                {Object.keys(integrationFields).map(key => (
                  <option key={key} value={key}>
                    {integrationFields[key]}
                  </option>
                ))}
              </Dropdown>
            </InputBoxWrapper>
          </InputContainerField>
        </MappingRow>
      ))}
      {!!mappingChanged && (
        <div>
          <TxtButton onClick={resetFields}>Reset</TxtButton>
          <Button solid onClick={saveMapping}>
            Update
          </Button>
        </div>
      )}
    </CardResponsive>
  );
};
