import React from "react";
import styled from "styled-components";
import BpModal from "../../elements/BpModal";
import { LoaderDotsSmall } from "../../elements/Loaders";
import WorkflowModal from "../workflows/WorkflowModal";
import SelectWorkflows from "./SelectWorkflows";

import {
  getAllCompanyWorkflows,
  workflowMatching
} from "../../../utils/WorkflowAPI";
import { Button } from "../../styled/Buttons";
import Card from "../../styled/Card";
import H3 from "../../styled/H3";

const P = styled.p`
  margin: 3rem;
`;

const Thankyou = props => {
  const { submissionComplete, pendingCampaign } = props;
  const { campaignId } = pendingCampaign;

  const [state, setState] = React.useState({
    message: null,
    video: "https://boostpoint.wistia.com/medias/lcafpyojvx",
    title: null,
    matchingWorkflow: "",
    selectedWorkflows: [],
    loading: false
  });

  const [modals, setModals] = React.useState({
    modalWorkflow: false,
    modalWorkflowMatching: false,
    modalSelectWorkflow: false,
    modalWorkflowDone: false
  });

  const [workflows, setWorkflows] = React.useState([]);

  React.useEffect(() => {
    if (submissionComplete) {
      setState(prevState => ({
        ...prevState,
        message:
          "Thank you for your submission. Please allow up to 24 hours for your ad content to go live.",
        title: "Submission Successful."
      }));
    }
    const getWorkflows = async () => {
      try {
        const workflowsResponse = await getAllCompanyWorkflows();
        console.log("get workflows", workflowsResponse);
        setWorkflows(workflowsResponse);
      } catch (err) {
        console.log("error getting integrations list", err);
        // throw new Error('error getting integrations', err.message);
      }
    };
    getWorkflows();
  }, [submissionComplete]);

  React.useEffect(() => {
    if (workflows.length > 0) {
      const matches = workflowMatching({
        triggerType: "NEW_CONTACT",
        filters: [{ campaignIds: [campaignId], type: "CAMPAIGN" }],
        workflows
      });
      console.log("matching workflows", matches);
    }
  }, [campaignId, workflows]);

  const updateWorkflows = workflowID => {
    const { selectedWorkflows } = state;
    if (workflowID === selectedWorkflows) {
      setState({ ...state, selectedWorkflows: [] });
    } else if (workflowID === "all" && selectedWorkflows === "all") {
      setState({ ...state, selectedWorkflows: [] });
    } else if (workflowID === "all") {
      setState({ ...state, selectedWorkflows: "all" });
    } else if (selectedWorkflows === "all") {
      const workflowIDs = workflows.map(flow => flow.workflowID);
      const newValue = workflowIDs.filter(flow => flow !== workflowID);
      setState({ ...state, selectedWorkflows: newValue });
    } else if (selectedWorkflows.includes(workflowID)) {
      const newValue = selectedWorkflows.filter(flow => flow !== workflowID);
      setState({ ...state, selectedWorkflows: newValue });
    } else {
      setState({
        ...state,
        selectedWorkflows: [...selectedWorkflows, workflowID]
      });
    }
  };

  const saveWorkflows = async () => {
    const { selectedWorkflows } = state;
    const wait = [];
    this.setState({ loading: true });
    workflows.forEach(flow => {
      if (selectedWorkflows.includes(flow.workflowID)) {
        flow.filters.forEach((filter, i) => {
          if (filter.field === "campaignID" && Array.isArray(filter.value)) {
            flow.filters[i].value.push(campaignId);
          }
        });
        try {
          //TODO: update old call to API
          // const modified = new Date();
          // wait.push(
          //   axios.put(`${BP_NODE_SERVER}/v1/workflows`, {
          //     workflow: {
          //       ...flow,
          //       dateModified: modified.getTime()
          //     }
          //   })
          // );
        } catch (err) {
          console.log("error saving workflow", err.message);
        }
      }
    });
    await Promise.all(wait)
      .then(() => {
        setState({ ...state, loading: false });
        setModals({
          ...modals,
          modalSelectWorkflow: false,
          modalWorkflowDone: true
        });
      })
      .catch(err => console.log("error writing workflow", err.message));
  };

  return (
    <div>
      <Card>
        <H3>{state.title}</H3>
        <P>{state.message}</P>
      </Card>
      <WorkflowModal
        open={modals.modalWorkflow}
        title={`Connect Flows`}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        contentLabel='Existing Campaign'
        onCloseMethod={() => setModals({ ...modals, modalWorkflow: false })}
        body={
          <div>
            <p>Would you like to add your campaign to a connect flow?</p>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              {/* <Button onClick={() => this.setState({ modalWorkflow: false })}>
                  Not Now
                </Button> */}
              <Button
                onClick={() => props.history.push(`/workflows/new`)}
                customMargin='1rem 1rem 0 0'
              >
                Create New
              </Button>
              {workflows.some(
                flow =>
                  flow.status === "active" && flow.triggerID === "contactNew"
              ) && (
                <Button
                  onClick={() =>
                    setModals({
                      ...modals,
                      modalWorkflow: false,
                      modalSelectWorkflow: true
                    })
                  }
                  solid
                  customMargin='1rem 0 0 1rem'
                >
                  Connect to Existing Flow
                </Button>
              )}
            </div>
          </div>
        }
        width='auto'
      />
      <WorkflowModal
        open={modals.modalSelectWorkflow}
        title={`Select Flows`}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        contentLabel='Existing Campaign'
        onCloseMethod={() =>
          this.setState({ modalSelectWorkflow: false, modalWorkflow: true })
        }
        // styleOverride={{
        //   content: {
        //     maxHeight: '300px'
        //   }
        // }}
        body={
          <div>
            <SelectWorkflows
              workflows={workflows.filter(flow => flow.status === "active")}
              selectedWorkflows={state.selectedWorkflows}
              updateWorkflows={updateWorkflows}
            />
            <Button
              onClick={() =>
                setModals({ ...modals, modalSelectWorkflow: false })
              }
            >
              Back
            </Button>
            <Button solid onClick={saveWorkflows} disabled={state.loading}>
              Continue
              {state.loading && <LoaderDotsSmall />}
            </Button>
          </div>
        }
        width='auto'
      />
      <BpModal
        open={modals.modalWorkflowMatching}
        title={`Matching Workflow`}
        primaryAction={{
          btnLabel: "View Workflow List",
          action: () => props.history.push(`/workflows`)
        }}
        secondaryAction={{
          btnLabel: "Close",
          action: () => setModals({ ...modals, modalWorkflowMatching: false })
        }}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        contentLabel='Matching Workflow'
        body={
          state.matchingWorkflow ? (
            <p>
              You already have a workflow with filters that match this campaign.
              It will send a message to any new lead.
              <Button
                solid
                onClick={() =>
                  props.history.push(`/workflows/${state.matchingWorkflow}`)
                }
              >
                View Matching Workflow
              </Button>
            </p>
          ) : (
            <p>
              You already have workflows with filters that match this campaign.
              They will send a message to any new lead.
            </p>
          )
        }
        width='auto'
      />
      <BpModal
        open={modals.modalWorkflowDone}
        title={`Matching Workflow`}
        primaryAction={{
          btnLabel: "Close",
          action: () => setModals({ ...modals, modalWorkflowDone: false })
        }}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        contentLabel='Matching Workflow'
        body={
          state.selectedWorkflows && state.selectedWorkflows.length === 1 ? (
            <p>
              Workflow updated. View view or modify the workflow, click the link
              below.
              <br />
              <Button
                solid
                onClick={() =>
                  props.history.push(`/workflows/${state.selectedWorkflows[0]}`)
                }
              >
                View Workflow
              </Button>
            </p>
          ) : (
            <p>
              Workflow updated. To view your workflows, click the link below.
              <Button solid onClick={() => props.history.push(`/workflows`)}>
                View Workflows
              </Button>
            </p>
          )
        }
        width='auto'
      />
    </div>
  );
};

export default Thankyou;
