import React from "react";
import styled from "styled-components";

import {
  Description,
  Field,
  FormCard,
  HeadlineImage,
  Input,
  Label,
  PreviewContainer,
  SubmitButton
} from "../../styled/LeadAdPreview";

const QuestionTitle = styled(Description)`
  text-align: left;
  font-size: 1.1em;
  color: black;
`;

const LeadAdPreview = props => {
  const { fields = [], headline, coverImage } = props;
  const [formFields, setFormFields] = React.useState(fields);

  React.useEffect(() => {
    const newFormFields = fields.filter(f => f.type !== "CUSTOM");
    setFormFields(newFormFields);
  }, [fields]);

  return (
    <div>
      <PreviewContainer>
        {coverImage && <HeadlineImage src={coverImage} alt={headline} />}
        <FormCard contextTitle>
          <QuestionTitle>Contact Information</QuestionTitle>
          {headline ? (
            <Description>{headline}</Description>
          ) : (
            <Description>Please enter a headline</Description>
          )}
          {formFields.map((field, index) => (
            <Field key={index}>
              <Label>{field.name}</Label>
              <Input type='text' placeholder='Enter your answer.' disabled />
            </Field>
          ))}
        </FormCard>
        <SubmitButton>Submit</SubmitButton>
      </PreviewContainer>
    </div>
  );
};

export default LeadAdPreview;
