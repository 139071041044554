import React from "react";
import styled from "styled-components";
import BpModal from "../../elements/BpModal";
import {
  statusesGetByCompany,
  statusesGetByIndustry,
  contactUpdate,
  statusUpdate,
  contactsGet
} from "../../../utils/ContactAPI";
import StatusDraggable from "./StatusDraggable";
import { errorHandler } from "../../../utils/Helpers";
import { Card, TitleLeft, TitleWrapper } from "../../styled/Layout";
import StatusAddModal from "./contactStatus/StatusAddModal";
import StatusEditModal from "./contactStatus/StatusEditModal";
import StatusOverride from "./contactStatus/StatusOverride";
import StatusRevert from "./contactStatus/StatusRevert";
import StatusList from "./contactStatus/StatusList";
import StatusDelete from "./contactStatus/StatusDelete";

const Title = styled.h3`
  font-size: 22px;
  color: ${props => props.theme.HC1};
  margin: 0 0 10px;
  @media only screen and (min-width: 901px) {
    font-size: 2em;
    margin: 1em;
  }
`;

const Back = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .material-icons {
    font-size: 44px;
  }
`;

const StatusCard = styled(Card)`
  padding: 45px;
  margin-top: 0;
  p {
    text-align: center;
  }
`;

const CardInner = styled.div`
  max-width: 500px;
  margin: 0 auto 2.5em;
`;

class ContactStatuses extends React.Component {
  state = {
    contactStatuses: [],
    defaultStatuses: [],
    usingDefault: false,
    userCompany: {},
    editStatus: false,
    editStatusId: "",
    defaultStatusSelected: {},
    newStatusLabel: "",
    statusToDelete: {},
    contactsToUpdate: [],
    contactsToUpdateCount: 0,
    loadingLeads: false,
    statusToMigrateTo: {},
    statusMigrate: {},
    revertStatusConfirm: false,
    modalConfirmDefault: false,
    modalConfirmDelete: false,
    modalStatusReassign: false,
    modalDeleteDefault: false
  };

  async componentDidMount() {
    const { userCompany: { industry = "" } = {} } = this.props;
    try {
      const companyStatuses = await statusesGetByCompany();
      this.setState({ contactStatuses: companyStatuses });
      if (companyStatuses.length < 1) {
        this.setState({ usingDefault: true });
      }
      if (industry) {
        const statuses = await statusesGetByIndustry(industry);
        this.setState({ defaultStatuses: statuses });
      }
    } catch (err) {
      console.log("error getting contact statuses", err);
    }
  }

  componentDidUpdate = async prevProps => {
    const { userCompany: { industry = "" } = {} } = this.props;
    const { userCompany: { industry: prevIndustry = "" } = {} } = prevProps;
    if (industry !== prevIndustry) {
      try {
        if (industry) {
          const statuses = await statusesGetByIndustry(industry);
          this.setState({ defaultStatuses: statuses });
        }
      } catch (err) {
        console.log("error getting contact statuses", err);
      }
    }
  };

  handleLabelChange = input => {
    const value = input.target.value;
    this.setState({ newStatusLabel: value });
  };

  handleStatusChange = async newStatusLabel => {
    const { editStatusId, contactStatuses } = this.state;
    if (newStatusLabel) {
      const { alert } = this.props;

      contactStatuses.forEach((status, i) => {
        if (status._id === editStatusId && status.status !== newStatusLabel) {
          contactStatuses[i].status = newStatusLabel;
          try {
            statusUpdate({ ...status, status: newStatusLabel.trim() });
            const alertMsg = `Status successfully changed to ${newStatusLabel}`;
            alert({ type: "success", msg: alertMsg });
            this.setState({
              editStatusId: "",
              modalStatusEdit: false,
              contactStatuses
            });
          } catch (err) {
            console.log("error saving field", err);
            alert({
              type: "error",
              msg: "Something went wrong saving the field. Please try again. If you continue to get this message, contact Boostpoint at help@boostpoint.com"
            });
            return false;
          }
        }
      });
    } else {
      alert({ type: "error", msg: "A value is required" });
      return false;
    }
  };

  revertComplete = () => {
    this.setState({
      contactStatuses: [],
      usingDefault: true
    });
  };

  handleOverride = async () => {
    const companyStatuses = await statusesGetByCompany();
    this.setState({ contactStatuses: companyStatuses, usingDefault: false });
  };

  handleToggleEdit = statusId => {
    const { editStatusId, editStatus, contactStatuses } = this.state;
    const statusSelected = contactStatuses.filter(
      status => status._id === statusId
    );
    if (!editStatus && editStatusId !== statusId && statusSelected[0]) {
      const { status: statusLabel } = statusSelected[0];
      this.setState({
        editStatusId: statusId,
        newStatusLabel: statusLabel,
        modalStatusEdit: true
      });
    }
  };

  handleChangeDefault = async statusId => {
    let {
      modalConfirmDefault,
      contactStatuses,
      defaultStatusSelected = {}
    } = this.state;
    console.log("statusId", statusId);
    console.log("default", defaultStatusSelected);
    const oldDefault = contactStatuses.find(c => c.isDefault);
    console.log("old default", oldDefault);
    if (!modalConfirmDefault) {
      defaultStatusSelected = contactStatuses.find(c => c._id === statusId);
      this.setState({ defaultStatusSelected, modalConfirmDefault: true });
      return;
    }
    const newContactStatuses = contactStatuses.map(status => {
      if (status._id === defaultStatusSelected._id) {
        return { ...status, isDefault: true };
      } else {
        return { ...status, isDefault: false };
      }
    });
    try {
      statusUpdate({ ...defaultStatusSelected, isDefault: true });
      const response = await statusUpdate({ ...oldDefault, isDefault: false });
      console.log("response", response);
      this.setState({
        contactStatuses: newContactStatuses,
        modalConfirmDefault: false,
        defaultStatusID: ""
      });
    } catch (err) {
      console.log("error making field default", err);
      alert({
        type: "error",
        msg: "Something went wrong making the field default. Please try again. If you continue to get this message, contact Boostpoint at help@boostpoint.com"
      });
      return false;
    }
  };

  statusModalClose = () => {
    this.setState({
      modalStatusEdit: false,
      editStatusId: ''
    });
  };

  handleSortChange = (oldIndex, newIndex, statusId) => {
    console.log(statusId, oldIndex, newIndex);
    const newContactStatuses = this.moveStatusOrder(
      oldIndex,
      newIndex,
      statusId
    );
    console.log("newContactStatuses", newContactStatuses);
    this.setState({ contactStatuses: newContactStatuses });
    newContactStatuses.forEach(c => {
      try {
        statusUpdate(c);
      } catch (err) {
        const { status, message } = errorHandler(err);
        console.log("err", `${status}: ${message}`);
      }
    });
  };

  moveStatusOrder = (oldIndex, newIndex, movedKey) => {
    const { contactStatuses = [] } = this.state;
    const difference = newIndex - oldIndex;
    let newStatuses = [];
    console.log("difference", Math.abs(difference), movedKey);
    if (Math.abs(difference) === 1) {
      newStatuses = contactStatuses.map(f => {
        if (f._id === movedKey) {
          f.orderNumber = newIndex;
        } else if (f.orderNumber === newIndex) {
          f.orderNumber = oldIndex;
        }
        return f;
      });
    } else if (difference > 0) {
      newStatuses = contactStatuses.map(f => {
        if (f._id === movedKey) {
          f.orderNumber = newIndex;
        } else if (f.orderNumber >= oldIndex) {
          f.orderNumber = f.orderNumber - 1;
        }
        return f;
      });
    } else {
      newStatuses = contactStatuses.map(f => {
        if (f._id === movedKey) {
          f.orderNumber = newIndex;
        } else if (f.orderNumber >= newIndex) {
          f.orderNumber = f.orderNumber + 1;
        }
        return f;
      });
    }
    return this.cleanupFieldOrder(newStatuses);
  };

  cleanupFieldOrder = statuses => {
    statuses.sort((a, b) => {
      if (a.orderNumber === b.orderNumber) {
        return a.name - b.name;
      }
      return a.orderNumber - b.orderNumber;
    });
    const newStatuses = statuses.map((f, index) => ({
      ...f,
      orderNumber: index
    }));
    return newStatuses;
  };

  handleRemoveStatus = async statusId => {
    const { contactStatuses } = this.state;
    if (statusId) {
      const newStatusToDelete = contactStatuses.find(
        status => status._id === statusId
      );
      try {
        this.setState({
          statusToDelete: newStatusToDelete
        });
      } catch (err) {
        console.log("error updating contacts", err.message);
      }
    }
  };

  handleStatusRemoved = async () => {
    const companyStatuses = await statusesGetByCompany();
    this.setState({ contactStatuses: companyStatuses });
  };

  handleDeleteCancel = () => {
    this.setState({ statusToDelete: {} });
  };

  handleMigrateDropdown = value => {
    const { contactStatuses } = this.state;
    const statusToMigrateTo = contactStatuses.find(
      status => status._id === value
    );
    this.setState({ statusToMigrateTo });
  };

  handleStatusMigration = async oldId => {
    const { statusToMigrateTo, contactsToUpdate } = this.state;
    if (contactsToUpdate.length > 0) {
      try {
        const wait = [];
        contactsToUpdate.forEach(contact => {
          if (contact.statusId === oldId) {
            wait.push(
              contactUpdate({ ...contact, statusId: statusToMigrateTo._id })
            );
          }
        });
        await Promise.all(wait);
        this.setState({ statusToMigrateTo: {}, contactsToUpdate: [] });
      } catch (err) {
        console.log("There was an error writing the contact updates");
      }
    }
  };

  getLeadsWithStatus = async statusId => {
    try {
      const contacts = await contactsGet({ statusId });
      return contacts;
    } catch (err) {
      console.log("error getting contacts with status", err.message);
    }
  };

  handleStatusAdd = async newStatus => {
    const { contactStatuses } = this.state;
    const { alert } = this.props;
    alert({ type: "success", msg: `${newStatus.status} added` });
    this.setState({
      editStatusId: '',
      contactStatuses: [...contactStatuses, newStatus],
      loadingLeads: false
    });
  };

  onBack = async () => {
    this.props.history.goBack();
  };

  render() {
    const {
      contactStatuses,
      defaultStatuses,
      usingDefault,
      defaultStatusSelected,
      contactsToUpdateCount,
      loadingLeads,
      editStatusId
    } = this.state;
    contactStatuses.sort((a, b) => a.sortIndex - b.sortIndex);
    const { userCompany: { _id: companyId } = {} } = this.props;

    return (
      <div>
        <TitleWrapper>
          <TitleLeft mobileStacking>
            <Back>
              <a
                href='/workflows'
                onClick={e => {
                  e.preventDefault();
                  this.onBack();
                }}
              >
                <span className='material-icons'>navigate_before</span>
              </a>
            </Back>
            <Title>Contact Status List</Title>
          </TitleLeft>
        </TitleWrapper>
        <StatusCard>
          {!!usingDefault && <p>You are currently using default statuses.</p>}
          <ul>
            {!usingDefault ? (
              <div>
                <StatusDraggable
                  sortableItems={contactStatuses}
                  handleChangeDefault={this.handleChangeDefault}
                  handleSortChange={this.handleSortChange}
                  handleRemove={this.handleRemoveStatus}
                  handleToggleEdit={this.handleToggleEdit}
                />
                <StatusAddModal
                  loadingLeads={loadingLeads}
                  statusAdd={this.handleStatusAdd}
                  userCompany={this.props.userCompany}
                  orderNumber={contactStatuses.length}
                />
              </div>
            ) : (
              <StatusList items={defaultStatuses} />
            )}
          </ul>
          <CardInner>
            {usingDefault ? (
              <StatusOverride
                defaultStatuses={defaultStatuses}
                companyId={companyId}
                handleOverride={this.handleOverride}
              />
            ) : (
              <StatusRevert
                defaultStatuses={defaultStatuses}
                contactStatuses={contactStatuses}
                revertComplete={this.revertComplete}
              />
            )}
          </CardInner>
        </StatusCard>
        <BpModal
          open={this.state.modalConfirmDefault}
          title={`Confirm Default Status`}
          primaryAction={{
            btnLabel: "Save Default",
            action: event => this.handleChangeDefault("", event)
          }}
          secondaryAction={{
            btnLabel: "Cancel",
            action: () =>
              this.setState({
                modalConfirmDefault: false,
                defaultStatusSelected: {}
              })
          }}
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
          contentLabel='Confirm Default Status'
          body={
            <p>
              Are you sure you want to make{" "}
              <strong>{defaultStatusSelected.label}</strong> the default contact
              status? This applies to new Leads and manually added Contacts
            </p>
          }
          width='auto'
        />

        <StatusEditModal
          modalStatusEdit={this.state.modalStatusEdit}
          handleStatusChange={this.handleStatusChange}
          statusModalClose={this.statusModalClose}
          status={contactStatuses.find(s => s._id === editStatusId)}
        />
        <StatusDelete
          statusToDelete={this.state.statusToDelete}
          loadingLeads={loadingLeads}
          handleStatusRemoved={this.handleStatusRemoved}
          handleDeleteCancel={this.handleDeleteCancel}
          history={this.props.history}
        />
      </div>
    );
  }
}

export default ContactStatuses;
