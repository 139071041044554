import React from "react";
import { Elements, StripeProvider } from "react-stripe-elements";
import PaymentFix from "./PaymentFix";

class PaymentFixStripeWrapper extends React.Component {
  render() {
    return (
      <div>
        <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
          <Elements>
            <PaymentFix {...this.props} />
          </Elements>
        </StripeProvider>
      </div>
    );
  }
}

export default PaymentFixStripeWrapper;
