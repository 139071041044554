import React from "react";
import styled from "styled-components";
import { getInitials } from "../../../utils/Helpers";
import AnimateHeight from "react-animate-height";

const SearchResults = styled.div`
  object-position: top;
  padding: 0 10px 10px 10px;
  background: white;
`;

const Numbers = styled.div`
  display: flex-box;
  background-color: white;
`;

const ContactInitials = styled.div`
  display: flex;
  align-items: top;
  justify-content: center;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin-right: 10px;
    border-radius: 50%;
    background: gray;
    color: white;
    font-size: 1em;
    font-weight: bold;
  }
`;

const ContactName = styled.div`
  font-weight: 300;
`;

const SearchResult = styled.div`
  cursor: pointer;
  border-bottom: 1px solid #e4e4e4;
  grid-template-columns: 40px 1fr;
  text-align: left;
  padding: 0.5em;
  &:last-child {
    border: none;
  }
  &:hover {
    background-color: ${props => (props.expand ? "white" : props.theme.ATextC)};
  }
`;

const MsgNum = styled.div`
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  font-size: 19px;
  margin: 18px 0 1px 0;
  padding: 0 12px 18px;
  cursor: pointer;
  span {
    padding-right: 8px;
    font-size: 24px;
  }
  &:last-child {
    border: none;
  }
`;

const Contact = styled.div`
  display: flex;
  align-items: center;
`;

const Next = styled.div`
  vertical-align: middle;
  font-size: 2em;
  color: ${props => props.theme.TextC};
  cursor: pointer;
  padding: 0;
  margin-left: auto;
  height: 30px;
`;

class NewMessageResults extends React.Component {
  state = {
    height: 0,
    key: ""
  };
  render() {
    const { searchResults } = this.props;
    const { height } = this.state;
    return searchResults.length > 0 ? (
      <div>
        <SearchResults>
          {searchResults.map(
            (result, key) =>
              (result.mobilePhone || result.phone) && (
                <SearchResult
                  key={result._id}
                  expand={key === this.state.key && height === 120}
                  onClick={() => {
                    if (result.mobilePhone && result.phone) {
                      this.setState({
                        height: height === 120 ? 0 : 120,
                        key: key
                      });
                    } else if (result.mobilePhone) {
                      console.log(result._id);
                      this.props.loadNewContact(result._id, result.mobilePhone);
                    } else if (result.phone) {
                      console.log(result._id);
                      this.props.loadNewContact(result._id, result.phone);
                    }
                  }}
                >
                  <Contact>
                    <ContactInitials>
                      <span>{getInitials(result)}</span>
                    </ContactInitials>
                    <ContactName>
                      {result.firstName} {result.lastName}
                    </ContactName>
                    <Next className='material-icons'>
                      {key === this.state.key && height === 120
                        ? "expand_more"
                        : "navigate_next"}
                    </Next>
                  </Contact>
                  <AnimateHeight
                    duration={500}
                    height={key === this.state.key ? height : 0}
                  >
                    <Numbers>
                      {!!result.mobilePhone && (
                        <MsgNum
                          onClick={() =>
                            this.props.loadNewContact(
                              result.contactID,
                              result.mobilePhone
                            )
                          }
                        >
                          <span className='material-icons'>smartphone</span>
                          {result.mobilePhone}
                        </MsgNum>
                      )}
                      {!!result.phone && (
                        <MsgNum
                          onClick={() =>
                            this.props.loadNewContact(result._id, result.phone)
                          }
                        >
                          <span className='material-icons'>call</span>
                          {result.phone}
                        </MsgNum>
                      )}
                    </Numbers>
                  </AnimateHeight>
                </SearchResult>
              )
          )}
        </SearchResults>
      </div>
    ) : null;
  }
}

export default NewMessageResults;
