import Cleave from "cleave.js/react";
import React from "react";
import { Carousel } from "react-responsive-carousel";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
// eslint-disable-next-line no-unused-vars

import "../../../css/carousel.css";
import {
  campaignDelete,
  campaignGetDrafts,
  campaignUpdate,
  campaignValidation,
  formTemplateCreate,
  formTemplateDelete,
  formTemplatesGetByCompany,
  formTemplatesGetByIndustry,
  formTemplateUpdate,
  leadGenStatusCheck,
  targetingGetById
} from "../../../utils/FacebookAPI";
import {
  errorHandler,
  sanitizeString,
  urlValidator
} from "../../../utils/Helpers.js";
import BpModal from "../../elements/BpModal";
import Icon from "../../elements/Icon";
import { HelperSubmit } from "./CampaignHelpers";

// Import components
import LeadAdForm from "./LeadAdForm";
import LeadAdPreview from "./LeadAdPreview";
import LeadAdPreviewContextCard from "./LeadAdPreviewContextCard";
import LeadAdPreviewThankyou from "./LeadAdPreviewThankyou";
import LeadAdPrivacy from "./LeadAdPrivacy";
import Thankyou from "./Thankyou";

// Styled components
import { adCreditsCheck } from "../../../utils/CreditsAPI";
import { cloudinaryFormat } from "../../../utils/MediaAPI";
import { LoaderDotsSmall } from "../../elements/Loaders";
import { Button, SubmitButton } from "../../styled/Buttons";
import { Title } from "../../styled/Headers";
import {
  Dropdown,
  InputBox,
  InputBoxWrapper,
  InputContainer,
  InputLabel,
  TextArea
} from "../../styled/Input";
import InputError from "../../styled/InputErrorForm";
import SectionGroup from "../../styled/SectionGroup";
import TemplateNameInput from "../../styled/TemplateName";

const SectionTitle = styled.div`
  text-align: center;
  margin: 0 auto;
  padding: 0;
  max-width: 700px;
`;

const SectionTitleText = styled(Title)`
  margin-top: 20px;
`;

const PreviewContainer = styled.div`
  width: 1020px;
  margin: 0 auto;
  max-width: 100%;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
`;

const Label = styled.label`
  color: ${props => props.theme.HC4};
  font-weight: bold;
  display: block;
  margin: 25px 0 5px;
`;

const Required = styled.span`
  color: ${props => props.theme.HCErr};
  font-weight: bold;
`;

const RequiredText = styled.p`
  color: ${props => props.theme.HCErr};
  font-size: 0.8em;
`;

const TextWrapper = styled.div`
  width: 75vw;
  max-width: 90%;
  min-width: 200px;
  margin: 0 auto;
  input,
  textarea,
  select {
    width: 100%;
    max-width: 100%;
  }
  select {
    font-size: 16px;
  }
`;

const PrivacyPolicyWrapper = styled.div`
  max-width: 650px;
  margin: 0 auto;
  padding: 0 20px;
`;
const PrivacyTextWrapper = styled.div`
  max-width: 500px;
  min-width: 200px;
  margin: 25px auto;
`;
const PrivacyDescriptionWrapper = styled.div`
  display: flex;
  div {
    width: 50%;
    &:first-child {
      padding-right: 10px;
    }
    &:last-child {
      padding-left: 10px;
    }
  }
  @media (max-width: 750px) {
    display: block;
    div {
      width: 100%;
      padding: 0;
    }
  }
`;

const ProgressBarTitle = styled.div`
  margin: 4em auto;
  max-width: 500px;
  margin: auto;
  p {
    font-size: 12px;
  }
`;

// Styled Ad Template
const AdTemplate = styled.div`
  margin: 2em auto;
`;

const AdTemplateLabel = styled.div`
  margin-bottom: 0.5em;
  font-size: 15px;
  color: ${props => props.theme.HC4};
  font-weight: bold;
`;

const AdTemplateWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  @media screen and (max-width: 825px) {
    display: block;
  }
`;

const Link = styled.a`
  color: ${props => (props.solid ? props.theme.BGC : props.theme.HC1)};
  background-color: ${props =>
    props.solid ? props.theme.HC1 : props.theme.BGC};
  display: inline-block;
  border-radius: 1.2rem;
  height: 2.3rem;
  padding: 0.4rem 1.5rem;
  min-width: 7rem;
  margin: ${props => (props.customMargin ? props.customMargin : "1rem")};
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  border: 2px solid ${props => props.theme.HC1};
  font-size: 15px;
  letter-spacing: 1px;
  :disabled {
    color: ${props => (props.solid ? props.theme.BGC : props.theme.TextC)};
    background-color: ${props =>
      props.solid ? props.theme.TextC : props.theme.BGC};
    border: 1.5px solid ${props => props.theme.TextC};
  }
`;

const NameLabel = styled.label`
  color: ${props => (props.disabled ? "lightgrey" : "inherit")};
`;

const Input = styled.input`
  :disabled {
    color: ${props => (props.solid ? props.theme.BGC : props.theme.TextC)};
    background-color: ${props =>
      props.solid ? props.theme.TextC : props.theme.BGC};
  }
`;

const Ul = styled.ul`
  list-style: disc;
  list-style-position: outside;
  text-align: left;
  max-width: 400px;
  margin: 0 auto;
`;

const PrivacyInput = styled.input`
  width: 90%;
  max-width: 500px;
  min-width: 200px;
`;

const phoneCodes = {
  US: { name: "US", code: 1, length: 1 },
  CA: { name: "CA", code: 1, length: 1 },
  MX: { name: "MX", code: 52, length: 2 }
};

const PhoneWrapper = styled.div`
  select {
    display: inline-block;
    width: 80px;
    height: 32px;
  }
  input {
    display: inline-block;
    width: 170px;
  }
`;

const CarouselBtn = styled.span`
  display: inline-block;
  border-radius: 50%;
  background-color: ${props => props.theme.HC1};
  width: 29px;
  height: 29px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  span {
    color: white;
  }
`;

const CarouselStatus = styled.span`
  padding-bottom: 25px;
  display: inline-block;
  position: relative;
  bottom: 6px;
  margin: 0 11px;
`;

const discriminatory_words = ["zip", "zip_code", "address", "city"];

class LeadAdCreate extends React.Component {
  state = {
    loading: false,
    savedForms: [],
    stockForms: [],
    stockFormsLoading: true,
    errMessages: {},
    errFields: [],
    leadAdFormSnapshot: "",
    privacyModalOpen: false,
    pendingCampaign: {},
    nameModalIsOpen: false,
    statusModalIsOpen: false,
    redirectToArea: false,
    redirectStartOver: false,
    submissionComplete: false,
    fieldsMessage: "",
    useCampaignName: true,
    formName: "",
    fieldCount: 0,
    fieldCountStatus: "normal",
    leadgenTosAccepted: false,
    leadgenTosModalOpen: false,
    submissionWaiting: false,
    loadingForm: true,
    saveTemplateName: "",
    savingTemplate: false,
    modalTemplateName: false,
    templateChange: false,
    modalTemplateRemove: false,
    modalFailedPayments: false,
    currentSlide: 0,
    targeting: {},
    thankyouPhoneRegion: "",
    refillingCredits: false
  };

  async componentDidMount() {
    window.scroll(0, 0);
    const { userProfile: { settings: { privacyPolicyUrl = "" } = {} } = {} } =
      this.props;
    const pendingCampaignResponse = await campaignGetDrafts();
    if (pendingCampaignResponse[0]) {
      if (this.props.history.action === "POP") {
        this.setState({
          statusModalIsOpen: true
        });
      }
      const pendingCampaign = pendingCampaignResponse[0];
      this.setState({ pendingCampaign }, () => {
        if (!pendingCampaign.form.privacyPolicyUrl && privacyPolicyUrl) {
          this.updateForm({
            ...pendingCampaign.form,
            privacyPolicyUrl
          });
        }
      });
      const { targetingId } = pendingCampaign;
      const targeting = await targetingGetById(targetingId);
      this.setState({ targeting });
    } else {
      console.log("no campaigns");
      this.props.history.push("/campaign-select");
    }
    this.getTemplates();
    this.props.checkPermissions();
    this.filterFields();
  }

  componentDidUpdate = async prevProps => {
    const { refillingCredits } = this.state;
    const {
      userCompany: { industry }
    } = this.props;
    const {
      userCompany: { industry: prevIndustry }
    } = prevProps;
    if (
      refillingCredits &&
      this.props.adCreditsAvailable !== prevProps.adCreditsAvailable
    ) {
      this.setState({ refillingCredits: false }, () => {
        this.openCampaignNameModal();
      });
    }
    if (industry && industry !== prevIndustry) {
      this.setState({ stockFormsLoading: true });
      const industryTemplates = await formTemplatesGetByIndustry(industry);
      this.setState({
        stockForms: industryTemplates,
        stockFormsLoading: false
      });
    }
  };

  filterFields = (passedFields, discriminationCheck) => {
    const { pendingCampaign: { form = {} } = {} } = this.state;
    let fields = passedFields;
    if (!passedFields) {
      fields = form.fields;
    }
    if (fields && fields.length > 0) {
      const newFields = fields.filter(f => {
        if (f.key && f.name) {
          const key = f.key.toLowerCase();
          const name = f.name.toLowerCase();
          const discrimination =
            discriminatory_words.includes(name) ||
            discriminatory_words.includes(key);
          if (discrimination && discriminationCheck) {
            this.handleFieldsFiltered();
          }
          return !discrimination;
        }
        return false;
      });
      if (!passedFields) {
        this.updateForm({ ...form, fields: newFields });
      } else {
        return newFields;
      }
    }
  };

  getTemplates = async () => {
    try {
      const {
        userCompany: { _id: companyId, industry }
      } = this.props;
      const companyTemplates = await formTemplatesGetByCompany(companyId);
      console.log("companyTemplates", companyTemplates);
      this.setState({ savedForms: companyTemplates });
      if (industry) {
        const industryTemplates = await formTemplatesGetByIndustry(industry);
        this.setState({
          stockForms: industryTemplates,
          stockFormsLoading: false
        });
      }
    } catch (err) {
      const { status, message } = errorHandler(err);
      console.log("err", `${status}: ${message}`);
    }
  };

  updateFields = fields => {
    const { pendingCampaign, savedForms } = this.state;
    const { form = {} } = pendingCampaign;
    const { formTemplateId = "" } = form;
    const fieldCount = fields ? fields.length : 0;
    this.checkFieldCount(fieldCount);
    if (formTemplateId && savedForms.some(a => a._id === formTemplateId)) {
      this.setState({ templateChange: true });
    }
    this.updateForm({
      ...form,
      fields
    });
  };

  checkFieldCount = number => {
    let fieldCount = number;
    let fieldCountStatus = "normal";
    if (!number) {
      fieldCount = this.state.fieldCount;
    }
    let fieldsMessage = "";
    if (fieldCount > 5) {
      fieldsMessage =
        "Adding more fields could drastically decrease your number of leads. The magic number of fields is 5.";
      fieldCountStatus = "warning";
    }
    if (fieldCount > 7) {
      fieldsMessage =
        "This many fields will drastically decrease the number of leads you receive. The magic number of fields is 5.";
      fieldCountStatus = "error";
    }
    this.setState({ fieldsMessage, fieldCountStatus });
  };

  handleContentChange = input => {
    const { pendingCampaign } = this.state;
    const { form } = pendingCampaign;
    const { templateType } = form;
    if (templateType) {
      this.setState({ templateChange: true });
    }
    if (input.target.name === "thankyouTitle") {
      const { errMessages } = this.state;
      if (input.target.value.length > 60) {
        errMessages.thankyouTitle = "Maximum length is 60 characters.";
        if (
          form.thankyouTitle.length > 60 &&
          form.thankyouTitle.length > input.target.value.length
        ) {
          form[input.target.name] = input.target.value;
        }
      } else {
        errMessages.thankyouTitle = "";
        form[input.target.name] = input.target.value;
      }
      this.setState({ errMessages });
    } else {
      form[input.target.name] = input.target.value;
    }
    this.updateForm(form);
  };

  handleNameCheckbox = () => {
    const { useCampaignName } = this.state;
    this.setState({ useCampaignName: !useCampaignName });
  };

  handleFormName = input => {
    this.setState({ formName: input.target.value });
  };

  handlePolicySave = async () => {
    const { alert, userProfile } = this.props;
    const {
      errMessages,
      pendingCampaign: { form }
    } = this.state;
    const { privacyPolicyUrl } = form;
    const urlValid = urlValidator(privacyPolicyUrl, true);
    if (urlValid === true) {
      this.updateForm({ ...form, privacyPolicyUrl, privacyPolicyValid: true });
      this.setState({ errMessages: { ...errMessages, privacy: "" } });
      this.props.updateUserDoc({
        ...userProfile,
        settings: { ...userProfile.settings, privacyPolicyUrl }
      });
    } else {
      alert({
        type: "error",
        msg: urlValid
      });
      this.updateForm({ ...form, privacyPolicyUrl, privacyPolicyValid: false });
      this.setState({
        errMessages: {
          ...errMessages,
          privacy: "Not valid. Must be in this format: http://domain.com"
        }
      });
    }
  };

  thankYouUrlValid = () => {
    const { errMessages } = this.state;
    const { thankyouURL } = this.state.form;
    const urlValid = urlValidator(thankyouURL);
    if (urlValid !== true) {
      this.setState({
        errMessages: {
          ...errMessages,
          thankyouUrl: urlValid
        }
      });
    } else {
      this.setState({
        errMessages: {
          ...errMessages,
          thankyouUrl: ""
        }
      });
    }
  };

  handleThankyouChange = input => {
    const { thankyouPhoneRegion = "US" } = this.state;
    const { name, value, rawValue } = input.target;
    const form = { ...this.state.pendingCampaign.form };
    if (name === "thankyouPhone") {
      form.thankyouPhoneraw = rawValue;
      if (
        thankyouPhoneRegion &&
        phoneCodes[thankyouPhoneRegion] &&
        !rawValue.startsWith(phoneCodes[thankyouPhoneRegion].code)
      ) {
        form.thankyouPhone = phoneCodes[thankyouPhoneRegion].code + rawValue;
      } else {
        form.thankyouPhone = rawValue;
      }
    } else if (name === "thankyouPhoneRegion") {
      this.setState({ thankyouPhoneRegion: value });
      // const phoneRegion = value;
      // form[name] = phoneRegion;
      // if (thankyouPhoneraw && phoneRegion) {
      //   form.thankyouPhone = phoneCodes[phoneRegion].code + thankyouPhoneraw;
      // }
    } else if (name === "thankyouButtonText") {
      form[name] = value;
      if (value === "url" && form.thankyouPhoneRaw) {
        form.thankyouPhone = "";
      }
    } else {
      form[name] = value;
    }
    this.updateForm(form);
  };

  togglePrivacyModal = () => {
    const { privacyModalOpen } = this.state;
    this.setState({ privacyModalOpen: !privacyModalOpen });
  };

  savePrivacyModal = () => {
    this.togglePrivacyModal();
    this.handlePolicySave();
  };

  handleGenericPolicy = () => {
    const {
      pendingCampaign: { form }
    } = this.state;
    this.updateForm({
      ...form,
      privacyPolicyUrl: "https://www.boostpoint.com/privacy-policy-lead-ads/",
      privacyPolicyValid: true
    });
  };

  handleContinue = () => {
    const { type } = this.state.pendingCampaign;
    // close modal by setting state back to false
    if (type) {
      this.setState({ statusModalIsOpen: false, redirectToArea: true });
    }
  };

  handleStartOver = async () => {
    try {
      const campaigns = await campaignGetDrafts();
      const campaignIds = campaigns.map(c => c._id);
      if (campaignIds.length > 0) {
        const wait = [];
        campaignIds.forEach(c => wait.push(campaignDelete(c)));
        await Promise.all(wait);
      }
      this.setState({ statusModalIsOpen: false, redirectStartOver: true });
    } catch (err) {
      console.log(err);
    }
  };

  handlePreviousButton = () => {
    const { history } = this.props;
    history.push("/create-ad");
  };

  updateForm = async (form, formId) => {
    const { pendingCampaign } = this.state;
    const { formTemplateId } = pendingCampaign;
    const newCampaign = {
      ...pendingCampaign,
      form,
      formTemplateId:
        typeof formId !== "undefined" && formTemplateId !== formId
          ? formId
          : formTemplateId
    };
    try {
      this.setState({
        pendingCampaign: {
          ...pendingCampaign,
          form,
          formTemplateId:
            typeof formId !== "undefined" && formTemplateId !== formId
              ? formId
              : formTemplateId
        }
      });
      campaignUpdate(newCampaign);
      return true;
    } catch (err) {
      console.log(err);
    }
  };

  handleFormDropdown = (input, isStock) => {
    const formId = input.target.value;
    const {
      savedForms,
      stockForms,
      pendingCampaign: { form }
    } = this.state;
    let matchingForm;
    if (formId === "Select One") {
      this.clearForm();
      return;
    }
    if (isStock) {
      matchingForm = stockForms.find(f => f._id === formId);
    } else {
      matchingForm = savedForms.find(f => f._id === formId);
    }
    const {
      fields,
      headline,
      privacyPolicyUrl,
      privacyPolicyValid,
      thankyouTitle,
      thankyouBody,
      thankyouURL,
      thankyouButtonText,
      thankyouPhone,
      description
    } = matchingForm;
    const pendingForm = {
      ...form,
      fields,
      headline,
      thankyouTitle,
      thankyouBody,
      thankyouURL,
      thankyouButtonText,
      thankyouPhone,
      privacyPolicyUrl,
      privacyPolicyValid,
      description
    };
    this.updateForm(pendingForm, formId);
  };

  clearForm = () => {
    const { pendingCampaign: { form = {} } = {} } = this.state;
    this.updateForm(
      {
        ...form,
        fields: [],
        headline: "",
        privacyPolicyUrl: "",
        privacyPolicyValid: false,
        thankyouTitle: "",
        thankyouBody: "",
        thankyouURL: "",
        thankyouButtonText: "",
        thankyouPhone: ""
      },
      ""
    );
  };

  handleFieldsFiltered = () => {
    const { modalFilteredFields, statusModalIsOpen } = this.state;
    if (!modalFilteredFields && !statusModalIsOpen) {
      this.setState({ modalFilteredFields: true });
    }
  };

  handleSubmitButton = async () => {
    const { userCompany, alert, businessPage, platformPermissions } =
      this.props;
    this.setState(
      {
        errMessages: {},
        errFields: []
      },
      async () => {
        const isValid = await this.handleFieldValidation();
        if (isValid) {
          const validationReturn = await campaignValidation({
            businessPage,
            platformPermissions
          });
          if (validationReturn.type === "error") {
            alert(validationReturn);
            console.warn(validationReturn.msg);
            return;
          }
          this.setState({ nameModalIsOpen: false, submissionWaiting: true });
          const { pendingCampaign } = this.state;
          try {
            const campaignID = await HelperSubmit({
              pendingCampaign,
              userCompany,
              formName: this.state.useCampaignName
                ? pendingCampaign.name
                : this.state.formName,
              businessPage
            });
            if (campaignID) {
              this.setState({
                errMessages: {},
                errFields: [],
                submissionComplete: true,
                pendingCampaign: {
                  ...pendingCampaign,
                  campaignID
                },
                submissionWaiting: false
              });
            } else {
              console.log("An error occurred. Campaign ID is missing");
              this.setState({ submissionWaiting: false });
            }
          } catch (err) {
            this.setState({ submissionWaiting: false });
            console.error(err);
            if (err.error_user_msg) {
              alert({
                type: "error",
                msg: err.error_user_msg
              });
            } else if (err.message) {
              alert({
                type: "error",
                msg: err.message
              });
            } else {
              alert({
                type: "error",
                msg: "An error occurred while submitting the ad. If you continue to receive this error, please contact help@boostpoint.com"
              });
            }
          }
        } else {
          this.setState({ nameModalIsOpen: false });
          const { errFields } = this.state;
          if (errFields.length > 0) {
            let errFieldsString = "";
            if (errFields.length === 1) {
              errFieldsString = errFields[0];
            } else if (errFields.length === 2) {
              errFieldsString = errFields.join(" and ");
            } else {
              errFields[errFields.length - 1] =
                "and " + errFields[errFields.length - 1];
              errFieldsString = errFields.join(", ");
            }
            alert({
              type: "error",
              msg: `You are missing ${errFieldsString}`,
              timeout: 10000
            });
          }
        }
      }
    );
  };

  handleFieldValidation = () =>
    new Promise(resolve => {
      let fieldsAreValid = true;
      let errMessages = {};
      const errFields = [];
      const { pendingCampaign: { form = {} } = {}, thankyouPhoneRegion } =
        this.state;
      const {
        fields,
        headline,
        description,
        thankyouTitle,
        thankyouBody,
        thankyouButtonText,
        thankyouURL,
        thankyouPhone,
        privacyPolicyUrl,
        privacyPolicyValid
      } = form;

      const { alert, platformPermissions = [], businessPage = {} } = this.props;
      const facebookPermissions = platformPermissions.find(
        p => p.platform === "Facebook"
      );
      if (!fields || fields.length < 1) {
        fieldsAreValid = false;
        errMessages = {
          ...errMessages,
          fields: "Please add at least one field"
        };
        errFields.push("at least one field");
      }
      if (!headline) {
        fieldsAreValid = false;
        errMessages = {
          ...errMessages,
          noHeadline: "Please enter a headline"
        };
        errFields.push("a headline");
      }
      if (headline.length > 60) {
        fieldsAreValid = false;
        errMessages = {
          ...errMessages,
          noHeadline: "Headline must be 60 characters or less"
        };
        alert({
          type: "error",
          msg: "Headline must be 60 characters or less."
        });
      }
      if (!description) {
        fieldsAreValid = false;
        errMessages = {
          ...errMessages,
          noDescription: "Please enter a description"
        };
        errFields.push("a description");
      }
      if (!privacyPolicyValid && privacyPolicyUrl) {
        const urlValid = urlValidator(privacyPolicyUrl, true);
        if (urlValid === true) {
          this.setState({ privacyPolicyValid: true }, () =>
            this.updateForm({ ...form, privacyPolicyValid: true })
          );
        } else {
          fieldsAreValid = false;
          errMessages = {
            ...errMessages,
            privacy: urlValid
          };
          errFields.push("a valid Privacy Policy URL");
        }
      } else if (!privacyPolicyUrl) {
        fieldsAreValid = false;
        errMessages = {
          ...errMessages,
          privacy: "Please enter a Privacy Policy URL"
        };
        errFields.push("a Privacy Policy URL");
      }
      if (!thankyouTitle) {
        fieldsAreValid = false;
        errMessages = {
          ...errMessages,
          thankyouTitle: "Please enter a Thank You Page title"
        };
        errFields.push("a Thank You Page title");
      } else if (thankyouTitle.length > 60) {
        fieldsAreValid = false;
        errMessages = {
          ...errMessages,
          thankyouTitle: "Maximum title length is 60 characters"
        };
        errFields.push("a Thank You Page title of 60 characters or less");
      }
      if (!thankyouBody) {
        fieldsAreValid = false;
        errMessages = {
          ...errMessages,
          thankyouBody: "Please enter a Thank You Page message"
        };
        errFields.push("a Thank You Page message");
      }
      if (!thankyouButtonText) {
        fieldsAreValid = false;
        errMessages = {
          ...errMessages,
          thankyouBtnTxt: "Please select a Thank You Page button text"
        };
        errFields.push("Thank You Page button text");
      }
      if (thankyouButtonText === "call") {
        if (!thankyouPhone) {
          fieldsAreValid = false;
          errMessages = {
            ...errMessages,
            thankyouPhone: "Please enter a phone number"
          };
          errFields.push("a phone number");
        } else if (!thankyouPhoneRegion) {
          fieldsAreValid = false;
          errMessages = {
            ...errMessages,
            thankyouPhone: "Please select a phone region"
          };
          errFields.push("a phone region");
        }
      }
      if (!thankyouURL) {
        fieldsAreValid = false;
        errMessages = {
          ...errMessages,
          thankyouUrl: "Please enter a Thank You Page URL"
        };
        errFields.push("a Thank You Page URL");
      } else if (!urlValidator(thankyouURL)) {
        fieldsAreValid = false;
        errMessages = {
          ...errMessages,
          thankyouUrl: "Please enter a valid Thank You Page URL"
        };
        errFields.push("a valid Thank You Page URL");
      }
      if (!facebookPermissions || !facebookPermissions.leadsRetrieval) {
        fieldsAreValid = false;
        alert({
          type: "error",
          msg: "You do not have the correct permissions to receive Facebook leads. Please reconnect your Facebook page.",
          timeout: 0
        });
      }
      if (!businessPage.pageToken || !businessPage.adAccountId) {
        fieldsAreValid = false;
        alert({
          type: "error",
          msg: "Your Facebook page is not connected properly. Please go to your Settings and reconnect your Facebook page.",
          timeout: 0
        });
      }

      this.setState(
        {
          errMessages,
          errFields
        },
        () => resolve(fieldsAreValid)
      );
    });

  handleSingleInputValidation = input => {
    const inputName = input.target.name;
    // eslint-disable-next-line no-unused-vars
    let fieldsAreValid = true;
    const { noHeadline, noDescription, privacy, thankyouBtnTxt } =
      this.state.errMessages;
    let errMessages = {};
    const { pendingCampaign: { form = {} } = {}, thankyouPhoneRegion = "" } =
      this.state;
    const {
      fields = [],
      headline,
      description,
      thankyouTitle,
      thankyouBody,
      thankyouButtonText,
      thankyouURL,
      thankyouPhone,
      privacyPolicyUrl,
      privacyPolicyValid
    } = form;
    if (inputName === "headline" || noHeadline) {
      if (!headline) {
        fieldsAreValid = false;
        errMessages = {
          ...errMessages,
          noHeadline: "Please enter a headline"
        };
      }
      if (headline.length > 60) {
        fieldsAreValid = false;
        errMessages = {
          ...errMessages,
          noHeadline: "Headline must be 60 characters or less"
        };
      }
    }
    if ((inputName === "description" || noDescription) && !description) {
      fieldsAreValid = false;
      errMessages = {
        ...errMessages,
        noDescription: "Please enter a description"
      };
    }
    if (
      (inputName === "privacyPolicyUrl" || privacy) &&
      !privacyPolicyValid &&
      privacyPolicyUrl
    ) {
      const urlValid = urlValidator(privacyPolicyUrl, true);
      if (urlValid === true) {
        this.setState({ privacyPolicyValid: true }, () =>
          this.updateForm({ ...form, privacyPolicyValid: true })
        );
      } else {
        fieldsAreValid = false;
        errMessages = {
          ...errMessages,
          privacy: urlValid
        };
      }
    } else if (
      (inputName === "privacyPolicyUrl" || privacy) &&
      !privacyPolicyUrl
    ) {
      fieldsAreValid = false;
      errMessages = {
        ...errMessages,
        privacy: "Please enter a Privacy Policy URL"
      };
    }
    if (
      (inputName === "thankyouTitle" || this.state.errMessages.thankyouTitle) &&
      !thankyouTitle
    ) {
      fieldsAreValid = false;
      errMessages = {
        ...errMessages,
        thankyouTitle: "Please enter a Thank You Page title"
      };
    } else if (
      (inputName === "thankyouTitle" || this.state.errMessages.thankyouTitle) &&
      thankyouTitle.length > 60
    ) {
      fieldsAreValid = false;
      errMessages = {
        ...errMessages,
        thankyouTitle: "Maximum title length is 60 characters"
      };
    }
    if (
      (inputName === "thankyouBody" || this.state.errMessages.thankyouBody) &&
      !thankyouBody
    ) {
      fieldsAreValid = false;
      errMessages = {
        ...errMessages,
        thankyouBody: "Please enter a Thank You Page message"
      };
    }
    if (
      (inputName === "thankyouButtonText" || thankyouBtnTxt) &&
      !thankyouButtonText
    ) {
      fieldsAreValid = false;
      errMessages = {
        ...errMessages,
        thankyouBtnTxt: "Please select a Thank You Page button text"
      };
    }
    if (thankyouButtonText === "call") {
      if (
        (inputName === "thankyouPhone" ||
          this.state.errMessages.thankyouPhone) &&
        !thankyouPhone
      ) {
        fieldsAreValid = false;
        errMessages = {
          ...errMessages,
          thankyouPhone: "Please enter a phone number"
        };
      } else if (
        (inputName === "thankyouPhoneRegion" ||
          this.state.errMessages.thankyouPhone) &&
        !thankyouPhoneRegion
      ) {
        fieldsAreValid = false;
        errMessages = {
          ...errMessages,
          thankyouPhone: "Please select a phone region"
        };
      }
    }
    if (
      (inputName === "thankyouURL" || this.state.errMessages.thankyouUrl) &&
      !thankyouURL
    ) {
      fieldsAreValid = false;
      errMessages = {
        ...errMessages,
        thankyouUrl: "Please enter a Thank You Page URL"
      };
    } else if (
      (inputName === "thankyouURL" || this.state.errMessages.thankyouUrl) &&
      urlValidator(thankyouURL) !== true
    ) {
      console.log("URL not valid");
      fieldsAreValid = false;
      errMessages = {
        ...errMessages,
        thankyouUrl: "Please enter a valid Thank You Page URL"
      };
    }

    this.setState({
      errMessages
    });
  };

  openCampaignNameModal = () => {
    this.setState(
      {
        errMessages: {},
        errFields: []
      },
      async () => {
        const isValid = await this.handleFieldValidation();
        const { userProfile, alert } = this.props;
        if (isValid) {
          const { fbLeadgenTosAccepted } = userProfile;
          const { businessPage: { pageId = "" } = {} } = this.props;

          if (!fbLeadgenTosAccepted) {
            const checkAccepted = await leadGenStatusCheck(pageId);
            if (checkAccepted) {
              this.setState(
                {
                  leadgenTosAccepted: true
                },
                async () => {
                  this.creditCheck();
                }
              );
            } else {
              this.setState({ leadgenTosModalOpen: true });
            }
          } else {
            this.creditCheck();
          }
        } else {
          const { errFields } = this.state;
          if (errFields.length > 0) {
            let errFieldsString = "";
            if (errFields.length === 1) {
              errFieldsString = errFields[0];
            } else if (errFields.length === 2) {
              errFieldsString = errFields.join(" and ");
            } else {
              errFields[errFields.length - 1] =
                "and " + errFields[errFields.length - 1];
              errFieldsString = errFields.join(", ");
            }
            alert({
              type: "error",
              msg: `You are missing ${errFieldsString}`,
              timeout: 10000
            });
          }
        }
      }
    );
  };

  handleCampaignNameSave = () => {
    const { pendingCampaign } = this.state;
    const { name: campaignName } = pendingCampaign;
    if (!campaignName || campaignName === "") {
      this.setState({
        errMessages: {
          noCampaignName: "Please enter a valid name for your campaign!"
        }
      });
    } else {
      campaignUpdate(pendingCampaign);
      this.setState(
        {
          nameModalIsOpen: false,
          errMessages: {},
          errFields: []
        },
        () => {
          this.handleSubmitButton();
        }
      );
    }
  };

  toggleLeadgenModal = () => {
    const { leadgenTosModalOpen } = this.state;
    this.setState({ leadgenTosModalOpen: !leadgenTosModalOpen });
  };

  saveTemplate = async saveNew => {
    const {
      alert,
      userCompany: { _id: companyId, industry }
    } = this.props;

    const {
      pendingCampaign: { form, formTemplateId: oldId },
      saveTemplateName = "",
      savedForms = []
    } = this.state;
    if (!saveTemplateName || saveNew) {
      const matchingTemplate = savedForms.find(f => f._id === oldId);
      this.setState({
        modalTemplateName: true,
        saveTemplateName:
          matchingTemplate && matchingTemplate.templateName
            ? `${matchingTemplate.templateName} (copy)`
            : ""
      });
    } else {
      this.setState({ templateChange: false, savingTemplate: true });
      //saving the template
      const templateNameInternal = sanitizeString(saveTemplateName);
      // const templateNameInternal = docID;

      const newForm = {
        ...form,
        templateName: saveTemplateName,
        templateNameInternal,
        companyId,
        isStock: false,
        industry
      };
      try {
        const newFormResponse = await formTemplateCreate(newForm);
        const formTemplateId = newFormResponse._id;
        this.getTemplates();
        alert({
          type: "success",
          msg: "Template was saved."
        });
        this.updateForm(form, formTemplateId);
        this.setState({
          modalTemplateName: false,
          savingTemplate: false,
          saveTemplateName: ""
        });
      } catch (err) {
        const { status, message } = errorHandler(err);
        console.log("err", `${status}: ${message}`);
      }
    }
  };

  updateTemplate = async () => {
    const { alert } = this.props;
    //saving the template
    const {
      pendingCampaign: { form = {}, formTemplateId = "" },
      savedForms
    } = this.state;
    if (!formTemplateId) {
      console.log("form template missing");
      return;
    }
    this.setState({ templateChange: false, savingTemplate: true });
    try {
      const matchingForm = savedForms.find(s => s._id === formTemplateId);
      if (matchingForm) {
        formTemplateUpdate({ ...matchingForm, ...form });
        alert({
          type: "success",
          msg: "Template was successfully updated."
        });
        this.getTemplates();
      } else {
        console.log("form template not found");
      }
      this.setState({ savingTemplate: false });
    } catch (err) {
      const { status, message } = errorHandler(err);
      console.log("err", `${status}: ${message}`);
    }
  };

  removeTemplate = async () => {
    const {
      modalTemplateRemove,
      pendingCampaign: { form, formTemplateId }
    } = this.state;
    if (!modalTemplateRemove) {
      this.setState({ modalTemplateRemove: true });
    } else {
      this.setState({ savingTemplate: true, modalTemplateRemove: false });
      const { alert } = this.props;
      //saving the template
      const pendingForm = {
        fields: [],
        headline: "",
        description: "",
        thankyouTitle: "",
        thankyouBody: "",
        thankyouURL: "",
        thankyouButtonText: "",
        thankyouPhone: "",
        privacyPolicyUrl: "https://",
        privacyPolicyValid: false
      };
      try {
        await formTemplateDelete(formTemplateId);
        alert({
          type: "success",
          msg: "Template was successfully removed."
        });
        this.getTemplates();
        this.updateForm({ ...form, ...pendingForm }, "");
        this.setState({ savingTemplate: false });
      } catch (err) {
        console.log(err.message);
      }
    }
  };

  handleCarouselNext = () => {
    const { currentSlide } = this.state;
    if (currentSlide === 3) {
      this.setState({ currentSlide: 0 });
    } else {
      this.setState({ currentSlide: currentSlide + 1 });
    }
  };

  handleCarouselPrev = () => {
    const { currentSlide } = this.state;
    if (currentSlide === 0) {
      this.setState({ currentSlide: 3 });
    } else {
      this.setState({ currentSlide: currentSlide - 1 });
    }
  };

  creditCheck = async () => {
    const { pendingCampaign: { credits: { original = 0 } = {} } = {} } =
      this.state;
    try {
      const creditResponse = await adCreditsCheck({
        adCreditsAmount: original
      });
      if (creditResponse.success) {
        this.setState({ nameModalIsOpen: true });
      } else if (creditResponse.status === "notEnoughCredits") {
        this.setState({ refillingCredits: true }, () => {
          this.props.openCreditModal(
            "campaignRequired",
            creditResponse.creditsRequired,
            original
          );
        });
      } else if (creditResponse.status === "failedPayments") {
        this.setState({ modalFailedPayments: true });
      } else {
        console.log("error checking credits", creditResponse);
      }
    } catch (err) {
      console.log("error", err.message);
    }
  };

  render() {
    const {
      errMessages,
      privacyModalOpen,
      pendingCampaign = {},
      fieldsMessage,
      useCampaignName,
      formName = "",
      fieldCountStatus,
      submissionComplete,
      leadgenTosModalOpen,
      submissionWaiting,
      statusModalIsOpen,
      savedForms,
      stockForms,
      templateChange,
      savingTemplate,
      currentSlide,
      targeting = {},
      thankyouPhoneRegion = "",
      stockFormsLoading
    } = this.state;
    const {
      form = {},
      formTemplateId = "",
      name: campaignName = ""
    } = pendingCampaign;
    const {
      fields = [],
      headline = "",
      description = "",
      thankyouTitle = "",
      thankyouBody = "",
      thankyouButtonText = "",
      thankyouURL = "",
      thankyouPhone = "",
      privacyPolicyUrl = ""
    } = form;
    const { userProfile, userCompany, businessPage = {} } = this.props;
    const { name: pageName = "", image: pageImage = "" } = businessPage;
    let coverImage;
    const { media = [] } = pendingCampaign;
    if (media[0]) {
      if (media[0].type === "VIDEO") {
        coverImage = cloudinaryFormat({
          type: "video",
          height: 300,
          width: 322,
          filename: media[0].name,
          format: "jpg"
        });
      } else {
        coverImage = cloudinaryFormat({
          type: "image",
          height: 300,
          filename: media[0].filename ? media[0].filename : media[0].public_id,
          format: media[0].format
            ? media[0].format
            : media[0].filename.substring(media[0].filename.indexOf(".") + 1)
        });
      }
    }
    return (
      <div>
        {submissionComplete ? (
          <Thankyou
            userProfile={userProfile}
            submissionComplete={submissionComplete}
            history={this.props.history}
            userCompany={this.props.userCompany}
            pendingCampaign={pendingCampaign}
          />
        ) : (
          <div>
            <SectionGroup>
              {targeting.description && <h2>{targeting.description}</h2>}
              {/* <ProgressBar
                pendingCampaign={pendingCampaign}
                currentPage="leadForm"
              /> */}
              <ProgressBarTitle>
                <h4>Lead Form Creation</h4>
                <p>
                  Create a lead form to use with your Boostpoint lead ad
                  campaign.
                  {/* You can also choose one from on of our stock form
                  templates or one of your previously saved forms. */}
                </p>
              </ProgressBarTitle>
              <AdTemplate>
                <AdTemplateLabel>
                  <label>Form Templates</label>
                </AdTemplateLabel>
                <AdTemplateWrapper>
                  <div>
                    <h5>My Saved Forms</h5>
                    <InputContainer>
                      <Dropdown
                        type='text'
                        name='formDropdown-saved'
                        onChange={input =>
                          this.handleFormDropdown(input, false)
                        }
                        value={formTemplateId}
                      >
                        <option key='defaultValue'>Select One</option>
                        {savedForms.length > 0
                          ? savedForms.map(content => (
                              <option key={content._id} value={content._id}>
                                {content.templateName}
                              </option>
                            ))
                          : null}
                      </Dropdown>
                    </InputContainer>
                  </div>
                  <div>
                    <h5>Stock Forms</h5>
                    <InputContainer>
                      <Dropdown
                        type='text'
                        name='formDropdown-stock'
                        onChange={input => this.handleFormDropdown(input, true)}
                        value={formTemplateId}
                      >
                        {stockForms.length > 0 ? (
                          <option key='defaultValue'>Select One</option>
                        ) : stockFormsLoading ? (
                          <option key='defaultValue'>Loading...</option>
                        ) : (
                          <option key='defaultValue'>
                            No stock forms available
                          </option>
                        )}
                        {stockForms.length > 0
                          ? stockForms.map(content => (
                              <option key={content._id} value={content._id}>
                                {content.templateName}
                              </option>
                            ))
                          : null}
                      </Dropdown>
                    </InputContainer>
                  </div>
                </AdTemplateWrapper>
              </AdTemplate>
            </SectionGroup>
            <LeadAdForm
              userProfile={userProfile}
              userCompany={userCompany}
              alert={this.props.alert}
              updateFields={this.updateFields}
              formFields={fields}
              errMessages={errMessages}
              fieldsMessage={fieldsMessage}
              fieldCountStatus={fieldCountStatus}
              specialAdCategories={targeting.specialAdCategories}
              loadingForm={this.state.loadingForm}
              handleFieldsFiltered={this.handleFieldsFiltered}
              filterFields={this.filterFields}
            />
            <SectionGroup id='form-text' padding>
              <SectionTitle>
                <SectionTitleText>Form Headline</SectionTitleText>
              </SectionTitle>
              <TextWrapper>
                <InputContainer customWidth={"30rem"}>
                  <InputLabel htmlFor='headline'>
                    Form Headline/Question Title<Required>*</Required>
                  </InputLabel>
                  <InputBoxWrapper>
                    <InputBox
                      type='text'
                      name='headline'
                      value={headline}
                      onChange={input => this.handleContentChange(input)}
                      onBlur={input => this.handleSingleInputValidation(input)}
                    />
                  </InputBoxWrapper>
                  <InputError>{errMessages.noHeadline}</InputError>
                </InputContainer>
                <InputContainer customWidth={"30rem"}>
                  <InputLabel htmlFor='description'>
                    Form Description<Required>*</Required>
                  </InputLabel>
                  <InputBoxWrapper>
                    <TextArea
                      name='description'
                      value={description}
                      onChange={input => this.handleContentChange(input)}
                      onBlur={input => this.handleSingleInputValidation(input)}
                    />
                  </InputBoxWrapper>
                  <InputError>{errMessages.noDescription}</InputError>
                </InputContainer>
              </TextWrapper>
              <RequiredText>
                <Required>*</Required>Indicates Required Field
              </RequiredText>
            </SectionGroup>
            <SectionGroup id='privacy-policy' padding>
              <SectionTitle>
                <SectionTitleText>Privacy Policy</SectionTitleText>
              </SectionTitle>
              <PrivacyPolicyWrapper>
                <PrivacyTextWrapper>
                  <div>
                    Because you will be collecting customer information, you
                    need to include a link to your company’s privacy policy.
                    Your link will appear with Facebook’s default privacy
                    disclaimer.
                  </div>
                  <InputContainer customWidth={"30rem"}>
                    <Label htmlFor='privacyPolicyUrl'>
                      Privacy Policy URL<Required>*</Required>
                    </Label>
                    <InputBoxWrapper>
                      <InputBox
                        type='text'
                        name='privacyPolicyUrl'
                        value={privacyPolicyUrl}
                        onChange={input => this.handleContentChange(input)}
                        onBlur={this.handlePolicySave}
                      />
                    </InputBoxWrapper>
                    <InputError>{errMessages.privacy}</InputError>
                  </InputContainer>
                </PrivacyTextWrapper>
                <PrivacyDescriptionWrapper>
                  <div>
                    <p>
                      You can use Boostpoint's generic privacy policy. This is a
                      basic privacy policy that covers collecting personal
                      information.{" "}
                      <a
                        href='https://www.boostpoint.com/privacy-policy-lead-ads/'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        Preview here.
                      </a>
                    </p>
                    <Button onClick={this.handleGenericPolicy}>
                      Use Generic Policy
                    </Button>
                  </div>
                  <div>
                    <p>
                      Want a more custom policy? You can create one by clicking
                      the button below and going to freeprivacypolicy.com.
                    </p>
                    <Button onClick={this.togglePrivacyModal}>
                      Create a Privacy Policy.
                    </Button>
                  </div>
                </PrivacyDescriptionWrapper>
              </PrivacyPolicyWrapper>

              <RequiredText>
                <Required>*</Required>Indicates Required Field
              </RequiredText>
            </SectionGroup>
            <SectionGroup id='thankyou' padding>
              <SectionTitle>
                <SectionTitleText>Thank You Text</SectionTitleText>
              </SectionTitle>
              <TextWrapper>
                <InputContainer customWidth={"30rem"}>
                  <InputLabel htmlFor='headline'>
                    Thank You Title<Required>*</Required>
                  </InputLabel>
                  <InputBoxWrapper>
                    <InputBox
                      type='text'
                      name='thankyouTitle'
                      value={thankyouTitle}
                      onChange={input => this.handleContentChange(input)}
                      onBlur={input => this.handleSingleInputValidation(input)}
                    />
                  </InputBoxWrapper>
                  <InputError>{errMessages.thankyouTitle}</InputError>
                </InputContainer>

                <InputContainer customWidth={"30rem"}>
                  <InputLabel htmlFor='body'>
                    Thank You Message<Required>*</Required>
                  </InputLabel>
                  <InputBoxWrapper>
                    <TextArea
                      name='thankyouBody'
                      value={thankyouBody}
                      onChange={input => this.handleContentChange(input)}
                      onBlur={input => this.handleSingleInputValidation(input)}
                    />
                  </InputBoxWrapper>
                  <InputError>{errMessages.thankyouBody}</InputError>
                </InputContainer>

                <InputContainer customWidth={"30rem"}>
                  <InputLabel htmlFor='url'>
                    Website URL<Required>*</Required>
                  </InputLabel>
                  <InputBoxWrapper>
                    <InputBox
                      type='text'
                      name='thankyouURL'
                      value={thankyouURL}
                      onChange={input => this.handleContentChange(input)}
                      onBlur={input => this.handleSingleInputValidation(input)}
                    />
                  </InputBoxWrapper>
                  <InputError>{errMessages.thankyouUrl}</InputError>
                </InputContainer>

                <InputContainer customWidth={"30rem"}>
                  <InputLabel htmlFor='buttonTxt'>
                    Button Text<Required>*</Required>
                  </InputLabel>
                  <InputBoxWrapper>
                    <Dropdown
                      name='thankyouButtonText'
                      value={thankyouButtonText}
                      onChange={input => this.handleThankyouChange(input)}
                      onBlur={input => this.handleSingleInputValidation(input)}
                    >
                      <option disabled value=''>
                        --Please choose an option--
                      </option>
                      <option value='url'>Visit Website</option>
                      <option value='call'>Call Business</option>
                    </Dropdown>
                  </InputBoxWrapper>
                  <InputError>{errMessages.thankyouBtnTxt}</InputError>
                </InputContainer>

                {thankyouButtonText === "call" && (
                  <InputContainer>
                    <InputLabel htmlFor='phone'>Phone Number</InputLabel>
                    <PhoneWrapper>
                      <select
                        name='thankyouPhoneRegion'
                        onChange={input => this.handleThankyouChange(input)}
                        value={thankyouPhoneRegion}
                      >
                        <option disabled value=''>
                          Select your Phone Region
                        </option>
                        <option value='US'>US +1</option>
                        <option value='CA'>CA +1</option>
                        <option value='MX'>MX +52</option>
                      </select>
                      <Cleave
                        style={{ textAlign: "center" }}
                        name='thankyouPhone'
                        options={{
                          phone: true,
                          phoneRegionCode: thankyouPhoneRegion
                        }}
                        onChange={input => this.handleThankyouChange(input)}
                        onBlur={input =>
                          this.handleSingleInputValidation(input)
                        }
                        value={thankyouPhone}
                      />
                      <InputError>{errMessages.thankyouPhone}</InputError>
                    </PhoneWrapper>
                  </InputContainer>
                )}
              </TextWrapper>
              <RequiredText>
                <Required>*</Required>Indicates Required Field
              </RequiredText>
            </SectionGroup>
            <SectionGroup padding heightOff>
              <SectionTitle>
                <SectionTitleText id='manageTemplate'>
                  {savedForms.some(s => s._id === formTemplateId)
                    ? "This Template"
                    : "Save Template"}
                </SectionTitleText>
              </SectionTitle>
              {templateChange && formTemplateId && (
                <p>You have made changes to the template.</p>
              )}
              {savedForms.some(s => s._id === formTemplateId) ? (
                <div>
                  <Button
                    onClick={this.updateTemplate}
                    solid={templateChange}
                    disabled={savingTemplate}
                  >
                    Update
                  </Button>
                  <Button
                    onClick={() => this.saveTemplate(true)}
                    disabled={savingTemplate}
                  >
                    Save New
                  </Button>
                  <Button
                    disabled={savingTemplate}
                    onClick={this.removeTemplate}
                  >
                    Remove
                  </Button>
                </div>
              ) : (
                <Button
                  onClick={() => this.saveTemplate(false)}
                  disabled={savingTemplate}
                >
                  Save new template
                </Button>
              )}
            </SectionGroup>
            <SectionGroup id='previews' padding>
              <PreviewContainer>
                <SectionTitle>
                  <SectionTitleText>Lead Form Preview</SectionTitleText>
                </SectionTitle>
                <div>
                  <CarouselBtn onClick={this.handleCarouselPrev}>
                    <span className='material-icons'>arrow_back</span>
                  </CarouselBtn>
                  <CarouselStatus>{currentSlide + 1} of 4</CarouselStatus>
                  <CarouselBtn onClick={this.handleCarouselNext}>
                    <span className='material-icons'>arrow_forward</span>
                  </CarouselBtn>
                </div>
                <Carousel
                  naturalSlideWidth={300}
                  naturalSlideHeight={500}
                  totalSlides={4}
                  showIndicators={false}
                  selectedItem={currentSlide}
                  dynamicHeight={false}
                  showArrows={false}
                  showThumbs={false}
                  showStatus={false}
                >
                  <LeadAdPreviewContextCard
                    fields={fields}
                    headline={headline}
                    description={description}
                    coverImage={coverImage}
                    privacyPolicyUrl={privacyPolicyUrl}
                    pageName={pageName}
                    pageImage={pageImage}
                  />
                  <LeadAdPreview
                    fields={fields}
                    headline={headline}
                    coverImage={coverImage}
                  />
                  <LeadAdPrivacy
                    headline={headline}
                    coverImage={coverImage}
                    privacyPolicyUrl={privacyPolicyUrl}
                    pageName={pageName}
                  />
                  <LeadAdPreviewThankyou
                    title={thankyouTitle}
                    body={thankyouBody}
                    buttonTxt={thankyouButtonText}
                    coverImage={coverImage}
                    pageName={pageName}
                    pageImage={pageImage}
                  />
                </Carousel>
              </PreviewContainer>
              <h6 style={{ fontStyle: "italic" }}>
                **These are Boostpoint generated previews.
              </h6>
              <SubmitButton type='button' onClick={this.handlePreviousButton}>
                {`<- Back`}
              </SubmitButton>
              <SubmitButton
                type='button'
                solid
                onClick={this.openCampaignNameModal}
                disabled={submissionWaiting}
                className='submit'
              >
                Submit {submissionWaiting && <LoaderDotsSmall />}
              </SubmitButton>
            </SectionGroup>
            <BpModal
              open={privacyModalOpen}
              title={"Create a Privacy Policy"}
              primaryAction={{
                btnLabel: "Save",
                action: this.savePrivacyModal
              }}
              secondaryAction={{
                btnLabel: "Cancel",
                action: this.togglePrivacyModal
              }}
              shouldCloseOnOverlayClick={false}
              shouldCloseOnEsc={false}
              contentLabel='Privacy Policy'
              body={
                <div>
                  <p>
                    Clicking the Continue button below will take you to
                    freeprivacypolicy.com. When you are finished creating your
                    privacy policy, you will receive an email. Please copy and
                    paste the URL in the email here.
                  </p>
                  <p>
                    <Link
                      solid
                      href='https://www.freeprivacypolicy.com/free-privacy-policy-generator.php'
                      target='_blank'
                    >
                      Continue
                    </Link>
                  </p>
                  <p>
                    <PrivacyInput
                      type='text'
                      name='privacyPolicyUrl'
                      value={form.privacyPolicyUrl}
                      onChange={input => this.handleContentChange(input)}
                      onBlur={this.handlePolicySave}
                    />
                  </p>
                </div>
              }
              width='auto'
            />
            <BpModal
              open={leadgenTosModalOpen}
              title={`Accept Facebook's Leadgen Terms of Service`}
              primaryAction={{
                btnLabel: `I'm Done`,
                action: this.openCampaignNameModal
              }}
              secondaryAction={{
                btnLabel: "Cancel",
                action: this.toggleLeadgenModal
              }}
              shouldCloseOnOverlayClick={false}
              shouldCloseOnEsc={false}
              contentLabel='Leadgen Terms of Service'
              body={
                <div>
                  <p>
                    You need to accept Facebook's Leadgen Terms of Service
                    before you can submit a Leadgen ad. Click the continue
                    button to be taken to the accept page.
                  </p>
                  <Link
                    solid
                    href='https://www.facebook.com/ads/leadgen/tos'
                    target='_blank'
                  >
                    Continue
                  </Link>
                  <Ul>
                    <li>
                      Make sure the correct page is showing in the dropdown at
                      the top left of the page
                    </li>
                    <li>Click Accept</li>
                    <li>
                      Come back here and click <i>I'm Done</i>
                    </li>
                  </Ul>
                </div>
              }
              width='auto'
            />
            <BpModal
              open={statusModalIsOpen}
              title={`You have an existing ${this.state.pendingCampaign.type} campaign`}
              primaryAction={{
                btnLabel: "Continue",
                action: this.handleContinue
              }}
              secondaryAction={{
                btnLabel: "Start Over",
                action: this.handleStartOver
              }}
              shouldCloseOnOverlayClick={false}
              shouldCloseOnEsc={false}
              contentLabel='Existing Campaign'
              body={<p>Would you like to continue with this campaign?</p>}
              width='auto'
            />
            <BpModal
              open={this.state.nameModalIsOpen}
              title={"Campaign Name"}
              primaryAction={{
                btnLabel: "Submit",
                action: this.handleCampaignNameSave
              }}
              secondaryAction={{
                btnLabel: "Cancel",
                action: () => {
                  this.setState({ nameModalIsOpen: false });
                }
              }}
              shouldCloseOnOverlayClick={false}
              shouldCloseOnEsc={false}
              contentLabel='Campaign Name Modal'
              body={
                <div>
                  <p>Please give this campaign a name.</p>
                  <TemplateNameInput
                    type='text'
                    name='campaignName'
                    value={campaignName}
                    onChange={input =>
                      this.setState({
                        pendingCampaign: {
                          ...pendingCampaign,
                          name: input.target.value
                        }
                      })
                    }
                  />
                  {/* Error message for this input */}
                  <div>
                    <InputError>
                      {this.state.errMessages.noCampaignName}
                    </InputError>
                  </div>
                  <p>
                    <input
                      type='checkbox'
                      name='useCampaignName'
                      value='useCampaignName'
                      checked={useCampaignName}
                      onChange={this.handleNameCheckbox}
                    />
                    <label
                      htmlFor='useCampaignName'
                      onClick={this.handleNameCheckbox}
                    >
                      Use Campaign Name as Form Name
                    </label>
                  </p>
                  <p>
                    <NameLabel htmlFor='formName' disabled={useCampaignName}>
                      Form Name
                    </NameLabel>
                    <br />
                    <Input
                      type='text'
                      name='formName'
                      value={useCampaignName ? campaignName : formName}
                      onChange={this.handleFormName}
                      disabled={useCampaignName}
                    />
                  </p>
                </div>
              }
              width='auto'
            />
            <BpModal
              open={this.state.modalTemplateName}
              title={"Template Name"}
              primaryAction={{
                btnLabel: "Save Template",
                action: () => this.saveTemplate(false),
                loading: savingTemplate
              }}
              secondaryAction={{
                btnLabel: "Cancel",
                action: () => {
                  this.setState({
                    modalTemplateName: false,
                    savingTemplate: false
                  });
                }
              }}
              shouldCloseOnOverlayClick={false}
              shouldCloseOnEsc={false}
              contentLabel='Template Name Modal'
              body={
                <div>
                  <p>Please give this template a name.</p>
                  <TemplateNameInput
                    type='text'
                    name='campaignName'
                    value={this.state.saveTemplateName}
                    onChange={input =>
                      this.setState({
                        saveTemplateName: input.target.value
                      })
                    }
                  />
                </div>
              }
              width='auto'
            />
            <BpModal
              open={this.state.modalTemplateRemove}
              title={"Remove Template"}
              primaryAction={{
                btnLabel: "Remove Template",
                action: this.removeTemplate
              }}
              secondaryAction={{
                btnLabel: "Cancel",
                action: () => {
                  this.setState({ modalTemplateRemove: false });
                }
              }}
              shouldCloseOnOverlayClick={false}
              shouldCloseOnEsc={false}
              contentLabel='Template Remove Confirmation'
              body={
                <p>
                  Are you sure you want to remove this template? This action
                  cannot be undone.
                </p>
              }
              width='auto'
            />
            <BpModal
              open={this.state.modalFailedPayments}
              title={`You have failed payments`}
              primaryAction={{
                btnLabel: "Fix",
                action: () =>
                  this.props.history.push({ pathname: "/member-settings" })
              }}
              secondaryAction={{
                btnLabel: "Close",
                action: () => this.setState({ modalFailedPayments: false })
              }}
              onAfterOpen={this.afterOpenModal}
              shouldCloseOnOverlayClick={false}
              shouldCloseOnEsc={false}
              contentLabel='Failed Payments'
              body={
                <p>
                  Please fix your failed payments before submitting your ad.
                  Clicking Fix will take you to your Member Settings where you
                  can update your credit card or try again with the card on
                  file.
                </p>
              }
              width='auto'
            />
            <BpModal
              open={this.state.modalFilteredFields && !statusModalIsOpen}
              title={
                <span>
                  <Icon icon='error_outline' inline={true} type='warning' />
                  <span>Some fields have been removed</span>
                </span>
              }
              primaryAction={{
                btnLabel: "Ok",
                action: () => this.setState({ modalFilteredFields: false })
              }}
              shouldCloseOnOverlayClick={false}
              shouldCloseOnEsc={false}
              contentLabel='Some fields have been removed'
              body={
                <p>
                  Due to Facebook's most recent non-discrimination policies for
                  recruiting ads, we removed some of the fields (like street
                  address, city, zip, etc) from your form that would violate
                  that policy.{" "}
                  <a
                    href='https://www.facebook.com/policies/ads/prohibited_content/discriminatory_practices'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    To read more about the non-discrimination policy click here.
                  </a>
                </p>
              }
              width='35rem'
            />

            {this.state.redirectStartOver && (
              <Redirect to='/campaign-select' push />
            )}
            {this.state.redirectToArea && this.state.pendingCampaign.type && (
              <Redirect to='/area-campaign' push />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default LeadAdCreate;
