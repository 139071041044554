import React from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import styled from "styled-components";
import LoadingCircle from "../../styled/LoadingCircle";
import { stripeGetInvoiceById } from "../../../utils/StripeAPI";

import { LoaderDotsSmall } from "../../elements/Loaders";
import { Button, ModalButtonWrapper } from "../../styled/Buttons";

const PaymentFixWrapper = styled.div`
  min-width: 400px;
  max-width: 530px;
  margin: 0 auto;
  p.invoice-description {
    margin-top: 20px;
  }
`;
const CCContainer = styled.div`
  width: 25em;
  margin: 1em auto;
  max-width: 90%;
  border: solid 0.5px rgb(238, 238, 238);
  padding: 0.5em;
`;

const LineItemsTable = styled.table`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  border-collapse: collapse;
  th {
    text-align: center;
  }
  .align-right {
    text-align: right;
    padding-right: 5px;
  }
  .align-left {
    text-align: left;
    padding-left: 5px;
  }
`;
const TableHeader = styled.th`
  padding-bottom: 10px;
`;

const TableRow = styled.tr`
  margin-top: 10px;
  td {
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
    padding: 10px 0;
  }
`;

const AmountDueRow = styled.tr`
  font-weight: bold;
  td {
    padding-top: 10px;
  }
`;
const AmountDue = styled.td`
  margin-top: 10px;
  text-align: right;
`;

const env = process.env.REACT_APP_STRIPE_ENV
  ? process.env.REACT_APP_STRIPE_ENV
  : process.env.NODE_ENV;

class StripePaymentFix extends React.Component {
  state = {
    invoice: {},
    invoiceLoading: true,
    defaultMethod: true,
    hideCardElement: false,
    ccValid: {}
  };

  componentDidMount = async () => {
    const { invoiceId } = this.props;
    const invoiceResponse = await stripeGetInvoiceById(invoiceId);
    console.log("invoiceResponse", invoiceResponse);
    this.setState({
      invoice: invoiceResponse,
      invoiceLoading: false
    });
  };

  formatMoney = money => (money / 100).toFixed(2);

  handleDefaultMethodChange = () => {
    const { defaultMethod } = this.state;
    this.setState({ defaultMethod: !defaultMethod });
  };

  handlePayInvoiceClick = async () => {
    const { defaultMethod } = this.state;
    try {
      const stripeToken = await this.props.stripe.createToken({});
      this.setState({ invoiceLoading: true });
      const { token: source } = stripeToken;
      console.log("stripe token", source);
      this.props.handleInvoicePaymentFixUpdate(defaultMethod, source.id);
    } catch (err) {
      console.log("error paying invoice", err.message);
    }
  };

  stripeElementChange = cc => {
    // console.log('element change is ', cc);
    this.setState({ ccValid: cc });
  };

  render() {
    const { invoiceLoading, invoice, hideCardElement, defaultMethod } =
      this.state;
    const { custUpdateLoading } = this.props;
    return (
      <PaymentFixWrapper>
        {invoiceLoading ? (
          <LoadingCircle />
        ) : (
          <div>
            ${this.formatMoney(invoice.amountDue)} due{" "}
            {this.props.formatDate(new Date(invoice.dueDate))}
            {!hideCardElement ? (
              <CCContainer>
                <CardElement onChange={this.stripeElementChange} />
              </CCContainer>
            ) : (
              <LoaderDotsSmall />
            )}
            <input
              type='checkbox'
              name='default-method'
              checked={defaultMethod}
              onChange={this.handleDefaultMethodChange}
            />
            <label
              htmlFor='default-method'
              onClick={this.handleDefaultMethodChange}
            >
              Make this my default payment method.
            </label>
            <p className='invoice-description'>{invoice.description}</p>
            <LineItemsTable>
              <thead>
                <tr>
                  <TableHeader className='align-left'>Description</TableHeader>
                  <TableHeader>Qty</TableHeader>
                  <TableHeader>Price</TableHeader>
                  <TableHeader className='align-right'>Total</TableHeader>
                </tr>
              </thead>
              <tbody>
                {invoice.lines &&
                  invoice.lines.map((lineItem, index) => (
                    <TableRow key={index}>
                      <td className='align-left'>{lineItem.description}</td>
                      <td>{lineItem.quantity}</td>
                      <td>${this.formatMoney(lineItem.amount)}</td>
                      <td className='align-right'>
                        ${this.formatMoney(lineItem.amount * lineItem.quantity)}
                      </td>
                    </TableRow>
                  ))}
                <AmountDueRow>
                  <AmountDue colSpan='3'>Amount Due</AmountDue>
                  <td className='align-right'>
                    ${this.formatMoney(invoice.amountDue)}
                  </td>
                </AmountDueRow>
              </tbody>
            </LineItemsTable>
            <ModalButtonWrapper>
              <Button
                onClick={this.props.handleCloseInvoiceFix}
                disabled={custUpdateLoading}
                small
              >
                Cancel
              </Button>
              <Button
                solid
                onClick={this.handlePayInvoiceClick}
                disabled={custUpdateLoading}
                small
              >
                {defaultMethod ? "Save card and pay invoice" : "Pay invoice"}
                {custUpdateLoading && <LoaderDotsSmall />}
              </Button>
            </ModalButtonWrapper>
          </div>
        )}
      </PaymentFixWrapper>
    );
  }
}

export default injectStripe(StripePaymentFix, { withRef: true });
