import styled from "styled-components";

export const Title = styled.h1`
  margin: 90px auto 25px;
  text-transform: ${props => (props.uppercase ? "uppercase" : "")};
  color: ${props => props.theme.HC1};
  font-weight: 600;
  font-size: 26px;
`;

export const Subtitle = styled.h2`
  margin: 25px auto;
  text-transform: ${props => (props.uppercase ? "uppercase" : "")};
  color: ${props => props.theme.HC2};
  font-weight: 600;
  font-size: 22px;
`;

export const PageTitle = styled.h1`
  color: ${props => props.theme.HC1};
  font-weight: 600;
  font-size: 28px;
  white-space: nowrap;
  margin: 0;
  padding: 0 23px;
  span span {
    font-style: italic;
  }
`;
