import React from "react";
import styled from "styled-components";
import { Button } from "../../../styled/Buttons";
import { LoaderDotsSmall } from "../../../elements/Loaders";
import { firstBreakpoint } from "../../../styled/PhoneNumberProvision";

const Wrapper = styled.div`
  margin: 50px 60px 80px;
  a {
    color: ${props => props.theme.HC1};
    text-decoration: underline;
    font-style: italic;
  }
`;

const Header = styled.h1`
  font-size: 1.2em;
  text-transform: uppercase;
  margin: 1em 1em 2em;
`;

const ItemWrapper = styled.p`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 440px;
  margin: 1.6em auto;
  @media screen and (min-width: ${firstBreakpoint}px) {
    flex-direction: row;
  }
`;

const Icon = styled.span`
  font-size: 30px;
  color: ${props => props.theme.TextC};
  @media screen and (min-width: ${firstBreakpoint}px) {
    font-size: 24px;
  }
`;

const DetailWrapper = styled.span`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: inline;
    margin-bottom: 0;
  }
  span {
    margin: 9px 0 0px;
    @media screen and (min-width: ${firstBreakpoint}px) {
      margin: 0;
    }
  }
`;

const Details = styled.span`
  border-bottom: 3px solid ${props => props.theme.HC1};
  padding-bottom: 12px;
  font-weight: bold;
  @media screen and (min-width: ${firstBreakpoint}px) {
    padding-bottom: 4px;
  }
`;

const EditLink = styled.span`
  font-size: 0.9em;
  color: ${props => props.theme.HC1};
  cursor: pointer;
  border-radius: 1.2rem;
  border: 2px solid ${props => props.theme.HC1};
  height: 1.7rem;
  margin: 1rem;
  padding: 4px 1rem;
  display: inline-block;
  font-size: 13px;
  vertical-align: middle;
  @media screen and (min-width: ${firstBreakpoint}px) {
    border: none;
    height: auto;
    font-style: italic;
  }
  &:hover {
    text-decoration: underline;
  }
`;

const Disclaimer = styled.p`
  font-size: 0.8em;
  max-width: 440px;
  margin: 1em auto;
  @media screen and (min-width: ${firstBreakpoint}px) {
    font-size: 0.9em;
  }
`;

const ConfirmPurchase = props => {
  const { selectedNumber, paymentMethod, selectedPlan, loading } = props;
  return (
    <Wrapper>
      <Header>Confirm your Messaging Plan</Header>
      <ItemWrapper>
        <Icon className='material-icons'>smartphone</Icon>
        <DetailWrapper>
          <span>Number Selected:</span>{" "}
          <Details>{selectedNumber.friendlyName}</Details>
        </DetailWrapper>
        <EditLink onClick={() => props.goTo("numberSearch")}>edit</EditLink>
      </ItemWrapper>
      <ItemWrapper>
        <span className='material-icons'>credit_card</span>
        <DetailWrapper>
          <span>Payment Method:</span>
          <Details>Card Ending in {paymentMethod.last4}</Details>
        </DetailWrapper>
        <EditLink onClick={() => props.goTo("selectPricing")}>edit</EditLink>
      </ItemWrapper>
      <ItemWrapper>
        <span className='material-icons'>task</span>
        <DetailWrapper>
          <span>Selected Plan:</span>
          <Details>
            ${selectedPlan.amount}/{selectedPlan.interval}
          </Details>
        </DetailWrapper>
        <EditLink onClick={() => props.goTo("selectPricing")}>edit</EditLink>
      </ItemWrapper>
      <Disclaimer>
        By clicking 'Confirm Purchase' you agree to Boostpoint Connect Terms of
        Use and Privacy Policy. Once you purchase a number, you will not be able
        to change it.
      </Disclaimer>
      <Button onClick={props.back}>Back</Button>
      <Button solid onClick={props.next} disabled={loading}>
        Confirm Purchase
        {loading && <LoaderDotsSmall />}
      </Button>
    </Wrapper>
  );
};

export default ConfirmPurchase;
