import React from "react";
import queryString from "query-string";
import * as _ from "underscore";
import styled from "styled-components";
import BpModal from "../../elements/BpModal";
import ContactsTable from "./ContactsTable";
import ContactTags from "./ContactTags";
import InputTagChanges from "./InputTagChanges";

// Styled Components
import { InputBoxWrapper } from '../../styled/Input';
import StatusDropdown from './contactStatus/StatusDropdown';
import {
  contactsGet,
  contactCreate,
  contactDeleteById,
  deletedStatus,
  statusesGetByCompany,
  statusesGetByIndustry,
  newContact,
  tagsGetByCompany,
  contactUpdate
} from "../../../utils/ContactAPI";
import { campaignGetById } from "../../../utils/FacebookAPI";

const TagsList = styled.ul`
  display: inline-flex;
  flex-wrap: wrap;
  margin: 1em 0;
  padding: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const Tag = styled.li`
  background: ${props => (props.color ? props.color : props.theme.HC3)};
  border-radius: 2px;
  color: white;
  display: block;
  font-size: 13px;
  font-weight: 300;
  list-style: none;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 0px 10px;
  border-radius: 1.2rem;
  white-space: nowrap;
  overflow: hidden;
  line-height: 1.8em;
  max-height: 1.8em;
  text-overflow: ellipsis;
  max-width: 13em;
`;

class Contacts extends React.Component {
  state = {
    contacts: [],
    contactCount: 0,
    showFilters: false,
    companyTags: [],
    tagFilterOperator: "or",
    contactsToEdit: [],
    selectedContacts: [],
    currentStatuses: [],
    statusToChangeTo: {},
    contactStatuses: [],
    contactTags: [],
    newContactTags: [],
    tagChanges: [],
    bulkUpdatingContacts: false,
    modalDeleteConfirm: false,
    modalEditStatus: false,
    modalConfirmStatuses: false,
    modalEditTags: false,
    modalEditTagsConfirm: false,
    editTagsConfirmChange: false,
    defaultFiltered: null,
    bulkEditOpen: false,
    filters: [],
    globalFilter: "",
    selectAllContacts: false,
    selectAllPages: false,
    hiddenColumns: ["source"],
    loadingContact: false
  };

  async componentDidMount() {
    const contactStatuses = await this.getStatuses();
    const companyTags = await this.getTags();
    const leadData = await this.getContacts();
    let newState = {
      contacts: leadData,
      contactStatuses
    };
    if (leadData && leadData.length > 0) {
      const contactCount = leadData.length;
      newState.contactCount = contactCount;
    }
    this.processContacts(leadData, companyTags, contactStatuses);
    const query = queryString.parse(this.props.location.search);
    const { campaignId, statusId } = query;
    if (campaignId) {
      const campaign = await campaignGetById(campaignId);
      if (campaign && campaign.name) {
        if (newState.defaultFiltered) {
          newState = {
            ...newState,
            defaultFiltered: [
              ...newState.defaultFiltered,
              { id: "campaignName", value: campaign.name }
            ]
          };
        } else {
          newState.defaultFiltered = [
            {
              id: "campaignName",
              value: campaign.name
            }
          ];
        }
      } else {
        newState.filterEmptyMessage =
          "There are no Campaigns matching the selected Campaign ID. Showing all contacts instead.";
      }
    }
    if (statusId) {
      const findStatus = contactStatuses.find(s => s._id === statusId);
      if (findStatus) {
        if (newState.defaultFiltered) {
          newState = {
            ...newState,
            defaultFiltered: [
              ...newState.defaultFiltered,
              { id: "status", value: [findStatus.status] }
            ]
          };
        } else {
          newState.defaultFiltered = [
            {
              id: "status",
              value: [findStatus.status]
            }
          ];
        }
      } else {
        newState.filterEmptyMessage =
          "There are no Statuses matching the selected status Id. Showing all contacts instead.";
      }
    }
    this.setState({ ...newState });
  }

  componentDidUpdate = async prevProps => {
    const { userCompany: { industry } = {} } = this.props;
    const { userCompany: { industry: prevIndustry } = {} } = prevProps;
    if (industry !== prevIndustry) {
      const contactStatuses = await this.getStatuses();
      this.setState({ contactStatuses });
      this.processContacts(this.state.contacts, [], contactStatuses);
    }
  };

  getContacts = async () => {
    try {
      const contacts = await contactsGet();
      const leads = contacts.filter(
        contact => contact.statusId !== deletedStatus
      );
      return leads;
    } catch (err) {
      console.log(err.message);
    }
  };

  getTags = async () => {
    try {
      const tags = await tagsGetByCompany();
      this.setState({ companyTags: tags });
      return tags;
    } catch (err) {
      console.log(err.message);
    }
  };

  getStatuses = async () => {
    const { userCompany: { industry } = {} } = this.props;
    try {
      const companyStatuses = await statusesGetByCompany();
      if (companyStatuses.length > 0) {
        return companyStatuses;
      } else if (industry) {
        const statuses = await statusesGetByIndustry(industry);
        return statuses;
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  processContacts = async (contacts, companyTags, contactStatuses = []) => {
    if (contacts) {
      const contactsUpdated = contacts.map(contact => {
        if (contact.tagIds && contact.tagIds.length > 0) {
          contact.tags = contact.tagIds
            .filter(tag => tag)
            .map(tag => {
              const matchingTag = companyTags.find(t => {
                if (t._id === tag) {
                  return true;
                } else {
                  return false;
                }
              });
              if (matchingTag) {
                return matchingTag;
              } else {
                return {};
              }
            });
        }
        if (contact.statusId && contactStatuses.length > 1) {
          const status = contactStatuses.filter(
            cStatus => cStatus._id === contact.statusId
          );
          if (status && status.length === 1) {
            contact.contactStatus = status[0];
          }
        }
        return contact;
      });
      this.setState({ contacts: contactsUpdated });
      return contactsUpdated;
    }
  };

  handleSelectedContacts = contactID => {
    const { selectedContacts } = this.state;
    if (selectedContacts.includes(contactID)) {
      const newSelectedContacts = selectedContacts.filter(
        contactList => contactList !== contactID
      );
      this.setState({
        selectedContacts: newSelectedContacts,
        selectAllContacts: false,
        selectAllPages: false
      });
    } else {
      this.setState({
        selectedContacts: [...selectedContacts, contactID],
        selectAllContacts: false,
        selectAllPages: false
      });
    }
  };

  handleSelectAll = contactIDs => {
    const { selectedContacts = [] } = this.state;
    if (selectedContacts.length === 0) {
      this.setState({
        selectedContacts: contactIDs,
        selectAllContacts: true
      });
    } else {
      this.setState({
        selectedContacts: [],
        selectAllContacts: false,
        selectAllPages: false
      });
    }
  };

  handleSelectAllPages = () => {
    const { contacts } = this.state;
    const contactIDs = contacts.map(contact => contact.contactID);
    this.setState({
      selectAllPages: !this.state.selectAllPages,
      selectAllContacts: contactIDs
    });
  };

  toggleFilters = () => {
    const { showFilters } = this.state;
    this.setState({ showFilters: !showFilters });
  };

  createContact = async () => {
    const { userCompany: { _id: companyId } = {}, alert } = this.props;
    const { contactStatuses } = this.state;
    const defaultStatus = contactStatuses.find(status => status.isDefault);
    try {
      if (companyId && defaultStatus) {
        this.setState({ loadingContact: true });
        const contact = {
          ...newContact,
          companyId: companyId,
          statusId: defaultStatus._id
        };
        const newContactResponse = await contactCreate(contact);
        const { _id: contactId } = newContactResponse;
        if (contactId) {
          this.setState({ loadingContact: false });
          alert({
            type: "success",
            msg: "Contact created successfully"
          });
          this.props.history.push(`/contacts/${contactId}`);
        } else {
          this.setState({ loadingContact: false });
          alert({
            type: "error",
            msg: "Something went wrong creating contact. Please refresh and try again."
          });
        }
      }
    } catch (err) {
      this.setState({ loadingContact: false });
      console.log(err.message);
    }
  };

  toggleFilterOperator = () => {
    const { tagFilterOperator } = this.state;
    if (tagFilterOperator === "or") {
      this.setState({ tagFilterOperator: "and" });
    } else {
      this.setState({ tagFilterOperator: "or" });
    }
  };

  deleteContact = async (e, selectedContact, deleteConfirmed) => {
    const { contactStatuses = [], companyTags = [] } = this.state;
    if (!deleteConfirmed) {
      this.setState({ modalDeleteConfirm: true, selectedContact });
    } else {
      const { alert } = this.props;
      try {
        this.setState({ deletingContact: true });
        await contactDeleteById(selectedContact);
        this.setState({
          deletingContact: false,
          modalDeleteConfirm: false
        });
        alert({ type: "success", msg: "Contact deleted." });
        const leadData = await this.getContacts();
        if (leadData && leadData.length > 0) {
          const contactCount = leadData.length;
          this.setState({
            contacts: leadData,
            contactCount,
            selectedContacts: [],
            selectAllContacts: false
          });
          this.processContacts(leadData, [], contactStatuses, companyTags);
        } else {
          this.setState({
            contacts: [],
            contactCount: 0,
            selectedContacts: [],
            selectAllContacts: false
          });
        }
      } catch (err) {
        console.log(err.message);
        this.setState({
          deletingContact: false,
          modalDeleteConfirm: false,
          selectedContacts: [],
          selectAllContacts: false
        });
        alert({
          type: "error",
          msg: "An error occurred. Please refresh and try again."
        });
      }
    }
  };

  toggleBulkEditMenu = () => {
    const { bulkEditOpen } = this.state;
    this.setState({ bulkEditOpen: !bulkEditOpen });
  };

  editContactStatuses = () => {
    const {
      modalEditStatus,
      modalConfirmStatuses,
      changeStatusConfirmed,
      contacts,
      selectedContacts,
      selectAllPages,
      contactStatuses = []
    } = this.state;
    if (
      !modalEditStatus &&
      !changeStatusConfirmed &&
      selectedContacts.length > 0
    ) {
      this.setState({ bulkEditOpen: false });
      let contactsToEdit = [];
      if (selectAllPages) {
        contactsToEdit = contacts;
      } else {
        contactsToEdit = contacts.filter(contact =>
          selectedContacts.includes(contact._id)
        );
      }
      const currentStatuses = [];
      const selectedStatuses = contactsToEdit.map(c => c.statusId);
      const reducedStatus = [...new Set(selectedStatuses)];
      reducedStatus.forEach(sId => {
        const matchingStatus = contactStatuses.find(cs => cs._id === sId);
        const contactCount = selectedStatuses.filter(cs => cs === sId);
        currentStatuses.push({
          ...matchingStatus,
          count: contactCount.length
        });
      });
      this.setState({ modalEditStatus: true, contactsToEdit, currentStatuses });
    } else if (!modalConfirmStatuses && !changeStatusConfirmed) {
      this.setState({ modalConfirmStatuses: true, modalEditStatus: false });
    } else if (changeStatusConfirmed) {
      this.updateBulkStatuses();
    }
  };

  handleStatusChange = async value => {
    const { contactStatuses = [] } = this.state;
    try {
      const newContactStatus = contactStatuses.find(
        status => status._id === value
      );
      this.setState({ statusToChangeTo: newContactStatus });
    } catch (err) {
      console.log("error setting contact status change", err.message);
    }
  };

  updateBulkStatuses = async () => {
    const { contactsToEdit, statusToChangeTo, contactStatuses, companyTags } =
      this.state;
    try {
      this.setState({ bulkUpdatingContacts: true });
      const wait = [];
      contactsToEdit.forEach(contact => {
        wait.push(
          contactUpdate({ ...contact, statusId: statusToChangeTo._id })
        );
      });
      await Promise.all(wait);
      this.setState({
        bulkUpdatingContacts: false,
        modalConfirmStatuses: false,
        changeStatusConfirmed: false,
        contactsToEdit: [],
        statusToChangeTo: {},
        currentStatus: [],
        selectedContacts: [],
        selectAllContacts: false
      });
      const leadData = await this.getContacts();
      if (leadData && leadData.length > 0) {
        const contactCount = leadData.length;
        this.setState({ contacts: leadData, contactCount, contactStatuses });
        this.processContacts(leadData, [], contactStatuses, companyTags);
      }
    } catch (err) {
      console.log("error editing contacts", err.message);
    }
  };

  editContactTags = () => {
    const {
      selectedContacts = [],
      editTagsConfirmChange,
      selectAllPages,
      tagChanges = [],
      contacts = [],
      companyTags = [],
      contactStatuses = []
    } = this.state;
    this.setState({ bulkEditOpen: false });
    let contactsToEdit = [];
    if (selectAllPages) {
      contactsToEdit = contacts;
    } else {
      contactsToEdit = contacts.filter(contact =>
        selectedContacts.includes(contact._id)
      );
    }
    const newContactTags = [];
    if (tagChanges.length < 1) {
      let contactTagIds = [];
      contactsToEdit.forEach(c => {
        if (c.tagIds) {
          contactTagIds = [...contactTagIds, ...c.tagIds];
        }
      });
      const reducedTags = [...new Set(contactTagIds)];
      reducedTags.forEach(tag => {
        const matchingTag = companyTags.find(ct => ct._id === tag);
        const tagCount = contactTagIds.filter(ct => ct === tag);
        newContactTags.push({ ...matchingTag, count: tagCount.length });
      });
      this.setState({ modalEditTags: true, newContactTags });
      return;
    } else if (!editTagsConfirmChange) {
      this.setState({ modalEditTagsConfirm: true, modalEditTags: false });
      return;
    }
    const adding = tagChanges
      .filter(changes => changes.action === "add")
      .map(changes => changes.tagToChange._id);
    const removing = tagChanges
      .filter(changes => changes.action === "remove")
      .map(changes => changes.tagToChange._id);
    const wait = [];
    contactsToEdit.forEach(contact => {
      const { tagIds = [] } = contact;
      const remainingTags = tagIds.filter(tag => !removing.includes(tag));
      const newTagIds = [...remainingTags, ...adding];
      wait.push(contactUpdate({ ...contact, tagIds: [...new Set(newTagIds)] }));
    });
    Promise.all(wait).then(async res => {
      this.setState({
        newContactTags: [],
        modalEditTagsConfirm: false,
        modalEditTags: false,
        contactToEdit: [],
        tagChanges: [],
        selectedContacts: [],
        selectAllContacts: false,
        contactTags: [],
        editTagsConfirmChange: false
      });
      const leadData = await this.getContacts();
      const newCompanyTags = await this.getTags();
      if (leadData && leadData.length > 0) {
        const contactCount = leadData.length;
        this.setState({
          contacts: leadData,
          contactCount,
          contactStatuses,
          companyTags: newCompanyTags
        });
        this.processContacts(leadData, [], contactStatuses, newCompanyTags);
      }
    });
  };

  handleTagsChange = (tagToChange, action) => {
    const { tagChanges = [] } = this.state;
    try {
      this.setState({ tagChanges: [...tagChanges, { tagToChange, action }] });
    } catch (err) {
      console.log(err.message);
    }
  };

  clearSelectedContacts = (filters, globalFilter = "") => {
    if (filters && filters.length !== this.state.filters.length) {
      this.setState({ selectedContacts: [], filters });
    }
    if (globalFilter && globalFilter !== this.state.globalFilter) {
      this.setState({ selectedContacts: [], globalFilter });
    }
  };

  render() {
    const { userProfile, alert } = this.props;
    const {
      contacts,
      contactCount,
      showFilters,
      currentStatuses,
      statusToChangeTo,
      contactStatuses = [],
      contactsToEdit,
      newContactTags = [],
      companyTags = [],
      hiddenColumns,
      selectedContacts,
      selectAllContacts,
      selectAllPages,
      bulkUpdatingContacts,
      tagFilterOperator,
      defaultFiltered,
      filterEmptyMessage,
      loadingContact,
      deletingContact,
      modalEditTagsConfirm,
      modalEditTags,
      tagChanges = []
    } = this.state;
    return (
      <div>
        <ContactsTable
          userProfile={userProfile}
          campaignHistory={this.props.campaignHistory}
          toggleFilters={this.toggleFilters}
          showFilters={showFilters}
          contacts={contacts}
          contactCount={contactCount}
          history={this.props.history}
          toggleFilterOperator={this.toggleFilterOperator}
          tagFilterOperator={tagFilterOperator}
          deleteContact={this.deleteContact}
          defaultFiltered={defaultFiltered}
          filterEmptyMessage={filterEmptyMessage}
          editContactStatuses={this.editContactStatuses}
          handleSelectedContacts={this.handleSelectedContacts}
          selectedContacts={selectedContacts}
          toggleBulkEditMenu={this.toggleBulkEditMenu}
          bulkEditOpen={this.state.bulkEditOpen}
          editContactTags={this.editContactTags}
          clearSelectedContacts={this.clearSelectedContacts}
          selectAllContacts={selectAllContacts}
          selectAllPages={selectAllPages}
          handleSelectAll={this.handleSelectAll}
          handleSelectAllPages={this.handleSelectAllPages}
          hiddenColumns={hiddenColumns}
          alert={alert}
          createContact={this.createContact}
          loadingContact={loadingContact}
        />
        <BpModal
          open={this.state.modalDeleteConfirm}
          title='Confirm Contact Delete'
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
          contentLabel='Confirm Contact Delete'
          onCloseMethod={() => {
            this.setState({ modalDeleteConfirm: false });
          }}
          primaryAction={{
            btnLabel: "Delete Contact",
            action: e =>
              this.deleteContact(e, this.state.selectedContacts[0], true),
            loading: deletingContact
          }}
          secondaryAction={{
            btnLabel: "Cancel",
            action: () => this.setState({ modalDeleteConfirm: false })
          }}
          body={
            <p>
              Are you sure you want to delete this contact? You cannot undo this
              action.
            </p>
          }
        />
        <BpModal
          open={this.state.modalEditStatus}
          title={`Edit Status for ${this.state.selectedContacts.length} Contacts`}
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
          contentLabel='Edit Contact Status'
          onCloseMethod={() => {
            this.setState({ modalEditStatus: false });
          }}
          primaryAction={{
            btnLabel: "Continue",
            action: this.editContactStatuses
          }}
          secondaryAction={{
            btnLabel: "Cancel",
            action: () =>
              this.setState({
                modalEditStatus: false,
                contactToEdit: [],
                currentStatuses: []
              })
          }}
          body={
            <div>
              <ul>
                {currentStatuses.map(status => (
                  <li key={status._id}>
                    <strong>{status.status}</strong>:{" "}
                    <em>
                      {status.count} contact{status.count > 1 ? "s" : ""}
                    </em>
                  </li>
                ))}
              </ul>
              <p>Change to</p>
              <InputBoxWrapper>
              <StatusDropdown
                statusValue={statusToChangeTo.statusID}
                contactStatuses={contactStatuses}
                onChange={input =>
                  this.handleStatusChange(input)
                }
              />
               
              </InputBoxWrapper>
            </div>
          }
        />
        <BpModal
          open={this.state.modalConfirmStatuses}
          title={`Confirm Status Change for ${this.state.selectedContacts.length} Contacts`}
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
          contentLabel='Confirm Status Change'
          onCloseMethod={() => {
            this.setState({ modalConfirmStatuses: false });
          }}
          primaryAction={{
            btnLabel: bulkUpdatingContacts ? "Updating" : "Confirm Change",
            action: () => {
              this.setState({ changeStatusConfirmed: true }, () =>
                this.editContactStatuses()
              );
            },
            loading: bulkUpdatingContacts
          }}
          secondaryAction={{
            btnLabel: "Cancel",
            action: () =>
              this.setState({
                modalConfirmStatuses: false,
                contactToEdit: [],
                currentStatuses: [],
                changeStatusConfirmed: false
              }),
            loading: bulkUpdatingContacts
          }}
          body={
            <div>
              <p>
                Change {contactsToEdit.length} contacts to{" "}
                {statusToChangeTo.status}
              </p>
              <p>
                <strong>Warning</strong> You are about to update{" "}
                {selectedContacts.length} contacts. You cannot undo this action.
              </p>
            </div>
          }
        />
        <BpModal
          open={modalEditTags}
          title={`Edit tags for ${selectedContacts.length} selected contacts`}
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
          contentLabel='Edit tags for selected contacts'
          onCloseMethod={() => {
            this.setState({ modalEditTags: false });
          }}
          primaryAction={{
            btnLabel: "Continue",
            action: this.editContactTags,
            loading: bulkUpdatingContacts
          }}
          secondaryAction={{
            btnLabel: "Cancel",
            action: () =>
              this.setState({
                modalEditTags: false,
                contactToEdit: [],
                contactTags: []
              }),
            loading: bulkUpdatingContacts
          }}
          styleOverride={{
            bodyStyles: {
              // minHeight: '27rem',
              allowOverflow: true
            },
            content: {
              overflow: "visible"
            }
          }}
          body={
            <div>
              <ContactTags
                alert={this.props.alert}
                contactTags={newContactTags}
                userProfile={userProfile}
                companyTags={companyTags}
                showLabel={false}
                handleTagsChange={this.handleTagsChange}
                showInfo={true}
                selectedContactCount={selectedContacts.length}
                showInModal={true}
                bulkEdit={true}
              />
              <InputTagChanges tags={tagChanges} />
            </div>
          }
        />
        <BpModal
          open={modalEditTagsConfirm}
          title={`Confirm tags for ${selectedContacts.length} selected contacts`}
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
          contentLabel='Edit tags for selected contacts'
          onCloseMethod={() => {
            this.setState({ modalEditTagsConfirm: false });
          }}
          primaryAction={{
            btnLabel: bulkUpdatingContacts ? "Updating" : "Confirm Change",
            action: () => {
              this.setState({ editTagsConfirmChange: true }, () =>
                this.editContactTags()
              );
            },
            loading: bulkUpdatingContacts
          }}
          secondaryAction={{
            btnLabel: "Cancel",
            action: () =>
              this.setState({
                modalEditTagsConfirm: false,
                contactToEdit: [],
                contactTags: [],
                tagChanges: {},
                selectedContacts: [],
                selectAllContacts: false
              }),
            loading: bulkUpdatingContacts
          }}
          body={
            <div>
              <InputTagChanges tags={tagChanges} />
              <p>
                <strong>Warning</strong> You are about to update{" "}
                {selectedContacts.length} contacts. You cannot undo this action.
              </p>
            </div>
          }
        />
      </div>
    );
  }
}

export default Contacts;
