import React from "react";
import styled from "styled-components";
import Search from "../../elements/Search";

import { Button } from "../../styled/Buttons";
import { TitleWrapper, TitleLeft, TitleRight } from "../../styled/Layout";
import { H1 } from "./styles";

const CreateButton = styled.a`
  grid-column: 1/3;
`;

const DeleteMany = styled.div`
  display: flex;
  align-items: center;
  .material-icons {
    cursor: pointer;
  }
`;

const WorkflowTitleWrapper = styled.div`
  text-transform: uppercase;
  margin: 0 20px 0 0;
  text-align: left;
`;

const WorkflowCount = styled.p`
  font-style: italic;
  text-align: left;
  margin-top: 0;
  text-transform: none;
`;

const WorkflowsListHeader = props => {
  const { filteredWorkflowsCount, workflowsCount, selectedWorkflowCount } =
    props;

  const [search, setSearch] = React.useState({ value: "", show: false });

  const onSearchChange = value => {
    setSearch(prevState => ({ ...prevState, value: value }));
  };

  const onEnter = event => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  const clearSearch = () => {
    setSearch({ value: "", show: false });
    props.clearSearch();
  };

  const onSearch = () => {
    props.search(search.value);
    setSearch(prevState => ({ ...prevState, show: true }));
  };

  return (
    <TitleWrapper>
      <TitleLeft>
        <WorkflowTitleWrapper>
          <H1>My Workflows</H1>
          {workflowsCount !== filteredWorkflowsCount ? (
            <WorkflowCount>
              Showing {filteredWorkflowsCount} of {workflowsCount} Workflow
              {workflowsCount > 1 ? "s" : ""}
            </WorkflowCount>
          ) : (
            <WorkflowCount>
              {workflowsCount} Workflow{workflowsCount > 1 ? "s" : ""}
            </WorkflowCount>
          )}
        </WorkflowTitleWrapper>
        <Search
          searchValue={search.value}
          onEnter={onEnter}
          onSearch={onSearch}
          onSearchChange={onSearchChange}
          clearSearch={clearSearch}
          showSearch={search.show}
          compact={true}
          placeholder='Search workflows'
          showActive={!search.show && filteredWorkflowsCount !== workflowsCount}
          showClear={search.show && filteredWorkflowsCount !== workflowsCount}
        />
      </TitleLeft>
      <TitleRight>
        <CreateButton onClick={() => props.createWorkflow()}>
          <Button solid>New Workflow</Button>
        </CreateButton>
        {selectedWorkflowCount > 0 && (
          <DeleteMany>
            <span className='material-icons' onClick={props.deleteMultiple}>
              delete
            </span>
            <span>({selectedWorkflowCount})</span>
          </DeleteMany>
        )}
      </TitleRight>
    </TitleWrapper>
  );
};

export default WorkflowsListHeader;
