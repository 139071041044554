import React from "react";
import styled from "styled-components";
import { ChromePicker } from "react-color";
import Floater from "react-floater";
import BpModal from "../../elements/BpModal";
import { colorDark } from "../../../utils/Helpers";
import { Button } from "../../styled/Buttons";
import { handleFloaterClick } from "../../../utils/Helpers";
import { tagCreate } from "../../../utils/ContactAPI";
import { InputBox } from "../../styled/Input";

const TagWrapper = styled.div`
  border-radius: 2px;
  display: flex;
  flex-wrap: wrap;
  padding: 5px 5px 0;
  margin-bottom: 12px;
`;

const TagsList = styled.ul`
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
`;

const Tag = styled.li`
  position: relative;
  border-radius: 2px;
  color: ${props => props.theme.CTextC};
  display: flex;
  font-size: 16px;
  font-weight: 300;
  list-style: none;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 0px 10px;
  border-radius: 1.2rem;
  white-space: nowrap;
  /* overflow: hidden; */
  line-height: 1.8em;
  max-height: 1.8em;
  text-overflow: ellipsis;
  max-width: 13em;
  align-items: center;
  > span {
    cursor: pointer;
  }
`;

const TagColor = styled.span`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 7px;
  background: ${props => (props.color ? props.color : props.theme.HC1)};
`;

const InputWrapper = styled.li`
  background: none;
  flex-grow: 1;
  padding: 0;
  position: relative;
  display: inline-flex;
`;

const TagInput = styled(InputBox)`
  margin-bottom: 10px;
`;

const TagTitle = styled.p`
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
`;

const NewTagClose = styled.span`
  cursor: pointer;
`;

const NewTagWrapper = styled.div`
  position: absolute;
  color: black;
  z-index: 2;
  /* top: 28px; */
  bottom: ${props => (props.showInModal ? "auto" : 0)};
  /* left: 0; */
  right: ${props => (props.popupOffScreen ? "0" : "auto")};
  background-color: white;
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
    0 1px 3px 1px rgba(60, 64, 67, 0.16);
  padding: 15px 15px 0;
`;

const NewTagPopup = styled.div`
  .chrome-picker {
    box-shadow: none !important;
  }
`;

const TagButton = styled.p`
  margin: 0 1em;
  display: flex;
  justify-content: space-between;
  button {
    margin: 1rem auto;
  }
`;

const TagButtons = styled(Button)`
  margin: 1rem 0;
`;

const floaterStyles = {
  wrapper: {
    cursor: "pointer",
    display: "inline-flex",
    flexDirection: "row"
  },
  wrapperPosition: {
    left: -1000,
    position: "relative",
    top: -1000,
    visibility: "hidden"
  },
  floater: {
    zIndex: 1100
  }
};

const floaterTagStyles = {
  ...floaterStyles,
  floater: {
    ...floaterStyles.floater,
    maxWidth: "419px"
  }
};

const FloaterOverlay = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1005;
`;

const InfoIcon = styled.i`
  position: relative;
  top: 2px;
  font-size: 1em;
  color: lightgray;
  &:hover {
    color: darkgray;
  }
`;

const InfoTag = styled.span`
  align-items: center;
  background: ${props => (props.color ? props.color : "#675da8")};
  border-radius: 1.2rem;
  color: ${props =>
    props.color ? (colorDark(props.color) ? "white" : "black") : "inherit"};
  font-weight: 300;
  list-style: none;
  height: 1.7rem;
  padding: 0 1rem;
  font-size: 13px;
  letter-spacing: 1px;
  display: inline-flex;
  margin: 5px 13px 5px 0px;
  position: relative;
  float: left;
  > span {
    cursor: pointer;
  }
`;

const FloaterTagContainer = styled.div`
  /* display: grid;
  grid-template-columns: 1fr 1fr; */
  min-width: 372px;
  p {
    margin-top: 0;
  }
`;

const FloatingTagDesc = styled.p``;

const AddButton = styled.button`
  float: left;
`;

const InputTag = props => {
  const { tags, companyId, userProfile = {}, allowChanges = true } = props;

  const [selectedTag, setSelectedTag] = React.useState(null);
  const [pickerOffset, setPickerOffset] = React.useState({ x: 0, y: 0 });
  const [selectedColor, setSelectedColor] = React.useState("");
  const [showTagNew, setShowTagNew] = React.useState(false);
  const [newTagColor, setNewTagColor] = React.useState("");
  const [showTagEditor, setShowTagEditor] = React.useState(false);
  const [modalDeleteConfirm, setModalDeleteConfirm] = React.useState(false);
  const [modalColorChangeConfirm, setModalColorChangeConfirm] =
    React.useState(false);
  const [deletingTag, setDeletingTag] = React.useState(false);
  const [popupOffScreen, setPopupOffScreen] = React.useState(false);
  const [floaters, setFloaters] = React.useState({
    tags: false,
    addTag: false
  });
  const [newTagName, setNewTagName] = React.useState("");

  const removeTag = async (i, confirmDelete) => {
    if (allowChanges) {
      if (confirmDelete) {
        setDeletingTag(true);
        await props.updateTags("remove", i);
        setDeletingTag(false);
        setModalDeleteConfirm(false);
      } else {
        setModalDeleteConfirm(true);
        setShowTagEditor(false);
      }
    }
  };

  const handleTagSave = async () => {
    if (allowChanges) {
      const value = newTagName.trim();
      let color = "";
      if (newTagColor.hex) {
        color = newTagColor.hex;
      }
      if (value) {
        try {
          const newTag = await tagCreate({
            name: value,
            color,
            companyId
          });
          console.log("new tag", newTag);
          await props.updateTags("add", newTag);
          return true;
        } catch (err) {
          console.log("error updating tags", err.message);
          throw new Error(err);
        }
      }
      return true;
    }
  };

  const toggleNewTag = async (e, save) => {
    e.preventDefault();
    if (allowChanges) {
      const targetClasses = e.nativeEvent.target.classList;
      if (
        targetClasses.contains("new-tag-btn") ||
        targetClasses.contains("color-wrapper") ||
        targetClasses.contains("color-cover") ||
        targetClasses.contains("tag-close") ||
        targetClasses.contains("new-tag-overlay")
      ) {
        if (save) {
          await handleTagSave();
          setNewTagColor("");
          setShowTagNew(false);
          setShowTagEditor(false);
          setSelectedColor("");
        } else if (showTagNew) {
          // close the color picker
          setNewTagColor("");
          setShowTagNew(false);
          setShowTagEditor(false);
          setSelectedColor("");
          setPickerOffset({ x: 0, y: 0 });
        } else {
          setShowTagNew(true);
          setShowTagEditor(false);
        }
      }
    }
  };

  const toggleColor = async (e, tag) => {
    e.preventDefault();
    if (allowChanges) {
      const targetClasses = e.nativeEvent.target.classList;
      if (
        targetClasses.contains("color-btn") ||
        targetClasses.contains("color-wrapper") ||
        targetClasses.contains("color-cover") ||
        targetClasses.contains("tag-close")
      ) {
        if (showTagEditor) {
          // close the color picker
          setSelectedTag(null);
          setShowTagEditor(false);
          setShowTagNew(false);
          setPopupOffScreen(false);
          setSelectedTag("");
        } else {
          setSelectedTag(tag);
          setShowTagEditor(true);
          setShowTagNew(false);
          const { color = "" } = tag;
          setSelectedColor(color);
        }
      }
    }
  };

  const handleColorChange = async (color, event) => {
    if (event) {
      event.preventDefault();
    }
    if (allowChanges) {
      if (color) {
        if (color !== selectedTag.color) {
          await props.handleColorChange(color, selectedTag);
          setShowTagEditor(false);
          setModalColorChangeConfirm(false);
          setPopupOffScreen(false);
        }
      } else {
        setShowTagEditor(false);
        setModalColorChangeConfirm(false);
        setPopupOffScreen(false);
      }
    }
  };

  const handleColorAdd = (color, event) => {
    event.preventDefault();
    if (allowChanges) {
      setNewTagColor(color);
      setSelectedColor(color);
    }
  };

  const handleFloaterClickHere = (e, clickTarget) => {
    e.preventDefault();
    if (allowChanges) {
      const newFloaters = handleFloaterClick(clickTarget, floaters);
      setFloaters(newFloaters);
    }
  };
  const windowWidth = window.innerWidth;

  return (
    <TagWrapper>
      <TagsList>
        {!!tags &&
          tags.map((tag, i) => (
            <Tag key={i} className={i + 1 === tags.length ? "last-tag" : ""}>
              <TagColor
                color={tag.color ? tag.color : ""}
                onClick={e => toggleColor(e, tag)}
              ></TagColor>
              <span onClick={e => toggleColor(e, tag)} className='color-btn'>
                {tag.name}
                {tag.count ? <em> ({tag.count})</em> : null}
              </span>
              {showTagEditor && selectedTag._id === tag._id && (
                <NewTagWrapper
                  onClick={toggleColor}
                  className='new-tag-wrapper'
                  ref={el => {
                    if (!el) return;
                    const elementPosition = el.getBoundingClientRect();
                    if (elementPosition.right > windowWidth) {
                      setPopupOffScreen(true);
                    }
                  }}
                  popupOffScreen={popupOffScreen}
                  showInModal={props.showInModal}
                >
                  <NewTagPopup
                    offsetX={pickerOffset.x}
                    offsetY={pickerOffset.y}
                    className='color-cover'
                  >
                    <TagTitle>
                      <span>Edit Tag</span>
                      <NewTagClose
                        onClick={toggleColor}
                        className='tag-close material-icons'
                      >
                        close
                      </NewTagClose>
                    </TagTitle>
                    <TagInput
                      compact
                      type='text'
                      value={tag.name}
                      readOnly
                      className='new-tag-input'
                    />
                    <ChromePicker
                      onChange={handleColorAdd}
                      color={selectedColor}
                      disableAlpha={true}
                    />
                    <TagButton>
                      <TagButtons
                        onClick={() => removeTag(selectedTag)}
                        small
                        solid
                        className='new-tag-btn'
                      >
                        Remove
                      </TagButtons>
                      <TagButtons
                        onClick={e => handleColorChange(selectedColor.hex, e)}
                        small
                        solid
                        className='new-tag-btn'
                      >
                        Update
                      </TagButtons>
                    </TagButton>
                  </NewTagPopup>
                </NewTagWrapper>
              )}
            </Tag>
          ))}
        <InputWrapper className='input-tag__tags__input'>
          <AddButton
            onClick={toggleNewTag}
            className='new-tag-btn material-icons'
          >
            add_circle
          </AddButton>
          {!!props.showInfo && (
            <Floater
              open={floaters.addTag}
              content={
                <FloaterTagContainer>
                  <div>
                    <InfoTag color='#675da8'>
                      <span>
                        Tag Name <em>(25)</em>
                      </span>
                    </InfoTag>
                    <FloatingTagDesc>
                      : The number in parenthesis is the number of contacts with
                      this tag.
                    </FloatingTagDesc>
                  </div>
                  <div>
                    <InfoTag color='#fbb74c'>
                      <span>
                        Tag Name <em>(new)</em>
                      </span>
                    </InfoTag>
                    <FloatingTagDesc>
                      : Tags you are about to add have <em>(new)</em> after the
                      tag name.
                    </FloatingTagDesc>
                  </div>
                  <div>
                    <InfoTag color='#675da8'>
                      <span>
                        Tag Name <em>(remove)</em>
                      </span>
                    </InfoTag>
                    <FloatingTagDesc>
                      : Tags you are about to remove have <em>(remove)</em>{" "}
                      after the tag name.
                    </FloatingTagDesc>
                  </div>
                  <div>
                    <AddButton
                      onClick={toggleNewTag}
                      className='new-tag-btn material-icons'
                    >
                      add_circle
                    </AddButton>
                    <p>
                      Add a new tag by clicking the + button. It will added to
                      all selected contacts.
                    </p>
                  </div>
                </FloaterTagContainer>
              }
              styles={floaterTagStyles}
              placement='top'
              target='.new-tag-btn'
              className='Floater Wrapper'
            >
              <InfoIcon
                className='material-icons'
                onClick={e => handleFloaterClickHere(e, "addTag")}
                name='floater-toggle'
              >
                info
              </InfoIcon>
              {floaters.addTag && (
                <FloaterOverlay
                  onClick={e => handleFloaterClickHere(e, "addTag")}
                  className='overlay'
                />
              )}
            </Floater>
          )}
          {!!showTagNew && (
            <NewTagWrapper
              onClick={toggleNewTag}
              className='new-tag-wrapper'
              ref={el => {
                if (!el) return;
                const elementPosition = el.getBoundingClientRect();
                if (elementPosition.right > windowWidth) {
                  setPopupOffScreen(true);
                }
              }}
              popupOffScreen={popupOffScreen}
              showInModal={props.showInModal}
            >
              <NewTagPopup
                offsetX={pickerOffset.x}
                offsetY={pickerOffset.y}
                className='color-cover'
              >
                <TagTitle>
                  <span>Add Tag</span>
                  <NewTagClose
                    onClick={toggleNewTag}
                    className='tag-close material-icons'
                  >
                    close
                  </NewTagClose>
                </TagTitle>
                <TagInput
                  type='text'
                  placeholder='new tag'
                  className='new-tag-input'
                  onChange={e => setNewTagName(e.target.value)}
                />
                <ChromePicker
                  onChange={handleColorAdd}
                  color={newTagColor}
                  disableAlpha={true}
                />
                <TagButton>
                  <Button
                    onClick={e => toggleNewTag(e, true)}
                    small
                    solid
                    className='new-tag-btn'
                  >
                    Save Tag
                  </Button>
                </TagButton>
              </NewTagPopup>
            </NewTagWrapper>
          )}
        </InputWrapper>
      </TagsList>
      <BpModal
        open={modalDeleteConfirm}
        title='Confirm Removing Tag'
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        contentLabel='Confirm Removing Tag'
        onCloseMethod={() => setModalDeleteConfirm(false)}
        primaryAction={{
          btnLabel: "Remove Tag",
          action: () => removeTag(selectedTag, true),
          loading: deletingTag
        }}
        secondaryAction={{
          btnLabel: "Cancel",
          action: () => setModalDeleteConfirm(false)
        }}
        body={<p>Are you sure you want to remove this tag?</p>}
      />
      <BpModal
        open={modalColorChangeConfirm}
        title='Confirm Tag Color'
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        contentLabel='Confirm Tag Color'
        onCloseMethod={() => {
          setModalColorChangeConfirm(false);
        }}
        width='25rem'
        primaryAction={{
          btnLabel: "Update Tag",
          action: () => handleColorChange(selectedColor.hex, false, true),
          loading: deletingTag
        }}
        secondaryAction={{
          btnLabel: "Cancel",
          action: () => setModalColorChangeConfirm(false)
        }}
        body={
          <p>
            This will change the color for this tag on all contacts. Are you
            sure you want to change the color?
            <br />
            <label>
              <input
                type='checkbox'
                name='dontShowAgain'
                defaultChecked={
                  userProfile.warningNotifications &&
                  userProfile.warningNotifications.tagChangeWarning
                }
              />
              Don't show this warning again.
            </label>
          </p>
        }
      />
    </TagWrapper>
  );
};

export default InputTag;
