import React, { useContext, useRef, Children } from "react";
import styled from "styled-components";

import { BubbleContext } from "../BubbleWrap/BubbleWrap";
import Icon from "../Icon";

const But = styled.button`
  color: ${props => (props.solid ? props.theme.BGC : props.theme.HC1)};
  background-color: ${props =>
    props.solid ? props.theme.HC1 : props.theme.BGC};
  display: inline-block;
  border-radius: 1.2rem;
  height: ${props => (props.small ? "1.7rem" : "40px")};
  padding: ${props => (props.small ? "0 1rem" : props.icon ? "0" : "0 1.5rem")};
  min-width: ${props => (props.small ? "5rem" : props.icon ? "0" : "7rem")};
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  border: ${props => (props.icon ? "" : `2px solid ${props.theme.HC1}`)};
  font-size: ${props => (props.small ? "13px" : "20px")};
  letter-spacing: 1px;
  &:hover {
    color: ${props => (props.solid ? props.theme.HC1 : props.theme.BGC)};
    background-color: ${props =>
      !props.icon
        ? props.solid
          ? props.theme.BGC
          : props.theme.HC1
        : "transparent"};
  }
  &:focus {
    color: ${props => props.theme.BGC};
    ${props =>
      !props.icon
        ? `background-color: ${props.theme.HC2};
    border: 2px solid ${props.theme.HC2};`
        : ""}
  }
  :disabled {
    color: ${props => (props.solid ? props.theme.BGC : props.theme.TextC)};
    background-color: ${props =>
      props.solid ? props.theme.TextC : props.theme.BGC};
    border: 1.5px solid lightgrey;
    cursor: not-allowed;
  }
  a {
    color: inherit;
  }
`;

type ButtonProps = {
  onClick: () => any;
  small?: boolean;
  solid?: boolean;
  hover?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
  icon?: boolean;
};
const Button = (props: ButtonProps) => {
  const { onClick, hover, children, icon } = props;

  const ref = useRef<HTMLButtonElement>();

  const bubble = useContext(BubbleContext);
  const child = Children.toArray(children);

  const click = async () => {
    await onClick();
    if (!hover && bubble) bubble();
  };

  const removeFocus = async () => {
    const buttonRef = ref.current;
    if (buttonRef) buttonRef.blur();
  };

  return (
    <But
      {...props}
      onClick={click}
      onMouseLeave={removeFocus}
      ref={ref}
      icon={!!icon}
    >
      {child.length === 1 && typeof child[0] === "string"
        ? child[0].toUpperCase()
        : children}
      {icon && <Icon icon={icon} />}
    </But>
  );
};

export default Button;
