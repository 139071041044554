import React, { useContext } from "react";
import { useTable } from "react-table";
import moment from "moment-timezone";
import styled from "styled-components";
import { firstBreakpoint } from "../../styled/PhoneNumberProvision";
import { ThemeContext } from "styled-components";

import Image from "../../elements/Image";
import { cloudinaryFormat } from "../../../utils/MediaAPI";

const TableStyle = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const Head = styled.thead`
  border-bottom: 1px solid #d9d9da;
  display: none;
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: table-header-group;
  }
`;

const TH = styled.th`
  padding: 20px 15px;
  text-transform: uppercase;
  display: none;
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: table-cell;
  }
  &.actions {
    text-align: right;
    padding-right: 25px;
  }
  &.active {
    text-align: center;
  }
`;

const TD = styled.td`
  cursor: pointer;
  padding: 0 15px;
  text-align: left;
  display: ${props => (props.mobileHide ? "none" : "block")};
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: table-cell;
  }
  &.name {
    font-weight: 510;
  }
`;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  @media screen and (min-width: ${firstBreakpoint}px) {
    justify-content: baseline;
  }
  span {
    color: ${props => props.theme.TextC};
    margin: 3px 6px;
  }
`;

const ActionButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  a,
  span {
    color: ${props => props.theme.TextC};
    cursor: pointer;
    &:hover {
      color: ${props => props.theme.HC1};
    }
  }
`;

const BodyRow = styled.tr`
  color: ${props => props.color};
  border-bottom: 1px solid #d9d9da;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: table-row;
  }
  &:last-of-type {
    border-bottom: none;
  }
  td {
    padding-top: 16px;
    padding-bottom: 16px;
  }
`;

const ThumbnailWrapper = styled.div`
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    outline: ${props => (props.active ? "3px solid #64EDC2" : "none")};
  }
`;

const mobileHiddenColumns = ["start-date", "end-date", "status"];

const CampaignHistoryTable = props => {
  const { columns, data } = props;
  const themeContext = useContext(ThemeContext);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <TableStyle {...getTableProps()}>
      <Head>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <TH
                {...column.getHeaderProps()}
                className={column.id ? column.id : ""}
              >
                {column.render("Header")}
              </TH>
            ))}
          </tr>
        ))}
      </Head>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <BodyRow
              {...row.getRowProps()}
              color={
                row.values.active === "draft"
                  ? themeContext.TextC
                  : themeContext.HC1
              }
            >
              {row.cells.map(cell => (
                <TD
                  {...cell.getCellProps()}
                  onClick={() => {
                    if (!(cell.column.id === "actions")) {
                      props.history.push(
                        `/campaign-history/${row.original.id}`
                      );
                    }
                  }}
                  className={cell.column.id ? cell.column.id : ""}
                  mobileHide={
                    mobileHiddenColumns.includes(cell.column.id) ? true : false
                  }
                >
                  {/* {console.log(cell)} */}
                  {cell.render("Cell")}
                </TD>
              ))}
            </BodyRow>
          );
        })}
      </tbody>
    </TableStyle>
  );
};

const CampaignHistoryList = props => {
  const { userProfile, campaignList } = props;

  const headers = [
    {
      Header: "",
      accessor: "thumbnail",
      id: "thumbnail",
      Cell: cellInfo => (
        <ThumbnailWrapper active={cellInfo.cell.value.isActive}>
          <Image
            src={cloudinaryFormat({
              mode: "fill",
              type: cellInfo.cell.value.mediaType.toLowerCase(),
              height: 40,
              width: 40,
              filename: cellInfo.cell.value.name,
              format: cellInfo.cell.value.format
            })}
            fallbackSrc='https://res.cloudinary.com/equipter/image/upload/c_scale,w_40/v1612279822/Boostpoint_Images/pngtree-vector-picture-icon-png-image_695350.jpg'
            alt={`${
              cellInfo.cell.value.isActive ? "Active" : "Inactive"
            } Campaign Thumbnail`}
            title={`${
              cellInfo.cell.value.isActive ? "Active" : "Inactive"
            } Campaign`}
          />
        </ThumbnailWrapper>
      )
    },
    {
      Header: "Name",
      accessor: "name",
      id: "name"
    },
    { Header: "Start Date", accessor: "startDate", id: "start-date" },
    { Header: "End Date", accessor: "endDate", id: "end-date" },
    { Header: "Status", accessor: "status", id: "status" },
    {
      Header: "Actions",
      accessor: "actions",
      id: "actions",
      // eslint-disable-next-line react/display-name
      Cell: cellInfo => (
        <ActionWrapper>
          <ActionButton>
            <span
              className='material-icons'
              onClick={() => props.duplicateCampaign(cellInfo.row.original.id)}
              title='Duplicate Campaign'
            >
              content_copy
            </span>
          </ActionButton>
        </ActionWrapper>
      )
    }
  ];
  const tableData = campaignList.map(campaign => {
    const { timezone } = userProfile;
    const { media, status } = campaign;
    const isActive = status === "ACTIVE" ? true : false;
    let startFormatted = "";
    let endFormatted = "";
    if (campaign.startDate) {
      const startMoment = moment(campaign.startDate).tz(timezone);
      startFormatted = startMoment.format("MMM DD, YYYY");
    }
    if (campaign.endDate) {
      const endMoment = moment(campaign.endDate).tz(timezone);
      endFormatted = endMoment.format("MMM DD, YYYY");
    }
    let imageFormat = "image";
    let extension = "jpg";

    if (media.length > 0) {
      if (media[0].mediaType) {
        imageFormat = media[0].mediaType.toLowerCase();
      } else if (media[0].type) {
        imageFormat = media[0].type.toLowerCase();
      }
      if (imageFormat === "video") {
        extension = "png";
      } else if (media[0].format) {
        extension = media[0].format;
      }
      //console.log('media', media);
    }

    return {
      id: campaign._id,
      thumbnail: {
        mediaType: imageFormat || media[0]?.mediaType,
        name: media[0]?.name || media[0]?.filename,
        format: extension || media[0]?.format,
        isActive
      },
      name: campaign.name,
      startDate: startFormatted,
      endDate: endFormatted,
      status: campaign.status,
      actions: ""
    };
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = React.useMemo(() => tableData, [campaignList]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = React.useMemo(() => headers, [campaignList]);

  return (
    <CampaignHistoryTable
      columns={columns}
      data={data}
      history={props.history}
    />
  );
};
export default CampaignHistoryList;
