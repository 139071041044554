import React from "react";
import Floater from "react-floater";
import { Elements, StripeProvider } from "react-stripe-elements";
import { firestore } from "../../../firebase/firebase";
import StripeAccount from "./StripeAccount";
import FbConnect from "./fbConnect/FbConnect";
// import GoogleConnect from './GoogleConnect'
import styled from "styled-components";
import {
  emailValidator,
  errorHandler,
  handleFloaterClick,
  industries,
  timezones,
  urlValidator
} from "../../../utils/Helpers";
import BpModal from "../../elements/BpModal";
import { FloaterFlexRow } from "../../styled/Floater";
import CreditDisplay from "./CreditDisplay";
import InteractiveDropdown from "../../elements/InteractiveDropdown";

// Styled Components
// import H3 from '../../styled/H3';
// import Card from '../../styled/Card';
// import RelativeCard from '../../styled/RelativeCard';
// import CardTitle from '../../styled/TitleCard';
import { getStripeInfo, stripeUpdateCustomer } from "../../../utils/StripeAPI";
import TimeDisplay from "./TimeDisplay";
import { Title } from "../../styled/Headers";
import {
  Dropdown,
  InputBox,
  InputBoxWrapper,
  InputContainer,
  InputFlexGroup,
  InputLabel
} from "../../styled/Input";
import { Card, CardTitle } from "../../styled/Layout";
import LeadEmailManage from "./LeadEmailManage";

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: ${props => (props.alignment ? props.alignment : "left")};
  width: ${props => (props.customWidth ? props.customWidth : "18rem")};
  margin: 0 auto 2.5em;
  max-width: 90%;
  &:hover i {
    color: #cbcbcb;
  }
`;
const DropdownLabel = styled.label`
  font-weight: 400;
  color: ${props => props.theme.HC2};
  margin-bottom: 0.5em;
`;

const InputGroup = styled.div`
  span.label {
    display: block;
    font-weight: 600;
    color: ${props => props.theme.HC3};
    letter-spacing: 2.5px;
    font-size: 17px;
  }

  p {
    /* font-size: 16px !important; */
    letter-spacing: 1px;
    font-style: italic;
  }
`;

const InfoIcon = styled.i`
  position: relative;
  top: 2px;
  font-size: 1em;
  color: lightgray;
  &:hover {
    color: darkgray;
  }
`;

const floaterStyles = {
  wrapper: {
    cursor: "pointer",
    display: "inline-flex",
    flexDirection: "row"
  },
  wrapperPosition: {
    left: -1000,
    position: "relative",
    top: -1000,
    visibility: "hidden"
  }
};
const FloaterOverlay = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1005;
`;

const IntegrationList = styled.div`
  max-width: 400px;
  margin: 0 auto;
`;

const IntegrationLink = styled.a`
  display: block;
  margin: 12px;
  & > span {
    display: grid;
    grid-template-columns: 5fr 3fr;
  }
`;

const IntegrationLabel = styled.span`
  text-align: right;
`;

const IntegrationLogo = styled.img`
  margin-right: 12px;
`;

const SettingsIcon = styled.span`
  position: relative;
  top: -2px;
  margin-left: 4px;
  text-align: left;
`;

const TimeWrapper = styled(InputBoxWrapper)`
  display: flex;
  align-items: center;
`;

class MemberSettings extends React.Component {
  state = {
    userEditClicked: false,
    companyEditClicked: false,
    loginEmailClicked: false,
    menuOpen: false,
    industryList: [],
    tourRun: false,
    welcomeOpen: false,
    floaters: {
      loginEmail: false,
      contactEmail: false,
      leadEmails: false,
      industryType: false,
      timezone: false
    },
    errMessages: {},
    userSettingsValid: true,
    cleaveOutlineStyle: "none",
    companySnapshot: null,
    companyRoles: {},
    companyData: { name: "", industry: "" },
    integrationData: {},
    integrationLogos: {}
  };

  async componentDidMount() {
    const { userProfile, userCompany } = this.props;
    try {
      this.props.checkPermissions();
    } catch (err) {
      console.log("error checking permissions", err);
    }
    try {
      if (userCompany && userProfile.companyID) {
        const companyRolesRef = await firestore
          .collection(`/companies/${userProfile.companyID}/roles`)
          .get();
        if (!companyRolesRef.empty) {
          let companyRoles = {};
          companyRolesRef.forEach(role => {
            companyRoles = {
              ...companyRoles,
              [role.id]: role.data()
            };
          });
          this.setState({ companyRoles });
        }
      }
      if (userProfile.integrationList) {
        const integrations = userProfile.integrationList.map(
          integration => integration.integrationType
        );
        const integrationWait = [];
        integrations.forEach(integrationType => {
          integrationWait.push(
            firestore.doc(`integrations/${integrationType}`).get()
          );
        });
        let integrationLogos = {};
        let integrationData = {};
        Promise.all(integrationWait).then(result => {
          result.forEach(doc => {
            if (doc.exists) {
              const integrationDoc = doc.data();
              integrationData = {
                ...integrationData,
                [integrationDoc.name]: integrationDoc
              };
              integrationLogos = {
                ...integrationLogos,
                [integrationDoc.name]: integrationDoc.icon
              };
            }
          });
          this.setState({
            integrationData,
            integrationLogos
          });
        });
      }
    } catch (err) {
      console.log("error getting the company roles", err);
    }
  }

  componentWillUnmount = () => {
    if (typeof this.state.companySnapshot === "function") {
      this.state.companySnapshot();
    }
    this.setState({ companySnapshot: null });
  };

  validateEmail = email =>
    new Promise((resolve, reject) => {
      let emailValue = email;
      const { errMessages } = this.state;
      let { userSettingsValid = true } = this.state;
      let contactEmailValid;
      if (!emailValue) {
        emailValue = this.contactEmail.value.trim();
      }
      if (emailValue) {
        contactEmailValid = emailValidator(emailValue);
        if (contactEmailValid !== true) {
          errMessages.contactEmail = contactEmailValid;
        } else {
          errMessages.contactEmail = null;
        }
      } else {
        errMessages.contactEmail = null;
        userSettingsValid = true;
      }
      if (errMessages.contactEmail) {
        userSettingsValid = false;
      } else {
        userSettingsValid = true;
      }
      this.setState({ errMessages, userSettingsValid });
      if (!contactEmailValid) {
        resolve(false);
      } else {
        resolve(true);
      }
    });

  handleFieldSave = async input => {
    const { alert, userProfile, userCompany } = this.props;
    const { uid, stripeID, firstName, lastName, email, phoneNumber } =
      userProfile;
    const { hubspotUserId } = userProfile;
    let hubspotCompanyID;
    const targetName = input.target.name;
    const defaultValue = input.target.defaultValue;
    let value = input.target.value;
    let nameReadable;
    try {
      if (this[targetName]) {
        nameReadable = this[targetName].dataset.namereadable;
      }
      const required = input.target.required;
      if (input.target.type === "text") {
        value = value.trim();
      }
      // check if it is changed
      if (required && !value) {
        if (nameReadable) {
          alert({
            type: "error",
            msg: `${nameReadable} is required`
          });
        } else {
          alert({
            type: "error",
            msg: "This field is required"
          });
        }
        this[targetName].value = defaultValue;
        return false;
      }
      if (input.target.type === "email") {
        const emailValid = await this.validateEmail(value);
        if (!emailValid && required) {
          console.log("email is not valid");
          alert({
            type: "error",
            msg: `${nameReadable} is required`
          });
          this[targetName].value = defaultValue;
          return false;
        }
      }
    } catch (err) {
      console.log("error saving field", err);
      alert({
        type: "error",
        msg: "Something went wrong saving the field. Please try again. If you continue to get this message, contact Boostpoint at help@boostpoint.com"
      });
      return false;
    }
    if (userProfile[targetName] !== value) {
      //validate fields - phone, email
      try {
        console.log(`${targetName} updated to ${value}`);
        this.props.updateUserDoc({ [targetName]: value });
        let alertMsg = "Field successfully changed";
        if (nameReadable) {
          alertMsg = `Your ${nameReadable} was successfully changed to ${value}`;
        }
        alert({
          type: "success",
          msg: alertMsg
        });
      } catch (err) {
        console.log("error saving field", err);
        alert({
          type: "error",
          msg: "Something went wrong saving the field. Please try again. If you continue to get this message, contact Boostpoint at help@boostpoint.com"
        });
        return false;
      }

      const stripeFields = ["firstName", "lastName", "email", "companyName"];
      if (stripeFields.includes(targetName)) {
        console.log("updating stripe");
        const { stripeId } = await getStripeInfo();
        const stripeData = {
          stripeId,
          metadata: {
            first_name: firstName,
            last_name: lastName
          },
          email: email
        };
        if (targetName === "firstName") {
          stripeData.metadata.first_name = value;
          stripeData.name = `${value.trim()} ${lastName}`;
        }
        if (targetName === "lastName") {
          stripeData.metadata.last_name = value;
          stripeData.name = `${firstName} ${value.trim()}`;
        }
        if (targetName === "email") {
          stripeData.email = value;
        }
        if (targetName === "companyName") {
          stripeData.description = value;
        }
        try {
          const response = await stripeUpdateCustomer(stripeData);
        } catch (err) {
          const { status, message } = errorHandler(err);
          console.log("err", `${status}: ${message}`);
        }
      }
      if (targetName === "email") {
        console.log("email changed");
        //email changed
        // check if new email has an existing hubspot account
        // try {
        //   const hsuser = await axios.post(
        //     `${BP_NODE_SERVER}/hubspotGetContactByEmail`,
        //     {
        //       email: value
        //     }
        //   );
        //   hsUserId = hsuser.data.vid;
        //   if (
        //     hsuser.data["properties"] &&
        //     hsuser.data["properties"]["associatedcompanyid"] &&
        //     hsuser.data["properties"]["associatedcompanyid"]["value"]
        //   ) {
        //     hubspotCompanyID =
        //       hsuser.data["properties"]["associatedcompanyid"]["value"];
        //   }
        //   if (hsUserId) {
        //     // yes, new email has an already existing hubspot contact
        //     console.log("new email already has a hubspot contact", hsuser.data);
        //     const userRef = firestore.collection("users").doc(uid);
        //     userRef.update({
        //       hubspotUserId: hsUserId
        //     });
        //     hubspotUserId = hsUserId;
        //   }
        // } catch (err) {
        //   console.log("error updating hubspot", err);
        // }
      }
      if (hubspotUserId) {
        let properties = [];
        if (targetName === "firstName") {
          properties = [...properties, { property: "firstname", value }];
        }
        if (targetName === "lastName") {
          properties = [...properties, { property: "lastname", value }];
        }
        if (targetName === "phoneNumber") {
          properties = [...properties, { property: "phone", value }];
        }
        if (properties && properties.length > 0) {
          console.log("properties", properties);
          // try {
          //   await axios.post(
          //     `${BP_NODE_SERVER}/hubspotSetContactPropertiesMultiple`,
          //     {
          //       properties,
          //       hsUserId: hubspotUserId
          //     }
          //   );
          // } catch (err) {
          //   console.log(err);
          // }
        }
      } else {
        // try {
        //   console.log("attempting to get contact id");
        //   const hsuser = await axios.post(
        //     `${BP_NODE_SERVER}/hubspotGetContactByEmail`,
        //     {
        //       email: targetName === "email" ? value : email
        //     }
        //   );
        //   const newHsUserId = hsuser.data.vid;
        //   if (
        //     hsuser.data["properties"] &&
        //     hsuser.data["properties"]["associatedcompanyid"] &&
        //     hsuser.data["properties"]["associatedcompanyid"]["value"]
        //   ) {
        //     hubspotCompanyID =
        //       hsuser.data["properties"]["associatedcompanyid"]["value"];
        //   }
        //   if (newHsUserId) {
        //     // yes, new email has an already existing hubspot contact
        //     console.log("email already has a hubspot contact", hsuser.data);
        //     const userRef = firestore.collection("users").doc(uid);
        //     userRef.update({
        //       hubspotUserId: newHsUserId
        //     });
        //     hubspotUserId = newHsUserId;
        //   } else {
        //     console.log("creating new contact");
        //     let hubspotContactProperties = [
        //       {
        //         property: "email",
        //         value: targetName === "email" ? value : email
        //       }
        //     ];
        //     if (firstName) {
        //       hubspotContactProperties = [
        //         ...hubspotContactProperties,
        //         { property: "firstname", value: firstName }
        //       ];
        //     }
        //     if (lastName) {
        //       hubspotContactProperties = [
        //         ...hubspotContactProperties,
        //         { property: "lastname", value: lastName }
        //       ];
        //     }
        //     if (phoneNumber) {
        //       hubspotContactProperties = [
        //         ...hubspotContactProperties,
        //         { property: "phone", value: phoneNumber }
        //       ];
        //     }
        //     try {
        //       const newHsuser = await axios.post(
        //         `${BP_NODE_SERVER}/hubspotCreateContact`,
        //         {
        //           properties: hubspotContactProperties
        //         }
        //       );
        //       console.log("new user: ", newHsuser.data);
        //       if (newHsuser.data.vid) {
        //         try {
        //           const userRef = firestore.collection("users").doc(uid);
        //           userRef.update({
        //             hubspotUserId: newHsuser.data.vid
        //           });
        //           if (
        //             newHsuser.data["properties"] &&
        //             newHsuser.data["properties"]["associatedcompanyid"] &&
        //             newHsuser.data["properties"]["associatedcompanyid"]["value"]
        //           ) {
        //             hubspotCompanyID =
        //               newHsuser.data["properties"]["associatedcompanyid"][
        //                 "value"
        //               ];
        //           }
        //         } catch (err) {
        //           console.log("error setting hubspot user id", err);
        //         }
        //       }
        //     } catch (err) {
        //       console.log("error setting new user", err);
        //     }
        //   }
        // } catch (err) {
        //   console.log("error updating hubspot", err.response);
        // }
      }
      const hubspotPropertyIds = {
        companyName: "name",
        companyAddress: "address",
        companyCity: "city",
        companyState: "state",
        companyZip: "zip",
        companyPhoneNumber: "phone",
        industryType: "industry",
        companyWebsite: "website"
      };
      if (hubspotPropertyIds[targetName] && hubspotUserId) {
        if (!hubspotCompanyID) {
          // try {
          //   const hubspotUser = await axios.post(
          //     `${BP_NODE_SERVER}/hubspotGetContactById`,
          //     {
          //       hsUserId: hubspotUserId
          //     }
          //   );
          //   console.log("hubspot user", hubspotUser);
          //   console.log(
          //     "hubspot property name",
          //     hubspotPropertyIds[targetName]
          //   );
          //   if (
          //     hubspotUser.data["properties"] &&
          //     hubspotUser.data["properties"]["associatedcompanyid"] &&
          //     hubspotUser.data["properties"]["associatedcompanyid"]["value"]
          //   ) {
          //     console.log(
          //       "company id",
          //       hubspotUser.data["properties"]["associatedcompanyid"]["value"]
          //     );
          //     hubspotCompanyID =
          //       hubspotUser.data["properties"]["associatedcompanyid"]["value"];
          //   }
          // } catch (err) {
          //   console.log("error getting company id", err);
          // }
        }
        if (hubspotCompanyID) {
          // try {
          //   await axios.post(
          //     `${BP_NODE_SERVER}/hubspotSetCompanyPropertiesMultiple`,
          //     {
          //       properties: [
          //         {
          //           name: hubspotPropertyIds[targetName],
          //           value
          //         }
          //       ],
          //       companyId: hubspotCompanyID
          //     }
          //   );
          // } catch (err) {
          //   console.log("setting company properties", err);
          // }
        } else {
          // try {
          //   console.log("add company");
          //   const newCompanyID = await axios.post(
          //     `${BP_NODE_SERVER}/hubspotCreateCompany`,
          //     {
          //       properties: [
          //         {
          //           name: hubspotPropertyIds[targetName],
          //           value
          //         }
          //       ]
          //     }
          //   );
          //   console.log("new companyID", newCompanyID.data.companyId);
          //   await axios.post(`${BP_NODE_SERVER}/hubspotSetCompanyAssociation`, {
          //     contactId: hubspotUserId,
          //     companyId: newCompanyID.data.companyId
          //   });
          // } catch (err) {
          //   if (err.message) {
          //     console.log("error getting hubspot user", err.message);
          //   } else {
          //     console.log("error getting hubspot user", err);
          //   }
          // }
        }
      }
    }
  };

  handleCompanyFieldSave = async input => {
    const { alert, userProfile, userCompany } = this.props;
    const { uid, stripeID } = userProfile;
    const { hubspotUserId } = userProfile;
    let hubspotCompanyID;
    const targetName = input.target.name;
    let fieldName = targetName;
    const defaultValue = input.target.defaultValue;
    let value = input.target.value;
    let nameReadable;
    const fieldMap = {
      companyName: "name",
      companyPhoneNumber: "phone",
      companyAddress: "address",
      companyCity: "city",
      companyZip: "zip",
      companyWebsite: "website",
      industryType: "industry",
      companyState: "state"
    };
    if (fieldMap[targetName]) {
      fieldName = fieldMap[targetName];
    }
    try {
      if (this[targetName]) {
        nameReadable = this[targetName].dataset.namereadable;
      }
      const required = input.target.required;
      if (input.target.type === "text") {
        value = value.trim();
      }
      // check if it is changed
      if (required && !value) {
        if (nameReadable) {
          alert({
            type: "error",
            msg: `${nameReadable} is required`
          });
        } else {
          alert({
            type: "error",
            msg: "This field is required"
          });
        }
        this[targetName].value = defaultValue;
        return false;
      }
      if (input.target.type === "email") {
        const emailValid = await this.validateEmail(value);
        if (!emailValid && required) {
          console.log("email is not valid");
          alert({
            type: "error",
            msg: `${nameReadable} is required`
          });
          this[targetName].value = defaultValue;
          return false;
        }
      }
      if (targetName === "companyWebsite") {
        console.log("checking website");
        const urlValid = urlValidator(value, false);
        if (urlValid !== true) {
          console.log("website is not valid");
          alert({
            type: "error",
            msg: "Please enter a valid website"
          });
          this[targetName].value = defaultValue;
          return false;
        }
      }
    } catch (err) {
      console.log("error saving field", err);
      alert({
        type: "error",
        msg: "Something went wrong saving the field. Please try again. If you continue to get this message, contact Boostpoint at help@boostpoint.com"
      });
      return false;
    }
    if (userCompany[fieldName] !== value) {
      try {
        console.log(`${nameReadable} updated to ${value}`);
        this.props.updateCompanyDoc({ [fieldName]: value });
        // await axios.post(`${BP_NODE_SERVER}/firestoreSetDoc`, {
        //   docPath: `companies/${userProfile.companyID}`,
        //   docData: {
        //     [fieldName]: value
        //   },
        //   mergeData: true
        // });
        let alertMsg = "Field successfully changed";
        if (nameReadable) {
          alertMsg = `Your ${nameReadable} was successfully changed to ${value}`;
        }
        alert({
          type: "success",
          msg: alertMsg
        });
        if (targetName === "companyName") {
          const { stripeId } = await getStripeInfo();
          try {
            const response = await stripeUpdateCustomer({
              stripeId,
              description: value
            });
          } catch (err) {
            const { status, message } = errorHandler(err);
            console.log("err", `${status}: ${message}`);
          }
        }
        const hubspotPropertyIds = {
          companyName: "name",
          companyAddress: "address",
          companyCity: "city",
          companyState: "state",
          companyZip: "zip",
          companyPhoneNumber: "phone",
          industryType: "industry",
          companyWebsite: "website"
        };
        if (hubspotPropertyIds[targetName] && hubspotUserId) {
          if (!hubspotCompanyID) {
            // try {
            //   const hubspotUser = await axios.post(
            //     `${BP_NODE_SERVER}/hubspotGetContactById`,
            //     {
            //       hsUserId: hubspotUserId
            //     }
            //   );
            //   console.log("hubspot user", hubspotUser);
            //   console.log(
            //     "hubspot property name",
            //     hubspotPropertyIds[targetName]
            //   );
            //   if (
            //     hubspotUser.data["properties"] &&
            //     hubspotUser.data["properties"]["associatedcompanyid"] &&
            //     hubspotUser.data["properties"]["associatedcompanyid"]["value"]
            //   ) {
            //     console.log(
            //       "company id",
            //       hubspotUser.data["properties"]["associatedcompanyid"]["value"]
            //     );
            //     hubspotCompanyID =
            //       hubspotUser.data["properties"]["associatedcompanyid"][
            //         "value"
            //       ];
            //   }
            // } catch (err) {
            //   console.log("error getting company id", err);
            // }
          }
          if (hubspotCompanyID) {
            // try {
            //   await axios.post(
            //     `${BP_NODE_SERVER}/hubspotSetCompanyPropertiesMultiple`,
            //     {
            //       properties: [
            //         {
            //           name: hubspotPropertyIds[targetName],
            //           value
            //         }
            //       ],
            //       companyId: hubspotCompanyID
            //     }
            //   );
            // } catch (err) {
            //   console.log("setting company properties", err);
            // }
          } else {
            // try {
            //   console.log("add company");
            //   const newCompanyID = await axios.post(
            //     `${BP_NODE_SERVER}/hubspotCreateCompany`,
            //     {
            //       properties: [
            //         {
            //           name: hubspotPropertyIds[targetName],
            //           value
            //         }
            //       ]
            //     }
            //   );
            //   console.log("new companyID", newCompanyID.data.companyId);
            //   await axios.post(
            //     `${BP_NODE_SERVER}/hubspotSetCompanyAssociation`,
            //     {
            //       contactId: hubspotUserId,
            //       companyId: newCompanyID.data.companyId
            //     }
            //   );
            // } catch (err) {
            //   if (err.message) {
            //     console.log("error getting hubspot user", err.message);
            //   } else {
            //     console.log("error getting hubspot user", err);
            //   }
            // }
          }
        }
      } catch (err) {
        console.log("error saving field", err);
        alert({
          type: "error",
          msg: "Something went wrong saving the field. Please try again. If you continue to get this message, contact Boostpoint at help@boostpoint.com"
        });
        return false;
      }
    }
  };

  handleCloseWelcome = () => {
    this.setState({ welcomeOpen: false });
  };

  handleFloaterClick = (e, clickTarget) => {
    e.preventDefault();
    const { floaters } = this.state;
    const newFloaters = handleFloaterClick(clickTarget, floaters);
    this.setState({ floaters: newFloaters });
  };

  updateLeadEmailValues = newArray => {
    this.props.updateCompanyDoc({ leadEmails: newArray });
  };

  updateContactEmailValues = newArray => {
    this.props.updateCompanyDoc({ contactEmails: newArray });
  };

  render() {
    const {
      userProfile = {},
      userCompany,
      businessPage = {},
      platformToken = {},
      adCredits = {}
    } = this.props;
    const { errMessages, floaters, integrationData, integrationLogos } =
      this.state;
    const { timezone } = userProfile;
    return (
      <div id='memberWrapper'>
        <Card>
          <CardTitle>
            <Title>User Information</Title>
          </CardTitle>
          <InputGroup id='memberName'>
            {/* <span className="label">User Name</span> */}
            <InputFlexGroup>
              <InputContainer>
                <InputLabel>First Name</InputLabel>
                <InputBoxWrapper>
                  <InputBox
                    ref={input => {
                      this.firstName = input;
                    }}
                    type='text'
                    name='firstName'
                    required
                    data-namereadable='first name'
                    defaultValue={userProfile.firstName}
                    onBlur={input => this.handleFieldSave(input)}
                  />
                </InputBoxWrapper>
              </InputContainer>

              <InputContainer>
                <InputLabel>Last Name</InputLabel>
                <InputBoxWrapper>
                  <InputBox
                    ref={input => {
                      this.lastName = input;
                    }}
                    type='text'
                    name='lastName'
                    data-namereadable='last name'
                    required
                    defaultValue={userProfile.lastName}
                    onBlur={input => this.handleFieldSave(input)}
                  />
                </InputBoxWrapper>
              </InputContainer>
            </InputFlexGroup>
            <InputFlexGroup>
              <InputContainer>
                <FloaterFlexRow>
                  <InputLabel>Login Email</InputLabel>
                  <Floater
                    open={floaters.loginEmail}
                    content='The email address you use to log in. Your login email cannot be changed here.'
                    styles={floaterStyles}
                    event='hover'
                    placement='right'
                  >
                    <InfoIcon
                      className='material-icons'
                      onClick={e => this.handleFloaterClick(e, "loginEmail")}
                      name='floater-toggle'
                    >
                      info
                    </InfoIcon>
                    {floaters.loginEmail && (
                      <FloaterOverlay
                        onClick={e => this.handleFloaterClick(e, "loginEmail")}
                        className='overlay'
                      />
                    )}
                  </Floater>
                </FloaterFlexRow>
                <InputBoxWrapper
                  onClick={() => this.setState({ loginEmailClicked: true })}
                >
                  <InputBox type='email' readOnly value={userProfile.email} />
                </InputBoxWrapper>
              </InputContainer>
              <InputContainer>
                <FloaterFlexRow>
                  <InputLabel>Contact Email</InputLabel>
                  <Floater
                    open={floaters.contactEmail}
                    content='The email address you will receive billing and informational communications to.'
                    styles={floaterStyles}
                    event='hover'
                    placement='right'
                  >
                    <InfoIcon
                      className='material-icons'
                      onClick={e => this.handleFloaterClick(e, "contactEmail")}
                      name='floater-toggle'
                    >
                      info
                    </InfoIcon>
                    {floaters.contactEmail && (
                      <FloaterOverlay
                        onClick={e =>
                          this.handleFloaterClick(e, "contactEmail")
                        }
                        className='overlay'
                      />
                    )}
                  </Floater>
                </FloaterFlexRow>
                <InteractiveDropdown
                  dropdownItems={
                    userCompany.contactEmails || [userProfile.email]
                  }
                  alert={this.props.alert}
                  type='Contact Email'
                  updateValues={this.updateContactEmailValues}
                />
              </InputContainer>
            </InputFlexGroup>
            <InputFlexGroup>
              <DropdownWrapper>
                <FloaterFlexRow>
                  <DropdownLabel>Lead Email(s)</DropdownLabel>
                  <Floater
                    open={floaters.leadEmails}
                    content='The email address(es) you receive Facebook and Instagram generated leads. This applies to lead generation campaigns only.'
                    styles={floaterStyles}
                    event='hover'
                    placement='right'
                  >
                    <InfoIcon
                      className='material-icons'
                      onClick={e => this.handleFloaterClick(e, "leadEmails")}
                      name='floater-toggle'
                    >
                      info
                    </InfoIcon>
                    {floaters.leadEmails && (
                      <FloaterOverlay
                        onClick={e => this.handleFloaterClick(e, "leadEmails")}
                        className='overlay'
                      />
                    )}
                  </Floater>
                </FloaterFlexRow>
                <LeadEmailManage
                  companyId={userCompany._id}
                  email={userProfile.email}
                  alert={this.props.alert}
                />
              </DropdownWrapper>
              <InputContainer>
                <InputLabel>Phone Number</InputLabel>
                <InputBoxWrapper>
                  <InputBox
                    ref={input => {
                      this.phoneNumber = input;
                    }}
                    options={{ phone: true, phoneRegionCode: "US" }}
                    type='tel'
                    name='phoneNumber'
                    data-namereadable='phone number'
                    defaultValue={userProfile.phoneNumber}
                    onBlur={input => this.handleFieldSave(input)}
                  />
                </InputBoxWrapper>
              </InputContainer>
            </InputFlexGroup>
            <InputFlexGroup>
              <InputContainer>
                <FloaterFlexRow>
                  <InputLabel>Preferred Timezone</InputLabel>
                  <Floater
                    open={floaters.timezone}
                    content='Changes the timezone of dates and times when they are displayed in the app.'
                    styles={floaterStyles}
                    event='hover'
                    placement='right'
                  >
                    <InfoIcon
                      className='material-icons'
                      onClick={e => this.handleFloaterClick(e, "timezone")}
                      name='floater-toggle'
                    >
                      info
                    </InfoIcon>
                    {floaters.timezone && (
                      <FloaterOverlay
                        onClick={e => this.handleFloaterClick(e, "timezone")}
                        className='overlay'
                      />
                    )}
                  </Floater>
                </FloaterFlexRow>
                <InputBoxWrapper>
                  <Dropdown
                    ref={input => {
                      this.timezone = input;
                    }}
                    type='text'
                    name='timezone'
                    data-namereadable='timezone'
                    value={
                      userProfile.timezone
                        ? userProfile.timezone
                        : "America/New_York"
                    }
                    onChange={input => this.handleFieldSave(input)}
                  >
                    {timezones.map((zone, key) => (
                      <option key={zone}>{zone}</option>
                    ))}
                  </Dropdown>
                </InputBoxWrapper>
              </InputContainer>
              <InputContainer>
                <InputLabel>Current Time</InputLabel>
                <TimeWrapper>
                  <TimeDisplay timezone={timezone} />
                </TimeWrapper>
              </InputContainer>
            </InputFlexGroup>
          </InputGroup>
        </Card>
        <Card className='company-info'>
          <CardTitle>
            <Title>Company Information</Title>
          </CardTitle>
          <InputGroup>
            <InputFlexGroup>
              <InputContainer>
                <InputLabel>Company Name</InputLabel>
                <InputBoxWrapper>
                  <InputBox
                    ref={input => {
                      this.companyName = input;
                    }}
                    type='text'
                    name='companyName'
                    data-namereadable='company name'
                    required
                    defaultValue={
                      userProfile.noContactAccess
                        ? userProfile.companyName
                        : userCompany.name
                    }
                    onBlur={input => {
                      if (userProfile.noContactAccess) {
                        this.handleFieldSave(input);
                      } else {
                        this.handleCompanyFieldSave(input);
                      }
                    }}
                  />
                </InputBoxWrapper>
              </InputContainer>

              <InputContainer>
                <InputLabel>Business Phone</InputLabel>
                <InputBoxWrapper>
                  <InputBox
                    ref={input => {
                      this.companyPhoneNumber = input;
                    }}
                    type='tel'
                    name='companyPhoneNumber'
                    data-namereadable='company phone number'
                    defaultValue={
                      userProfile.noContactAccess
                        ? userProfile.companyPhoneNumber
                        : userCompany.phone
                    }
                    onBlur={input => {
                      if (userProfile.noContactAccess) {
                        this.handleFieldSave(input);
                      } else {
                        this.handleCompanyFieldSave(input);
                      }
                    }}
                  />
                </InputBoxWrapper>
              </InputContainer>
            </InputFlexGroup>

            <InputFlexGroup>
              <InputContainer>
                <InputLabel>Street Address</InputLabel>
                <InputBoxWrapper>
                  <InputBox
                    ref={input => {
                      this.companyAddress = input;
                    }}
                    type='text'
                    name='companyAddress'
                    data-namereadable='company address'
                    defaultValue={
                      userProfile.noContactAccess
                        ? userProfile.companyAddress
                        : userCompany.address
                    }
                    onBlur={input => {
                      if (userProfile.noContactAccess) {
                        this.handleFieldSave(input);
                      } else {
                        this.handleCompanyFieldSave(input);
                      }
                    }}
                  />
                </InputBoxWrapper>
              </InputContainer>

              <InputContainer>
                <InputLabel>City</InputLabel>
                <InputBoxWrapper>
                  <InputBox
                    ref={input => {
                      this.companyCity = input;
                    }}
                    type='text'
                    name='companyCity'
                    data-namereadable='company city'
                    defaultValue={userCompany.city}
                    onBlur={this.handleCompanyFieldSave}
                  />
                </InputBoxWrapper>
              </InputContainer>
            </InputFlexGroup>

            <InputFlexGroup>
              <InputContainer>
                <InputLabel>State/Province</InputLabel>
                <InputBoxWrapper>
                  <InputBox
                    ref={input => {
                      this.companyState = input;
                    }}
                    type='text'
                    name='companyState'
                    data-namereadable='company state'
                    defaultValue={userCompany.state}
                    onBlur={this.handleCompanyFieldSave}
                  />
                </InputBoxWrapper>
              </InputContainer>

              <InputContainer>
                <InputLabel>Zip Code/Postal Code</InputLabel>
                <InputBoxWrapper>
                  <InputBox
                    ref={input => {
                      this.companyZip = input;
                    }}
                    type='text'
                    name='companyZip'
                    data-namereadable='company zip'
                    defaultValue={userCompany.zip}
                    onBlur={this.handleCompanyFieldSave}
                  />
                </InputBoxWrapper>
              </InputContainer>
            </InputFlexGroup>
            <InputFlexGroup>
              <InputContainer>
                <InputLabel>Country</InputLabel>
                <InputBoxWrapper>
                  <Dropdown
                    ref={input => {
                      this.country = input;
                    }}
                    type='text'
                    name='country'
                    data-namereadable='Country'
                    value={
                      userCompany && userCompany.country
                        ? userCompany.country
                        : "us"
                    }
                    onChange={this.handleCompanyFieldSave}
                  >
                    <option value='us'>United States</option>
                    <option value='ca'>Canada</option>
                  </Dropdown>
                </InputBoxWrapper>
              </InputContainer>
              <InputContainer>
                <InputLabel className='label'>Website</InputLabel>
                <InputBoxWrapper>
                  <InputBox
                    ref={input => {
                      this.companyWebsite = input;
                    }}
                    type='text'
                    name='companyWebsite'
                    data-namereadable='company website'
                    defaultValue={userCompany.website}
                    onBlur={this.handleCompanyFieldSave}
                  />
                </InputBoxWrapper>
              </InputContainer>
            </InputFlexGroup>
            <InputFlexGroup>
              <InputContainer>
                <FloaterFlexRow>
                  <InputLabel>Industry Type</InputLabel>
                  <Floater
                    open={floaters.industryType}
                    content='The industry type is used to provide you with custom templates and media during ad creation, as well as helps in the placement of your ads on Facebook and Instagram.'
                    styles={floaterStyles}
                    event='hover'
                    placement='right'
                  >
                    <InfoIcon
                      className='material-icons'
                      onClick={e => this.handleFloaterClick(e, "industryType")}
                      name='floater-toggle'
                    >
                      info
                    </InfoIcon>
                    {floaters.industryType && (
                      <FloaterOverlay
                        onClick={e =>
                          this.handleFloaterClick(e, "industryType")
                        }
                        className='overlay'
                      />
                    )}
                  </Floater>
                </FloaterFlexRow>
                <InputBoxWrapper>
                  <Dropdown
                    ref={input => {
                      this.industryType = input;
                    }}
                    type='text'
                    name='industryType'
                    data-namereadable='industry'
                    value={
                      userCompany && userCompany.industry
                        ? userCompany.industry
                        : "Select One"
                    }
                    onChange={this.handleCompanyFieldSave}
                  >
                    <option>Select One</option>
                    {Object.keys(industries).map(i => (
                      <option value={i} key={i}>
                        {industries[i]}
                      </option>
                    ))}
                  </Dropdown>
                </InputBoxWrapper>
              </InputContainer>
            </InputFlexGroup>
          </InputGroup>
        </Card>
        <Card>
          <CardTitle>
            <Title>Subscription Information</Title>
          </CardTitle>
          <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
            <Elements>
              <StripeAccount
                {...this.props}
                registrationPaymentFailed={this.props.registrationPaymentFailed}
              />
            </Elements>
          </StripeProvider>
        </Card>
        <Card className='facebook'>
          <CardTitle>
            <Title>Advertising Accounts</Title>
          </CardTitle>
          <CardTitle>
            <h4>Link the Following Accounts</h4>
          </CardTitle>

          <FbConnect
            companyEditClicked={this.state.companyEditClicked}
            userEditClicked={this.state.userEditClicked}
            businessPage={businessPage}
            platformToken={platformToken}
            getFacebookInfo={this.props.getFacebookInfo}
            alert={this.props.alert}
            userCompany={this.props.userCompany}
            updateUserDoc={this.props.updateUserDoc}
            {...this.props}
          />
          {(!platformToken.token || !businessPage.pageToken) && (
            <p>No account connected. Please connect account.</p>
          )}
        </Card>
        {userProfile.integrationActive && (
          <Card id='integrations'>
            <CardTitle>
              <Title uppercase>Active Integrations</Title>
            </CardTitle>
            <IntegrationList>
              <IntegrationLink href='/integrations/BoostpointCRM'>
                <span>
                  <IntegrationLabel>Boostpoint Contacts</IntegrationLabel>
                  <SettingsIcon className='material-icons'>
                    settings
                  </SettingsIcon>
                </span>
              </IntegrationLink>
              {userProfile.integrationList.map((integration, index) => (
                <IntegrationLink
                  href={`/integrations/${integration.integrationType}`}
                  key={index}
                >
                  {!integration.auth &&
                  integration.integrationType === "EZTexting" ? (
                    <span>
                      <IntegrationLabel>
                        <img
                          src='https://res.cloudinary.com/equipter/image/upload/c_scale,h_28/v1604944184/Boostpoint_Images/boostpoint_Logo_sms.png'
                          alt='Boostpoint sms logo'
                          title='Boostpoint SMS'
                        />
                      </IntegrationLabel>
                      <SettingsIcon className='material-icons'>
                        settings
                      </SettingsIcon>
                    </span>
                  ) : integrationData[integration.integrationType] &&
                    integrationLogos[integration.integrationType] ? (
                    <span>
                      <IntegrationLabel>
                        <IntegrationLogo
                          src={`https://res.cloudinary.com/equipter/image/upload/c_scale,h_28/v1592316065/Boostpoint_Images/${
                            integrationLogos[integration.integrationType]
                          }`}
                          alt={
                            integrationData[integration.integrationType]
                              .nameReadable
                          }
                          title={
                            integrationData[integration.integrationType]
                              .nameReadable
                          }
                        />
                        {
                          integrationData[integration.integrationType]
                            .nameReadable
                        }
                      </IntegrationLabel>
                      <SettingsIcon className='material-icons'>
                        settings
                      </SettingsIcon>
                    </span>
                  ) : null}
                </IntegrationLink>
              ))}
            </IntegrationList>
          </Card>
        )}
        <Card className='credits'>
          <CardTitle>
            <Title>Credits</Title>
          </CardTitle>
          <CreditDisplay adCredits={adCredits} />
        </Card>
        <BpModal
          open={this.state.loginEmailClicked}
          title='Login Email Cannot Be Changed'
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
          contentLabel='Login Email Cannot Be Changed'
          onCloseMethod={() => {
            this.setState({ loginEmailClicked: false });
          }}
          primaryAction={{
            btnLabel: "Back",
            action: () => this.setState({ loginEmailClicked: false })
          }}
          body={
            <p>
              Sorry, you can't do that here. If you really need to change your
              login email, please{" "}
              <a href='mailto:help@boostpoint.com'>
                email us if you need to change your login email.
              </a>
            </p>
          }
        />
      </div>
    );
  }
}

export default MemberSettings;
