import React from "react";
import Floater from "react-floater";
import LineChart from "../../elements/charts/LineChart";
import LoadingBar from "../../elements/LoadingBar";
import ChartHeader from "./ChartHeader";
import {
  ChartTitle,
  ChartTitleChange,
  ChartTitleNumber,
  DashboardModuleTitle,
  floaterStyles,
  Icon,
  Overlay,
  Section
} from "./DashboardStyles";

const LinkClicks = props => {
  const {
    chartsLoading,
    floaters,
    linkClicks = {},
    currentIncDate,
    currentIncClicks,
    prevIncDate,
    prevIncClicks,
    theme
  } = props;

  const [linkClicksChange, setLinkClicksChange] = React.useState(0);
  const [linkClicksFormatted, setLinkClicksFormatted] = React.useState(0);

  React.useEffect(() => {
    const linkClicksDiff = linkClicks.current - linkClicks.prev;
    setLinkClicksChange(
      linkClicks.prev
        ? Math.round((linkClicksDiff / linkClicks.prev) * 100 * 100) / 100
        : 0
    );
    setLinkClicksFormatted(
      linkClicks.current
        ? linkClicks.current.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : 0
    );
  }, [linkClicks]);

  return (
    <Section columns='3/5'>
      <DashboardModuleTitle>
        <ChartTitle>
          <h6>Link Clicks</h6>
          <Floater
            open={floaters.linkClicks}
            content='The number of clicks on links to select destinations or experiences, on or off Facebook-owned properties.'
            styles={floaterStyles}
            event='hover'
            placement='right'
          >
            <Icon
              className='material-icons'
              onClick={e => props.handleFloaterClick(e, "linkClicks")}
              name='floater-toggle'
            >
              info
            </Icon>
            {floaters.linkClicks && (
              <Overlay
                onClick={e => props.handleFloaterClick(e, "linkClicks")}
                className='overlay'
              />
            )}
          </Floater>
        </ChartTitle>
        {chartsLoading ? null : (
          <ChartTitle>
            <ChartTitleNumber className="color-yellow">
              {linkClicksFormatted}
            </ChartTitleNumber>
            {!!linkClicksChange &&
              (linkClicksChange >= 0 ? (
                <ChartTitleChange>
                  (+
                  {linkClicksChange}
                  %)
                </ChartTitleChange>
              ) : (
                <ChartTitleChange style={{ color: "red" }}>
                  ({linkClicksChange}
                  %)
                </ChartTitleChange>
              ))}
          </ChartTitle>
        )}
      </DashboardModuleTitle>
      {chartsLoading ? (
        <LoadingBar />
      ) : (
        <LineChart
          backgroundColor={
            theme.HCSuc +
            Math.round(Math.min(Math.max(0.2 || 1, 0), 1) * 255)
              .toString(16)
              .toUpperCase()
          }
          borderColor={theme.HCSuc}
          labelValue={currentIncDate}
          dataValue={currentIncClicks}
          prevBackgroundColor='rgba(235, 239, 239, 0.2)'
          prevBorderColor='rgba(211, 224, 224, 1)'
          prevLabelValue={prevIncDate}
          prevDataValue={prevIncClicks}
        />
      )}
    </Section>
  );
};

export default LinkClicks;
