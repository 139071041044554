import React from "react";
import styled, { css } from "styled-components";
import Notifications from "./Notifications";
import CompanyProfile from "./CompanyProfile";

import MainMenuItems from "./MainMenuItems";

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  z-index: 10000;
  width: 100%;
  height: ${props => (props.menuOpen ? "100%" : "66px")};
  background: linear-gradient(180deg, #7211b6 0%, #3a0ca2 100%) 0% 0%;
  transition: height 0.2s ease-in-out;
  overflow: hidden;
`;

const MenuIcon = styled.span`
  color: #64edc2;
  font-size: 30px;
  margin: 10px 0;
`;

const MenuContentWrapper = styled.div`
  display: ${props => (props.menuOpen ? "block" : "none")};
  overflow-x: auto;
  padding: 0 10px;
`;

const MenuContent = styled.div`
  padding-top: ${props => (props.menuOpen ? "36px" : "0")};
  transition: height 0.2s ease-in-out;
`;

const BottomMenu = styled.div`
  display: flex;
  position: absolute;
  background-color: ${props => (props.menuOpen ? "#3A0CA2" : "transparent")};
  bottom: 0;
  height: 100%;
  width: 100%;
  padding: 0 25px;
  height: 66px;
  justify-content: space-between;
  align-items: center;
`;

const HelpPopup = styled.div`
  min-width: 36px;
  min-height: 36px;
  border-radius: 50%;
  background-color: #64edc2;
`;

const CenterBPLogo = styled.div`
  img {
    height: 28px;
  }
`;

const ExpandMenuWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .menu-top {
    width: 28px;
    height: 3px;
    background-color: #64edc2;
    margin: 3px 0;
    transform: ${props =>
      props.menuOpen ? "rotate(45deg) translate(6px, 5px)" : "none"};
    transition: all 0.2s ease-out;
  }
  .menu-middle {
    width: 36px;
    height: 3px;
    background-color: #64edc2;
    margin: 3px 0;
    opacity: ${props => (props.menuOpen ? "0" : "1")};
  }
  .menu-bottom {
    width: 28px;
    height: 3px;
    background-color: #64edc2;
    margin: 3px 0;
    transform: ${props =>
      props.menuOpen ? "rotate(-45deg) translate(8px, -7px)" : "none"};
    transition: all 0.2s ease-out;
  }
`;

const SeparatorLine = styled.div`
  width: ${props => (props.menuOpen ? "286px" : "50px")};
  height: 0px;
  border: 1px solid #693fc6;
  border-radius: 1px;
  margin-left: -8px;
  transition: all 0.2s ease-in-out;
`;

const BottomContent = styled.div``;

const ProfileWrapper = styled.div`
  display: ${props => (props.menuOpen ? "flex" : "none")};
  margin: 20px 0 75px 0;
`;

const CompanyLogo = styled.div`
  cursor: pointer;
  margin: ${props =>
    props.menuOpen ? "20px 0 50px -8px" : "30px 0 40px -8px"};
  transition: all 0.1s ease-in-out;
  img {
    width: ${props => (props.menuOpen ? "65px" : "50px")};
    border-radius: 50%;
    transition: all 0.1s ease-in-out;
  }
`;

const ProfileInfo = styled.div`
  margin: 25px 0 0 25px;
  width: ${props => (props.menuOpen ? "100%" : "0px")};
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  opacity: ${props => (props.menuOpen ? "1" : "0")};
  .profile-name {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    justify-content: space-between;
    height: 20px;
    cursor: pointer;
  }
  .profile-name:hover .company-name {
    color: #64edc2;
  }
  .profile-name:hover > * .material-icons {
    color: #64edc2;
  }
  .profile-name:hover .company-name {
    color: #64edc2;
  }
  .company-name {
    color: #ffffff;
    font: normal normal 500 16px Poppins, sans-serif;
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .logout {
    color: #64edc2;
    font: normal normal 500 11px Poppins, sans-serif;
    white-space: nowrap;
    text-decoration: underline;
    cursor: pointer;
  }
  .logout:hover {
    color: #ffffff;
  }
  .credits-wrapper {
    display: flex;
    color: #ffffff;
    white-space: nowrap;
    margin-bottom: 12px;
  }
  .credit-amounts {
    font: normal normal 300 15px Poppins, sans-serif;
    white-space: nowrap;
  }
  .credit-number {
    color: #f5b508;
    font-weight: 600;
  }
  .subtext {
    font-size: 11px;
  }
  #ad-credits {
    padding-right: 15px;
    border-right: 1px solid #f5b508;
  }
  #sms-credits {
    padding-left: 15px;
  }
`;

const SlideOutMenuArrow = styled.div`
  opacity: ${props => (props.menuOpen ? "1" : "0")};
  .material-icons {
    font-size: 30px;
    margin: 10px 0;
    color: #ffffff;
  }
`;

const MenuItemWrapper = styled.div`
  cursor: pointer;
  display: flex;
  height: 40px;
  border-radius: 10px;
  padding: 7px;
  margin-left: -7px;
  justify-content: flex-start;
  align-items: center;
  :hover {
    background-color: #2c097b;
  }
`;

const MenuText = styled.div`
  color: #ffffff;
  text-align: left;
  margin-left: 20px;
  font: normal normal 500 16px Poppins, sans-serif;
  width: ${props => (props.menuOpen ? "100%" : "0px")};
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  opacity: ${props => (props.menuOpen ? "1" : "0")};
  white-space: nowrap;
`;

const NotificationWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const SlideOutMenu = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1;
  background: #fafbfa;
  top: 0;
  right: 0%;
  width: ${props => (props.slideOutOpen ? "350px" : "0")};
  max-width: 100%;
  height: 100%;
  padding: ${props => (props.slideOutOpen ? "15px" : "15px 0")};
  overflow: hidden;
  transition: all 0.2s ease-in-out;
`;

const CloseBtn = styled.div`
  cursor: pointer;
  align-self: flex-end;
  .material-icons {
    color: #3a0ca2;
    margin: 0;
  }
`;

const SlideOutContent = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
`;

const MobileMenu = props => {
  const {
    menuOpen,
    authUser,
    smsCredits,
    adCreditsTotal,
    businessPage: { image } = {}
  } = props;
  const { name: companyName } = props.userCompany;
  const [slideOutOpen, setSlideOutOpen] = React.useState(false);
  const [notificationsOpen, setNotificationsOpen] = React.useState(false);
  const [profileOpen, setProfileOpen] = React.useState(false);

  const toggleSlideOutMenu = (open, type) => {
    setSlideOutOpen(false);
    setNotificationsOpen(false);
    setProfileOpen(false);
    if (open & (type === "notification")) {
      setSlideOutOpen(true);
      setNotificationsOpen(true);
    }
    if (open & (type === "profile")) {
      setSlideOutOpen(true);
      setProfileOpen(true);
    }
  };

  const handleMenuLink = pathname => {
    toggleSlideOutMenu(false);
    props.toggleMenu();
    props.history.push({ pathname });
  };

  return (
    <MenuContainer menuOpen={menuOpen && "menuOpen"}>
      <MenuContentWrapper menuOpen={menuOpen && "menuOpen"}>
        <MenuContent menuOpen={menuOpen && "menuOpen"}>
          <MainMenuItems
            menuOpen={menuOpen}
            handleMenuLink={handleMenuLink}
            isMobile={true}
          ></MainMenuItems>
        </MenuContent>
        <MenuItemWrapper
          menuOpen={menuOpen && "menuOpen"}
          onClick={() => toggleSlideOutMenu(!notificationsOpen, "notification")}
          title='Notifications'
        >
          <MenuIcon className='material-icons'>notifications</MenuIcon>
          <NotificationWrapper>
            <MenuText menuOpen={menuOpen && "menuOpen"}>
              <div>Notifications</div>
            </MenuText>
            <SlideOutMenuArrow menuOpen={menuOpen && "menuOpen"}>
              <span className='material-icons'>keyboard_arrow_right</span>
            </SlideOutMenuArrow>
          </NotificationWrapper>
        </MenuItemWrapper>
        <MenuItemWrapper
          onClick={() => handleMenuLink("/member-settings")}
          menuOpen={menuOpen && "menuOpen"}
          title='Settings'
        >
          <MenuIcon className='material-icons'>settings</MenuIcon>
          <MenuText menuOpen={menuOpen && "menuOpen"}>Settings</MenuText>
        </MenuItemWrapper>
      </MenuContentWrapper>
      {authUser && (
        <BottomContent>
          <SeparatorLine menuOpen={menuOpen && "menuOpen"}></SeparatorLine>
          <ProfileWrapper menuOpen={menuOpen && "menuOpen"}>
            <CompanyLogo
              menuOpen={menuOpen && "menuOpen"}
              onClick={() => toggleSlideOutMenu(!profileOpen, "profile")}
            >
              <img
                alt='Company Logo'
                src={image}
              />
            </CompanyLogo>
            <ProfileInfo menuOpen={menuOpen && "menuOpen"}>
              <div
                onClick={() => toggleSlideOutMenu(!profileOpen, "profile")}
                className='profile-name'
              >
                <div className='company-name'>{companyName}</div>
                <SlideOutMenuArrow menuOpen={menuOpen && "menuOpen"}>
                  <MenuIcon
                    className='material-icons'
                    title='Open menu'
                  >
                    keyboard_arrow_right
                  </MenuIcon>
                </SlideOutMenuArrow>
              </div>
              <div className='credits-wrapper'>
                <div
                  id='ad-credits'
                  className='credit-amountsad-credits'
                >
                  <div className='credit-number'>{adCreditsTotal}</div>
                  <div className='subtext'>Ad Credits</div>
                </div>
                <div
                  id='sms-credits'
                  className='credit-amountssms-credits'
                >
                  <div className='credit-number'>{smsCredits}</div>
                  <div className='subtext'>SMS Credits</div>
                </div>
              </div>
            </ProfileInfo>
          </ProfileWrapper>
        </BottomContent>
      )}
      <BottomMenu menuOpen={menuOpen && "menuOpen"}>
        <CenterBPLogo>
          <img
            id='wordLogo'
            alt='Boostpoint Full Logo'
            src='https://res.cloudinary.com/equipter/image/upload/v1633965014/Boostpoint_Images/BP-Logo-OneColor-Rev-Trimmed.png'
          />
        </CenterBPLogo>
        <ExpandMenuWrapper
          menuOpen={menuOpen && "menuOpen"}
          onClick={props.toggleMenu}
        >
          <div className='menu-top'></div>
          <div className='menu-middle'></div>
          <div className='menu-bottom'></div>
        </ExpandMenuWrapper>
      </BottomMenu>
      <SlideOutMenu slideOutOpen={slideOutOpen && "slideOutOpen"}>
        <CloseBtn onClick={() => toggleSlideOutMenu(false)}>
          <span className='material-icons'>close</span>
        </CloseBtn>
        {notificationsOpen && (
          <SlideOutContent>
            <Notifications unreadMessages={props.unreadMessages} />
          </SlideOutContent>
        )}
        {profileOpen && (
          <SlideOutContent>
            <CompanyProfile
              toggleSlideOutMenu={toggleSlideOutMenu}
              {...props}
            ></CompanyProfile>
          </SlideOutContent>
        )}
      </SlideOutMenu>
    </MenuContainer>
  );
};

export default MobileMenu;
