import styled from "styled-components";

//TODO: depreciate this for elements/button
// Base Styled Button Component
export const Button = styled.button`
  color: ${props => (props.solid ? props.theme.BGC : props.theme.HC1)};
  background-color: ${props =>
    props.solid ? props.theme.HC1 : props.theme.BGC};
  display: inline-block;
  border-radius: 1.2rem;
  height: ${props => (props.small ? "1.7rem" : "40px")};
  padding: ${props => (props.small ? "0 1rem" : "0 1.5rem")};
  min-width: ${props => (props.small ? "5rem" : "7rem")};
  margin: ${props => (props.customMargin ? props.customMargin : "1rem")};
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  border: 2px solid ${props => props.theme.HC1};
  font-size: ${props => (props.small ? "13px" : "20px")};
  letter-spacing: 1px;
  &:hover {
    color: ${props => (props.solid ? props.theme.HC1 : props.theme.BGC)};
    background-color: ${props =>
      props.solid ? props.theme.BGC : props.theme.HC1};
  }
  &:focus {
    color: ${props => props.theme.BGC };
    background-color: ${props => props.theme.HC2};
    border: 2px solid ${props => props.theme.HC2};
  }
  :disabled {
    color: ${props => (props.solid ? props.theme.BGC : props.theme.TextC)};
    background-color: ${props =>
      props.solid ? props.theme.TextC : props.theme.BGC};
    border: 1.5px solid lightgrey;
    cursor: not-allowed;
  }
  a {
    color: inherit;
  }
`;

export const TxtButton = styled.button`
  color: ${props => (props.solid ? props.theme.BGC : props.theme.HC1)};
  display: inline-block;
  height: ${props => (props.small ? "1.7rem" : "2.3rem")};
  padding: ${props => (props.small ? "0 1rem" : "0 1.5rem")};
  min-width: ${props => (props.small ? "5rem" : "7rem")};
  margin: ${props => (props.customMargin ? props.customMargin : "1rem")};
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  font-size: ${props => (props.small ? "13px" : "15px")};
  letter-spacing: 1px;
  &:hover {
    text-decoration: underline;
  }
  :disabled {
    color: ${props => (props.solid ? props.theme.BGC : props.theme.TextC)};
    cursor: not-allowed;
  }
  a {
    color: inherit;
  }
`;

export const CancelButton = styled(Button)`
  color: ${props => props.theme.BGC};
  background-color: ${props => props.theme.HCErr};
  border: 2px solid ${props => props.theme.HCErr};
  :disabled {
    color: ${props => props.theme.BGC};
    background-color: lightgrey;
    border: 1.5px solid lightgrey;
  }
`;

export const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 460px) {
    flex-direction: column;
  }
`;

export const SubmitButton = styled(Button)`
  margin: 5em 3em 0;
  width: 7em;

  @media screen and (max-width: 430px) {
    margin: 5em 1em 0;
  }
`;

export const TemplateButton = styled.button`
  font-size: 13px;
  padding: 6px;
  margin: 0 0.7em;
  border: none;
  background: none;
  background-color: #${props => props.theme.HC2};
  color: #${props => props.theme.ATextC};
  border-radius: 3px;
  display: inline-block;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
    0 1px 3px 1px rgba(60, 64, 67, 0.16);
`;
