import React from "react";
import styled from "styled-components";
import {
  InputBox,
  InputBoxWrapper,
  InputContainer,
  InputLabel
} from "../../styled/Input";
import {
  ActionDelete,
  FlowCard,
  FlowHeader,
  H2,
  Icon,
  OrderIcons
} from "./styles";

const Integration = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LogoThumb = styled.img`
  max-width: 35px;
`;

const ConnectIntegration = props => {
  const {
    action,
    actionKey,
    setIntegrationOptions,
    removeAction,
    first,
    last
  } = props;
  const [integrations, setIntegrations] = React.useState([]);

  // React.useEffect(() => {
  //   const getIntegrations = async () => {
  //     try {
  //       const integrationResponse = await axios.get(
  //         `${BP_NODE_SERVER}/v1/integrations`
  //       );
  //       setIntegrations(integrationResponse.data);
  //     } catch (err) {
  //       console.log("error getting integrations list", err);
  //       throw new Error("error getting integrations", err.message);
  //     }
  //   };
  //   getIntegrations();
  // }, []);

  const moveUp = () => {
    props.setActionOrder("up", actionKey);
  };

  const moveDown = () => {
    props.setActionOrder("down", actionKey);
  };

  const renderHeader = () => {
    if (!action.integrationName) {
      return (
        <FlowHeader>
          <Icon className='material-icons'>settings</Icon>
          <H2>Action</H2>
        </FlowHeader>
      );
    } else {
      const integrationFilter = integrations.filter(
        int => int.name === action.integrationName
      );
      const integration = integrationFilter[0];
      return (
        <FlowHeader>
          <OrderIcons className='order-icons' first={first} last={last}>
            <span className='material-icons first' onClick={moveUp}>
              keyboard_arrow_up
            </span>
            <span className='material-icons last' onClick={moveDown}>
              keyboard_arrow_down
            </span>
          </OrderIcons>
          <Icon>
            {integration && integration.icon && (
              <LogoThumb
                src={`https://res.cloudinary.com/equipter/image/upload/v1616090520/Boostpoint_Images/${
                  integration.icon ? integration.icon : integration.image
                }`}
                alt={
                  integration.nameReadable
                    ? integration.nameReadable
                    : integration.name
                }
              />
            )}
          </Icon>
          <H2>
            {integration
              ? integration.nameReadable
                ? integration.nameReadable
                : integration.name
              : ""}
          </H2>
          <ActionDelete
            className='delete'
            onClick={() => removeAction(actionKey)}
          >
            <span className='material-icons'>delete</span>
          </ActionDelete>
        </FlowHeader>
      );
    }
  };

  const renderIntegration = () => {
    if (!action.integrationName) {
      return (
        <div>
          {integrations.map((integration, index) => (
            <Integration
              key={index}
              onClick={() =>
                props.setIntegrationName(integration.name, actionKey)
              }
            >
              {!!integration.icon && (
                <LogoThumb
                  src={`https://res.cloudinary.com/equipter/image/upload/v1616090520/Boostpoint_Images/${
                    integration.icon ? integration.icon : integration.image
                  }`}
                  alt={
                    integration.nameReadable
                      ? integration.nameReadable
                      : integration.name
                  }
                />
              )}
              {integration.name}
            </Integration>
          ))}
        </div>
      );
    } else {
      switch (action.integrationName) {
        case "ConstantContact":
          return (
            <ConstantContact
              action={action}
              setIntegrationOptions={setIntegrationOptions}
              actionKey={actionKey}
            />
          );
        case "AppStack":
          return (
            <AppStack
              action={action}
              setIntegrationOptions={setIntegrationOptions}
              actionKey={actionKey}
            />
          );
        case "JobNimbus":
          return (
            <JobNimbus
              action={action}
              setIntegrationOptions={setIntegrationOptions}
              actionKey={actionKey}
            />
          );
        case "Hatch":
          return (
            <Hatch
              action={action}
              setIntegrationOptions={setIntegrationOptions}
              actionKey={actionKey}
            />
          );
        case "EZTexting":
          return (
            <EZTexting
              action={action}
              setIntegrationOptions={setIntegrationOptions}
              actionKey={actionKey}
            />
          );
        case "JobProgress":
          return (
            <JobProgress
              action={action}
              setIntegrationOptions={setIntegrationOptions}
              actionKey={actionKey}
            />
          );
        case "HealthcareSource":
          return (
            <HealthcareSource
              action={action}
              setIntegrationOptions={setIntegrationOptions}
              actionKey={actionKey}
            />
          );
        case "PreIntent":
          return (
            <PreIntent
              action={action}
              setIntegrationOptions={setIntegrationOptions}
              actionKey={actionKey}
            />
          );
        default:
          return null;
      }
    }
  };

  return (
    <FlowCard>
      {renderHeader()}
      {renderIntegration()}
    </FlowCard>
  );
};

export default ConnectIntegration;

const Container = styled(InputContainer)`
  margin: 1em auto 0;
`;

const ConstantContact = props => {
  const { action, actionKey, setIntegrationOptions } = props;
  return (
    <div>
      <Container>
        <InputLabel>List to sign up</InputLabel>
        <InputBoxWrapper>
          <InputBox
            type='text'
            name='list_membership'
            required
            value={action.options.list_membership}
            onChange={input =>
              setIntegrationOptions(
                { ...action.options, list_membership: input.target.value },
                actionKey
              )
            }
          />
        </InputBoxWrapper>
      </Container>
    </div>
  );
};

const AppStack = props => {
  const { action, actionKey, setIntegrationOptions } = props;
  return (
    <div>
      <Container>
        <InputLabel>Job ID</InputLabel>
        <InputBoxWrapper>
          <InputBox
            type='text'
            name='jobID'
            required
            value={action.options.jobID}
            onChange={input =>
              setIntegrationOptions(
                { ...action.options, jobID: input.target.value },
                actionKey
              )
            }
          />
        </InputBoxWrapper>
      </Container>
      <Container>
        <InputLabel>Stage</InputLabel>
        <InputBoxWrapper>
          <InputBox
            type='text'
            name='stage'
            required
            value={action.options.stage}
            onChange={input =>
              setIntegrationOptions(
                { ...action.options, stage: input.target.value },
                actionKey
              )
            }
          />
        </InputBoxWrapper>
      </Container>
    </div>
  );
};

const JobNimbus = props => {
  const { action, actionKey, setIntegrationOptions } = props;
  return (
    <div>
      <Container>
        <InputLabel>Record Type</InputLabel>
        <InputBoxWrapper>
          <InputBox
            type='text'
            name='record_type_name'
            required
            value={action.options.record_type_name}
            onChange={input =>
              setIntegrationOptions(
                { ...action.options, record_type_name: input.target.value },
                actionKey
              )
            }
          />
        </InputBoxWrapper>
      </Container>
      <Container>
        <InputLabel>Status</InputLabel>
        <InputBoxWrapper>
          <InputBox
            type='text'
            name='status_name'
            required
            value={action.options.status_name}
            onChange={input =>
              setIntegrationOptions(
                { ...action.options, status_name: input.target.value },
                actionKey
              )
            }
          />
        </InputBoxWrapper>
      </Container>
    </div>
  );
};

const Hatch = props => {
  const { action, actionKey, setIntegrationOptions } = props;
  return (
    <div>
      <Container>
        <InputLabel>Department ID</InputLabel>
        <InputBoxWrapper>
          <InputBox
            type='text'
            name='deptid'
            required
            value={action.options.deptID}
            onChange={input =>
              setIntegrationOptions(
                { ...action.options, deptID: input.target.value },
                actionKey
              )
            }
          />
        </InputBoxWrapper>
      </Container>
      <Container>
        <InputLabel>Status</InputLabel>
        <InputBoxWrapper>
          <InputBox
            type='text'
            name='status'
            required
            value={action.options.status}
            onChange={input =>
              setIntegrationOptions(
                { ...action.options, status: input.target.value },
                actionKey
              )
            }
          />
        </InputBoxWrapper>
      </Container>
    </div>
  );
};

const EZTexting = props => {
  const { action, actionKey, setIntegrationOptions } = props;
  return (
    <div>
      <Container>
        <InputLabel>Group ID</InputLabel>
        <InputBoxWrapper>
          <InputBox
            type='text'
            name='id'
            required
            value={action.options.groupID.id}
            onChange={input =>
              setIntegrationOptions(
                {
                  ...action.options,
                  groupID: { ...action.options.groupID, id: input.target.value }
                },
                actionKey
              )
            }
          />
        </InputBoxWrapper>
      </Container>
      <Container>
        <InputLabel>Group Name</InputLabel>
        <InputBoxWrapper>
          <InputBox
            type='text'
            name='groupName'
            required
            value={action.options.groupID.name}
            onChange={input =>
              setIntegrationOptions(
                {
                  ...action.options,
                  groupID: {
                    ...action.options.groupID,
                    name: input.target.value
                  }
                },
                actionKey
              )
            }
          />
        </InputBoxWrapper>
      </Container>
    </div>
  );
};

const JobProgress = props => {
  const { action, actionKey, setIntegrationOptions } = props;
  return (
    <div>
      <Container>
        <InputLabel>Referred By Type</InputLabel>
        <InputBoxWrapper>
          <InputBox
            type='text'
            name='referred_by_type'
            required
            value={action.options.referred_by_type}
            onChange={input =>
              setIntegrationOptions(
                { ...action.options, referred_by_type: input.target.value },
                actionKey
              )
            }
          />
        </InputBoxWrapper>
      </Container>
      <Container>
        <InputLabel>Referred By Note</InputLabel>
        <InputBoxWrapper>
          <InputBox
            type='text'
            name='referred_by_note'
            required
            value={action.options.referred_by_note}
            onChange={input =>
              setIntegrationOptions(
                { ...action.options, referred_by_note: input.target.value },
                actionKey
              )
            }
          />
        </InputBoxWrapper>
      </Container>
    </div>
  );
};

const HealthcareSource = props => {
  const { action, actionKey, setIntegrationOptions } = props;
  return (
    <div>
      <Container>
        <InputLabel>Candidate ID</InputLabel>
        <InputBoxWrapper>
          <InputBox
            type='text'
            name='candidateID'
            required
            value={action.options.candidateID}
            onChange={input =>
              setIntegrationOptions(
                { ...action.options, candidateID: input.target.value },
                actionKey
              )
            }
          />
        </InputBoxWrapper>
      </Container>
    </div>
  );
};

const PreIntent = props => {
  const { action, actionKey, setIntegrationOptions } = props;
  return (
    <div>
      <Container>
        <InputLabel>Webhook URL</InputLabel>
        <InputBoxWrapper>
          <InputBox
            type='text'
            name='webhookURL'
            required
            value={action.options.webhookURL}
            onChange={input =>
              setIntegrationOptions(
                { ...action.options, webhookURL: input.target.value },
                actionKey
              )
            }
          />
        </InputBoxWrapper>
      </Container>
    </div>
  );
};
