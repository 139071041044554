import React from "react";
import BpModal from "../../elements/BpModal";
import { TextArea } from "../../styled/Input";
import { emailSendBasic } from "../../../utils/NotificationsAPI";

const CampaignEdit = props => {
  const { modalEditOpen, userProfile, campaign } = props;
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const sendMessage = async () => {
    setLoading(true);
    try {
      await emailSendBasic({
        to: ["support@boostpoint.com"],
        subject: `Edit Campaign Request from ${userProfile.firstName} ${userProfile.lastName}`,
        message: `
          userId: ${userProfile._id};
          user email: ${userProfile.email};
          Campaign Name: ${campaign.name};
          Campaign Id: ${campaign._id};
          Facebook Campaign Id: ${campaign.fbCampaignId};
          ${message}
        `
      });
      setSuccess(true);
    } catch (err) {
      console.log("error sending mail", err.message);
    }
    setLoading(false);
  };

  const handlePrimaryAction = () => {
    if (success) {
      props.setModalEditOpen(false);
    } else {
      sendMessage();
    }
  };

  return (
    <BpModal
      open={modalEditOpen}
      title={"Edit Campaign"}
      primaryAction={{
        btnLabel: success ? "Close" : "Send Message",
        action: handlePrimaryAction,
        disabled: loading
      }}
      secondaryAction={{
        btnLabel: "Cancel",
        action: () => props.setModalEditOpen(false)
      }}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      contentLabel='Edit Campaign'
      body={
        <div>
          {!!success && (
            <p>
              <strong>
                Your message was sent. We will be in contact with you.
              </strong>
            </p>
          )}
          <p>
            Need to edit this campaign? Tell us what you would like to change.
          </p>
          <p>
            <TextArea
              type='text'
              name='editMessage'
              rows='5'
              value={message}
              onChange={input => setMessage(input.target.value)}
            />
          </p>
        </div>
      }
    />
  );
};

export default CampaignEdit;
