import styled from "styled-components";

const FiftyTwoWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 1024px;
  margin: 20px auto;

  @media screen and (max-width: 560px) {
    flex-flow: column;
  }
`;

export default FiftyTwoWrapper;
