import React from "react";
import styled from "styled-components";
import DotsFlashingLoader from "../../elements/DotsFlashingLoader";

const MsgWrapper = styled.div`
  display: flex;
  flex-direction: ${props => (props.inbound ? "row" : "row-reverse")};
  position: relative;
  margin-left: ${props => (props.showSelectors ? "30px" : "0")};
  transition: margin 0.3s ease-in-out;
  margin-bottom: 12px;
  @media only screen and (min-width: 901px) {
    margin-bottom: 20px;
  }
`;

const MsgInner = styled.div`
  max-width: 700px;
  width: 70%;
  @media only screen and (max-width: 900px) {
    max-width: 80%;
    width: 80%;
  }
`;

const MsgContainer = styled.div`
  background-color: ${props =>
    props.inbound ? props.theme.BGCAlt : props.theme.HC2};
  border-radius: ${props =>
    props.inbound ? "5px 5px 5px 0px" : "5px 5px 0px 5px"};
  color: ${props => (props.inbound ? props.theme.BTextC : props.theme.ATextC)};
  border: ${props => (props.inbound ? "1px solid rgb(242, 242, 242)" : "none")};
  padding: 1em;
  text-align: left;
  a {
    color: ${props => (props.inbound ? "inherit" : "white")};
  }
`;

const MsgBody = styled.div`
  margin: 0;
  img {
    max-width: 100%;
    max-height: 300px;
  }
  ${props =>
    props.msgLoading &&
    `
    display: flex;
    justify-content: right;
    padding-right: 20px;
    `}
`;

const MessagePlaceholder = props => (
    <MsgWrapper inbound={false}>
      <MsgInner>
        <MsgContainer inbound={false}>
          <MsgBody msgLoading>
            <DotsFlashingLoader darkMode={false} reverse={true} />
          </MsgBody>
        </MsgContainer>
      </MsgInner>
    </MsgWrapper>
  );

export default MessagePlaceholder;
