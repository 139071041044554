import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import { adCreditsCheck } from "../../../utils/CreditsAPI";
import {
  campaignDelete,
  campaignGetDrafts,
  campaignUpdate,
  campaignValidation,
  mediaTemplateByIndustry,
  mediaTemplateCreate,
  mediaTemplateDelete,
  mediaTemplateGetByCompany,
  mediaTemplateUpdate,
  targetingGetById
} from "../../../utils/FacebookAPI";
import {
  callsToActionLeads,
  callsToActionLinkClick,
  errorHandler,
  urlValidator
} from "../../../utils/Helpers";
import BpModal from "../../elements/BpModal";
import { H1 } from "../../styled/Fonts";
import { TitleLeft, TitleWrapper } from "../../styled/Layout";
import { HelperSubmit } from "./CampaignHelpers";
import FullScreenWaiting from "./FullScreenWaiting";
import MyTemplates from "./MyTemplates";
import Thankyou from "./Thankyou";

// Styled Components
import { cloudinaryFormat } from "../../../utils/MediaAPI";
import AdPreview from "../../AdPreview";
import { LoaderDotsSmall } from "../../elements/Loaders";
import ImageSelector from "../../media/ImageSelector";
import VideoSelector from "../../media/VideoSelector";
import { Button, SubmitButton } from "../../styled/Buttons";
import FiftyTwo from "../../styled/FiftyTwo";
import FiftyTwoWrapper from "../../styled/FiftyTwoWrapper";
import { Subtitle, Title } from "../../styled/Headers";
import {
  Dropdown,
  InputBox,
  InputBoxWrapper,
  InputContainer,
  InputLabel,
  TextArea
} from "../../styled/Input";
import InputError from "../../styled/InputErrorForm";
import LargeFiftyTwoWrapper from "../../styled/LargeFiftyTwoWrapper";
import { Card, CardTitle } from "../../styled/Layout";
import TemplateNameInput from "../../styled/TemplateName";
import Yellow from "../../styled/YellowColor";

const customStyles = {
  overlay: {
    zIndex: "1002",
    backgroundColor: "rgba(250, 250, 250, .75)"
  },
  content: {
    textAlign: "center",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    maxWidth: "90%",
    padding: "1em 2em 2em",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 0px 9px 0px rgba(0, 0, 6, .1)"
  }
};

const PreviewModalWrapper = styled.div`
  margin: 2em auto 0.5em;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SelectedImageDisplay = styled.div`
  margin-top: 4em;
  display: flex;
  justify-content: center;
  div {
    margin: 10px;
    display: flex;
    align-items: center;
  }
`;

// Styled Ad Template
const AdTemplate = styled.div`
  margin: auto;
`;

const AdTemplateWrapper = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (max-width: 825px) {
    display: block;
  }
`;

const AdTemplateGroup = styled.div`
  margin: 0 2em;
`;

const H5 = styled.h5`
  margin-bottom: 0.5em;
  font-style: italic;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 60%;
  max-width: 375px;
  min-width: 200px;
  margin: 0 auto;
`;

const MediaButton = styled.button`
  border: 1px solid ${props => props.theme.ATextC};
  padding: 1em 5em;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 6, 0.1);
`;

const MediaLink = styled.i`
  font-size: 5em;
  color: ${props => props.theme.HC3};
`;

const MediaSpecs = styled.div`
  text-align: left;
  margin: auto;
`;

const VideoThumbnailWrapper = styled.div`
  position: relative;
`;

const VideoThumbnail = styled.img`
  height: 5em;
  cursor: pointer;
`;

const ImagePlayOverlay = styled.div`
  position: absolute;
  overflow: hidden;
  cursor: pointer;
  background-color: RGBA(191, 191, 191, 0.6);
  padding: 0.1em 0.2em;
  width: 2em;
  height: 1.6em;
  top: -10px;
  left: -10px;
  transition: all ease 0.5s;

  :hover {
    background-color: RGBA(191, 191, 191, 1);
    transition: all ease 0.5s;
  }
`;

const ImageThumbnail = styled.img`
  height: 5em;
  cursor: pointer;
`;

class CreateAd extends React.Component {
  state = {
    imageModalStatus: false,
    fbPreviewSrc: [],
    fbVideoPreviewSrc: [],
    fbPreviewNum: 0,
    fbPreviewNumDisplay: 1,
    modalExistingCampaign: false,
    nameModalIsOpen: false,
    campaignType: null,
    redirectToArea: false,
    redirectToCustomer: false,
    redirectStartOver: false,
    pendingCampaign: {},
    stockTemplates: [],
    myTemplates: [],
    previewIsOpen: false,
    previewItem: null,
    submissionComplete: false,
    showSubmitWaiting: false,
    segment: {},
    errMessages: {},
    loading: true,
    errFields: [],
    refillingCredits: false,
    modalFailedPayments: false,
    campaignName: ""
  };

  async componentDidMount() {
    const {
      userCompany: { industry }
    } = this.props;
    try {
      await this.getCampaign();
      this.getSegment();
      let stockTemplates = [];
      if (industry) {
        stockTemplates = await mediaTemplateByIndustry(industry);
      }
      const myTemplates = await mediaTemplateGetByCompany();
      this.setState({ myTemplates, stockTemplates });
      this.props.checkPermissions();
    } catch (err) {
      const { status, message } = errorHandler(err);
      console.log("err", `${status}: ${message}`);
    }
  }

  componentDidUpdate = async prevProps => {
    const { refillingCredits } = this.state;
    const {
      userCompany: { industry }
    } = this.props;
    const { userCompany: { industry: prevIndustry } = {} } = prevProps;
    if (
      refillingCredits &&
      this.props.adCreditsAvailable !== prevProps.adCreditsAvailable
    ) {
      this.setState({ refillingCredits: false }, () => {
        this.openCampaignNameModal();
      });
    }
    if (industry !== prevIndustry) {
      const stockTemplates = await mediaTemplateByIndustry(industry);
      this.setState({ stockTemplates });
    }
  };

  getCampaign = async () => {
    const { history } = this.props;
    try {
      const campaigns = await campaignGetDrafts();
      if (campaigns.length === 0) {
        console.log("no campaigns");
        history.push("/campaign-select");
      }
      if (history.action === "POP") {
        this.setState({
          modalExistingCampaign: true
        });
      }
      const { name: campaignName = "" } = campaigns[0];
      this.setState({
        pendingCampaign: campaigns[0],
        campaignName: campaignName === "untitled" ? "" : campaignName
      });
    } catch (err) {
      const { status, message } = errorHandler(err);
      console.log(status, message);
    }
  };

  getSegment = async () => {
    const { targetingId } = this.state.pendingCampaign;
    try {
      if (targetingId) {
        const targeting = await targetingGetById(targetingId);
        this.setState({ segment: targeting });
      }
    } catch (err) {
      const { status, message } = errorHandler(err);
      console.log("err", `${status}: ${message}`);
    }
  };

  updateCampaign = campaign =>
    new Promise((resolve, reject) => {
      try {
        campaignUpdate(campaign);
        this.setState({ pendingCampaign: campaign }, () => {
          resolve("success");
        });
      } catch (err) {
        const { status, message } = errorHandler(err);
        console.log(status, message);
        reject(err.message);
      }
    });

  updateCampaignMedia = media => {
    const { pendingCampaign } = this.state;
    this.updateCampaign({ ...pendingCampaign, media });
  };

  // opens image selection modal
  openImagesModal = () => {
    const bodyTag = document.getElementsByTagName("body")["0"];
    bodyTag.classList.add("overflowY-hidden");
    this.setState({
      imageModalStatus: true
    });
  };

  openVideoModal = () => {
    const bodyTag = document.getElementsByTagName("body")["0"];
    bodyTag.classList.add("overflowY-hidden");
    this.setState({
      videoModalStatus: true
    });
  };

  updateMedia = async selection => {
    const bodyTag = document.getElementsByTagName("body")["0"];
    bodyTag.classList.remove("overflowY-hidden");
    this.setState({
      imageModalStatus: false,
      videoModalStatus: false
    });
    if (selection) {
      const { pendingCampaign } = this.state;
      await this.updateCampaign({
        ...pendingCampaign,
        media: selection
      });
    }
    this.handleFieldValidation();
    const node = ReactDOM.findDOMNode(this.refs.imageAnchor);
    window.scrollTo(0, node.offsetTop);
  };

  // this function is called whenever an input field is manually changed
  handleContentChange = input => {
    const { name: fieldName, value } = input.target;
    const { pendingCampaign } = this.state;
    this.updateCampaign({
      ...pendingCampaign,
      content: {
        ...pendingCampaign.content,
        [fieldName]: value
      }
    });
  };

  validateWebsite = () => {
    const { content: { website = "" } = {}, type } = this.state.pendingCampaign;
    const facebook = type === "LeadAd" ? false : true; // if Facebook.com is a valid domain
    const urlValid = urlValidator(website, false, facebook);
    const { errMessages } = this.state;
    if (urlValid !== true) {
      this.props.alert({ type: "error", msg: urlValid });
      errMessages.noWebsite = "Please enter a valid website address";
    } else {
      errMessages.noWebsite = "";
    }
    this.setState({ errMessages });
    if (errMessages.noWebsite) {
      return true;
    } else {
      return false;
    }
  };

  prevAdPreview = () => {
    const length = this.state.fbPreviewSrc.length;
    if (this.state.fbPreviewNum <= length) {
      this.setState(prevState => ({
        fbPreviewNumDisplay: prevState.fbPreviewNumDisplay - 1,
        fbPreviewNum: prevState.fbPreviewNum - 1
      }));
    }
  };

  nextAdPreview = () => {
    const length = this.state.fbPreviewSrc.length;
    if (this.state.fbPreviewNum <= length) {
      this.setState(prevState => ({
        fbPreviewNumDisplay: prevState.fbPreviewNumDisplay + 1,
        fbPreviewNum: prevState.fbPreviewNum + 1
      }));
    }
  };

  handleMyTemplateDropdown = input => {
    const { pendingCampaign, myTemplates } = this.state;
    const { type = "" } = pendingCampaign;
    const value = input.target.value;
    const matchingTemplate = myTemplates.find(
      template => template._id === value
    );
    if (matchingTemplate) {
      const {
        body = "",
        headline = "",
        newsFeedLinkDesc = "",
        website = "",
        callToAction = "",
        media = []
      } = matchingTemplate;
      const updatedTemplateContent = {
        body,
        headline,
        newsFeedLinkDesc,
        website,
        callToAction: ""
      };
      if (type === "LinkClick" && callsToActionLinkClick[callToAction]) {
        updatedTemplateContent.callToAction = callToAction;
      } else if (type === "LeadAd" && callsToActionLeads[callToAction]) {
        updatedTemplateContent.callToAction = callToAction;
      }
      this.updateCampaign(
        {
          ...pendingCampaign,
          content: updatedTemplateContent,
          media: media ? media : [],
          templateId: value
        },
        () => {
          this.handleFieldValidation();
        }
      );
    }
    if (input.target.value === "Select One") {
      this.handleFieldClear();
    }
  };

  handleStockTemplateDropdown = input => {
    const { stockTemplates, pendingCampaign } = this.state;
    const { type = "" } = pendingCampaign;
    const value = input.target.value;
    const matchingTemplate = stockTemplates.find(
      template => template._id === value
    );
    if (matchingTemplate) {
      const {
        body = "",
        headline = "",
        newsFeedLinkDesc = "",
        website = "",
        callToAction = "",
        media = []
      } = matchingTemplate;
      const updatedTemplateContent = {
        body,
        headline,
        newsFeedLinkDesc,
        website
      };
      if (type === "LinkClick" && callsToActionLinkClick[callToAction]) {
        updatedTemplateContent.callToAction = callToAction;
      }
      if (type === "LeadAd" && callsToActionLeads[callToAction]) {
        updatedTemplateContent.callToAction = callToAction;
      }
      this.updateCampaign({
        ...pendingCampaign,
        content: updatedTemplateContent,
        media: media ? media : [],
        templateId: value
      });
    }
    if (input.target.value === "Select One") {
      this.handleFieldClear();
    }
  };

  openCampaignNameModal = () => {
    this.handleFieldValidation(() => {}).then(async isValid => {
      if (isValid) {
        try {
          this.creditCheck();
        } catch (err) {
          try {
            if (err.status === "notEnoughCredits") {
              const { pendingCampaign } = this.state;
              this.setState({ refillingCredits: true }, () => {
                this.props.openCreditModal(
                  "campaignRequired",
                  err.creditsRequired,
                  pendingCampaign.totalBudget
                );
              });
            } else if (err.status === "failedPayments") {
              this.setState({ modalFailedPayments: true });
            } else {
              console.log("error checking credits", err);
            }
          } catch (err) {
            console.log("error", err);
          }
        }
      } else {
        const { errFields } = this.state;
        if (errFields.length > 0) {
          let errFieldsString = "";
          if (errFields.length === 1) {
            errFieldsString = errFields[0];
          } else if (errFields.length === 2) {
            errFieldsString = errFields.join(" and ");
          } else {
            errFields[errFields.length - 1] =
              "and " + errFields[errFields.length - 1];
            errFieldsString = errFields.join(", ");
          }
          this.props.alert({
            type: "error",
            msg: `You are missing ${errFieldsString}`,
            timeout: 10000
          });
        }
      }
    });
  };

  handleCampaignNameSave = async () => {
    try {
      const { campaignName = "", pendingCampaign } = this.state;
      console.log("saving campaign name");
      if (!campaignName || campaignName === "untitled") {
        this.setState({
          errMessages: {
            noCampaignName: "Please enter a valid name for your campaign!"
          }
        });
        return;
      }
      await this.updateCampaign({ ...pendingCampaign, name: campaignName });
      console.log("submitting");
      this.handleSubmitButton();
    } catch (err) {
      console.log("error saving campaign name", err.message);
    }
  };

  handleSubmitButton = async () => {
    const { alert, businessPage = {}, platformPermissions } = this.props;
    const isValid = await this.handleFieldValidation();
    console.log("is valid", isValid);
    if (isValid) {
      const validationReturn = await campaignValidation({
        businessPage,
        platformPermissions
      });
      if (validationReturn.type === "error") {
        alert(validationReturn);
        console.warn(validationReturn.msg);
        return;
      }
      this.setState({ showSubmitWaiting: true });
      const { pendingCampaign } = this.state;
      try {
        const campaignResponse = await HelperSubmit({
          pendingCampaign,
          formName: "",
          businessPage
        });
        console.log(campaignResponse);
        if (campaignResponse) {
          this.setState({
            errMessages: {},
            errFields: [],
            submissionComplete: true,
            pendingCampaign: campaignResponse
          });
        }
      } catch (err) {
        console.error(err);
        alert({
          type: "error",
          msg: err.message
        });
      }
    } else {
      const { errFields } = this.state;
      if (errFields.length > 0) {
        let errFieldsString = "";
        if (errFields.length === 1) {
          errFieldsString = errFields[0];
        } else if (errFields.length === 2) {
          errFieldsString = errFields.join(" and ");
        } else {
          errFields[errFields.length - 1] =
            "and " + errFields[errFields.length - 1];
          errFieldsString = errFields.join(", ");
        }
        alert({
          type: "error",
          msg: `You are missing ${errFieldsString}`,
          timeout: 10000
        });
      }
    }
  };

  handlePreviewOpen = data => {
    this.setState({
      previewIsOpen: true,
      previewItem: data.filename
    });
  };

  handlePreviewClose = () => {
    this.setState({
      previewIsOpen: false,
      previewItem: null
    });
  };

  handlePreviousButton = () => {
    const { type = "LeadAd" } = this.state.pendingCampaign;
    if (type) {
      this.props.history.push("/area-campaign");
    }
  };

  handleNextButton = async () => {
    await this.setState({
      errMessages: {},
      errFields: []
    });
    this.handleFieldValidation(() => {})
      .then(isValid => {
        if (isValid) {
          const { type = "" } = this.state.pendingCampaign;
          if (type === "LeadAd") {
            this.props.history.push("/create-lead-ad");
          }
        } else {
          const { errFields } = this.state;
          if (errFields.length > 0) {
            let errFieldsString = "";
            if (errFields.length === 1) {
              errFieldsString = errFields[0];
            } else if (errFields.length === 2) {
              errFieldsString = errFields.join(" and ");
            } else {
              errFields[errFields.length - 1] =
                "and " + errFields[errFields.length - 1];
              errFieldsString = errFields.join(", ");
            }
            this.props.alert({
              type: "error",
              msg: `You are missing ${errFieldsString}`,
              timeout: 10000
            });
          }
        }
      })
      .catch(reject => {
        console.error("reject ", reject);
      });
  };

  handleFieldValidation = async () => {
    const { pendingCampaign } = this.state;
    let fieldsAreValid = true;
    console.log("pendingCampaign", pendingCampaign);
    const {
      content: { website, headline, body, callToAction, newsFeedLinkDesc } = {},
      media = [],
      type
    } = pendingCampaign;
    console.log(
      "callToAction",
      callToAction,
      callsToActionLinkClick[callToAction]
    );
    let errMessages = {};
    const errFields = [];
    if (media.length === 0) {
      fieldsAreValid = false;
      errMessages = {
        ...errMessages,
        noImages:
          "Please select at least one image or a video for your campaign"
      };
      errFields.push("at least one image or a video");
    } else if (media.filter(m => m.mediaType === "IMAGE").length > 6) {
      fieldsAreValid = false;
      errMessages = {
        ...errMessages,
        noImages:
          "The maximum number of images is 6. Please ensure you have 6 images or less."
      };
      errFields.push("no more than 6 images");
    }
    if (!website) {
      fieldsAreValid = false;
      errMessages = {
        ...errMessages,
        noWebsite: "Please enter a valid website address"
      };
      errFields.push("a website address");
    } else if (this.validateWebsite()) {
      fieldsAreValid = false;
      errMessages = {
        ...errMessages,
        noWebsite: "Please enter a valid website address"
      };
      errFields.push("a valid website address");
    }
    if (!headline || headline === "") {
      fieldsAreValid = false;
      errMessages = {
        ...errMessages,
        noHeadline: "Please enter a headline"
      };
      errFields.push("a headline");
    }
    if (!body || body === "") {
      fieldsAreValid = false;
      errMessages = {
        ...errMessages,
        noText: "Please enter ad text"
      };
      errFields.push("ad text");
    }
    if (!callToAction || callToAction === "") {
      fieldsAreValid = false;
      errMessages = {
        ...errMessages,
        noCallToAction: "Please select a call to action"
      };
      errFields.push("a call to action");
    }
    if (!newsFeedLinkDesc || newsFeedLinkDesc === "") {
      fieldsAreValid = false;
      errMessages = {
        ...errMessages,
        noNewsFeedLinkDescription: "Please enter a link description"
      };
      errFields.push("a link description");
    }
    if (callToAction && type === "LinkClick") {
      if (!Object.keys(callsToActionLinkClick).includes(callToAction)) {
        fieldsAreValid = false;
        const callsToActionText = Object.keys(callsToActionLinkClick).map(
          cta => callsToActionLeads[cta]
        );
        const displayCallsToAction = callsToActionText
          .join(", ")
          .replace(/, ([^,]*)$/, " or $1");
        errMessages = {
          ...errMessages,
          wrongCallToAction: `For Link Click Campaigns, Call to Action must be one of ${displayCallsToAction}`
        };
        this.props.alert({
          type: "error",
          msg: `For Link Click Campaigns, Call to Action must be one of ${displayCallsToAction}`
        });
      }
    }
    this.setState({ errMessages, errFields });
    return fieldsAreValid;
  };

  handleSingleInputValidation = async input => {
    const inputName = input.target.name;
    // eslint-disable-next-line no-unused-vars
    let fieldsAreValid = true;
    const {
      noWebsite,
      noHeadline,
      noText,
      noCallToAction,
      noNewsFeedLinkDescription,
      wrongCallToAction
    } = this.state.errMessages;
    const {
      content: { website, headline, body, callToAction, newsFeedLinkDesc } = {},
      type
    } = this.state.pendingCampaign;
    let errMessages = {};
    // todo update this clear with new datamodel
    if ((inputName === "website" || noWebsite) && !website) {
      fieldsAreValid = false;
      errMessages = {
        ...errMessages,
        noWebsite: "Please enter a valid website address"
      };
    }
    if ((inputName === "headline" || noHeadline) && !headline) {
      fieldsAreValid = false;
      errMessages = {
        ...errMessages,
        noHeadline: "Please enter a headline"
      };
    }
    if ((inputName === "body" || noText) && !body) {
      fieldsAreValid = false;
      errMessages = {
        ...errMessages,
        noText: "Please enter text"
      };
    }
    if ((inputName === "callToAction" || noCallToAction) && !callToAction) {
      fieldsAreValid = false;
      errMessages = {
        ...errMessages,
        noCallToAction: "Please select a call to action"
      };
    }
    if (
      (inputName === "newsFeedLinkDesc" || noNewsFeedLinkDescription) &&
      !newsFeedLinkDesc
    ) {
      fieldsAreValid = false;
      errMessages = {
        ...errMessages,
        noNewsFeedLinkDescription: "Please enter a link description"
      };
    }
    if (
      (inputName === "callToAction" || wrongCallToAction) &&
      callToAction &&
      type === "LinkClick"
    ) {
      if (!callsToActionLinkClick[callToAction]) {
        fieldsAreValid = false;
        errMessages = {
          ...errMessages,
          wrongCallToAction: `For Link Click Campaigns, Call to Action must be one of 'Contact Us' or 'Learn More'`
        };
        this.props.alert({
          type: "error",
          msg: `For Link Click Campaigns, Call to Action must be one of 'Contact Us' or 'Learn More'`
        });
      }
    }
    this.setState({
      errMessages
    });
  };

  handleFieldClear = async () => {
    const { pendingCampaign } = this.state;
    const media = pendingCampaign.media.filter(item => item.type !== "IMAGE");
    await this.updateCampaign({
      ...pendingCampaign,
      media,
      content: {
        headline: "",
        body: "",
        website: "",
        callToAction: "",
        newsFeedLinkDesc: ""
      }
    });
    return true;
  };

  handleContinue = () => {
    this.setState({ modalExistingCampaign: false, redirectToArea: true });
  };

  handleStartOver = async () => {
    try {
      const campaigns = await campaignGetDrafts();
      const campaignIds = campaigns.map(c => c._id);
      if (campaignIds.length > 0) {
        const wait = [];
        campaignIds.forEach(c => wait.push(campaignDelete(c)));
        await Promise.all(wait);
      }
      this.setState({ modalExistingCampaign: false, redirectStartOver: true });
    } catch (err) {
      const { status, message } = errorHandler(err);
      console.log("err", `${status}: ${message}`);
    }
  };

  creditCheck = async () => {
    const { pendingCampaign: { credits: { original = 0 } = {} } = {} } =
      this.state;
    try {
      const creditResponse = await adCreditsCheck({
        adCreditsAmount: original
      });
      if (creditResponse.success) {
        this.setState({ nameModalIsOpen: true });
      } else if (creditResponse.status === "notEnoughCredits") {
        this.setState({ refillingCredits: true }, () => {
          this.props.openCreditModal(
            "campaignRequired",
            creditResponse.creditsRequired,
            original
          );
        });
      } else if (creditResponse.status === "failedPayments") {
        this.setState({ modalFailedPayments: true });
      } else {
        console.log("error checking credits", creditResponse);
      }
    } catch (err) {
      console.log("error", err.message);
    }
  };

  handleTemplateCallback = async ({ action, name, saveMedia }) => {
    const { userCompany } = this.props;
    const { pendingCampaign, myTemplates } = this.state;
    const {
      content: {
        body = "",
        callToAction = "",
        headline = "",
        newsFeedLinkDesc = "",
        website = ""
      },
      media = [],
      templateId
    } = pendingCampaign;
    try {
      if (action === "save") {
        const templateObject = {
          companyId: userCompany._id,
          body,
          callToAction,
          headline,
          newsFeedLinkDesc,
          media: saveMedia ? media : [],
          specialAdCategory: ["NONE"],
          templateName: name,
          website,
          isStock: false,
          industry: userCompany.industry
        };
        const template = await mediaTemplateCreate(templateObject);
        this.updateCampaign({ ...pendingCampaign, templateId: template._id });
        const updatedTemplates = await mediaTemplateGetByCompany();
        this.setState({ myTemplates: updatedTemplates });
        return template;
      } else if (action === "update") {
        console.log("update template started");
        const currentTemplate = myTemplates.find(t => t._id === templateId);
        const updatedTemplate = {
          ...currentTemplate,
          body,
          callToAction,
          headline,
          newsFeedLinkDesc,
          website,
          media: saveMedia ? media : []
        };
        const template = await mediaTemplateUpdate(updatedTemplate);
        console.log("updated template: ", template);
        this.updateCampaign({ ...pendingCampaign, templateId: template._id });
        return template;
      } else if (action === "remove") {
        const templateResponse = await mediaTemplateDelete(templateId);
        console.log("response", templateResponse);
        this.updateCampaign({ ...pendingCampaign, templateId: "" });
        const updatedTemplates = await mediaTemplateGetByCompany();
        this.setState({ myTemplates: updatedTemplates });
        return templateResponse;
      }
    } catch (err) {
      const { status, message } = errorHandler(err);
      console.log("err", `${status}: ${message}`);
    }
  };

  render() {
    const {
      showSubmitWaiting,
      submissionComplete,
      segment,
      pendingCampaign,
      modalExistingCampaign,
      campaignName,
      imageModalStatus,
      videoModalStatus,
      myTemplates = [],
      stockTemplates = []
    } = this.state;
    const { userProfile } = this.props;
    const {
      type,
      media = [],
      templateId = "",
      content: {
        website = "",
        headline = "",
        body = "",
        callToAction,
        newsFeedLinkDesc = ""
      } = {}
    } = pendingCampaign;

    let callsToAction = callsToActionLinkClick;
    if (type === "LeadAd") {
      callsToAction = callsToActionLeads;
    }

    return (
      <div>
        <TitleWrapper>
          <TitleLeft>
            <H1>{segment && segment.name ? segment.name : "New"} Campaign</H1>
          </TitleLeft>
        </TitleWrapper>
        {submissionComplete ? (
          <Thankyou
            userProfile={userProfile}
            submissionComplete={submissionComplete}
            pendingCampaign={pendingCampaign}
          />
        ) : (
          <div id='fbAdWrapper'>
            {showSubmitWaiting && (
              <FullScreenWaiting message='Submitting your campaign to Facebook and Instagram. This may take some time. Please do not leave this page during this process.' />
            )}
            {!!imageModalStatus && (
              <ImageSelector
                {...this.props}
                pendingCampaign={pendingCampaign}
                updateMedia={this.updateMedia}
                updateCampaignMedia={this.updateCampaignMedia}
                alert={this.props.alert}
              />
            )}
            {!!videoModalStatus && (
              <VideoSelector
                {...this.props}
                pendingCampaign={pendingCampaign}
                updateMedia={this.updateMedia}
                updateCampaignMedia={this.updateCampaignMedia}
                alert={this.props.alert}
              />
            )}
            <Card>
              <CardTitle>
                <Title>Content Templates</Title>
              </CardTitle>
              <AdTemplate>
                <AdTemplateWrapper>
                  <AdTemplateGroup className='my-templates'>
                    <H5>my templates</H5>
                    <InputContainer>
                      <Dropdown
                        type='text'
                        name='customTemplates'
                        onChange={this.handleMyTemplateDropdown}
                        value={templateId}
                      >
                        <option key='defaultValue'>Select One</option>
                        {myTemplates.map(template => (
                          <option key={template._id} value={template._id}>
                            {template.templateName}
                          </option>
                        ))}
                      </Dropdown>
                    </InputContainer>
                  </AdTemplateGroup>
                  <AdTemplateGroup className='stock-templates'>
                    <H5>stock templates</H5>
                    <InputContainer>
                      <Dropdown
                        type='text'
                        name='stockTemplates'
                        onChange={this.handleStockTemplateDropdown}
                        value={templateId}
                      >
                        <option key='defaultValue'>Select One</option>
                        {stockTemplates.map((content, index) => (
                          <option key={index} value={content._id}>
                            {content.templateName}
                          </option>
                        ))}
                      </Dropdown>
                    </InputContainer>
                  </AdTemplateGroup>
                </AdTemplateWrapper>
              </AdTemplate>
            </Card>
            <Card ref='imageAnchor' className='add-image'>
              <CardTitle bigMargin>
                <Title>Media</Title>
                <Subtitle>
                  You can select up to 6 images and/or a single video.
                </Subtitle>
              </CardTitle>
              <FiftyTwoWrapper>
                <FiftyTwo>
                  <div>
                    <MediaButton onClick={this.openImagesModal} id='add-images'>
                      <MediaLink className='material-icons'>
                        add_a_photo
                      </MediaLink>
                      <h4>
                        <Yellow>
                          {media.some(item => item.type === "IMAGE")
                            ? "EDIT"
                            : "ADD"}{" "}
                          IMAGES
                        </Yellow>
                      </h4>
                    </MediaButton>
                  </div>
                </FiftyTwo>
                <FiftyTwo>
                  <div>
                    <MediaButton onClick={this.openVideoModal} id='add-images'>
                      <MediaLink className='material-icons'>
                        video_settings
                      </MediaLink>
                      <h4>
                        <Yellow>
                          {media.some(item => item.type === "VIDEO")
                            ? "EDIT"
                            : "ADD"}{" "}
                          VIDEO
                        </Yellow>
                      </h4>
                    </MediaButton>
                  </div>
                </FiftyTwo>
              </FiftyTwoWrapper>
              <MediaSpecs>
                <h4>Recommended Media Specs</h4>
                <small>
                  * Recommended Media Size: <Yellow>1,200 x 628 pixels</Yellow>
                </small>
                <br />
                <small>
                  * Media ratio: <Yellow>1.91:1</Yellow>
                </small>
                <br />
                <small>
                  * To maximize ad delivery, use an image or video with{" "}
                  <Yellow>little to no text overlay</Yellow>
                </small>
              </MediaSpecs>
              <SelectedImageDisplay>
                {media.map((item, index) => (
                  <div key={index}>
                    {item.mediaType === "VIDEO" ? (
                      <VideoThumbnailWrapper>
                        <ImagePlayOverlay
                          onClick={() => this.handlePreviewOpen(item)}
                        >
                          <i className='material-icons md-dark md-24'>
                            play_circle_filled_white
                          </i>
                        </ImagePlayOverlay>
                        <VideoThumbnail
                          ref={item.filename}
                          id={item.filename}
                          src={cloudinaryFormat({
                            type: "video",
                            height: 80,
                            mode: "scale",
                            filename: item.filename,
                            format: "jpg"
                          })}
                          alt={item.noImages}
                          onClick={this.openVideoModal}
                        />
                      </VideoThumbnailWrapper>
                    ) : (
                      <ImageThumbnail
                        alt={item.filename}
                        src={cloudinaryFormat({
                          type: "image",
                          height: 80,
                          mode: "scale",
                          filename: item.filename,
                          format: "jpg"
                        })}
                        onClick={this.openImagesModal}
                      />
                    )}
                  </div>
                ))}
              </SelectedImageDisplay>
              {/* Error message for this input */}
              <InputError>{this.state.errMessages.noImages}</InputError>
            </Card>
            <Card>
              <CardTitle bigMargin>
                <Title>Ad Content</Title>
              </CardTitle>
              <LargeFiftyTwoWrapper>
                <FiftyTwo id='edit-content'>
                  <Subtitle>Copy</Subtitle>
                  <small>Enter text for your ad.</small>
                  <div style={{ marginTop: "2rem" }}></div>
                  <InputContainer customWidth={"20rem"}>
                    <InputLabel>Website Address</InputLabel>
                    <InputBoxWrapper>
                      <InputBox
                        type='text'
                        name='website'
                        value={website}
                        onChange={input => this.handleContentChange(input)}
                        onBlur={this.validateWebsite}
                      />
                    </InputBoxWrapper>
                    {/* Error message for this input */}
                    <InputError>{this.state.errMessages.noWebsite}</InputError>
                  </InputContainer>

                  <InputContainer customWidth={"20rem"}>
                    <InputLabel>Headline</InputLabel>
                    <InputBoxWrapper>
                      <InputBox
                        type='text'
                        name='headline'
                        value={headline}
                        onChange={input => this.handleContentChange(input)}
                        onBlur={input =>
                          this.handleSingleInputValidation(input)
                        }
                      />
                    </InputBoxWrapper>
                    {/* Error message for this input */}
                    <InputError>{this.state.errMessages.noHeadline}</InputError>
                  </InputContainer>
                  <InputContainer customWidth={"20rem"}>
                    <InputLabel>Text</InputLabel>
                    <InputBoxWrapper>
                      <TextArea
                        name='body'
                        value={body}
                        onChange={input => this.handleContentChange(input)}
                        onBlur={input =>
                          this.handleSingleInputValidation(input)
                        }
                      />
                    </InputBoxWrapper>
                    {/* Error message for this input */}
                    <InputError>{this.state.errMessages.noText}</InputError>
                  </InputContainer>
                  <InputContainer customWidth={"20rem"}>
                    <InputLabel>Call to Action</InputLabel>
                    <InputBoxWrapper>
                      <Dropdown
                        type='text'
                        name='callToAction'
                        value={callToAction}
                        onChange={input => this.handleContentChange(input)}
                      >
                        <option value='' defaultValue>
                          Select One
                        </option>
                        {Object.keys(callsToAction).map(cta => (
                          <option key={cta} value={cta}>
                            {callsToAction[cta]}
                          </option>
                        ))}
                      </Dropdown>
                    </InputBoxWrapper>
                    {/* Error message for this input */}
                    <InputError>
                      {this.state.errMessages.noCallToAction}
                      {this.state.errMessages.wrongCallToAction}
                    </InputError>
                  </InputContainer>
                  <InputContainer customWidth={"20rem"}>
                    <InputLabel>Link Description</InputLabel>
                    <InputBoxWrapper>
                      <TextArea
                        name='newsFeedLinkDesc'
                        value={newsFeedLinkDesc}
                        onChange={input => this.handleContentChange(input)}
                        onBlur={input =>
                          this.handleSingleInputValidation(input)
                        }
                      />
                    </InputBoxWrapper>
                    {/* Error message for this input */}
                    <InputError>
                      {this.state.errMessages.noNewsFeedLinkDescription}
                    </InputError>
                  </InputContainer>
                  <MyTemplates
                    templateId={templateId}
                    handleTemplateCallback={this.handleTemplateCallback}
                    userProfile={userProfile}
                    myTemplates={myTemplates}
                    stockTemplates={stockTemplates}
                    alert={this.props.alert}
                  />
                </FiftyTwo>
                <FiftyTwo top id='ad-preview'>
                  <Subtitle>Preview</Subtitle>
                  <div>
                    <AdPreview
                      pendingCampaign={pendingCampaign}
                      callToAction={callsToAction[callToAction]}
                      userProfile={this.props.userProfile}
                      userCompany={this.props.userCompany}
                      businessPage={this.props.businessPage}
                    />
                  </div>
                </FiftyTwo>
              </LargeFiftyTwoWrapper>
              <ButtonWrapper>
                <SubmitButton type='button' onClick={this.handlePreviousButton}>
                  {`<- Back`}
                </SubmitButton>
                {type === "LeadAd" ? (
                  <div>
                    <SubmitButton
                      id='submit-ad'
                      type='button'
                      onClick={this.handleNextButton}
                    >
                      Next -{`>`}
                    </SubmitButton>
                  </div>
                ) : (
                  <div>
                    <SubmitButton
                      solid
                      id='submit-ad'
                      onClick={this.openCampaignNameModal}
                      disabled={showSubmitWaiting}
                    >
                      Submit {showSubmitWaiting && <LoaderDotsSmall />}
                    </SubmitButton>
                  </div>
                )}
              </ButtonWrapper>
            </Card>
            <Modal
              isOpen={this.state.previewIsOpen}
              shouldCloseOnOverlayClick={false}
              shouldCloseOnEsc={false}
              style={customStyles}
              contentLabel='Video Preview'
              appElement={document.getElementById("root")}
            >
              <video style={{ maxWidth: "100%", maxHeight: "63vh" }} controls>
                <source
                  src={`${process.env.REACT_APP_CLOUDINARY_VIDEO_BASEURL}/upload/${this.state.previewItem}`}
                  type='video/webm'
                />
                <source
                  src={`${process.env.REACT_APP_CLOUDINARY_VIDEO_BASEURL}/upload/${this.state.previewItem}`}
                  type='video/mp4'
                />
                <source
                  src={`${process.env.REACT_APP_CLOUDINARY_VIDEO_BASEURL}/upload/${this.state.previewItem}`}
                  type='video/ogg'
                />
                Your browser does not support this video type
              </video>
              <PreviewModalWrapper>
                <Button type='button' onClick={this.handlePreviewClose}>
                  close
                </Button>
              </PreviewModalWrapper>
            </Modal>
            <BpModal
              open={modalExistingCampaign}
              title={`You have an existing ${this.state.pendingCampaign.type} campaign`}
              primaryAction={{
                btnLabel: "Continue",
                action: this.handleContinue
              }}
              secondaryAction={{
                btnLabel: "Start Over",
                action: this.handleStartOver
              }}
              shouldCloseOnOverlayClick={false}
              shouldCloseOnEsc={false}
              contentLabel='Existing Campaign'
              body={<p>Would you like to continue with this campaign?</p>}
              width='auto'
            />
            <BpModal
              open={this.state.nameModalIsOpen}
              title='Campaign Name'
              primaryAction={{
                btnLabel: "Submit",
                action: this.handleCampaignNameSave
              }}
              secondaryAction={{
                btnLabel: "Cancel",
                action: () => {
                  this.setState({ nameModalIsOpen: false });
                }
              }}
              shouldCloseOnOverlayClick={false}
              shouldCloseOnEsc={false}
              contentLabel='Campaign Name Modal'
              body={
                <div>
                  <p>Please give this campaign a name.</p>
                  <TemplateNameInput
                    type='text'
                    name='campaignName'
                    value={campaignName}
                    onChange={input =>
                      this.setState({
                        campaignName: input.target.value
                      })
                    }
                  />
                  <div>
                    <InputError>
                      {this.state.errMessages.noCampaignName}
                    </InputError>
                  </div>
                </div>
              }
              width='auto'
            />
            <BpModal
              open={this.state.modalFailedPayments}
              title={`You have failed payments`}
              primaryAction={{
                btnLabel: "Fix",
                action: () =>
                  this.props.history.push({ pathname: "/member-settings" })
              }}
              secondaryAction={{
                btnLabel: "Close",
                action: () => this.setState({ modalFailedPayments: false })
              }}
              onAfterOpen={this.afterOpenModal}
              shouldCloseOnOverlayClick={false}
              shouldCloseOnEsc={false}
              contentLabel='Failed Payments'
              body={
                <p>
                  Please fix your failed payments before submitting your ad.
                  Clicking Fix will take you to your Member Settings where you
                  can update your credit card or try again with the card on
                  file.
                </p>
              }
              width='auto'
            />
          </div>
        )}
        {this.state.redirectStartOver && (
          <Redirect to='/campaign-select' push />
        )}
        {this.state.redirectToArea && <Redirect to='/area-campaign' push />}
      </div>
    );
  }
}

export default CreateAd;
