import styled from "styled-components";

const InputError = styled.div`
  color: #ea6262;
  background-color: rgba(237, 41, 57, 0.1);
  display: inline-block;
  border-radius: 6px;
  padding: 0.5em 1em;
  font-size: 14px;
  font-weight: 400;

  :empty {
    display: none;
  }
`;

export default InputError;
