import styled from "styled-components";

const FiftyTwo = styled.div`
  margin: 0 20px;
  text-align: ${props => (props.left ? "left" : "")};

  @media screen and (max-width: 560px) {
    text-align: center;

    /* h4 {
			text-align: center;
		} */
  }

  @media screen and (max-width: 836px) {
    margin-top: ${props => (props.top ? "4em" : "")};
  }
`;

export default FiftyTwo;
