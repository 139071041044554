import {
  FormDescription,
  FormWrapper,
  InputBox,
  InputBoxWrapper,
  InputContainer,
  InputLabel
} from "../../../styled/Input";

export const JobNimbusCoreSettings = props => {
  const { integration } = props;

  return (
    <FormWrapper single>
      <InputContainer slimHeight customWidth='100%'>
        <InputLabel>API Token</InputLabel>
        <InputBoxWrapper>
          <InputBox
            type='text'
            name='API_Token'
            readOnly
            value={integration.API_Token}
          />
        </InputBoxWrapper>
      </InputContainer>
      <FormDescription>
        Please contact your Support Representative to update these values.
      </FormDescription>
    </FormWrapper>
  );
};

export const JobNimbusOptionalSettings = props => {
  const { integration } = props;

  return (
    <FormWrapper>
      <InputContainer customWidth='100%'>
        <InputLabel>Workflow Name</InputLabel>
        <InputBox
          type='text'
          name='record_type_name'
          readOnly
          value={integration.record_type_name}
        />
      </InputContainer>
      <InputContainer customWidth='100%'>
        <InputLabel>Status Name</InputLabel>
        <InputBox
          type='text'
          name='status_name'
          readOnly
          value={integration.status_name}
        />
      </InputContainer>
      <InputContainer customWidth='100%'>
        <InputLabel>Source Name</InputLabel>
        <InputBox
          type='text'
          name='source_name'
          readOnly
          value={integration.source_name}
        />
      </InputContainer>
      <FormDescription>
        Please contact your Support Representative to update these values.
      </FormDescription>
    </FormWrapper>
  );
};
