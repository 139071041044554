import React from "react";
import styled from "styled-components";
import * as _ from "underscore";
import { getInitials } from "../../../utils/Helpers";
import { Button } from "../../styled/Buttons";

const ContactNameHeader = styled.div`
  border-bottom: 1px solid ${props => props.theme.Border};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  z-index: 20;
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
    0 1px 3px 1px rgba(60, 64, 67, 0.16);
  .arrow-icon {
    @media only screen and (min-width: 901px) {
      display: none;
    }
  }
  .contact-name {
    display: flex;
    align-items: center;
  }
`;

const ContactName = styled.div`
  @media (max-width: 900px) {
    display: flex;
    align-items: center;
  }
`;

const ContactInitials = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  font-size: 1em;
  border-radius: 50%;
  background: ${props => props.theme.TextC};
  color: ${props => props.theme.BGC};
  font-weight: bold;
  margin: 0 10px 0 0;
`;

const Icons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const MoreIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const MoreIconTrigger = styled.span`
  color: ${props => props.theme.TextC};
  margin-right: 10px;
  cursor: pointer;
`;

const ActionList = styled.div`
  z-index: 1003;
  display: ${props => (props.visible ? "block" : "none")};
  position: absolute;
  right: 0;
  top: 30px;
  background: white;
  border: 1px solid ${props => props.theme.Border};
  border-radius: 5px;
  padding: 5px 10px;
  min-width: 170px;
  &:after {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
    position: absolute;
    top: -10px;
    right: 11px;
  }
  &:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 11px solid ${props => props.theme.Border};
    position: absolute;
    top: -11px;
    right: 10px;
  }
`;

const Action = styled.li`
  border-bottom: 1px solid ${props => props.theme.ATextC};
  padding: 6px 0;
  cursor: pointer;
  &:last-of-type {
    border: none;
  }
`;

const AccountLink = styled.a`
  font-size: 35px;
  color: ${props => props.theme.TextC};
`;

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.03);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1001;
  cursor: pointer;
`;

const MessageHeader = props => {
  const { contactId, contact, contactPhone, showSelectors, selected, contactView, backPage } = props;

  const [contactName, setContactName] = React.useState('');
  const [showMoreOptions, setShowMoreOptions] = React.useState(false);

  React.useEffect(() => {
    const {firstName, lastName} = contact;
    if (firstName) {
      setContactName(firstName, lastName);
    } else {
      setContactName(contactPhone);
    }
  }, [contact, contactPhone]);

  const toggleActions = () => {
    setShowMoreOptions(!showMoreOptions);
  }

  const deleteConversation = () => {
    setShowMoreOptions(false);
    props.deleteConversation();
  }

  const toggleSelectors = () => {
    toggleActions();
    props.toggleSelectors();
  }

  return (
    <ContactNameHeader>
      <ContactName>
        {!props.embedded ? (
          <span
            className='material-icons arrow-icon'
            onClick={props.unLoadContact}
          >
            arrow_back_ios
          </span>
        ) : null}
        {contactId && !_.isEmpty(contact) ? (
          <span className='contact-name'>
            <ContactInitials>{getInitials(contact)}</ContactInitials>
            {contactName}
          </span>
        ) : (
          <span className='contact-name'>{contactPhone}</span>
        )}
      </ContactName>
      <Icons>
        {!!showSelectors && (
          <Button
            onClick={toggleSelectors}
            small
          >
            Cancel
          </Button>
        )}
        {selected.length > 0 && showSelectors && (
          <Button small solid onClick={deleteConversation}>
            Delete Selected
          </Button>
        )}
        <MoreIcon>
          <MoreIconTrigger
            className='material-icons'
            onClick={toggleActions}
          >
            more_horiz
          </MoreIconTrigger>
          <ActionList visible={showMoreOptions}>
            <ul>
              <Action onClick={deleteConversation}>
                Delete conversation
              </Action>
              <Action onClick={toggleSelectors}>
                Delete selected
              </Action>
            </ul>
          </ActionList>
          {!!showMoreOptions && (
            <Overlay onClick={toggleActions} className='overlay' />
          )}
        </MoreIcon>
        {contactId && !_.isEmpty(contact) && !contactView && (
          <AccountLink
            className='material-icons'
            href={backPage}
          >
            account_circle
          </AccountLink>
        )}
      </Icons>
    </ContactNameHeader>
  )
};

export default MessageHeader;
