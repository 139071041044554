import styled from "styled-components";

const ModalLargeWrapper = styled.div`
  height: 33em;
  width: 50em;
  max-width: 100vw;
  background-color: white;
  position: relative;
  max-height: 100vh;

  @media screen and (max-width: 580px) {
    width: 100vw;
  }
`;

export default ModalLargeWrapper;
