import React from "react";
import styled from "styled-components";
import ImageMenuWrapper from "../styled/ImageMenuWrapper";

const UploadBtn = styled.div`
  @media screen and (max-width: 580px) {
    display: none;
  }
`;

const ImageModalHeader = props => {
  const { menuSelection } = props;

  return (
    <ImageMenuWrapper>
      <div
        id='uploadImages'
        className={
          menuSelection === "uploadImages" ? "selected button" : "button"
        }
        onClick={() => props.setMenu("uploadImages")}
      >
        <button>
          <h2>Upload</h2>
          <small>Add images to your gallery</small>
        </button>
      </div>
      <div
        id='myImages'
        className={menuSelection === "myImages" ? "selected button" : "button"}
      >
        <button onClick={() => props.setMenu("myImages")}>
          <h2>Gallery</h2>
          <small>Browse your images</small>
        </button>
      </div>
      <div
        id='freeImages'
        className={
          menuSelection === "freeImages" ? "selected button" : "button"
        }
      >
        <button onClick={() => props.setMenu("freeImages")}>
          <h2>Stock</h2>
          <small>Royalty free images</small>
        </button>
      </div>
    </ImageMenuWrapper>
  );
};

export default ImageModalHeader;
