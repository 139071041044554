import React from "react";

import {
  BusinessPageImage,
  BusinessPageName,
  ContextDescription,
  CustomField,
  CustomLabel,
  FormCard,
  Headline,
  HeadlineImage,
  Input,
  PageImageWrapper,
  PreviewContainer,
  SubmitButton
} from "../../styled/LeadAdPreview";

const LeadAdPreviewContextCard = props => {
  const { fields = [], headline, description, coverImage, pageImage } = props;
  const [pageName, setPageName] = React.useState(props.pageName);
  const [formFields, setFormFields] = React.useState(fields);

  React.useEffect(() => {
    if (!props.pageName) {
      setPageName("Preview Only. Please relink your Business Page");
    }
  }, [pageName, props.pageName]);

  React.useEffect(() => {
    const newFormFields = fields.filter(f => f.type === "CUSTOM");
    setFormFields(newFormFields);
  }, [fields]);

  return (
    <PreviewContainer className='context-card'>
      {coverImage && <HeadlineImage src={coverImage} alt={headline} />}
      <FormCard contextTitle>
        {pageImage && (
          <PageImageWrapper>
            <BusinessPageImage src={pageImage} alt={pageName} />
          </PageImageWrapper>
        )}
        <BusinessPageName>{pageName}</BusinessPageName>
        {headline ? (
          <Headline>{headline}</Headline>
        ) : (
          <Headline>Please enter a headline</Headline>
        )}
        {description ? (
          <ContextDescription>{description}</ContextDescription>
        ) : (
          <ContextDescription>Please enter a description</ContextDescription>
        )}
      </FormCard>
      {formFields.map((field, index) => (
        <FormCard key={index}>
          <CustomField key={index}>
            <CustomLabel>{field.name}</CustomLabel>
            <Input type='text' placeholder='Enter your answer.' disabled />
          </CustomField>
        </FormCard>
      ))}
      <SubmitButton>Next</SubmitButton>
    </PreviewContainer>
  );
};

export default LeadAdPreviewContextCard;
