import React from "react";
// import { useRouteMatch } from 'react-router-dom';
import styled from "styled-components";
import { LoaderDotsSmall } from "../../elements/Loaders";

// Styled Components
import { Button } from "../../styled/Buttons";
import Card from "../../styled/Card";
import { InputContainer, InputBoxWrapper, TextArea } from "../../styled/Input";
import { monthsAbr } from "../../../utils/Helpers";

const ContactCard = styled.div`
  margin: 0;
  padding: 1em;
  @media only screen and (min-width: 901px) {
    background-color: ${props => props.theme.BGC};
  }
`;

const Note = styled.div`
  text-align: left;
  margin: 0;
  padding-bottom: 25px;
  border-bottom: 1px solid #ccc;
  &:last-child {
    border: none;
  }
  @media only screen and (min-width: 901px) {
    margin: 50px 60px;
  }
`;

const NoteDate = styled.div`
  font-size: 0.8em;
  font-weight: bold;
  margin-bottom: 10px;
  color: ${props => props.theme.HC1};
`;

const NoteInputContainer = styled(InputContainer)`
  width: auto;
  margin: 28px 59px 2.5em;
  padding-bottom: 25px;
  border-bottom: ${props =>
    props.notesExist ? `1px solid ${props.theme.HC1}` : "none"};
  @media only screen and (max-width: 500px) {
    margin: 0 40px 2.5em;
  }
  @media only screen and (max-width: 500px) {
    margin: 0 15px 2.5em;
  }
`;

const BtnWrapper = styled.div`
  text-align: center;
`;

const ContactNotes = props => {
  const { contact = {} } = props;
  const [savingNote, setSavingNote] = React.useState(false);
  const [newNote, setNewNote] = React.useState("");
  const [notes, setNotes] = React.useState([]);

  React.useEffect(() => {
    if (contact.notes && contact.notes.length > 0) {
      const { notes: contactNotes } = contact;
      contactNotes.sort((a, b) => {
        if (a.created === b.created) {
          return a.value - b.value;
        } else {
          // we want them sorted in reverse order with most recent on top.
          const bTime = new Date(b.created).getTime();
          const aTime = new Date(a.created).getTime();
          return bTime - aTime;
        }
      });
      setNotes(contactNotes);
    }
  }, [contact]);

  const saveNote = async () => {
    const created = new Date();
    const newNotes = [
      ...notes,
      {
        created: created.toISOString(),
        value: newNote
      }
    ];
    if (newNotes) {
      try {
        setSavingNote(true);
        console.log("contact valid");
        props.updateContact({ ...contact, notes: newNotes });

        setNewNote("");
        setSavingNote(false);
      } catch (err) {
        console.log("error updating contact", err);
      }
    }
  };

  const getFormattedDate = (date, includeTime) => {
    const dateMonthNum = date.getMonth();
    const dateMonthText = monthsAbr[dateMonthNum];
    const dateFormatted = `${dateMonthText} ${date.getDate()}, ${date.getFullYear()}`;
    if (includeTime) {
      let dateHours = date.getHours();
      const timePeriod = dateHours > 12 ? "PM" : "AM";
      if (dateHours > 12) {
        dateHours = dateHours - 12;
      }
      const dateMinutes = date.getMinutes();
      const dateString = date.toString();
      const dateParts = dateString.split(/[/(/)]/);
      const timeZoneStrings = dateParts[1].split(" ");
      let timeZone = "";
      timeZoneStrings.forEach(
        zoneString => (timeZone = `${timeZone}${zoneString.charAt(0)}`)
      );
      return `${dateFormatted} @ ${dateHours}:${dateMinutes} ${timePeriod} ${timeZone}`;
    } else {
      return dateFormatted;
    }
  };

  return (
    <ContactCard>
      <NoteInputContainer notesExist={notes && notes.length > 0}>
        <NoteDate className='notes-container'>
          On {getFormattedDate(new Date())}, you wrote:
        </NoteDate>
        <InputBoxWrapper>
          <div>
            <TextArea
              type='text'
              name='addNote'
              rows='5'
              value={newNote}
              placeholder='Begin typing...'
              onChange={input => setNewNote(input.target.value)}
            />
          </div>
        </InputBoxWrapper>
        <BtnWrapper>
          <Button onClick={saveNote} disabled={savingNote}>
            Add Note {savingNote && <LoaderDotsSmall />}
          </Button>
        </BtnWrapper>
      </NoteInputContainer>
      {!!notes && (
        <div>
          {notes.map(note => (
            <Note key={`${note.created}${note.value.substring(0, 3)}`}>
              <NoteDate>
                {`On ${getFormattedDate(
                  new Date(note.created),
                  true
                )}, you wrote:`}
              </NoteDate>
              <p>{note.value}</p>
            </Note>
          ))}
        </div>
      )}
    </ContactCard>
  );
};

export default ContactNotes;
