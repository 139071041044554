import moment from "moment-timezone";
import React from "react";
import { usePagination, useSortBy, useTable } from "react-table";
import styled from "styled-components";
import * as _ from "underscore";
import { LoaderDotsSmall } from "../../elements/Loaders";
import DateRangePickerWrapper from "../../elements/PresetDateRangePicker";
import { Button } from "../../styled/Buttons";
import LoadingCircle from "../../styled/LoadingCircle";
import media from "../../styled/MediaQueries";
import { stripeGetInvoices } from "../../../utils/StripeAPI";

const ResetButton = styled.span`
  display: inline-block;
  margin-left: 15px;
  cursor: pointer;
  ${media.phone`
    margin: 10px 0 0 20px;
  `}
`;

const TableWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  table {
    border-collapse: collapse;
  }
  .invoice-num {
    width: 175px;
    margin-left: 25px;
  }
  .status {
    width: 110px;
  }
  .due-date,
  .date-paid {
    width: 90px;
  }
  .next-attempt {
    width: 230px;
  }
  .amount {
    width: 160px;
    .cell-value {
      display: block;
    }
  }
  .payment-failed {
    background-color: #fee8e9;
  }
  .refund {
    background-color: ${props => props.theme.HCSuc};
    min-height: 85px;
  }
  ${media.phone`
    .invoice-num,
    .status, 
    .due-date,
    .date-paid,
    .next-attempt, 
    .amount {
      width: auto;
    }
    .invoice-num {
      margin-left: 0;
    }
  `}
`;

const TableScrollContainer = styled.div`
  max-height: 31vh;
  overflow: scroll;
`;

const FailedLink = styled(Button)`
  margin: 5px;
`;

const TableBody = styled.tbody`
  @media only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    display: block;
  }
`;

const TableHead = styled.thead`
  @media only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    display: block;
    tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
  }
`;

const TableRow = styled.tr`
  padding: 10px 0;
  border-top: ${props =>
    props.zebrastripe === "odd" ? "1px solid #e4e4e4" : "none"};
  border-bottom: ${props =>
    props.zebrastripe === "odd" ? "1px solid #e4e4e4" : "none"};
  background-color: ${props =>
    props.zebrastripe === "odd" ? "#f8f8f8" : "transparent"};
  @media only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    display: block;
  }
`;

const HeaderCell = styled.td`
  margin: 10px 0;
  color: ${props => props.theme.HC2};
  text-align: center;
`;

const BodyCell = styled.td`
  margin: 10px 0;
  text-align: center;
  padding: 10px;
  @media only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    display: ${props => (props.additionalInfo ? "block" : "grid")};
    grid-template-columns: 2fr 3fr;
    grid-gap: 10px;
    padding: 0;
    > span {
      padding: ${props => (props.additionalInfo ? "0" : "0 10px")};
    }
  }
`;

const AmountSpan = styled.span`
  display: block;
  min-width: 185px;
`;

const ResponsiveDesc = styled.span`
  display: none;
  text-align: left;
  color: ${props => props.theme.HC2};
  @media only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    display: ${props => (props.additionalInfo ? "none" : "block")};
  }
`;

const CellValue = styled.span`
  text-align: center;
  @media only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    text-align: right;
  }
`;

function PaymentInfo(vars) {
  const {
    status,
    id,
    custUpdateLoading,
    handleInvoicePaymentFixId,
    handlePayInvoiceClick
  } = vars;
  return status === "failed" ? (
    <span>
      <FailedLink
        onClick={() => {
          handleInvoicePaymentFixId(id);
        }}
        small
        solid
      >
        Update credit card
      </FailedLink>
      <FailedLink
        disabled={custUpdateLoading}
        onClick={() => {
          handlePayInvoiceClick(id);
        }}
        small
        solid
      >
        Retry with default card
        {custUpdateLoading && <LoaderDotsSmall />}
      </FailedLink>
    </span>
  ) : null;
}

function TableData({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }
    },
    useSortBy,
    usePagination
  );

  return (
    <div>
      <div className='pagination'>
        {pageCount > 1 && (
          <span>
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {"<<"}
            </button>{" "}
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {"<"}
            </button>{" "}
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              {">"}
            </button>{" "}
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </button>
          </span>
        )}
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
      </div>
      <table {...getTableProps()}>
        <TableHead>
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <HeaderCell
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className='material-icons'>
                          keyboard_arrow_down
                        </span>
                      ) : (
                        <span className='material-icons'>
                          keyboard_arrow_up
                        </span>
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </HeaderCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            const classes = [];
            const { status = "", amount: { refunded = 0 } = {} } = row.values;
            if (status === "failed") {
              classes.push("payment-failed");
            }
            if (refunded > 0) {
              classes.push("refund");
            }
            return (
              <TableRow
                {...row.getRowProps()}
                zebrastripe={i % 2 ? "even" : "odd"}
                className={classes}
              >
                {row.cells.map(cell => (
                  <BodyCell
                    {...cell.getCellProps()}
                    additionalInfo={
                      cell.column.id === "additionalInfo" ? true : false
                    }
                  >
                    <ResponsiveDesc
                      className='header'
                      additionalInfo={
                        cell.column.id === "additionalInfo" ? true : false
                      }
                    >
                      {cell.column.Header}
                    </ResponsiveDesc>
                    <CellValue>{cell.render("Cell")}</CellValue>
                  </BodyCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </table>
    </div>
  );
}

function RecentTransactionsTable(props) {
  const {
    transactions,
    custUpdateLoading,
    handleInvoicePaymentFixId,
    handlePayInvoiceClick,
    timezone
  } = props;
  const headers = [
    { Header: "Invoice Number", accessor: "number" },
    { Header: "Status", accessor: "status" },
    { Header: "Due Date", accessor: "invoiceDueDate" },
    {
      Header: "Amount",
      accessor: "amount",
      disableSortBy: true,
      // eslint-disable-next-line react/display-name
      Cell: cellInfo => (
        <span>
          Paid: {cellInfo.value && cellInfo.value.paid} <br />
          {cellInfo.value && cellInfo.value.refunded > 0 && (
            <AmountSpan>
              Refunded: {cellInfo.value && cellInfo.value.refunded}
            </AmountSpan>
          )}
          {cellInfo.value.due > 0 && (
            <AmountSpan>Due: {cellInfo.value.due}</AmountSpan>
          )}
          {cellInfo.value &&
            cellInfo.value.total > 0 &&
            cellInfo.value.total !== cellInfo.value.paid && (
              <AmountSpan>Total: {cellInfo.value.total}</AmountSpan>
            )}
        </span>
      )
    },
    { Header: "Date Paid", accessor: "invoicePaidDate" },
    {
      Header: "",
      accessor: "other",
      disableSortBy: true,
      Cell: cellInfo =>
        PaymentInfo({
          status: cellInfo.value.status,
          id: cellInfo.value.id,
          custUpdateLoading,
          handleInvoicePaymentFixId,
          handlePayInvoiceClick
        })
    }
  ];
  const tableData = transactions.map(line => {
    const { amountDue, amountPaid, amountRemaining, dueDate, paidAt } = line;
    const dueDateMoment = moment(dueDate).tz(timezone);
    const invoiceDueDate = dueDateMoment.format("MM/DD/YYYY");
    const paidDateMoment = moment(paidAt).tz(timezone);
    const invoicePaidDate = paidDateMoment.format("MM/DD/YYYY");
    let status = line.status;
    if (
      line.paymentIntent &&
      !_.isEmpty(line.paymentIntent.last_payment_error) &&
      status === "open"
    ) {
      status = "failed";
    }
    return {
      number: line.invoiceId,
      status,
      invoiceDueDate,
      amount: {
        paid: (amountPaid / 100).toFixed(2),
        due: (amountRemaining / 100).toFixed(2),
        refunded: 0,
        total: (amountDue / 100).toFixed(2)
      },
      invoicePaidDate,
      other: { status, id: line.invoiceId }
    };
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = React.useMemo(() => tableData, [transactions]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = React.useMemo(() => headers, [transactions]);

  return TableData({ columns, data });
}

const StripeRecentTransactions = props => {
  const { stripeId, timezone, startDate, endDate, custUpdateLoading } = props;

  const [startingDate, setStartingDate] = React.useState(startDate);
  const [endingDate, setEndingDate] = React.useState(endDate);
  const [starting_after, setStartingAfter] = React.useState("");
  const [ending_before, setEndingBefore] = React.useState("");
  const [transactionsHasMoreNext, setTransactionsHasMoreNext] =
    React.useState(false);
  const [transactionsHasMorePrev, setTransactionsHasMorePrev] =
    React.useState(false);
  const [invoices, setInvoices] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [direction, setDirection] = React.useState(0); // positive = next, negative = prev
  const [transactionsPerPage, setTransactionsPerPage] = React.useState(10);

  React.useEffect(() => {
    const a = async () => {
      setLoading(true);
      console.log("refreshing");
      const params = {
        stripeId,
        limit: transactionsPerPage,
        expand: "data.charge,data.payment_intent",
        gte: moment(startingDate).unix(),
        lte: moment(endingDate).unix(),
        ...(direction > 0
          ? { direction: "next", starting_after }
          : direction < 0
          ? { direction: "prev", ending_before }
          : {})
      };
      const invoiceResponse = await stripeGetInvoices(params);
      console.log("invoiceresponse", invoiceResponse);
      const { invoices: invoiceRes = [], hasMore = false } = invoiceResponse;
      setInvoices(invoiceRes);
      if (invoiceRes.length > 0) {
        const firstInvoice = invoiceRes[0];
        setEndingBefore(firstInvoice.invoiceId);
        if (invoiceRes.length > 1) {
          const lastInvoice = invoiceRes[invoiceRes.length - 1];
          setStartingAfter(lastInvoice.invoiceId);
        }
        if (direction === 0) {
          setTransactionsHasMoreNext(hasMore);
          setTransactionsHasMorePrev(false);
        }
        if (direction > 0) {
          setTransactionsHasMoreNext(hasMore);
          setTransactionsHasMorePrev(true);
        } else if (direction < 0) {
          setTransactionsHasMoreNext(true);
          setTransactionsHasMorePrev(hasMore);
        }
      }
      setLoading(false);
    };
    a();
  }, [
    direction,
    endingDate,
    ending_before,
    startingDate,
    starting_after,
    stripeId,
    transactionsPerPage,
    custUpdateLoading
  ]);

  const dateRangeClose = (startDateSelected, endDateSelected) => {
    setStartingDate(startDateSelected.toISOString());
    if (endDateSelected) {
      setEndingDate(endDateSelected.toISOString());
    } else {
      setEndingDate(startDateSelected.toISOString());
    }
    setLoading(true);
  };

  const resetDateRange = () => {
    setStartingDate(moment().subtract(2, "weeks").toISOString());
    setEndingDate(moment().toISOString());
  };

  const showNext = () => {
    setDirection(1);
    setLoading(true);
  };

  const showPrev = () => {
    setDirection(-1);
    setLoading(true);
  };

  return loading ? (
    <LoadingCircle />
  ) : (
    <TableWrapper>
      <div className='DateRangePicker'>
        <DateRangePickerWrapper
          className='DateRangePickerWrapper'
          onClose={(startDateSelected, endDateSelected) =>
            dateRangeClose(startDateSelected, endDateSelected)
          }
          initStartDate={startingDate}
          initEndDate={endingDate}
        />
        <ResetButton onClick={resetDateRange}>Reset</ResetButton>
      </div>
      {invoices.length > 0 ? (
        <TableScrollContainer>
          <RecentTransactionsTable
            transactions={invoices}
            timezone={timezone}
            transactionsHasMoreNext={transactionsHasMoreNext}
            transactionsHasMorePrev={transactionsHasMorePrev}
            handleInvoicePaymentFixId={props.handleInvoicePaymentFixId}
            handlePayInvoiceClick={props.handlePayInvoiceClick}
          />
          <div className='pagination'>
            {transactionsHasMoreNext ||
              (transactionsHasMorePrev && (
                <span>
                  {/* <button onClick={() => gotoPage(0)} disabled={!transactionsHasMorePrev}>
              {'<<'}
            </button>{' '} */}
                  <button
                    onClick={showNext}
                    disabled={!transactionsHasMorePrev}
                  >
                    {"<"}
                  </button>{" "}
                  <button
                    onClick={showPrev}
                    disabled={!transactionsHasMoreNext}
                  >
                    {">"}
                  </button>{" "}
                  {/* <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!transactionsHasMoreNext}
            >
              {'>>'}
            </button> */}
                </span>
              ))}
            <select
              value={transactionsPerPage}
              onChange={e => {
                setTransactionsPerPage(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map(pageSizeOption => (
                <option key={pageSizeOption} value={pageSizeOption}>
                  Show {pageSizeOption}
                </option>
              ))}
            </select>
          </div>
        </TableScrollContainer>
      ) : (
        <div>No invoices for selected period</div>
      )}
    </TableWrapper>
  );
};

export default StripeRecentTransactions;
