import React from "react";
import styled from "styled-components";
import { cloudinaryFormat } from "../../utils/MediaAPI";
import LoadingBar from "../elements/LoadingBar";
import ImageWrapper from "../styled/ImageWrapper";

const SelectableImageWrapper = styled.div`
  position: relative;
  img {
    height: 6em;
    margin: 1em;
    cursor: pointer;
    box-shadow: ${props =>
      props.selected ? "0px 0px 2px 5px #fbb74c" : "none"};
  }
`;

const StockImagesPane = props => {
  const { show, freeImages, selectedImages, loading } = props;

  const handleStockImagesSelected = async image => {
    const newImage = {
      name: image.public_id,
      format: image.format,
      type: "IMAGE",
      height: image.height,
      width: image.width
    };
    const imageFilenames = selectedImages.map(i =>
      i.name ? i.name : i.public_id
    );
    if (imageFilenames.includes(newImage.name)) {
      console.log("already selected");
      props.handleImagesSelected(
        selectedImages.filter(i => {
          if (i.name) {
            return i.name !== newImage.name;
          } else {
            return i.public_id !== image.public_id;
          }
        })
      );
    } else {
      props.handleImagesSelected([...selectedImages, newImage]);
    }
  };

  return (
    show && (
      <ImageWrapper>
        {/* displaying of all the stock images stored in state.cloudinaryFreeImages */}
        {loading ? (
          <LoadingBar />
        ) : (
          freeImages.map(data => (
            <SelectableImageWrapper
              publicId={`${data.public_id}.${data.format}`}
              key={data.public_id}
              selected={selectedImages.some(
                image =>
                  image.name === data.public_id ||
                  image.public_id === data.public_id
              )}
            >
              <img
                src={cloudinaryFormat({
                  filename: data.public_id,
                  format: data.format,
                  height: 96,
                  width: 144,
                  mode: "fill"
                })}
                onClick={() => handleStockImagesSelected(data)}
                alt='Selectable'
              />
            </SelectableImageWrapper>
          ))
        )}
      </ImageWrapper>
    )
  );
};

export default StockImagesPane;
