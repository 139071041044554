import styled from "styled-components";

const H3 = styled.h3`
  margin: 90px auto 25px;
  text-transform: ${props => (props.uppercase ? "uppercase" : "")};
  color: ${props => props.theme.HC1};
  font-weight: 600;
  font-size: 34px;
`;
export default H3;
