import React from "react";
import styled from "styled-components";

// Images
import circleArrowLt from "../images/circle_arrow_lt.png";
import circleArrowRt from "../images/circle_arrow_rt.png";
import PlaceholderImg from "../images/facebook_placeholder.png";
import LogoPlaceholder from "../images/Logo_Placeholder.png";

// Styled Components
import {
  processHeadline,
  processLinkDesc,
  processText,
  processUrl
} from "../utils/Helpers";
import { cloudinaryFormat } from "../utils/MediaAPI";
import FbPreviewCompanyName from "./styled/FbPreviewCompanyName";
import FbPreviewCTA from "./styled/FbPreviewCTA";
import FbPreviewCtaWrapper from "./styled/FbPreviewCtaWrapper";
import FbPreviewHeadline from "./styled/FbPreviewHeadline";
import FbPreviewMore from "./styled/FbPreviewMore";
import FbPreviewNewsfeedLinkDesc from "./styled/FbPreviewNewsfeedLinkDesc";
import FbPreviewSponsoredAd from "./styled/FbPreviewSponsoredAd";
import FbPreviewText from "./styled/FbPreviewText";
import FbPreviewTitleWrapper from "./styled/FbPreviewTitleWrapper";
import FbPreviewWebsite from "./styled/FbPreviewWebsite";
import Yellow from "./styled/YellowColor";

const Preview = styled.div`
  min-height: 28em;
  width: 23em;
  max-width: 85%;
  border: 1px solid lightgrey;
  box-shadow: rgba(0, 0, 6, 0.1) 0px 0px 9px 0px;
  margin: 0px auto;
  border-radius: 0.4em;
  padding: 1em;
  display: grid;
  grid-template-columns: 3em repeat(15, 1fr);
  grid-auto-rows: minmax(1em, auto);
  background-color: ${props => (props.grey ? "lightgrey" : "")};
`;

const PreviewLogo = styled.div`
  grid-row: span 3;
  img {
    border-radius: 50%;
  }
`;

const PreviewPhoto = styled.div`
  grid-column: 1 / -1;
  grid-row: 7;
  overflow: hidden;
`;

const TextWrapper = styled.div`
  padding: 10px;
`;

const CarouselCard = styled.div`
  background-color: transparent;
`;

const Image = styled.img`
  height: "auto";
  width: 100%;
  maxheight: "14rem";
  maxwidth: "100%";
`;

const Video = styled.img`
  height: 14em;
`;

class AdPreview extends React.Component {
  state = {
    fbPreviewNum: 1,
    fbTextPreviewExpanded: false,
    trimmedText: "",
    displayText: "",
    headline: "",
    trimmedHeadline: ""
  };

  componentDidMount = () => {
    const { content: { body = "", headline = "" } = {} } =
      this.props.pendingCampaign;
    if (body) {
      this.setState(processText(body));
    }
    if (headline) {
      this.setState(processHeadline(headline));
    }
  };

  componentDidUpdate = prevProps => {
    const { pendingCampaign: prevCampaign } = prevProps;
    const { content: { body, headline } = {} } = this.props.pendingCampaign;
    const { content: { body: prevBody, headline: prevHeadline } = {} } =
      prevCampaign;
    if (body !== prevBody) {
      this.setState(processText(body));
    }
    if (headline !== prevHeadline) {
      this.setState(processHeadline(headline));
    }
  };

  prevAdPreview = () => {
    const { fbPreviewNum } = this.state;
    if (fbPreviewNum > 1) {
      this.setState(prevState => ({
        fbPreviewNum: prevState.fbPreviewNum - 1
      }));
    }
  };

  nextAdPreview = () => {
    const { media = [] } = this.props.pendingCampaign;
    const { fbPreviewNum } = this.state;
    if (fbPreviewNum < media.length) {
      this.setState(prevState => ({
        fbPreviewNum: prevState.fbPreviewNum + 1
      }));
    }
  };

  moreButtonClick = e => {
    e.preventDefault();
    this.setState({ fbTextPreviewExpanded: !this.state.fbTextPreviewExpanded });
  };

  render() {
    const {
      pendingCampaign,
      callToAction = "",
      businessPage = {}
    } = this.props;
    const { content: { newsFeedLinkDesc = "", website } = {}, media = [] } =
      pendingCampaign;
    const {
      name: fbBusinessPageName = "Preview Only. Please relink your Business Page",
      image: fbBusinessPageImage = LogoPlaceholder
    } = businessPage;
    const {
      fbTextPreviewExpanded,
      trimmedText,
      displayText,
      headline,
      trimmedHeadline
    } = this.state;

    const { fbPreviewNum } = this.state;

    return (
      <CarouselCard>
        {media.length > 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "1em"
            }}
          >
            {media.length > 1 && (
              <button onClick={this.prevAdPreview} type='button'>
                <img
                  src={circleArrowLt}
                  style={{ height: "25px" }}
                  alt='Previous Preview'
                />
              </button>
            )}
            <Yellow withMargin>
              {this.state.fbPreviewNum} of {media.length}
            </Yellow>
            {media.length > 1 && (
              <button onClick={this.nextAdPreview} type='button'>
                <img
                  src={circleArrowRt}
                  style={{ height: "25px" }}
                  alt='Next Preview'
                />
              </button>
            )}
          </div>
        )}
        <Preview>
          <PreviewLogo>
            <img
              style={{ height: "3em" }}
              src={fbBusinessPageImage}
              alt='Company Logo Icon'
            />
          </PreviewLogo>
          {!fbBusinessPageName ? (
            <FbPreviewCompanyName PH>{null}</FbPreviewCompanyName>
          ) : (
            <FbPreviewCompanyName>{fbBusinessPageName}</FbPreviewCompanyName>
          )}
          <FbPreviewSponsoredAd>Sponsored</FbPreviewSponsoredAd>
          <FbPreviewMore>&hellip;</FbPreviewMore>
          {!trimmedText ? (
            <FbPreviewText PH>{null}</FbPreviewText>
          ) : (
            <FbPreviewText>
              {fbTextPreviewExpanded ? trimmedText : displayText}
              {displayText !== trimmedText ? (
                <span>
                  {fbTextPreviewExpanded ? " " : "... "}
                  <span className='more' onClick={this.moreButtonClick}>
                    {fbTextPreviewExpanded ? "Less" : "See More"}
                  </span>
                </span>
              ) : null}
            </FbPreviewText>
          )}
          {/* { !text ? <div className="fbPreview__text2"></div> : null } */}
          <PreviewPhoto>
            {media[fbPreviewNum - 1] && media[fbPreviewNum - 1].filename ? (
              media[fbPreviewNum - 1].mediaType === "VIDEO" ? (
                <Video
                  src={cloudinaryFormat({
                    height: 250,
                    mode: "scale",
                    type: "video",
                    filename: media[fbPreviewNum - 1].filename,
                    format: "jpg"
                  })}
                  alt='Facebook Ad'
                />
              ) : (
                <Image
                  src={cloudinaryFormat({
                    height: 250,
                    mode: "scale",
                    type: "image",
                    filename: media[fbPreviewNum - 1].filename
                      ? media[fbPreviewNum - 1].filename
                      : media[fbPreviewNum - 1].public_id,
                    format: media[fbPreviewNum - 1].format
                      ? media[fbPreviewNum - 1].format
                      : media[fbPreviewNum - 1].filename.substring(
                          media[fbPreviewNum - 1].filename.indexOf(".") + 1
                        )
                  })}
                  alt='Facebook Ad'
                />
              )
            ) : (
              <img
                style={{ height: "14em" }}
                src={PlaceholderImg}
                alt='Facebook Ad'
              />
            )}
          </PreviewPhoto>
          <FbPreviewTitleWrapper
            empty={!headline && !website && !newsFeedLinkDesc}
          >
            <TextWrapper>
              {!website ? (
                <FbPreviewWebsite PH>{null}</FbPreviewWebsite>
              ) : (
                <FbPreviewWebsite>{processUrl(website)}</FbPreviewWebsite>
              )}
              {!headline ? (
                <FbPreviewHeadline PH>{null}</FbPreviewHeadline>
              ) : (
                <FbPreviewHeadline>
                  {headline}
                  {trimmedHeadline !== headline ? "..." : null}
                </FbPreviewHeadline>
              )}
              {!newsFeedLinkDesc ? (
                <FbPreviewNewsfeedLinkDesc PH>{null}</FbPreviewNewsfeedLinkDesc>
              ) : (
                <FbPreviewNewsfeedLinkDesc>
                  {processLinkDesc(newsFeedLinkDesc)}
                </FbPreviewNewsfeedLinkDesc>
              )}
            </TextWrapper>
            <FbPreviewCtaWrapper>
              {!callToAction ? (
                <FbPreviewCTA PH>{null}</FbPreviewCTA>
              ) : (
                <FbPreviewCTA>{callToAction}</FbPreviewCTA>
              )}
            </FbPreviewCtaWrapper>
          </FbPreviewTitleWrapper>
        </Preview>
        <div>
          <h6 style={{ fontStyle: "italic" }}>
            **This is a Boostpoint generated ad preview.
          </h6>
        </div>
      </CarouselCard>
    );
  }
}

export default AdPreview;
