/* global cloudinary */

import React from "react";
import styled from "styled-components";
import LoadingBar from "../elements/LoadingBar";
import EditMobileMenu from "../styled/EditMobileMenu";
import ImageWrapper from "../styled/ImageWrapper";
import { assetAdd, cloudinaryFormat } from "../../utils/MediaAPI";

const SelectableImageWrapper = styled.div`
  position: relative;
  img {
    height: 6em;
    margin: 1em;
    cursor: pointer;
    box-shadow: ${props =>
      props.selected ? "0px 0px 2px 5px #fbb74c" : "none"};
  }
`;

const DeleteAssetButton = styled.button`
  display: block;
  position: absolute;
  right: 16px;
  top: 16px;
  background-color: rgba(255, 255, 255, 0.8);
  width: 1.95em;
  height: 2em;
  &:hover {
    background-color: rgba(236, 100, 75, 0.8);
  }
`;

const MyImagesPane = props => {
  const { show, myImages, selectedImages, email, companyId, loading } = props;

  const uploadCloudinaryWidget = () => {
    cloudinary.openUploadWidget(
      {
        cloud_name: "equipter",
        /* cspell: disable-next-line */
        upload_preset: "x8udno21",
        sources: ["local", "camera"],
        cropping: false,
        minImageWidth: 600,
        minImageHeight: 600,
        resource_type: "image",
        multiple: false,
        show_powered_by: false,
        theme: "minimal",
        tags: [email]
      },
      (error, result) => {
        if (result.event === "success") {
          uploadSuccess(result.info);
        }
        if (result.event === "close") {
          props.setMenu("myImages");
        }
        if (error) {
          console.error(error);
        }
      }
    );
  };

  const uploadSuccess = async image => {
    const newImage = {
      companyId,
      name: image.public_id,
      format: image.format,
      type: "IMAGE",
      status: "ACTIVE",
      height: image.height,
      width: image.width,
      createdDate: image.created_at,
      userPending: true,
      usedCampaignIds: []
    };
    await assetAdd(newImage);
    props.loadUserImages();
    props.handleMyImagesSelected([...selectedImages, newImage]);
  };

  const handleImagesSelected = image => {
    console.log("image", image);
    const imageFilenames = selectedImages.map(i => i.name);
    if (imageFilenames.includes(image.name)) {
      props.handleImagesSelected(
        selectedImages.filter(i => i.name !== image.name)
      );
    } else {
      props.handleImagesSelected([...selectedImages, image]);
    }
  };

  return (
    show && (
      <div>
        <EditMobileMenu>
          <div id='editMenuLeft' className='editMenuMobile__item'>
            <p onClick={uploadCloudinaryWidget}>+ add</p>
          </div>
          <button id='editMenuRight' className='editMenuMobile__item'>
            <p>edit</p>
          </button>
        </EditMobileMenu>
        <ImageWrapper>
          {/* displaying of all the users images stored in state.myImages */}
          {loading ? (
            <LoadingBar />
          ) : myImages.length === 0 ? (
            <p id='emptyMessage'>
              You have no images.{" "}
              <span className='desktop'>Use the Upload tab to add images.</span>
              <span className='mobile'>
                Click the +add button to add images.
              </span>
            </p>
          ) : (
            myImages.map((data, index) => (
              <SelectableImageWrapper
                publicId={`${data.name}`}
                key={data.name}
                selected={selectedImages.some(
                  image => image.name === `${data.name}`
                )}
              >
                <img
                  src={cloudinaryFormat({
                    filename: data.name,
                    format: data.format,
                    height: 96,
                    width: 144,
                    mode: "fill"
                  })}
                  onClick={() => handleImagesSelected(data)}
                  alt='Selectable'
                />
                <DeleteAssetButton
                  onClick={() => props.deleteAssetClick(data, index)}
                >
                  X
                </DeleteAssetButton>
              </SelectableImageWrapper>
            ))
          )}
        </ImageWrapper>
      </div>
    )
  );
};

export default MyImagesPane;
