import React from "react";

import {
  BusinessPageImage,
  BusinessPageName,
  Description,
  FormCard,
  Headline,
  HeadlineImage,
  PageImageWrapper,
  PreviewContainer,
  SubmitButton
} from "../../styled/LeadAdPreview";

const LeadAdPreviewThankyou = props => {
  const { title, body, buttonTxt, coverImage, headline, pageImage } = props;
  const [pageName, setPageName] = React.useState(props.pageName);
  const [buttonLabel, setButtonLabel] = React.useState(
    buttonTxt === "url"
      ? "Visit Website"
      : buttonTxt === "call"
      ? "Call Business"
      : "Choose Button Text"
  );

  React.useEffect(() => {
    if (!props.pageName) {
      setPageName("Preview Only. Please relink your Business Page");
    }
  }, [pageName, props.pageName]);

  React.useEffect(() => {
    if (buttonTxt === "url") {
      setButtonLabel("Visit Website");
    } else if (buttonTxt === "call") {
      setButtonLabel("Call Business");
    } else {
      setButtonLabel("Choose Button Text");
    }
  }, [buttonTxt]);

  return (
    <div>
      <PreviewContainer>
        {coverImage && <HeadlineImage src={coverImage} alt={headline} />}
        <FormCard contextTitle>
          {pageImage && (
            <PageImageWrapper>
              <BusinessPageImage src={pageImage} alt={pageName} />
            </PageImageWrapper>
          )}
          <BusinessPageName>{pageName}</BusinessPageName>
          {title ? (
            <Headline>{title}</Headline>
          ) : (
            <Headline>Please enter a Thank You Title</Headline>
          )}
          <Description>
            {body ? body : "Please enter a Thank You Body"}
          </Description>
        </FormCard>
        <SubmitButton>{buttonLabel}</SubmitButton>
      </PreviewContainer>
    </div>
  );
};

export default LeadAdPreviewThankyou;
