import React from "react";
import styled, { keyframes } from "styled-components";

const DotFlashing = keyframes`
  0% {
    opacity: 1;
  }
  50%,
  100% {
    opacity: .3
  }
`;

const Loader = styled.div`
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${props =>
    props.darkMode ? props.theme.HC1 : props.theme.BGC};
  animation: ${DotFlashing} 1s infinite linear alternate;
  animation-delay: 0.5s;
  &::before,
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  &::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${props =>
      props.darkMode ? props.theme.HC1 : props.theme.BGC};
    animation: ${DotFlashing} 1s infinite alternate;
    animation-delay: ${props => (props.reverse ? "1s" : "0s")};
  }
  &::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${props =>
      props.darkMode ? props.theme.HC1 : props.theme.BGC};
    animation: ${DotFlashing} 1s infinite alternate;
    animation-delay: ${props => (props.reverse ? "0s" : "1s")};
  }
`;

// darkMode = true: dots are the Highlight 1 theme color DEFAULT
// darkMode = false: dots are background color
// reverse = false: dots animate left-to-right DEFAULT
// reverse = true: dots animate right-to-left

const DotsFlashingLoader = props => {
  const { darkMode = true, reverse = false } = props;
  return (
    <Loader
      darkMode={darkMode}
      reverse={reverse}
    />
  );
};

export default DotsFlashingLoader;
