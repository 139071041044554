import React from "react";
import smoothscroll from "smoothscroll-polyfill";
import styled from "styled-components";
import * as _ from "underscore";
import {
  errorHandler,
  handleFloaterClick
} from "../../../utils/Helpers";
import BpModal from "../../elements/BpModal";

// Styled Components
import { messageDelete } from "../../../utils/MessagesAPI";
import MessageSendMessage from "../messaging/MessageSendMessage";
import MessageHeader from "../messaging/MessageHeader";
import MessagePlaceholder from "../messaging/MessagePlaceholder";
import MessageDisplay from "../messaging/MessageDisplay";
// kick off the polyfill!
smoothscroll.polyfill();

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 70px 1fr ${props =>
      `${48 + props.msgRows * 30 + props.showMedia}px`};
  height: 100%;
  overflow: auto;
  background-color: white;
`;

const ThreadWrapper = styled.div`
  height: 100%;
  padding: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: ${props => props.theme.BGC};
`;

const Thread = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  /* > div.new {
    max-height: 0;
    transform: scale(1, 0);
  } */
`;



class ContactMessages extends React.Component {
  messagesEnd = React.createRef();
  state = {
    errMessages: {},
    errFields: [],
    msgBody: "",
    msgRows: 1,
    contactPhone: "",
    loading: false,
    mediaUrls: [],
    showFileUploader: false,
    floaters: {},
    errorCodes: {},
    showMoreOptions: false,
    selected: [],
    showSelectors: false,
    confirmDelete: false,
    modalConfirmDelete: false,
    deleting: false
  };

  componentDidMount() {
    if (this.props.embedded) {
      this.scrollToBottom();
    }
  }

  async componentDidUpdate(prevProps) {
    const {
      contact: { phone, mobilePhone } = {},
      contactId,
      contactMessages = []
    } = this.props;
    const {
      contactId: prevContactId,
      contactMessages: prevContactMessages = []
    } = prevProps;
    if (contactId !== prevContactId) {
      this.scrollToBottom(true);
      let contactPhone = "";
      if (contactMessages[0] && contactMessages[0].direction) {
        if (contactMessages[0].direction === "incoming") {
          if (contactMessages[0].from === phone) {
            contactPhone = "phone";
          } else if (contactMessages[0].from === mobilePhone) {
            contactPhone = "mobile";
          }
        } else {
          if (contactMessages[0].to === phone) {
            contactPhone = "phone";
          } else if (contactMessages[0].to === mobilePhone) {
            contactPhone = "mobile";
          }
        }
      }
      this.setState({ contactPhone });
    }
    if (
      contactMessages.length > 1 &&
      contactMessages.length !== prevContactMessages.length
    ) {
      this.scrollToBottom(true);
      let floaters = {};
      contactMessages.forEach((msg, step) => {
        if (msg.smsStatus === "failed" || msg.smsStatus === "undelivered") {
          floaters = {
            ...floaters,
            [`failed${step}`]: false
          };
        }
      });
      this.setState({ floaters });
      return;
    }
    let equals = true;
    if (contactMessages.length > 0) {
      contactMessages.forEach(async (msg, index) => {
        if (!_.isEqual(msg, prevContactMessages[index])) {
          equals = false;
        }
        if (
          (msg.smsStatus === "failed" || msg.smsStatus === "undelivered") &&
          !this.state.errorCodes[msg.errorCode]
        ) {
          // this.getErrorCode(msg);
        }
      });
      if (!equals) {
        this.scrollToBottom(true);
      }
    }
    if (contactMessages.length !== prevContactMessages.length) {
      this.scrollToBottom(true);
    }
  }

  // async getErrorCode(msg) {
  //   const errorCodes = this.state.errorCodes;
  //   errorCodes[msg.errorCode] = 'finding';
  //   try {
  //     const messageCode = await axios.post(
  //       `${BP_NODE_SERVER}/v1/sms/getErrorCode`,
  //       {
  //         errorCode: msg.errorCode
  //       }
  //     );
  //     errorCodes[msg.errorCode] = messageCode.data;
  //     this.setState({ errorCodes: errorCodes });
  //   } catch (err) {
  //     console.error(err);
  //     errorCodes[msg.errorCode] = 'Error Code ' + msg.errorCode + 'Not Found';
  //   }
  //   this.setState({ errorCodes: errorCodes });
  // }

  scrollToBottom = mobile => {
    // console.log('scroll');
    const isSmall = window.innerWidth < 1200;
    if (!isSmall && !mobile && this.messagesEnd && this.messagesEnd.current) {
      setTimeout(() => {
        this.messagesEnd.current.scrollIntoView({ behaviour: "smooth" }, 500);
      });
    } else if (this.messagesEnd && this.messagesEnd.current) {
      setTimeout(() => {
        if (this.messagesEnd.current) {
          this.messagesEnd.current.scrollIntoView({ behaviour: "smooth" });
        }
      }, 1000);
    }
  };  

  sendMessage = async (msgBody, mediaUrls) => {
    const { alert } = this.props;
    const mediaUrl = mediaUrls.map(media => ({
      url: media.url,
      mimetype: media.mimetype
    }));
    if (msgBody || mediaUrl.length > 0) {
      try {
        const sent = await this.props.sendMessage({ msgBody, mediaUrl });
        if (sent) {
          this.setState({ msgBody: "", msgRows: 1, mediaUrls: [] });
        } else {
          alert({ type: "error", msg: "message sending failed" });
        }
      } catch (err) {
        const { status, message } = errorHandler(err);
        console.log("err", `${status}: ${message}`);
      }
    } else {
      alert({
        type: "info",
        msg: "Enter a message or attach an image to send."
      });
    }
  };

  handleFloaterClick = (e, clickTarget) => {
    e.preventDefault();
    const { floaters } = this.state;
    const newFloaters = handleFloaterClick(clickTarget, floaters);
    this.setState({ floaters: newFloaters });
  };

  tryAgain = async message => {
    try {
      const { floaters } = this.state;
      const { body, _id: messageId } = message;
      const numMedia = parseInt(message.numMedia);
      let mediaUrl = [];
      if (numMedia > 0) {
        for (let step = 0; step < numMedia; step++) {
          mediaUrl = [
            ...mediaUrl,
            {
              url: message[`fsMediaUrl${step}`],
              mimetype: message[`fsMediaContentType${step}`]
            }
          ];
        }
      }
      console.log("try again message", body, mediaUrl);
      const sent = await this.props.sendMessage({ msgBody: body, mediaUrl });
      console.log("sent", sent.success);
      if (sent.success) {
        const response = await messageDelete(messageId);
        console.log("delete response", response);
        Object.keys(floaters).forEach(i => {
          floaters[i] = false;
        });
        this.setState({ floaters });
      }
    } catch (err) {
      console.log("error resending message", err.message);
    }
  };

  deleteConversation = async () => {
    const { selected, confirmDelete } = this.state;
    if (!confirmDelete) {
      this.setState({ modalConfirmDelete: true });
      return;
    }
    this.setState({ deleting: true });
    const { contactMessages, embedded } = this.props;
    try {
      let toDelete = selected;
      if (selected.length === 0 || selected.length === contactMessages.length) {
        console.log('deleting entire conversation');
        toDelete = contactMessages.map(msg => msg._id);
      }
      console.log("delete", toDelete);
      const wait = [];
      toDelete.forEach(messageId => wait.push(messageDelete(messageId)));
      Promise.all(wait).then(() => {
        this.setState({
          modalConfirmDelete: false,
          confirmDelete: false,
          deleting: false
        });
        if (
          (selected.length === 0 || selected.length === contactMessages.length) 
          && !embedded) {
          this.props.unLoadContact();
        } else if (!embedded) {
          this.props.updateConversations();
        }
        this.toggleSelectors();
        this.props.updateConversationList();
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  confirmDelete = () => {
    this.setState({ confirmDelete: true }, () => {
      this.deleteConversation();
    });
  };

  cancelDelete = () => {
    this.setState({
      modalConfirmDelete: false,
      selected: [],
      showSelectors: false,
    });
  };

  toggleSelectors = () => {
    const { showSelectors } = this.state;
    let newState = {
      showSelectors: !showSelectors
    };
    if (showSelectors) {
      newState = {
        ...newState,
        selected: []
      };
    }
    this.setState({ ...newState });
  };

  onChangeSelector = value => {
    const { selected } = this.state;
    let newSelected = [];
    if (selected.includes(value)) {
      newSelected = selected.filter(id => id !== value);
    } else {
      newSelected = [...selected, value];
    }
    this.setState({ selected: newSelected });
  };

  updateMsgBody = (newValue, newRows) => {
    this.setState({msgBody: newValue, msgRows: newRows})
  }

  updateMedia = values => {
    this.setState({mediaUrls: values})
  }

  render() {
    const {
      msgBody,
      msgRows,
      mediaUrls,
      floaters,
      errorCodes,
      selected,
      showSelectors,
      modalConfirmDelete,
      deleting
    } = this.state;
    const {
      contact,
      contactId,
      userProfile,
      contactMessages,
      contactPhone,
      contactView,
      embedded
    } = this.props;
    const contactName = contact.firstName
      ? `${contact.firstName} ${contact.lastName}`
      : contactPhone;
    return (
      <Wrapper
        className='wrapper'
        msgRows={msgRows}
        showMedia={mediaUrls.length > 0 ? 100 : 0}
      >
        <MessageHeader 
          contactId={contactId}
          contact={contact}
          contactPhone={contactPhone}
          showSelectors={showSelectors}
          selected={selected}
          toggleSelectors={this.toggleSelectors}
          deleteConversation={this.deleteConversation}
          contactView={contactView}
          unLoadContact={this.props.unLoadContact}
          embedded={this.props.embedded}
          backPage={`contacts/${contact._id}?back=messages${
            this.props.page ? `&page=${this.props.page}` : ""
          }`}
        />
        <ThreadWrapper>
          <Thread>
            <span className='messages-end' ref={this.messagesEnd}></span>
            {msgBody && !this.props.sending && (
              <MessagePlaceholder />
            )}
            {contactMessages.length > 0 ? (
              <MessageDisplay 
                contactMessages={contactMessages}
                showSelectors={showSelectors}
                selected={selected}
                onChangeSelector={this.onChangeSelector}
                tryAgain={this.tryAgain}
                handleFloaterClick={this.handleFloaterClick}
                floaters={floaters}
                errorCodes={errorCodes}
              />
            ) : contactName ? (
              <p>This is the start of your conversation with {contactName}</p>
            ) : contactPhone || !embedded ? (
              <p>
                Welcome to Boostpoint Messaging! Click "New Message" to start a
                conversation!
              </p>
            ) : (
              <p>
                This contact doesn't have a phone number. Please add one before
                trying to message them.
              </p>
            )}
          </Thread>
        </ThreadWrapper>
        {!!contactPhone && (
          <MessageSendMessage 
            sendMessage={this.sendMessage}
            msgRows={msgRows}
            msgBody={msgBody}
            mediaUrls={mediaUrls}
            updateMsgBody={this.updateMsgBody}
            updateMedia={this.updateMedia}
            handleTextChange={this.handleTextChange}
            alert={this.props.alert}
            scrollToBottom={this.scrollToBottom}
          />
        )}
        <BpModal
          open={modalConfirmDelete}
          title='Are you sure?'
          primaryAction={{
            btnLabel: "Continue and Delete",
            action: this.confirmDelete,
            loading: deleting
          }}
          secondaryAction={{
            btnLabel: "Cancel",
            action: this.cancelDelete
          }}
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
          contentLabel='Existing Campaign'
          body={
            <p>
              You are about to delete{" "}
              {selected.length > 0
                ? `${selected.length} message${selected.length > 1 ? "s" : ""}`
                : "this entire conversation"}
              . Are you sure you want to continue?
            </p>
          }
          width='auto'
        />
      </Wrapper>
    );
  }
}

export default ContactMessages;
