import styled from "styled-components";

const Card = styled.div`
  background-color: ${props => props.theme.BGC};
  margin: 2.5em;
  padding: 1em 0;
  /* overflow: auto; */
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
    0 1px 3px 1px rgba(60, 64, 67, 0.16);
  /* border-radius: 8px; */
  @media screen and (max-width: 600px) {
    margin: 2.5em 1.5em;
  }

  @media screen and (max-width: 450px) {
    margin: 2.5em 0.5em;
  }
`;

export default Card;
