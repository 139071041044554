import styled from "styled-components";
import { LoaderDotsSmall } from "../../../elements/Loaders";
import { Button } from "../../../styled/Buttons";
import { firstBreakpoint } from "../../../styled/PhoneNumberProvision";

const MobileButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-top: 11px solid ${props => props.theme.ATextC};
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: none;
  }
`;

const MobileButtons = props => (
  <MobileButtonsWrapper>
    <Button onClick={props.back}>Previous</Button>
    <Button onClick={props.next} solid disabled={props.loading}>
      Next
      {props.loading && <LoaderDotsSmall />}
    </Button>
  </MobileButtonsWrapper>
);

export default MobileButtons;
