import React from 'react';

export const Error = {
  general: (
    <p>
      Something went wrong. Please refresh and try again. If you continue to get
      this message, contact Boostpoint at{' '}
      <a href="mailto:help@boostpoint.com">help@boostpoint.com</a>
    </p>
  ),
  representative: 'Please contact your Account Executive'
};
