import React from "react";
import BaseIcon from "./BaseIcon";

const SuccessIcon = props => (
  <BaseIcon
    color={props.theme && props.theme.HCSuc ? props.theme.HCSuc : "#72e7c4"}
  >
    <path d='M22 11.08V12a10 10 0 1 1-5.93-9.14' />
    <polyline points='22 4 12 14.01 9 11.01' />
  </BaseIcon>
);

export default SuccessIcon;
