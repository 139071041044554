import React from "react";
import styled from "styled-components";
import { colorDark } from "../../../utils/Helpers";
import { handleFloaterClick } from "../../../utils/Helpers";
import { tagCreate } from "../../../utils/ContactAPI";

const TagWrapper = styled.div`
  border-radius: 2px;
  display: flex;
  flex-wrap: wrap;
  padding: 5px 5px 0;
  margin-bottom: 12px;
`;

const TagsList = styled.ul`
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
`;

const Tag = styled.li`
  position: relative;
  border-radius: 2px;
  color: ${props => props.theme.CTextC};
  display: flex;
  font-size: 16px;
  font-weight: 300;
  list-style: none;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 0px 10px;
  border-radius: 1.2rem;
  white-space: nowrap;
  line-height: 1.8em;
  max-height: 1.8em;
  text-overflow: ellipsis;
  max-width: 13em;
  align-items: center;
`;

const TagColor = styled.span`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 7px;
  background: ${props => (props.color ? props.color : props.theme.HC1)};
`;

const RemoveIcon = styled.span`
  background-color: ${props => props.theme.HCErr};
  color: ${props => props.theme.BGC};
  border-radius: 11px;
  padding: 3px 5px;
  font-size: 12px;
  margin-left: 6px;
`;

const AddIcon = styled(RemoveIcon)`
  background-color: ${props => props.theme.HC1};
  color: ${props => props.theme.BGC};
`;

const InputTagChanges = props => {
  const { tags } = props;

  return (
    <TagWrapper>
      <TagsList>
        {!!tags &&
          tags.map((tag, i) => (
            <Tag key={i} className={i + 1 === tags.length ? "last-tag" : ""}>
              <TagColor
                color={tag.tagToChange.color ? tag.tagToChange.color : ""}
              ></TagColor>
              <span className='color-btn'>
                {tag.tagToChange.name}
                {tag.action === "add" ? (
                  <AddIcon>add</AddIcon>
                ) : (
                  <RemoveIcon>remove</RemoveIcon>
                )}
              </span>
            </Tag>
          ))}
      </TagsList>
    </TagWrapper>
  );
};

export default InputTagChanges;
