import styled from "styled-components";

export const ContactFieldset = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media only screen and (max-width: 660px) {
    grid-template-columns: 1fr;
  }
  margin: 0 auto;
  max-width: 700px;
`;

export const NavigateBack = styled.span`
  position: relative;
  top: 5px;
`;

export const ContactLoading = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
`;
