import React from "react";
import styled from "styled-components";
import { firstBreakpoint } from "../styled/PhoneNumberProvision";

const SearchWrapper = styled.div`
  @media screen and (min-width: ${firstBreakpoint}px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const SearchInputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  /* width: 0; */
  width: ${(props) => (props.showMobileView ? "auto" : "22rem")};
  padding: 0;
  margin: ${(props) => (props.showMobileView ? 0 : "0 auto")};
  border-radius: ${(props) =>
    props.showMobileView ? "5px" : "5px 35px 35px 5px"};
  border: ${(props) =>
    props.showMobileView
      ? `1px solid ${props.theme.HC1}`
      : props.theme
      ? `1px solid ${props.theme.HC1}`
      : "1px solid #e4e4e4"};
  max-width: 74vw;
  break-inside: avoid;
  background-color: ${(props) => props.theme.BGC};
  &:hover i {
    color: ${(props) => props.theme.BGC};
  }
  button {
    margin: 0;
  }
  @media screen and (min-width: ${firstBreakpoint}px) {
    background-color: ${(props) => props.theme.BGC};
    padding: 0;
    width: 22rem;
    margin: 0 20px;

    border-radius: 0 35px 35px 0;
    border: 1px solid ${(props) => props.theme.HC1};
    max-width: 90%;
  }
`;

const SearchIcon = styled.span`
  display: inline-block;
  cursor: pointer;
  border: none;
  padding: ${(props) =>
    props.showMobileView ? "4px" : props.compact ? "8px" : "17px"};
  margin: ${(props) =>
    props.showMobileView
      ? 0
      : props.compact
      ? "4px 4px 4px 1px"
      : "7px 7px 7px 5px"};
  font-size: ${(props) =>
    props.showMobileView ? "28px" : props.compact ? "20px" : "24px"};
  border-radius: 0;
  color: ${(props) =>
    props.showMobileView ? props.theme.HC1 : props.theme.BGC};
  background-color: ${(props) =>
    props.showMobileView ? "transparent" : props.theme.HC1};
  border-radius: 50%;
  @media screen and (min-width: ${firstBreakpoint}px) {
    padding: ${(props) => (props.compact ? "8px" : "17px")};
    color: ${(props) => props.theme.BGC}
    background-color: ${(props) => props.theme.HC1};
    font-size: ${(props) => (props.compact ? "20px" : "24px")};
    margin: ${(props) =>
      props.compact ? "4px 4px 4px 1px" : "7px 7px 7px 5px"};
  }
`;

const GlobalFilterBox = styled.input`
  background-color: ${(props) =>
    props.showSearch || !props.showMobileView
      ? props.theme.BGC
      : "transparent"};
  height: 2.5em;
  border: none;
  font-size: 0.9rem;
  font-style: ${(props) => (props.showPlaceholder ? "italic" : "auto")};
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  max-width: 400px;
  min-width: ${(props) =>
    props.showSearch || !props.showMobileView ? "auto" : "0"};
  position: ${(props) => (props.showMobileView ? "relative" : "static")};
  right: 0;
  /* width: 0; */
  width: ${(props) => (props.showMobileView ? "auto" : "100%")};
  text-align: left;
  color: ${(props) => (props.readOnly ? props.theme.ATextC : "inherit")};
  cursor: auto;
  margin: 0 auto;
  padding-left: ${(props) =>
    props.showSearch || !props.showMobileView ? "15px" : "0"};
  transition: all 0.3s ease-in-out;
  ${(props) =>
    props.customBackground && `background: ${props.customBackground};`};
  @media screen and (min-width: ${firstBreakpoint}px) {
    background-color: ${(props) => props.theme.BGC};
    position: static;
    width: 100%;
    min-width: auto;
    padding-left: 15px;
  }
`;

const ClearSearch = styled.span`
  display: ${(props) => (props.showClear ? "block" : "none")};
  position: absolute;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  color: ${(props) => props.theme.TextC};
  right: ${(props) =>
    props.showMobileView ? "8px" : props.compact ? "40px" : "80px"};
  @media screen and (min-width: ${firstBreakpoint}px) {
    right: ${(props) => (props.compact ? "40px" : "80px")};
    &:hover {
      background: rgba(130, 130, 130, 0.3);
    }
  }
`;

const Search = (props) => {
  const {
    searchValue,
    onEnter,
    onSearch,
    onSearchChange,
    showSearch,
    showActive,
    showClear,
    clearSearch,
    placeholder = "Search",
    showMobileView = true,
    compact = false
  } = props;
  return (
    <SearchWrapper className="search-wrapper">
      <SearchInputContainer
        className="globalFilter"
        showMobileView={showMobileView}
      >
        <GlobalFilterBox
          value={searchValue || ""}
          showPlaceholder={searchValue ? false : true}
          onChange={(e) => onSearchChange(e.target.value)}
          onKeyDown={onEnter}
          placeholder={placeholder}
          showSearch={showSearch}
          showMobileView={showMobileView}
          compact={compact}
        />
        <SearchIcon
          className="material-icons"
          onClick={onSearch}
          showActive={showActive}
          showMobileView={showMobileView}
          compact={compact}
        >
          search
        </SearchIcon>
        <ClearSearch
          className="material-icons"
          showClear={showClear}
          onClick={clearSearch}
          compact={compact}
        >
          close
        </ClearSearch>
      </SearchInputContainer>
    </SearchWrapper>
  );
};

export default Search;
