import styled, { keyframes } from "styled-components";

export const joyRideBeaconInner = keyframes`
  0% {
    opacity: 0.7;
  }

  10% {
    opacity: 0.9;
  }

  20% {
    opacity: 0.7;
  }

  100% {
    opacity: 0.7;
  }
`;
export const joyRideBeaconOuter = keyframes`
  0% {
    opacity: 0.7;
    transform: scale(0.5);
  }

  20% {
    opacity: 0;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }

  
`;

export const BeaconWrapper = styled.span`
  cursor: help;
  display: inline-flex;
  flex-direction: column;
  z-index: ${props => (props.zIndex ? props.zIndex : "99")};
  position: relative;
  will-change: transform;
`;

export const BeaconButton = styled.button`
  background-color: transparent;
  border: 0px;
  border-radius: 0px;
  color: rgb(85, 85, 85);
  cursor: pointer;
  font-size: 16px;
  line-height: 1;
  padding: 8px;
  -webkit-appearance: none;
  display: inline-block;
  height: 36px;
  position: relative;
  width: 36px;
  z-index: ${props => (props.zIndex ? props.zIndex : "99")};
`;

//rgb(63, 127, 191)
//rgb(255, 0, 68)
//rgba(104,93,168,1)

export const BeaconAnimationCenter = styled.span`
  animation: 5s ease-in-out 0s infinite normal none running
    ${joyRideBeaconInner};
  background-color: rgba(104, 93, 168, 1);
  color: white;
  border-radius: 50%;
  display: block;
  height: 50%;
  left: 50%;
  opacity: 0.7;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  font-size: 0.89em;
  padding-top: 2px;
`;

export const BeaconAnimation = styled.span`
  animation: 5s ease-in-out 0s infinite normal none running
    ${joyRideBeaconOuter};
  background-color: rgba(104, 93, 168, 0.2);
  border: 2px solid rgba(104, 93, 168, 1);
  border-radius: 50%;
  box-sizing: border-box;
  display: block;
  height: 100%;
  left: 0px;
  opacity: 0.9;
  position: absolute;
  top: 0px;
  transform-origin: center center;
  width: 100%;
`;

export const FloaterFlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
