import React from "react";
import { Button } from "./styled/Buttons";
import Modal from "react-modal";
import styled from "styled-components";
import { withTheme } from "styled-components";
import H3 from "./styled/H3";
const Icon = styled.span`
  color: ${props => props.theme.HC1};
  padding: 0 0.5em;
  font-size: 1.5em;
  @media only screen and (max-width: 450px) {
    color: rgba(0, 0, 0, 0);
  }
`;
const Title = styled.div`
  margin: 0 auto;
  padding: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 2em;
`;
const Body = styled.div`
  margin-top: -1em;
`;
class NoConnectModal extends React.Component {
  render() {
    const overlay = {
      backgroundColor: "rgba(0, 0, 0, .75)",
      zIndex: 100
    };
    const content = {
      textAlign: "center",
      padding: "0 1em 1em 1em",
      minWidth: "20em",
      maxWidth: "40em",
      border: "none",
      boxShadow: "0px 0px 9px 0px rgba(0, 0, 6, .1)",
      overflow: "hidden",
      transform: "translate(-50%, -50%)",
      marginRight: "-50%",
      inset: "50% auto auto 50%",
      backgroundColor: this.props.theme.BGC
    };
    const { showModal } = this.props;
    return (
      <Modal
        isOpen={showModal}
        zIndex={100}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        style={{ content, overlay }}
        appElement={document.getElementById("root")}
      >
        <Title>
          <Icon className='material-icons'>chat</Icon>
          <H3>Boostpoint Connect</H3>
          <Icon className='material-icons'>account_tree</Icon>
        </Title>
        <Body>
          <p>
            Messaging and Workflows are a part of our Boostpoint Connect add-on.
            These features help you connect with your leads both individually
            and automatically. If you would like to begin using Boostpoint
            Connect, click the Sign Up button.
          </p>
          <Button
            onClick={() => {
              this.props.history.push("/integrations/BoostpointConnect");
            }}
          >
            Find Out More
          </Button>
          <Button
            solid
            onClick={() => {
              this.props.history.push(
                "/integrations/BoostpointConnect?signup=true"
              );
            }}
          >
            Sign Up
          </Button>
        </Body>
      </Modal>
    );
  }
}
export default withTheme(NoConnectModal);
