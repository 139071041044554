import { Component } from "react";
// import shouldPureComponentUpdate from 'react-pure-render/function';
import styled from "styled-components";
import { meters2ScreenPixels } from "google-map-react";
import mapIcon from "../../../images/mapIcon.svg";

export default class MapMarker extends Component {
  render() {
    const { radius, zoom, lat, lng } = this.props;

    const markerWidth = `29px`;
    const markerHeight = `44px`;

    const originX = `14.5px`;
    const originY = `42px`;

    const meters = radius * 1609.34;

    const { w, h } = meters2ScreenPixels(meters, { lat, lng }, zoom);

    const Marker = styled.div`
      background-image: url(${mapIcon});
      position: absolute;
      cursor: pointer;
      width: ${markerWidth};
      height: ${markerHeight};
      top: -${originY};
      left: -${originX};
      transform-origin: ${originX} ${originY};
      margin: 0;
      padding: 11px 0 0 1px;
      z-index: 1;
    `;

    const Radius = styled.div`
      position: absolute;
      width: ${w * 2}px;
      height: ${h * 2}px;
      left: -${w}px;
      top: -${h}px;
      border: 2px solid black;
      border-radius: 50%;
      background-color: black;
      opacity: 0.2;
    `;
    return (
      <div>
        <Marker>{this.props.text}</Marker>
        <Radius />
      </div>
    );
  }
}
