import styled from "styled-components";
// Images
import DropdownArrow from "../../images/DropdownArrow.png";

// Basic Input Elements And Related Elements

// New standard input box styles
export const InputBox = styled.input`
  background-color: ${props =>
    props.bgColor ? props.bgColor : props.theme.BGC};
  outline: ${props =>
    props.readOnly
      ? props.theme.TextC + " solid 1px"
      : props.theme.HC2 + " solid 1px"};
  border: none;
  height: ${props => (props.compact ? "1.6em" : "2.5em")};
  border-radius: 3px;
  font-size: 16px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  width: 100%;
  text-align: left;
  color: ${props => (props.readOnly ? props.theme.TextC : "inherit")};
  cursor: auto;
  margin: 0 auto;
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : "15px")};
  ${props => props.customBackground && `background: ${props.customBackground};`}
  &:focus {
    outline: ${props =>
      props.readOnly
        ? props.theme.TextC + " solid 1px"
        : props.theme.HC1 + " solid 2px"};
  }
`;
export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: ${props => (props.alignment ? props.alignment : "left")};
  width: ${props => (props.customWidth ? props.customWidth : "18rem")};
  margin: 0 auto 30px;
  max-width: 90%;
  break-inside: avoid;
  &:hover i {
    color: #cbcbcb;
  }
`;
export const InputBoxWrapper = styled.div`
  position: relative;
  min-height: 32px;
`;
export const InputLabel = styled.label`
  font-weight: 400;
  color: ${props => props.theme.HC2};
  margin-bottom: 0.5em;
  float: left;
  text-align: left;
`;
export const InputFlexGroup = styled.div`
  display: flex;
  max-width: 50em;
  margin: auto;
  @media (max-width: 670px) {
    flex-direction: column;
  }
`;

// Input Dropdown Selects
export const Dropdown = styled.select`
  color: ${props => props.theme.TextC};
  width: 100%;
  height: ${props => (props.compact ? "1.8em" : "2.5em")};
  padding: 0 37px 0 15px;
  border-radius: 3px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  background-color: ${props => props.theme.BGC};
  border: 1px solid ${props => props.theme.HC2};
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  background: url(${DropdownArrow}) ${props => props.theme.BGC};
  background-repeat: no-repeat;
  background-position: right 15px center;
  &:focus {
    outline: ${props =>
      props.readOnly ? "none" : props.theme.HC1 + " auto 2px"};
  }
`;

// Textareas
export const TextArea = styled.textarea`
  width: 100%;
  min-height: 8rem;
  border-radius: 3px;
  border: 1px solid ${props => props.theme.HC2};
  font-size: 16px;
  color: ${props => (props.readOnly ? props.theme.TextC : "inherit")};
  border: ${props =>
    props.readOnly
      ? `1px solid ${props.theme.TextC}`
      : `1px solid ${props.theme.HC2}`}
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  padding: 15px;
  font-family: 'Poppins', sans-serif;
`;

// Number Display Read Only
export const NumberDisplay = styled.input`
  width: 3rem;
  text-align: center;
  cursor: auto;
  outline: ${props => `1px solid ${props.theme.HC1}`};
  border: none;
  border-radius: 4px;
  height: 3em;
  margin-left: 20px;
`;

// Old legacy Input Wrapper. TODO: remove
export const Input = styled.div`
  margin: ${props => (props.campaign ? "1em" : "1.5em 0")};
  flex: ${props => (props.campaign ? "1 0 40%" : "")};
  position: relative;
  max-width: 700px;
  span {
    display: block;
    font-weight: 600;
    color: ${props => props.theme.HC2};
    letter-spacing: 2.5px;
    font-size: 17px;
  }

  p {
    font-size: 16px !important;
    letter-spacing: 1px;
    font-style: italic;
  }

  input {
    width: ${props => (props.campaign ? "100%" : "40%")};
    font-size: ${props => (props.campaign ? "16px" : "")};
    max-width: ${props => (props.register ? "25em" : "")};
    padding-left: 15px;
    outline: ${props =>
      props.readOnly ? "none" : props.theme.HC2 + " auto 1px"};
    &:focus {
      outline: ${props =>
        props.readOnly ? "none" : props.theme.HC2 + " auto 3px"};
    }
  }

  @media screen and (max-width: 527px) {
    flex: ${props => (props.campaign ? "1 0 90%" : "")};
  }
`;

export const FormWrapper = styled.div`
  display: grid;
  width: 90%;
  margin: 0 auto;
  @media screen and (min-width: 1000px) {
    grid-template-columns: 1fr 1fr;
    width: 80%;
  }
`;

export const FormDescription = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  color: ${props => props.theme.HC1};
  padding: 0 20px;
  grid-area: auto / 1 / auto/ 4;
  justify-content: center;
`;
