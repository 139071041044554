import React from "react";
import { emailValidator } from "../../../utils/Helpers";
import {
  notificationCreate,
  notificationsGet,
  notificationUpdate
} from "../../../utils/NotificationsAPI";
import InteractiveDropdown from "../../elements/InteractiveDropdown";

const LeadEmailManage = props => {
  const { companyId, email, readOnly = false } = props;
  const [leadNotificationSettings, setLeadNotificationSettings] =
    React.useState({});

  const createNotification = React.useCallback(async () => {
    const newNotification = await notificationCreate({
      companyId,
      notificationType: "LEAD",
      emailContacts: [email]
    });
    return newNotification;
  }, [companyId, email]);

  React.useEffect(() => {
    const fetch = async () => {
      try {
        const notificationsResponse = await notificationsGet();
        console.log("notificationREsponse", notificationsResponse);
        if (notificationsResponse.length < 1) {
          const newNotification = await createNotification();
          setLeadNotificationSettings(newNotification);
        } else {
          const leadSettings = notificationsResponse.find(
            n => n.notificationType === "LEAD"
          );
          if (!leadSettings) {
            const newNotification = await createNotification();
            setLeadNotificationSettings(newNotification);
          } else {
            setLeadNotificationSettings(leadSettings);
          }
        }
      } catch (err) {
        console.log("Error getting notification settings", err.message);
      }
    };
    fetch();
  }, [companyId, createNotification, email]);

  const validateEmail = value => {
    const valid = emailValidator(value);
    if (valid !== true) {
      return { valid: false, msg: "Please enter a valid email" };
    } else {
      return { valid };
    }
  };

  const updateNotification = async newEmails => {
    try {
      const newNotification = await notificationUpdate({
        ...leadNotificationSettings,
        emailContacts: newEmails
      });
      setLeadNotificationSettings(newNotification);
    } catch (err) {
      console.log("error updating notifications", err.message);
    }
  };

  return (
    <InteractiveDropdown
      dropdownItems={leadNotificationSettings.emailContacts}
      alert={props.alert}
      readOnly={readOnly}
      type='Lead Email'
      validateItem={validateEmail}
      updateValues={updateNotification}
    />
  );
};

export default LeadEmailManage;
