import styled from "styled-components";
import Checkbox from "../../elements/Checkbox";

const CheckboxWrapper = styled.div`
  /* padding: 10px 0 10px 24px; */
  input {
    opacity: 0;
  }
  label {
    position: relative;
    font-style: italic;
    &::before {
      content: "";
      display: inline-block;
      height: 20px;
      width: 20px;
      border-radius: 3px;
      border: 1px solid #ccc;
      position: absolute;
      top: -3px;
      left: -20px;
      background: #fbfcfc;
    }
    &::after {
      content: "";
      display: inline-block;
      height: 7px;
      width: 12px;
      border-left: 2px solid;
      border-bottom: 2px solid;
      transform: rotate(-45deg);
      position: absolute;
      left: -16px;
      top: 2px;
      color: ${props => (props.allSelected ? props.theme.HC1 : "white")};
    }
  }
  input + label::after {
    content: none;
  }
  input:checked + label::before {
    background: ${props => (props.allSelected ? "white" : props.theme.HC1)};
    border-color: ${props => props.theme.TextC};
  }
  input:checked + label::after {
    content: "";
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr;
  border-top: 1px solid ${props => props.theme.ATextC};
  border-bottom: 1px solid ${props => props.theme.ATextC};
  padding: 20px;
  margin: 0 24px;
  &:last-child {
    border-bottom: none;
  }
  &:first-child {
    border-top: none;
  }
`;

const SelectWorkflows = props => {
  const { workflows, selectedWorkflows, updateWorkflows } = props;
  return (
    <div>
      <Wrapper onClick={() => updateWorkflows("all")}>
        <Checkbox
          checked={selectedWorkflows === "all" || selectedWorkflows.length > 0}
          partialSelectOn={
            selectedWorkflows !== "all" && selectedWorkflows.length > 0
          }
        />

        <span>Select All</span>
      </Wrapper>
      {workflows.length > 0 &&
        workflows.map((flow, key) => (
          <Wrapper key={key} onClick={() => updateWorkflows(flow.workflowID)}>
            <CheckboxWrapper
              allSelected={
                !Array.isArray(selectedWorkflows) && selectedWorkflows === "all"
              }
            >
              <input
                type='checkbox'
                checked={
                  selectedWorkflows.includes(flow.workflowID) ||
                  !Array.isArray(selectedWorkflows)
                }
                onChange={() => updateWorkflows(flow.workflowID)}
              />
              <label />
            </CheckboxWrapper>{" "}
            <span>{flow.name}</span>
          </Wrapper>
        ))}
    </div>
  );
};

export default SelectWorkflows;
