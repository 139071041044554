/* global cloudinary */

import React from "react";
import Modal from "react-modal";
import styled from "styled-components";
import BpModal from "../elements/BpModal";
import LoadingBar from "../elements/LoadingBar";

// Import Styled Components
import { errorHandler } from "../../utils/Helpers";
import {
  assetAdd,
  assetDelete,
  assetImagesGetByCompany,
  assetVideosGetByCompany
} from "../../utils/MediaAPI";
import { Button } from "../styled/Buttons";
import EditMobileMenu from "../styled/EditMobileMenu";
import ImageMenuWrapper from "../styled/ImageMenuWrapper";
import ImageWrapper from "../styled/ImageWrapper";
import ModalLargeWrapper from "../styled/ModalLargeWrapper";
import ModalWrapper from "../styled/ModalWrapper";

const MediaWrapper = styled(ImageWrapper)`
  height: 23em;
`;

const SelectableMediaWrapper = styled.div`
  position: relative;
  img {
    height: 6em;
    margin: 1em;
    cursor: pointer;
    box-shadow: ${props =>
      props.selected ? "0px 0px 2px 5px #fbb74c" : "none"};
  }
`;

const SelectableVideoPlay = styled.div`
  position: absolute;
  overflow: hidden;
  cursor: pointer;
  background-color: RGBA(191, 191, 191, 0.6);
  padding: 0.1em 0.2em;
  width: 2em;
  height: 1.6em;
  top: 1em;
  left: 1em;
  transition: all ease 0.5s;

  :hover {
    background-color: RGBA(191, 191, 191, 1);
    transition: all ease 0.5s;
  }
`;

const PreviewModalWrapper = styled.div`
  margin: 2em auto 0.5em;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DeleteAssetButton = styled.button`
  display: block;
  position: absolute;
  right: 16px;
  top: 16px;
  background-color: rgba(255, 255, 255, 0.8);
  width: 1.95em;
  height: 2em;
  &:hover {
    background-color: rgba(236, 100, 75, 0.8);
  }
`;

const ButtonWrapper = styled.div`
  position: absolute;
  right: 50%;
  margin-right: -2.5em;
  bottom: 0;
  height: 4em;
  width: 5em;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-height: 580px) {
    position: static;
    margin: 20px 0;
    width: auto;
  }
`;

const ModalFooterWrapper = styled.div`
  border-top: 1px solid lightgrey;
  margin: 0 1.5em;
  @media screen and (max-height: 580px) {
    display: flex;
    justify-content: space-around;
  }
`;

const CloudinaryProgressBar = styled.div`
  background-color: #bfbfbf;
  width: 60%;
  height: 1.5em;
  margin: 1em auto 0;
`;

const CloudinaryProgress = styled.div`
  background-color: rgb(252, 183, 75);
  height: 100%;
`;

const customStyles = {
  overlay: {
    backgroundColor: "rgba(250, 250, 250, .75)",
    zIndex: "1002"
  },
  content: {
    textAlign: "center",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    maxWidth: "90%",
    padding: "1em 2em 2em",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 0px 9px 0px rgba(0, 0, 6, .1)",
    zIndex: "1002"
  }
};

class MediaManager extends React.Component {
  state = {
    imageMenuSelection: "myImages",
    progressBarValue: 0,
    progressBarDisplay: "none",
    loading: true,
    myImages: [],
    myVideos: [],
    showChangeWarning: false,
    deletingImage: false,
    deleteModal: false,
    deleteAsset: {},
    imageSnapshot: null,
    videoSnapshot: null
  };

  async componentDidMount() {
    try {
      this.loadUserImages();
      this.loadUserVideos();
    } catch (err) {
      console.log(err);
    }
    this.setState({
      loading: false
    });
  }

  componentWillUnmount = () => {
    if (typeof this.state.imageSnapshot === "function") {
      this.state.imageSnapshot();
    }
    if (typeof this.state.videoSnapshot === "function") {
      this.state.videoSnapshot();
    }
  };

  loadUserImages = async () => {
    const { companyId } = this.props.userProfile;
    try {
      this.setState({ loading: true });
      const imageResponse = await assetImagesGetByCompany(companyId);
      const images = imageResponse.filter(image => image.status === "ACTIVE");
      this.setState({ myImages: images, loading: false });
    } catch (err) {
      const { status, message } = errorHandler(err);
      console.log("err", `${status}:`, message);
    }
  };

  loadUserVideos = async () => {
    const { companyId } = this.props.userProfile;
    try {
      this.setState({ loading: true });
      const videoResponse = await assetVideosGetByCompany(companyId);
      const videos = videoResponse.filter(video => video.status === "ACTIVE");
      this.setState({ myVideos: videos, loading: false });
    } catch (err) {
      const { status, message } = errorHandler(err);
      console.log("err", `${status}:`, message);
    }
  };

  handleUploadImagesMenu = () => {
    this.setState({
      imageMenuSelection: "uploadImages"
    });
  };

  handleMyImagesMenu = () => {
    this.setState({
      imageMenuSelection: "myImages"
    });
  };

  handleMyVideosMenu = () => {
    this.setState({
      imageMenuSelection: "myVideos"
    });
  };

  uploadCloudinaryWidget = async () => {
    const { email, uid } = this.props.userProfile;
    cloudinary.openUploadWidget(
      {
        cloud_name: "equipter",
        /* cspell: disable-next-line */
        upload_preset: "x8udno21",
        sources: ["local", "camera"],
        cropping: false,
        minImageWidth: 600,
        minImageHeight: 600,
        resource_type: "auto",
        multiple: true,
        show_powered_by: false,
        theme: "minimal",
        tags: [email]
      },
      async (error, result) => {
        if (result.event === "success") {
          await this.saveAsset(result.info);
        }
        if (result.event === "close") {
          if (result.info && result.info.resource_type) {
            if (result.info.resource_type === "video") {
              this.handleMyVideosMenu();
            } else {
              this.handleMyImagesMenu();
            }
          } else {
            this.handleMyImagesMenu();
          }
        }
        if (error) {
          console.error(error);
        }
      }
    );
  };

  saveAsset = async asset => {
    const { companyId } = this.props.userProfile;
    const mediaTypes = {
      image: "IMAGE",
      video: "VIDEO"
    };
    const newAsset = {
      format: asset.format,
      name: asset.public_id,
      created: asset.created_at,
      status: "ACTIVE",
      width: asset.width,
      height: asset.height,
      type: mediaTypes[asset.resource_type]
        ? mediaTypes[asset.resource_type]
        : asset.resource_type,
      createdDate: new Date(),
      companyId: companyId
    };
    await assetAdd(newAsset);
    this.loadUserImages();
    this.loadUserVideos();
  };

  handleClose = () => {
    this.props.closeMediaManager();
  };

  deleteAssetClick = asset => {
    this.setState({
      deleteModal: true,
      deleteAsset: asset
    });
  };

  deleteAsset = async () => {
    const { deleteAsset: asset } = this.state;
    this.setState({ loading: true });
    if (asset) {
      try {
        await assetDelete(asset);
        this.setState({
          loading: false,
          deleteModal: false,
          deleteAsset: {}
        });

        this.loadUserImages();
        this.loadUserVideos();
        this.props.alert({
          type: "success",
          msg: "The media asset has been deleted."
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  handlePreviewOpen = data => {
    this.setState({
      previewIsOpen: true,
      previewItem: data.name
    });
  };

  handlePreviewClose = () => {
    this.setState({
      previewIsOpen: false,
      previewItem: null
    });
  };

  render() {
    const {
      loading,
      selectedVideo,
      deleteAsset,
      imageMenuSelection,
      myImages,
      myVideos
    } = this.state;
    return (
      <ModalWrapper>
        <ModalLargeWrapper id='images-modal-container'>
          <ImageMenuWrapper>
            <div
              id='uploadImages'
              className={
                imageMenuSelection === "uploadImages"
                  ? "selected button"
                  : "button"
              }
              onClick={this.handleUploadImagesMenu}
            >
              <button>
                <h2>Upload</h2>
                <small>Add media to your gallery</small>
              </button>
            </div>
            <div
              id='myImages'
              className={
                imageMenuSelection === "myImages" ? "selected button" : "button"
              }
            >
              <button onClick={this.handleMyImagesMenu}>
                <h2>Images</h2>
                <small>Browse your images</small>
              </button>
            </div>
            <div
              id='myVideos'
              className={
                imageMenuSelection === "myVideos" ? "selected button" : "button"
              }
            >
              <button onClick={this.handleMyVideosMenu}>
                <h2>Videos</h2>
                <small>Browse your videos</small>
              </button>
            </div>
          </ImageMenuWrapper>
          {imageMenuSelection === "uploadImages" ? (
            <MediaWrapper drag>
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  flexBasis: "100%"
                }}
              >
                <Button
                  type='button'
                  onClick={() => {
                    this.uploadCloudinaryWidget();
                  }}
                  style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-58%, -50%)"
                  }}
                >
                  upload new media assets
                </Button>
              </div>
              {this.state.error && (
                <p style={{ color: "red" }}>{this.state.error}</p>
              )}
              <CloudinaryProgressBar
                ref={cloudinaryProgress => {
                  this.cloudinaryProgress = cloudinaryProgress;
                }}
                style={{ display: this.state.progressBarDisplay }}
              >
                <CloudinaryProgress
                  style={{ width: `${this.state.progressBarValue}%` }}
                />
              </CloudinaryProgressBar>
            </MediaWrapper>
          ) : null}
          {imageMenuSelection === "myImages" ? (
            <div>
              <EditMobileMenu>
                <div id='editMenuLeft' className='editMenuMobile__item'>
                  <p onClick={this.uploadCloudinaryWidget}>+ add</p>
                </div>
                <button id='editMenuRight' className='editMenuMobile__item'>
                  <p>edit</p>
                </button>
              </EditMobileMenu>
              <MediaWrapper>
                {/* displaying of all the users images stored in state.myImages */}
                {this.state.loading ? (
                  <LoadingBar />
                ) : myImages.length === 0 ? (
                  <p id='emptyMessage'>
                    You have no images.{" "}
                    <span className='desktop'>
                      Use the Upload tab to add images.
                    </span>
                    <span className='mobile'>
                      Click the +add button to add images.
                    </span>
                  </p>
                ) : (
                  myImages.map((data, index) => {
                    const thisImage = `${data.name}.${data.format}`;
                    return (
                      <SelectableMediaWrapper key={index}>
                        <img
                          src={`${process.env.REACT_APP_CLOUDINARY_IMAGE_BASEURL}/upload/c_scale,f_auto,h_96,q_auto/c_crop,g_center,h_96,w_144/${thisImage}`}
                          alt=''
                        />
                        <DeleteAssetButton
                          onClick={() => this.deleteAssetClick(data)}
                        >
                          X
                        </DeleteAssetButton>
                      </SelectableMediaWrapper>
                    );
                  })
                )}
              </MediaWrapper>
              {this.state.error && (
                <p style={{ color: "red" }}>{this.state.error}</p>
              )}
              <CloudinaryProgressBar
                ref={cloudinaryProgress => {
                  this.cloudinaryProgress = cloudinaryProgress;
                }}
                style={{ display: this.state.progressBarDisplay }}
              >
                <CloudinaryProgress
                  style={{ width: `${this.state.progressBarValue}%` }}
                />
              </CloudinaryProgressBar>
            </div>
          ) : null}
          {imageMenuSelection === "myVideos" ? (
            <MediaWrapper>
              {/* displaying of all the stock images stored in state.cloudinaryFreeImages */}
              {this.state.loading ? (
                <LoadingBar />
              ) : myVideos.length === 0 ? (
                <p id='emptyMessage'>
                  You have no videos.{" "}
                  <span className='desktop'>
                    Use the Upload tab to add videos.
                  </span>
                  <span className='mobile'>
                    Click the +add button to add videos.
                  </span>
                </p>
              ) : (
                myVideos.map((data, index) => (
                  <SelectableMediaWrapper
                    style={{ position: "relative" }}
                    key={index}
                  >
                    <SelectableVideoPlay
                      onClick={() => this.handlePreviewOpen(data)}
                    >
                      <i className='material-icons md-dark md-24'>
                        play_circle_filled_white
                      </i>
                    </SelectableVideoPlay>
                    <img
                      src={`${process.env.REACT_APP_CLOUDINARY_VIDEO_BASEURL}/upload/c_scale,h_96,q_auto/${data.name}.jpg`}
                      id={data.name + "." + data.format}
                      alt={data.name}
                    />
                    <DeleteAssetButton
                      onClick={() => this.deleteAssetClick(data)}
                    >
                      X
                    </DeleteAssetButton>
                  </SelectableMediaWrapper>
                ))
              )}
            </MediaWrapper>
          ) : null}
          {/* this section displays any images that have been selected through the campaign creation process */}
          <ModalFooterWrapper>
            <ButtonWrapper>
              <Button
                solid
                type='button'
                onClick={this.handleClose}
                id='image-submit'
              >
                Close
              </Button>
            </ButtonWrapper>
          </ModalFooterWrapper>
        </ModalLargeWrapper>

        <BpModal
          open={this.state.deleteModal}
          title={deleteAsset.type === "IMAGE" ? "Delete Image" : "Delete Video"}
          primaryAction={{
            btnLabel:
              deleteAsset.type === "IMAGE" ? "Delete Image" : "Delete Video",
            action: this.deleteAsset,
            loading
          }}
          secondaryAction={{
            btnLabel: "Cancel",
            action: () => {
              this.setState({ deleteModal: false, deleteAsset: {} });
            }
          }}
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
          contentLabel={
            deleteAsset.type === "IMAGE" ? "Delete Image" : "Delete Video"
          }
          body={
            deleteAsset && (
              <div>
                <p>
                  Are you sure you want to delete this media asset? This cannot
                  be undone.
                </p>
                <p>
                  {deleteAsset.type === "IMAGE" ? (
                    <img
                      src={`${process.env.REACT_APP_CLOUDINARY_IMAGE_BASEURL}/upload/c_scale,f_auto,h_96,q_auto/c_crop,g_center,h_96,w_144/${deleteAsset.name}.${deleteAsset.format}`}
                      alt='to delete'
                    />
                  ) : (
                    <img
                      src={`${process.env.REACT_APP_CLOUDINARY_VIDEO_BASEURL}/upload/c_scale,f_auto,h_96,q_auto/${deleteAsset.name}.jpg`}
                      alt='to delete'
                    />
                  )}
                </p>
              </div>
            )
          }
          width='auto'
          zIndex='1002'
        />
        <Modal
          isOpen={this.state.previewIsOpen}
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
          style={customStyles}
          contentLabel='Video Preview'
          appElement={document.getElementById("root")}
        >
          <video style={{ maxWidth: "100%", maxHeight: "63vh" }} controls>
            <source
              src={`${process.env.REACT_APP_CLOUDINARY_VIDEO_BASEURL}/upload/${this.state.previewItem}.webm`}
              type='video/webm'
            />
            <source
              src={`${process.env.REACT_APP_CLOUDINARY_VIDEO_BASEURL}/upload/${this.state.previewItem}.mp4`}
              type='video/mp4'
            />
            <source
              src={`${process.env.REACT_APP_CLOUDINARY_VIDEO_BASEURL}/upload/${this.state.previewItem}.ogv`}
              type='video/ogg'
            />
            Your browser does not support this video type
          </video>
          <PreviewModalWrapper>
            <Button type='button' onClick={this.handlePreviewClose}>
              close
            </Button>
          </PreviewModalWrapper>
        </Modal>
      </ModalWrapper>
    );
  }
}

export default MediaManager;
