import React from "react";
// import { useRouteMatch } from 'react-router-dom';
import styled from "styled-components";
import * as _ from "underscore";
import axios from "axios";
// import { io } from 'socket.io-client';
import PhoneInput from "react-phone-input-2";
import {
  contactDeleteById,
  contactGetById,
  statusesGetByCompany,
  statusesGetByIndustry,
  contactUpdate,
  tagsGetByCompany
} from "../../../utils/ContactAPI";
import queryString from "query-string";
import BpModal from "../../elements/BpModal";
import { LoaderDotsSmall } from "../../elements/Loaders";
import ContactInfo from "./ContactInfo";
import ContactNotes from "./ContactNotes";
import ContactMessages from "./ContactMessages";
import { errorHandler, getInitials } from "../../../utils/Helpers";
// Styled Components
import Card from "../../styled/Card";
import {
  InputContainer,
  InputBoxWrapper,
  InputLabel,
  Dropdown
} from "../../styled/Input";
import { getStripeInfo, isConnectActive } from "../../../utils/StripeAPI";
import {
  messageContactUnread,
  messagesGetThread,
  messagesMarkRead,
  messagesSend,
  messageUpdate
} from "../../../utils/MessagesAPI";
import { TitleLeft, TitleRight, TitleWrapper } from "../../styled/Layout";
import ContactMsgBtn from "./ContactMsgBtn";
import LoadingCircle from "../../styled/LoadingCircle";

const DeleteBtn = styled.div`
  display: flex;
  justify: center;
  align-items: center;
`;

const Back = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .material-icons {
    font-size: 44px;
  }
`;

const ContactInitialWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const MessageWrapper = styled.div`
  margin: 0;
  padding: 0;
  @media only screen and (min-width: 901px) {
    background-color: ${props => props.theme.BGC};
    padding: 1em;
  }
`;

const ContactTabsWrapper = styled.div`
  display: none;
  grid-template-columns: ${props =>
    props.showMessages ? "1fr 1fr 1fr" : "1fr 1fr"};
  padding: 0;
  @media only screen and (min-width: 901px) {
    display: grid;
  }
`;

const ContactTab = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-color: ${props => (props.selected ? props.theme.BGC : "#EBE8ED")};
  color: ${props => (props.selected ? props.theme.HC2 : props.theme.HC1)};
  padding: 12px;
  border-right: 2px solid white;
  cursor: pointer;
  &:last-child {
    border: none;
  }
  @media only screen and (max-width: 990px) {
    padding: 0 12px;
  }
`;

const ContactTabIcon = styled.span`
  font-size: 36px;
  color: inherit;
  font-weight: bolder;
  display: inline-block;
  padding: 5px;
`;

const ContactTabTitle = styled.span`
  position: relative;
  border-bottom: 0;
  font-size: 20px;
  font-weight: bolder;
  display: inline-block;
  padding: ${props => (props.active ? "5px 5px 1px 5px" : "5px")};
  @media only screen and (max-width: 990px) {
    font-size: 16px;
  }
`;

const ContactDropdown = styled.div`
  display: block;
  width: 18rem;
  margin: auto;
  @media only screen and (min-width: 901px) {
    display: none;
  }
`;

const ContactName = styled.h3`
  font-size: 22px;
  color: ${props => props.theme.HC1};
  margin: 0 0 10px;
  @media only screen and (min-width: 901px) {
    font-size: 2em;
    margin: 1em;
  }
`;

const ContactInitials = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: gray;
  color: white;
  font-size: 2em;
  font-weight: bold;
  margin: 14px;
  @media only screen and (min-width: 901px) {
    width: 82px;
    height: 82px;
    font-size: 3em;
  }
`;

const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 0 22px 0;
  padding: 0 0 22px 0;
  border-bottom: 1px solid ${props => props.theme.HC1};
  @media only screen and (min-width: 901px) {
    width: auto;
    justify-content: space-between;
    margin: 0 20px;
    padding: 0 15px;
    border-right: 1px solid ${props => props.theme.HC1};
    border-left: 1px solid ${props => props.theme.HC1};
    border-bottom: 0;
  }
`;

const ActionButton = styled.a`
  display: flex;
  position: relative;
  margin: 0 8px;
  border-radius: 50%;
  color: ${props => (!props.disabled ? props.theme.HC2 : props.theme.TextC)};
  width: 37px;
  height: 37px;
  justify-content: center;
  align-items: center;
  font-size: 34px;
  cursor: pointer;
  span {
    font-size: 37px;
  }
`;

const TagsList = styled.ul`
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  /* width: 100%; */
`;

const Tag = styled.li`
  /* background: ${props => (props.color ? props.color : props.theme.HC1)}; */
  border-radius: 2px;
  color: ${props => props.theme.CTextC};
  display: flex;
  font-size: 16px;
  font-weight: 300;
  list-style: none;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 0px 10px;
  border-radius: 1.2rem;
  white-space: nowrap;
  overflow: hidden;
  line-height: 1.8em;
  max-height: 1.8em;
  text-overflow: ellipsis;
  max-width: 13em;
  align-items: center;
`;

const TagColor = styled.span`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 7px;
  background: ${props => (props.color ? props.color : props.theme.HC1)};
`;

const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px 0;
  @media only screen and (min-width: 901px) {
    box-shadow: 0px 0px 16px #00000015;
    margin: 0 3em 0;
    padding: 0;
  }
`;
const UnreadBubble = styled.span`
  background-color: ${props => props.theme.HCErr2};
  width: 18px;
  height: 18px;
  position: absolute;
  display: block;
  border-radius: 50%;
  left: -15px;
  top: -8px;
`;

class ContactsView extends React.Component {
  state = {
    contact: {},
    contactId: "",
    contactTags: [],
    contactPhone: "",
    contactStatuses: [],
    contactStatus: {},
    userCompany: {},
    loadingContact: true,
    companyUsers: [],
    companyTags: [],
    deletingContact: false,
    modalDeleteConfirm: false,
    modalSignUpMessaging: false,
    unreadMessages: 0,
    showNumDropdown: false,
    phoneNumber: "",
    panelShowing: "info",
    loadingMessages: false,
    contactMessages: [],
    stripeInfo: {},
    noConnect: false,
    contactChanged: false,
    queryPage: 0,
    backLocation: "contacts"
  };

  async componentDidMount() {
    const {
      computedMatch: {
        params: { contactId }
      }
    } = this.props;
    console.log("props", this.props.match.params, this.props.authUser);
    const query = queryString.parse(this.props.location.search);
    const { panel = "info", page, back: backLocation } = query;
    this.setState({
      contactId,
      panelShowing: panel,
      ...(panel === "messages" && { loadingMessages: true }),
      ...(page && { queryPage: page }),
      ...(backLocation && { backLocation })
    });
    this.getUnreadMsgCount(contactId);
    const stripeInfo = await getStripeInfo();
    this.setState({ stripeInfo });
    document.addEventListener("scroll", () => {
      const min = window.scrollY > 10;
      if (min !== this.state.min) {
        this.setState({ min });
      }
    });
    try {
      const contact = await contactGetById(contactId);
      console.log("contact", contact);
      this.setState({ contact, loadingContact: false }, async () => {
        const statuses = await this.getStatuses();
        this.setState({ contactStatuses: statuses });
        this.getContactStatus(contact, statuses);
      });
      const { mobilePhone, phone } = contact;
      const contactPhone = mobilePhone ? mobilePhone : phone;
      this.setState({ contactPhone });

      // todo get unread message counts

      const tags = await tagsGetByCompany();
      this.setState({ companyTags: tags });

      if (panel === "messages") {
        // this.startSocket();
        this.showMessages();
        this.checkSubscriptions();
      }
      // todo log event
    } catch (err) {
      console.log(err.message);
    }
  }

  componentDidUpdate = async (prevProps, prevState) => {
    const { contact: { tagIds = [] } = {}, companyTags = [] } = this.state;
    const {
      contact: { tagIds: prevTagIds = [] } = {},
      companyTags: prevCompanyTags = []
    } = prevState;
    const { userCompany: { industry } = {} } = this.props;
    const { userCompany: { industry: prevIndustry } = {} } = prevProps;
    if (
      (tagIds && prevTagIds && tagIds.length !== prevTagIds.length) ||
      companyTags.length !== prevCompanyTags.length
    ) {
      if (prevTagIds && tagIds.length > 0 && companyTags) {
        this.getTags();
      } else {
        this.setState({ contactTags: [] });
      }
    }
    if (industry !== prevIndustry) {
      const statuses = await this.getStatuses();
      this.setState({ contactStatuses: statuses });
      const stripeInfo = await getStripeInfo();
      this.setState({ stripeInfo });
    }
  };

  checkSubscriptions = async () => {
    const stripeInfo = await getStripeInfo();
    const connectActive = isConnectActive(stripeInfo.subscriptions);
    this.setState({ noConnect: !connectActive });
  };

  getTags = () => {
    const { contact: { tagIds = [] } = {}, companyTags } = this.state;
    const contactTags = tagIds.map(tag => {
      const matchingCompanyTag = companyTags.find(cTag => cTag._id === tag);
      if (matchingCompanyTag) {
        return matchingCompanyTag;
      } else {
        console.log("companyTag missing for", tag);
        return { name: tag, _id: tag };
      }
    });
    contactTags.sort(function (a, b) {
      if (a.name && b.name) {
        return a.name.localeCompare(b.name, "en", { sensitivity: "base" });
      } else {
        return 0;
      }
    });
    this.setState({ contactTags });
  };

  getTagsByCompany = async () => {
    try {
      const tags = await tagsGetByCompany();
      this.setState({ companyTags: tags }, () => {
        this.getTags();
      });
      return tags;
    } catch (err) {
      console.log(err.message);
    }
  };

  getStatuses = async () => {
    const { userCompany: { industry } = {} } = this.props;
    try {
      const companyStatuses = await statusesGetByCompany();
      if (companyStatuses.length > 0) {
        return companyStatuses;
      } else if (industry) {
        const statuses = await statusesGetByIndustry(industry);
        console.log("statuses", statuses);
        return statuses;
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  startSocket = () => {
    const updateDebounce = _.debounce(this.showMessages, 100);
    // todo connect to message socket
    // const socket = io(BP_NODE_SERVER, {
    //   query: { companyID },
    //   auth: { uid }
    // });
    // socket.on('connect', () => {
    //   console.log(`messaging socket connected`);
    //   socket.emit('messageRoom');
    // });
    // socket.on('messagesUpdate', () => {
    //   updateDebounce();
    // });
  };

  getUnreadMsgCount = async contactId => {
    try {
      const unreadMessages = await messageContactUnread(contactId);
      this.setState({ unreadMessages });
    } catch (err) {
      console.log(err.message);
    }
  };

  updateContact = async contact => {
    try {
      this.setState({ contact });
      console.log("contact to edit", contact);
      const updatedContact = await contactUpdate(contact);
      this.getContactStatus(contact);
      console.log("updated contact", updatedContact);
      return updatedContact;
    } catch (err) {
      console.log(err.message);
    }
  };

  getContactStatus = async (contact, statuses) => {
    let contactStatuses;
    if (!statuses) {
      contactStatuses = this.state.contactStatuses;
    } else {
      contactStatuses = statuses;
    }
    if (contact.statusId) {
      const matchingStatus = contactStatuses.find(
        s => s._id === contact.statusId
      );
      if (matchingStatus) {
        this.setState({ contactStatus: matchingStatus });
      }
    }
  };

  deleteContact = async (e, deleteConfirmed) => {
    if (!deleteConfirmed) {
      this.setState({ modalDeleteConfirm: true });
    } else {
      const { contactId } = this.state;
      const { alert } = this.props;
      try {
        this.setState({ deletingContact: true });
        await contactDeleteById(contactId);
        this.setState({
          deletingContact: false,
          modalDeleteConfirm: false
        });
        alert({ type: "success", msg: "Contact deleted" });
        this.props.history.push(
          `/contacts${
            this.state.queryPage ? `&page=${this.state.queryPage}` : ""
          }`
        );
      } catch (err) {
        this.setState({ modalDeleteConfirm: false, deletingContact: false });
        console.log("error setting new contact", err);
        alert({
          type: "error",
          msg: "An error occurred. Please refresh and try again."
        });
      }
    }
  };

  savePhoneNumber = async () => {
    const { alert } = this.props;
    const { contact, newPhone } = this.state;
    try {
      await this.updateContact({
        ...contact,
        phone: newPhone
      });
      this.setState({ modalPhoneMissing: false });
    } catch (err) {
      console.log("error saving field", err);
      alert({
        type: "error",
        msg: "Something went wrong saving the field. Please try again. If you continue to get this message, contact Boostpoint at help@boostpoint.com"
      });
      return false;
    }
  };

  handleMobileChange = async input => {
    const valueRaw = input.target.value;
    const { alert } = this.props;
    try {
      if (valueRaw && valueRaw !== "+1" && valueRaw !== "+") {
        const value = valueRaw.replace(/[^0-9]+/g, "");
        const phone = `+${value}`;
        this.setState({ newPhone: phone });
      } else {
        this.setState({ newPhone: "" });
      }
    } catch (err) {
      console.log("error saving field", err);
      alert({
        type: "error",
        msg: "Something went wrong saving the field. Please try again. If you continue to get this message, contact Boostpoint at help@boostpoint.com"
      });
      return false;
    }
  };

  handleNavDropdown = value => {
    if (value === "info") {
      this.showInfo();
    } else if (value === "notes") {
      this.showNotes();
    } else if (value === "messages") {
      this.showMessages();
    }
  };

  showInfo = () => {
    this.setState({ panelShowing: "info" });
    const { contactId } = this.state;
    this.props.history.push(
      `/contacts/${contactId}?panel=info${
        this.state.queryPage ? `&page=${this.state.queryPage}` : ""
      }`
    );
  };

  showNotes = () => {
    this.setState({ panelShowing: "notes" });
    const { contactId } = this.state;
    this.props.history.push(
      `/contacts/${contactId}?panel=notes${
        this.state.queryPage ? `&page=${this.state.queryPage}` : ""
      }`
    );
  };

  showMessages = async () => {
    try {
      const {
        userProfile,
        userCompany: { _id: companyId }
      } = this.props;
      let {
        contactId,
        contact: { phone, mobilePhone },
        contactMessages
      } = this.state;
      this.checkSubscriptions();
      if (contactMessages.length === 0) {
        this.setState({ loadingMessages: true });
      }
      this.props.history.push(
        `/contacts/${contactId}?panel=messages${
          this.state.queryPage ? `&page=${this.state.queryPage}` : ""
        }`
      );
      let phoneMessages = [];
      if (phone) {
        phoneMessages = await this.getConversation(
          userProfile.companyId,
          contactId,
          phone
        );
      }
      let mobilePhoneMessages = [];
      if (mobilePhone) {
        mobilePhoneMessages = await this.getConversation(
          userProfile.companyId,
          contactId,
          mobilePhone
        );
      }
      let contactPhone = mobilePhone ? mobilePhone : phone;
      if (phoneMessages.length > 0 && !mobilePhoneMessages.length > 0) {
        contactMessages = phoneMessages;
        contactPhone = phone;
      } else if (!phoneMessages.length > 0 && mobilePhoneMessages.length > 0) {
        contactMessages = mobilePhoneMessages;
        contactPhone = mobilePhone;
      } else if (phoneMessages.length > 0 && mobilePhoneMessages.length > 0) {
        if (phoneMessages[0].dateCreated > mobilePhoneMessages[0].dateCreated) {
          contactMessages = phoneMessages;
          contactPhone = phone;
        } else {
          contactMessages = mobilePhoneMessages;
          contactPhone = mobilePhone;
        }
      }
      this.setState({
        contactMessages,
        contactPhone,
        loadingMessages: false,
        panelShowing: "messages"
      });
      await messagesMarkRead({ companyId, contactId });
      this.getUnreadMsgCount();
      this.props.updateUnreadMsgCount();
    } catch (err) {
      console.log(err.message);
      this.setState({ loadingMessages: false });
    }
  };

  updateMessages = () => {
    this.setState({
      contactPhone: '',
      contactMessages: []
    }, () => {
      this.showMessages();
    })
  }

  contactChange = change => {
    this.setState({ contactChanged: change });
  };

  onBack = async () => {
    const {
      contactChanged,
      queryPage,
      backLocation = "contacts",
      contact: { _id: contactId, mobilePhone, phone } = {}
    } = this.state;
    if (contactChanged) {
      const { alert } = this.props;
      alert({
        type: "error",
        msg: "Please finish saving your contact or cancel"
      });
    } else {
      const contactPhone = mobilePhone ? mobilePhone : phone;
      const queryParams = [];
      if (backLocation === "messages") {
        queryParams.push(`contactId=${contactId}`);
        if (contactPhone) {
          queryParams.push(`contactPhone=${encodeURIComponent(contactPhone)}`);
        }
        if (queryPage) {
          queryParams.push(`page=${queryPage}`);
        }
      }
      this.props.history.push(
        `/${backLocation}${
          queryParams.length > 0 ? `?${queryParams.join("&")}` : ""
        }`
      );
    }
  };

  getConversation = async (companyId, contactId, contactPhone) => {
    try {
      // encodeURIComponent
      const conversationResponse = await messagesGetThread({
        companyId,
        contactId
      });
      return conversationResponse;
    } catch (err) {
      console.log(err.message);
    }
  };

  validateMsg = () => {
    const { contact, contactPhone, noConnect } = this.state;
    const { userCompany, alert, smsCredits } = this.props;
    const { twilioPhone } = userCompany;
    const to = contactPhone
      ? contactPhone
      : contact.mobilePhone
      ? contact.mobilePhone
      : contact.phone;
    if (noConnect) {
      alert({
        type: "error",
        msg: (
          <span>
            You do not have an active Boostpoint Connect subscription.{" "}
            <a
              href='/integrations/BoostpointConnect?signup=true'
              style={{ color: "white" }}
            >
              Please sign up for Boostpoint Connect.
            </a>
          </span>
        )
      });
      return false;
    }
    if (!twilioPhone) {
      alert({
        type: "error",
        msg: (
          <span>
            Your phone number is missing.{" "}
            <a
              href='/integrations/BoostpointConnect?signup=true'
              style={{ color: "white" }}
            >
              Please complete the sign-up process to select a phone number.
            </a>
          </span>
        )
      });
      return false;
    }
    if (smsCredits < 1) {
      alert({
        type: "error",
        msg: (
          <span>
            You are out of message credits. Please contact us at{" "}
            <a
              href='mailto:help@boostpoint.com'
              target='_blank'
              rel='noopener noreferrer'
              style={{ color: "white" }}
            >
              help@boostpoint.com
            </a>{" "}
            to get more.
          </span>
        )
      });
      return false;
    }
    if (to === twilioPhone) {
      console.log("error sending message - to matches smsPhone");
      alert({
        type: "error",
        msg: (
          <span>
            Error sending message. Please reload page. If you continue to get
            this error, contact us at{" "}
            <a
              href='mailto:help@boostpoint.com'
              target='_blank'
              rel='noopener noreferrer'
              style={{ color: "white" }}
            >
              help@boostpoint.com
            </a>
          </span>
        )
      });
      return false;
    }
    return true;
  };

  sendMessage = async ({ msgBody, mediaUrl = [] }) => {
    const { contact, contactMessages, contactPhone } = this.state;
    const { _id: contactId } = contact;
    const { userCompany } = this.props;
    const { _id: companyId, twilioPhone } = userCompany;
    const valid = this.validateMsg();
    if (!valid) {
      console.log("not valid");
      return false;
    }
    const to = contactPhone
      ? contactPhone
      : contact.mobilePhone
      ? contact.mobilePhone
      : contact.phone;
    try {
      this.setState({ sending: true });
      const newMessage = {
        body: msgBody,
        direction: "outbound-api",
        from: twilioPhone,
        to,
        smsStatus: "queued",
        companyId,
        contactId
      };
      if (mediaUrl.length > 0) {
        newMessage.mediaUrl = mediaUrl;
      } else {
        newMessage.mediaUrl = [];
      }
      contactMessages.splice(0, 0, newMessage);
      this.setState({ contactMessages });
      const msgResponse = await messagesSend({
        body: msgBody,
        from: twilioPhone,
        to,
        companyId: companyId.toString(),
        contactId: contactId.toString(),
        mediaUrl,
        env: process.env.NODE_ENV
      });
      contactMessages.splice(0, 1, msgResponse);
      this.setState({ contactMessages, sending: false });
      return msgResponse;
    } catch (err) {
      console.log(err.message);
    }
  };

  render() {
    const { userProfile = {}, userCompany } = this.props;
    const {
      contact = {},
      contactId,
      contactStatuses,
      contactStatus,
      deletingContact,
      companyUsers,
      unreadMessages,
      panelShowing,
      contactMessages,
      loadingMessages,
      contactPhone,
      contactTags = [],
      companyTags = [],
      stripeInfo: { subscriptions = [] } = {}
    } = this.state;
    const { firstName = "", lastName = "", phone = "", email = "" } = contact;
    return (
      <div>
        <TitleWrapper>
          <TitleLeft mobileStacking>
            <ContactInitialWrapper>
              <Back>
                <a
                  href={`/${this.state.backLocation}${
                    this.state.queryPage ? `&page=${this.state.queryPage}` : ""
                  }`}
                  onClick={e => {
                    e.preventDefault();
                    this.onBack();
                  }}
                >
                  <span className='material-icons'>navigate_before</span>
                </a>
              </Back>
              <ContactInitials>{getInitials(contact)}</ContactInitials>
            </ContactInitialWrapper>
            <ContactName>
              {firstName} {lastName}
            </ContactName>
            <BtnWrapper>
              <ActionButton
                className='call-button'
                href={`tel:${phone}`}
                title='Call Contact'
              >
                <span className='material-icons' title='Call Contact'>
                  phone_in_talk
                </span>
              </ActionButton>
              <ActionButton
                href={`mailto:${email}`}
                target='_blank'
                title='Email Contact'
              >
                <span className='material-icons' title='Email Contact'>
                  email
                </span>
              </ActionButton>
              {isConnectActive(subscriptions) && (
                <ContactMsgBtn
                  twilioPhone={userCompany.twilioPhone}
                  mobilePhone={contact.mobilePhone}
                  phone={contact.phone}
                  unreadMessages={unreadMessages}
                  btnDisabled={userCompany.twilioPhone ? false : true}
                />
              )}
            </BtnWrapper>
            <TagsList>
              {!!contactTags &&
                contactTags.map((tag, i) => (
                  <Tag key={i}>
                    <TagColor
                      color={tag.color ? tag.color : "#675da8"}
                    ></TagColor>
                    {tag.name}
                  </Tag>
                ))}
            </TagsList>
          </TitleLeft>
          <TitleRight>
            <DeleteBtn onClick={e => this.deleteContact(e)}>
              <span className='material-icons'>delete</span>
            </DeleteBtn>
          </TitleRight>
        </TitleWrapper>

        {contact ? (
          <ContactWrapper className='cardSection__campaign'>
            <ContactTabsWrapper showMessages={isConnectActive(subscriptions)}>
              <ContactTab
                onClick={this.showInfo}
                selected={panelShowing === "info"}
              >
                <ContactTabIcon className='material-icons icon'>
                  account_box
                </ContactTabIcon>
                <ContactTabTitle>Contact Info</ContactTabTitle>
              </ContactTab>
              <ContactTab
                onClick={this.showNotes}
                selected={panelShowing === "notes"}
              >
                <ContactTabIcon className='material-icons icon'>
                  feed
                </ContactTabIcon>
                <ContactTabTitle>Notes</ContactTabTitle>
              </ContactTab>

              {isConnectActive(subscriptions) && (
                <ContactTab
                  onClick={() => {
                    this.showMessages();
                    this.startSocket();
                  }}
                  selected={panelShowing === "messages"}
                >
                  <ContactTabIcon className='material-icons icon'>
                    chat
                  </ContactTabIcon>
                  <ContactTabTitle>
                    Messages{unreadMessages > 0 && <UnreadBubble />}
                  </ContactTabTitle>
                </ContactTab>
              )}
            </ContactTabsWrapper>
            <ContactDropdown>
              <Dropdown
                name='nav-dropdown'
                required
                data-namereadable='Status'
                value={panelShowing}
                onChange={input => this.handleNavDropdown(input.target.value)}
              >
                <option value='info'>Contact Info</option>
                <option value='notes'>Notes</option>
                <option value='messages'>Messages</option>
              </Dropdown>
            </ContactDropdown>
            <div>
              {panelShowing === "info" && (
                <ContactInfo
                  contact={contact}
                  userProfile={userProfile}
                  userCompany={userCompany}
                  contactStatuses={contactStatuses}
                  contactStatus={contactStatus}
                  companyUsers={companyUsers}
                  contactTags={contactTags}
                  companyTags={companyTags}
                  alert={this.props.alert}
                  updateContact={this.updateContact}
                  getTags={this.getTagsByCompany}
                  contactChange={this.contactChange}
                />
              )}
              {panelShowing === "notes" && (
                <MessageWrapper>
                  <ContactNotes
                    contact={contact}
                    userProfile={userProfile}
                    updateContact={this.updateContact}
                  />
                </MessageWrapper>
              )}
              {panelShowing === "messages" ? (
                loadingMessages ? (
                  <LoaderDotsSmall />
                ) : (
                  <ContactMessages
                    contactView={true}
                    contact={contact}
                    contactId={contactId}
                    contactMessages={contactMessages}
                    userProfile={userProfile}
                    userCompany={userCompany}
                    sendMessage={this.sendMessage}
                    sending={this.state.sending}
                    alert={this.props.alert}
                    embedded={true}
                    contactPhone={contactPhone}
                    updateConversationList={this.updateMessages}
                  />
                )
              ) : null}
            </div>
          </ContactWrapper>
        ) : (
          <div>
            <LoadingCircle />
          </div>
        )}
        <BpModal
          open={this.state.modalDeleteConfirm}
          title='Confirm Contact Delete'
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
          contentLabel='Confirm Contact Delete'
          onCloseMethod={() => {
            this.setState({ modalDeleteConfirm: false });
          }}
          primaryAction={{
            btnLabel: "Delete Contact",
            action: e => this.deleteContact(e, true),
            loading: deletingContact
          }}
          secondaryAction={{
            btnLabel: "Cancel",
            action: () => this.setState({ modalDeleteConfirm: false })
          }}
          body={
            <p>
              Are you sure you want to delete this contact? You cannot undo this
              action.
            </p>
          }
        />
        <BpModal
          open={this.state.modalSignUpMessaging}
          title='Text your customers with Boostpoint Connect'
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
          contentLabel='Text your customers with Boostpoint Connect'
          onCloseMethod={() => {
            this.setState({ modalSignUpMessaging: false });
          }}
          primaryAction={{
            btnLabel: "Sign Me Up",
            action: () => {
              this.setState({ modalSignUpMessaging: false });
              this.props.history.push(
                "/integrations/BoostpointConnect?signup=true"
              );
            }
          }}
          secondaryAction={{
            btnLabel: "Cancel",
            action: () => this.setState({ modalSignUpMessaging: false })
          }}
          body={
            <p>
              Messaging and Workflows are a part of our Boostpoint Connect
              add-on. These features help you connect with your leads both
              individually and automatically. Send and receive texts from your
              Boostpoint contacts all from one powerful platform. Get started
              with Boostpoint Connect today.
            </p>
          }
        />
        <BpModal
          open={this.state.modalPhoneMissing}
          title='It looks like this contact is missing a phone number'
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
          contentLabel='It looks like this contact is missing a phone number'
          onCloseMethod={() => {
            this.setState({ modalPhoneMissing: false });
          }}
          primaryAction={{
            btnLabel: "Save Number",
            action: this.savePhoneNumber
          }}
          secondaryAction={{
            btnLabel: "Cancel",
            action: () => this.setState({ modalPhoneMissing: false })
          }}
          body={
            <div>
              <p>
                You need to add a phone number before you are able to text this
                contact. Enter one below to start texting them.
              </p>
              <InputContainer>
                <InputLabel>Mobile Phone</InputLabel>
                <InputBoxWrapper>
                  <PhoneInput
                    ref={input => {
                      this.mobilePhone = input;
                    }}
                    type='text'
                    name='mobilePhone'
                    data-namereadable='mobile phone'
                    value={contact.mobilePhone}
                    onBlur={input => this.handleMobileChange(input)}
                    country='us'
                    regions={["north-america"]}
                    inputStyle={{
                      backgroundColor: "#f7f7f7",
                      border: "2px solid #f0f0f0"
                    }}
                    buttonStyle={{
                      backgroundColor: "#f7f7f7",
                      border: "2px solid #f0f0f0"
                    }}
                    prefix='+'
                  />
                </InputBoxWrapper>
              </InputContainer>
            </div>
          }
        />
      </div>
    );
  }
}

export default ContactsView;
