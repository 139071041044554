import styled from "styled-components";

export const SummaryContainer = styled.div`
  background: #f7f7f7;
  padding: 1rem;
  border-radius: 4px;
  max-width: 250px;
  margin: 0.75rem auto;
`;

export const SummaryLine = styled.div`
  display: grid;
  grid-template-columns: 1fr 85px;
  div {
    margin: 4px;
    display: flex;
    align-items: center;
  }
  .creditsDescription {
    text-align: left;
  }
  .creditsAmount {
    background: white;
    border-radius: 8px;
    padding: 5px;
    justify-content: center;
  }
`;
