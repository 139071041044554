import styled from "styled-components";

export const H1 = styled.h2`
  font-weight: bold;
  font-size: 1.3em;
  color: ${props => props.theme.HC1};
`;

export const H4 = styled.h4`
  margin: 2em auto;
  text-align: center;
  text-transform: ${props => (props.uppercase ? "uppercase" : "none")};
`;
