import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-alert";
import ReactGA from "react-ga";
import * as Sentry from "@sentry/browser";
import LogRocket from "logrocket";
import { hotjar } from "react-hotjar";
import AlertTemplate from "./components/alerts/react-alert-template";

// Import styles
import "./css/style.css";
import "./css/bootstrap-datetimepicker-standalone.css";

// Import components
import App from "./components/App";

// Service Worker
import registerServiceWorker, { unregister } from "./registerServiceWorker";

// eslint-disable-next-line @typescript-eslint/no-var-requires
require("dotenv").config();

if (process.env.NODE_ENV === "production") {
  LogRocket.init("ewaedc/boostpoint");
}

if (process.env.NODE_ENV === "development") {
  console.log(`running in ${process.env.NODE_ENV} mode`);
}

// Initialize Sentry. this will start logging all uncaught exceptions.
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN
  });
}

// Init Hotjar for analytics and heatmaping
if (process.env.NODE_ENV === "production") {
  const hjid = process.env.REACT_APP_HJID;
  const hjsv = 6;
  hotjar.initialize(hjid, hjsv);
}

const options = {
  timeout: 5000,
  position: "top center",
  zIndex: 10000
};

const Main = () => (
  <BrowserRouter>
    <Provider
      template={AlertTemplate}
      {...options}
    >
      <App />
    </Provider>
  </BrowserRouter>
);

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

ReactDOM.render(<Main />, document.getElementById("root"));
unregister();
//This comment isn't important
