import axios from "axios";
import { axiosResponse, errorHandler } from "./Helpers";
export const deletedStatus = "61b7b7438dbfea0805c50652";

const BP_API = process.env.REACT_APP_BP_API;

export const newContact = {
  companyId: "",
  firstName: "",
  lastName: "",
  phone: "",
  mobilePhone: "",
  email: "",
  alternateEmail: "",
  tagIds: [],
  formId: "",
  leadId: "",
  adId: "",
  created: new Date(),
  campaignName: "",
  campaignId: "",
  notes: [],
  source: "MANUAL_ENTRY",
  statusId: "",
  website: "",
  address: "",
  address2: "",
  city: "",
  state: "",
  zipCode: "",
  isDeleted: false
};

export const contactCreate = async contactObject => {
  try {
    const contact = axiosResponse(
      await axios.post(`${BP_API}/v1/contact`, contactObject)
    );
    return contact;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(`${status}: ${message}`);
  }
};

export const contactUpdate = async contactObject => {
  try {
    console.log("new contact", contactObject);
    const contact = axiosResponse(
      await axios.put(
        `${BP_API}/v1/contact/${contactObject._id}`,
        contactObject
      )
    );
    console.log("update", contact);
    return contact;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(`${status}: ${message}`);
  }
};

export const contactGetById = async contactId => {
  try {
    const contact = axiosResponse(
      await axios.get(`${BP_API}/v1/contact/${contactId}`)
    );
    return contact;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(`${status}: ${message}`);
  }
};

export const contactsGet = async (params = {}) => {
  try {
    const contacts = axiosResponse(
      await axios.get(`${BP_API}/v1/contact`, { params })
    );
    return contacts;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(`${status}: ${message}`);
  }
};

export const contactDeleteById = async contactId => {
  try {
    const contact = axiosResponse(
      await axios.delete(`${BP_API}/v1/contact/${contactId}`)
    );
    return contact;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(`${status}: ${message}`);
  }
};

export const statusesGetByIndustry = async industry => {
  try {
    const allStatuses = axiosResponse(
      await axios.get(`${BP_API}/v1/contact/stock/${industry}`)
    );
    const statuses = allStatuses.filter(status => status._id !== deletedStatus);
    statuses.sort((a, b) => {
      if (a.type === b.type) {
        if (a.orderNumber === b.orderNumber) {
          return a.status - b.status;
        } else {
          return a.orderNumber - b.orderNumber;
        }
      } else {
        return b.type - a.type;
      }
    });
    return statuses;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(`${status}: ${message}`);
  }
};

export const statusesGetByCompany = async () => {
  try {
    const statuses = axiosResponse(
      await axios.get(`${BP_API}/v1/contact-status`)
    );
    statuses.sort((a, b) => a.orderNumber - b.orderNumber);
    return statuses;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(`${status}: ${message}`);
  }
};

export const statusCreate = async newStatus => {
  try {
    const updatedStatus = axiosResponse(
      await axios.post(`${BP_API}/v1/contact-status`, newStatus)
    );
    return updatedStatus;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(`${status}: ${message}`);
  }
};

export const statusUpdate = async newStatus => {
  try {
    const updatedStatus = axiosResponse(
      await axios.put(`${BP_API}/v1/contact-status/${newStatus._id}`, newStatus)
    );
    return updatedStatus;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(`${status}: ${message}`);
  }
};

export const statusDelete = async statusId => {
  try {
    const updatedStatus = axiosResponse(
      await axios.delete(`${BP_API}/v1/contact-status/${statusId}`)
    );
    return updatedStatus;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(`${status}: ${message}`);
  }
};

export const tagsGetByCompany = async () => {
  try {
    const tags = axiosResponse(await axios.get(`${BP_API}/v1/tag`));
    tags.sort((a, b) => a.name - b.name);
    return tags;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(`${status}: ${message}`);
  }
};

export const tagCreate = async tag => {
  try {
    const newTag = axiosResponse(await axios.post(`${BP_API}/v1/tag`, tag));
    return newTag;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(`${status}: ${message}`);
  }
};

export const tagUpdate = async tag => {
  try {
    const updatedTag = axiosResponse(
      await axios.put(`${BP_API}/v1/tag/${tag._id}`, tag)
    );
    return updatedTag;
  } catch (err) {
    const { status, message } = errorHandler(err);
    console.log(status, message);
    throw new Error(`${status}: ${message}`);
  }
};
