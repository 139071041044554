import moment from "moment";
import React from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import styled from "styled-components";
import { errorHandler } from "../../../utils/Helpers";
import BpModal from "../../elements/BpModal";
import Loading from "../../elements/LoadingBar";
import StripePaymentFix from "./StripePaymentFix";
import StripeRecentTransactions from "./StripeRecentTransactions";
import UserAgreement from "./UserAgreement";

// Styled Components Import
import { Button } from "../../styled/Buttons";

import {
  getStripeInfo,
  stripeCreateCard,
  stripeGetPaymentMethods,
  stripeGetSubscriptions,
  stripePayInvoice,
  stripeUpdateCustomer
} from "../../../utils/StripeAPI";
import SubscriptionList from "./SubscriptionList";

const CCContainer = styled.div`
  width: 25em;
  margin: 1em auto;
  max-width: 90%;
  border: solid 0.5px rgb(238, 238, 238);
  padding: 0.5em;
`;

const ListGroup = styled.div`
  display: flex;
  max-width: 45em;
  margin: auto;
  @media (max-width: 670px) {
    flex-direction: column;
  }
`;
const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: ${props => (props.wide ? "auto" : "19em")};
  margin: 2em auto;
  max-width: 90%;
`;
const ListLabel = styled.label`
  font-weight: 600;
  color: ${props => props.theme.HC3};
  letter-spacing: 2.5px;
  margin-bottom: 0.5em;
`;
const CCListWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5em;
  margin-bottom: 1em;
  -webkit-box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
    0 1px 3px 1px rgba(60, 64, 67, 0.16);
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
    0 1px 3px 1px rgba(60, 64, 67, 0.16);
`;

const OpenRecentTransactions = styled.button`
  color: ${props => props.theme.HC1};
  font-weight: 600;
  font-size: 0.9em;
  margin-top: 10px;
  outline: ${props =>
    props.outlined ? `1px solid ${props.theme.HC1}` : "none"};
  padding: ${props => (props.outlined ? "6px 0" : "0")};
`;

const Icon = styled.span`
  position: relative;
  top: 2px;
  margin-right: 7px;
`;

const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const ButtonContainer = styled.p`
  text-align: center;
  margin-top: 0;
`;

const env = process.env.REACT_APP_STRIPE_ENV
  ? process.env.REACT_APP_STRIPE_ENV
  : process.env.NODE_ENV;

class StripeAccount extends React.Component {
  state = {
    subscriptionActive: false,
    ccNumber: null,
    ccExpiryMonth: null,
    ccExpiryYear: null,
    ccZip: null,
    ccBrand: null,
    ccValid: {},
    ccExists: false,
    ccExpiring: false,
    ccExpired: false,
    defaultSource: null,
    modalCancelSubscription: false,
    openChangeCard: false,
    openRecentTransactions: false,
    loading: false,
    transactions: [],
    transactionsLoading: false,
    transactionsFailed: [],
    lastTransactionId: null,
    startingTransactionId: null,
    transactionsHasMoreNext: false,
    transactionsHasMorePrev: false,
    transactionsPerPage: 100,
    dateRange: {
      startDate: moment().subtract(2, "weeks").toISOString(),
      endDate: moment().toISOString(),
      dateRangeSelected: false
    },
    custUpdateLoading: false,
    openStripePaymentFix: false,
    invoicePaymentFixId: null,
    stripeSkipTrialConfirm: false,
    termsAccepted: false,
    openTermsOfUse: false,
    openBetaAgreement: false,
    interval: "",
    openCampaigns: false,
    cancelReason: "",
    cancelReasonError: "",
    cancelReasonOther: "",
    hideCardElement: false,
    subType: "Standard",
    subscriptions: [],
    modalCorrectPaymentMethod: false,
    modalAddPaymentMethod: false,
    stripeInfo: {}
  };

  componentDidMount = async () => {
    try {
      const { registrationPaymentFailed } = this.props;
      if (registrationPaymentFailed) {
        this.setState({ modalCorrectPaymentMethod: true });
      }
      const stripeInfo = await getStripeInfo();
      const { stripeId } = stripeInfo;
      console.log("stripe info", stripeInfo);
      this.setState({ stripeId }, async () => {
        const { subscriptions } = await this.getSubscriptionStatus();
        let subscriptionActive = false;
        if (subscriptions.length > 0) {
          subscriptionActive = true;
        }
        this.setState({ subscriptionActive, subscriptions, stripeInfo });
        await this.getCCList();
        this.setState({ loading: false });
      });
    } catch (err) {
      console.log(err.message);
      this.setState({ loading: false, modalAddPaymentMethod: true });
    }
  };

  componentDidUpdate = prevProps => {
    const { paymentFailed } = this.props;
    const { paymentFailed: prevPaymentFailed } = prevProps;
    if (paymentFailed !== prevPaymentFailed && !paymentFailed) {
      // todo refresh stripe info
    }
  };

  updatePaymentMethod = async (updateCustomer = true, source) => {
    const { stripeId } = this.state;
    this.setState({ custUpdateLoading: true });
    const { alert } = this.props;
    let tokenId = source;
    try {
      if (!tokenId) {
        const { token } = await this.props.stripe.createToken({});
        console.log("tokehn", token);
        tokenId = token.id;
      }
      console.log("token id", tokenId);
      const createResponse = await stripeCreateCard({
        stripeId,
        source: tokenId
      });
      const { cardId } = createResponse;
      console.log("card source", createResponse);
      if (cardId) {
        this.setState({
          openChangeCard: false,
          custUpdateLoading: false
        });
      }
      alert({
        type: "success",
        msg: "Credit Card Updated Successfully"
      });
      if (updateCustomer) {
        await this.updateCustomer(cardId);
        await this.getCCList();
      }
      return cardId;
    } catch (err) {
      alert({
        type: "error",
        msg: "There was an error updating your credit card account. Please refresh and try again. If you've received this error before, please contact help@boostpoint.com."
      });
      const { status, message } = errorHandler(err);
      console.log("err", `${status}: ${message}`);
      this.setState({
        custUpdateLoading: false
      });
    }
  };

  updateCustomer = async source => {
    const { userCompany, userProfile } = this.props;
    const { stripeId } = this.state;
    const { email, firstName, lastName, uid } = userProfile;
    let companyName = "";
    if (userCompany.name) {
      companyName = userCompany.name;
    }

    this.setState({
      custUpdateLoading: true
    });
    const request = {
      stripeId,
      ...(source && { newSource: source }),
      description: companyName,
      metadata: { first_name: firstName, last_name: lastName },
      email
    };
    try {
      const response = await stripeUpdateCustomer(request);
      console.log("customer response", response);
      return response;
    } catch (err) {
      console.log("err updating customer", err.message);
    }
  };

  getCCList = async () => {
    const { stripeId } = this.state;
    if (stripeId) {
      try {
        const stripePaymentMethods = await stripeGetPaymentMethods(stripeId);
        if (stripePaymentMethods.length < 1) {
          return { ccExists: false };
        }
        const { ccExists, details } = stripePaymentMethods[0];
        if (ccExists) {
          const {
            last4,
            exp_month,
            exp_year,
            address_zip = "",
            brand,
            id
          } = details;
          this.setState({
            ccNumber: last4,
            ccExpiryMonth: exp_month,
            ccExpiryYear: exp_year,
            ccZip: address_zip,
            ccBrand: brand,
            ccExists: true,
            defaultSource: id,
            paymentMethodSelected: "default"
          });
          return details;
        } else {
          return { ccExists: false };
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  getSubscriptionStatus = async () => {
    const { stripeId } = this.state;
    if (stripeId) {
      try {
        const subResponse = await stripeGetSubscriptions(stripeId);
        console.log("stripe subscriptions", subResponse);
        return subResponse;
      } catch (err) {
        const { status, message } = errorHandler(err);
        console.log("err", `${status}: ${message}`);
        throw new Error(message);
      }
    }
  };

  formatDate = date =>
    date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();

  cancelSubscription = () => {
    const { modalCancelSubscription } = this.state;
    this.setState({ modalCancelSubscription: !modalCancelSubscription });
  };

  subToggleChange = async () => {
    const { subscriptionActive, modalStartSubscription } = this.state;
    if (subscriptionActive) {
      this.setState({
        modalCancelSubscription: true
      });
    } else {
      this.setState({ modalStartSubscription: !modalStartSubscription });
    }
  };

  stripeElementChange = cc => {
    // console.log('element change is ', cc);
    this.setState({ ccValid: cc });
  };

  validateCC = () => {
    const { ccValid } = this.state;
    return new Promise((resolve, reject) => {
      if (!ccValid.complete) {
        if (ccValid.empty && !ccValid.error) {
          console.error(ccValid);
          reject(
            "You need to enter a credit card to activate your subscription"
          );
        }
        if (!ccValid.error) {
          console.error(ccValid);
          reject(
            "There is an issue with your credit card. Please try to re-enter it."
          );
        }
        console.log(ccValid);
        if (ccValid.error) {
          console.error(ccValid);
          reject(ccValid.error.message);
        }
      } else {
        resolve(ccValid);
      }
    });
  };

  openRecentTransactions = () => {
    this.setState({ openRecentTransactions: true });
  };

  handleInvoicePaymentFixId = invoiceNum => {
    this.setState({
      invoicePaymentFixId: invoiceNum,
      openStripePaymentFix: true,
      hideCardElement: true
    });
  };

  handleCloseInvoiceFix = () => {
    this.setState(
      {
        invoicePaymentFixId: null,
        openStripePaymentFix: false,
        hideCardElement: false
      },
      () => {
        this.setState({ custUpdateLoading: false });
      }
    );
  };

  handleInvoicePaymentFixUpdate = async (setDefaultMethod, source) => {
    this.setState({
      custUpdateLoading: true,
      hideCardElement: true
    });
    let invoice = {};
    // let source;
    const { invoicePaymentFixId } = this.state;
    const { alert } = this.props;
    try {
      const addSourceResponse = await this.updatePaymentMethod(
        setDefaultMethod,
        source
      );
      if (!addSourceResponse) {
        return;
      }
      invoice = await this.handlePayInvoice(
        invoicePaymentFixId,
        addSourceResponse
      );
      if (invoice && invoice.status && invoice.status === "paid") {
        alert({
          type: "success",
          msg: "Invoice is paid. Thank you."
        });
        this.setState({
          openStripePaymentFix: false,
          invoicePaymentFixId: null,
          hideCardElement: false
        });
        this.openRecentTransactions();
      } else {
        console.log(invoice);
        alert({
          type: "error",
          msg: Error.general
        });
        this.setState({
          custUpdateLoading: false
        });
      }
      this.setState({ hideCardElement: false });
    } catch (err) {
      console.log("error paying invoice", err);
      alert({
        type: "error",
        msg: err.message
      });
    }
  };

  handlePayInvoice = async (invoiceId, source) => {
    const { invoicePaymentFixId, defaultSource } = this.state;
    this.setState({
      custUpdateLoading: true
    });
    let options = {};
    if (!invoiceId || typeof invoiceId !== "string") {
      options = {
        ...options,
        invoiceId: invoicePaymentFixId
      };
    } else {
      options = {
        ...options,
        invoiceId
      };
    }
    if (source) {
      options = {
        ...options,
        source
      };
    } else {
      options = {
        ...options,
        source: defaultSource
      };
    }
    console.log("options", options);
    try {
      const response = await stripePayInvoice(options);
      console.log("pay response", response);
      this.setState({
        custUpdateLoading: false
      });
      return Promise.resolve(response);
    } catch (err) {
      this.setState({
        custUpdateLoading: false
      });
      console.log(err.message);
      if (err.message) {
        return Promise.reject(err.message);
      }
      return Promise.reject(err);
    }
  };

  handlePayInvoiceClick = async (invoiceId, source) => {
    await this.handlePayInvoice(invoiceId, source);
    this.openRecentTransactions();
  };

  handleUserAgreementCheckbox = () => {
    const { termsAccepted } = this.state;
    this.setState({ termsAccepted: !termsAccepted });
  };

  openUserAgreement = input => {
    const { id } = input.target;
    if (id === "termsOfUse") {
      this.setState({ openTermsOfUse: true });
    }
    if (id === "betaAgreement") {
      this.setState({ openBetaAgreement: true });
    }
  };

  closeUserAgreement = () => {
    this.setState({
      openTermsOfUse: false,
      openBetaAgreement: false
    });
  };

  handleCancelOther = input => {
    this.setState({ cancelReasonOther: input.target.value });
  };

  switchPaymentMethod = newMethod => {
    const { paymentMethodSelected } = this.state;
    if (paymentMethodSelected !== newMethod) {
      this.setState({ paymentMethodSelected: newMethod });
    }
  };

  render() {
    const {
      subscriptionActive,
      ccNumber,
      ccExpiryMonth,
      ccExpiryYear,
      ccZip,
      modalStartSubscription,
      subscriptions,
      stripeId
    } = this.state;
    const { startDate, endDate } = this.state.dateRange;
    const { userProfile } = this.props;
    const { timezone } = userProfile;

    return (
      <div>
        {subscriptionActive ? (
          <div>
            <ListGroup>
              <ListContainer wide={true}>
                <SubscriptionList
                  subscriptionList={subscriptions}
                  timezone={userProfile.timezone}
                />
              </ListContainer>
            </ListGroup>
            {ccNumber ? (
              <ListContainer>
                <ListLabel>Credit Card on File</ListLabel>
                <CCListWrapper>
                  <span>{`...${ccNumber}`}</span>
                  <span>{`${ccExpiryMonth}/${ccExpiryYear}`}</span>
                  <span>{`${ccZip}`}</span>
                </CCListWrapper>
                <OpenRecentTransactions
                  color='purple-outline'
                  onClick={() => this.setState({ openChangeCard: true })}
                  id='change-payment-method'
                  outlined={userProfile.stripePaymentsFailed > 0 ? true : false}
                >
                  Change payment method
                </OpenRecentTransactions>
              </ListContainer>
            ) : (
              <Loading />
            )}
          </div>
        ) : ccNumber ? (
          <ListContainer>
            <ListLabel>Credit Card on File</ListLabel>
            <CCListWrapper>
              <span>{`...${ccNumber}`}</span>
              <span>{`${ccExpiryMonth}/${ccExpiryYear}`}</span>
              <span>{`${ccZip}`}</span>
            </CCListWrapper>
            <OpenRecentTransactions
              color='purple-outline'
              onClick={() => this.setState({ openChangeCard: true })}
              id='change-payment-method'
              outlined={
                this.props.userProfile.stripePaymentsFailed > 0 ? true : false
              }
            >
              Change payment method
            </OpenRecentTransactions>
          </ListContainer>
        ) : (
          <Loading />
        )}
        <OpenRecentTransactions onClick={this.openRecentTransactions}>
          Recent Transactions
        </OpenRecentTransactions>
        {/* Modal for confirming cancellation of subscriptions */}
        <BpModal
          open={this.state.modalCancelSubscription}
          title={`Cancel Subscription`}
          primaryAction={{
            btnLabel: "Close",
            action: this.cancelSubscription
          }}
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
          contentLabel='Cancel Subscription'
          width='500px'
          body={
            <div>
              <p>
                Please contact your account executive to cancel your
                subscription. If you don’t know their contact information you
                can contact us by the methods below.
              </p>
              <ButtonWrapper>
                <ButtonContainer>
                  Email:
                  <br />
                  <a href='mailto:help@boostpoint.com?subject=I want to cancel my subscription'>
                    sales@boostpoint.com
                  </a>
                  <br />
                  <Button>
                    <a
                      href='mailto:help@boostpoint.com?subject=I want to cancel my subscription'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <Icon className='material-icons'>email</Icon>
                    </a>
                  </Button>
                </ButtonContainer>
                <ButtonContainer>
                  Call:
                  <br />
                  <a href='tel:717.455.7125'>717.455.7125</a>
                  <br />
                  <Button>
                    <a href='tel:717.455.7125'>
                      <Icon className='material-icons'>call</Icon>
                    </a>
                  </Button>
                </ButtonContainer>
              </ButtonWrapper>
            </div>
          }
        />

        {/* Modal for updating payment method */}
        <BpModal
          open={this.state.openChangeCard}
          title={`Update Payment Method`}
          primaryAction={{
            btnLabel: "Save",
            action: this.updatePaymentMethod,
            loading: this.state.custUpdateLoading
          }}
          secondaryAction={{
            btnLabel: "Cancel",
            action: () => this.setState({ openChangeCard: false }),
            loading: this.state.custUpdateLoading
          }}
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
          contentLabel='Update Payment Method'
          body={
            <div>
              <p>Enter your updated payment information below</p>
              <CCContainer>
                <CardElement onChange={this.stripeElementChange} />
              </CCContainer>
            </div>
          }
          width='auto'
        />

        {/* Modal for viewing transactions */}
        <BpModal
          open={this.state.openRecentTransactions}
          title={`Transaction History`}
          primaryAction={{
            btnLabel: "Close",
            action: () =>
              this.setState({
                openRecentTransactions: false
              })
          }}
          styleOverride={{
            bodyStyles: {
              allowOverflow: true
            },
            content: {
              overflow: "visible"
            }
          }}
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
          onRequestClose={() => {
            this.setState({ openRecentTransactions: false });
          }}
          contentLabel='Transaction History'
          body={
            <StripeRecentTransactions
              stripeId={stripeId}
              timezone={timezone}
              startDate={startDate}
              endDate={endDate}
              handleInvoicePaymentFixId={this.handleInvoicePaymentFixId}
              handlePayInvoiceClick={this.handlePayInvoiceClick}
              custUpdateLoading={this.state.custUpdateLoading}
            />
          }
          width='auto'
        />

        <BpModal
          open={this.state.openStripePaymentFix}
          title={`Enter Payment Method`}
          // primaryAction={{
          //   btnLabel: 'Done',
          //   action: this.updateCustomer,
          //   loading: this.state.custUpdateLoading
          // }}
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
          contentLabel='Enter Payment Method'
          body={
            <StripePaymentFix
              invoiceId={this.state.invoicePaymentFixId}
              formatDate={this.formatDate}
              handleCloseInvoiceFix={this.handleCloseInvoiceFix}
              stripeElementChange={this.stripeElementChange}
              custUpdateLoading={this.state.custUpdateLoading}
              handleInvoicePaymentFixUpdate={this.handleInvoicePaymentFixUpdate}
            />
          }
          width='auto'
        />
        <BpModal
          open={this.state.modalStripeCustomerMissing}
          title={`Account Issue`}
          primaryAction={{
            btnLabel: "Close",
            action: () => this.setState({ modalStripeCustomerMissing: false })
          }}
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
          contentLabel='Account Issue'
          body={
            <p>
              There is a problem with your account. Please email us at{" "}
              <a href='mailto:help@boostpoint.com'>help@boostpoint.com</a> or
              call us at 717-455-7125 to resolve this issue.
            </p>
          }
          width='auto'
        />
        <BpModal
          open={modalStartSubscription}
          title={`Start Subscription`}
          primaryAction={{
            btnLabel: "Close",
            action: this.subToggleChange
          }}
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
          contentLabel='Start Subscription'
          width='500px'
          body={
            <div>
              <p>
                Please contact sales for pricing and to start your subscription.
              </p>
              <ButtonWrapper>
                <ButtonContainer>
                  Email:
                  <br />
                  <a href='mailto:help@boostpoint.com?subject=I want to start a subscription'>
                    sales@boostpoint.com
                  </a>
                  <br />
                  <Button>
                    <a
                      href='mailto:help@boostpoint.com?subject=I want to cancel my subscription'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <Icon className='material-icons'>email</Icon>
                    </a>
                  </Button>
                </ButtonContainer>
                <ButtonContainer>
                  Call:
                  <br />
                  <a href='tel:717.455.7125'>717.455.7125</a>
                  <br />
                  <Button>
                    <a href='tel:717.455.7125'>
                      <Icon className='material-icons'>call</Icon>
                    </a>
                  </Button>
                </ButtonContainer>
              </ButtonWrapper>
            </div>
          }
        />

        <UserAgreement
          openTermsOfUse={this.state.openTermsOfUse}
          openBetaAgreement={this.state.openBetaAgreement}
          closeUserAgreement={this.closeUserAgreement}
        />
      </div>
    );
  }
}

export default injectStripe(StripeAccount, { withRef: true });
